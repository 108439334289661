// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all {
  ::placeholder {
    color: rgb(190, 190, 190);
  }

  input {
    border: 1px solid rgb(194, 194, 194);
    border-radius: 2px;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    height: 3rem;
    line-height: 1.5rem;
    padding: 10px;
    width: 100%;
  }

  .bold {
    font-weight: bold;
  }

  .disabled {
    color: rgb(150, 150, 150);
  }

  .error {
    background: unset !important;
  }

  .error-label-text {
    color: rgb(223, 79, 60);
    font-weight: bold;
    float: right;
    max-width: 50%;
  }

  .error > input {
    border: 2px solid rgb(233, 79, 68);
  }

  .error > input > label, .required > label > span {
    color: rgb(223, 79, 60);
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/forms/input-date/input-date.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAyB;EAC3B;;EAEA;IACE,oCAAoC;IACpC,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,WAAW;EACb;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,uBAAuB;IACvB,iBAAiB;IACjB,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,kCAAkC;EACpC;;EAEA;IACE,uBAAuB;EACzB;AACF","sourcesContent":["@media all {\n  ::placeholder {\n    color: rgb(190, 190, 190);\n  }\n\n  input {\n    border: 1px solid rgb(194, 194, 194);\n    border-radius: 2px;\n    display: inline-block;\n    font-size: 1rem;\n    font-weight: 400;\n    height: 3rem;\n    line-height: 1.5rem;\n    padding: 10px;\n    width: 100%;\n  }\n\n  .bold {\n    font-weight: bold;\n  }\n\n  .disabled {\n    color: rgb(150, 150, 150);\n  }\n\n  .error {\n    background: unset !important;\n  }\n\n  .error-label-text {\n    color: rgb(223, 79, 60);\n    font-weight: bold;\n    float: right;\n    max-width: 50%;\n  }\n\n  .error > input {\n    border: 2px solid rgb(233, 79, 68);\n  }\n\n  .error > input > label, .required > label > span {\n    color: rgb(223, 79, 60);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
