import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.css']
})
export class ButtonDropdownComponent implements OnInit {
  @Input() buttonID = '';
  @Input() label = 'Expand';

  constructor() { }

  ngOnInit(): void {
  }

}
