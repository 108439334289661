import { Component, OnInit } from '@angular/core';
import { BusinessDevelopmentService } from '../services/business-development.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment';
import {MenuService} from '../../../core/page-header/service/menu.service';
import { HttpService } from '../../../services/http.service';

@Component({
  selector: 'app-bd-dashboard',
  templateUrl: './bd-dashboard.component.html',
  styleUrls: ['./bd-dashboard.component.css']
})
export class BdDashboardComponent implements OnInit {
  dashboardData;
  notifications;
  pageNo = 1;
  showMore;
  userInfo=JSON.parse(localStorage.getItem('userInfo'));
  constructor(
    private businessDevelopmentService: BusinessDevelopmentService,
    private toastrService: ToastrService,
    private router: Router,
    private httpService: HttpService,
    private menuService:MenuService
  ) { }

  ngOnInit() {
    this.getNotifications(0,this.pageNo);
    this.getDashboardData();
  }

  getDashboardData() {
    this.businessDevelopmentService.getDasboardData().subscribe(res => {
      if (res) {
        this.dashboardData = res[0];
        console.log(this.dashboardData);
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  gotoCustomer() {
    let params = {};
    params = {isNew: false, isActive: true};
    this.router.navigate(['business-development/sites'], { queryParams: params });
  }

  gotoProjects(params){
    this.router.navigate(['business-development/projects'], { queryParams: params });
  }

  gotoAudit(){
    let params = {};
    params = { startDate: moment().startOf('month').format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY') };
    this.router.navigate(['business-development/audit-request'], { queryParams: params });
  }

  gotoInvoice(){
    let params = {};
    params = { startDate: moment().startOf('month').format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY'), productId: 1 };
    this.router.navigate(['business-development/billing'], { queryParams: params });
  }
  getNotifications(Siteid, pageNo) {
    this.menuService.getNotifications(Siteid, pageNo).subscribe((res) => {
      if (res) {
        this.pageNo = pageNo;
        this.notifications = res;
        this.showMore = this.notifications.length < (pageNo * 10) ? false : true;
      }
    },
      err => {
        throw err;
      });
  }

}
