import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-play-stop-button',
  templateUrl: './play-stop-button.component.html',
  styleUrls: ['./play-stop-button.component.css'],
})
/**
 * @property {string} The URL for the audio that should be played
 */
export class PlayStopButtonComponent implements OnInit {
  @Input() audio ?: HTMLAudioElement;
  @Input() url: string;

  isPlaying = false;

  constructor() { }

  ngOnInit(): void {
    // Creates an new Audio element if one isn't passed from the parent
    if (!this.audio) {
      this.audio = new Audio();
    }

    // When audio is endend, this will toggle the icon for all components that use the audio element
    this.audio.addEventListener('ended', (event) => {
      this.isPlaying = false;
    });

    // Custom event that toggles the icon for all siblings, has to be fired manually. Created to
    // deal with race conditions that come with using the native events.
    this.audio.addEventListener('childIsPlaying', (event) => {
      this.isPlaying = false;
    });
  }

  /**
   * Plays the audio and toggles the icon state
   */
  playAudio(): void {
    this.audio.src = this.url;

    this.audio.load();

    this.audio.play();

    this.audio.dispatchEvent(new Event('childIsPlaying'));

    this.isPlaying = true;
  }

  /**
   * Stops the audio that playing and changes the icon state
   */
  stopAudio(): void {
    this.isPlaying = false;

    this.audio.pause();
    this.audio.currentTime = 0;
  }
}
