// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host, form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

app-input-group div {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

app-input-group > div, .business-name {
  display: inline-flex;
  flex-direction: row;
  gap: 1.5rem;
}

.language-assistance {
  max-width: 50rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/intakes/local-listings/local-listings-location-info/local-listings-location-info.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[":host, form {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\napp-input-group div {\n  display: flex;\n  flex-direction: row;\n  gap: 2rem;\n}\n\napp-input-group > div, .business-name {\n  display: inline-flex;\n  flex-direction: row;\n  gap: 1.5rem;\n}\n\n.language-assistance {\n  max-width: 50rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
