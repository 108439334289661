import { Component, OnInit } from '@angular/core';
import { IntakesService } from '../../services/intakes.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

@Component({
  selector: 'app-approval-popup',
  templateUrl: './approval-popup.component.html',
  styleUrls: ['./approval-popup.component.css']
})
/**
 * @deprecated
 */
export class ApprovalPopupComponent implements OnInit {
basicMenu;
allChecked;
familyId;
intakeId;
  constructor(
    private intakesService: IntakesService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.basicMenu = _.filter(this.intakesService.basicMenu, (menu) => {
      return menu.name.toLowerCase() != 'review';
    });
    console.log(this.basicMenu);
  }

  approve(basicMenu){
    console.log(this.basicMenu);
  }

  disapprove(){
    this.activeModal.close();
  }
}
