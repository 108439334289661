import { Pipe, PipeTransform } from '@angular/core';
import { String } from '@shared/utilities/StringUtilities';

@Pipe({
  name: 'toIdString',
  pure: true,
})
export class ToIdStringPipe implements PipeTransform {
  transform(string: string, addOnText: string = ''): string {
    return String.toIdString(string, addOnText);
  }
}
