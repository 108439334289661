import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-submit',
  templateUrl: './button-submit.component.html',
  styleUrls: ['./button-submit.component.css'],
})
export class ButtonSubmitComponent implements OnInit {
  @Input() disabled = false;
  @Input() label = 'Submit';

  @Output() clickFunction = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }
}
