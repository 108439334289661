import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css'],
})
export class TabComponent implements OnInit {
  constructor() { }

  /** @TODO: Determine type of {site}, or rewrite so type is consistent */
  @Input() site;
  @Input() siteId: string;
  @Input() tabDisabled = false;

  ngOnInit(): void {}
}
