import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import {DownloadsService} from '../downloads.service';
import {ExcelService} from '../../reporting/services/excel.service';
import * as _ from 'lodash';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { HttpService } from '@services/http.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.css'],
  providers: [DatePipe]
})
export class ExportDataComponent implements OnInit, PageHasTable {
  @ViewChild('dt') dataTable: TableGenericComponent;
  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  columnHeadersPayment: ColumnHeader[];
  columnHeadersSiteCCM: ColumnHeader[];
  columnHeadersSiteSetting: ColumnHeader[];
  columnHeadersStripe: ColumnHeader[];
  nestedTableColumnHeaders: ColumnHeader[];
  nestedTableData: Array<object>;
  nestedTableDataType: ColumnDataTypes;
  nestedTableQuery: EventEmitter<any>;
  siteTypes: string[] = [];
  accountManagerNames: string[] = [];
  corporatePartnerNames: string[] = [];

  dateRange: any;
  constructor(private downloadsService:DownloadsService,
              private excelService:ExcelService,
              private httpService: HttpService,
              private toastrService: ToastrService,
              private datePipe: DatePipe,) { }
  type=1;
  types=[{id:1,name:'Site Setting '},{id:2,name:'Site CCM '},{id:6,name:'Client Reporting'},{id:5,name:'Other'}];
  exportdata;
  report=0;
  reportType;
  selectedReport: string = 'PPC';
  bsConfig: Partial<any>;

  ngOnInit() {
    this.getExportDate(this.type);
    this.bsConfig = {
      isAnimated: true,
      adaptivePosition: true,
      dateInputFormat: 'MM/DD/YYYY'
    };
  }

  getExportDate(type: number) {
    this.type = type;

    if (type === 5 ) {
      this.getReportConfigMenu();
    } else if (type === 6 ) {
      this.getReportConfigMenuForClientReporting();
    } else {
      this.downloadsService.getExportData(type).subscribe(res => {
        if (res) {
          this.type = type;
          this.exportdata = res;

          this.siteTypes = Array.from(new Set(res.map(item => item.siteType)));
          this.accountManagerNames = Array.from(new Set(res.map(item => item.accountManagerName)));
          this.corporatePartnerNames = Array.from(new Set(res.map(item => item.corporatePartnerName)));

        }
      },
      (err) => {
        throw err;
      },
      () => this.getTableData()
      );
    }
  }

  getTableData(): void {
    this.columnHeadersSiteSetting = [
      {field: 'name', header: 'Site Name'},
      {field: 'siteType', header: 'Site Type', filter: 'select'},
      {field: 'domainUrl', header: 'Domain'},
      {field: 'accountManagerName', header: 'AM', filter: 'select'},
      {field: 'corporatePartnerName', header: 'CP', filter: 'select'},
    ];

    this.columnHeadersSiteCCM = [
      {field: 'name', header: 'Site Name'},
      {field: 'domainUrl', header: 'Site Type'},
      {field: 'newsletterDate', header: 'NewsLetter Date'},
      {field: 'cmsEmailDate', header: 'CMS Email Date'},
      {field: 'cmsDemoDate', header: 'CMS Demo Date'},
    ];

    this.columnHeadersStripe = [
      {header: 'Site Name', field: 'name'},
      {header: 'Domain', field: 'domainUrl'},
      {header: 'Product Name', field: 'productName'},
      {header: 'List Price', field: 'listPrice'},
      {header: 'Quantity', field: 'quantity'},
      {header: 'Recurring Fee', field: 'totalPrice'},
      {header: 'Stripe Subscription ID', field: 'stripeSubscriptionId'},
      {header: 'Stripe Pricebook ID', field: 'stripePricebookId'},
    ];

    this.columnHeadersPayment = [
      {header: 'ID', field: 'id'},
      {header: 'Site Name', field: 'name'},
      {header: 'Domain', field: 'domainUrl'},
      {header: 'Products', field: 'products'},
      {header: 'Payment Status', field: 'paymentStatusName'},
      {header: 'Payment Mode', field: 'paymentModeName'},
      {header: 'Intake Status', field: 'statusName'},
    ];

    switch (this.type) {
      case 1: this.columnHeaders = this.columnHeadersSiteSetting; break;
      case 2: this.columnHeaders = this.columnHeadersSiteCCM; break;
      case 3: this.columnHeaders = this.columnHeadersStripe; break;
      case 4: this.columnHeaders = this.columnHeadersPayment; break;
      default: this.columnHeaders = this.columnHeadersSiteSetting;
    }
  }

  /**
   * Generates CSV export file
   * @deprecated Process to export data may need to be included in general export
   * @param {Object} type
   */
  exportToCsv(type) {
    let exelData;
    let header;
    let fileName;
    switch(type){
      case 1:
        exelData = [
          {
            id:'Id',
            name: 'Site Name',
            siteType:'Site Type',
            domainUrl:'Domain Url',
            accountManagerName:'Account Manager',
            corporatePartnerName:'Program',
            isLive:'Is Live',
            isActive:'Is Active',
            launchDate:'Launch Date',
            wpSitePath:'WP Site Path',
            cpAccountNo:'CP#',
            emailProvider:'Email Provider',
            fbAccountId:'Facebook Account Id',
            gAdsCustomerId:'Google Ads Customer Id',
            gaAdminAccountId:'Google Analytics Admin Account Id',
            gaPropertyId:'Google Analytics Property Id',
            gaViewId:'Google Analytics View Id',
            gscAdminAccountId:'GSC Admin Account Id',
            gscDomain:'GSC Domain',
            piwikDomain:'Piwik Domain',
            piwikSiteId:'Piwik Site Id',
            seMrushDomain:'SemRush Domain',
            seMrushProjectId:'SemRush Project Id',
            stripeCustomerId:'Stripe Customer Id',
            stripeCustomerId2:'stripe Customer Id 2'
          }
        ];
        header=['id','name','siteType','domainUrl','accountManagerName','corporatePartnerName','isLive',
        'isActive','launchDate','wpSitePath','cpAccountNo','emailProvider','fbAccountId','gAdsCustomerId','gaAdminAccountId',
        'gaPropertyId','gaViewId',,'gscAdminAccountId','gscDomain','piwikDomain','piwikSiteId','seMrushDomain',
        'seMrushProjectId','stripeCustomerId','stripeCustomerId2'];
        fileName='Site Setting';
      break;
      case 2:
        exelData = [
          {
            id:'Id',
            name: 'Name',
            domainUrl: 'Domain Url',
            newsletterDate:'Newsletter Date',
            isSocialMediaFollows:'Is Social Media Follows',
            cmsEmailDate:'CMS Email Date',
            cmsDemoDate:'CMS Demo Date',
            cmsThanksDate:'CMS Thanks Date',
            followupDate:'Followup Date',
            followupResponse:'Followup Response',
            reviewRequestDate:'Review Request Date',
            requestSeoReportDate:'Request SEO Report Date',
            surveyRequestDate:'Survey Request Date',
            surveyResponse:'Survey Response',
            handWrittenSentDate:'Hand Written Sent Date',
            giftLinkSentDate:'Gift Link Sent Date',
            annualFollowupDate:'Annual Followup Date',
            annualFollowupResponse:'Annual Followup Response',
          }
        ];
        header=['id','name','domainUrl','newsletterDate','isSocialMediaFollows','cmsEmailDate','cmsDemoDate',
                'followupDate','followupResponse','reviewRequestDate','requestSeoReportDate','surveyRequestDate','surveyResponse',
                 'handWrittenSentDate','giftLinkSentDate','annualFollowupDate','annualFollowupResponse'];
        fileName='Site CCM'
      break;
      case 3:
        exelData = [
          {
            id:'Id',
            name: 'Name',
            domainUrl: 'Domain Url',
            family:'Family',
            productName:'Product Name',
            listPrice:'List Price',
            quantity:'Quantity',
            unitPrice:'Unit Price',
            totalPrice:'Total Price',
            paymentType:'Payment Type',
            stripeSubscriptionId:'Stripe Subscription Id',
            stripeSubscriptionStatus:'Stripe Subscription Status',
            subscriptionStartedOn:'Subscription Started On',
            stripePricebookId:'Stripe Pricebook Id'
          }
        ];
        header=['id','name','family','domainUrl','productName','listPrice','quantity','unitPrice','totalPrice',
                'paymentType','stripeSubscriptionId','stripeSubscriptionStatus','subscriptionStartedOn','stripePricebookId'];
        fileName='Site Product'
      break;
      case 4:
        exelData = [
          {
            id:'Id',
            name: 'Name',
            domainUrl: 'Domain Url',
            products:'Products',
            initiate_date:'Start Date',
            paymentStatusName:'Payment Status',
            paymentModeName:'Payment Mode',
            statusName:'Intake Status'
          }
        ];
        header=['id','name','domainUrl','products','initiate_date','paymentStatusName','paymentModeName','statusName'];
        fileName='Site Product Type';
        break;
    }

    _.forEach(this.exportdata, (user) => {
      let data = _.pick(user, header);
      exelData.push(data);
    })
    this.excelService.exportAsExcelFile(exelData, fileName);
  }
  getReportConfig(type){
    if(type==0)
      return;
    this.downloadsService.getReportConfig(type).subscribe(res=>{
      if(res){
        this.downloadReportConfig(res,this.report);
      }
    },err=>{
      throw err;
    })
  }
  downloadReportConfig(reportConfig,type){
    let exelData;
    let header;
    let fileName;
    switch(type){
    case "1":
      exelData = [
        {
          domainUrl:'Domain Url',
          locationId: 'Location Id',
          locationName: 'Location Name'
        }
      ];
      header=['domainUrl','locationId','locationName'];
      fileName='Goggle My Business';
      break;
      case "2":
        exelData = [
          {
            domainUrl:'Domain Url',
            pageId: 'Page Id',
            pageName: 'Page Name'
          }
        ];
        header=['domainUrl','pageId','pageName'];
        fileName='InataPage';
        break;
        case "3":
          exelData = [
            {
              domainUrl:'Domain Url',
              locationId: 'Location Id',
              locationName: 'Location Name'
            }
          ];
          header=['domainUrl','locationId','locationName'];
          fileName='SEM Lcoation';
          break;
          case "4":
            exelData = [
              {
                domainUrl:'Domain Url',
                gradusId: 'Gradus Id',
                profileName: 'Profile Name'
              }
            ];
            header=['domainUrl','gradusId','profileName'];
            fileName='ReviewGen';
            break;
            case "5":
              exelData = [
                {
                  domainUrl:'Domain Url',
                  customerCode: 'Customer Code',
                  customerName: 'Customer Namee',
                  adSource:'Ad Source',
                  targetNo:'Target No',
                  trackingNo:'TrackingNo',
                  isReplacementNotRequired:'Do not replace Target No',
                  isActive:'Active'
                }
              ];
              header=['domainUrl','customerCode','customerName','adSource','targetNo','trackingNo', 'isReplacementNotRequired', 'isActive'];
              fileName='Call Tracking';
              break;
            case "6":
              exelData = [
                {
                  domainUrl:'Domain Url',
                  accountId: 'Account Id',
                  accountName: 'Account Name'
                }
              ];
              header=['domainUrl','accountId','accountName'];
              fileName='Social Media Marketing';
            break;
            case "7":
              exelData = [
                {
                  domainUrl:'Domain Url',
                  clinicId: 'Clinic Id',
                  clinicName: 'Clinic Name',
                  locationName:'Location Name'
                }
              ];
              header=['domainUrl','clinicId','clinicName','locationName'];
              fileName='BluePrint';
            break;
            case "8":
              exelData = [
                {
                  domainUrl:'Domain Url',
                  clinicId: 'Clinic Id',
                  versionName: 'Version',
                  parentId:'Parent Id',
                  location:'Location',
                  callbackEmail:'Call back Email',
                  timeZoneName:'Time Zone',
                  gradusId:'Gradus Id',
                  profileName:'Profile Name'
                }
              ];
              header=['domainUrl','clinicId','versionName','parentId','location',
                      'callbackEmail','timeZoneName','gradusId','profileName'];
              fileName='Sycle';
            break;
    }
    _.forEach(reportConfig, (user) => {
      let data = _.pick(user, header);
      exelData.push(data);
    });
  this.excelService.exportAsExcelFile(exelData, fileName);
  }
  getReportConfigMenu(){
    this.downloadsService.getGeneralMasterByType(14).subscribe(res=>{
      if(res){
        this.reportType=res;
      }
    },err=>{
      throw err;
    })
  }
  getReportConfigMenuForClientReporting() {
    this.downloadsService.getGeneralMasterByType(14).subscribe(res => {
      if (res) {
        this.reportType = res.filter(item => item.name === 'Google My Business');
      }
    }, err => {
      throw err;
    });
  }

  onReportChange(event: any) {
    if (this.selectedReport === 'ClientsPackagesAndProducts') {
      this.dateRange = null; // Clear the date range
    }
  }

  getCSVReport(selectedReportConfig: string): void {
    let startDate = '';
    let endDate = '';

    if (this.dateRange && this.dateRange.length === 2) {
      startDate = this.dateRange[0] ? this.datePipe.transform(this.dateRange[0], 'MM/dd/yyyy') : '';
      endDate = this.dateRange[1] ? this.datePipe.transform(this.dateRange[1], 'MM/dd/yyyy') : '';
    }

    this.downloadsService.getCSVReport(startDate, endDate, selectedReportConfig).subscribe(
      () => {
        // Display success pop-up
        this.toastrService.success('Report is being processed, expect an email shortly!', 'Success');
      },
      (err) => {
        // Handle error directly
        const errorMessage = err.error?.error || 'An error occurred.';
        this.httpService.openErrorPopup(errorMessage);
      }
    );
  }
}