import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit {
  @Input() data;
  @Input() label;

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: String[];
  public pieChartData: number[];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];

  constructor() { }

  ngOnInit() { 
    this.pieChartLabels = this.label;
    this.pieChartData = this.data;
    console.log(this.data,'==piechart data')
    console.log(this.label,'==piechart label')
  }

}
