export class Address {
      intakeId:number=0;
      srNo:number=0;
      currentBusinessName:string='';
      previousBusinessName:string='';
      businessOwnerName:string='';
      businessOwnerEmail:string='';
      hoursofOperation:string='';
      paymentsAccepted:string='';
      currentBusinessUrl:string='';
      previousBusinessUrl:string='';
    //   curBusLocationName:string='';
      curBusAddress1:string='';
      curBusAddress2:string='';
      curBusCity:string='';
      curBusState:string='';
      curBusZipCode:string='';
      curBusPhoneNumber:number=null;
    //   preBusLocationName:string='';
      preBusAddress1:string='';
      preBusAddress2:string='';
      preBusCity:string='';
      preBusState:string='';
      preBusZipCode:string='';
      preBusPhoneNumber:number=null;
      lstLocationWorkingHours=[
        {
            id:0,
            locationId:0,
            day:'Monday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Tuesday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Wednesday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Thursday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Friday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Saturday',
            openTime:'Closed',
            closeTime:'Closed'
        },{
            id:0,
            locationId:0,
            day:'Sunday',
            openTime:'Closed',
            closeTime:'Closed'
        }
    ]
    constructor(id?) {
       this.intakeId=id?id:0;
    }

}


     
