const customLayout={
 getLayout:function(tooltipModel,canvas){
    var tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip'; 
        document.body.appendChild(tooltipEl);
    }
    
    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        // tooltipEl.style.opacity = '0';
        tooltipEl.style.display = 'none';
        return;
    }
    
    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }
    
    function getBody(bodyItem) {
        return bodyItem.lines;
    }
    
    // Set Text
    if (tooltipModel.body) {
        var titleLines = tooltipModel.title || [];
        var bodyLines = tooltipModel.body.map(getBody);
    
        var innerHtml = '';
        bodyLines.forEach(function(body, i) {
          innerHtml += '<div style="background:#303E4E; color:#fff; border-radius:2px; font-size:20px; font-weight:400; padding:6px 22px; min-height:30px;   display:inline-block; text-align: center;">'+body+'</div>';
         
        }); 
        tooltipEl.innerHTML = innerHtml;
    }
    
    // `this` will be the overall tooltip
    var position = canvas.getBoundingClientRect();
    
    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.display = 'block';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
 }
}

export{
    customLayout
}
