import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { roles } from '@shared/master-enum.enum';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})

// export let currentRole;

export class RoleGuardsService {
  currentRole;
  constructor(public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean { 
    // this will be passed from the route config
    // on the data property
    let expectedRole = route.data.expectedRole;
    const userInfo =JSON.parse(localStorage.getItem('userInfo')); 
    if(userInfo){
      let role = _.find(roles, (role) => {
        return role.roleId == userInfo['roleId'];
      })
      this.currentRole = role.url;
      if((typeof expectedRole == 'object') && expectedRole.length){
        expectedRole = _.find(expectedRole, (role) => {
          return role == userInfo['roleId'];
        })
      }
      if(userInfo.roleId !== expectedRole){
        this.router.navigate(['/dashboard']);
      }
    }   

    if (!userInfo) {
      localStorage.clear();
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    return true;
  }

  

}

export function getCurrentRole() {
  let currentUser = JSON.parse(localStorage.getItem('userInfo'));
  if(currentUser){
    let role = _.find(roles, (role) => {
      return role.roleId == currentUser['roleId'];
    })
    return role.url;
  }
}