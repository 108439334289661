// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-input::placeholder {
  color: rgb(190, 190, 190);
}

.upload-input {
  border: 1px solid rgb(194, 194, 194);
  border-radius: 2px;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  height: 3rem;
  line-height: 1.5rem;
  padding: 10px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/intakes/local-listings/local-listings-business-info/local-listings-business-info.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,WAAW;AACb","sourcesContent":[".upload-input::placeholder {\n  color: rgb(190, 190, 190);\n}\n\n.upload-input {\n  border: 1px solid rgb(194, 194, 194);\n  border-radius: 2px;\n  display: inline-block;\n  font-size: 1rem;\n  font-weight: 400;\n  height: 3rem;\n  line-height: 1.5rem;\n  padding: 10px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
