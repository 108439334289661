import { Component, HostListener } from '@angular/core';
import { HttpService } from '@services/http.service';
import { Router, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';
import { roles } from '@shared/master-enum.enum';

@Component({
  selector: 'pages',
  template: `
    <ng-container *ngIf="isSmallScreen(); else largeScreenTemplate">
      <!-- Template for small screens -->
      <div id="leftNav" class="leftNav slide-nav-toggle">
        <app-page-header></app-page-header> 
        <div id="wrapper" class="wrapper">
          <router-outlet></router-outlet> 
          <app-page-footer></app-page-footer>
        </div>
      </div>
    </ng-container>
    
    <ng-template #largeScreenTemplate>
      <!-- Template for large screens -->
      <div id="leftNav" class="leftNav">
        <app-page-header></app-page-header> 
        <div id="wrapper" class="wrapper menuExpanded">
          <router-outlet></router-outlet> 
          <app-page-footer></app-page-footer>
        </div>
      </div>
    </ng-template>
  `
})
export class Pages {
  menuList;

  constructor(
    private http: HttpService,
    private router: Router
  ) { }

  isSmallScreen(): boolean {
    return window.innerWidth < 992;
  }

  ngOnInit() {
    if(localStorage.getItem('userInfo')){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let role = _.find(roles, (role) => {
        return role.roleId == userInfo.roleId;
      });
      let url = this.router.url == '/' ? `/${role.url}/dashboard` : this.router.url;
      this.router.navigateByUrl(url);
    }
  }
}
