import { Component, OnInit, Renderer2, Output, EventEmitter, Input } from '@angular/core';
import { SectionStatus } from '../../review/section-model';
import { IntakesService } from '../../../../services/intakes.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { HttpService } from '../../../../../../../services/http.service';

@Component({
  selector: 'app-enhanced-facebook',
  templateUrl: './enhanced-facebook.component.html',
  styleUrls: ['./enhanced-facebook.component.css']
})
export class EnhancedFacebookComponent implements OnInit {
  intakeId;
  siteId;
  @Input() productType;
  @Input() sectionId;
  @Input() intakeTypeId;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  constructor(
    private _renderer2: Renderer2,
    private toastrService: ToastrService,
    private intakesService: IntakesService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.siteId = params.siteId;
      }
    });
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.addSendibleConnect();
  }

  addSendibleConnect() {
    let script = this._renderer2.createElement('script');
    let widget = document.getElementById('client-connect-widget');
    script.type = `text/javascript`;
    script.text = `!function(){var e=document,t=e.createElement("iframe");window.addEventListener("message",function(e){if(!e || !e.data || !e.data.height){return;}t.height=(parseInt(e.data.height, 10)+45)+"px"},!1),t.style.border="0",t.style.width="100%",t.id="client-connect-iframe",t.src="https://api.sendible.com/api/v2/client_connect_widget.json?id=89",e.getElementById("client-connect-widget").appendChild(t)}();
		`;
    this._renderer2.appendChild(widget.parentNode, script);
  }
  goBack() {
    this.backClicked.next();
  }
  goToNext() {
    this.updateStatus();
    this.continueClicked.next('parent');
  }

  updateStatus() {
    let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, this.intakeId);
    let menu = _.find(this.intakesService.basicInfoRequiredData, (menu) => {
      return menu.familyId == this.intakeTypeId;
    });
    let selectedSubMenu = _.find(menu.submenu, (menuItem) => {
      return menuItem.id == this.sectionId;
    })
    sectionStatus.lstIntakeSection[0].percentage = selectedSubMenu.percentage;
    this.postIntakeSection(sectionStatus);
  }

  postIntakeSection(sectionStatus) {
    console.log(sectionStatus);
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }
}
