import { Component, OnInit, Input } from "@angular/core";
import { ReportingService } from "../services/reporting.service";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import moment from 'moment';
import * as _ from "lodash";
import * as html2pdf from "html2pdf.js";
import { HttpService } from "../../../services/http.service";

@Component({
  selector: "app-gsc-report",
  templateUrl: "./gsc-report.component.html",
  styleUrls: ["./gsc-report.component.css"],
  providers: [DatePipe]
})
export class GscReportComponent implements OnInit {
  @Input() siteId;
  // @Input() reportParams;
  gscReport;
  quarterData;
  yearsData = [];
  currentYear = moment().year();
  page = 1;
  reportParams = {
    quarter: moment()
      .subtract(1, "quarter")
      .startOf("quarter")
      .format("MM/DD/YY")
  };
  constructor(
    private reportingService: ReportingService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private datePipe: DatePipe
  ) {}

  ngOnChanges() {
    this.getReport(this.siteId, this.reportParams);
  }

  ngOnInit() {
    this.quarterData = this.reportingService.getQuarters();
    // this.getReport(this.siteId, this.reportParams);
  }

  getReport(siteId, params) {
    this.gscReport={};
    if (moment(params["quarter"], "MM/DD/YY", true).isValid()) {
      params["formattedStartDate"] = moment(params["quarter"])
        .startOf("quarter")
        .format("MM/DD/YYYY");
      params["formattedEndDate"] = moment(params["quarter"])
        .endOf("quarter")
        .format("MM/DD/YYYY");
    }
    this.reportingService.getGscReport(siteId, params).subscribe(
      (res: any) => {
        if (res) {
          this.gscReport = res;
          console.log("api response =>", this.gscReport);
        }
      },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  downloadReport(report: string) {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = "none";
    var collapsedElements = document.getElementsByClassName("collapse");
    var showLess = document.querySelectorAll(".show-less, .drop-icon");
    _.forEach(collapsedElements, element => {
      element.className += " show";
    });
    _.forEach(showLess, element => {
      element.style.display = "none";
    });
    var element =
      '<div style="padding: 10px;">' +
      document.getElementById("report").innerHTML +
      document.getElementById("table-title").innerHTML +
      "</div>";

    var opt = {
      margin: 0,
      filename: "report.pdf",
      jsPDF: { unit: "in", format: "b3", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] }
    };
    let values = [];
    let gscTableData = this.gscReport.googleSearchDetail;
    _.forEach(gscTableData, data => {
      let value = _.at(data, [
        "query",
        "clicks",
        "impressions",
        "ctr",
        "position"
      ]);
      values.push(value);
    });

    var output = "save";
    if (report == "print") {
      output = "dataurlnewwindow";
    }
    var worker = html2pdf()
      .from(element)
      .set(opt);
    worker
      .toPdf()
      .get("pdf")
      .then(pdf => {
        pdf.autoTable({
          headStyles: { fillColor: null, textColor: [0, 0, 0] },
          head: [["Queries", "Clicks", "Impressions", "CTR", "Position"]],
          body: values,
          columnStyles: {
            1: { minCellWidth: 0.75 },
            2: { minCellWidth: 1 },
            3: { minCellWidth: 0.75 },
            4: { minCellWidth: 0.75 }
          },
          startY: 4
        });
        document.getElementById("downloadButton").style.display = "block";
        _.forEach(showLess, element => {
          element.style.display = "block";
        });
      })
      .output(output, "report.pdf");
  }
}
