import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddMasterTypeComponent } from '../add-master-type/add-master-type.component';
import { HttpService } from '@services/http.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class MtListComponent implements OnInit, PageHasTable {
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  typeData;
  message;
  masterType;
  isEdit = true;
  masterTypeId;

  @ViewChild('dt') table: TableGenericComponent;

  constructor(
    private masterService: MasterService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private httpService: HttpService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.masterTypeId = params.id;
      this.getMasterTypeData(params.id);
    });
  }

  ngOnInit() {
    this.getTableData();
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnHeaders = [
      {field: 'name', header: 'Name'},
      {field: 'description', header: 'Description'},
    ];
  }

  openAddPopup() {
    var model = this.modalService.open(AddMasterTypeComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.masterTypeId = this.masterTypeId
    model.componentInstance.masterType = this.masterType.name;
    model.result.then((result) => {
        this.getMasterTypeData(this.masterTypeId);
      this.showMsg('Add Successfully')
      console.log(`add success: ${result}`);
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });

  }
  goToEdit(master) {
    var model = this.modalService.open(AddMasterTypeComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.masterTypeId = this.masterTypeId
    model.componentInstance.master = master;
    model.componentInstance.masterType = this.masterType.name
    model.result.then((result) => {
      setTimeout(() => {
        this.getMasterTypeData(this.masterTypeId);
      }, 50);
      this.showMsg('Update Successfully')
      console.log(`Update success: ${result}`);
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }

  getMasterTypeData(id) {
    this.masterService.getMastersListById(id).subscribe(res => {
      if (res) {
        this.typeData = res.masterList;
        this.masterType = res.masterType;
        this.isEdit = res.masterType.isEdit;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  deleteMaster(id, name: string) {
    if (confirm("Are you sure to delete " + name)) {
      this.masterService.delete(id).subscribe(res => {
        var removeIndex = this.typeData.map(function (item) { return item.id; })
          .indexOf(id);
        ~removeIndex && this.typeData.splice(removeIndex, 1);
      }, err => {
        this.httpService.openErrorPopup(err.error.message);
      });

    }
  }

  showMsg(msg) {
    this.message = msg;
    setTimeout(() => {
      this.message = '';
    }, 3000);
  }
}
