import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from '@modules/intakes/intake-component.interface';
import { IntakeSubmenu } from '@shared/interfaces/intakes';
import { IntakeStatus } from '@modules/intakes/intake-component.interface';

@Component({
  selector: 'app-intake-sidebar-submenu',
  templateUrl: './intake-sidebar-submenu.component.html',
  styleUrls: ['./intake-sidebar-submenu.component.css'],
})
export class IntakeSidebarSubmenuComponent implements OnInit {
  @Input() sectionId: number;
  @Input() menuItems: IntakeSubmenu[];
  @Output() openPage = new EventEmitter<any>();

  @Output() pageChangeFunction = new EventEmitter<any>();

  constructor(
  ) { }

  ngOnInit(): void {}

  selectStep(menuItem: MenuItem): void {
    this.openPage.emit(menuItem);
  }

  convertStatusIdToIntakeStatus(menuItemStatus?: number): IntakeStatus {
    return menuItemStatus === 1 ? 'incomplete' : menuItemStatus === 2 ? 'completed' : 'untouched';
  }
}
