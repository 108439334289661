import { Component, OnInit,ViewChild } from '@angular/core';
import { DownloadsService } from '../downloads.service';
import { HttpService } from '@services/http.service';
import * as _ from 'lodash';
import { ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-sendible-downloads',
  templateUrl: './sendible-downloads.component.html',
  styleUrls: ['./sendible-downloads.component.css'],
})
export class SendibleDownloadsComponent implements OnInit, PageHasTable {
  accounts;
  allAccounts;
  columnHeaders: ColumnHeader[];
  filterBy = {name: null};

  @ViewChild('dt') dataTable: TableGenericComponent;

  constructor(
    private downloadsService: DownloadsService,
    private httpService: HttpService
  ) { }

  ngOnInit() {
    this.getTableData();
  }

  getAccounts(){
    this.downloadsService.getSendibleAccountsData().subscribe((res: any) => {
      if (res) {
        this.allAccounts=res;
        this.accounts = Object.assign([],this.allAccounts);

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData() {
    this.getAccounts();

    this.columnHeaders = [
      {header: 'ID', field: 'id'},
      {header: 'Name', field: 'name'},
    ];
  }

  /**
   * @deprecated
   * @param {Object} filterBy
   */
  applyFilter(filterBy) {
    let filteredUsers = this.allAccounts;

    if (filterBy) {
      if (!_.isEmpty(filterBy.name)) {
        filteredUsers = _.filter(filteredUsers, (user) => {
          return _.includes(user['name'].toLowerCase(), filterBy.name.toLowerCase());
        });
      }

      this.accounts = filteredUsers;
    }
  }
}
