import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertPopupComponent } from '@shared/alert-popup/alert-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const show: Function = null;
const hide: Function = null;

@Injectable({ providedIn: 'root' })
export class HttpService {
  public API_URL = environment.apiUrl;
  public isMasterMockTrue = environment.isMasterMockTrue;
  public httpOptions;

  /**
   * Acts as an adapter to the `HTTPClient` to allow other services to query the API
   * @param {HttpService} http - The base HTTP class
   * @param {Router} router - Handles application routing
   * @param {NgbModal} modalService - The service that manages modals
   * @param {ActivatedRoute} state
   */
  constructor(
    private http: HttpClient,
    private router: Router,
    private modalService: NgbModal,
    private state: ActivatedRoute,
  ) {
    this.setHeaders();
  }

  /**
   * Performs a DELETE request to the specified endpoint
   * @param {String} url          The endpoint to use
   * @param {String} mockFileName The filename to use if using mock data
   * @param {Boolean }isMockTrue  Set true if using mock data
   *
   * @return {Observable<ArrayBuffer|Object>} The response from the endpoint
   */
  deleteApi(
    url: string,
    mockFileName?: string,
    isMockTrue: boolean = false,
    body?: any 
  ): Observable<ArrayBuffer| Object> {
    show();

    if (this.httpOptions !== null) this.setHeaders();

//    let params = {"llBusinessId":body.ll_business_id,"locationId":body.ll_location_id};
    let params = "?llBusinessId="+body.ll_business_id+"&locationId=" + body.ll_location_id;

    if (this.isMasterMockTrue || isMockTrue) {
      return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
    } else {
      return this.http.delete(this.API_URL + url + params, this.httpOptions).pipe(
        map((response) => response),
        catchError(this.handleError<any>()),
        finalize(() => {
          hide();
        })
      );
    }
  }

  /**
   * Performs a GET request
   * @param {String} url The URL of the endpoint
   * @param {Boolean} setHeader
   * @param {String} mockFileName
   * @param {Boolean} isMockTrue Set true to use mock JSON data
   * @param {Boolean} isShowError
   *
   * @return {Observable<Object>} The response from the API endpoint
   */
  getApi(
    url: string,
    setHeader = true,
    mockFileName?: string,
    isMockTrue = false,
    isShowError = true
  ): Observable<object> {
    show();

    url = url.replace(/\+/gi, '%2B');

    if (this.httpOptions !== null) this.setHeaders();

    if (setHeader == false) {
      const userInfo =
        localStorage.getItem('userInfo') != null ?
          JSON.parse(localStorage.getItem('userInfo')):
          { token: '', userId: null, roleId: null };

      this.httpOptions = {
        reportProgress: true,
        responseType: 'blob',
        headers: new HttpHeaders({
          Authorization: 'bearer ' + userInfo.token,
        }),
      };
    }

    if (this.isMasterMockTrue || isMockTrue) {
      return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
    } else {
      return this.http.get(this.API_URL + url, this.httpOptions).pipe(
        map((response) => response),
        catchError(this.handleError<any>(isShowError)),
        finalize(() => {
          hide();
        })
      );
    }
  }

  public getApiNoBody(
    url: string,
    setheader: boolean = true,
    mockFileName?: string,
    isMockTrue: boolean = false,
    isShowError:boolean =true
  ) {
    show();
    url=url.replace(/\+/gi, '%2B');
    if (this.httpOptions !== null) this.setHeaders();
    if (setheader == false) {
      const userInfo =
      localStorage.getItem("userInfo") != null
        ? JSON.parse(localStorage.getItem("userInfo"))
        : { token: "", userId: null, roleId: null };

      this.httpOptions = {
        reportProgress: true,
        responseType: "blob",
        headers: new HttpHeaders({
          Authorization: "bearer " + userInfo.token
        })
      };
    }
    if (this.isMasterMockTrue || isMockTrue) {
      return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
    } else {
      return this.http.get(this.API_URL + url, this.httpOptions).pipe(
        map(response => response),
        catchError(this.handleError<any>(isShowError)),
        finalize(() => {
          hide();
        })
      );
    }
  }

  /**
   * Error handler for HTTP API requests
   * @param {Boolean} isShowError Triggers an error Popup
   *
   * @return {Observable<any>}
   */
  handleError<T>(isShowError = true) {
    return (error: any): Observable<T> => {
      if (error.status === 401 || error.status === 502 || error.status === 504) {
        if (document.getElementById('chat-widget-container')) {
          document.getElementById('chat-widget-container').style.display = 'none';
        }

        localStorage.removeItem('userInfo');

        if (!this.state.snapshot['_routerState'].url.includes('returnUrl')) {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: this.state.snapshot['_routerState'].url },
          });
        }
      } else if (error.status === 404 && error.error.warning && isShowError) {
        this.openErrorPopup(error.error.warning);

        throw new Error('404 Error');
      }

      throw error;
    };
  }

  /**
   * Shows a modal with the given message
   *
   * @deprecated
   * @param {String} message
   */
  openErrorPopup(message): void {
    const model = this.modalService.open(AlertPopupComponent, {
      size: 'sm',
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
    });

    model.componentInstance.message = message;
  }

  /**
   * Performs a POST request to the specified endpoint
   * @param {String} url          The endpoint URL
   * @param {Object} options      The options for the request
   * @param {String} contentType  The content type for the request
   * @param {String} mockFileName The filename to use if using mock data
   * @param {String} isMockTrue   Set true if using mock data
   *
   * @return {Observable<ArrayBuffer|Object>} The response from the endpoint request
   */
  postApi(
    url: string,
    options,
    contentType?,
    mockFileName?: string,
    isMockTrue: boolean = false
  ) {
    show();

    if (this.httpOptions !== null) {
      this.setHeaders();
    }

    if (contentType) {
      this.setHeaders(contentType);
    }

    if (this.isMasterMockTrue || isMockTrue) {
      return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
    } else {
      const requestParams: {} = options;

      return this.http
        .post(this.API_URL + url, requestParams, this.httpOptions)
        .pipe(
          map((response) => response),
          catchError(this.handleError<any>()),
          finalize(() => {
            hide();
          })
        );
    }
  }

  /**
   * Performs a POST request to the specified endpoint
   * @param {String} url          The endpoint URL
   * @param {Object} options      The options for the request
   * @param {String} contentType  The content type for the request
   * @param {String} mockFileName The filename to use if using mock data
   * @param {String} isMockTrue   Set true if using mock data
   *
   * @return {Observable<ArrayBuffer|Object>} The response from the endpoint request
   */
  postApiThird(
    url: string,
    options: object,
    contentType?,
    mockFileName?: string,
    isMockTrue = false
  ): Observable<ArrayBuffer|Object> {
    show();

    if (this.httpOptions !== null) {
      this.setHeaders1();
    }

    if (contentType) {
      this.setHeaders1(contentType);
    }

    if (this.isMasterMockTrue || isMockTrue) {
      return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
    } else {
      const requestParams: {} = options;

      return this.http.post(url, requestParams, this.httpOptions).pipe(
        map((response) => response),
        catchError(this.handleError<any>()),
        finalize(() => {
          hide();
        })
      );
    }
  }


  /**
   * Performs a PUT request to the specified endpoint
   * @param {String} url          The endpoint URL
   * @param {Object} options      The options for the request
   * @param {String} mockFileName The filename to use if using mock data
   * @param {String} isMockTrue   Set true if using mock data
   *
   * @return {Observable<ArrayBuffer|Object>} The response from the endpoint request
   */
  putApi(url: string, options, mockFileName?: string, isMockTrue: boolean = false) {
    show();

    if (this.isMasterMockTrue || isMockTrue) {
      return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
    } else {
      const requestParams: {} = options;

      return this.http
        .put(this.API_URL + url, requestParams, this.httpOptions)
        .pipe(
          map((response) => response),
          catchError(this.handleError<any>()),
          finalize(() => {
            hide();
          })
        );
    }
  }

  /**
   * Sets the request headers for service operations
   * @param {String} contentType The content type for the request
   */
  setHeaders(contentType?): void {
    const userInfo =
      localStorage.getItem('userInfo') != null ?
        JSON.parse(localStorage.getItem('userInfo')) :
        { token: '', userId: null, roleId: null };

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': contentType || 'application/json',
        'Authorization': 'bearer ' + userInfo.token,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'If-Modified-Since': '0',
      }),
    };
  }

  /**
   * Sets a default header values
   * @param {String} contentType The content type for the request
   * @private
   */
  private setHeaders1(contentType?): void {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': contentType || 'application/json',
      }),
    };
  }
}
