// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame-form-div {
  border: 1px solid rgb(186, 186, 186);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.frame-label{
  cursor: pointer;
  width: 100%;
}

footer {
  background-color: rgb(50, 62, 74);
  color: rgb(255, 255, 255);
  padding-left: 1rem;
  text-decoration: underline;
  width: 100%;
}

header {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
}

header label, .delete-confirmation > a {
  color: rgb(28, 153, 251);
  font-weight: bold;
  margin: 0;
  text-decoration: underline;
}

header > i {
  background-size: 100%;
  width: 1.5rem;
}

img {
  filter: brightness(0.5) sepia(1) hue-rotate(167deg) saturate(275%);
  height: 1rem;
  margin-left: 0.5rem;
  width: 1rem;
}

img.rotate {
  transform: rotate(-180deg) translate(0rem, 0px);
}

label span {
  background-image: url('drop-icon.svg');
}

main {
  background-color: unset !important;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
}

.hidden {
  display: none;
}

.box-shadow {
  border-bottom: 1px solid rgb(186, 186, 186);
  box-shadow: 0 3px 4px rgb(186, 186, 186);
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/intakes/frame-form-container/frame-form-container.component.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,iCAAiC;EACjC,yBAAyB;EACzB,kBAAkB;EAClB,0BAA0B;EAC1B,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,SAAS;EACT,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,kEAAkE;EAClE,YAAY;EACZ,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,sCAAoD;AACtD;;AAEA;EACE,kCAAkC;EAClC,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,2CAA2C;EAC3C,wCAAwC;AAC1C","sourcesContent":[".frame-form-div {\n  border: 1px solid rgb(186, 186, 186);\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.frame-label{\n  cursor: pointer;\n  width: 100%;\n}\n\nfooter {\n  background-color: rgb(50, 62, 74);\n  color: rgb(255, 255, 255);\n  padding-left: 1rem;\n  text-decoration: underline;\n  width: 100%;\n}\n\nheader {\n  display: flex;\n  justify-content: space-between;\n  padding: 1.5rem;\n}\n\nheader label, .delete-confirmation > a {\n  color: rgb(28, 153, 251);\n  font-weight: bold;\n  margin: 0;\n  text-decoration: underline;\n}\n\nheader > i {\n  background-size: 100%;\n  width: 1.5rem;\n}\n\nimg {\n  filter: brightness(0.5) sepia(1) hue-rotate(167deg) saturate(275%);\n  height: 1rem;\n  margin-left: 0.5rem;\n  width: 1rem;\n}\n\nimg.rotate {\n  transform: rotate(-180deg) translate(0rem, 0px);\n}\n\nlabel span {\n  background-image: url(\"assets/images/drop-icon.svg\");\n}\n\nmain {\n  background-color: unset !important;\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n  padding: 1rem;\n}\n\n.hidden {\n  display: none;\n}\n\n.box-shadow {\n  border-bottom: 1px solid rgb(186, 186, 186);\n  box-shadow: 0 3px 4px rgb(186, 186, 186);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
