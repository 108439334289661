export class LocationModel {

    constructor(){}

       id:number=0;
       address1:string='';
       address2:string='';
       city:string='';
       state:string='';
       zipCode:string='';
       phoneNumber:number=null;
       faxNumber:number=null;
       email:string='';
       locationName:string='';
       googleBusinessName:string='';
       neighborhoodBuilding:string='';
       altPhone:number=null;
       picture:string='';
       hasHours:boolean=false;
       lstLocationWorkingHours=[
        {
            id:0,
            locationId:0,
            day:'Monday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Tuesday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Wednesday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Thursday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Friday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Saturday',
            openTime:'Closed',
            closeTime:'Closed'
        },{
            id:0,
            locationId:0,
            day:'Sunday',
            openTime:'Closed',
            closeTime:'Closed'
        }
    ]

    
}
