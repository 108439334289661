// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wizard-content {
  max-width: unset !important;
}
.site-input-container {
  margin-bottom: 25px; /* adjust this value to match the desired space */
}

.button-container {
  margin-bottom: 25px; /* adjust this value to match the desired space */
}`, "",{"version":3,"sources":["webpack://./src/app/components/admin/users/list/list.component.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;AACA;EACE,mBAAmB,EAAE,iDAAiD;AACxE;;AAEA;EACE,mBAAmB,EAAE,iDAAiD;AACxE","sourcesContent":[".wizard-content {\n  max-width: unset !important;\n}\n.site-input-container {\n  margin-bottom: 25px; /* adjust this value to match the desired space */\n}\n\n.button-container {\n  margin-bottom: 25px; /* adjust this value to match the desired space */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
