import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTagsByFormId',
})
export class FilterTagsByFormId implements PipeTransform {
  transform(tags: any[], formId: number, formType: number): any {
    if(formType == 1){
      return tags.filter(tag => tag.ll_business_id == formId);
    }
    if(formType == 2){
      return tags.filter(tag => tag.ll_location_id == formId);
    }
  }
}
