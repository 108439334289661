import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { from } from "rxjs";
import { NgbActiveModal, NgbTypeaheadConfig } from "@ng-bootstrap/ng-bootstrap";
import { SiteService } from "../../../service/site.service";
import { DataService } from "../../../../../shared/data-service.service";
import { MasterService } from "../../../../admin/masters/services/master.service";
import { PhonePipe } from "@pipes/phone/phone.pipe";
@Component({
  selector: "app-add-call-tracking",
  templateUrl: "./add-call-tracking.component.html",
  styleUrls: ["./add-call-tracking.component.css"],
})
export class AddCallTrackingComponent implements OnInit {
  siteId;
  callTrackingForm: UntypedFormGroup;
  modalService;
  existingData;
  adSources;
  constructor(
    private formBuilder: UntypedFormBuilder, 
    public activeModal: NgbActiveModal,
    private router: Router,
    private toastrService: ToastrService,
    private siteService: SiteService,
    private dataService: DataService,
    private masterService: MasterService,
    private phonePipe: PhonePipe,
  ) {}

  ngOnInit() {
    this.getAdSourceData();
  }

  preprocessPhoneNumber(phoneNumber: string): string {
    return phoneNumber ? phoneNumber.replace(/\D/g, '').slice(0, 10) : null;
  }
  
  initializeForm() {
    this.callTrackingForm = this.formBuilder.group({
      id: [this.existingData ? this.existingData.id : 0],
      siteId: [this.existingData ? this.existingData.siteId : this.siteId],
      customerName: [
        this.existingData ? this.existingData.customerName : null,
        [Validators.required],
      ],
      rawTargetNumber: [
        this.existingData ? this.preprocessPhoneNumber(this.existingData.targetNumber) : null,
        [(control: AbstractControl) => {
          if (control.value === null || control.value === '') {
            return null; // valid if empty
          }
          return /^\d{10}$/.test(control.value) ? null : { 'invalidPattern': true };
        }],
      ],
      targetNumber: [this.existingData ? this.existingData.targetNumber : null],
      rawTrackNumber: [
        this.existingData ? this.preprocessPhoneNumber(this.existingData.trackNumber) : null,
        [(control: AbstractControl) => {
          if (control.value === null || control.value === '') {
            return null; // valid if empty
          }
          return /^\d{10}$/.test(control.value) ? null : { 'invalidPattern': true };
        }],
      ],
      trackNumber: [this.existingData ? this.existingData.trackNumber : null],
      aDSource: [
        this.existingData ? this.existingData.adSource : "",
        [Validators.required],
      ],
      customerCode: [
        this.existingData ? this.existingData.customerCode : null,
        [Validators.required],
      ],
      campaignID: [this.existingData ? this.existingData.campaignID : null],
      isReplacementNotRequired: [
        this.existingData ? this.existingData.isReplacementNotRequired : 0,
      ],
      isActive: [this.existingData ? this.existingData.isActive : true],
    });
  }
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
  addCallTracking(form) {
    if (form.valid) {
      const unformattedTargetNumber = form.value.targetNumber.replace(
        /\D/g,
        ""
      ); // Remove non-numeric characters
      const unformattedTrackNumber = form.value.trackNumber.replace(/\D/g, ""); // Remove non-numeric characters

      form.value.targetNumber = unformattedTargetNumber; //sets the unformatted target number to the  value to send to the toastr service
      form.value.trackNumber = unformattedTrackNumber; //sets the unformatted track number to the  value to send to the toastr service

      this.siteService.CallSourceCallTracking(form.value).subscribe(
        (res) => {
          if (res) {
            this.activeModal.close();
            this.toastrService.success("Added Successfully", "success");
          }
        },
        (err) => {
          this.toastrService.error(err.error);
        }
      );
    }
  }
  lastValidInput = '';
  onInputFormatPhone(event: Event, formattedControlName: string, rawControlName: string): void {
    const inputElement = event.target as HTMLInputElement;
  
    // Restrict input to digits, parentheses, and hyphens
    const cleanInput = inputElement.value.replace(/[^\d()-]/g, '');
    inputElement.value = cleanInput;
  
    // Use only digits for the raw value
    let rawValue = cleanInput.replace(/\D/g, '');
  
    if (rawValue.length <= 10) {
      this.callTrackingForm.get(rawControlName).setValue(rawValue, { emitEvent: false });
      this.callTrackingForm.get(rawControlName).updateValueAndValidity(); // Trigger validation check
  
      if (rawValue.length === 10) {
        const formattedValue = this.phonePipe.transform(rawValue);
        inputElement.value = formattedValue; // Update with formatted value
      }
    }
  
    // Mark as touched
    this.callTrackingForm.get(rawControlName).markAsTouched();
  }


  tenDigitsOrNullValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const value = control.value;
      const isValid = value === null || value.match(/^\d{10}$/);
      return isValid ? null : { 'invalidNumber': { value } };
    }
  }

  getAdSourceData() {
    this.masterService.getMastersListById(45).subscribe(
      (res: any) => {
        if (res) {
          this.adSources = res["masterList"];
          this.initializeForm();
        }
      },
      (err) => {
        this.toastrService.error(err.error);
      }
    );
  }
}
