import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import { DataService } from '@shared/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { LocationModel } from '../models/location';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { HttpService } from '../../../../../../../services/http.service';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-locatoin',
  templateUrl: './locatoin.component.html',
  styleUrls: ['./locatoin.component.css']
})
export class LocatoinComponent implements OnInit {

  @ViewChild('formRef',null) locationForm: NgForm;
  hasHoursIndex: number = null;
  intakeId: number = null;
  createdPage = {};
  siteId: number = null;
  @Input() intakeTypeId;
  //intakeType: number = 1;
  locationList: Array<Object>; 
  newLocation = new LocationModel();
  imageSrc;
  baseUrl = environment.imgUrl;
  timeLists = []; 
  constructor(
    private intakesService: IntakesService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    public dataService: DataService,
    public modalService: NgbModal,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.siteId = params.siteId;
      }
    });
  }

  ngOnInit() {
    this.locationList= this.locationList || [];
    this.getAppointmentTime();
    //this.timeLists = this.intakesService.locationTimeLists;
    this.getLocation(this.intakeId, this.intakeTypeId);
  }
  displayHours(event, index) {
    if (event.target.checked) {
      this.hasHoursIndex = index;
    } else {
      this.hasHoursIndex = null;
    }

  };
  getLocation(intakeId, intakeType) {
    var hours=this.newLocation.lstLocationWorkingHours;
    this.intakesService.getLocation(intakeId, intakeType).subscribe((res: any) => {
      if (res) {
        this.locationList = res.lstLocation;
        if (this.locationList.length == 0)
          this.locationList.push(this.newLocation);
        else {
          _.forEach(this.locationList, function (loc) {
            loc.hasHours = false; 
            if (loc.lstLocationWorkingHours!=null && loc.lstLocationWorkingHours.length > 0 && loc.lstLocationWorkingHours[0].id > 0)
              loc.hasHours = true; 
              else{
                loc.lstLocationWorkingHours=hours;
              }
              
          })
        }
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  };

  addLocation() {
    if (this.locationList == null) {
      this.locationList = [];
    }
    this.newLocation = new LocationModel();
    this.locationList.push(this.newLocation);
  }
  deleteLocation(index) {
    this.locationList.splice(index, 1);
  }
  saveLocation(formData) {
    if (this.locationList) {

      let intakeLocation = {
        "intakeId": this.intakeId,
        "intakeType": this.intakeTypeId,
        "lstLocation": this.locationList
      }

      if (formData.valid) {
        this.intakesService.postLocation(intakeLocation).subscribe((response) => {
          if (response) {
            this.toastrService.success('Location added successfully', 'Success');
          }
        }, err => {
          this.httpService.openErrorPopup(err.error.message);
        });
      } else {
        this.httpService.openErrorPopup(this.dataService.requiredMsg);
        for (let i = 0; i < this.locationList.length; i++) {
          let location = `location${i}`;
          let address = `address1${i}`;
          let city = `city${i}`;
          let state = `state${i}`;
          let zipCode = `zipCode${i}`;
          let phoneNumber = `phoneNumber${i}`;
          formData.controls[location].markAsTouched();
          formData.controls[address].markAsTouched();
          formData.controls[city].markAsTouched();
          formData.controls[state].markAsTouched();
          formData.controls[zipCode].markAsTouched();
          formData.controls[phoneNumber].markAsTouched();
        }

      }
    }
  };
  openUploadedFilesPopup(index) {

    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = this.siteId;
    model.result.then((result) => {
      if (result) {
        this.imageSrc = result[0];
        _.forEach(this.locationList, (locationList, itemIndex) => {
          if (index === itemIndex) {
            locationList.picture = result[0]
          }
        })

      }
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }
  getAppointmentTime(){
    this.intakesService.getMaster(49).subscribe(res=>{
      if(res){        
        this.timeLists=this.TimesInBetween(res['masterList'][0]['name'],res['masterList'][1]['name'])
        console.log(this.timeLists);
      }
    },
    err=>{
      throw err;
    })
  }
  TimesInBetween(start, end) {
    let getGenTime = (timeString) => {
      let H = +timeString.substr(0, 2);
      let h = (H % 12) || 12;
      let ampm = H < 12 ? " AM" : " PM";
      return timeString = h + timeString.substr(2, 3) + ampm;
    }
    var timesInBetween = [];
  
    var startH = parseInt(start.split(":")[0]);
    var startM = parseInt(start.split(":")[1]);
    var endH = parseInt(end.split(":")[0]);
    var endM = parseInt(end.split(":")[1]);
  
    if (startM == 30)
      startH++;
  
    for (var i = startH; i < endH; i++) {
      timesInBetween.push(i < 10 ? "0" + i + ":00" : i + ":00");
      timesInBetween.push(i < 10 ? "0" + i + ":30" : i + ":30");
    }
  
    timesInBetween.push(endH + ":00");
    if (endM == 30)
      timesInBetween.push(endH + ":30")
  
    return timesInBetween.map(getGenTime);
  }

}