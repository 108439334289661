// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button:disabled {
  cursor: not-allowed;
}

.card-banner {
  background-color: green;
  border: 1px solid transparent;
  color: white;
  font-weight: bold;
  min-height: 3rem;
  margin: auto -1px -0.5rem -1px;
  position: relative;
  padding: 0.75rem 1.25rem;
  text-align: center;
}

.card-banner > p {
  margin-bottom: unset;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/frames/modal-frame/modal-frame.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,8BAA8B;EAC9B,kBAAkB;EAClB,wBAAwB;EACxB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["button:disabled {\n  cursor: not-allowed;\n}\n\n.card-banner {\n  background-color: green;\n  border: 1px solid transparent;\n  color: white;\n  font-weight: bold;\n  min-height: 3rem;\n  margin: auto -1px -0.5rem -1px;\n  position: relative;\n  padding: 0.75rem 1.25rem;\n  text-align: center;\n}\n\n.card-banner > p {\n  margin-bottom: unset;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
