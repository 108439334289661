import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportingService } from '../../services/reporting.service';
import * as _ from 'lodash';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { HttpService } from '../../../../services/http.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AddInsightDataComponent } from '../../add-insight-data/add-insight-data.component';

@Component({
  selector: 'app-keyword-ranking',
  templateUrl: './keyword-ranking.component.html',
  styleUrls: ['./keyword-ranking.component.css']
})
export class KeywordRankingComponent {
userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
@Input() siteId;
@Output() openPopup = new EventEmitter<any>();
semRushReport;
canvasWidth = 100;
quarterData;
download;
gaugeOptions = {
	hasNeedle: true,
	needleColor: "black",
	needleStartValue: 50,
	arcColors: ["rgb(221,221,221)","rgb(255,255,255)","rgb(22,181,166)"],
	arcDelimiters: [75,80],
	rangeLabelFontSize: 12,
};
reportParams = {
  quarter: moment().subtract(1, 'quarter').startOf('quarter').format('MM/DD/YY'),
  isRefreshData:false
};
  constructor(
    private reportingService: ReportingService,
    private httpService: HttpService,
    public modalService: NgbModal,
    private toastrService: ToastrService
  ) { }

  ngOnChanges(){
    this.getReport(this.siteId, this.reportParams);
  }

  ngOnInit() {
    this.quarterData = this.reportingService.getQuarters();
  }

  onValueChange(event) {
    this.reportParams['startDate'] = moment(event[0]).format('MM/DD/YYYY');
    this.reportParams['endDate'] = moment(event[1]).format('MM/DD/YYYY');
    this.getReport(this.siteId, this.reportParams);
  }

  getReport(siteId, params) {
    if (moment(params['quarter'], "MM/DD/YY", true).isValid()) {
      params['formattedStartDate'] = moment(params['quarter']).startOf('quarter').format('MM/DD/YYYY');
      params['formattedEndDate'] = moment(params['quarter']).endOf('quarter').format('MM/DD/YYYY');
    }

    this.reportingService.getSemRushReport(siteId, params).subscribe((res: any) => {
      if (res) {
        this.semRushReport = res;
        this.semRushReport['healthyPercentage'] = this.semRushReport.siteHealth.healthy / this.semRushReport.siteHealth.crowledPages * 100;
        this.semRushReport['brokenPercentage'] = this.semRushReport.siteHealth.broken / this.semRushReport.siteHealth.crowledPages * 100;
        this.semRushReport['haveIssuesPercentage'] = this.semRushReport.siteHealth.haveIssues / this.semRushReport.siteHealth.crowledPages * 100;
        this.semRushReport['redirectedPercentage'] = this.semRushReport.siteHealth.redirected / this.semRushReport.siteHealth.crowledPages * 100;
        this.semRushReport['blockedPercentage'] = this.semRushReport.siteHealth.blocked / this.semRushReport.siteHealth.crowledPages * 100;
        this.gaugeOptions.needleStartValue = this.semRushReport.siteHealth.totalScore;
      }
    },
      err => {
        this.semRushReport ={};
        this.httpService.openErrorPopup(err.error.message);
      });
  }
 setSEMData(res){

 }
  downloadReport(report: string) {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = 'none';
    document.getElementById("health-icon").style.display = 'none';
    document.getElementById("health-img").style.display = 'block';
    var element = document.getElementById('report');
    var collapsedElements = document.getElementsByClassName('collapse');
    var showLess = document.querySelectorAll('.show-less, .titleWithLink');
    var titleWithoutLink = document.querySelectorAll('.titleWithoutLink');
    _.forEach(collapsedElements, (element) => {
      element.className += ' show';
    })
    _.forEach(showLess, (element) => {
      element.style.display = 'none';
    })
    _.forEach(titleWithoutLink, (element) => {
      element.style.display = 'block';
    })
    var pagebreak ={ mode: '', before: '.before', after: '.after', avoid: '.avoid' };
    var opt = {
      margin: 0.3,
      filename: 'website-keyword-ranking.pdf',
      pagebreak: pagebreak,
      jsPDF: { unit: 'in', format: 'b3', orientation: 'portrait' }
    };
    if (report == 'print') {
      html2pdf().from(element).set(opt).output('dataurlnewwindow').then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        _.forEach(showLess, (element) => {
          element.style.display = 'block';
        });
        _.forEach(titleWithoutLink, (element) => {
          element.style.display = 'none';
        });
        document.getElementById("health-icon").style.display = 'block';
        document.getElementById("health-img").style.display = 'none';
      });
    } else {
      html2pdf().from(element).set(opt).save().then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        _.forEach(showLess, (element) => {
          element.style.display = 'block';
        });
        _.forEach(titleWithoutLink, (element) => {
          element.style.display = 'none';
        });
        document.getElementById("health-icon").style.display = 'block';
        document.getElementById("health-img").style.display = 'none';
      });
    }
  }

  openInsightPopup(){
    this.openPopup.next(this.reportParams);
  }
  refreshData(){
    this.reportParams.isRefreshData=true;
    this.getReport(this.siteId, this.reportParams);
  }

}
