// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-design .nav-tabs .nav-link.active {
    background: #4296F7;
    color: #fff !important;
    text-decoration: none;
}

@media screen {
  .wizard-content {
    max-width: unset !important;
  }
}

.small-icon {
  width: 96px;
  height: 96px;
}

.filename {
  font-size: 11px; /* Make the font smaller */
  width: 100%; /* Ensure the text takes the full width of its container */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

.panel-content .panel-head {
  background: #f5f6fa;
  height: 90px;
  padding: 30px 30px;
}

.panel-content .panel-paragraph {
  padding: 20px;
}

.panel-content .panel-input {
  padding: 20px;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.panel-input .compact-select {
  display: flex;
  align-items: center;
}

.panel-input .compact-select .input-group-text {
  margin-right: 10px;
  white-space: nowrap;
}

.panel-input .compact-select .form-select {
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/content-generation/content-generation.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;EACE;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe,EAAE,0BAA0B;EAC3C,WAAW,EAAE,0DAA0D;EACvE,gBAAgB,EAAE,uBAAuB;EACzC,uBAAuB,EAAE,mCAAmC;AAC9D;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,OAAO;AACT","sourcesContent":[".panel-design .nav-tabs .nav-link.active {\n    background: #4296F7;\n    color: #fff !important;\n    text-decoration: none;\n}\n\n@media screen {\n  .wizard-content {\n    max-width: unset !important;\n  }\n}\n\n.small-icon {\n  width: 96px;\n  height: 96px;\n}\n\n.filename {\n  font-size: 11px; /* Make the font smaller */\n  width: 100%; /* Ensure the text takes the full width of its container */\n  overflow: hidden; /* Hide overflow text */\n  text-overflow: ellipsis; /* Add ellipsis for overflow text */\n}\n\n.panel-content .panel-head {\n  background: #f5f6fa;\n  height: 90px;\n  padding: 30px 30px;\n}\n\n.panel-content .panel-paragraph {\n  padding: 20px;\n}\n\n.panel-content .panel-input {\n  padding: 20px;\n}\n\n.mb-3, .my-3 {\n  margin-bottom: 1rem !important;\n}\n\n.panel-input .compact-select {\n  display: flex;\n  align-items: center;\n}\n\n.panel-input .compact-select .input-group-text {\n  margin-right: 10px;\n  white-space: nowrap;\n}\n\n.panel-input .compact-select .form-select {\n  flex: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
