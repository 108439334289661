import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approval-popup',
  templateUrl: './approval-popup.component.html',
  styleUrls: ['./approval-popup.component.css'],
})
export class ApprovalPopupComponent implements OnInit {
  @Input() headerText = 'Discover Phase Approval';

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {}
}
