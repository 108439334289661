import {
  Component, ElementRef,
  Input,
  OnInit, Renderer2,
  SimpleChanges,
} from '@angular/core';
import { SiteInfo } from '@shared/interfaces/site';
import { EmptyUser, ImpersonateUserConfig, UserInfo, UserProfile } from '@shared/interfaces/user';
import { ActivatedRoute, Router } from '@angular/router';
import { roles } from '@shared/master-enum.enum';
import { UserService } from '../../../../components/admin/users/services/user-service.service';
import { TicketService } from '../../../../components/ticket/service/ticket.service';
import * as _ from 'lodash';
import { WordPressHelper } from '@shared/utilities/WordPressUtilities';
import { hasPermission } from '@shared/policies/permissions';
import { SiteService } from '../../../../components/site/service/site.service';

@Component({
  selector: 'app-glyphs-client-actions',
  templateUrl: './glyphs-client-actions.component.html',
  styleUrls: ['./glyphs-client-actions.component.css'],
})
export class GlyphsClientActionsComponent implements OnInit {
  @Input() currentUser ?: EmptyUser|UserInfo;
  @Input() enabled = true;
  @Input() parentInfo;
  @Input() site: Partial<SiteInfo>;
  @Input() showBilling = true
  @Input() showConfig = true;
  @Input() showImpersonation = false;
  @Input() showJira = true;
  @Input() showReports = true;
  @Input() showWordPress = true;

  private jiraURL: string;
  private jiraProjectKey: string;
  private userRole: string;

  public hasPermission = hasPermission;
  public userRoleId: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private siteService: SiteService,
    private ticketService: TicketService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.userRole = this.userService.getCurrentUserRole();

    this.userRoleId = Number(this.currentUser.roleId);
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.hasOwnProperty('site')) {
      if (!this.site && change.site.currentValue) {
        this.site = change.site.currentValue.siteId;
      }

      if (this.site && !this.site.hasOwnProperty('id')) {
        this.site = this.siteService.normalizeIds(this.site);
      }
    }
  }

  /**
   * Returns true if user impersonation is possible
   *
   * @return {Boolean} True, if it is possible to impersonate another user
   */
  get canImpersonateUser(): boolean {
    if (this.enabled && this.site.userId > 0) {
      return hasPermission(this.userRoleId, 'IMPERSONATE_USER') && !this.parentInfo;
    }

    return false;
  }

  /**
   * Returns true if the provided site has a WordPress site path attached
   * @return {Boolean} True, if there is a WordPress site path
   */
  get hasWPSite(): boolean {
    return (
      this.site &&
        this.site.hasOwnProperty('wpSitePath') &&
        this.site.wpSitePath &&
        this.site.wpSitePath != ''
    );
  }

  /**
   * Retrievs the information needed to impersonate a user
   *
   * @param {ImpersonateUserConfig} user
   */
  getUserProfile(user: ImpersonateUserConfig) {
    this.userService.getUserProfile(user.userId).subscribe(
      (res: UserProfile) => {
        if (res) {
          this.userService.setUserProfile(res);

          // If a user is a part of multiple sites
          if (res.site.length && user.roleId != roles.corporatePartner.roleId) {
            const impersonateSite = _.find(res.site, (site) => {
              return site.siteId == localStorage.getItem('impersonateSite');
            });

            const site = {
              siteId: impersonateSite.siteId || res.site[0].siteId,
              domainUrl: impersonateSite.domainUrl || res.site[0].domainUrl,
            };

            localStorage.setItem('selectedSite', JSON.stringify(site));
          }

          this.goToDashboard(user);
        }
      },
      (err) => {
        throw err;
      }
    );
  }

  /**
   * Navigates to the Billing page
   *
   * @param {SiteInfo} site
   */
  goToBillingSection(site): void {
    localStorage.setItem('selectedSite', JSON.stringify(site));
    this.router.navigateByUrl(`${this.userService.currentUserRole}/billing`);
  }

  /**
   * Redirects user to the client dashboard
   * @TODO finish and test method
   * @param res
   */
  goToDashboard(res) {
    if (false) {
      // this.router.navigateByUrl(this.returnUrl);
    } else {
      const role = _.find(roles, (role) => {
        return role.roleId == res.roleId;
      });
      this.router.navigateByUrl(role.url);
    }
  }

  /**
   * Opens the JIRA project associate with the given site
   *
   * @param {SiteInfo} site
   */
  goToJiraDashboard(site: SiteInfo): void {
    this.ticketService.getJiraUrl().subscribe(
      (res) => {
        if (res) {
          this.jiraURL = `${res['url']}/jira/core`;
          this.jiraProjectKey = res['ProjectKey'];

          window.open(
            `${this.jiraURL}/projects/${this.jiraProjectKey}${site.id}/board`,
            '_blank'
          );
        }
      },
      (err) => {
        throw err;
      }
    );
  }

  /**
   * Redirects the user to the Reports page for the given site
   *
   * @param {SiteInfo} site The data object for the selected site
   */
  goToReports(site: SiteInfo): void {
    localStorage.setItem('selectedSite', JSON.stringify(site));


    this.router.navigateByUrl(`${this.userRole}/client-reports`);
  }

  /**
   * Allows the currently logged in user to browse the application as a sites default user
   *
   * @param {SiteInfo} site The configuration information for the target user's site
   */
  impersonateUser(site: SiteInfo): void {
    localStorage.setItem('parentInfo', localStorage.getItem('userInfo'));

    const data = {
      userId: site.userId,
    };

    this.userService.impersonateUser(data).subscribe(
      (userConfig: ImpersonateUserConfig) => {
        if (userConfig) {
          localStorage.setItem('userInfo', JSON.stringify(userConfig));
          localStorage.setItem('impersonateSite', JSON.stringify(site.id));
          localStorage.setItem('selectedSite', JSON.stringify(site));
          this.getUserProfile(userConfig);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  /**
   * Generates a hidden form that opens the WordPress site in another tab
   *
   * @param {SiteInfo} site
   */
  logInToWordPress(site: SiteInfo): void {
    if (this.enabled && this.hasWPSite) {
      const WordPress = new WordPressHelper(
        this.el,
        this.renderer,
        this.userService
      );

      WordPress.logInToWordPress(this.currentUser, site);
    }
  }

  /**
   * Redirecrts to the config page for the currently selected site
   *
   * @param {SiteInfo} site The currently selected site
   */
  viewSiteConfig(site): void {
    this.router.navigate(
      ['../site-config/', site.id],
      { relativeTo: this.activatedRoute }
    );
  }
}
