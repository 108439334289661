import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-semrush-icons',
  templateUrl: './semrush-icons.component.html',
  styleUrls: ['./semrush-icons.component.css'],
})
export class SemrushIconsComponent implements OnInit {
  @Input() features: Array<string> = [];

  public hiddenFeatures = [
    'SEMrush/org.svg',
  ];

  constructor() { }

  ngOnInit(): void {}
}
