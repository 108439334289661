import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from './services/login.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { roles } from '@shared/master-enum.enum';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../admin/users/services/user-service.service';
import { HttpService } from '../../services/http.service';
import { AlertPopupComponent } from '@shared/alert-popup/alert-popup.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  currentYear: number;
  email;
  loginForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private loginService: LoginService,
    private httpService: HttpService,
    private userService: UserService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) { }
  returnUrl;
  show:boolean=false;
  iconText:string='Show';

  ngOnInit() {
    const d = new Date();
    this.currentYear = d.getFullYear();

    let queryString = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
    if(queryString && queryString.includes('email=')){
      this.email = queryString.split('email=')[1].replace("%20", "+");
    }
    this.initializeForm();
    if (localStorage.getItem('userInfo')) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.getUserProfile(userInfo);
    }else{
      this.activeModal.close();
    }
  }

  initializeForm() {
    this.loginForm = this.formBuilder.group({
      username: [this.email || '', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  login(form) {
    if(localStorage.getItem('userInfo')){
      this.router.navigateByUrl('dashboard');
    }else if (form.valid) {
      this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
      const loginInfo = form.value;
      this.loginService.validateUser(loginInfo).subscribe(res => {
        if (res) {
          this.getUserProfile(res);
        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.error);
          // this.toastrService.error(err.error.error, 'Error');
        });
    }
  }

  getUserProfile(user) {
    this.userService.getUserProfile(user.userId).subscribe((res) => {
      if (res) {
        this.userService.UserProfile = res;
        let site;
        if (res.site.length && user.roleId != roles.corporatePartner.roleId && user.roleId != roles.corporatePartnerUser.roleId ) {
          let impersonateSite = _.find(res.site, (site) => {
            return site.siteId == localStorage.getItem('impersonateSite');
          })
          site = {
            siteId: impersonateSite ? impersonateSite.siteId : res.site[0].siteId,
            domainUrl: impersonateSite ? impersonateSite.domainUrl : res.site[0].domainUrl
          }
          localStorage.setItem('selectedSite', JSON.stringify(site));
        }
        this.goToDashboard(user);
      }
    },
      err => {
        throw err;
      })
  }

  goToDashboard(res) {
    if (this.returnUrl)
      this.router.navigateByUrl(this.returnUrl);
    else {
      let role = _.find(roles, (role) => {
        return role.roleId == res.roleId;
      })
      this.router.navigateByUrl(role.url);
    }
  }

  showPassword(){
   if(this.show){
     this.show=false;
     this.iconText='Show';
   }else{
    this.show=true;
    this.iconText='Hide';
   }
  }




}
