// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aud-input {
  padding: 0.5rem 1rem !important;
  width: 25rem;
}

.select-domain {
  max-width: unset;
}

.tab-panel-wrap .nav-tabs .nav-link:hover {
  background: #415164;
  color: #fff;
  text-decoration: underline;
}

.tab-panel-wrap .nav-tabs .nav-item.show .nav-link, .tab-panel-wrap .nav-tabs .nav-link.active {
  background: #4296F7;
  color: #fff;
}

.wizard-content {
  max-width: unset;
}
.custom-file{height:auto;}
.image-upload-section{padding:18px 20px 12px 18px;}
.image-upload-section .inside-box{width:175px; height:205px; margin:0 14px 14px 0; padding-bottom: 30px;}
.image-upload-section .inside-box img{height:100%; width:100%;}
.image-upload-section .inside-box:nth-child(4n){margin-right:0;}

.custom-file-lg{
    z-index: 1 !important;
    right:14px;
    width: 100%;
}

.close{
    padding: 8px 15px;
    position: absolute;
    right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/reporting/report.component.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;AACA,aAAa,WAAW,CAAC;AACzB,sBAAsB,2BAA2B,CAAC;AAClD,kCAAkC,WAAW,EAAE,YAAY,EAAE,oBAAoB,EAAE,oBAAoB,CAAC;AACxG,sCAAsC,WAAW,EAAE,UAAU,CAAC;AAC9D,gDAAgD,cAAc,CAAC;;AAE/D;IACI,qBAAqB;IACrB,UAAU;IACV,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,QAAQ;AACZ","sourcesContent":[".aud-input {\n  padding: 0.5rem 1rem !important;\n  width: 25rem;\n}\n\n.select-domain {\n  max-width: unset;\n}\n\n.tab-panel-wrap .nav-tabs .nav-link:hover {\n  background: #415164;\n  color: #fff;\n  text-decoration: underline;\n}\n\n.tab-panel-wrap .nav-tabs .nav-item.show .nav-link, .tab-panel-wrap .nav-tabs .nav-link.active {\n  background: #4296F7;\n  color: #fff;\n}\n\n.wizard-content {\n  max-width: unset;\n}\n.custom-file{height:auto;}\n.image-upload-section{padding:18px 20px 12px 18px;}\n.image-upload-section .inside-box{width:175px; height:205px; margin:0 14px 14px 0; padding-bottom: 30px;}\n.image-upload-section .inside-box img{height:100%; width:100%;}\n.image-upload-section .inside-box:nth-child(4n){margin-right:0;}\n\n.custom-file-lg{\n    z-index: 1 !important;\n    right:14px;\n    width: 100%;\n}\n\n.close{\n    padding: 8px 15px;\n    position: absolute;\n    right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
