// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen {
  :host ::ng-deep hr {
    margin: 1rem auto;
    width: 95%;
  }

  .panel-frame {
    background-color: white;
    border: #D2D2D7 1px solid;
    margin-bottom: 20px;
  }
}

`, "",{"version":3,"sources":["webpack://./src/app/shared/components/panel-frame/panel-frame.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,iBAAiB;IACjB,UAAU;EACZ;;EAEA;IACE,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;EACrB;AACF","sourcesContent":["@media screen {\n  :host ::ng-deep hr {\n    margin: 1rem auto;\n    width: 95%;\n  }\n\n  .panel-frame {\n    background-color: white;\n    border: #D2D2D7 1px solid;\n    margin-bottom: 20px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
