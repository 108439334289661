import { Component, OnInit } from '@angular/core';
import { ManageCustomerService } from '../services/manage-customer.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {
  cpCustomers;
  userInfo;
  baseUrl = environment.imgUrl;
  constructor(
    private manageCustomerService: ManageCustomerService,
    private router: Router,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getCustomerList(this.userInfo.userId);
  }

  getCustomerList(userId){
    this.manageCustomerService.getCustomerList(userId).subscribe((res)=>{
      if(res){
        this.cpCustomers=res;
      }
    },
    err=>{
      this.toastrService.error(err.error.message,'Error');
    })
  }

  goToDetails(customer){
    this.router.navigate([`/corporate-partner/client-details/${customer.id}`]);
  }

  impersonate(customer){
    let cpInfo = _.cloneDeep(localStorage.getItem('userInfo'));
    let data = {
      roleId: '11',
      userId: customer.id,
      token: JSON.parse(localStorage.getItem('userInfo')).token
    }
    localStorage.setItem('userInfo', JSON.stringify(data));
    localStorage.setItem('cpInfo', cpInfo);
    this.router.navigate(['/client/dashboard']);
  }

}
