// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep app-secondary-button {
  float: right;
  margin-right: 1rem;
}

.shipping-address-text {
  font-weight: bold;
  margin-top: 1.4rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/site/create-invoice/create-invoice.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[":host ::ng-deep app-secondary-button {\n  float: right;\n  margin-right: 1rem;\n}\n\n.shipping-address-text {\n  font-weight: bold;\n  margin-top: 1.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
