import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import { tap } from 'rxjs/operators';
import { UploadedFile } from '@shared/models/UploadedFile';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  uploadedFiles = {};
  constructor(
    private httpService: HttpService
  ) { }

  getUploadedfilesData(siteId): Observable<any[]> {
    return this.httpService.getApi(`FileUpload/SiteFile/${siteId}`)
    .pipe(
      tap(res => {
        if(res){
          return res;
        }

    })
  );
  }

  uploadFile(data): Observable<any[]> {

    return this.httpService.postApi('FileUpload/SiteFile', data, {
      reportProgress: true // for progress data
    })
    .pipe(
      tap(res => {
        if(res){
          return res;
        }

    })
  );
  }

  getUploadedFileData(filePath: string): Observable<UploadedFile> {
    return this.httpService.getApi(`FileUpload/SiteFileData?filePath=${filePath}`)
    .pipe(
      tap(res => {
        if(res) {
          return res[0];
        }
      })
    )
  }

  getMultipleUploadedFileData(multipleFilePath: string): Observable<UploadedFile> {
    return this.httpService.getApi(`FileUpload/MultipleSiteFileData?multipleFilePath=${multipleFilePath}`)
    .pipe(
      tap(res => {
        if(res) {
          return res[0];
        }
      })
    )
  }

  b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

}
