import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvoiceProduct, InvoiceResponse, InvoiceSalesTaxData } from '@shared/interfaces/invoices';
import { HttpService } from '@services/http.service';
import { DataResponse } from '@shared/interfaces/responses';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private httpService: HttpService) { }

  /**
   * Returns an Observable containing the list of invoice products
   *
   * @return An observable containing an array of invoice products. It is wrapped in a custom
   * response object from the API
   *
   * @see {DataResponse} in responses.ts
   */
  getProducts(): Observable<DataResponse<InvoiceProduct>> {
    return this.httpService.getApi('invoice/products');
  }

  getSalesTax(invoiceData: InvoiceSalesTaxData|any): Observable<InvoiceResponse> {
    return this.httpService.postApi('invoice/salestax', invoiceData);
  }
      
  getStripeInvoiceUrl(invoiceId: string): Observable<any> {
    return this.httpService.getApi(`stripe/GetStripeInvoiceURL?stripeInvoiceId=${invoiceId}`);
        
  }
}
