import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-intake-sidebar-header',
  templateUrl: './intake-sidebar-header.component.html',
  styleUrls: ['./intake-sidebar-header.component.css'],
})
export class IntakeSidebarHeaderComponent implements OnInit {
  @Input() icon: string;
  @Input() label: string;

  constructor() { }

  ngOnInit(): void {}
}
