import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { IntakesService } from '../../../services/intakes.service';
import { Ppc } from '../../../models/intakePpc';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { SectionStatus } from '../../basic/review/section-model';
import { DataService } from '@shared/data-service.service';
import {ClientInfoModel} from '../client-information/clientInfo-model';
import { HttpService } from '../../../../../../services/http.service';
import moment from 'moment';
@Component({
  selector: 'app-campaign-information',
  templateUrl: './campaign-information.component.html',
  styleUrls: ['./campaign-information.component.css']
})
export class CampaignInformationComponent implements OnInit {
  campaignInfoForm: UntypedFormGroup;
  selectedIntakeData;
  intakeId;
  siteId;
  @Input() productType;
  @Input() existingData;
  @Input() sectionId;
  @Input() intakeTypeId;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() changeStatus= new EventEmitter<any>();
  minStartDate:Date;
  minEndDate:Date;
  clientInfoModel=new ClientInfoModel();
  constructor(
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    public dataService:DataService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.siteId = params.siteId;

      }
    });
    this.minStartDate=new Date();
    this.minEndDate=new Date();
  }

  ngOnInit() {
    if(this.existingData.lstPPCLocation==null){
      this.existingData.lstPPCLocation=[];
      this.existingData.lstPPCLocation.push(this.clientInfoModel);
    }
    this.initializeForm();
  }

  onValueChange(value: Date): void {
    if(value==null){
      //this.minEndDate=new Date();
    }else{
      this.minEndDate=value;
    }
    if(this.minEndDate > this.campaignInfoForm.controls.campaign1StartDate.value){
      this.campaignInfoForm.controls.campaign1EndDate.setValue(null);
    }
  }

  initializeForm(){
    this.campaignInfoForm = this.formBuilder.group({
      intakeId : this.intakeId ? this.intakeId : null,
      intakeTypeId : this.intakeTypeId ? this.intakeTypeId : null,
      productType : new UntypedFormControl(this.productType.toUpperCase()),
      campaign1StartDate : new UntypedFormControl(this.existingData && this.existingData.campaign1StartDate ? new Date(this.existingData.campaign1StartDate):null),
      campaign1EndDate : new UntypedFormControl(this.existingData && this.existingData.campaign1EndDate && this.existingData.campaign1EndDate != 'ongoing' ? new Date(this.existingData.campaign1EndDate):null),
      campaign1Budget : new UntypedFormControl(this.existingData ? this.existingData.campaign1Budget : null, Validators.pattern(/^[.\d]+$/)),
      campaign2Budget : new UntypedFormControl(this.existingData ? this.existingData.campaign2Budget : null, Validators.pattern(/^[.\d]+$/)),
      targetDemographic :  new UntypedFormControl(this.existingData ? this.existingData.targetDemographic : null),
      targetGeographicZone :  new UntypedFormControl(this.existingData ? this.existingData.targetGeographicZone : null),
      previousCampaignHistory : new UntypedFormControl(this.existingData ? this.existingData.previousCampaignHistory : null),
      isActiveAccount : new UntypedFormControl(this.existingData ? this.existingData.isActiveAccount : null),
      facebookBusinessUrl : new UntypedFormControl(this.existingData ? this.existingData.facebookBusinessUrl : null, Validators.pattern(this.dataService.urlRegex)),
      additionalNotes : new UntypedFormControl(this.existingData ? this.existingData.additionalNotes : null),
      topSellingServices : new UntypedFormControl(this.existingData ? this.existingData.topSellingServices : null),
      promotion : new UntypedFormControl(this.existingData ? this.existingData.promotion : null),
      isCampaignOngoing : new UntypedFormControl(this.existingData.campaign1EndDate == 'ongoing' ? true : this.existingData.campaign1EndDate ? false : null),
      // focusOnService : new FormControl(this.existingData ? this.existingData.focusOnService : null),
      // costPerLead : new FormControl(this.existingData ? this.existingData.costPerLead : null),
      // leadConversionRate : new FormControl(this.existingData ? this.existingData.leadConversionRate : null),
      // averageRevenue : new FormControl(this.existingData ? this.existingData.averageRevenue : null),
      // averageProfitMargin : new FormControl(this.existingData ? this.existingData.averageProfitMargin : null)
    });
  }

  save(form,mode){
    if(form.value.isCampaignOngoing){
      this.campaignInfoForm.controls.campaign1EndDate.setValue(null);
    }
    if(form.valid){
      let intakeData =form.value;
      intakeData.campaign1StartDate = form.value.campaign1StartDate ? moment(form.value.campaign1StartDate).format('YYYY-MM-DD') : null;
      intakeData.campaign1EndDate = intakeData.isCampaignOngoing ? 'ongoing' : form.value.campaign1EndDate ? moment(form.value.campaign1EndDate).format('YYYY-MM-DD') : null;

      _.assign(this.existingData, intakeData);
      this.intakesService.savePpcIntake(this.existingData).subscribe(res => {
        if (res) {
          this.changeStatus.next();
          this.toastrService.success('Saved successfully', 'Success');
          this.intakesService.updateRequiredFieldsStatus(intakeData);
          let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, this.intakeId);
          this.postIntakeSection(sectionStatus);
          if(mode == 'continue'){
            this.goToNext();
          }
        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
    }else{
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        // this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }
  goBack() {
    this.backClicked.next();
  }

  goToNext(){
    this.continueClicked.next();
  }
}
