import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'na'
})
export class NaPipe implements PipeTransform {

  transform(value: string, emptyText?: string): string {
    if (!emptyText) {
      emptyText = 'N/A';
    }

    return value ? value : emptyText;
  }

}
