import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpService } from '../../../services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';
import { masterEnum } from '@shared/master-enum.enum';
@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private httpService: HttpService
  ) {
  }
  getTickets(params) {
    return this.httpService.getApi(`JIRA/GetTicketList?TicketType=${params.ticketType || ''}&Project=${params.project || ''}&Status=${params.status || ''}&Queue=${params.queue || ''}&PageNo=${params.pageNo || 0}&siteId=${params.siteId || ''}&startDate=${params.startDate || ''}&endDate=${params.endDate || ''}&Subject=${params.subject || ''}&Domain=${params.domain || ''}
    `)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      )
  }
  createTicket(data) {
    return this.httpService.postApi('JIRA/CreateTicket', data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      )
  }
  getTicketDetail(ticketId) {
    return this.httpService.getApi('JIRA/GetTicket/' + ticketId)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      )
  }

  getJiraStatusData() {
    return this.httpService.getApi('Jira/GetAllStatus')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      )
  }
  addComent(data) {
    return this.httpService.postApi('JIRA/AddComment', data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      )
  }
  getJiraUrl(){
    return this.httpService.getApi(`JIRA/jiraurl`)
    .pipe(
      tap(res=>{
        if(res){
          return res;
        }
      })
    )
  }

  downloadAttachment(attachment){
    return this.httpService.getApi(`jira/DownloadAttachment?attachmentId=${attachment.id}&filename=${attachment.filename}`,false)
    .pipe(
      tap(res=>{
        if(res){
          return res;
        }
      })
    )
  }
  deleteAttachment(attachmentId){
    return this.httpService.deleteApi(`jira/DeleteAttachment/${attachmentId}`)
    .pipe(
      tap(res=>{
        if(res){
          return res;
        }
      })
    )
  }

}
