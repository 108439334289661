import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpDashboardComponent } from './dashboard/dashboard.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { RouterModule } from '@angular/router';
import { CustomerListComponent } from './manage-customer/customer-list/customer-list.component';
import { RequestListComponent } from './audit-request/request-list/request-list.component';
import { SharedModule } from '@shared/shared.module';
import {  BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CustomerDetailsComponent } from './manage-customer/customer-details/customer-details.component';
import {AuditRequestComponent} from './audit-request/audit-request/audit-request.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerModule } from "../customer/customer.module";
import { AuditDetailComponent } from './audit-request/audit-detail/audit-detail.component';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';


@NgModule({
    imports: [
        CommonModule,
        CustomerModule,
        ReactiveFormsModule,
        AngularEditorModule,
        NgbModule,
        TableModule,
        BsDatepickerModule.forRoot(),
        RouterModule,
        SharedModule,
        PaginatorModule,
    ],
    declarations: [
        CpDashboardComponent,
        CustomerListComponent,
        CustomerDetailsComponent,
        RequestListComponent,
        AuditRequestComponent,
        AuditDetailComponent,
    ]
})
export class CorporatePartnerModule { }
