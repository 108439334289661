import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'convertToHours' })
export class ConvertToHoursPipe implements PipeTransform {
  transform(value: any): any {
    // Assuming 'value' is the number of seconds
    const duration = moment.duration(value, 'seconds');
    const formattedDuration = moment().startOf('day').add(duration).format('HH:mm:ss');
    return formattedDuration;
  }
}