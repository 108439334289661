import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmDashboardComponent } from './dashboard/dashboard.component';
import { SitesComponent } from './sites/sites.component';
import { IntakesComponent } from './intakes/intakeList/intakes.component';
import { routing } from '../pages.routing';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IntakeProductListComponent } from './intakes/intake-product-list/intake-product-list.component';
import { InitiatePopupComponent } from './intakes/initiate-popup/initiate-popup.component';
import { TicketModule} from '../ticket/ticket.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerModule } from '../customer/customer.module';
import { SiteModule } from '../site/site.module';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    imports: [
        routing,
        CommonModule,
        ReactiveFormsModule,
        CustomerModule,
        FormsModule,
        TicketModule,
        BsDatepickerModule.forRoot(),
        NgbModule,
        SiteModule,
        SharedModule,
    ],
    declarations: [
        SitesComponent,
        IntakesComponent,
        AmDashboardComponent,
        IntakeProductListComponent,
        InitiatePopupComponent,
    ]
})

export class AccountManagerModule { }
