import { Component, OnInit, ViewChild } from '@angular/core';
import { DownloadsService } from '../downloads.service';
import { HttpService } from '@services/http.service';
import { ExcelService } from '../../reporting/services/excel.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-gmb-downloads',
  templateUrl: './gmb-downloads.component.html',
  styleUrls: ['./gmb-downloads.component.css'],
})
export class GmbDownloadsComponent implements OnInit, PageHasTable {
  accounts;
  account;
  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  locations;
  loc;
  filterBy = {name: null, city: null};

  @ViewChild('dt') table: TableGenericComponent;

  constructor(
    private downloadsService: DownloadsService,
    private httpService: HttpService,
    private excelService: ExcelService
  ) { }

  ngOnInit() {
    this.getAccounts();

    this.getTableData();
  }

  getAccounts(){
    this.downloadsService.getGmbAccountsData().subscribe((res: any) => {
      if (res) {
        this.accounts = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getLocationData(accountId){
    this.filterBy = {name:null,city:null};
    this.locations=[];
    this.downloadsService.getGmbLocationsData(accountId).subscribe((res: any) => {
      if (res) {
        this.loc =res;
        this.locations = Object.assign([], this.loc);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnHeaders = [
      {field: 'id', header: 'ID', filter: 'none'},
      {field: 'name', header: 'Name'},
      {field: 'city', header: 'City'},
    ];
  }
}
