import { Injectable } from '@angular/core';
import { Promotion } from '@shared/interfaces/login';
import promotions from '../../../components/login/promotions.json';

@Injectable({
  providedIn: 'root',
})
export class PromotionsService {
  constructor() { }

  /**
   * Returns an array of promotions
   * @return {Promotion[]} The array of promotions
   */
  getPromotions(): Promotion[] {
    return promotions;
  }
}
