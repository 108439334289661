import { Component, OnInit, Input } from '@angular/core';
import { SiteService } from '../service/site.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { PendingInvoiceItem } from '@shared/interfaces/billing';

@Component({
  selector: 'app-pending-invoice-items',
  templateUrl: './pending-invoice-items.component.html',
  styleUrls: ['./pending-invoice-items.component.css'],
})
export class PendingInvoiceItemsComponent implements OnInit, PageHasTable {
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader<PendingInvoiceItem>[];
  pendingInvoice: PendingInvoiceItem[];

  @Input() selectedSiteId;
  @Input() paymentMode;

  constructor(private siteService: SiteService) { }

  ngOnInit() {
    this.getPendingInvoiceItems();

    this.getTableData();
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      amount: 'currency',
    };

    this.columnHeaders = [
      { field: 'amount', header: 'Amount' },
      { field: 'subscriptionType', header: 'Subscription Type' },
      { field: 'description', header: 'Description' },
      { field: 'date', header: 'Date' },
    ];
  }

  /**
   * Queries API for pending invoice items
   */
  getPendingInvoiceItems(): void {
    this.siteService.getPendingInvoiceItems(this.selectedSiteId, this.paymentMode).subscribe(
      (res) => {
        if (res) {
          this.pendingInvoice = res;
        }
      },
      (err) => {
        throw err;
      }
    );
  }
}
