// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.background {
  background-color: rgb(245, 246, 250);
}

div.heading-main{
  padding:2rem 1rem;
  border-left: 0.75rem solid rgb(77, 79, 92);
  margin-bottom: 2rem
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/headings/header-block/header-block.component.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,iBAAiB;EACjB,0CAA0C;EAC1C;AACF","sourcesContent":["div.background {\n  background-color: rgb(245, 246, 250);\n}\n\ndiv.heading-main{\n  padding:2rem 1rem;\n  border-left: 0.75rem solid rgb(77, 79, 92);\n  margin-bottom: 2rem\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
