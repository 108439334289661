import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.css']
})
export class InputNumberComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() disabled = false;
  @Input() label: string;
  @Input() labelIsBold = true;
  @Input() max = 100;
  @Input() min = 1;
  @Input() name: string;
  @Input() placeholderText;

  constructor() {}

  ngOnInit(): void {
    this.placeholderText = `Select a number between ${this.min} and ${this.max}`;
  }
}
