import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from '../../../service/site.service';

@Component({
  selector: 'app-review-gen-setup',
  templateUrl: './review-gen-setup.component.html',
  styleUrls: ['./review-gen-setup.component.css']
})
export class ReviewGenSetupComponent implements OnInit {

  type;
  existingData
  reviewGenForm:UntypedFormGroup;
  userForm:UntypedFormGroup;
  modalService;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private router: Router,
    private toastrService: ToastrService,
    private siteService:SiteService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }
  initializeForm(){
    if(this.type==1){
    this.reviewGenForm=this.formBuilder.group({
      ProfileId:[this.existingData.profileId?this.existingData.profileId:0],
      ProfileName:[this.existingData.profileName?this.existingData.profileName:'',[Validators.required]],
      // Email:[this.existingData.email?this.existingData.email:'',[Validators.required,Validators.email]],
      SiteId:[this.existingData.siteId?this.existingData.siteId:'',[Validators.required]],
      GradusId:[this.existingData.gradusId?this.existingData.gradusId:'',[Validators.required]],
    });
  }
  else{
    this.userForm=this.formBuilder.group({
      Id:[this.existingData.id?this.existingData.id:0],
      Email:[this.existingData.email?this.existingData.email:'',[Validators.required,Validators.email]],
      SiteId:[this.existingData.siteId?this.existingData.siteId:'',[Validators.required]]
    });
  }
  }
  
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
  saveProfile(form) {
    if (form.valid) {
      this.siteService.saveReviewGen(form.value).subscribe(res=>{
        if(res){
          this.activeModal.close();
          this.toastrService.success('Profile Saved','success');
        }
      },
      err=>{
        this.toastrService.error(err.error);
      })
      }
      
    }
    saveUser(form) {
      if (form.valid) {
        this.siteService.saveReviewGenUser(form.value).subscribe(res=>{
          if(res){
            this.activeModal.close();
            this.toastrService.success('User Saved','success');
          }
        },
        err=>{
          this.toastrService.error(err.error);
        })
        }
        
      }

}
