// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date label {
  color: #2699FB;
  padding: 0 0 0 4px;
  text-decoration: underline;
}

.wizard-content {
  max-width: unset;
}


.eye {
  color: blue;  /* Default color for active links */
  text-decoration: underline;
  cursor: pointer;
}

/* Hover effect for active links */
.eye:hover {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/site/cancel-site/cancel-site.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;AAClB;;;AAGA;EACE,WAAW,GAAG,mCAAmC;EACjD,0BAA0B;EAC1B,eAAe;AACjB;;AAEA,kCAAkC;AAClC;EACE,WAAW;EACX,0BAA0B;EAC1B,eAAe;AACjB","sourcesContent":[".date label {\n  color: #2699FB;\n  padding: 0 0 0 4px;\n  text-decoration: underline;\n}\n\n.wizard-content {\n  max-width: unset;\n}\n\n\n.eye {\n  color: blue;  /* Default color for active links */\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n/* Hover effect for active links */\n.eye:hover {\n  color: blue;\n  text-decoration: underline;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
