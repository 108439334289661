import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { roles } from '@shared/master-enum.enum';
import { Router, ActivatedRoute } from '@angular/router';
import { ApprovalPopupComponent } from '../../approval-popup/approval-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { MenuService } from '@core/page-header/service/menu.service';
import { environment } from 'environments/environment';
import { HttpService } from '@services/http.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';

@Component({
  selector: 'app-review-review-gen',
  templateUrl: './review-review-gen.component.html',
  styleUrls: ['./review-review-gen.component.css'],
})
export class ReviewReviewGenComponent implements OnInit, PageHasTable {
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];

  constructor(private intakesService:IntakesService,
    private toastrService: ToastrService,
    public modalService: NgbModal,
    private menuService: MenuService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private router: Router,) { }
    userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
    reviewGenData;
    roles = roles;
    currentRole;
    @Input() intakeTypeId;
    @Input() intakeId;
    @Input() status;
    @Input() isEdit;
    @Input()comments;
    @Output() changeStatus= new EventEmitter<any>();
    @Output() editClicked = new EventEmitter<object>();
    Comment = '';
    disabled='';
    baseUrl = environment.imgUrl;

    ngOnInit() {
      this.activatedRoute.params.subscribe(params => {
        if (params.id) {
          this.intakeId=params.id;
          this.getReviewGenData(params.id);
        }if (params.status) {
          this.status = params.status;
        }
      });

      this.disabled = this.status == 3 || this.status == 5 ? 'disabled' : '';

      this.getCurrentRole();

      this.getTableData();
    }

    getCurrentRole(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if(userInfo){
        this.currentRole = _.find(roles, (role) => {
          return role.roleId == userInfo.roleId;
        })
      }
    }

    getReviewGenData(IntakeId){
      this.intakesService.getReviewGen(IntakeId).subscribe((res)=>{
        if(res){
          this.reviewGenData=res;
        }
      },
      err=>{
        this.httpService.openErrorPopup(err.error.message);
      });
    }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      date: 'date',
    };

    this.columnHeaders = [
      { field: 'statusName', header: 'Status' },
      { field: 'date', header: 'Date' },
      { field: 'comment', header: 'Comment' },
    ];
  }

    updateIntakeApproval(data, redirectTo?) {

      //let formValid = this.checkValidation();
      //by pass validation for intake approval process
      let formValid=true;
      if (formValid) {
        this.intakesService.updateIntakeApproval(data).subscribe((res) => {
          if (res) {
            this.changeStatus.next();
            if(data.Status==3){
              let sectionStatus = {
                "IntakeId": data.IntakeId,
                "lstIntakeSection": [{
                  "ProductSectionId":68,
                  "Status": 2,
                  "Percentage": 100,
                  "ParentId": 67
                }]
              };
              this.postIntakeSection(sectionStatus);
              this.getIntakeProductList(this.intakeId, true);
            }
            this.toastrService.success(this.message, 'Success');
            if(redirectTo){
              this.router.navigate([redirectTo]);
            }
          }
        },
          err => {
            console.log(err.error.message);
          });

      } else {
        this.toastrService.error("Please complete substeps first", 'Error');
      }

    }
    //flag:Observable<any>;
    message='';
    reviewIntake() {
      this.message='Intake has been submitted successfully'
      let data = {
        IntakeId: this.intakeId,
        FamilyId: this.intakeTypeId,
        Status: 3,
        Comment: this.Comment,
        UserId: this.userInfo.userId,
        IsSubmit: 1
      };
      this.updateIntakeApproval(data);

    }

    acceptIntake() {
      this.message='Intake has been Accepted';
      let data = {
        IntakeId: this.intakeId,
        FamilyId: this.intakeTypeId,
        Status: 5,
        Comment: this.Comment,
        UserId: this.userInfo.userId,
        IsSubmit: 0
      };
      this.updateIntakeApproval(data, `${this.currentRole.url}/intake-details`);
    }
    rejectIntake() {
      this.message='Intake has been Rejected'
      let data = {
        IntakeId: this.intakeId,
        FamilyId: this.intakeTypeId,
        Status: 4,
        Comment: this.Comment,
        UserId: this.userInfo.userId,
        IsSubmit: 0
      };
      this.updateIntakeApproval(data, `${this.currentRole.url}/intake-details`);
    }
    postIntakeSection(sectionStatus) {
      this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
        if (res) {
          this.getProductSectionData(this.intakeTypeId, sectionStatus.IntakeId);
        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        });
    }
    getProductSectionData(familyId, intakeId) {
      this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
        if (!_.isEmpty(res)) {
          this.intakesService.basicMenu = this.intakesService.groupMenu(res);
          this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
          this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
          this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

        } else {
          this.toastrService.error("Site doesn't exist", 'Error');
        }
      })
    }
    goToIntakePage(menuId, sectionId){
      this.editClicked.emit({"menuId": menuId, "sectionId": sectionId});
    }
    openApprovalPopup(){
      var modal = this.modalService.open(ApprovalPopupComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
      modal.componentInstance.approve = (menu) => {
        // let pendingMenu = _.find(menu, (item) => {return !item.hasOwnProperty('approvalStatus')})
        // if(!pendingMenu){
          let reqData = {
            ApprovalStatus: _.map(menu, (menuItem) => {
            return {
              intakeId: this.intakeId,
              familyId: menuItem.familyId,
              ProductSectionId: menuItem.id,
              status: menuItem.approvalStatus
            };
          })
        }
        this.postApprovalStatus(reqData);
        modal.close();
        // }else{
        //   modal.componentInstance.errorMsg = "Please fill mandatory fields";
        // }

      };
      modal.componentInstance.disapprove = () => {
      modal.close();
      };
    }

    postApprovalStatus(reqData){
      this.intakesService.postApprovalStatus(reqData).subscribe((res)=>{
        if(res){
          this.reviewIntake();
          // this.toastrService.success("df",'Success');
          // this.premiumData=res;
        }
      },
      err=>{
        this.toastrService.error(err.error.message,'Error');
      });
    }

    getIntakeProductList(intakeId, isSubmit?) {
      this.intakesService.getIntakeProductList(intakeId).subscribe((res) => {
        if (res) {
          let selectedItem = _.find(res['sfIntakeProductFamily'], (item) => {
            return item.intakeTypeId == this.intakeTypeId;
          })

          this.changeStatus.next(isSubmit);
          this.menuService.intakeProductFamily.next({ family: res['sfIntakeProductFamily'], selectedProduct: selectedItem });
        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        });
    }
}
