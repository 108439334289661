import { Component, OnInit } from '@angular/core';
import {UserService} from '../services/user-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../services/http.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  constructor(private userService:UserService,
    private httpService: HttpService,
          private toastrService: ToastrService ) 
          { }
          userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
  ngOnInit() {
    this.getUserDetails(this.userInfo.userId);
  }
  user;
  getUserDetails(userId){
    this.userService.getUserInfo(userId).subscribe((res=>{
      if(res){
        this.user=res;
      }
    })
    ,err=>{
      this.httpService.openErrorPopup(err.error.message);
    });
  }

}
