// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-file{
    border: 1px solid #bbb;
    height: 48px;
}

.img-name{
    position: absolute;
    left: 10px;
    top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/user-profile/user-profile.component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;AACb","sourcesContent":[".custom-file{\n    border: 1px solid #bbb;\n    height: 48px;\n}\n\n.img-name{\n    position: absolute;\n    left: 10px;\n    top: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
