import { Component, OnInit } from '@angular/core';
import { SiteService } from '../service/site.service';
import { Router, ActivatedRoute, Params, RoutesRecognized, Data } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IntakesService } from '../../customer/intakes/services/intakes.service';
import { roles } from '@shared/master-enum.enum';
import { HttpService } from '@services/http.service';
import { UserService } from '../../admin/users/services/user-service.service';
import { Roles as UserRoles } from '@shared/enums/Roles';
import { SiteInfo, SiteInfoWithSiteID } from '@shared/interfaces/site';
import { NotificationsService } from '@shared/services/notifications/notifications.service';

@Component({
  selector: 'app-site-config',
  templateUrl: './site-config.component.html',
  styleUrls: ['./site-config.component.css'],
})
export class SiteConfigComponent implements OnInit {
  roles = roles;
  siteInfo;
  siteId: number;
  familyId: 'users'|'Basic'|'pms'|string;
  currentRole;
  currentRoute;
  parentId;
  currentUser;
  parentInfo: object;
  selectedSite: SiteInfo | SiteInfoWithSiteID;
  userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
  routeData: Data;
  type;

  constructor(
    private siteService: SiteService,
    private toastrService: ToastrService,
    private router: Router,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private intakesService: IntakesService,
    private notificationsService: NotificationsService,
    private userService: UserService,
  ) {
    this.currentRoute = this.activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.routeData = this.activatedRoute.snapshot.data;
    localStorage.setItem('previousUrl', this.router.url);
    this.parentInfo = JSON.parse(localStorage.getItem('parentInfo'));
    const selectedSite = JSON.parse(localStorage.getItem('selectedSite'));
    localStorage.setItem('selectedSite', JSON.stringify(selectedSite));
    
    this.selectedSite = selectedSite;
    this.activatedRoute.parent.url.subscribe(
      (urlPath) => {
        this.currentRole = urlPath[urlPath.length - 1].path;

        // TODO: Remove these variables. They are unused.
        var flag1 = this.userInfo.roleId != roles.corporatePartner.roleId;
        let flag2 = this.userInfo.roleId != roles.siteUser.roleId;
      }
    );

    if (this.userInfo.roleId == UserRoles.CLIENT) {
      this.familyId = 'users';
    } else {
      this.familyId = 'Basic';
    }

    if (this.routeData['familyId']) {
      this.familyId = this.routeData['familyId'];
    }

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        if (params && Object.keys(params).length > 0) {
          this.siteId = params.siteId;
        } else {
          this.siteId = this.selectedSite.siteId;
        }

        const { familyId, type } = this.activatedRoute.snapshot.data

        if (familyId) {
          this.familyId = familyId;
        }

        if (type) {
          this.type = type;
        }

        if (params && params.siteId) {
          this.siteId = params.siteId;
        }

        this.getSiteInfo(this.siteId ?? this.selectedSite.id);
      }
    );

    if (this.intakesService.goToSetup) {
      this.goToSetup(this.intakesService.goToSetup);
    }
  }

  get showBasic() {
    return !this.familyId || this.familyId === 'Basic';
  }

  /**
   * Retrieves the site information based on the provided ID
   * @param {Number} siteId The sites ID
   */
  getSiteInfo(siteId: number): void {
    this.siteService.getSiteInfo(siteId).subscribe(
      (res: SiteInfo) => {
        if (res) {
          this.siteInfo = res;
          localStorage.setItem('selectedSite', JSON.stringify(res));
        }
      },
      (err) => {
        if (err.error.errors.hasOwnProperty('SiteId')) {
          err = new Error(err.error.title);
        }

        this.notificationsService.showModalError(err, 'Validation Error');
      });
  }

  goToSetup(intakeTypeId): void {
    this.familyId=intakeTypeId;
  }

  updateSite(siteId): void {
    this.getSiteInfo(siteId);
  }
}
