import { Component, Input, OnInit } from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { ClinicLocationsService } from '@shared/services/clinic-locations/clinic-locations.service';
import { AddressData } from '@shared/interfaces/addresses';
import { HttpErrorResponse } from '@shared/interfaces/responses';
import { ToastrService } from 'ngx-toastr';
import statesData from '@shared/data/states.json';
import { SiteInfo } from '@shared/interfaces/site';

@Component({
  selector: 'app-manage-locations',
  templateUrl: './manage-locations.component.html',
  styleUrls: ['./manage-locations.component.css'],
})
export class ManageLocationsComponent extends TabComponent implements OnInit {
  @Input() locations: AddressData[] = []
  @Input() site: SiteInfo;

  addressErrorMessages = {};
  filteredLocations: AddressData[] = []
  hasBillingAddress = false;
  locationToEdit: AddressData;
  showLocationAddButton = false;
  showAddForm = false;
  showEditForm = false;

  constructor(
    private clinicLocationsService: ClinicLocationsService,
    private toastrService: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.getLocations();
  }

  /**
   * Accessor that determines whether or not the filter is returning anything.
   */
  get filterHasResults(): boolean {
    return this.filteredLocations.length > 0;
  }

  /**
   * Accessor to return whether or not the locations array is empty.
   *
   * @return {Boolean}
   */
  get hasLocations(): boolean {
    return this.locations.length > 0;
  }

  /**
   * Returns the number of locations after the filter has been applied
   */
  get numberOfLocations(): number {
    return this.locations.length;
  }

  /**
   * Deletes an address based on its location ID
   *
   * @param {number} locationId
   */
  deleteAddress(locationId: number) {
    this.clinicLocationsService.deleteAddress(locationId).subscribe(
      () => {
        this.toastrService.success('Location Removed');
        this.getLocations();
      },
      ({error}: HttpErrorResponse) => {
        // The error message is sent to an object to be used by another component, shown via toast
        this.addressErrorMessages[locationId] = error.message;
        this.getLocations();
      });
  }

  /**
   * Filters the array of locations by field
   *
   * @param {string} searchString
   */
  filterLocations(searchString: string) {
    this.filteredLocations = this.locations.filter((location: AddressData) => {
      return JSON.stringify(
        Object.values(location)
      ).toLowerCase().includes(searchString.toLowerCase());
    });
  }

  /**
   * Retrieves the list of locations and passes them to the template
   *
   * @param {Boolean} overrideShowAddForm Will prevent the add from from being opened,
   *                  useful when triggering `getLocations()` after submitting the edit form
   */
  getLocations(overrideShowAddForm = false): void {
    this.hasBillingAddress = false;

    this.clinicLocationsService.getAddressesBySite(this.site.id)
      .subscribe(
        (res) => {
          this.locations = res;
          this.filteredLocations = [];

          this.locations.map((location: AddressData) => {
            const locationData = location;

            /* Appends Subdivision's full name to location object, to enable better filtering */
            if (location.state && location.country) {
              const isoCountryCode = location.country.toLowerCase();

              if (statesData.hasOwnProperty(isoCountryCode)) {
                const subdivisions = statesData[isoCountryCode];
                
                if(location.state)
                  locationData.stateFullName = subdivisions.filter(x => x.abbreviation.toLowerCase() === location.state.toLowerCase())[0].name;
              }
            }

            /* Adds an error message items on reload */

            // Checks if any of the address is a billing address
            if (location.isBillingAddress === true) {
              this.hasBillingAddress = true;
            }

            if (this.addressErrorMessages.hasOwnProperty(location.addressId)) {
              locationData.errorMessage = this.addressErrorMessages[location.addressId];
            }

            this.filteredLocations.push(locationData);
          });
        },
        (err) => {
          console.log(err);
          throw err;
        },
        () => {
          this.showEditForm = false;

          console.log(this.hasBillingAddress);
        }
      );
  }

  /**
   * Hides the Add Location form
   */
  hideAddForm(): void {
    this.showAddForm = false;
  }

  /**
   * Sets the add location form to be visible
   */
  showAddLocationForm(): void {
    this.showAddForm = true;
    this.showEditForm = false;
    this.showLocationAddButton = true;
  }

  /**
   * Sets the edit location form to be visible
   *
   * @param {Location} location
   */
  showEditLocationForm(location: AddressData): void {
    this.locationToEdit = location;
    this.showAddForm = false;
    this.showEditForm = true;
    this.showLocationAddButton = true;
  }

  /**
   * Toggles the add location form
   */
  toggleAddLocationForm(): void {
    this.showAddForm = !this.showAddForm;
  }

  callFromParent(site: any): void {
    this.site = site;
    this.getLocations();
  }
}
