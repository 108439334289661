import { Component, OnInit, ViewChild } from '@angular/core';
import { SiteService } from '../service/site.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { masterEnum, roles } from '@shared/master-enum.enum';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { ExcelService } from '../../reporting/services/excel.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MasterService } from '../../admin/masters/services/master.service';
import { HttpService } from '../../../services/http.service';
import moment from 'moment';
import { CorporatePartnerService } from '../../admin/manage-corporate-partner/services/corporate-partner.service';
import {
  ColumnDataTypes,
  ColumnHeader,
  PageHasTable,
  TableFilter,
} from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-intake-list',
  templateUrl: './intake-list.component.html',
  styleUrls: ['./intake-list.component.css'],
  providers: [DatePipe]
})
export class IntakeListComponent implements OnInit, PageHasTable {
  @ViewChild('dt') table: TableGenericComponent;

  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  columnWidths: {[columnName: string]: number };
  filterBy = {
    dateRange: null,
    domainUrl: null,
    businessName: null,
    startDate: null,
    endDate: null,
    isNew: null,
    cpAccountNo: null
  };
  allSites;
  siteType;
  projectType;
  status;
  accountManager;
  corporatePartner;
  statusData;
  sites: Array<Object> = [];
  userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
  siteLists;
  roles=roles;

  constructor(private siteService: SiteService,
    private toastrService: ToastrService,
    private masterService: MasterService,
    public router: Router,
    private excelService: ExcelService,
    private httpService: HttpService,
    private corporatePartnerService: CorporatePartnerService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.filterBy['status'] = this.status = this.activatedRoute.snapshot.queryParamMap.get('status');
    this.filterBy['project'] = this.activatedRoute.snapshot.queryParamMap.get('product_type');
    this.filterBy['taskStatus'] = this.activatedRoute.snapshot.queryParamMap.get('taskStatus');

    this.getAccountManager();
    this.getCorporatePartner();
    this.getApproveSite(this.filterBy, this.status);
    this.getProductType(4);
    this.getProductType(3);
    this.getSiteType(masterEnum.site_type);
    this.getSites();

    this.getTableData();
  }

  /**
   * Gets a list of websites with Intakes
   *
   * @param {TableFilter} filterBy The Filter Object
   * @param status
   */
  getApproveSite(filterBy: TableFilter, status: any): void {
    this.filterBy['formattedStartDate'] = this.datePipe.transform(
      this.filterBy.startDate, 'yyyy-MM-dd'
    );
    this.filterBy['formattedEndDate'] = this.datePipe.transform(
      this.filterBy.endDate, 'yyyy-MM-dd'
    );

    this.siteService.getApproveSite(filterBy, status).subscribe(res => {
      this.sites = [];

      if (res) {
        // TODO: When table filters are changed to dropdown, change this to support them
        for (let i = 0; i < res.length; i++) {
          const site = res[i];
          site.taskStatusLabel = 'In Progress';

          if (site.taskStatus != 0) {
            site.taskStatusLabel = 'Completed';
          }

          this.sites.push(site);
        }

        if (Object.values(filterBy).every(x => (x === null || x === ''))) {
          this.allSites = Object.assign([], res);
        }
      }
    }
    , (err) => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  /**
   * Gets data for the tables and sets the column headers
   *
   * @TODO Move API call to this method
   */
  getTableData(): void {
    this.columnHeaders = [
      {field: 'domainUrl', header: 'Domain URL', width: 15},
      {field: 'siteType', header: 'Practice Type'},
      {field: 'cpName', header: 'Program'},
      {field: 'accountManagerName', header: 'Account Manager'},
      {field: 'statusName', header: 'Intake Status'},
      {field: 'productFamily', header: 'Project'},
      {field: 'taskStatusLabel', header: 'Project Status'},
      {field: 'initiatedOn', header: 'Initiated'},
    ];

    this.columnDataTypes = {
      initiatedOn: 'date',
    };
  }

  /**
   * Resets the table data
   * @param {Object} key
   * @param {String} status
   */
  applySearch(key, status) {
    if (status == '') {
      this.status = null;
    }

    if ((typeof this.filterBy[key]) == 'object') {
      this.filterBy[key] = this.filterBy[key][key];
    }

    this.table.clearFilters();
    this.getApproveSite(this.filterBy, status);
  }

  review(siteData) {
    let path = _.find(roles, (role) => {
      return role.roleId == this.userInfo.roleId;
    })
    this.router.navigate([`review/${siteData.intakeId}/${siteData.productFamilyId}`]);
  }

  selectedItem(obj?) {
    this.getApproveSite(this.filterBy, this.status);
  }
  getProductType(type) {
    this.masterService.getGeneralMasterByType(type).subscribe((res) => {
      if (res) {
        if (type == 3) {
          this.statusData = _.filter(res, (item) => {
            return item.name != null;
          });
        } else if (type == 4) {
          this.projectType = res;
        }
      }
    },
      err => {
        throw err;
      })
  }

  getSiteType(id) {
    this.siteService.getMastersListById(id).subscribe((res) => {
      if (res) {
        this.siteType = res.masterList;
      }
    },
      err => {
        throw err;
      })
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.siteLists.filter(v => v['businessName'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  formatter = (result: string) => `${result['businessName']}`;
  searchDomain = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.siteLists.filter(v => v['domainUrl'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  domainFormatter = (result: string) => `${result['domainUrl']}`;
  getSites() {
    this.siteService.getSites().subscribe((res) => {
      if (res) {
        this.siteLists = Object.assign([], res);
      }
    },
      err => {
        throw err;
      })
  };

  getCorporatePartner() {
    this.corporatePartnerService.getCorporatePartners().subscribe(res => {
      if (res) {
        this.corporatePartner = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getAccountManager() {
    this.siteService.getAccountManager().subscribe(res => {
      if (res) {
        this.accountManager = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  /**
   * Change handler for the Intake date picker
   *
   * @param {[Date, Date]} event The dates from the date picker selection
   */
  onValueChange(event: [Date, Date]): void {
    this.filterBy['startDate'] = moment(event[0]).format('MM/DD/YYYY');
    this.filterBy['endDate'] = moment(event[1]).format('MM/DD/YYYY');
    this.filterBy['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.filterBy['_endDate'] = moment(event[1]).format('MM/DD/YY');

    this.getApproveSite(this.filterBy, this.status);
  }
}
