import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.css'],
})
export class AlertPopupComponent implements OnInit {
  @Input() message = 'There has been an error';
  @Input() title = 'Error';

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  /**
   * Closes the modal
   */
  dismissModal(): void {
    this.activeModal.dismiss();
  }
}
