import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split',
})
export class SplitPipe implements PipeTransform {
  transform(value: any, splitter = ','): Array<string> {
    if (Array.isArray(value)) {
      return value;
    } else if (typeof value == 'string') {
      return value.split(splitter);
    } else {
      return [];
    }
  }
}
