import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css'],
})
/**
 * @property {Boolean} active if the tab is the active tab
 */
export class TabComponent implements OnInit {
  @Input() label: string;
  @Input() active = false;
  @Input() disabled = false;

  @Output() clickFunction = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Click handle for the ticket tab
   *
   * @param {Event} event The click event
   */
  onClick(event): void {
    this.clickFunction.emit(event);
  }
}
