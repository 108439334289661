// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep app-button-basic button.disabled a {
  background-color: transparent !important;
  border-color: rgb(255, 255, 255) !important;
  color: rgb(255, 255, 255) !important;
}

div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-end;
}

div.footer-container {
  padding: 1rem;
  background-color: rgb(77, 79, 92);
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/buttons/button-container/button-container.component.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,2CAA2C;EAC3C,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,iCAAiC;AACnC","sourcesContent":[":host ::ng-deep app-button-basic button.disabled a {\n  background-color: transparent !important;\n  border-color: rgb(255, 255, 255) !important;\n  color: rgb(255, 255, 255) !important;\n}\n\ndiv {\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n  justify-content: flex-end;\n}\n\ndiv.footer-container {\n  padding: 1rem;\n  background-color: rgb(77, 79, 92);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
