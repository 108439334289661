import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkmarkClickable',
})
export class CheckmarkClickablePipe implements PipeTransform {
  /**
   * Converts a true/false value to a checkmark or a checkbox
   *
   * @param {Boolean} value Displays a checkmark if true, a checkbox if false
   *
   * @return {String} The HTML string for checkmark or checkbox
   */
  transform(value: boolean): string {
    if (value) {
      return '<i class="icon tick-mark iconWidthHeight"></i>';
    }

    return '<input type="checkbox" value="false">';
  }
}
