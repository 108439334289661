// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.down-arrow {
  background: url(/assets/images/up-arrow-offgray.svg) no-repeat;
  width: 11px;
  height: 7px;
  /* display: inline-block;
  margin-left: 16px; */
  transform: rotate(90deg);
  float: left;
  margin-right: 7px;
  margin-left: 0;
  margin-top: 7px;
}

.down-arrow.show {
  background: url(/assets/images/up-arrow-offgray.svg) no-repeat;
  width: 11px;
  height: 7px;
  /* display: inline-block; */
  /* margin-left: 16px; */
  transform: rotate(180deg);
  float: left;
  margin-right: 7px;
  margin-left: 0;
  margin-top: 7px;
}

.gray-bg {
  margin: 0 -1rem;
}

.table.table-style tr.active-row td {
  background: #E7E9F2;
}

.sorting-tr th {
  background: #F5F6FA;
  border: none !important;
  color: #4D4F5C;
}

.sorting-tr td {
  /* min-width: 119px; */
  border: none !important;
  background: #F5F6FA;
}

.sorting-tr th:first-child, .sorting-tr td:first-child {
  border-left: #D1D3DB solid 1px !important;
}

.sorting-tr th:last-child, .sorting-tr td:last-child {
  border-right: #D1D3DB solid 1px !important;
}

.date label {
  color: #2699FB;
  padding: 0 0 0 4px;
  text-decoration: underline;
}

.wizard-content {
  max-width: unset;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/product-manager/projects/projects.component.css"],"names":[],"mappings":"AAAA;EACE,8DAA8D;EAC9D,WAAW;EACX,WAAW;EACX;sBACoB;EAEpB,wBAAwB;EACxB,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,8DAA8D;EAC9D,WAAW;EACX,WAAW;EACX,2BAA2B;EAC3B,uBAAuB;EAEvB,yBAAyB;EACzB,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".down-arrow {\n  background: url(/assets/images/up-arrow-offgray.svg) no-repeat;\n  width: 11px;\n  height: 7px;\n  /* display: inline-block;\n  margin-left: 16px; */\n  -webkit-transform: rotate(90deg);\n  transform: rotate(90deg);\n  float: left;\n  margin-right: 7px;\n  margin-left: 0;\n  margin-top: 7px;\n}\n\n.down-arrow.show {\n  background: url(/assets/images/up-arrow-offgray.svg) no-repeat;\n  width: 11px;\n  height: 7px;\n  /* display: inline-block; */\n  /* margin-left: 16px; */\n  -webkit-transform: rotate(180deg);\n  transform: rotate(180deg);\n  float: left;\n  margin-right: 7px;\n  margin-left: 0;\n  margin-top: 7px;\n}\n\n.gray-bg {\n  margin: 0 -1rem;\n}\n\n.table.table-style tr.active-row td {\n  background: #E7E9F2;\n}\n\n.sorting-tr th {\n  background: #F5F6FA;\n  border: none !important;\n  color: #4D4F5C;\n}\n\n.sorting-tr td {\n  /* min-width: 119px; */\n  border: none !important;\n  background: #F5F6FA;\n}\n\n.sorting-tr th:first-child, .sorting-tr td:first-child {\n  border-left: #D1D3DB solid 1px !important;\n}\n\n.sorting-tr th:last-child, .sorting-tr td:last-child {\n  border-right: #D1D3DB solid 1px !important;\n}\n\n.date label {\n  color: #2699FB;\n  padding: 0 0 0 4px;\n  text-decoration: underline;\n}\n\n.wizard-content {\n  max-width: unset;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
