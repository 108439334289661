import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  
  @Input() productType
  constructor() { }

  @Output() continueClicked = new EventEmitter<any>();
  ngOnInit() {
  }
  goToNext() {
    this.continueClicked.next();
  }
}