import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-local-listings-your-practice',
  templateUrl: './local-listings-your-practice.component.html',
  styleUrls: ['./local-listings-your-practice.component.css']
})
export class LocalListingsYourPracticeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
