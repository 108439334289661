import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {Location} from '@angular/common';
import { MasterService } from '../../../admin/masters/services/master.service';
import { HttpService } from '@services/http.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-exhibit-b',
  templateUrl: './exhibit-b.component.html',
  styleUrls: ['./exhibit-b.component.css']
})
export class ExhibitBComponent implements OnInit {
  @Output() back = new EventEmitter<any>();
  exhibitData;
  exhibitType;
  selectedExhibit;
  constructor(
    private _location: Location,
    private masterService: MasterService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService
  ) { 
    this.activatedRoute.params.subscribe(params => {
      if(params.type){
        this.exhibitType = params.type;
      }
    })
  }

  ngOnInit() {
    this.getExhibitData(3);
  }

  getExhibitData(type){
    this.masterService.getSowDataByType(type).subscribe(res => {
      if(res){
        this.exhibitData = res;     
        this.selectedExhibit = _.find(this.exhibitData, (data) => {
          return data.name.charAt(data.name.length - 1) == this.exhibitType;
        })
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  goBack() {
    this._location.back();
  }

}
