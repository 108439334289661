import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-basic',
  templateUrl: './button-basic.component.html',
  styleUrls: ['./button-basic.component.css'],
})
export class ButtonBasicComponent implements OnInit {
  readonly buttonTypes = [
    'action',
    'default',
    'intake-back',
    'intake-continue',
    'intake-save',
    'other',
    'reset',
    'save-continue',
    'submit',
  ] as const;

  buttonCss = {}

  @Input() cssClasses = '';
  @Input() label: string;
  @Input() type ?: ButtonType = 'default';
  @Input() disabled = false;

  @Output() clickFunction = new EventEmitter<object>();

  constructor() { }

  ngOnInit(): void {
    this.cssClasses.split(' ').map((cssClass: string) => {
      this.buttonCss[cssClass] = true;
    });

    if (this.buttonTypes.includes(this.type)) {
      this.buttonCss[this.type] = true;
    } else {
      this.type = 'default';
    }
  }
}

@Component({
  selector: 'app-button-intake-back',
  templateUrl: './button-basic.component.html',
  styleUrls: ['./button-basic.component.css'],
})
export class ButtonIntakeBackComponent extends ButtonBasicComponent {
  label = 'Back'
  type: ButtonType = 'intake-back'
}

@Component({
  selector: 'app-button-intake-continue',
  templateUrl: './button-basic.component.html',
  styleUrls: ['./button-basic.component.css'],
})
export class ButtonIntakeContinueComponent extends ButtonBasicComponent {
  label = 'Continue'
  type: ButtonType = 'intake-continue'
}

/**
 * Allows for typehinting and autocompletion of button types
 *
 * @TODO: Migrate this to the `types.ts` file and consider creating separate components for each
 * button type
 */
type ButtonType = typeof ButtonBasicComponent.prototype.buttonTypes[number];
