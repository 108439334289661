// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .p-paginator-page {
  border: 0;
  font-weight: bold;
}

:host ::ng-deep .p-paginator-page:not(.p-highlight) {
  border: 1px solid rgb(209, 211, 219);
  font-weight: normal;
}

:host ::ng-deep .p-paginator-first,
:host ::ng-deep .p-paginator-last,
:host ::ng-deep .p-paginator-next,
:host ::ng-deep .p-paginator-prev {
  border: 0;
}

:host ::ng-deep .p-paginator {
  float: left;
  margin: 1.5rem 1.5rem 1.5rem 0;
}

:host ::ng-deep span .p-paginator-page:not(.p-highlight) {
  background-color: unset;
}

:host ::ng-deep .p-paginator-element {
  background-color: rgb(245, 246, 250);
  border-radius: .2rem;
  height: 2rem;
  margin-right: .5rem;
  width: 2rem;
}

:host ::ng-deep .p-paginator-icon {
  color: rgb(000, 000, 000);
}

:host ::ng-deep .p-paginator-current {
  order: 2;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/tables/paginator/paginator.component.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;;;;EAIE,SAAS;AACX;;AAEA;EACE,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,oBAAoB;EACpB,YAAY;EACZ,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,QAAQ;AACV","sourcesContent":[":host ::ng-deep .p-paginator-page {\n  border: 0;\n  font-weight: bold;\n}\n\n:host ::ng-deep .p-paginator-page:not(.p-highlight) {\n  border: 1px solid rgb(209, 211, 219);\n  font-weight: normal;\n}\n\n:host ::ng-deep .p-paginator-first,\n:host ::ng-deep .p-paginator-last,\n:host ::ng-deep .p-paginator-next,\n:host ::ng-deep .p-paginator-prev {\n  border: 0;\n}\n\n:host ::ng-deep .p-paginator {\n  float: left;\n  margin: 1.5rem 1.5rem 1.5rem 0;\n}\n\n:host ::ng-deep span .p-paginator-page:not(.p-highlight) {\n  background-color: unset;\n}\n\n:host ::ng-deep .p-paginator-element {\n  background-color: rgb(245, 246, 250);\n  border-radius: .2rem;\n  height: 2rem;\n  margin-right: .5rem;\n  width: 2rem;\n}\n\n:host ::ng-deep .p-paginator-icon {\n  color: rgb(000, 000, 000);\n}\n\n:host ::ng-deep .p-paginator-current {\n  order: 2;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
