import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SetPasswordService {

  constructor(
    private httpService: HttpService
  ) { }

  setPassword(data): Observable<any> {

    return this.httpService.postApi('User/SetPassword', data)
    .pipe(
      tap(res => {

          return res;

    })
  );
  }

  validateToken(token): Observable<any> {

    return this.httpService.postApi('User/ValidateUserToken', token)
    .pipe(
      tap(res => {

          return res;

    })
  );
  }

}
