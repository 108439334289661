import { IntakeStatus } from '@modules/intakes/intake-component.interface';
import { countryCode, Day } from '@shared/types/types';

export interface Site {
  accountManager: null;
  accountManagerName: string;
  clientType: string;
  cpAccountNo ?: unknown;
  cpId: number;
  cpName: string;
  domainUrl: string;
  initiatedOn: string;
  intakeId: number;
  isNew: boolean;
  isUpgrade: boolean;
  productFaimly: string;
  productFamilyId: number;
  siteName: string;
  siteType: string;
  status: number;
  statusName: string;
  taskStatus: number | string;
}

export interface Intake {
  approvalDate ?: unknown;
  approvedBy: number;
  businesssName ?: unknown;
  clientType: 'Existing Client'|'New Client';
  comment: null;
  cpId: number;
  cpName: string;
  createDate: string;
  customerEmail: string;
  description ?: unknown;
  designReviewUrl ?: unknown;
  domainUrl: string;
  expirationDate: null;
  initiateDate ?: null;
  intakeCanBeDeleted: boolean;
  intakeId: number|string;
  isAcceptTnC: boolean;
  isDeleted: boolean;
  isNew: boolean;
  isResound_Signia: boolean;
  paymentMode: number;
  paymentModeName: string;
  paymentStatus: number;
  paymentStatusName: string;
  products: string;
  sfIntakeProduct: null;
  sfIntakeProductFamily: null;
  sfNonIntakeProductFamily: null;
  siteId: number;
  siteName: string;
  status: number;
  statusName ?: unknown;
  stripeInvoiceId ?: unknown;
  submittedBy: number;
  sumbmissionDate ?: null;
  wpThemeId: null;
}

/**
 * Basic Intake data
 */
export interface IntakeBasic {
  intakeId: number;
}

export interface IntakeFamilyItem {
  id: number;
  name ?: string;
  description ?: string;
  display ?: string;
}

/**
 * Methods required for intake forms
 *
 * @method saveForm Saves the intake form
 */
export interface IntakeForm {
  saveForm(): void;
}

/**
 * The Local Listing data provide in the Intake's response
 */
export interface IntakeLocalListing {
  business_tags: LlListingTag[],
  health_insurances: string,
  intake_id: number,
  ll_business_id: number,
  locations: LocalListingsLocation[],
  logo: string,
  website: string,
}

/**
 * Local Listings data for a specific location
 */
export interface LocalListingsLocation {
  appointment_link: string,
  gbp_location_id: string,
  has_existing_gbp_listing: boolean,
  intake_id: number,
  intake_location?: IntakeLocationData,
  languages_supported: string,
  ll_business_id: number,
  ll_location_id: number,
  location_id: number,
  location_tags: LlListingTag[]
  mobile_services: boolean,
  office_photos: string,
  service_area: string,
  site_id: number,
  year_established: string,
}

export interface IntakeLocationData {
  address1: string,
  address2: string,
  altPhone: string,
  city: string,
  country: countryCode,
  email2 ?: string;
  email: string,
  faxNumber ?: string,
  googleBusinessName: string,
  hasHours: false
  id: number,
  intakeType: number;
  locationName: string,
  lstLocationWorkingHours: WorkingDay[];
  neighborhoodBuilding: string,
  phoneNumber ?: string,
  picture ?: string,
  state: string,
  type: number;
  zipCode: string,
}

/**
 * Local Listings Business Interface
 * @todo Update with typed entries for `business_tags` and `locations`
 * @todo Change interface name to something more clear
 */
export interface LlBusiness {
  ll_business_id: any;
  intake_id: any;
  website: string;
  logo: string;
  health_insurances: string;
  location_name: string;
  business_tags: Array<any>;
  locations: Array<any>;
}

export interface WorkingDay {
  closeTime: string;
  day: Day;
  id: number;
  locationId: number;
  openTime: string;
}

// refers to ad_local_listings_listing_tag table which ties the tags to the business or location table they belong to.
export interface LlListingTag {
  listing_tag_id: number,
  ll_business_id?: null,
  ll_location_id: number
  ll_tag_id: number,
  tagInfo: LocalListingsTag,
}

export interface IntakeProduct {
  discount: number;
  discountPercent: number;
  paymentType: 'Monthly' | 'Quarterly' | 'Yearly' | string;
  productList: IntakeProductListItem[];
  subtotal: number;
  total: number;
}

export interface IntakeProductListItem {
  discount: number;
  isDirect: boolean;
  listPrice: number;
  productId: number | string;
  productName: string;
  quantity: number;
  totalPrice: number;
  unitPrice: number;
}

export interface IntakeStep {
  percentage ?: number;
  status: IntakeStatus;
  stepName: string;
  stepNumber: number;
}

export interface IntakeSubmenu {
  displayOrder ?: number;
  familyId: number;
  id: number;
  isOverview: false;
  name: string;
  parent: number;
  percentage ?: number;
  status ?: string;
}

// this is the actual tag data from ad_local_listings_tags table and its metadata from ad_general_master
export interface LocalListingsTag {
  description: string;
  form_description: string;
  form_display: string;
  form_name: string | 'Business' | 'Location';
  form_type: number;
  is_active: boolean;
  ll_tag_id: number;
  name: string;
  tag_type: number;
  tag_type_description: string;
  tag_type_display: string;
  tag_type_name: string;
}

export class RequiredLocationData {
  locationName: string;
  country: string;
  address1: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  mobile_services: boolean;
  lstLocationWorkingHours: WorkingDay[];
  location_tags: LlListingTag[];

  constructor(location: LocalListingsLocation){
    this.locationName = location.intake_location.locationName
    this.country = location.intake_location.country
    this.address1 = location.intake_location.address1
    this.city = location.intake_location.city
    this.state = location.intake_location.state
    this.zipCode = location.intake_location.zipCode
    this.phoneNumber = location.intake_location.phoneNumber
    this.mobile_services = location.mobile_services
    this.lstLocationWorkingHours = location.intake_location.lstLocationWorkingHours
    this.location_tags = location.location_tags
  }
}


export class LlRequiredData{
  website: string;
  locations: RequiredLocationData[];

  constructor(llIntake: IntakeLocalListing){
    this.website = llIntake.website;
    let locationList = [];
    llIntake.locations?.forEach(loc => {
      const existingLocation  = new RequiredLocationData(loc);
      locationList.push(existingLocation);
    });
    this.locations = locationList;
  }
}
