import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import { IntakeFamilyItem } from '@shared/interfaces/intakes';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  constructor(private httpService: HttpService) { }

  /**
   * Queries the API for a list of product families
   *
   * @return {Observable}
   */
  getFamilyList(): Observable<IntakeFamilyItem[]> {
    return this.httpService.getApi(`master/general/4`);
  }
}
