import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { InputGeneric } from '@shared/interfaces/forms';
import { CounselEarSetup } from '@shared/interfaces/counsel-ear';
import { ReviewGenProfile } from '@shared/interfaces/reports';
import { ActivatedRoute } from '@angular/router';
import { CounselEarService } from '@shared/services/practice-management-software/counsel-ear.service';
import { NotificationsService } from '@shared/services/notifications/notifications.service';
import { ReportingService } from '../../../../reporting/services/reporting.service';
import { UserService } from '../../../../admin/users/services/user-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalFrameComponent } from '@shared/components/frames/modal-frame/modal-frame.component';

@Component({
  selector: 'app-counsel-ear-edit',
  templateUrl: './counsel-ear-edit.component.html',
  styleUrls: ['./counsel-ear-edit.component.css'],
})
export class CounselEarEditComponent implements OnInit {
  counselEarForm = new UntypedFormGroup({
    locationName: new UntypedFormControl(null, Validators.required),
    locationId: new UntypedFormControl(null, [
      Validators.min(0),
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    reviewGenProfile: new UntypedFormControl(null, Validators.required),
  });
  reviewGenProfiles: InputGeneric[] = [];
  siteId: number;
  userInfo;

  @Input() setupData: CounselEarSetup;

  @Output('onLocationEdit') onLocationEdit = new EventEmitter<any>();

  @ViewChild('modal') modal: ModalFrameComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private counselEarService: CounselEarService,
    private notificationsService: NotificationsService,
    private reportingService: ReportingService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    var siteString = localStorage.getItem('selectedSite');

// Parse the JSON string back into an object
    var selectedSite = JSON.parse(siteString);
    this.siteId = selectedSite.siteId

    this.userInfo = localStorage.getItem('userInfo') != null ?
      JSON.parse(localStorage.getItem('userInfo')) :
      { 'token': '', 'userId': null, 'roleId': null };

    this.getReviewGenProfiles();
  }

  /**
   * Returns an error message based the control
   *
   * @param {String} control The name of the form control
   *
   * @return {String} The error message
   */
  getErrorMessages(control: string) {
    if (this.counselEarForm?.controls.hasOwnProperty(control)) {
      const formControl = this.counselEarForm.controls[control];

      if (formControl?.errors?.pattern) {
        return 'Numbers only';
      }
    }
  }

  /**
   * @return {Array<any>}
   */
  getReviewGenProfiles(): Array<any> {
    this.reportingService.getProfileData(this.siteId).subscribe(
      (res: ReviewGenProfile[]) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            this.reviewGenProfiles.push({
              text: res[i].profileName,
              value: res[i].profileId,
            });
          }
        }
      },
      (error) => console.error(error),
      () => {
        this.updateFormData();
      }
    );

    return [];
  }

  /**
   * Saves the updated Setup Location data
   *
   * @param {FormGroup} form
   */
  saveLocation(form: UntypedFormGroup): void {
    this.setupData['counselear_location_id'] = form.value.locationId;
    this.setupData['location_name'] = form.value.locationName;
    this.setupData['modified_by'] = this.userInfo.userId;
    this.setupData['reviewgen_id'] = form.value.reviewGenProfile;
    this.setupData['modified_by'] = this.userInfo.userId;

    this.counselEarService.saveSetup(this.setupData).subscribe(
      (res) => {
        if (res) {
          this.notificationsService.showToastSuccess(
            `Location ${form.value.locationName} Added`
          );
        }
      },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.notificationsService.showToastError(error.message);
      },
      () => {
        this.onLocationEdit.emit();
      }
    );

    this.modal.dismissModal();
  }

  /**
   * Updates the edit form with the already existing Setup data
   */
  updateFormData(): void {
    this.counselEarForm.patchValue(
      {
        locationId: this.setupData.counselear_location_id,
        locationName: this.setupData.location_name,
        reviewGenProfile: this.setupData.reviewgen_id,
      },
      {
        onlySelf: false,
        emitEvent: true,
      }
    );

    this.counselEarForm.controls['reviewGenProfile'].setValue(this.setupData.reviewgen_id);
  }
}
