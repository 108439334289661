import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[appEqualsValid]' })
export class EqualsValidDirective {
  hasView: boolean;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set appEqualsValid(condition: string) {
    if (condition.toLowerCase() === 'valid') {
      this.viewContainer.clear();
      this.hasView = false;
    } else {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }
}
