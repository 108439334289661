// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header.initiate-intake{background:#303E4E; color:#fff; padding:10px 18px; border-radius: 0;}
.modal-header.initiate-intake .modal-title{ font-size:24px; font-weight:700; text-transform: capitalize;}
.modal-header.initiate-intake .close, .modal-header.initiate-intake .close:hover{color: #fff;    opacity: 1;     font-size: 36px;  line-height: 33px;} 
.modal-header.initiate-intake .close:focus{box-shadow:none; outline: none;}
.blue-bg{background:#4296F7 !important; opacity:1 !important;}
.theme-bg{background:#303E4E !important;}
.btn.aud-btn{color:#FFFFFF; font-size:15px;}
.intiate-info{font-size:16px;  color:#303E4E;}
.intiate-info label{font-weight:700;}
.aud-input{background-color:#F8F8F8;}
select.form-control.aud-input.style-2{background-image:url(/assets/images/up-down-select-option.svg) !important; background-position:98% 15px; background-repeat:no-repeat;}
.modal-body.initiate-intake{padding:0;}
.modal-body.initiate-intake .pay-info{padding:22px 15px 0 15px;}
.modal-body.initiate-intake .form-space{padding:0 15px 0 15px;}
.button-section {background:#F8F8F8; padding:15px; border-top:#D1D3DB solid 1px; margin-top:12px;}
`, "",{"version":3,"sources":["webpack://./src/app/components/account-manager/intakes/initiate-popup/initiate-popup.component.css"],"names":[],"mappings":"AAAA,8BAA8B,kBAAkB,EAAE,UAAU,EAAE,iBAAiB,EAAE,gBAAgB,CAAC;AAClG,4CAA4C,cAAc,EAAE,eAAe,EAAE,0BAA0B,CAAC;AACxG,iFAAiF,WAAW,KAAK,UAAU,MAAM,eAAe,GAAG,iBAAiB,CAAC;AACrJ,2CAA2C,eAAe,EAAE,aAAa,CAAC;AAC1E,SAAS,6BAA6B,EAAE,oBAAoB,CAAC;AAC7D,UAAU,6BAA6B,CAAC;AACxC,aAAa,aAAa,EAAE,cAAc,CAAC;AAC3C,cAAc,cAAc,GAAG,aAAa,CAAC;AAC7C,oBAAoB,eAAe,CAAC;AACpC,WAAW,wBAAwB,CAAC;AACpC,sCAAsC,yEAAyE,EAAE,4BAA4B,EAAE,2BAA2B,CAAC;AAC3K,4BAA4B,SAAS,CAAC;AACtC,sCAAsC,wBAAwB,CAAC;AAC/D,wCAAwC,qBAAqB,CAAC;AAC9D,iBAAiB,kBAAkB,EAAE,YAAY,EAAE,4BAA4B,EAAE,eAAe,CAAC","sourcesContent":[".modal-header.initiate-intake{background:#303E4E; color:#fff; padding:10px 18px; border-radius: 0;}\n.modal-header.initiate-intake .modal-title{ font-size:24px; font-weight:700; text-transform: capitalize;}\n.modal-header.initiate-intake .close, .modal-header.initiate-intake .close:hover{color: #fff;    opacity: 1;     font-size: 36px;  line-height: 33px;} \n.modal-header.initiate-intake .close:focus{box-shadow:none; outline: none;}\n.blue-bg{background:#4296F7 !important; opacity:1 !important;}\n.theme-bg{background:#303E4E !important;}\n.btn.aud-btn{color:#FFFFFF; font-size:15px;}\n.intiate-info{font-size:16px;  color:#303E4E;}\n.intiate-info label{font-weight:700;}\n.aud-input{background-color:#F8F8F8;}\nselect.form-control.aud-input.style-2{background-image:url(/assets/images/up-down-select-option.svg) !important; background-position:98% 15px; background-repeat:no-repeat;}\n.modal-body.initiate-intake{padding:0;}\n.modal-body.initiate-intake .pay-info{padding:22px 15px 0 15px;}\n.modal-body.initiate-intake .form-space{padding:0 15px 0 15px;}\n.button-section {background:#F8F8F8; padding:15px; border-top:#D1D3DB solid 1px; margin-top:12px;}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
