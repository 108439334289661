import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalListingsService } from '@shared/services/local-listings/local-listings.service';
import { IntakeLocationData, IntakeLocalListing } from '@shared/interfaces/intakes';
import { LocalListingsLocation } from '@shared/interfaces/intakes';
import {IntakeLocalListingsComponent } from '../../intakes/intake-local-listings/intake-local-listings.component';

@Component({
  selector: 'app-frame-form-container',
  templateUrl: './frame-form-container.component.html',
  styleUrls: ['./frame-form-container.component.css']
})
export class FrameFormContainerComponent implements OnInit {
  @Input() location: LocalListingsLocation;
  @Input() frameCollapsed = true;
  @Output() locationDelete = new EventEmitter<any>();

  headerLabel: string;
  showDeleteConfirm = false;
  localListingsIntake: IntakeLocalListing;
  intakeData: IntakeLocationData;
  showDeleteLocationViewButton = false;

  constructor(    
    private localListingsService: LocalListingsService,
    private intakeLocalListingsComponent: IntakeLocalListingsComponent,
  ) { }

  ngOnInit(): void {
    if (this.location && this.location?.intake_location?.address1) {
      this.setHeaderLabel(this.location.intake_location.address1);
      this.showDeleteLocationViewButton = false;
    } else {
      this.setHeaderLabel(`New Location`);
      this.showDeleteLocationViewButton = true;
    }
  }

  /**
   * Triggers thet location delete functionality, provided by the parent component
   * @param {LocalListingsLocation} location The location provided by the parent component
   */
  deleteLocation(location: LocalListingsLocation): void {
    this.localListingsService.deleteLocationData(location).subscribe(
      (res) => {
        if (res) {
          this.intakeLocalListingsComponent.getExistingIntake();
        }
      },
      (err) => {
        console.log(err)
      }
    );
  }

    /**
   * Triggers thet location delete functionality, provided by the parent component
   */
    deleteLocationView(): void {
      this.intakeLocalListingsComponent.getExistingIntake();
    }  

  /**
   * Sets the Header label and triggers change detection
   * @param {String} headerLabel The text that appears in the panel header
   */
  setHeaderLabel(headerLabel: string): void {
    this.headerLabel = headerLabel;
  }

  verifyDelete(): void {

  }
}
