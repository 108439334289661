import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesDashboardComponent } from './des-dashboard/des-dashboard.component';
import { TicketModule } from '../ticket/ticket.module';

@NgModule({
  imports: [
    CommonModule,
    TicketModule
  ],
  declarations: [DesDashboardComponent]
})
export class DesignerModule { }
