import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from '../../../service/site.service';
@Component({
  selector: 'app-piwik-setup',
  templateUrl: './piwik-setup.component.html',
  styleUrls: ['./piwik-setup.component.css']
})
export class PiwikSetupComponent implements OnInit {

  siteId;
  piwikScript;
  constructor(
    public activeModal: NgbActiveModal,
    private siteService:SiteService,
  ) { }

  ngOnInit() {
    this.getPiwikScript();
  }
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
  getPiwikScript(){
    this.siteService.getPiwikScript(this.siteId).subscribe(res=>{
      if(res){
        this.piwikScript=res['PiwikScript'];
      }
    })
  }  
}
