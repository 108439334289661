
export class CustomPageModel {
    
    id:number=0;
    name:string='';
    title:string='';
    parent:number=0;
    type:string='p';
    children:string=null;
    selected:boolean=false;
    notSubscribed:string='';

    constructor(selected) {
        this.selected = selected;
       }

}

