// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all {
  button {
    padding: 6px 10px;
    width: 8rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/buttons/reset-button/reset-button.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,iBAAiB;IACjB,WAAW;EACb;AACF","sourcesContent":["@media all {\n  button {\n    padding: 6px 10px;\n    width: 8rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
