import { Component, Input, OnInit } from '@angular/core';
import { faPhoneAlt, faEnvelope, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact-panel',
  templateUrl: './contact-panel.component.html',
  styleUrls: ['./contact-panel.component.css']
})
export class ContactPanelComponent implements OnInit {
  faPhoneAlt = faPhoneAlt;
  faEnvelope = faEnvelope;
  faCalendarPlus = faCalendarPlus;

  @Input() email: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() middleName: string;
  @Input() phoneNumber: string;
  @Input() profileImageLink: string;
  @Input() scheduleLink: string;
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
    if (this.profileImageLink.includes('null')) {
      this.profileImageLink = null;
    }
  }

}
