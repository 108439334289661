import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { SectionStatus } from '../basic/review/section-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../services/http.service';
@Component({
  selector: 'app-social-media-setup',
  templateUrl: './social-media-setup.component.html',
  styleUrls: ['./social-media-setup.component.css']
})
export class SocialMediaSetupComponent implements OnInit {
  SMSForm: UntypedFormGroup;
  @Input() submenu;
  @Input() intakeTypeId;
  @Input() menuId;
  intakeId: number = null;
  siteId: number = null;
  intakeSMSData;
  twitter = [];
  facebook = [];
  youtube = [];
  @Output() continue = new EventEmitter<any>();  
  @Output() changeStatus= new EventEmitter<any>(); 
  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    public dataService:DataService,
    private toastrService: ToastrService) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.siteId = params.siteId;
      }
    });
  }

  ngOnInit() {
    this.getIntakeSMS(this.intakeId);
    //this.initializeForm();
  }

  initializeForm() {
    this.SMSForm = this.formBuilder.group({
      intakeId: this.intakeId ? this.intakeId : null,
      isTwitttRequired: new UntypedFormControl(this.twitter.length && this.twitter[0].isRequired != null ? this.twitter[0].isRequired : null, Validators.required),
      isFacebookRequired: new UntypedFormControl(this.facebook.length && this.facebook[0].isRequired != null ? this.facebook[0].isRequired : null, Validators.required),
      isYoutubeRequired: new UntypedFormControl(this.youtube.length && this.youtube[0].isRequired != null ? this.youtube[0].isRequired : null, Validators.required),
      email: new UntypedFormControl(this.intakeSMSData ? this.intakeSMSData.email : '',Validators.pattern(this.dataService.emailRegex)),
      twitter: this.formBuilder.group({
        socialMediaTypeId: this.twitter.length ? this.twitter[0].socialMediaTypeId : 141,
        url: new UntypedFormControl(this.twitter.length ? this.twitter[0].url : null,Validators.pattern(this.dataService.urlRegex)),
        userName: new UntypedFormControl(this.twitter.length ? this.twitter[0].userName : null),
        password: new UntypedFormControl(this.twitter.length ? this.twitter[0].password : null),
        isRequired:this.twitter.length ? this.twitter[0].isRequired : null
      }),
      facebook: this.formBuilder.group({
        socialMediaTypeId: this.facebook.length ? this.facebook[0].socialMediaTypeId : 142,
        url: new UntypedFormControl(this.facebook.length ? this.facebook[0].url : null, Validators.pattern(this.dataService.urlRegex)),
        userName: new UntypedFormControl(this.facebook.length ? this.facebook[0].userName : null),
        password: new UntypedFormControl(this.facebook.length ? this.facebook[0].password : null),
        isRequired:this.facebook.length ? this.facebook[0].isRequired : null
      }),
      youtube: this.formBuilder.group({
        socialMediaTypeId: this.youtube.length ? this.youtube[0].socialMediaTypeId : 144,
        url: new UntypedFormControl(this.youtube.length ? this.youtube[0].url : null, Validators.pattern(this.dataService.urlRegex)),
        userName: new UntypedFormControl(this.youtube.length ? this.youtube[0].userName : null),
        password: new UntypedFormControl(this.youtube.length ? this.youtube[0].password : null),
        isRequired:this.youtube.length ? this.youtube[0].isRequired : null
      })

    });
  }

  isFieldValid(field: string,index:number =0) {
      return !this.SMSForm.get(field).valid && this.SMSForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray){
        for (let i=0;i<control.controls.length;i++){
          this.validateAllFormFields(control.controls[i]);
        }
      }

    });
  }

  getIntakeSMS(intakeId) {
    this.intakesService.getSMSData(intakeId).subscribe((res) => {
      if (res) {
        this.intakeSMSData = res;
        _.forEach(this.intakeSMSData.socialMedias, (item) => {
          if (item.socialMediaType == "Twitter") {
            this.twitter.push(item);
          } else if (item.socialMediaType == "Facebook") {
            this.facebook.push(item);
          } else {
            this.youtube.push(item);
          }
        })
        this.initializeForm();
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  saveFormData(form, mode?) {

    if(this.SMSForm.valid){
     console.log('valid form submitted');
     form.value.twitter.isRequired=form.value.isTwitttRequired;
    form.value.facebook.isRequired=form.value.isFacebookRequired;
    form.value.youtube.isRequired=form.value.isYoutubeRequired;
    console.log(form.value, '==form value');
    let smsFormData = {
      "intakeId": form.value.intakeId,
      "email": form.value.email,
      "socialMedias":
        [form.value.twitter, form.value.facebook, form.value.youtube]
    };

    this.intakesService.postSMSFormData(smsFormData).subscribe(res => {
      if (res) {
        this.changeStatus.next();
        this.toastrService.success('Saved Successfully', 'Success');
        this.intakesService.updateRequiredFieldsStatus(smsFormData);
        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.submenu[0].id, this.intakeId);
        this.postIntakeSection(sectionStatus);
        if(mode == 'continue'){
          this.goToNext('parent');
        }
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
    }else{
      console.log('invalid form submitted');
      this.validateAllFormFields(this.SMSForm);
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
  
  };

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        // this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  isTwittRequired() {
    if (!this.SMSForm.controls.isTwitttRequired.value) {
      this.SMSForm.controls.twitter['controls'].url.setValue('');
      this.SMSForm.controls.twitter['controls'].userName.setValue('');
      this.SMSForm.controls.twitter['controls'].password.setValue('');
    }
  }
  isFacebookRequired() {
    if (!this.SMSForm.controls.isFacebookRequired.value) {
      this.SMSForm.controls.facebook['controls'].url.setValue('');
    }
  }
  isYoutubeRequired() {
    if (!this.SMSForm.controls.isYoutubeRequired.value) {
      this.SMSForm.controls.youtube['controls'].url.setValue('');
      this.SMSForm.controls.youtube['controls'].userName.setValue('');
      this.SMSForm.controls.youtube['controls'].password.setValue('');
    }
  }

  validateSocialMedia(media, key) {
    if (typeof this.SMSForm.controls[key].value == 'boolean') {
      // let keys = _.keys(this.SMSForm.controls[media]['controls'], (val) => {return val != 'socialMediaTypeId'});
      if (!this.SMSForm.controls[key].value) {
        for (let property in this.SMSForm.controls[media]['controls']) {
          if (property != 'socialMediaTypeId') {
            this.SMSForm.controls[media]['controls'][property].setValue('');
            this.SMSForm.controls[media]['controls'][property].clearValidators();
            this.SMSForm.controls[media]['controls'][property].updateValueAndValidity();
          }
        }
      } else {
        for (let property in this.SMSForm.controls[media]['controls']) {
          this.SMSForm.controls[media]['controls'][property].setValidators([Validators.required]);
        }
      }
      // this.SMSForm.get(media).updateValueAndValidity();
    }

  }

  goToNext(menu){
    this.continue.next(menu);
  }

}
