import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.css'],
})
export class InputDateComponent implements OnInit {
  @Input() controlName: string;
  @Input() disabled = false;
  @Input() error = false;
  @Input() errorMessage: string;
  @Input() label: string;
  @Input() labelIsBold = true;
  @Input() name: string;
  @Input() placeholderText = '';
  @Input() required = false;
  @Input() showLabel = true;
  @Input() formControl: UntypedFormControl;

  inputFormGroup: AbstractControl|UntypedFormGroup;

  constructor(
    private controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {
    if (!this.name) {
      this.name = this.controlName;
    }

    this[this.controlName] = new UntypedFormControl();

    this.inputFormGroup = this.controlContainer.control;
  }
}
