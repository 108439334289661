import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';

@Component({
  selector: 'app-exhibit-c',
  templateUrl: './exhibit-c.component.html',
  styleUrls: ['./exhibit-c.component.css']
})
export class ExhibitCComponent  implements OnInit {
  
  @Output() back = new EventEmitter<any>();
   constructor(
    private activatedRoute: ActivatedRoute
  ) {
    
  }

  ngOnInit() {
  }

  goBack() {
    this.back.next();
    window.scroll(0,0);
  }

}

