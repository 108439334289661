import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../../services/intakes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { roles } from '@shared/master-enum.enum';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageSampleComponent } from '../pages/page-sample/page-sample.component';
import * as _ from 'lodash';
import { ApprovalPopupComponent } from '../../approval-popup/approval-popup.component';
import { MenuService } from '@core/page-header/service/menu.service';
import { AssetsComponent } from '../content/assets/assets.component';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '@services/http.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { hasPermission } from '@shared/policies/permissions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css'],
})
export class ReviewComponent implements OnInit, PageHasTable {
  faGlobe = faGlobe;
  @Input() menu;
  @Input() intakeTypeId;
  @Input() selectedMenu;
  @Input() isUpgrade;
  @Input() comments;

  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  Comment = '';
  hasPermission = hasPermission;
  loading: boolean = false;
  roles = roles;
  activeTab;
  baseUrl = environment.imgUrl;
  @Input() intakeId;
  savedFormData = this.intakesService.basicInfo;
  errorMsg = [];
  currentRole;
  enhacedData;
  savePages;
  affiliationLogo;
  photography;
  homePageData;
  selectedColorList;
  premiumData;
  @Output() editClicked = new EventEmitter<object>();
  userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
  @Input() status;
  @Input() isEdit;
  @Output() changeStatus = new EventEmitter<any>();
  disabled;
  message = '';
  @Input()wpSiteId;

  constructor(
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    public menuService: MenuService,
    private httpService: HttpService,
    public router: Router,
    private dataService: DataService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        if (!this.isUpgrade) {
          this.getIntakeBasic(params.id);
          this.getHomepageData(params.id);
          this.getIntakeSavedPages(params.id);
        }
        if (!this.menu) {
          this.getProductList(this.intakeTypeId, params.id);
        }
        if (this.intakeTypeId == 2)
          this.getEnhancedData(params.id);
        if (this.intakeTypeId == 3)
          this.getPremiumData(params.id);
      }
      if (params.status) {
        this.status = params.status;
      }

    });
    this.disabled = this.status == 3 || this.status == 5 ? 'disabled' : '';
    this.getCurrentRole();
    this.getActiveTab();

    this.getTableData();
  }

  getActiveTab() {
    if (this.isUpgrade && this.intakeTypeId == 2) {
      this.activeTab = 'enhanced';
    } else if (this.isUpgrade && this.intakeTypeId == 3) {
      this.activeTab = 'premium';
    } else {
      this.activeTab = 'practice';
    }
  }

  getCurrentRole() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if(userInfo){
      this.currentRole = _.find(roles, (role) => {
        return role.roleId == userInfo.roleId;
      })
    }

  }

  getProductList(familyId, siteId) {
    this.intakesService.getProductList(familyId, siteId).subscribe((res) => {
      if (res) {
        _.forEach(res.lstProductSection, (menuItem) => {
          if (menuItem.parent == '0') {
            let groupedMenu = _.groupBy(res.lstProductSection, (menu) => {
              return menu.parent == menuItem.id;
            });
            menuItem['submenu'] = groupedMenu.true;
          }
        })
        res = _.filter(res.lstProductSection, (response) => {
          return !response.parent;
        });
        console.log('menu' + res);
        this.menu = res;
      }
    })
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      date: 'date',
    };

    this.columnHeaders = [
      { field: 'statusName', header: 'Status' },
      { field: 'date', header: 'Date' },
      { field: 'comment', header: 'Comment' },
    ];
  }

  updateIntakeApproval(data, redirectTo?) {
    this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
    let formValid = true;
    if (data.Status == 3)
      formValid = this.checkValidation();
    if (formValid) {
      this.loading = true;
      this.intakesService.updateIntakeApproval(data).subscribe((res) => {
        if (res) {
          this.changeStatus.next();
          if (data.Status == 3) {
            let sectionStatus = {
              "IntakeId": data.IntakeId,
              "lstIntakeSection": [{
                "ProductSectionId": this.selectedMenu.submenu[0].id,
                "Status": 2,
                "Percentage": 100,
                "ParentId": this.selectedMenu.id
              }]
            };
            this.postIntakeSection(sectionStatus);
            this.getIntakeProductList(this.intakeId, true);
          }
          if (redirectTo) {
            this.router.navigate([redirectTo]);
          }
          this.toastrService.success(this.message, 'Success');
          this.loading = false;
        }
      },
        err => {
          this.loading = false;
          console.log(err.error.message);
        });

    } else {
      this.loading = false;
      this.toastrService.error("Please complete substeps first", 'Error');
    }

  }
  reviewIntake() {
    this.message = 'Intake has been submitted successfully'
    let data = {
      IntakeId: this.intakeId,
      FamilyId: this.intakeTypeId,
      Status: 3,
      Comment: this.Comment,
      UserId: this.userInfo.userId,
      IsSubmit: 1
    };
    this.updateIntakeApproval(data);

  }

  acceptIntake() {
    this.message = 'Intake has been Accepted';
    let data = {
      IntakeId: this.intakeId,
      FamilyId: this.intakeTypeId,
      Status: 5,
      Comment: this.Comment,
      UserId: this.userInfo.userId,
      IsSubmit: 0
    };
    this.updateIntakeApproval(data, `${this.currentRole.url}/intake-details`);
  }
  rejectIntake() {
    this.message = 'Intake has been Reverted'
    let data = {
      IntakeId: this.intakeId,
      FamilyId: this.intakeTypeId,
      Status: 4,
      Comment: this.Comment,
      UserId: this.userInfo.userId,
      IsSubmit: 0
    };
    this.updateIntakeApproval(data, `${this.currentRole.url}/intake-details`);
  }
  bulidSite() {
    this.router.navigate([`${this.currentRole.url}/build-site/${this.intakeId}`]);
  }

  checkValidation() {
    this.errorMsg = [];
    _.forEach(this.intakesService.basicInfoRequiredData, (menu) => {
      _.forEach(menu.submenu, (data) => {
        _.forEach(data.requiredFields, (item) => {
          if (!item.status) {
            // this.errorMsg.push(item['errorMsg']);
            this.errorMsg.push(item);
          }
        });
      })
    })

    let incompleteSubmenu = _.find(this.intakesService.basicInfoRequiredData, (item) => {
      return item.name.toLowerCase() != 'review' && item.percentage < 100;
    })
    console.log(this.errorMsg);
    return (this.errorMsg.length || incompleteSubmenu) ? false : true;
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, sectionStatus.IntakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getIntakeBasic(intakeId) {
    this.intakesService.getIntakeBasicReview(intakeId).subscribe((res) => {
      if (res) {
        this.savedFormData = res;
        this.selectedColorList = res.colorPalette ? res.colorPalette.split(',') : [];
        this.affiliationLogo = res.affiliateLogo ? res.affiliateLogo.split(',') : [];
        this.photography = res.photography ? res.photography.split(',') : [];
        _.assign(this.intakesService.commonBasicInfoObj, this.savedFormData);
      }
    },
      err => {
        console.log(err.error.message);
      });
  }
  getIntakeSavedPages(intakeId) {
    this.intakesService.getIntakeSavedPages(intakeId, 0).subscribe((res) => {
      if (res) {
        this.savePages = res;
      }
    })
  }
  editPage(menuId, sectionId, sectionName,pages){
   this.dataService.changeMessage(false);
   this.dataService.setData(pages);
   this.editClicked.emit({ "menuId": menuId, "sectionId": sectionId, "sectionName": sectionName });
  }

  deletePage(pages) {
    let msg = 'Are you sure to delete this page?';
    if (confirm(msg)) {
      this.deleteInkateCustomPages(pages.intakeId, pages.itemId, pages.productSectionId);
    }
  }
  deleteInkateSavedPage(intakeId,pageId,parentId, productSectionId){
    this.intakesService.deleteIntakeSavedPages(intakeId,pageId,parentId,productSectionId).subscribe((res:any) => {
      if (res) {
        this.toastrService.success('Deleted Successfully', 'Success');
        this.getIntakeSavedPages(this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });

  }
  deleteInkateCustomPages(intakeId,title, productSectionId){
    this.intakesService.deleteCustomPages(intakeId,title, productSectionId).subscribe((res:any) => {
      if (res) {
        this.toastrService.success('Deleted Successfully', 'Success');
        this.getIntakeSavedPages(this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });

  }


  
  getHomepageData(intakeId) {
    this.intakesService.getHomepageData(intakeId).subscribe((res) => {
      if (res) {
        this.homePageData = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  deleteContentSection(pages){

    let msg = 'Are you sure to delete this page?';
    if (confirm(msg)) {
      for (var i = 0; i < this.homePageData.intakeBasicContents.length; i++) {
        if (this.homePageData.intakeBasicContents[i].id === pages.id) {
            this.homePageData.intakeBasicContents.splice(i, 1);
        }
     }
    let contentSectionData=this.homePageData;
    this.intakesService.postContentHomepage(contentSectionData).subscribe((res) => {
      if (res) {
        this.getHomepageData(this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
    }
  }
  openPageContent(page) {
    let model = this.modalService.open(PageSampleComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title', windowClass: 'pop-height' });
    let data = {
      pageName: page.pageName,
      content: page.content,
      title: '',
      intakeId: this.intakeId,
      intakeTypeId: this.intakeTypeId
    };
    model.componentInstance.data = data;

  }

  openAssetsPopup(page) {
    let model = this.modalService.open(AssetsComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    page.asset.split(",").forEach(function (file) {
      model.componentInstance.assets.push({ path: file, name: file.split('/')[file.split('/').length - 1] })
    });
    model.componentInstance.source = 'review';
  }

  openPhotographyPopup(photography) {
    let model = this.modalService.open(AssetsComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
        model.componentInstance.title='Your Photography';
        photography.split(",").forEach(function (file) {
          model.componentInstance.assets.push({ path: file, name: file.split('/')[file.split('/').length - 1] })
        });
        model.componentInstance.source = 'review';
  }
  openAffiliateLogo(Affiliate) {
    let model = this.modalService.open(AssetsComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
        model.componentInstance.title='Affiliate Logo';
        Affiliate.split(",").forEach(function (file) {
          model.componentInstance.assets.push({ path: file, name: file.split('/')[file.split('/').length - 1] })
        });
        model.componentInstance.source = 'review';
  }

  openHomePage(page) {
    let model = this.modalService.open(PageSampleComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    let data = {
      pageName: page.name,
      content: page.content,
      title: page.title
    };
    model.componentInstance.data = data;

  }
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  openPage(menu) {
    this.activeTab = menu;
  }
  getEnhancedData(intakeId) {
    this.intakesService.getEnhancedData(intakeId).subscribe((res) => {
      if (res) {
        this.enhacedData = res;
        _.assign(this.intakesService.commonBasicInfoObj, this.enhacedData);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getPremiumData(intakeId) {
    this.intakesService.getIntakePremium(intakeId).subscribe((res) => {
      if (res) {
        this.premiumData = res;
        _.assign(this.intakesService.commonBasicInfoObj, this.premiumData);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  goToIntakePage(menuId, sectionId, sectionName) {
    this.dataService.changeMessage(true);
    this.editClicked.emit({ "menuId": menuId, "sectionId": sectionId, "sectionName": sectionName });
  }

  openApprovalPopup() {
    var modal = this.modalService.open(ApprovalPopupComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static',windowClass: 'max-600' });
    modal.componentInstance.familyId = this.intakeTypeId;
    modal.componentInstance.approve = (menu) => {
      let pendingMenu = _.find(menu, (item) => { return !item.hasOwnProperty('approvalStatus') })
      if (!pendingMenu) {
        let reqData = {
          ApprovalStatus: _.map(menu, (menuItem) => {
            return {
              intakeId: this.intakeId,
              familyId: menuItem.familyId,
              ProductSectionId: menuItem.id,
              status: menuItem.approvalStatus
            };
          })
        }
        this.postApprovalStatus(reqData);
        modal.close();
      } else {
        modal.componentInstance.errorMsg = "Please fill mandatory fields";
      }

    };
    modal.componentInstance.disapprove = () => {
      modal.close();
    };
  }

  postApprovalStatus(reqData) {
    this.intakesService.postApprovalStatus(reqData).subscribe((res) => {
      if (res) {
        this.reviewIntake();
        // this.toastrService.success("df",'Success');
        // this.premiumData=res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getIntakeProductList(intakeId, isSubmit?) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res) => {
      if (res) {
        let selectedItem = _.find(res['sfIntakeProductFamily'], (item) => {
          return item.intakeTypeId == this.intakeTypeId;
        })

        this.changeStatus.next(isSubmit);
        this.menuService.intakeProductFamily.next({ family: res['sfIntakeProductFamily'], selectedProduct: selectedItem });
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  // getComment(){
  //   this.intakesService.getComment(this.intakeId,this.intakeTypeId).subscribe((res)=>{
  //     if(res){
  //         this.comments=res;
  //     }
  //   },
  //   err=>{
  //     throw err;
  //   })
  // }

}
