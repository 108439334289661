import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportingService } from '../services/reporting.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '../../../services/http.service';
import { AddInsightDataComponent } from '../add-insight-data/add-insight-data.component';
import * as tooltipText from '@shared/text/tooltips.json';

@Component({
  selector: 'app-facebook-ads-report',
  templateUrl: './facebook-ads-report.component.html',
  styleUrls: ['./facebook-ads-report.component.css'],
  providers: [DatePipe]
})
export class FacebookAdsReportComponent {
  reportData;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  @Input() siteId;
  @Output() openCallTraking = new EventEmitter<any>();
  @Input() callTracking;
  clicksBreakdownByAge;
  clicksBreakdownByGender;
  impressionsBreakdownByAge;
  impressionsBreakdownByGender;
  selectedMenu;
  insightData;
  today = new Date();
  _startDate; _endDate;
  isShowPMS=false;
  ttText: any;
  submenu = [
    { id: 1, name: 'Leads' },
    {id: 2, name: 'Insights'},
    { id: 3, name: 'Campaign Performance' }
  ]
  reportParams = {
    startDate: moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY'),
    endDate: moment(moment()).subtract(1, 'months').endOf('month').format('MM/DD/YYYY'),
    _startDate: moment().subtract(1, 'months').startOf('month').format('MM/DD/YY'),
    _endDate: moment(moment()).subtract(1, 'months').endOf('month').format('MM/DD/YY')
  };
  constructor(
    private reportingService: ReportingService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    public modalService: NgbModal,
    private datePipe: DatePipe
  ) {
    let date = new Date();
    this.today = new Date(date.getFullYear(), date.getMonth(), 0);
  }

  ngOnChanges() {
    this._startDate = moment(this.reportParams.startDate).format("MM/DD/YY");
    this._endDate = moment(this.reportParams.endDate).format("MM/DD/YY");
    this.selectedMenu = this.submenu[0];
    this.getFacebookReport(this.siteId, this.reportParams);
  }

  ngOnInit() {
    this.ttText = (tooltipText as any).default;
  }

  onValueChange(event) {
    this.reportParams['startDate'] = moment(event[0]).format('MM/DD/YYYY');
    this.reportParams['endDate'] = moment(event[1]).format('MM/DD/YYYY');
    this._startDate = moment(event[0]).format('MM/DD/YY');
    this._endDate = moment(event[1]).format('MM/DD/YY')
    this.getFacebookReport(this.siteId, this.reportParams);
  }


  getFacebookReport(siteId, params) {
    let that=this;
    this.resetJSLegend();
    params['formattedStartDate'] = params.startDate;
    params['formattedEndDate'] = params.endDate;
    this.reportingService.getFacebookAdsReport(siteId, params).subscribe((res) => {
      if (res) {
          that.setFacebookData(res);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }
setFacebookData(res){
  this.reportData={};
  this.reportData = res;
  let label=['Form Leads'];
  let data=[this.reportData.OverAll.leads];
  let backgroundColor=['#F1911F'];
  if(this.callTracking&&this.reportData.IsFacebookSetup){
    label.push('Qualified Phone Leads');
    data.push(parseInt(this.reportData.Lead.qualifiedLeads));
    backgroundColor.push('#943BC9');
  }
  if(this.reportData.IsOnlineAppointmentSetup){
    label.push('Online Appointment Booking');
    data.push(this.reportData.OnlineAppointment);
    backgroundColor.push('#46A99C');
  }
  this.reportData['chart'] = {
    label: label,
    data: data,
    colors: [
      {
        backgroundColor: backgroundColor
      }
    ]
  };
  this.clicksBreakdownByAge = this.getChartData(this.reportData.ClicksBreakdownOnAge.data, 'age', 'clicks', ['#388982', '#46A99B', '#53C6B6']);
  this.clicksBreakdownByGender = this.getChartData(this.reportData.ClicksBreakdownOnGender.data, 'gender', 'clicks', ['#F1931F', '#3F84E2', '#DFDFDF']);
  this.impressionsBreakdownByAge = this.getChartData(this.reportData.ImpressionsBreakdownOnAge.data, 'age', 'impressions', ['#388982', '#46A99B', '#53C6B6']);
  this.impressionsBreakdownByGender = this.getChartData(this.reportData.ImpressionsBreakdownOnGender.data, 'gender', 'impressions', ['#F1931F', '#3F84E2', '#DFDFDF']);
}
  getChartData(data, label, dataItem, bgColors) {
    if(data.length!=0&& data.length!=bgColors.length)
      bgColors=this.addColor((data.length-bgColors.length),bgColors)
    let values = _.map(data, dataItem);
    let sum = 0;
    _.forEach(values, (val) => {
      sum += parseInt(val);
    })
    let obj = {
      label: [],
      data: [],
      colors: [
        {
          backgroundColor: bgColors
        }
      ]
    }
    _.forEach(data, (item) => {
      obj['label'].push(item[label]);
      obj['data'].push(_.round(item[dataItem] / sum * 100, 2));
    })
    obj['total'] = sum;
    return obj;
  }
addColor(length,bgColors){
  let MbgColors=['#8cd9cf','#b3e6df','#c6ece7','#d9f2ef','#79d2c6','#ecf9f7','#66ccbe'];
  for(let i=0;i<length;i++){
      bgColors.push(MbgColors[i]);
  }
  return bgColors;
}

  gotoCallTrackingReport() {
    this.reportParams['adSource'] = 'Facebook Ads';
    this.openCallTraking.emit({ productId: 4, params: this.reportParams });
  }

  downloadReport(report: string) {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = 'none';
    var element = document.getElementById('report');
    var collapsedElements = document.getElementsByClassName('collapse');
    var showLess = document.querySelectorAll('.show-less, .titleWithLink');
    var titleWithoutLink = document.querySelectorAll('.titleWithoutLink');
    _.forEach(collapsedElements, (element) => {
      element.className += ' show';
    })
    _.forEach(showLess, (element) => {
      element.style.display = 'none';
    })
    _.forEach(titleWithoutLink, (element) => {
      element.style.display = 'block';
    })
    var opt = {
      margin: 0,
      filename: `${this.selectedMenu.name}.pdf`,
      jsPDF: { unit: 'in', format: 'b3', orientation: 'portrait' }
    };
    if (report == 'print') {
      html2pdf().from(element).set(opt).output('dataurlnewwindow').then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        _.forEach(showLess, (element) => {
          element.style.display = 'block';
        })
        _.forEach(titleWithoutLink, (element) => {
          element.style.display = 'none';
        })
      });
    } else {
      html2pdf().from(element).set(opt).save().then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        _.forEach(showLess, (element) => {
          element.style.display = 'block';
        })
        _.forEach(titleWithoutLink, (element) => {
          element.style.display = 'none';
        })
      });
    }


  }
  resetJSLegend() {
    let legends = document.querySelectorAll('[id^="js-legend"]');
    [].forEach.call(legends, function (element) {
      element.id = "js-legend";
    })
  }

  getInsightsData(insight?, mode?){
    if(this.selectedMenu.id == 2){
      let params = {
        siteId: this.siteId,
        masterTypeId: 44,
        masterId: insight ? insight.masterId : '',
        startDate: this.reportParams.startDate,
        endDate: this.reportParams.endDate
      }
      let selectedInsight;
      this.reportingService.getWebsiteInsightsData(params).subscribe((res) => {
        if (!_.isEmpty(res)) {
          insight ? selectedInsight = res : this.insightData = res;
          if(mode == 'edit'){
            this.openInsightPopup(selectedInsight);
          }
        } else {
        }
      })
    }
  }
  openInsightPopup(params){
    var model = this.modalService.open(AddInsightDataComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
    model.componentInstance.siteId = this.siteId;
    let insightData = params[0];
    insightData['masterTypeId'] = 44;
    insightData['startDate'] = this.reportParams.startDate;
    insightData['endDate'] = this.reportParams.endDate;
    model.componentInstance.params = model.componentInstance.existingData = insightData;
    // model.componentInstance.intake = intake;
    // model.componentInstance.masterType = this.masterType.name
    model.result.then((result) => {
      this.getInsightsData();
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }

openPMS(){
  this.reportParams['adSource']=3;
  this.isShowPMS=true
}

}
