// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
  width: 100%;
}

.shortcut-container, .tip-container {
  align-content: stretch;
  align-items: flex-start;
  flex-direction: row;
  flex-grow: 1;
  gap: 2rem 1rem;
  justify-content: space-between;
}

.shortcut-container > a, .tip-container ::ng-deep app-tip-block {
  flex-basis: 49%;
  flex-grow: 1;
  margin-bottom: 4rem;
}

.shortcut-container > a {
  font-weight: bold;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/intakes/local-listings/local-listings-your-practice/local-listings-your-practice.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":["h2 {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 0;\n  width: 100%;\n}\n\n.shortcut-container, .tip-container {\n  align-content: stretch;\n  align-items: flex-start;\n  flex-direction: row;\n  flex-grow: 1;\n  gap: 2rem 1rem;\n  justify-content: space-between;\n}\n\n.shortcut-container > a, .tip-container ::ng-deep app-tip-block {\n  flex-basis: 49%;\n  flex-grow: 1;\n  margin-bottom: 4rem;\n}\n\n.shortcut-container > a {\n  font-weight: bold;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
