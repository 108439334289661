import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ChartType, ChartOptions, ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration } from 'chart.js';
import { customLayout } from '../custom-tooltip';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css']
})
export class DoughnutChartComponent implements OnInit, AfterViewInit {
  @ViewChild(BaseChartDirective) chartComponent: BaseChartDirective;
  @Output() chartClicked = new EventEmitter<any>();
  @Input() data: number[];
  @Input() label: string[];
  @Input() isLegend: boolean;
  @Input() chartColors: string[];
  @Input() customValue: string;
  @Input() isTotalDisplay: boolean;
  @Input() aspectRatio: number;
  @Input() total: number;

  public doughnutChartLabels: string[] = [];
  public doughnutChartData: ChartData<'doughnut'> = {
    labels: [],
    datasets: [
      { data: [], label: 'Dataset 1' }
    ]
  };
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'];

  colorMapping = {
    'bbb': '#0D46AD',
    'citysearch': '#C3E8FF',
    'diamondcertified': '#011C57',
    'direct': '#82CFFF',
    'facebook': '#2C74D9',
    'google': '#E53D2F',
    'healthgrades': '#44A0FC',
    'healthyhearing': '#003481',
    'hearingtracker': '#FFC4C4',
    'judysbook': '#67000D',
    'yellowpages': '#F86C5E',
    'yelp': '#B21117',
    'yp': '#B21117',
    'zocdoc': '#FD958D',
    'qualifiedformleads': '#F1931F',
    'qualifiedcallleads': '#46A99C',
    'onlineappointmentbooking': '#953bc9',
    'default': '#1BB5A6'
  };

  ngOnInit() {
    this.initializeChart();
  }

  ngOnChanges() {
    this.initializeChart();
  }

  ngAfterViewInit() {
    this.updateLegend();
  }

  initializeChart() {
    this.doughnutChartOptions = {
      plugins: {
        legend: {
          display: this.isLegend ?? true,
          position: 'right',
        },
        tooltip: {
          enabled: false,
          external: function (tooltipModel) {
            customLayout.getLayout(tooltipModel, this.chart.canvas);
          }
        },
      },
      animation: {
        onProgress: () => {
          this.setTotal(this.chartComponent.chart);
        },
      },
      aspectRatio: this.aspectRatio ?? 2,
    };

    const normalizedLabels = this.label.map(label => this.normalizeCategory(label));
    const colors = normalizedLabels.map(label => this.colorMapping[label] || this.colorMapping['default']);



    this.doughnutChartData = {
      labels: this.label,
      datasets: [{
        data: this.data,
        backgroundColor: colors,
        label: 'Your Data Label'
      }]
    };

    // Ensure the legend is updated
    setTimeout(() => this.updateLegend(), 0);
  }

  normalizeCategory(category: string): string {
    return category.toLowerCase().replace(/\s+/g, '');
  }

  updateLegend() {
    const chart = this.chartComponent.chart;
    const legendId = `js-legend-${this.generateUUID()}`;
    const element = document.getElementById("js-legend");
    if (element) {
      let legendHTML = '';

      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((data, index) => {
          const style = `background-color:${dataset.backgroundColor[index]};`;
          const text = chart.data.labels[index] || '';

          legendHTML += `<span style="${style}">&nbsp;&nbsp;&nbsp;&nbsp;</span> ${text}<br>`;
        });
      });

      element.innerHTML = legendHTML;
    }
  }

  generateUUID() {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  setTotal(chart) {
    const ctx = chart.ctx;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    const meta = chart.getDatasetMeta(0);
    const txt = (this.isTotalDisplay && meta.total !== 0) ? this.total || Math.round(meta.total) : '';
    const color = '#212529';
    const fontStyle = '"Source Sans Pro", sans-serif';
    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
    const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
    ctx.font = `Bold 30px ${fontStyle}`;
    ctx.fillStyle = color;
    ctx.fillText(txt, centerX, centerY);
  }
}

