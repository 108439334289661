import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { IntakesService } from '../../../services/intakes.service';
import { Ppc } from '../../../models/intakePpc';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { SectionStatus } from '../../basic/review/section-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../../services/http.service';
@Component({
  selector: 'app-competitor-information',
  templateUrl: './competitor-information.component.html',
  styleUrls: ['./competitor-information.component.css']
})
export class CompetitorInformationComponent implements OnInit {
  competitorInfoForm: UntypedFormGroup;
  selectedIntakeData;
  intakeId;
  siteId;
  @Input() productType;
  @Input() existingData;
  @Input() sectionId;
  @Input() intakeTypeId;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() changeStatus= new EventEmitter<any>(); 
  constructor(
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    public dataService:DataService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.siteId = params.siteId;
        
      }
    });
   }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm(){
    this.competitorInfoForm = this.formBuilder.group({
      intakeId : this.intakeId ? this.intakeId : null,
      intakeTypeId : this.intakeTypeId ? this.intakeTypeId : null,
      productType : new UntypedFormControl(this.productType.toUpperCase()),
      competitorName1 : new UntypedFormControl(this.existingData ? this.existingData.competitorName1 : null),
      website1 : new UntypedFormControl(this.existingData ? this.existingData.website1 : null,Validators.pattern(this.dataService.urlRegex)),
      competitorName2 : new UntypedFormControl(this.existingData ? this.existingData.competitorName2 : null),
      website2 : new UntypedFormControl(this.existingData ? this.existingData.website2 : null,Validators.pattern(this.dataService.urlRegex)),
      competitorName3 : new UntypedFormControl(this.existingData ? this.existingData.competitorName3 : null),
      website3 : new UntypedFormControl(this.existingData ? this.existingData.website3 : null,Validators.pattern(this.dataService.urlRegex))
    
    });
  }

  save(form, mode?){
    if(form.valid){
      let intakeData =form.value;
      intakeData.date = intakeData.date ? intakeData.date.getMonth()+1+"/"+intakeData.date.getDate()+"/"+intakeData.date.getFullYear() : intakeData.date;
      _.assign(this.existingData, intakeData);
      this.intakesService.savePpcIntake(this.existingData).subscribe(res => {
        if (res) {
          this.changeStatus.next();
          this.toastrService.success('Saved successfully', 'Success');

          this.intakesService.updateRequiredFieldsStatus(intakeData);
          let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, this.intakeId);
          this.postIntakeSection(sectionStatus);
          if(mode == 'continue'){
            this.goToNext('parent');
          }
        } 
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
    }else{
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        // this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }
  goBack() {
    this.backClicked.next();
  }

  goToNext(menu){
    this.continueClicked.next(menu);
  }
}
