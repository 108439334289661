import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ChartDataset, ChartOptions, Color } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { customLayout } from '../custom-tooltip'
// import * as pluginAnnotations from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.css']
})

export class AreaChartComponent implements OnInit {
  @Input() data;
  @Input() label;
  @Input() height;
  @Input() aspectRatio;
  @Input() customValue;
  public lineChartData: ChartDataset[];
  public lineChartLabels: String[];
  public lineChartOptions: (ChartOptions & { annotation: any });
  public lineChartColors = [
    {
      borderColor: '#35847D',
      pointBorderColor: '#35847D',
      pointBackgroundColor: '#35847D',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#333',
      backgroundColor: 'rgba(70, 169, 156, 0.5)'
    }
  ];
  public lineChartLegend = false;
  public lineChartType = 'line';

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  constructor() { }

  ngOnChanges() {
    this.ngOnInit();
  }

  ngOnInit() {
    const customValue = this.customValue || '';
    this.lineChartData = [
      { data: this.data, fill: false, label: '' }
    ];
    this.lineChartLabels = this.label;
    this.lineChartOptions = {
      responsive: true,
      plugins: {
        tooltip: {
          enabled: false,
          callbacks: {
            label: function(tooltipItem) {
              const chart = (this as any).chart;
              const datasetLabel = chart.data.datasets[tooltipItem.datasetIndex].label || '';
              const dataValue = chart.data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex];
              const customValue = "Your custom value here";  // Define customValue or ensure it's accessible in this scope
              return datasetLabel + ': ' + customValue + dataValue;
            },
            title: function() {
              return '';  // Return an empty title.
            }
          },
          external: function(tooltipModel) {
            // Ensure customLayout.getLayout is defined and accessible here.
            customLayout.getLayout(tooltipModel, (this as any).chart.canvas);
          }
        }
      },
      elements: {
        line: {
          tension: 0  // This sets the bezier curve tension of the line. 0 means straight lines.
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawOnChartArea: false,
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            display: true,
            drawOnChartArea: true,
          },
          position: 'left',
        }
      },
      annotation: {},
    };
    
    
    if(this.aspectRatio){
      this.lineChartOptions.aspectRatio = this.aspectRatio;
    }
  }
  chartHovered(event) {

  }
  chartClicked(event) {

  }
}
