// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen {
  div {
    padding: .25rem 1rem;
  }

  p {
    font-style: italic;
    margin-bottom: 0;
  }

  img {
    min-width: 100%;
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  img {
    max-width: 45%;
    min-width: unset;
    margin: 2%;
  }
}

@media screen and (min-width: 1400px) {
  img {
    max-width: 35%;
  }
}

@media screen and (min-width: 1601px) {
  img {
    max-width: 15%;
    margin: .25rem 1.5% 1rem 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/logos/partnership-logos/partnership-logos.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,oBAAoB;EACtB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,cAAc;IACd,gBAAgB;IAChB,UAAU;EACZ;AACF;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,cAAc;IACd,0BAA0B;EAC5B;AACF","sourcesContent":["@media screen {\n  div {\n    padding: .25rem 1rem;\n  }\n\n  p {\n    font-style: italic;\n    margin-bottom: 0;\n  }\n\n  img {\n    min-width: 100%;\n    margin-bottom: 1.5rem;\n  }\n}\n\n@media screen and (min-width: 1200px) {\n  img {\n    max-width: 45%;\n    min-width: unset;\n    margin: 2%;\n  }\n}\n\n@media screen and (min-width: 1400px) {\n  img {\n    max-width: 35%;\n  }\n}\n\n@media screen and (min-width: 1601px) {\n  img {\n    max-width: 15%;\n    margin: .25rem 1.5% 1rem 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
