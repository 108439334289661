import { Component, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-frame-close-button',
  templateUrl: './modal-frame-close-button.component.html',
  styleUrls: ['./modal-frame-close-button.component.css']
})
export class ModalFrameCloseButtonComponent implements OnInit {
  @Output() closeFunction: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
