import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ReportingService } from '../../services/reporting.service';
import * as _ from 'lodash';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { HttpService } from '../../../../services/http.service';
import * as tooltipText from '@shared/text/tooltips.json';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  @Input() siteId;
  @Input() reportParams;
  @Input() callTracking;
  @Output() sendDataToParent = new EventEmitter<any>();
  @Output() openCallTracking = new EventEmitter<any>();
  reportData:any;
  sourceChart = {data:[],label:[]};
  today = new Date();
  chartColors = [
    {
      backgroundColor: [
        '#F1931F',
        '#46A99C',
        '#953bc9'
    ]
    }
  ];
  isShowPMS=false;
  ttText: any;
  constructor(
    private reportingService: ReportingService,
    private httpService: HttpService,
    private toastrService: ToastrService
  ) {  }

  ngOnInit() {
    this.ttText = (tooltipText as any).default;
  }

  ngOnChanges(){
    this.reportParams = {
      startDate: moment().startOf('month').format('MM/DD/YYYY'),
      endDate: moment(moment()).endOf('month').format('MM/DD/YYYY'),
      _startDate: moment().startOf('month').format('MM/DD/YY'),
      _endDate: moment(moment()).endOf('month').format('MM/DD/YY')
    }
    this.getReport(this.siteId, this.reportParams);
  }

  getReport(siteId, params) {
    this.reportData={};
    this.resetJSLegend();
    params['site_id'] = siteId;
    this.reportingService.getContactLeadReport(params).subscribe((res) => {
      if (res) {
        this.reportData = res;
        this.sourceChart = {
          data: [Number(this.reportData.totalQualifiedLeads),
            Number(this.reportData.callTracking.qualifiedLeads ?? 0),
            Number(this.reportData.onlineAppointment)],
          label: ['Qualified Form Leads', 'Qualified Call Leads', 'Online Appointment Booking']
        };
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  onValueChange(event) {
    this.reportParams['startDate'] = moment(event[0]).format('MM/DD/YYYY');
    this.reportParams['endDate'] = moment(event[1]).format('MM/DD/YYYY');
    this.reportParams['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.reportParams['_endDate'] = moment(event[1]).format('MM/DD/YY');
    this.getReport(this.siteId, this.reportParams);
  }

  viewFormDetails(){
    this.sendDataToParent.emit({data:true, params: this.reportParams});
  }

  gotoCallTrackingReport(){
    this.reportParams['adSource'] = 'Website';
    this.openCallTracking.emit({productId: 4, params: this.reportParams});
  }

  downloadReport(report?: string) {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = 'none';
    document.getElementById("growth-icon").style.display = 'none';
    document.getElementById("growth-img").style.display = 'block';
    var element = document.getElementById('report');
    var collapsedElements = document.getElementsByClassName('collapse');
    _.forEach(collapsedElements, (element) => {
      element.className += ' show';
    });
    var opt = {
      margin: 0.3,
      filename: 'website-contacts.pdf',
      jsPDF: { unit: 'in', format: 'b3', orientation: 'portrait' }
    };
    if (report == 'print') {
      html2pdf().from(element).set(opt).output('dataurlnewwindow').then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        document.getElementById("growth-icon").style.display = 'block';
        document.getElementById("growth-img").style.display = 'none';
      });
    } else {
      html2pdf().from(element).set(opt).save().then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        document.getElementById("growth-icon").style.display = 'block';
        document.getElementById("growth-img").style.display = 'none';
      });
    }


  }
  resetJSLegend() {
    let legends = document.querySelectorAll('[id^="js-legend"]');
    [].forEach.call(legends, function (element) {
      element.id = "js-legend";
    })
  }
  openPMS(){
    this.reportParams['adSource']=1;
    this.isShowPMS=true;
  }
  goToContacts(){
    this.isShowPMS=false;
  }
}