import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from '../service/site.service';

@Component({
  selector: 'app-modify-subscription',
  templateUrl: './modify-subscription.component.html',
  styleUrls: ['./modify-subscription.component.css']
})
export class ModifySubscriptionComponent implements OnInit {
  
siteId;
product;
productName;
modifiedData = {
  quantity: 0,
  unitPrice: 0,
  prorate: false
}

  constructor(
    public activeModal: NgbActiveModal,
    private siteService: SiteService,
  ) { }

  ngOnInit() {
    
    this.modifiedData.quantity = this.product.quantity;
    this.modifiedData.unitPrice = this.product.unitPrice;
    this.modifiedData['productId'] = this.product.productId;
    let obj = {
      siteId: this.siteId
    }
    this.siteService.getProductBySite(obj).subscribe((res) => {
      if (res && res.result && res.result.products && res.result.products.length > 0) {
        let foundProductName = null;
    
        for (const productGroup of res.result.products) {
          const matchingProduct = productGroup.products.find(product => product.productId === this.product.productId);
    
          if (matchingProduct) {
            foundProductName = matchingProduct.productName;
            break; // Break out of the loop once the matching product is found
          }
        }
    
        if (foundProductName) {
          this.productName = foundProductName;
        } else {
          console.error('Matching product not found');
          // Handle the case where no matching product is found
        }
      } else {
        console.error('No products available in the response');
        // Handle the case where the products array is not available or empty
      }
    });
    
    
    
    
    
  }

  dismissModal() {
    this.activeModal.close();
  }
  modifySubscription(){
    // let total=this.modifiedData.quantity*this.modifiedData.unitPrice;
    // if(total<=999999.99)
      this.activeModal.close(this.modifiedData);
  }
  

}
