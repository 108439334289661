import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private httpService: HttpService
  ) { }

  getDasboardData(){
    return this.httpService.getApi(`Report/Dashboard`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }
}
