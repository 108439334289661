// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-design .nav-tabs .nav-link.active {
    background: #4296F7;
    color: #fff !important;
    text-decoration: none;
}

@media screen {
  .wizard-content {
    max-width: unset !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/admin/blue-print-process/blue-print-process.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;EACE;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".panel-design .nav-tabs .nav-link.active {\n    background: #4296F7;\n    color: #fff !important;\n    text-decoration: none;\n}\n\n@media screen {\n  .wizard-content {\n    max-width: unset !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
