// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: capitalize;
}

section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/forms/input-group/input-group.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb","sourcesContent":["h2 {\n  font-size: 1.1rem;\n  font-weight: bold;\n  text-transform: capitalize;\n}\n\nsection {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
