import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css'],
})
export class PaginatorComponent implements OnInit {
  @Input() currentFirstResult: number;
  @Input() rows = 10;
  @Input() showCurrentPageReport = true;
  @Input() totalRecords: number;

  @Output() onPageChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Returns the number of pages in a table
   *
   * @return {Number} The number of pages in a table
   */
  get numberOfPages(): number {
    if (this.totalRecords % this.rows === 0) {
      return Math.floor(this.totalRecords / this.rows);
    } else if (this.totalRecords % this.rows > 0) {
      return Math.floor(this.totalRecords / this.rows) + 1;
    }

    return 1;
  }

  /**
   * Returns the string with the number of pages for the paginator
   *
   * @return {String} The paginator's pagination message
   */
  get paginationMessage(): string {
    if (this.currentFirstResult != null) {
      const pageFirstResult = this.currentFirstResult + 1;
      const pageLastResult = Math.min(this.currentFirstResult + this.rows, this.totalRecords);

      return `${pageFirstResult}-${pageLastResult} of ${this.totalRecords}`;
    }

    return `of ${this.numberOfPages.toString()}`;
  }

  pageChange($event): void {
    this.onPageChange.emit($event);
  }
}
