// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all {
  a {
    font-style: unset;
  }

  a > fa-icon {
    color: white;
  }

  fa-icon {
    --blue-grey: rgb(48, 62, 78);

    color: var(--blue-grey);
    margin-right: .5rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: .2rem;
  }

  img {
    --image-radius: 120px;

    border-radius: 50%;
    border: #DCE0EB solid 4px;
    height: var(--image-radius);
    width: var(--image-radius);
  }

  fa-icon, p {
    font-size: 1rem;
    margin-bottom: .2rem;
  }

  .btn {
    color: white;
  }
  .email-me {
    display: none;
  }
}

@media (max-width: 1460px) {
  .email {
    display: none
  }
  .email-me {
    display: inline;
  }
}

@media (min-width: 1461px) {
  .email {
    display: inline;
  }
  .email-me {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/contact-panel/contact-panel.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,4BAA4B;;IAE5B,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,oBAAoB;EACtB;;EAEA;IACE,qBAAqB;;IAErB,kBAAkB;IAClB,yBAAyB;IACzB,2BAA2B;IAC3B,0BAA0B;EAC5B;;EAEA;IACE,eAAe;IACf,oBAAoB;EACtB;;EAEA;IACE,YAAY;EACd;EACA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE;EACF;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,aAAa;EACf;AACF","sourcesContent":["@media all {\n  a {\n    font-style: unset;\n  }\n\n  a > fa-icon {\n    color: white;\n  }\n\n  fa-icon {\n    --blue-grey: rgb(48, 62, 78);\n\n    color: var(--blue-grey);\n    margin-right: .5rem;\n  }\n\n  h3 {\n    font-size: 1.5rem;\n    margin-bottom: .2rem;\n  }\n\n  img {\n    --image-radius: 120px;\n\n    border-radius: 50%;\n    border: #DCE0EB solid 4px;\n    height: var(--image-radius);\n    width: var(--image-radius);\n  }\n\n  fa-icon, p {\n    font-size: 1rem;\n    margin-bottom: .2rem;\n  }\n\n  .btn {\n    color: white;\n  }\n  .email-me {\n    display: none;\n  }\n}\n\n@media (max-width: 1460px) {\n  .email {\n    display: none\n  }\n  .email-me {\n    display: inline;\n  }\n}\n\n@media (min-width: 1461px) {\n  .email {\n    display: inline;\n  }\n  .email-me {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
