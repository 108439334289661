// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-group a {
  margin: 0 0.5rem
}

.button-group a:last-child {
  margin-right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/site/site-config/site-user/site-user.component.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".button-group a {\n  margin: 0 0.5rem\n}\n\n.button-group a:last-child {\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
