// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  position: relative;
  border-radius: 100%;
  height: 1.25rem;
  margin-right: 0.5rem;
  width: 1.25rem;
}

.circle.untouched {
  background-color: #ECECEC;
  color: #ccc;
}

.circle.active {
  background: rgb(54, 151, 235) url(/assets/images/ellipses-icon.svg) no-repeat center !important; background-size: 67% !important;
}

.circle.completed {
  background: #00A89B;
}

.circle.incomplete {
  background: rgb(255, 165, 0) url(/assets/images/incomplete-icon.svg) no-repeat center; background-size: 15%;
}

.checkmark {
  background: transparent;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 0 2px 2px 0;
  height: 0.75rem;
  left: 18%;
  position: absolute;
  top: 49%;
  transform: rotate(50deg) translate(-50%, -50%);
  width: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/intakes/intake-sidebar-submenu-item-icon/intake-sidebar-submenu-item-icon.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,+FAA+F,EAAE,+BAA+B;AAClI;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qFAAqF,EAAE,oBAAoB;AAC7G;;AAEA;EACE,uBAAuB;EACvB,gCAAgC;EAChC,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;EACf,SAAS;EACT,kBAAkB;EAClB,QAAQ;EACR,8CAA8C;EAC9C,aAAa;AACf","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.circle {\n  position: relative;\n  border-radius: 100%;\n  height: 1.25rem;\n  margin-right: 0.5rem;\n  width: 1.25rem;\n}\n\n.circle.untouched {\n  background-color: #ECECEC;\n  color: #ccc;\n}\n\n.circle.active {\n  background: rgb(54, 151, 235) url(/assets/images/ellipses-icon.svg) no-repeat center !important; background-size: 67% !important;\n}\n\n.circle.completed {\n  background: #00A89B;\n}\n\n.circle.incomplete {\n  background: rgb(255, 165, 0) url(/assets/images/incomplete-icon.svg) no-repeat center; background-size: 15%;\n}\n\n.checkmark {\n  background: transparent;\n  border-color: rgb(255, 255, 255);\n  border-style: solid;\n  border-width: 0 2px 2px 0;\n  height: 0.75rem;\n  left: 18%;\n  position: absolute;\n  top: 49%;\n  transform: rotate(50deg) translate(-50%, -50%);\n  width: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
