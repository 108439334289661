// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-column-left {
  width: 70%;
}

.dashboard-column-right {
  max-width: 30%;
}

.panel-content-table {
  display: flex;
  flex-direction: column;
}

.panel-content-table div {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/core/dashboard/dashboard.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".dashboard-column-left {\n  width: 70%;\n}\n\n.dashboard-column-right {\n  max-width: 30%;\n}\n\n.panel-content-table {\n  display: flex;\n  flex-direction: column;\n}\n\n.panel-content-table div {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
