import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import countries from '../../data/countries.json';
import states from '../../data/states.json';
import { AddressData } from '@shared/interfaces/addresses';
import { getCountrySubdivision, getCountryData, getCountryCodeFromFullName } from '@shared/data/locations';
import { InputGeneric } from '@shared/interfaces/forms';
import { countrySubdivisionLabel, postalCodeLabelType } from '@shared/types/types';

@Component({
  selector: 'app-address-grid-item',
  templateUrl: './address-grid-item.component.html',
  styleUrls: ['./address-grid-item.component.css'],
})
export class AddressGridItemComponent implements OnInit {
  @Input() errorMessage: string;
  @Input() location: AddressData;
  @Input() countrySubdivisionLabel: countrySubdivisionLabel = 'State';
  @Input() postalCodeLabel: postalCodeLabelType = 'ZIP Code'

  @Output() editLink = new EventEmitter<any>();
  @Output() deleteFunction = new EventEmitter<any>();

  countryFullName = '';
  editable = false;
  showDeleteConfirmation = false;
  stateFullName = '';

  constructor() { }

  ngOnInit(): void {
    if (this.location.country.length > 2)
    {
      this.location.country = getCountryCodeFromFullName(this.location.country);
    }
    this.editable = this.editLink.observers.length > 0;
    this.countryFullName = this.getCountryFullName();
    this.stateFullName = this.getStateFullName();

    this.getCountrySubdivisionLabel();
  }

  /**
   * Cancels the deleting process, and changes the component back to the view data
   */
  cancelDeleteProcess(): void {
    this.showDeleteConfirmation = false;
  }

  /**
   * Processes delete action once user confirms
   */
  confirmAddressDelete() {
    if (!this.errorMessage) {
      this.deleteFunction.emit();

      this.showDeleteConfirmation = false;
    }
  }

  /**
   * Changes the component flag to trigger the delete display
   */
  deleteAddress() {
    this.showDeleteConfirmation = true;
  }

  /**
   * Emits the click function for the edit location link
   */
  editAddress(): void {
    this.editLink.emit({
      event: event,
      locationId: this.location.siteLocationAddressId,
    });

    this.scrollToEditForm();
  }

  /**
   * Returns the full name of the country if the correct abbreviation is provided
   *
   * @return {String}
   */
  getCountryFullName(): string {
    const countryData = Object.values(countries).find(
      (country) => {
        if (country.code && this.location.country) {
          return country.code.toLowerCase() === this.location.country.toLowerCase();
        }
      }
    );

    return countryData ? countryData.name : this.location.country;
  }

  /**
   * Returns the correct label for national subdivisions based on the country
   *
   * @return {countrySubdivisionLabel}
   */
  getCountrySubdivisionLabel(): countrySubdivisionLabel {
    let countryData = getCountryData(this.location.country.toLowerCase());

    this.countrySubdivisionLabel = countryData.subdivisionName;
    this.postalCodeLabel = countryData.postalCodeLabel;

    return this.countrySubdivisionLabel;
  }

  /**
   * Returns the full name of the state if the correct abbreviation is provided.
   *
   * @return {String}
   */
  getStateFullName(): string {
    const stateProvince = this.location.country.toLowerCase() ?? 'us';

    if (states[stateProvince]) {
      const stateData = states[stateProvince].find(
        (state: { abbreviation: string }) => {
          if (state.abbreviation && this.location.state) {
            return state.abbreviation.toLowerCase() === this.location.state.toLowerCase();
          }
        }
      );

      if (stateData) {
        return stateData.name;
      }
    }

    return this.location.state;
  }

  /**
   * @todo This can generate an error if the form is not visible. This functionality either needs to
   *       be based off of the Add Location button or to watch the DOM for the appearance of <form>
   * @see https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
   */
  scrollToEditForm(): void {
    const formLocationX = document.getElementsByTagName('form')[0].offsetTop;

    window.scrollTo(0, formLocationX);
  }
}
