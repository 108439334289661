// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-file{height:auto;}
.image-upload-section{padding:18px 20px 12px 18px;}
.image-upload-section .inside-box{width:175px; height:205px; margin:0 14px 14px 0; padding-bottom: 30px;}
.image-upload-section .inside-box img{height:100%; width:100%;}
.image-upload-section .inside-box:nth-child(4n){margin-right:0;}

.custom-file-lg{
    z-index: 1 !important;
    right:14px;
    width: 100%;
}
.custom-file input {
  cursor: pointer;
}

.close{
    padding: 8px 15px;
    position: absolute;
    right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/file-upload/file-upload.component.css"],"names":[],"mappings":"AAAA,aAAa,WAAW,CAAC;AACzB,sBAAsB,2BAA2B,CAAC;AAClD,kCAAkC,WAAW,EAAE,YAAY,EAAE,oBAAoB,EAAE,oBAAoB,CAAC;AACxG,sCAAsC,WAAW,EAAE,UAAU,CAAC;AAC9D,gDAAgD,cAAc,CAAC;;AAE/D;IACI,qBAAqB;IACrB,UAAU;IACV,WAAW;AACf;AACA;EACE,eAAe;AACjB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,QAAQ;AACZ","sourcesContent":[".custom-file{height:auto;}\n.image-upload-section{padding:18px 20px 12px 18px;}\n.image-upload-section .inside-box{width:175px; height:205px; margin:0 14px 14px 0; padding-bottom: 30px;}\n.image-upload-section .inside-box img{height:100%; width:100%;}\n.image-upload-section .inside-box:nth-child(4n){margin-right:0;}\n\n.custom-file-lg{\n    z-index: 1 !important;\n    right:14px;\n    width: 100%;\n}\n.custom-file input {\n  cursor: pointer;\n}\n\n.close{\n    padding: 8px 15px;\n    position: absolute;\n    right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
