import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from '../service/site.service';

@Component({
  selector: 'app-add-discount',
  templateUrl: './add-discount.component.html',
  styleUrls: ['./add-discount.component.css']
})
export class AddDiscountComponent implements OnInit {
  discountMode: boolean | null = null;  // true for amount, false for percentage, null initially
  filteredCoupons = [];
  discount = {};
  coupons;
  constructor(
    public activeModal: NgbActiveModal,
    public siteService: SiteService
  ) { }

  ngOnInit() {
    this.coupons = [];

  }
  dismissModal() {
    this.activeModal.close();
  }

  getCoupons() {
    if (!this.discount['duration']) {
      return; // Do nothing if duration is not selected
    }
  
    this.siteService.getCoupons(this.discount['duration']).subscribe(res => {
      if (res) {
        this.coupons = res;
        this.filterCoupons();
      }
    }, err => {
      throw err;
    });
  }
  
  filterCoupons() {
    if (this.discountMode === true) {
      // Filter by amount
      this.filteredCoupons = this.coupons.filter(coupon => coupon.percentOff === null);
    } else if (this.discountMode === false) {
      // Filter by percentage
      this.filteredCoupons = this.coupons.filter(coupon => coupon.percentOff !== null);
    }
  }

  onDiscountModeChange() {
    this.discount['duration'] = null; // Reset duration when mode changes
    this.discount['coupon'] = null;  // Reset selected coupon
    this.filteredCoupons = [];       // Clear filtered coupons
  }

}
