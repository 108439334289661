import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntakeSubmenu } from '@shared/interfaces/intakes';

@Component({
  selector: 'app-intake-sidebar',
  templateUrl: './intake-sidebar.component.html',
  styleUrls: ['./intake-sidebar.component.css'],
})
export class IntakeSidebarComponent implements OnInit {
  @Input() sectionId: number;
  @Input() submenu: IntakeSubmenu[]
  @Input() label: string;
  @Input() icon: string;
  @Output() selectItem = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {}

  openPage(item?: any): void {
    this.selectItem.emit(item ?? {id:0});
  }
}
