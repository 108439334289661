import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.css'],
})
export class ExportButtonComponent implements OnInit {
  @Input() label = 'Export To CSV';

  @Output() clickFunction = new EventEmitter<object>();

  constructor() { }

  ngOnInit(): void {
  }

}
