import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox-squared',
  templateUrl: './checkbox-squared.component.html',
  styleUrls: ['./checkbox-squared.component.css'],
})
export class CheckboxSquaredComponent implements OnInit {
  @Input() checked = false;
  @Input() controlName = '';
  @Input() disabled = false;
  @Input() formGroup: AbstractControl|UntypedFormGroup;
  @Input() invalid: boolean;
  @Input() label = 'Checkbox';
  @Input() labelIsBold = true;
  @Input() name = '';
  @Input() required = false;
  @Input() tooltip: string;

  public id = '';

  constructor(
    private controlContainer: ControlContainer
  ) { }

  ngOnInit(): void {
    if (!this.name) {
      this.name = this.controlName;
      this.id = `checkbox_${this.controlName}`;
    }

    this.formGroup = this.controlContainer.control;
  }
}
