// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen {
  :host {
    width: 100%;
  }

  .d-flex {
    display: flex;
  }

  .g-sm {
    gap: 0.5rem;
  }

  .form-block-md {
    flex: 0 0 33%;
    padding: 1rem;
    max-width: 33%
  }

  .w-16 {
    width: 16%;
  }

  .w-25 {
    width: 25%;
  }

  .w-50 {
    width: 50%;
  }

  form {
    display: flex;
    flex-wrap: wrap;
  }

  label, input, select {
    width: 100%;
  }

  label {
    font-weight: bold;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/billing/manage-locations-edit-form/manage-locations-edit-form.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;EACb;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,aAAa;IACb;EACF;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["@media screen {\n  :host {\n    width: 100%;\n  }\n\n  .d-flex {\n    display: flex;\n  }\n\n  .g-sm {\n    gap: 0.5rem;\n  }\n\n  .form-block-md {\n    flex: 0 0 33%;\n    padding: 1rem;\n    max-width: 33%\n  }\n\n  .w-16 {\n    width: 16%;\n  }\n\n  .w-25 {\n    width: 25%;\n  }\n\n  .w-50 {\n    width: 50%;\n  }\n\n  form {\n    display: flex;\n    flex-wrap: wrap;\n  }\n\n  label, input, select {\n    width: 100%;\n  }\n\n  label {\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
