import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import {IntakesService} from '../../../../services/intakes.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-page-sample',
  templateUrl: './page-sample.component.html',
  styleUrls: ['./page-sample.component.css']
})
export class PageSampleComponent implements OnInit {
locations;
staffs;
  constructor(
    public activeModal: NgbActiveModal,
    private renderer: Renderer2,
    private intakesService:IntakesService
  ) { }
data;
baseUrl = environment.imgUrl;
  ngOnInit() { 
    if(this.data.pageName=='Locations')
      this.getLocation(this.data.intakeId,this.data.intakeTypeId);
    if(this.data.pageName=='Staff')
      this.getStatff(this.data.intakeId);
    document.getElementById("icontent").innerHTML = 
        `<iframe id=IFRAME2 name=IFRAME2 width=100% height=480px></iframe>`;
    var doc = frames["IFRAME2"].document;
    doc.open();
    doc.close();
    doc.body.innerHTML = this.data.content;
   
    // var element=document.getElementsByClassName("img-wrap");
    // const iframe = this.renderer.createElement('iframe'); 
    // this.renderer.setAttribute(iframe, 'id', 'contentRenderer');
    // this.renderer.setAttribute(iframe, 'width', '100%');
    // this.renderer.setAttribute(iframe, 'height', '480px');
    // this.renderer.appendChild(element[0], iframe); 
    // var body = iframe.contentDocument.body;
    // body.innerHTML = this.data.content; 
    // body.style.pointerEvents = "none";
  }

  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
  getLocation(IntakeId,IntakeTypeId){
    this.intakesService.getLocation(IntakeId,IntakeTypeId).subscribe(res=>{
      if(res){
        this.locations=res;
      }
    },err=>{
      throw err;
    })
  }
  getStatff(InatkeId){
    this.intakesService.getStaff(InatkeId).subscribe(res=>{
      if(res){
        this.staffs=res;
      }
    },err=>{
      throw err;
    })
  }


}
