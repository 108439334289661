import { Component, OnInit, ViewChild } from '@angular/core';
import { BuildSiteService } from '../build-site/build-site.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { ExcelService } from '../../reporting/services/excel.service';
import * as _ from 'lodash';
import { ReviewGenLog } from '@shared/interfaces/blueprint';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-blue-print-process',
  templateUrl: './blue-print-process.component.html',
  styleUrls: ['./blue-print-process.component.css']
})
export class BluePrintProcessComponent implements OnInit, PageHasTable {
  @ViewChild('dt') dataTable: TableGenericComponent;

  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  filterBy = {type:'',startDate:'',_startDate:'',endDate:'',_endDate:''};
  productId;
  reviewGenLogs;
  type = [];

  constructor(private buildSiteService:BuildSiteService,
              private toastrService:ToastrService,
              private excelService:ExcelService) { }

  ngOnInit() {
    this.productId = 1;
    
    this.getTableData();
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData() {
    this.columnHeaders = [
      {header: 'Type', field: 'type', filter: 'select'},
      {header: 'Message', field: 'message'},

      {header: 'Processed On', field: 'createdOn', showFilterInput:false},

    ];

    this.columnDataTypes = {
      createdOn: 'date',
    };
  }

  runBluePrintProcess(){
    this.buildSiteService.runBluePrintProcess().subscribe(res=>{
      if(res){
        this.toastrService.success('Process run Successfully', 'Success');
      }
    })
  }

  getReviewGenLog() {
    this.buildSiteService.getReviewGenLog(this.filterBy)
      .subscribe((res: ReviewGenLog[]) => {
        if (res) {
          res.sort((a, b) => {
            const createdOnA = new Date(a.createdOn);
            const createdOnB = new Date(b.createdOn);

            if (createdOnA < createdOnB) { return 1; }
            if (createdOnA > createdOnB) { return -1; }

            return 0;
          });
          this.reviewGenLogs = res;
          this.type = Array.from(new Set(res.map(item => item.type)));
        }
      }, err => {
        throw err;
    });
  }

  onValueChange(event) {
    this.filterBy['startDate'] = moment(event[0]).format('MM/DD/YYYY');
    this.filterBy['endDate'] = moment(event[1]).format('MM/DD/YYYY');
    this.filterBy['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.filterBy['_endDate'] = moment(event[1]).format('MM/DD/YY');
    this.getReviewGenLog();
  }

  /**
   * Resets the table filter
   */
  resetFilter() {
    this.filterBy = {
      type: '',
      startDate: '',
      _startDate: '',
      endDate: '',
      _endDate:'',
    };

    this.getReviewGenLog();

    this.dataTable.clearFilters();
  }

  downloadFile(type, filename){
    this.buildSiteService.downloadFile(type,filename).subscribe(res => {
      let Res=res;
      const downloadedFile = new Blob([Res], { type: Res.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = filename;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
    },
    err=>{
      this.toastrService.error('File Not Found', 'Error');
    })
  }

}
