import * as _ from 'lodash';
import { IntakesService } from '../../../../services/intakes.service';


export class Associations {
    intakeId: number;
    tradeAssociations: string;
    otherAssociation: string;
    ltradeAssociations: object;
    affiliateLogo: string;


    constructor(info, intakeId) {
        this.intakeId = intakeId;
        this.affiliateLogo = info.affiliateLogo;
        this.tradeAssociations = info.ltradeAssociations ? this.getTradeAssociationsId(info.ltradeAssociations) : null;
        this.ltradeAssociations = info.ltradeAssociations ? this.getTradeAssociations(info.ltradeAssociations) : null;
        this.otherAssociation = info.otherAssociation || this.getTradeOtherValue(info.ltradeAssociations);
    }


    getTradeAssociationsId(tradeAssociation) {
        let tAssocation = _.filter(tradeAssociation, (association) => {
            return association.checked;
        });
        tAssocation = _.toString(_.map(tAssocation, 'id'));
        return tAssocation;
    }

    getTradeAssociations(tradeAssociation) {
        let tAssocation = _.filter(tradeAssociation, (association) => {
            return association.checked;
        });
        // tAssocation = _.toString(_.map(tAssocation, 'id'));
        return tAssocation;
    }

    getTradeOtherValue(tradeAssociation){
        let otherTradeAssoc = _.find(tradeAssociation, (association) => {
            return !_.isEmpty(association.otherTradeAssociation) && association.name.toLowerCase() == 'other';
        })
        return otherTradeAssoc ? otherTradeAssoc.otherTradeAssociation : '';
    }

}

