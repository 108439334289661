import * as _ from 'lodash';
import { IntakesService } from '../../../../services/intakes.service';

export class SocialMedia {
    intakeId: number;
    isSocialMediaAccount: boolean;
    facebookUrl: string;
    twitterUrl: string;
    youtubeUrl: string;
    linkedinUrl: string;
    yelpUrl: string;
    blogUrl: string;
    googleplusUrl: string;
    instagramUrl: string;
    pinterestUrl: string;
    //tumblrUrl: string;
    otherUrl: string;
    noThridPartySite: string;
    sisterPracticeUrl: string;
    patientPortalUrl: string;
    reviewServiceUrl: string;


    constructor(info, intakeId) {
        console.log(info,'==info')
        this.intakeId = intakeId;
        this.isSocialMediaAccount = info.noSocialMediaAccount;
        this.facebookUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'facebook') : null;
        this.twitterUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'twitter') : null;
        this.youtubeUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'youtube') : null;
        this.linkedinUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'linkedin') : null;
        this.yelpUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'yelp') : null;
        this.blogUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'blog') : null;
        this.googleplusUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'google+') : null;
        this.instagramUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'instagram') : null;
        this.pinterestUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'pinterest') : null;
        //this.tumblrUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'tumblr') : null;
        this.otherUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'other') : null;
        this.noThridPartySite = info.socialMedia.noThridPartySite;
        this.sisterPracticeUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'sister practice') : null;
        this.patientPortalUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'patient portal') : null;
        this.reviewServiceUrl = info.socialMedia ? this.getSocialMediaUrl(info.socialMedia.socialMedia, 'review service (Ex. Healthy Hearing , Zoc Doc)') : null;

    }

    getSocialMediaUrl(socialMedia, name) {
        let mediaUrl;
        _.forEach(socialMedia, (media) => {
            if (media.checked && (media['name'].toLowerCase() == name.toLowerCase())) {
                mediaUrl = media.url;
            }
        })
        return mediaUrl;
    }
}