import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { IntakesService } from '../../../../services/intakes.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SectionStatus } from '../../../basic/review/section-model';
import * as _ from 'lodash';
import { HttpService } from '../../../../../../../services/http.service';
import { HoursOperation } from '../google-my-business/hours-model';
import { DataService } from '@shared/data-service.service';

@Component({
  selector: 'app-google-my-business',
  templateUrl: './google-my-business.component.html',
  styleUrls: ['./google-my-business.component.css']
})
export class GoogleMyBusinessComponent implements OnInit {
  intakeId;
  siteId;
  gmbForm: UntypedFormGroup;
  hasHoursIndex: number = null;
  @Input() productType;
  @Input() existingData;
  @Input() sectionId;
  @Input() intakeTypeId;
  @Input() requiredFields;
  @Input() submenu;
  createdPage = {};
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() changeStatus = new EventEmitter<any>();
  hoursOperation = new HoursOperation();
  timeLists = [];
  maxDate:Date;
  constructor(
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    public dataService: DataService
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.siteId = params.siteId;
      }
    });
    this.maxDate=new Date();
  }

  ngOnInit() {
    this.timeLists = this.intakesService.locationTimeLists;
    this.getIntakePremium(this.intakeId);
    this.initializeForm();
    console.log(this.intakesService.basicInfoRequiredData);
  }

  getIntakePremium(intakeId) {
    this.intakesService.getIntakePremium(intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.existingData = res;
        this.initializeForm();
        this.intakesService.updateRequiredFieldsStatus(res);
      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  initializeForm() {
    this.gmbForm = this.formBuilder.group({
      intakeId: this.intakeId,
      isGMB: new UntypedFormControl(this.existingData && this.existingData.isGMB != null ? this.existingData.isGMB : null),
      gmbEmail: new UntypedFormControl(this.existingData ? this.existingData.gmbEmail : '', Validators.pattern(this.dataService.emailRegex)),
      gmbPassword: new UntypedFormControl(this.existingData && this.existingData.gmbPassword !=null ? this.existingData.gmbPassword : ''),
      gmbConfirmPassword: new UntypedFormControl(this.existingData && this.existingData.gmbPassword != '' && this.existingData.gmbPassword != null ? this.existingData.gmbPassword : ''),
      gmbRecoveryEmail: new UntypedFormControl(this.existingData ? this.existingData.gmbRecoveryEmail : '', Validators.pattern(this.dataService.emailRegex)),
      gmbdob: new UntypedFormControl(this.existingData && this.existingData.gmbdob ? new Date(this.existingData.gmbdob) : null),
      gmbGender: new UntypedFormControl(this.existingData ? this.existingData.gmbGender : ''),
      gmbRecoveryMobile: new UntypedFormControl(this.existingData ? this.existingData.gmbRecoveryMobile : '', Validators.pattern(this.dataService.phoneRegex)),
      firstName: new UntypedFormControl(this.existingData ? this.existingData.firstName : ''),
      lastName: new UntypedFormControl(this.existingData ? this.existingData.lastName : ''),
      pEmail: new UntypedFormControl(this.existingData ? this.existingData.pEmail : '', Validators.pattern(this.dataService.emailRegex)),
      dob: new UntypedFormControl(this.existingData && this.existingData.dob ? new Date(this.existingData.dob) : null),
      gender: new UntypedFormControl(this.existingData ? this.existingData.gender : ''),
      recoveryMobile: new UntypedFormControl(this.existingData ? this.existingData.recoveryMobile : '', Validators.pattern(this.dataService.phoneRegex)),
      businessName: new UntypedFormControl(this.existingData ? this.existingData.businessName : ''),
      //hoursOperation: new FormControl(this.existingData ? this.existingData.hoursOperation : ''),
      businessOwner: new UntypedFormControl(this.existingData ? this.existingData.businessOwner : ''),
      isPrvBusinessName: new UntypedFormControl(this.existingData && this.existingData.isPrvBusinessName != null ? this.existingData.isPrvBusinessName : null),
      isPrvBusinessNumber: new UntypedFormControl(this.existingData && this.existingData.isPrvBusinessNumber != null ? this.existingData.isPrvBusinessNumber : null),
      isPrvBusinessUrl: new UntypedFormControl(this.existingData && this.existingData.isPrvBusinessUrl != null ? this.existingData.isPrvBusinessUrl : null),
      prvBusinessName: new UntypedFormControl(this.existingData ? this.existingData.prvBusinessName : ''),
      prvBusinessNumber: new UntypedFormControl(this.existingData ? this.existingData.prvBusinessNumber : ''),
      prvBusinessUrl: new UntypedFormControl(this.existingData ? this.existingData.prvBusinessUrl : ''),
      lstLocationWorkingHours: new UntypedFormArray(
        this.timeInstance(this.existingData && this.existingData.lstLocationWorkingHours.length > 0 ? this.existingData.lstLocationWorkingHours : this.hoursOperation.lstLocationWorkingHours),
      )

    }, { validator: this.checkPasswords('gmbPassword', 'gmbConfirmPassword') })
    this.getRequiredFieldData(this.gmbForm.value.isGMB);
  }

  timeInstance(data?) {
    let arr: any = [];
    for (let i = 0; i < data.length; i++) {
      let hours = this.formBuilder.group({
        id: data[i].id,
        day: new UntypedFormControl(data[i].day),
        openTime: new UntypedFormControl(data[i].openTime),
        closeTime: new UntypedFormControl(data[i].closeTime),
      });
      arr.push(hours);
    }
    return arr;
  }
  checkPasswords(firstField, secondField) { // here we have the 'passwords' group
    return (c: UntypedFormGroup) => {
      return (c.controls && c.controls[firstField].value == c.controls[secondField].value) ? null : {
        passwordsEqual: {
          valid: false
        }
      };
    }
  }

  resetUnwantedValues(value){
    if(value.isGMB){
      this.gmbForm.controls['firstName'].setValue('');
      this.gmbForm.controls['lastName'].setValue('');
      this.gmbForm.controls['pEmail'].setValue('');
      this.gmbForm.controls['dob'].setValue(null);
      this.gmbForm.controls['gender'].setValue('');
      this.gmbForm.controls['recoveryMobile'].setValue('');
    }else{
      this.gmbForm.controls['gmbEmail'].setValue('');
      this.gmbForm.controls['gmbPassword'].setValue('');
      this.gmbForm.controls['gmbConfirmPassword'].setValue('');
      this.gmbForm.controls['gmbRecoveryEmail'].setValue('');
      this.gmbForm.controls['gmbdob'].setValue(null);
      this.gmbForm.controls['gmbGender'].setValue('');
      this.gmbForm.controls['gmbRecoveryMobile'].setValue('');
    }
    this.gmbForm.updateValueAndValidity();
  }

  isFieldValid(field: string,index:number =0) {
    
    // let control=this.domainForm.get('lstIntakeDomain');
    //  if(field=='url'){
    //    for (let i=index;i<control['controls'].length;i++){ 
    //      return !control['controls'][i].controls.url.valid && control['controls'][i].controls.url.touched; 
    //    }
    //  }else{
      return !this.gmbForm.get(field).valid && this.gmbForm.get(field).touched;
    //}
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray){
        for (let i=0;i<control.controls.length;i++){
          this.validateAllFormFields(control.controls[i]);
        }
      }
    });
  }
  save(form, mode) {
    this.resetUnwantedValues(form.value);
    if(this.gmbForm.valid){
      console.log('valid form submitted');
    let intakeData = form.value;
    if(!intakeData.isGMB){
      if(intakeData.dob){
        intakeData.dob=new Date(intakeData.dob);
        intakeData.dob = !isNaN(intakeData.dob) ? intakeData.dob.getMonth() + 1 + "/" + intakeData.dob.getDate() + "/" + intakeData.dob.getFullYear() : intakeData.dob;
      }
    }else{
      if(intakeData.gmbdob){
        intakeData.gmbdob=new Date(intakeData.gmbdob);
        intakeData.gmbdob = !isNaN(intakeData.gmbdob) ? intakeData.gmbdob.getMonth() + 1 + "/" + intakeData.gmbdob.getDate() + "/" + intakeData.gmbdob.getFullYear() : intakeData.gmbdob;
      }
  }
 
    if (!intakeData.isPrvBusinessNumber) {
      intakeData.prvBusinessNumber = '';
    }
    if (!intakeData.isPrvBusinessUrl) {
      intakeData.prvBusinessUrl = '';
    }
    if (!intakeData.isPrvBusinessName) {
      intakeData.prvBusinessName = '';
    }

    _.assign(this.existingData, intakeData);
    this.intakesService.postPremiumPagesData(this.existingData).subscribe((res) => {
      if (res) {
        this.changeStatus.next();
        this.toastrService.success(this.dataService.successMsg, 'Success');
        this.intakesService.updateRequiredFieldsStatus(form.value);
        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, this.intakeId);
        this.postIntakeSection(sectionStatus);
        if (mode == 'continue') {
          this.goToNext();
        }
        console.log(this.gmbForm);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
    }else{
      console.log('invalid form submitted');
      this.validateAllFormFields(this.gmbForm);
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
    
    
  }
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });
      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getRequiredFieldData(gmb?) {
    this.requiredFields = _.filter(this.intakesService.requiredFields, (field) => {
      return field.sectionId == this.sectionId;
    });
    let requiredFields;
    if (typeof gmb == 'boolean') {
      requiredFields = _.filter(this.requiredFields, (field) => {
        return (field.gmb == gmb || !_.has(field, 'gmb')) && field.sectionId == this.sectionId;
      })
    } else {
      requiredFields = _.filter(this.requiredFields, (field) => {
        return !_.has(field, 'gmb') && field.sectionId == this.sectionId;
      })
    }
    _.forEach(this.submenu, (menu) => {
      menu.requiredFields = _.filter(requiredFields, (field) => {
        field.percentage = menu.percentage / requiredFields.length;
        return field.sectionId == menu.id;
      })
    })
    
  }

  validateField(selectedInput, textField, data) {
    if (data) {
      data && selectedInput == data.id ? this.gmbForm.get(textField).setValidators([Validators.required]) : this.gmbForm.get(textField).clearValidators();
    } else {
      selectedInput ? this.gmbForm.get(textField).setValidators([Validators.required]) : this.gmbForm.get(textField).clearValidators();
    }
    this.gmbForm.controls[textField].setValue('');
    this.gmbForm.get(textField).updateValueAndValidity();
  }
  goBack() {
    this.backClicked.next();
  }

  goToNext() {
    this.continueClicked.next();
  }
  displayHours(event, index) {
    if (event.target.checked) {
      this.hasHoursIndex = index;
    } else {
      this.hasHoursIndex = null;
    }

  };
}
