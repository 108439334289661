// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=radio] {
  margin-right: .5rem;
}

.error {
  background: transparent !important;
  color: red;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/site/add-subscription/add-subscription.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,kCAAkC;EAClC,UAAU;EACV,iBAAiB;AACnB","sourcesContent":["input[type=radio] {\n  margin-right: .5rem;\n}\n\n.error {\n  background: transparent !important;\n  color: red;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
