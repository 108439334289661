// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-100 {
    flex-basis: 100px;
    min-width: 100px;
}
.blue-text {
    color: #3597EC !important;
}
.text-right {
    text-align: right !important;
}
.width-340 {
    width: 340px !important;
}
.font-semi-bold {
    font-weight: 600 !important;
}

.table td, .table th{
    vertical-align: middle !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/site/start-billing/start-billing.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,4BAA4B;AAChC;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,2BAA2B;AAC/B;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".flex-100 {\n    flex-basis: 100px;\n    min-width: 100px;\n}\n.blue-text {\n    color: #3597EC !important;\n}\n.text-right {\n    text-align: right !important;\n}\n.width-340 {\n    width: 340px !important;\n}\n.font-semi-bold {\n    font-weight: 600 !important;\n}\n\n.table td, .table th{\n    vertical-align: middle !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
