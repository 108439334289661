import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { SiteService } from '../../service/site.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '@shared/data-service.service';
@Component({
  selector: 'app-email-provider',
  templateUrl: './email-provider.component.html',
  styleUrls: ['./email-provider.component.css']
})
export class EmailProviderComponent implements OnInit {
  @Input() siteInfo;
  @Output() updateSite = new EventEmitter<any>();
  emailProviders;
  emailProviderForm: UntypedFormGroup;
  userInfo = JSON.parse(localStorage.getItem("userInfo"));
  constructor(private siteService:SiteService,
                  private formBuilder: UntypedFormBuilder,
                  private toastrService: ToastrService,
                  public dataService:DataService) { }

  ngOnInit() {
    this.initializeForm()
  }
  initializeForm(){
    this.emailProviderForm=this.formBuilder.group({
      siteId:[this.siteInfo.id?this.siteInfo.id:0,[Validators.required]],
      emailProvider:[this.siteInfo.oSiteSetting.emailProvider?this.siteInfo.oSiteSetting.emailProvider:'']
    });
}

  setUp(form) {
    if (form.valid) {
      this.siteService.siteSetup(form.value).subscribe(res => {
        if (res) {
          this.toastrService.success('Email Provider Setup Done', 'success');
          this.updateSite.next(this.siteInfo.id);
        }
      },
        err => {
          this.toastrService.error(err.error);
        })
    }

  }
  // getSiteInfo(siteId) {
  //   this.siteService.getSiteInfo(siteId).subscribe((res) => {
  //     if (res) {
  //       this.siteInfo = res;
  //       this.initializeForm();
  //     }
  //   },
  //     err => {
  //       throw err;
  //     });
  // }
}
