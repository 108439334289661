import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntakeLocalListingsComponent } from './intake-local-listings/intake-local-listings.component';
import { IntakeSidebarComponent } from './intake-sidebar/intake-sidebar.component';
import { IntakeSidebarHeaderComponent } from './intake-sidebar-header/intake-sidebar-header.component';
import { IntakeSidebarSubmenuComponent } from './intake-sidebar-submenu/intake-sidebar-submenu.component';
import { IntakeSidebarSubmenuItemComponent } from './intake-sidebar-submenu-item/intake-sidebar-submenu-item.component';
import { IntakeSidebarSubmenuItemIconComponent } from './intake-sidebar-submenu-item-icon/intake-sidebar-submenu-item-icon.component';
import { IntakeSidebarStatusGroupComponent } from './intake-sidebar-status-group/intake-sidebar-status-group.component';
import { IntakeContentComponent } from './intake-content/intake-content.component';
import { LocalListingsBusinessInfoComponent } from './local-listings/local-listings-business-info/local-listings-business-info.component';
import { LocalListingsLocationInfoComponent } from './local-listings/local-listings-location-info/local-listings-location-info.component';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocalListingsYourPracticeComponent } from './local-listings/local-listings-your-practice/local-listings-your-practice.component';
import { TipBlockComponent } from './tip-block/tip-block.component';
import { ApprovalPopupComponent } from './approval-popup/approval-popup.component';
import { FrameFormContainerComponent } from './frame-form-container/frame-form-container.component';
import { LocationFormComponent } from './local-listings/location-form/location-form.component';
import { FilterTagsByFormIdPipe } from './pipes/filter-tags-by-form-id.pipe';

@NgModule({
    declarations: [
        ApprovalPopupComponent,
        IntakeLocalListingsComponent,
        IntakeSidebarComponent,
        IntakeSidebarHeaderComponent,
        IntakeSidebarSubmenuComponent,
        IntakeSidebarSubmenuItemComponent,
        IntakeSidebarSubmenuItemIconComponent,
        IntakeSidebarStatusGroupComponent,
        IntakeContentComponent,
        LocalListingsBusinessInfoComponent,
        LocalListingsLocationInfoComponent,
        LocalListingsYourPracticeComponent,
        TipBlockComponent,
        FrameFormContainerComponent,
        LocationFormComponent,
        FilterTagsByFormIdPipe,
    ],
    exports: [
        IntakeLocalListingsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
    ]
})

export class IntakesModule {
}
