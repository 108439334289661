import { Injectable } from '@angular/core';
import { GenericError } from '@shared/interfaces/errors';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertPopupComponent } from '@shared/alert-popup/alert-popup.component';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  toastConfig: Partial<IndividualConfig> = {
    closeButton: true,
    enableHtml: false,
    tapToDismiss: true,
  };

  constructor(
    private modalService: NgbModal,
    private toasterService: ToastrService
  ) { }

  clearToastMessage(messageId: number = null): void {
    this.toasterService.clear(messageId);
  }

  showGenericError(error: GenericError): void {
    return this.showModalError(error);
  }

  /**
   * Displays an error modal
   * @param {Error} error  The error object which is parsed for data
   * @param {String} title The title displayed in the modal header
   */
  showModalError(error: GenericError, title ?: string): void {
    const modal: AlertPopupComponent = this.modalService.open(AlertPopupComponent, {
      size: 'sm',
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
    }).componentInstance;

    modal.message = error.message;
    modal.title = title ?? 'There has been an error!!!';
  }

  showToastError(
    message: string,
    title: string = '',
    config: Partial<IndividualConfig> = {}
  ): void {
    config.closeButton = true;
    config.disableTimeOut = true;

    this.toasterService.error(message, title, {
      ...this.toastConfig,
      ...config,
    });
  }

  showToastSuccess(message: string, title: string = '', config: Partial<IndividualConfig> = {}): void {
    this.toasterService.success(message, title, this.toastConfig);
  }

  showToastMessage(message: string) {
    this.toasterService.show(message);
  }
}
