import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeoDashboardComponent } from './seo-dashboard/seo-dashboard.component';
import { TicketModule } from '../ticket/ticket.module';
import { CustomerModule } from "../customer/customer.module";
import { routing } from '../pages.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    routing,
    CommonModule,
    NgbModule,
    CustomerModule,
    TicketModule
  ],
  declarations: [SeoDashboardComponent]
})
export class SeoModule { }
