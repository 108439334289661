import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductManagerService } from '../services/product-manager.service';
import { MasterService } from '../../admin/masters/services/master.service';
import { DatePipe } from '@angular/common';
import { masterEnum } from '@shared/master-enum.enum';
import { ActivatedRoute } from '@angular/router';
import { SiteService } from '../../site/service/site.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CorporatePartnerService } from '../../admin/manage-corporate-partner/services/corporate-partner.service';
import { TicketService } from '../../ticket/service/ticket.service';
import { HttpService } from '@services/http.service';
import { ExcelService } from '../../reporting/services/excel.service';
import moment from 'moment';
import { roles } from '@shared/master-enum.enum';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
  providers: [DatePipe],
})
export class ProjectsComponent implements OnInit, PageHasTable {
  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  colspan = 2;
  nestedTableColumnHeaders: ColumnHeader[];
  nestedTableDataType: ColumnDataTypes;
  nestedTableData: Array<Object>;
  roles = roles;
  userInfo;
  filterBy = {
    domainUrl: null,
    businessName: null,
    startDate: null,
    endDate: null,
    dateRange: null,
    _startDate: null,
    _endDate: null,
  };
  projects = [];
  siteStatus;
  corporatePartner;
  jiraUrl;
  accountManager;
  projectType;
  allProjects;
  siteType;
  siteLists;

  @ViewChild('dt') table: TableGenericComponent;

  constructor(
    private productManagerService: ProductManagerService,
    private masterService: MasterService,
    private ticketService: TicketService,
    private excelService: ExcelService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private siteService: SiteService,
    private corporatePartnerService: CorporatePartnerService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.filterBy['status'] = this.activatedRoute.snapshot.queryParamMap.get('task_status');
    this.filterBy['project'] = this.activatedRoute.snapshot.queryParamMap.get('product_type');
    this.filterBy['taskSubStatus'] = this.activatedRoute.snapshot.queryParamMap.get('task_sub_status');
    this.filterBy['siteInDiscover'] = this.activatedRoute.snapshot.queryParamMap.get('SiteInDiscover');
    this.filterBy['project'] = this.activatedRoute.snapshot.queryParamMap.get('product_type');
    this.filterBy['isUpgrade'] = this.activatedRoute.snapshot.queryParamMap.get('isUpgrade');

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

    this.getCorporatePartner();
    this.getAccountManager();
    this.getProjects(this.filterBy);
    this.getSiteStatus();
    this.getProductType();
    this.getSiteType(masterEnum.site_type);
    this.getJiraUrl();
    this.getSites();
    this.getTableData();
  }

  selectedItem(obj?){
    this.getProjects(this.filterBy);
  }

  getProjects(filterBy) {
    filterBy['formattedStartDate'] = this.datePipe.transform(filterBy.startDate, 'yyyy-MM-dd');
    filterBy['formattedEndDate'] = this.datePipe.transform(filterBy.endDate, 'yyyy-MM-dd');
    filterBy['domainUrl'] = typeof this.filterBy['domainUrl'] == 'object' ? this.filterBy['domainUrl'] : this.filterBy['domainUrl'];
    filterBy['businessName'] = typeof this.filterBy['businessName'] == 'object' ? this.filterBy['businessName'] : this.filterBy['businessName'];
    this.productManagerService.getAdvanceSearchData(filterBy, this.userInfo.userId).subscribe(res => {
      if (res) {
        if (Object.values(filterBy).every(x => (x === null || x === ''))) {
          this.allProjects = Object.assign([], res);
        }

        for (let i = 0; i < res.length; i++) {
          res[i]['rowIndex'] = i;
        }

        this.projects = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getSiteStatus() {
    this.masterService.getGeneralMasterByType(8).subscribe((res) => {
      if (res) {
        this.siteStatus = res;
      }
    },
      err => {
        throw err;
      })
  }

  getProductType() {
    this.masterService.getGeneralMasterByType(4).subscribe((res) => {
      if (res) {
        this.projectType = res;
      }
    },
      err => {
        throw err;
      })
  }

  getCorporatePartner() {
    this.corporatePartnerService.getCorporatePartners().subscribe(res => {
      if (res) {
        this.corporatePartner = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getAccountManager() {
    this.siteService.getAccountManager().subscribe(res => {
      if (res) {
        this.accountManager = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getSiteType(id) {
    this.siteService.getMastersListById(id).subscribe((res) => {
      if (res) {
        this.siteType = res.masterList;
      }
    },
      err => {
        throw err;
      })
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {};

    this.columnHeaders = [
      {field: 'domainUrl', header: 'Domain URL', filter: 'text', width: 20},
      {field: 'siteName', header: 'Business Name', filter: 'text'},
      {field: 'siteType', header: 'Practice Type', filter: 'select'},
      {field: 'corporatePartnerName', header: 'Programs', filter: 'select'},
      {field: 'cpAccountNo', header: 'Account #', filter: 'text'},
      {field: 'accountManagerName', header: 'Account Manager', filter: 'select'},
      {field: 'familyName', header: 'Project', filter: 'select'},
    ];

    this.nestedTableDataType = {
      issueKey: 'viewLink',
    };

    this.nestedTableColumnHeaders = [
      {field: 'taskStatusName', header: 'Task Type', width: 20},
      {field: 'taskSubStatus', header: 'Status'},
      {field: 'assignedTo', header: 'Assigned To'},
      {field: 'assignedTo', header: 'Review status'},
      {field: 'issueKey', header: 'Action'},
    ];
  }

  /**
   * Reset the table and datepicker filters
   */
  reset(): void {
    this.filterBy = {
      domainUrl: null,
      businessName: null,
      startDate: null,
      endDate: null,
      dateRange: null,
      _startDate: null,
      _endDate: null,
    };

    this.table.clearFilters();

    this.getProjects(this.filterBy);
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.siteLists.filter(v => v['businessName'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  formatter = (result: string) => `${result['businessName']}`;

  searchDomain = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.siteLists.filter(v => v['domainUrl'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  domainFormatter = (result: string) => `${result['domainUrl']}`;

  searchSubStatus = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.allProjects.filter(v => v.taskSubStatus ? v.taskSubStatus : ''.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  SubStatusFormatter = (result: string) => `${result['taskSubStatus']}`;

  applySearch(key) {
    if ((typeof this.filterBy[key]) == 'object') {
      this.filterBy[key] = this.filterBy[key][key];
    }
    this.getProjects(this.filterBy);
  }

  getJiraUrl() {
    this.ticketService.getJiraUrl().subscribe(res => {
      if (res) {
        this.jiraUrl = res['url'];
      }
    },
      err => {
        throw err;

      })
  }
  getSites(){
    this.siteService.getSites().subscribe((res) => {
      if (res) {
        this.siteLists = Object.assign([], res);
      }
    },
      err => {
        throw err;
      })
  };

  onValueChange(event) {
    if(event){
    this.filterBy.startDate = moment(event[0]).format('MM/DD/YYYY');
    this.filterBy.endDate = moment(event[1]).format('MM/DD/YYYY');
    this.filterBy['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.filterBy['_endDate'] = moment(event[1]).format('MM/DD/YY');
    this.getProjects(this.filterBy);
    }
  }
}
