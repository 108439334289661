import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpService } from '@services/http.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContentGenerationService {

  constructor(private httpService: HttpService) { }

  generateContentFromCSVFile(csvFile: File, chatGPTModel: string): Observable<any> {
    // Create a FormData object and append the CSV file to it
    const formData = new FormData();
    formData.append('file', csvFile, csvFile.name);
  
    const headers = new HttpHeaders({ 'Content-Type': 'text/csv' });

    // Append the chatGPTModel as a query parameter in the URL
    const url = `ContentGeneration/GenerateContent?chatGptModel=${chatGPTModel}`;

    // Send the FormData object to the C# endpoint using the HttpService
    return this.httpService.postApi(url, formData, { headers })
      .pipe(
        tap(res => {
          // Return the response
          if (res) {
            return res;
          }
        })
      );
  }


  bulkDownloadContent(startDate: string, endDate: string): Observable<Blob> {
    const options = { responseType: 'blob' }; // Specify response type as blob
    return this.httpService.getApi(`ContentGeneration/DownloadContent?startDate=${startDate}&endDate=${endDate}`, true, undefined, false, true, options)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getLinksForContent(startDate: string, endDate: string): Observable<any> {
    return this.httpService.getApi(`ContentGeneration/DisplayContent?startDate=${startDate}&endDate=${endDate}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
}