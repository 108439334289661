import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from '../../../service/site.service';
@Component({
  selector: 'app-add-social-media-marketing',
  templateUrl: './add-social-media-marketing.component.html',
  styleUrls: ['./add-social-media-marketing.component.css']
})
export class AddSocialMediaMarketingComponent implements OnInit {
  siteId;
  smmForm:UntypedFormGroup;
  modalService;
  existingData;
  
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private router: Router,
    private toastrService: ToastrService,
    private siteService:SiteService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }
  initializeForm(){
    this.smmForm=this.formBuilder.group({
      id: [ this.existingData?this.existingData.id: 0],
      siteId:[this.existingData?this.existingData.siteId: this.siteId],
      accountId:[this.existingData?this.existingData.accountId: null,[Validators.required]],
      accountName:[ this.existingData?this.existingData.accountName: null,[Validators.required]]
    });
  }
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
  addAccount(form) {
    if (form.valid) {
      this.siteService.setUpSocialMediaMarketing(form.value).subscribe(res=>{
        if(res){
          this.activeModal.close();
          this.toastrService.success('Account Saved','success');
        }
      },
      err=>{
        this.toastrService.error(err.error);
      })
      }
      
    }
    
  }

