import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../services/intakes.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { masterEnum } from '@shared/master-enum.enum';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { SectionStatus } from '../basic/review/section-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../services/http.service';
@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css']
})
export class DomainComponent implements OnInit {
  domainForm: UntypedFormGroup;
  intakeId;
  @Input() submenu;
  @Input() menuId;
  @Input() intakeTypeId;
  @Output() continue = new EventEmitter<any>();  
  @Output() changeStatus= new EventEmitter<any>(); 
  existingData;
  checkLocationField:boolean=false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    public dataService:DataService,
  ) { 
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.getDomainIntake(this.intakeId);
      }
    });
  }

  ngOnInit() {
    this.initializeForm();
  }

  getDomainIntake(intakeId){
    this.intakesService.getDomainIntake(intakeId).subscribe((res) => {
      if (res) {
        this.existingData = res;
        this.setDomainControl(this.existingData);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  initializeForm(data?){
    this.domainForm = this.formBuilder.group({
      lstIntakeDomain: new UntypedFormArray([
        this.domainInstance(1)
      ])      
    })
  }

  setDomainControl(data) {
    if(data.length){
      this.domainForm.setControl('lstIntakeDomain', this.formBuilder.array(data.map(c => 
        this.domainInstance(data.indexOf(c)+1, c)
      )))
    }   
  }

  domainInstance(index, data?) {
    let domain = this.formBuilder.group({
      intakeId: this.intakeId,
      url: new UntypedFormControl(data ? data.url : null,Validators.pattern(this.dataService.urlRegex)),
      sNo: index
    });
    return domain;
  }

  updateDomainInstance(i, action) {
    if (action == 'add') {
      this.domainForm.controls.lstIntakeDomain['controls'].push(
        this.domainInstance(this.domainForm.controls.lstIntakeDomain['controls'].length+1)
      );
    } else {
      if(this.domainForm.controls.lstIntakeDomain['controls'].length > 1){
        this.domainForm.controls.lstIntakeDomain['controls'].splice(i, 1);
      }
    }    
    this.domainForm.get('lstIntakeDomain').updateValueAndValidity();
  }

  isFieldValid(field: string,index:number =0) {
    
    let control=this.domainForm.get('lstIntakeDomain');
     if(field=='url'){
       for (let i=index;i<control['controls'].length;i++){ 
         return !control['controls'][i].controls.url.valid && control['controls'][i].controls.url.touched; 
       }
     }else{
      return !this.domainForm.get(field).valid && this.domainForm.get(field).touched;
    }
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray){
        for (let i=0;i<control.controls.length;i++){
          this.validateAllFormFields(control.controls[i]);
        }
      }
    });
  }
  saveFormData(form, mode?) {
   
    this.domainForm.get('lstIntakeDomain').updateValueAndValidity();
    let intakeData = this.domainForm.value;
    if(this.domainForm.valid){
      console.log('valid form submitted');
      if(intakeData.lstIntakeDomain.length==1){
        this.checkLocationField=true;
      }else{
        _.forEach(intakeData.lstIntakeDomain, (location) => {
          this.checkLocationField=true;
          if((location.url == '' || location.url == null)){
          this.checkLocationField=false;
          this.httpService.openErrorPopup("Please fill the domain name");
            return false;
          }
      })
      }
  
    if(this.checkLocationField){
      this.intakesService.saveDomainIntake(intakeData).subscribe(res => {
        if (res) {
          this.changeStatus.next();
          this.toastrService.success(this.dataService.successMsg, 'Success');
          this.intakesService.updateRequiredFieldsStatus(intakeData);
          let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.submenu[0].id, this.intakeId);
          this.postIntakeSection(sectionStatus);
          if(mode == 'continue'){
            this.goToNext('parent');
          }
        }
  
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
  
      }

    }else{
      console.log('invalid form submitted');
      this.validateAllFormFields(this.domainForm);
      this.httpService.openErrorPopup("Please fill the domain name");
      }
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  goToNext(menu){
    this.continue.next(menu);
  }

}
