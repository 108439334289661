import { Component, OnInit } from '@angular/core';
import { PromotionsService } from '@shared/services/promotions/promotions.service';

@Component({
  selector: 'app-promotions-panel',
  templateUrl: './promotions-panel.component.html',
  styleUrls: ['./promotions-panel.component.css'],
})
export class PromotionsPanelComponent implements OnInit {
  promotions = [];

  constructor(private promotionsService: PromotionsService) { }

  ngOnInit(): void {
    this.promotionsService.getPromotions().map(
      (promotion) => {
        this.promotions.push(promotion);
      }
    );
  }
}
