// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-design .nav-tabs .nav-link.active {
    background: #4296F7;
    color: #fff !important;
    text-decoration: none;
}

.wizard-content {
  max-width: unset;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/downloads/downloads.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".panel-design .nav-tabs .nav-link.active {\n    background: #4296F7;\n    color: #fff !important;\n    text-decoration: none;\n}\n\n.wizard-content {\n  max-width: unset;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
