import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.css'],
})
export class AddButtonComponent implements OnInit {
  @Input() label = 'Add';
  @Input() queryParameters;
  @Input() route ?: Array<string>;
  @Input() showIcon = false;
  @Input() type: string;

  @Output() onButtonClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {}

  /**
   * Returns a formatted router link string
   */
  get routLink(): string {
    return `[${this.route}]`;
  }

  /**
   * Triggers a function that is passed the component on click
   */
  clickFunction(): void {
    this.onButtonClick.emit();
  }
}

@Component({
  selector: 'app-add-location-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.css'],
})
/**
 * A button for adding locations in forms, primarily the intake
 *
 * This should have all of the standard functionality of the main Add Button, but with a
 * different collor and label
 *
 * @extends AddButtonComponent
 */
export class AddLocationButtonComponent extends AddButtonComponent {
  @Input() label = 'Add Location';
  @Input() type = 'add-location';

  constructor() {
    super();
  }
}
