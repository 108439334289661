// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  vertical-align: middle;
  height: 2rem;
  pointer-events: unset;
  width: 2rem;
  cursor: pointer; 
}


a:hover {
  cursor: pointer; 
}

a.disabled {
  pointer-events: none; 
}

a.disabled:hover {
  cursor: not-allowed; 
}

a.disabled i {
  filter: brightness(5) saturate(0); 
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/glyphs/glyph-generic/glyph-generic.component.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,qBAAqB;EACrB,WAAW;EACX,eAAe;AACjB;;;AAGA;EACE,eAAe;AACjB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":["a {\n  vertical-align: middle;\n  height: 2rem;\n  pointer-events: unset;\n  width: 2rem;\n  cursor: pointer; \n}\n\n\na:hover {\n  cursor: pointer; \n}\n\na.disabled {\n  pointer-events: none; \n}\n\na.disabled:hover {\n  cursor: not-allowed; \n}\n\na.disabled i {\n  filter: brightness(5) saturate(0); \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
