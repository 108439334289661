import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntakeListComponent } from './intake-list/intake-list.component';
import { SiteListComponent } from './site-list/site-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { SiteConfigComponent } from './site-config/site-config.component';
import { SiteBasicComponent } from './site-config/basic/basic.component';
import { SiteUserComponent } from './site-config/site-user/site-user.component';
import { RouterModule } from '@angular/router';
import { SiteCallTrackingComponent } from './site-config/site-call-tracking/site-call-tracking.component';
import { SocialMediaMarketingComponent } from './site-config/social-media-marketing/social-media-marketing.component';
import { AddSocialMediaMarketingComponent } from './site-config/social-media-marketing/add-social-media-marketing/add-social-media-marketing.component';
import { PiwikSetupComponent } from './site-config/basic/piwik-setup/piwik-setup.component';
import { ReviewGenComponent } from './site-config/review-gen/review-gen.component';
import { ReviewGenSetupComponent } from './site-config/review-gen/review-gen-setup/review-gen-setup.component';
import { ConfirmModalComponent } from '@shared/confirm-modal/confirm-modal.component';
import { SocialMediaSetupComponent } from './site-config/social-media-setup/social-media-setup.component';
import { AddSocialMediaSetupComponent } from './site-config/social-media-setup/add-social-media-setup/add-social-media-setup.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FacebookAdsComponent } from './site-config/facebook-ads/facebook-ads.component';
import { FacebookAdsSetupComponent } from './site-config/facebook-ads/facebook-ads-setup/facebook-ads-setup.component';
import { GoogleGaGscComponent } from './site-config/google-ga-gsc/google-ga-gsc.component';
import { GoogleSetupComponent } from './site-config/google-ga-gsc/google-setup/google-setup.component';
import { SiteProductComponent } from './site-product/site-product.component';
import { CustomerCommunicationComponent } from './site-config/customer-communication/customer-communication.component';
import { CancelSiteComponent } from './cancel-site/cancel-site.component';
import { GoogleAdsComponent } from './site-config/google-ads/google-ads.component';
import { AddCallTrackingComponent } from './site-config/site-call-tracking/add-call-tracking/add-call-tracking.component';
import { AddGoogleGaGscComponent } from './site-config/google-ga-gsc/add-google-ga-gsc/add-google-ga-gsc.component';
import { DowngradeProductComponent } from './site-config/downgrade-product/downgrade-product.component';
import { LocalListingsComponent } from './site-config/site-gmb/local-listings.component';
import { AddLocalListingsComponent } from './site-config/site-gmb/add-gmb/add-gmb.component';
import { AddInstaPageComponent } from './site-config/google-ads/add-insta-page/add-insta-page.component';
import { SiteSettingComponent } from './site-setting/site-setting.component';
import { PaymentHistoryComponent } from '../customer';
import { ManageCardComponent } from '../customer/manage-card/manage-card.component';
import { StartBillingComponent } from './start-billing/start-billing.component';
import { AddTrialComponent } from './add-trial/add-trial.component';
import { AddDiscountComponent } from './add-discount/add-discount.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { SubscriptionPausedComponent } from './subscription-paused/subscription-paused.component';
import { RefundsComponent } from './refunds/refunds.component';
import { EmailProviderComponent } from './site-config/email-provider/email-provider.component';
import { ModifySubscriptionComponent } from './modify-subscription/modify-subscription.component';
import { SycleSetupComponent } from './site-config/sycle-setup/sycle-setup.component';
import { AddSycleSetupComponent } from './site-config/sycle-setup/add-sycle-setup/add-sycle-setup.component';
import { AddBluePrintSetupComponent } from './site-config/sycle-setup/add-blue-print-setup/add-blue-print-setup.component';
import { PendingInvoiceItemsComponent } from './pending-invoice-items/pending-invoice-items.component';
import { MapSiteUserComponent } from './site-config/map-site-user/map-site-user.component';
import { SharedModule } from '@shared/shared.module';
import { AddSubscriptionComponent } from './add-subscription/add-subscription.component';
import { BillingModule } from '../billing/billing.module';
import { ChangeProductComponent } from '../billing/change-product/change-product.component';
import { CounselEarComponent } from './site-config/practice-management-software/counsel-ear/counsel-ear.component';
import { CounselEarAddComponent } from './site-config/practice-management-software/counsel-ear-add/counsel-ear-add.component';
import { CounselEarEditComponent } from './site-config/practice-management-software/counsel-ear-edit/counsel-ear-edit.component';
import { PhonePipe } from '@pipes/phone/phone.pipe';
import { PaginatorModule } from 'primeng/paginator';
import { CalendarModule } from 'primeng/calendar';


@NgModule({
    imports: [
        CommonModule,
        TableModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        RouterModule,
        BsDatepickerModule.forRoot(),
        SharedModule,
        BillingModule,
        CalendarModule,
        PaginatorModule,
    ],
    declarations: [
        IntakeListComponent,
        SiteConfigComponent,
        SiteListComponent,
        SiteBasicComponent,
        SiteUserComponent,
        SiteCallTrackingComponent,
        SocialMediaMarketingComponent,
        AddSocialMediaMarketingComponent,
        PiwikSetupComponent,
        ReviewGenComponent,
        ReviewGenSetupComponent,
        SocialMediaSetupComponent,
        AddSocialMediaSetupComponent,
        FacebookAdsComponent,
        FacebookAdsSetupComponent,
        GoogleGaGscComponent,
        GoogleSetupComponent,
        SiteProductComponent,
        CustomerCommunicationComponent,
        CancelSiteComponent,
        GoogleAdsComponent,
        AddCallTrackingComponent,
        AddGoogleGaGscComponent,
        DowngradeProductComponent,
        LocalListingsComponent,
        AddLocalListingsComponent,
        AddInstaPageComponent,
        SiteSettingComponent,
        PaymentHistoryComponent,
        ManageCardComponent,
        StartBillingComponent,
        AddTrialComponent,
        AddDiscountComponent,
        CreateInvoiceComponent,
        SubscriptionPausedComponent,
        RefundsComponent,
        EmailProviderComponent,
        ModifySubscriptionComponent,
        SycleSetupComponent,
        AddSycleSetupComponent,
        AddBluePrintSetupComponent,
        PendingInvoiceItemsComponent,
        MapSiteUserComponent,
        AddSubscriptionComponent,
        CounselEarComponent,
        CounselEarAddComponent,
        CounselEarEditComponent,
    ],
    providers: [PhonePipe],
    exports: [
        PendingInvoiceItemsComponent,
        DowngradeProductComponent
    ]
})
export class SiteModule { }
