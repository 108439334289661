import { Component, Input, OnInit } from '@angular/core';
import { WorkingDay } from '@shared/interfaces/intakes';
import { FormArray, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { TimeListService } from '@shared/services/time-list/time-list.service';

@Component({
  selector: 'app-input-office-hours',
  templateUrl: './input-office-hours.component.html',
  styleUrls: ['./input-office-hours.component.css'],
})
export class InputOfficeHoursComponent implements OnInit {
  @Input() index: number;
  @Input() formName: string;
  @Input() defaultOption: number | string;
  @Input() disabled?: boolean = false;
  timeList: any[];
  form: UntypedFormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private timeListService: TimeListService
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
    this.getTimes();
    this.defaultOption = 'Closed';
  }

  getTimes(): void {
    this.timeListService.getTimeList().subscribe((times) => {
      this.timeList = times;
    });
  }
}
