import { Component, OnInit } from '@angular/core';
import { SiteService } from '../../site/service/site.service';
import { ReportingService } from '../services/reporting.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-business-performance',
  templateUrl: './business-performance.component.html',
  styleUrls: ['./business-performance.component.css']
})
export class BusinessPerformanceComponent implements OnInit {

  cpList = [];
  cpId = '';
  filterBy = {
    cpId: '',
    month: '',
    year:''
  };
  businessReport = [];
  bpList;
  amrpcChartData = [];
  amrpcChartLebel = [];
  locRepresentedChartData = [];
  locRepresentedChartLebel = [];
  activeWebClientChartData = [];
  activeWebClientChartLebel = [];
  totalActiveChartData = [];
  moRevenueChartData = [];
  WebsiteTypeLebel = ['Basic', 'Enhanced, Expanded', 'Premium SEO, Comprehensive'];
  total_var_redesign = null;
  paidClientPpcChartData = [];
  paidClientFbChartData = [];
  reviewGenChartData = [];
  socialMediaChartData = [];
  paidClientChartLebel = [];
  years=[];
  constructor(
    private siteService: SiteService,
    private reportingService: ReportingService
  ) { }

  ngOnInit() {
    this.getCorporatePartner();
    this.getYears();
    this.getBusinessPerformanceReport(this.filterBy);
  }

  getCorporatePartner() {
    this.siteService.getCorporatePartner().subscribe((res) => {
      if (res) {
        this.cpList = res;
        console.log(this.cpList, '==this.cpList');
      }
    },
      err => {
        throw err;
      })
  }

  getBusinessPerformanceReport(param) {
    
    if (param.cpId && param.month && param.year) {
      this.reset();
      this.reportingService.getBusinessPerformanceReport(param).subscribe((res) => {
        if (res) {
          this.bpList = res;
          _.forEach(res.amrpc, (data) => {
            this.amrpcChartData.push(data.amrpc);
            this.amrpcChartLebel.push(data.month + '/' + data.year)
          })
          _.forEach(res.activeWebClient, (data) => {
            this.activeWebClientChartData.push(data.activeWebClient);
            this.activeWebClientChartLebel.push(data.month + '/' + data.year)
          })
          _.forEach(res.locationsRepresented, (data) => {
            this.locRepresentedChartData.push(data.locationsRepresented);
            this.locRepresentedChartLebel.push(data.month + '/' + data.year)
          })
          _.forEach(res.paidClient_MTD, (data) => {
            this.paidClientPpcChartData.push(data.ppC_MTD);
            this.paidClientFbChartData.push(data.fB_MTD);
            this.reviewGenChartData.push(data.reviewgen_MTD);
            this.socialMediaChartData.push(data.socialMedia_MTD);
            this.paidClientChartLebel.push(data.month + '/' + data.year);
          })
          this.total_var_redesign = (this.bpList.newSitesInProgress[1].discover +
                                    this.bpList.newSitesInProgress[1].design +
                                    this.bpList.newSitesInProgress[1].development +
                                    this.bpList.newSitesInProgress_Redesign[1].redesign) -
                                    (this.bpList.newSitesInProgress[0].discover +
                                      this.bpList.newSitesInProgress[0].design +
                                      this.bpList.newSitesInProgress[0].development +
                                      this.bpList.newSitesInProgress_Redesign[0].redesign);
          this.totalActiveChartData.push(res.websiteType[0].basicWebsite);
          this.totalActiveChartData.push(res.websiteType[0].enhancedWebsite);
          this.totalActiveChartData.push(res.websiteType[0].premiumWebsite);
          this.moRevenueChartData.push(res.websiteType[0].basicMORevenue);
          this.moRevenueChartData.push(res.websiteType[0].enhancedMORevenue);
          this.moRevenueChartData.push(res.websiteType[0].premiumMORevenue);         
        }
      },
        err => {
          throw err;
        })
    }
  }
  reset() {
    this.amrpcChartData = [];
    this.amrpcChartLebel = [];
    this.locRepresentedChartData = [];
    this.locRepresentedChartLebel = [];
    this.activeWebClientChartData = [];
    this.activeWebClientChartLebel = [];
    this.totalActiveChartData = [];
    this.moRevenueChartData = [];
    this.total_var_redesign = null;
    this.paidClientPpcChartData = [];
    this.paidClientFbChartData = [];
    this.reviewGenChartData = [];
    this.socialMediaChartData = [];
    this.paidClientChartLebel = [];

  }
  
  getYears(){
    let currentYear=new Date().getFullYear();
    for(let i=2017;i<=currentYear;i++){
      this.years.push(i);
    }
  }
}
