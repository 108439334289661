import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../services/intakes.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { masterEnum } from '@shared/master-enum.enum';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { SectionStatus } from '../basic/review/section-model';
import { Address } from './models/citationAddress-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../services/http.service';
@Component({
  selector: 'app-citation-cleanup',
  templateUrl: './citation-cleanup.component.html',
  styleUrls: ['./citation-cleanup.component.css']
})
/**
 * @deprecated This has been replaced by the local listings intake
 * @see IntakeLocalListingsComponent
 * @todo Remove these components from the source code
 */
export class CitationCleanupComponent implements OnInit {

  citationAddress;
  citationForm: UntypedFormGroup;
  intakeId;
  @Input() submenu;
  @Input() menuId;
  @Input() intakeTypeId;
  @Output() continue = new EventEmitter<any>();
  @Output() changeStatus = new EventEmitter<any>();
  existingData;
  timeLists = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    public dataService: DataService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.getCitationIntake(this.intakeId);
        this.citationAddress = new Address(this.intakeId);
      }
    });
  }

  ngOnInit() {
    this.timeLists = this.intakesService.locationTimeLists;
    this.initializeForm();
  }
  getCitationIntake(intakeId) {
    this.intakesService.getCitationIntake(intakeId).subscribe((res) => {
      if (res) {
        this.existingData = res;
        this.setFormInstance(this.existingData);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  initializeForm(data?) {
    this.citationForm = this.formBuilder.group({
      lstIntakeCitationCleanUp: new UntypedFormArray([
        this.formInstance(),
      ]),
    })
    // console.log(this.citationForm.value);
  }

  setFormInstance(data) {
    if (data.length) {
      this.citationForm.setControl('lstIntakeCitationCleanUp', this.formBuilder.array(data.map(c =>
        this.formInstance(c)
      )))
    }
  }
  formInstance(data?) {
    let formData = this.formBuilder.group({
      intakeId: this.intakeId,
      srNo: data ? data.srNo : 0,
      currentBusinessName: new UntypedFormControl(data ? data.currentBusinessName : ''),
      previousBusinessName: new UntypedFormControl(data ? data.previousBusinessName : ''),
      businessOwnerName: new UntypedFormControl(data ? data.businessOwnerName : ''),
      businessOwnerEmail: new UntypedFormControl(data ? data.businessOwnerEmail : '', Validators.pattern(this.dataService.emailRegex)),
      hoursofOperation: new UntypedFormControl(data ? data.hoursofOperation : ''),
      paymentsAccepted: new UntypedFormControl(data ? data.paymentsAccepted : ''),
      currentBusinessUrl: new UntypedFormControl(data ? data.currentBusinessUrl : '', Validators.pattern(this.dataService.urlRegex)),
      previousBusinessUrl: new UntypedFormControl(data ? data.previousBusinessUrl : '', Validators.pattern(this.dataService.urlRegex)),
      // curBusLocationName:new FormControl(data ? data.curBusLocationName : ''),
      curBusAddress1: new UntypedFormControl(data ? data.curBusAddress1 : ''),
      curBusAddress2: new UntypedFormControl(data ? data.curBusAddress2 : ''),
      curBusCity: new UntypedFormControl(data ? data.curBusCity : ''),
      curBusState: new UntypedFormControl(data ? data.curBusState : ''),
      curBusZipCode: new UntypedFormControl(data ? data.curBusZipCode : ''),
      curBusPhoneNumber: new UntypedFormControl(data ? data.curBusPhoneNumber : '', Validators.pattern(this.dataService.phoneRegex)),
      // preBusLocationName:new FormControl(data ? data.preBusLocationName : ''),
      preBusAddress1: new UntypedFormControl(data ? data.preBusAddress1 : ''),
      preBusAddress2: new UntypedFormControl(data ? data.preBusAddress2 : ''),
      preBusCity: new UntypedFormControl(data ? data.preBusCity : ''),
      preBusState: new UntypedFormControl(data ? data.preBusState : ''),
      preBusZipCode: new UntypedFormControl(data ? data.preBusZipCode : ''),
      preBusPhoneNumber: new UntypedFormControl(data ? data.preBusPhoneNumber : '', Validators.pattern(this.dataService.phoneRegex)),
      lstLocationWorkingHours: new UntypedFormArray(
        this.workingHoursInstance(data && data.lstLocationWorkingHours.length > 0 ? data.lstLocationWorkingHours : this.citationAddress.lstLocationWorkingHours),
      ),

    });

    return formData;
  }

  workingHoursInstance(data?) {
    let arr: any = [];
    for (let i = 0; i < data.length; i++) {
      let hours = this.formBuilder.group({
        id: data[i].id,
        day: new UntypedFormControl(data[i].day),
        openTime: new UntypedFormControl(data[i].openTime),
        closeTime: new UntypedFormControl(data[i].closeTime),
      });
      arr.push(hours);
    }
    return arr;
  }

  updateCitationInstance(index, action) {
    if (action == 'add') {
      this.citationAddress = new Address(this.intakeId);
      this.citationForm.controls.lstIntakeCitationCleanUp['controls'].push(
        this.formInstance(this.citationAddress)
      );
    } else {
      this.citationForm.controls.lstIntakeCitationCleanUp['controls'].splice(index, 1);
    }

    this.citationForm.get('lstIntakeCitationCleanUp').updateValueAndValidity();
  }

  get f() {
    return this.citationForm.controls;
  }
  saveFormData(form, mode?) {
    if (form.valid) {
      this.citationForm.get('lstIntakeCitationCleanUp').updateValueAndValidity();
      let intakeData = form.value;
      this.intakesService.saveCitationIntake(intakeData).subscribe(res => {
        if (res) {
          this.changeStatus.next();
          this.toastrService.success(this.dataService.successMsg, 'Success');
          _.forEach(intakeData.lstIntakeCitationCleanUp, (location) => {
          this.intakesService.updateRequiredFieldsStatus(location);
          })
          let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.submenu[0].id, this.intakeId);
          this.postIntakeSection(sectionStatus);
          if (mode == 'continue') {
            this.goToNext('parent');
          }
        }

      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
    } else {
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        // this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });
      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  goToNext(menu) {
    this.continue.next(menu);
  }

}
