// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dot {
  content: "";
  background: rgb(53, 150, 236);
  border-radius: 4px;
  height: 4px;
  right: -2px;
  width: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/shapes/dot-blue/dot-blue.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,UAAU;AACZ","sourcesContent":[".dot {\n  content: \"\";\n  background: rgb(53, 150, 236);\n  border-radius: 4px;\n  height: 4px;\n  right: -2px;\n  width: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
