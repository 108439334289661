import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../services/intakes.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { masterEnum } from '@shared/master-enum.enum';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { SectionStatus } from '../basic/review/section-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../services/http.service';
@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {
  emailForm: UntypedFormGroup;
  intakeId;
  @Input() submenu;
  @Input() menuId;
  @Input() intakeTypeId;
  @Output() continue = new EventEmitter<any>();  
  @Output() changeStatus= new EventEmitter<any>(); 
  existingData;
  checkLocationField:boolean=false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    public dataService:DataService,
  ) { 
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.getEmailIntake(this.intakeId);
      }
    });
  }

  ngOnInit() {
    this.initializeForm();
  }

  getEmailIntake(intakeId){
    this.intakesService.getEmailIntake(intakeId).subscribe((res) => {
      if (res) {
        this.existingData = res;
        this.initializeForm(this.existingData);
        if(this.existingData.emails){
          this.setEmailControl(this.existingData.emails);
        }else{
          this.setEmailControl([]);
        }
       
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  initializeForm(data?){
    this.emailForm = this.formBuilder.group({
      intakeId: this.intakeId,
      isCreateDomainEmail: new UntypedFormControl(data && data.isCreateDomainEmail !=null ? data.isCreateDomainEmail:null),
      isExistingDomain: new UntypedFormControl(data && data.isExistingDomain !=null ? data.isExistingDomain:null,Validators.required),
      isDomainEmail: new UntypedFormControl(data && data.isDomainEmail !=null ? data.isDomainEmail:null),
      emails: new UntypedFormArray([
        this.emailInstance(1)
      ])      
    })
  }

  setEmailControl(data) {
    if(data.length){
      this.emailForm.setControl('emails', this.formBuilder.array(data.map(c => 
        this.emailInstance(data.indexOf(c)+1, c)
      )))
    }   
    
  }

  emailInstance(index, data?) {
    let email = this.formBuilder.group({
      firstName: new UntypedFormControl(data ? data.firstName : null),
      lastName: new UntypedFormControl(data ? data.lastName : null),
      emailId: new UntypedFormControl(data ? data.emailId : null,Validators.pattern(this.dataService.emailRegex)),
      sNo: index
    });
    return email;
  }

  updateEmailInstance(i, action) {
    if (action == 'add') {
      this.emailForm.controls.emails['controls'].push(
        this.emailInstance(this.emailForm.controls.emails['controls'].length + 1)
      );
    } else {
      if(this.emailForm.controls.emails['controls'].length > 1){
        this.emailForm.controls.emails['controls'].splice(i, 1);
      }
    }
    this.emailForm.get('emails').updateValueAndValidity();
  }

  isExistingDomain(event){
    this.emailForm.controls.isCreateDomainEmail.setValue(null);
    this.emailForm.controls.isDomainEmail.setValue(null);
    this.setEmailControl([{firstName: "", lastName: "", emailId: "", sNo: 1}]);
  }

  isFieldValid(field: string,index:number =0) {
    
    let control=this.emailForm.get('emails');
    if(field=='firstName'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.firstName.valid && control['controls'][i].controls.firstName.touched; 
      }
    }else if(field=='lastName'){
      for (let i=index;i<control['controls'].length;i++){
        return !control['controls'][i].controls.lastName.valid && control['controls'][i].controls.lastName.touched;
      }
    }else if(field=='emailId'){
      for (let i=index;i<control['controls'].length;i++){
        return !control['controls'][i].controls.emailId.valid && control['controls'][i].controls.emailId.touched;
      }
    }else{
      return !this.emailForm.get(field).valid && this.emailForm.get(field).touched;
    }
   
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray){
        for (let i=0;i<control.controls.length;i++){
          this.validateAllFormFields(control.controls[i]);
        }
      }

    });
  }
  bool:boolean=false;
  saveFormData(form, mode?) {
    this.emailForm.get('emails').updateValueAndValidity();
    let intakeData = form.value;
    if(!intakeData.isExistingDomain && intakeData.isExistingDomain !=null && !intakeData.isCreateDomainEmail && intakeData.isCreateDomainEmail !=null){
      this.bool=true;
    } else if(intakeData.isExistingDomain && intakeData.isExistingDomain !=null && !intakeData.isDomainEmail && intakeData.isDomainEmail !=null){
      this.bool=true;
    }else{
      this.bool=false;
    }

   if (this.emailForm.valid || this.bool) {
      console.log('valid form submitted');
      if(intakeData.isExistingDomain==null){
        this.checkLocationField=false;
        this.httpService.openErrorPopup(this.dataService.requiredMsg);
      }else{
      if(intakeData.isDomainEmail==null && intakeData.isCreateDomainEmail == null){
        this.checkLocationField=false;
        this.httpService.openErrorPopup(this.dataService.requiredMsg);
      }else{
        if(!intakeData.isDomainEmail && !intakeData.isCreateDomainEmail){
          intakeData.emails=[{firstName: "", lastName: "", emailId: "", sNo: 1}];
          this.setEmailControl([{firstName: "", lastName: "", emailId: "", sNo: 1}]);
          this.checkLocationField=true;
        }else{
            _.forEach(intakeData.emails, (location) => {
              this.checkLocationField=true;
              if((location.firstName == '' || location.firstName == null) || (location.lastName == '' || location.lastName == null) || (location.emailId == '' || location.emailId == null)){
              this.checkLocationField=false;
              this.httpService.openErrorPopup(this.dataService.requiredMsg);
                return false;
              }
          })
        }
      }
    }
      if(this.checkLocationField){
          this.intakesService.saveEmailIntake(intakeData).subscribe(res => {
            if (res) {
              this.changeStatus.next();
              this.toastrService.success(this.dataService.successMsg, 'Success');
              this.intakesService.updateRequiredFieldsStatus(intakeData);
              let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.submenu[0].id, this.intakeId);
              this.postIntakeSection(sectionStatus);
              if(mode == 'continue'){
                this.goToNext('parent');
              }
            }
  
          },
            err => {
              this.httpService.openErrorPopup(err.error.message);
            })
  
      }
    } else {
      console.log('invalid form submitted');
      this.validateAllFormFields(this.emailForm);
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
    
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  goToNext(menu){
    this.continue.next(menu);
  }

}
