// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  font-weight: bold;
  margin-bottom: 0.75rem;
  width: 100%;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  padding-left: 1.5rem;
  width: 75%;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/forms/chip-group/chip-group.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,mBAAmB;EACnB,oBAAoB;EACpB,UAAU;AACZ","sourcesContent":["label {\n  font-weight: bold;\n  margin-bottom: 0.75rem;\n  width: 100%;\n}\n\n.chips {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 1rem;\n  margin-bottom: 2rem;\n  padding-left: 1.5rem;\n  width: 75%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
