import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(
    private httpService: HttpService
  ) { }

  postEmail(data): Observable<any> {

    return this.httpService.postApi('User/ForgetPassword', data)
    .pipe(
      tap(res => {

          return res;

    })
  );
  }

}
