// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.py-36 {
padding-top: 36px !important;
/* padding-bottom: 36px !important; */
}
.font-12 {
font-size: 12px !important;
}
h1, h2, h3, h4, h5, h6, p {
margin: 0 0 0 0;
}
.font-12 p {
font-size: 12px !important;
}
.btn.btn-xl {
line-height: 50px;
padding: 0 14px;
font-size: 15px;
font-weight: 700;
border-radius: 4px;
min-width: 100px;
}
.btn.add-btn {
color: #fff;
}
.blue-bg {
background: #3497EC !important;
}
.bule-2 {
color: #4296F7 !important;
}
img {
max-width: 100%;
}
.icon {

display: inline-block;
flex-basis: 22px;
min-width: 22px;
}
.font-semi-bold {
font-weight: 600 !important;
}
.form-control.aud-input.xl {
height: 50px;
padding: 5px 8px;
font-size: 17px;
border-radius: 0px;
}
.form-control.aud-input {
background-color: #fff;
border-radius: 0px;
color: #939292;
border: #BABABA solid 1px;
}
.btn.btn-xl {
  line-height: 50px;
  padding: 0 14px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 4px;
  min-width: 100px;
}

`, "",{"version":3,"sources":["webpack://./src/app/components/set-password/set-password.component.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,8BAA8B;AAChC;AACA;AACA,4BAA4B;AAC5B,qCAAqC;AACrC;AACA;AACA,0BAA0B;AAC1B;AACA;AACA,eAAe;AACf;AACA;AACA,0BAA0B;AAC1B;AACA;AACA,iBAAiB;AACjB,eAAe;AACf,eAAe;AACf,gBAAgB;AAChB,kBAAkB;AAClB,gBAAgB;AAChB;AACA;AACA,WAAW;AACX;AACA;AACA,8BAA8B;AAC9B;AACA;AACA,yBAAyB;AACzB;AACA;AACA,eAAe;AACf;AACA;;AAEA,qBAAqB;AACrB,gBAAgB;AAChB,eAAe;AACf;AACA;AACA,2BAA2B;AAC3B;AACA;AACA,YAAY;AACZ,gBAAgB;AAChB,eAAe;AACf,kBAAkB;AAClB;AACA;AACA,sBAAsB;AACtB,kBAAkB;AAClB,cAAc;AACd,yBAAyB;AACzB;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".px-40 {\n  padding-left: 40px !important;\n  padding-right: 40px !important;\n}\n.py-36 {\npadding-top: 36px !important;\n/* padding-bottom: 36px !important; */\n}\n.font-12 {\nfont-size: 12px !important;\n}\nh1, h2, h3, h4, h5, h6, p {\nmargin: 0 0 0 0;\n}\n.font-12 p {\nfont-size: 12px !important;\n}\n.btn.btn-xl {\nline-height: 50px;\npadding: 0 14px;\nfont-size: 15px;\nfont-weight: 700;\nborder-radius: 4px;\nmin-width: 100px;\n}\n.btn.add-btn {\ncolor: #fff;\n}\n.blue-bg {\nbackground: #3497EC !important;\n}\n.bule-2 {\ncolor: #4296F7 !important;\n}\nimg {\nmax-width: 100%;\n}\n.icon {\n\ndisplay: inline-block;\nflex-basis: 22px;\nmin-width: 22px;\n}\n.font-semi-bold {\nfont-weight: 600 !important;\n}\n.form-control.aud-input.xl {\nheight: 50px;\npadding: 5px 8px;\nfont-size: 17px;\nborder-radius: 0px;\n}\n.form-control.aud-input {\nbackground-color: #fff;\nborder-radius: 0px;\ncolor: #939292;\nborder: #BABABA solid 1px;\n}\n.btn.btn-xl {\n  line-height: 50px;\n  padding: 0 14px;\n  font-size: 15px;\n  font-weight: 700;\n  border-radius: 4px;\n  min-width: 100px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
