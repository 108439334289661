import { Component, Input, OnInit } from '@angular/core';
import { Day } from '@shared/types/types';
import { WorkingDay } from '@shared/interfaces/intakes';
import { UntypedFormArray, UntypedFormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-input-office-hours-week',
  templateUrl: './input-office-hours-week.component.html',
  styleUrls: ['./input-office-hours-week.component.css'],
})
export class InputOfficeHoursWeekComponent implements OnInit {
  @Input() required = false;
  form: UntypedFormGroup;
  whArray: UntypedFormArray;

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
    this.whArray = this.form.controls['lstLocationWorkingHours'] as UntypedFormArray;
  }

  /**
   * A compare function that sets the original order of the `keyvalue` pipe object
   * @return {number} The number zero
   */
  originalOrder(): 0 {
    return 0;
  }
}
