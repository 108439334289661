import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ob-dashboard',
  templateUrl: './ob-dashboard.component.html',
  styleUrls: ['./ob-dashboard.component.css']
})
export class ObDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
