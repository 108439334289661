import { Component, OnInit } from '@angular/core';
import { roles } from '@shared/master-enum.enum';
import * as _ from 'lodash';
import { Role } from '@shared/interfaces/interfaces';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.css']
})
export class PageFooterComponent implements OnInit {
  currentRole: Role;
  currentYear: number = new Date().getFullYear();

  constructor() { }

  ngOnInit() {
    const currentRole = JSON.parse(localStorage.getItem('userInfo')).roleId;

    this.currentRole = _.find(roles, (role) => {
      return role.roleId === currentRole;
    });
  }

}
