import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteBasicComponent } from 'app/components/site/site-config/basic/basic.component';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.css']
})
export class AlertModalComponent implements OnInit {
  site;
  updatedcomments
  comments;
  heading;
  Reviewstatus = [{
    name: 'Review RD1'
  }, {
    name: 'Review RD2'
  },
  {
    name: 'Review RD3'
  }];
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  successModal(action,comments) {
    this.activeModal.close(action);
  }

  dismissModal() {
    this.activeModal.close();
  }
  filterBy = {};
  updateComment() {
    this.updatedcomments = this.site.status == 4 ?
        this.filterBy["status"] + ': ' + this.comments :
      this.comments;
  }

}
