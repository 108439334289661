// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-invalid{
    border: 1px solid red;
}
.hours-operation{margin-bottom:0;}
.hours-operation .bottom-spacing{margin-bottom:9px;}
.hours-operation .bottom-spacing:last-child{margin-bottom:0;}
.form-control:disabled, .form-control[readonly]{background:#E9ECEF;}
.hours-operation .form-control{font-size:15px; color:#4D4F5C; font-weight:600;}

`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/citation-cleanup/citation-cleanup.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA,iBAAiB,eAAe,CAAC;AACjC,iCAAiC,iBAAiB,CAAC;AACnD,4CAA4C,eAAe,CAAC;AAC5D,gDAAgD,kBAAkB,CAAC;AACnE,+BAA+B,cAAc,EAAE,aAAa,EAAE,eAAe,CAAC","sourcesContent":[".is-invalid{\n    border: 1px solid red;\n}\n.hours-operation{margin-bottom:0;}\n.hours-operation .bottom-spacing{margin-bottom:9px;}\n.hours-operation .bottom-spacing:last-child{margin-bottom:0;}\n.form-control:disabled, .form-control[readonly]{background:#E9ECEF;}\n.hours-operation .form-control{font-size:15px; color:#4D4F5C; font-weight:600;}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
