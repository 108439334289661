// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all {
  label {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0 1rem;
  }

  p.error {
    background: unset !important;
    color: rgb(223, 79, 60);
    font-weight: bold;
  }

  section {
    margin-bottom: 2rem;
  }

  section:last-child {
    margin-bottom: 4rem;
  }

  .wizard-content {
    max-width: unset;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/site/site-config/downgrade-product/downgrade-product.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,eAAe;IACf,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,4BAA4B;IAC5B,uBAAuB;IACvB,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":["@media all {\n  label {\n    font-size: 1rem;\n    font-weight: bold;\n    margin: 0 0 1rem;\n  }\n\n  p.error {\n    background: unset !important;\n    color: rgb(223, 79, 60);\n    font-weight: bold;\n  }\n\n  section {\n    margin-bottom: 2rem;\n  }\n\n  section:last-child {\n    margin-bottom: 4rem;\n  }\n\n  .wizard-content {\n    max-width: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
