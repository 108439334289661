import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from '../../../service/site.service';

@Component({
  selector: 'app-add-gmb',
  templateUrl: './add-gmb.component.html',
  styleUrls: ['./add-gmb.component.css']
})
export class AddLocalListingsComponent implements OnInit {

  siteId;
  SEMForm:UntypedFormGroup;
  modalService;
  existingData;
  msg;
  gmbAdminAccounts;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private router: Router,
    private toastrService: ToastrService,
    private siteService:SiteService
  ) { }

  ngOnInit() {
    this.getGMBAdminAccount();
    }
  initializeForm(){
    this.SEMForm=this.formBuilder.group({
      id: [ this.existingData?this.existingData.id: 0],
      siteId:[this.existingData?this.existingData.siteId: this.siteId],
      locationId:[this.existingData?this.existingData.locationId: null,[Validators.required]],
      locationName:[ this.existingData?this.existingData.locationName: null,[Validators.required]],
      gmbAdminAccountId:[ this.existingData?this.existingData.gmbAdminAccountId: null,[Validators.required]],
    });
  }
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
  addAccount(form) {
    if (form.valid) {
      this.siteService.addGMB(form.value).subscribe(res=>{
        if(res){
          this.activeModal.close();
          this.toastrService.success(this.msg,'success');
        }
      },
      err=>{
        this.toastrService.error(err.error);
      })
      }      
    }
  getGMBAdminAccount(){
    this.siteService.getGeneralMaster(12).subscribe(res=>{
      if(res){
          this.gmbAdminAccounts=res;
          this.initializeForm();
      }
    },
    err=>{
      throw err;
    })
  }

}
