import { Component, Input, OnInit } from '@angular/core';
import { IntakeStatus } from '@modules/intakes/intake-component.interface';

@Component({
  selector: 'app-intake-sidebar-submenu-item-icon',
  templateUrl: './intake-sidebar-submenu-item-icon.component.html',
  styleUrls: ['./intake-sidebar-submenu-item-icon.component.css'],
})
export class IntakeSidebarSubmenuItemIconComponent implements OnInit {
  @Input() status: IntakeStatus = 'incomplete';
  @Input() isSelected: boolean;

  constructor() { }

  ngOnInit(): void {
  }
}
