// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.loop-col-space .col-6:nth-child(2n){padding-left:35px;}
.loop-col-space .col-6:nth-child(2n+1){padding-right:35px;}*/


.select-theme .img-thumb{ margin:0 10px;}
.select-theme label{ width:170px; white-space:normal;}
.max-height-200{max-height:200px; overflow:auto;}
.active {
    border: 2px solid grey;
}
input[type=radio].setup{
    visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/components/admin/build-site/build-site.component.css"],"names":[],"mappings":"AAAA;4DAC4D;;;AAG5D,0BAA0B,aAAa,CAAC;AACxC,qBAAqB,WAAW,EAAE,kBAAkB,CAAC;AACrD,gBAAgB,gBAAgB,EAAE,aAAa,CAAC;AAChD;IACI,sBAAsB;AAC1B;AACA;IACI,kBAAkB;AACtB","sourcesContent":["/*.loop-col-space .col-6:nth-child(2n){padding-left:35px;}\n.loop-col-space .col-6:nth-child(2n+1){padding-right:35px;}*/\n\n\n.select-theme .img-thumb{ margin:0 10px;}\n.select-theme label{ width:170px; white-space:normal;}\n.max-height-200{max-height:200px; overflow:auto;}\n.active {\n    border: 2px solid grey;\n}\ninput[type=radio].setup{\n    visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
