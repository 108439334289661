import { Component, OnInit, Input } from '@angular/core';
import { ReportingService } from '../../services/reporting.service';
import * as _ from 'lodash';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '@services/http.service';
import moment from 'moment';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { ContentReport } from '@shared/interfaces/reports';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css'],
})
export class ContentComponent implements OnInit, PageHasTable {
  @Input() siteId;
  columnHeaders: ColumnHeader[];
  columnDataTypes ?: ColumnDataTypes;
  blogTableColumnHeaders: ColumnHeader[];
  reportData ?: ContentReport;
  tableTitles = ['Blog Posts', 'Newsfeed', 'Manufacturer Updates'];
  @Input() reportParams;
  @Input() intakeTypeId;
  today = new Date();

  constructor(
    private reportingService: ReportingService,
    private httpService: HttpService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getContentReport(this.siteId, this.reportParams);

    this.getTableData();
  }

  ngOnChanges() {
    this.reportParams = {
      startDate: moment().startOf('month').format('MM/DD/YYYY'),
      endDate: moment(moment()).endOf('month').format('MM/DD/YYYY'),
      _startDate: moment().startOf('month').format('MM/DD/YY'),
      _endDate: moment(moment()).endOf('month').format('MM/DD/YY'),
    };

    this.getContentReport(this.siteId, this.reportParams);
  }

  /**
   * Retrieves the report data for website content items
   * @param {String} siteId The ID of the website you want to query
   * @param {Object} params Any parameters you want to pass to the report query
   */
  getContentReport(siteId, params) {
    params['site_id'] = siteId;

    this.reportData = {};

    this.reportingService.getContentReport(params).subscribe(
      (res: ContentReport) => {
        if (res) {
          this.reportData = res;

          if (res.hasOwnProperty('blog')) {
            for (let i = 0; i < res.blog.length; i++) {
              res.blog[i].urlText = res.blog[i].title.rendered;
            }
          }

          if (this.intakeTypeId == 1) {
            delete this.reportData.blog,

            this.tableTitles.splice(0, 1);
          }
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      post_modified: 'longDate',
      url: 'urlWithText',
    };

    this.blogTableColumnHeaders = [
      { field: 'url', header: 'Title' },
      { field: 'slug', header: 'Slug' },
      { field: 'post_modified', header: 'Date Posted' },
    ];
  }

  onValueChange(event) {
    this.reportParams['startDate'] = moment(event[0]).format('MM/DD/YYYY');
    this.reportParams['endDate'] = moment(event[1]).format('MM/DD/YYYY');
    this.reportParams['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.reportParams['_endDate'] = moment(event[1]).format('MM/DD/YY');
    this.getContentReport(this.siteId, this.reportParams);
  }

  downloadReport() {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = 'none';
    var element = '<div style="padding: 55px;"></div>';
    var opt = {
      margin: 0,
      filename: 'website-content.pdf',
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };
    var output = 'save';
    var worker = html2pdf().from(element).set(opt);
    worker.toPdf().get('pdf').then((pdf) => {
      for (let i = 0; i < Object.keys(this.reportData).length; i++) {
        let values = [];
        let tableData = this.reportData[Object.keys(this.reportData).reverse()[i]];
        let title = this.tableTitles[i];
        _.forEach(tableData, (data) => {
          let value = _.at(data, ['title.rendered', 'slug', 'post_modified']);
          values.push(value);
        });
        pdf.text(0.55, pdf.autoTableEndPosY() + 0.8, title);
        pdf.autoTable({
          head: [['Title', 'Slug', 'Date Modified']],
          body:
            values,
          columnStyles: { 0: { minCellWidth: 2 }, 1: { minCellWidth: 2 }, 2: { minCellWidth: 2 } },
          startY: pdf.autoTableEndPosY() + 1

        });
      }
      document.getElementById("downloadButton").style.display = 'block';

    }).output(output, 'website-content.pdf');
  }
}
