import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class SalesforceService {
    constructor(
        private httpService: HttpService
    ) { }
    getAssertion(userId): Observable<any> {
        return this.httpService.getApi('Salesforce/Assertion/'+userId)
            .pipe(
                tap(res => {
                    if (res) {

                        return res;
                    }

                })
            );
    }
    postToThirdParty(url, option): Observable<any> {
        return this.httpService.postApiThird(url, option)
            .pipe(
                tap(res => {
                    if (res) {

                        return res;
                    }
                })
            );
    }
    writeCookies(): any {
        return this.httpService.postApi('api/WordPress/SetWPCookies', '')
        .pipe(
          tap(res => {
            if(res){

              return res;
            }

        })
      );
      }
}
