import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';
import { masterEnum } from '@shared/master-enum.enum';
import * as _ from 'lodash';
import moment from 'moment';
import { BlogPost, ContentReport } from '@shared/interfaces/reports';
import { forkJoin } from 'rxjs';
import { report } from 'process';
interface CalltrackingReporingResponse {
  appointmentSet: string,
  bookableLeads: string,
  callSources: any[],
  callTrackingIsActive: boolean,
  leads: string,
  missedOpp: string,
  qualifiedLeads: string,
  adSources:any[]
}

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  adUserReports = [
    { id: 15, name: "Project By CP" },
    { id: 16, name: "Project By Product" },
    { id: 17, name: "Average Monthly Report" },
    { id: 18, name: "ROI Performcance Report" },
    { id: 19, name: "Business Performance Report" },
    { id: 20, name: "Sales Performcance Report" },
    {id:21,name:'Sales Budget Upload'}
  ]

  constructor(
    private httpService: HttpService
  ) { }

  getQuarters(){
    let quarterData = [];
    for(let i=4; i>=0; i--){
      let quarter = moment().subtract(i, 'quarter').startOf('quarter').format('MM/DD/YY')
      let quarterObj = {
        label: `${moment(quarter).startOf('quarter').format('MM/DD/YY')} - ${moment(quarter).endOf('quarter').format('MM/DD/YY')}`,
        value: moment(quarter).startOf('quarter').format('MM/DD/YY')
      }
      quarterData.push(quarterObj);
    }
    return quarterData;
  }

  getCallSourceDetails(siteId, reportParams): Observable<any> {
    // Split the callSourceId string into an array of individual IDs
    const callSourceIdsArray = (typeof reportParams.callSourceId === 'string')
      ? reportParams.callSourceId.split(',')
      : [reportParams.callSourceId];
  
    // Create an array of observables by mapping each callSourceId to a separate API call
    const apiCalls = callSourceIdsArray.map((callSourceId) => {
      return this.httpService.getApi(`CallSource/Report?SiteId=${siteId}&CallSourceId=${callSourceId}&StartDate=${reportParams.startDate}&EndDate=${reportParams.endDate}&AdSource=${reportParams.adSource}`);
    });
  
    // Use forkJoin to execute all API calls in parallel
    return forkJoin<CalltrackingReporingResponse[]>(apiCalls).pipe(
      catchError((error) => {
        console.error('Error in CallSource/Report API calls:', error);
        // Handle the error as needed, e.g., return a default value or re-throw the error
        return error;
      }),
      map((results: CalltrackingReporingResponse[]) => {
        var totalResponse: CalltrackingReporingResponse = {
          appointmentSet: '0',
          bookableLeads: '0',
          callSources: [],
          callTrackingIsActive: true,
          leads: '0',
          missedOpp: '0',
          qualifiedLeads: '0',
          adSources:[]
        };

        if (results.length > 1) {
          results.forEach(function (res) {
            // Use the unary plus operator to convert the string to a number
            totalResponse.appointmentSet = (parseInt(totalResponse.appointmentSet) + parseInt(res.appointmentSet)).toString();
            totalResponse.bookableLeads = (parseInt(totalResponse.bookableLeads) + parseInt(res.bookableLeads)).toString();
            totalResponse.leads = (parseInt(totalResponse.leads) + parseInt(res.leads)).toString();
            totalResponse.missedOpp = (parseInt(totalResponse.missedOpp) + parseInt(res.missedOpp)).toString();
            totalResponse.qualifiedLeads = (parseInt(totalResponse.qualifiedLeads) + parseInt(res.qualifiedLeads)).toString();

            totalResponse.callSources = totalResponse.callSources.concat(res.callSources);
            totalResponse.callTrackingIsActive = res.callTrackingIsActive;
          });
          const allADSources = results.flatMap(res =>
            res.callSources.map(callSource => callSource.aD_Source)
          );
          totalResponse.adSources = totalResponse.adSources.concat(Array.from(new Set(allADSources)));

          return totalResponse;
          
        } else {
          totalResponse.appointmentSet = results[0].appointmentSet;
          totalResponse.bookableLeads = results[0].bookableLeads;
          totalResponse.callSources = totalResponse.callSources.concat(results[0].callSources);
          totalResponse.callTrackingIsActive = results[0].callTrackingIsActive;
          totalResponse.leads = results[0].leads;
          totalResponse.missedOpp = results[0].missedOpp;
          totalResponse.qualifiedLeads = results[0].qualifiedLeads;

          const allADSources = results.flatMap(res =>
            res.callSources.map(callSource => callSource.aD_Source)
          );
          totalResponse.adSources = totalResponse.adSources.concat(Array.from(new Set(allADSources)));

          return totalResponse;
        }
      })
    );
  }

  getAccountsData(siteId) {
    return this.httpService.getApi(`SocialMediaMarketing/SetUp/${siteId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getSmmReport(params) {
    return this.httpService.getApi(`SocialMediaMarketing/Report?AccountId=${params.accountId==null ?'':params.accountId}&StartDate=${params.formattedStartDate}&EndDate=${params.formattedEndDate}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  getCallTracking(siteId) {
    return this.httpService.getApi(`callSource/GetCallTracking/${siteId}`)
      .pipe(
        tap(res => {
          return res;
        })
      );
  }

  getGaReport(siteId, params) {
    return this.httpService.getApi(`GoogleAnalytics/Report?SiteId=${siteId}&StartDate=${params.formattedStartDate}&EndDate=${params.formattedEndDate}`,true,null,false,false)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  getGscReport(siteId, params) {
    return this.httpService.getApi(`GoogleSearchConsole/Report?SiteId=${siteId}&StartDate=${params.formattedStartDate}&EndDate=${params.formattedEndDate}`,true,null,false,false)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  getFacebookAdsReport(siteId, params) {
    return this.httpService.getApi(`FacebookAds/Report?SiteId=${siteId}&StartDate=${params.formattedStartDate}&EndDate=${params.formattedEndDate}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getGoogleAdsLeads(siteId, params) {
    return this.httpService.getApi(`GooglePPC/GoogleLead?SiteId=${siteId}&StartDate=${params.formattedStartDate}&Enddate=${params.formattedEndDate}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getGoogleAdsAnalytics(siteId, params) {
    return this.httpService.getApi(`GooglePPC/Report?SiteId=${siteId}&StartDate=${params.formattedStartDate}&Enddate=${params.formattedEndDate}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getInstaPageReportData(siteId, params) {
    return this.httpService.getApi(`GooglePPC/InstaPageReport?SiteId=${siteId}&StartDate=${params.startDate}&EndDate=${params.endDate}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getSemRushReport(siteId, params) {
    return this.httpService.getApi(`SEMrush/report?SiteId=${siteId}&StartDate=${params.formattedStartDate}&EndDate=${params.formattedEndDate}&IsRefreshData=${params.isRefreshData} `)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  // getManufacturerUpdates(data): Observable<any[]> {
  //   return this.httpService.postApi(`wordpress/UpdatedGlobalPages`, data)
  //     .pipe(
  //       tap(res => {
  //         if (res) {
  //
  //           return res;
  //         }

  //       })
  //     );
  // }

  // getNewsfeedArticles(data): Observable<any[]> {
  //   return this.httpService.postApi(`wordpress/UpdatedGlobalNews`, data)
  //     .pipe(
  //       tap(res => {
  //         if (res) {
  //
  //           return res;
  //         }

  //       })
  //     );
  // }

  /**
   * Queries the API for website report data
   * @param {Object} data
   */
  getContentReport(data): Observable<ContentReport> {
    return this.httpService.postApi(`wordpress/report`, data)
      .pipe(
        tap((res) => {
          if (res) {
            return res;
          }
        })
      );
  }

  getContactFormReport(data): Observable<any[]> {
    return this.httpService.postApi(`wordpress/LeadDetails`, data)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getContactLeadReport(data): Observable<any[]> {
    return this.httpService.postApi(`wordpress/Lead`, data)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getPiwik(SiteId): Observable<any[]> {
    return this.httpService.getApi(`Piwik/GetPiwik/${SiteId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getProfileData(siteId) {
    return this.httpService.getApi(`ReviewGen/Profile/${siteId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getReviewgenReport(params) {
    return this.httpService.getApi(`Reviewgen/Report?profileid=${(params.profileId && params.profileId != 'null') ? params.profileId : ''}&StartDate=${params.startDate}&EndDate=${params.endDate}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getRoiReport(params) {
    return this.httpService.getApi(`report/ROIPerformance?CPId=${params.cp}&SiteId=${params.siteId}&StartDate=${params.formattedStartDate}&EndDate=${params.formattedEndDate}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getBusinessPerformanceReport(params) {
    return this.httpService.getApi(`report/BusinessPerformance?CPId=${params.cpId ? params.cpId : ''}&Year=${params.year ? params.year :''}&Month=${params.month ? params.month :''}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getGmbReport(params) {
    return this.httpService.getApi(`googleMyBusiness/report?SiteId=${params.siteId}&StartDate=${params.startDate}&Enddate=${params.endDate}&LocationId=${params.locationId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  getGmbLead(params){
    return this.httpService.getApi(`googleMyBusiness/lead?SiteId=${params.siteId}&StartDate=${params.startDate}&Enddate=${params.endDate}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getLocations(siteId) {
    return this.httpService.getApi(`googleMyBusiness/Location/${siteId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getWebsiteInsightsData(params) {
    return this.httpService.getApi(`site/insights?MasterTypeId=${params.masterTypeId}&SiteId=${params.siteId}&StartDate=${params.startDate}&EndDate=${params.endDate}&MasterId=${params.masterId || ''}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  saveInsightData(data): Observable<any[]> {
    return this.httpService.postApi(`site/insights?MasterTypeId=${data.masterTypeId}&SiteId=${data.siteId}&StartDate=${data.startDate}&EndDate=${data.endDate}`, data)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getGaGscReport(siteId, params): Observable<any[]> {
    return this.httpService.getApi(`GoogleAnalytics/Report?SiteId=${siteId}&StartDate=${params.formattedStartDate}&EndDate=${params.formattedEndDate}`)
      .pipe(
        mergeMap(character => this.httpService.getApi(`GoogleSearchConsole/Report?SiteId=${siteId}&StartDate=${params.formattedStartDate}&EndDate=${params.formattedEndDate}`)),
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  getBluePrintReport(siteId,reportParams):Observable<any>{
    return this.httpService.getApi(`PMS/BluePrintReport?SiteId=${siteId}&ClinicId=${reportParams.clinicId?reportParams.clinicId:''}&StartDate=${reportParams.startDate}&EndDate=${reportParams.endDate}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  getSycleReport(siteId,reportParams):Observable<any>{
    return this.httpService.getApi(`PMS/SycleReport?SiteId=${siteId}&LocationName=${reportParams.locationName?reportParams.locationName:''}&AdSource=${reportParams.adSource?reportParams.adSource:0} &StartDate=${reportParams.startDate}&EndDate=${reportParams.endDate}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  getGeneralMaster(type){
    return this.httpService.getApi(`Master/General/${type}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      )
  }
  getBluePrintSetup(siteId):Observable<any> {
    return this.httpService.getApi(`PMS/BluePrintSetup/${siteId}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  getSycleSetup(siteId):Observable<any> {
    return this.httpService.getApi(`PMS/SycleSetup/${siteId}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  uploadSalesBudget(data): Observable<any[]> {
    return this.httpService.postApi('Report/SalesBudget', data, {
      reportProgress: true // for progress data
    })
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }

}
