import { Component, OnInit } from '@angular/core';
import { ProductManagerService } from '../services/product-manager.service';
import * as _ from 'lodash';
import { MasterService } from '../../admin/masters/services/master.service';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { catchError } from 'rxjs/operators';
import { roles } from '@shared/master-enum.enum';

@Component({
  selector: 'app-pm-dashboard',
  templateUrl: './pm-dashboard.component.html',
  styleUrls: ['./pm-dashboard.component.css']
})
export class PmDashboardComponent implements OnInit {
  sites;
  userInfo;
  dashboardData;
  projectsInProgress;
  graphCategories;
  dataByTask;
  notifications;
  currentRole;
  activeTab = 1;

  constructor(
    private productManagerService: ProductManagerService,
    private masterService: MasterService,
    private router: Router
  ) {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
  }

  ngOnInit() {
    this.getDashboardData(this.userInfo);
    this.getProjectsInProgress(this.userInfo);
    this.getCurrentRole();
  }

  getCurrentRole(){
    if(this.userInfo){
      this.currentRole = _.find(roles, (role) => {
        return role.roleId == this.userInfo.roleId;
      })
    }
  }

  getDashboardData(userInfo) {
    this.productManagerService.getDasboardData(userInfo).subscribe(res => {
      if (res) {
        this.dashboardData = res[0];
      }
    },
      err => {
        throw err;
      })
  }

  getProjectsInProgress(userInfo) {
    this.productManagerService.getProjectsInProgress(userInfo).subscribe(res => {
      if (res) {
        _.forEach(res, (data) => {
          data['graph'] = _.omit(data, ['id', 'total', 'productName', 'is_upgrade']);
          data.graph = this.createChart(data.graph);
        })
        this.projectsInProgress = res;
        // this.getDataByTask(res);
      }
    },
      err => {
        throw err;
      })
  }

  getDataByTask(res) {
    let taskData = [];
    let post = {
      categories: [],
      data: []
    };
    _.forEach(this.graphCategories, (category) => {
      post.categories.push(_.startCase(_.toLower(category)));
      let count = [];
      _.forEach(res, (data) => {
        count.push(data[category]);
      });
      post['data'].push(_.sum(count));
    })
    post['total'] = _.sum(post['data']);
    this.dataByTask = post;
  }

  createChart(posts) {
    let post = {
      categories: [],
      data: []
    };
    _.forIn(posts, function (value, key) {
      post.categories.push(key);
      post.data.push(value);
    });
    post.data = [
      { data: post.data, label: '', borderWidth:1 }
    ];
    this.graphCategories = post.categories;
    return post;
  }

  gotoCustomer() {
    let params = {};
    params = { isNew: true, isActive: true };
    this.router.navigate([`${this.currentRole.url}/sites`], { queryParams: params });
  }

  getLabelId(label){
      this.masterService.getGeneralMasterByType(8).subscribe((res) => {
        if (res) {
          let status = _.find(res, (data) => {
            return data.name.toLowerCase() == label.toLowerCase();
          })
          this.gotoProjects({'task_status': status.id});
        }
      },
        err => {
          throw err;
        })
  }

  gotoProjects(params){

     if(params.task_status=='completed'){
      this.router.navigate([`${this.currentRole.url}/completed-projects`]);
     }else{
      this.router.navigate([`${this.currentRole.url}/projects`], { queryParams: params });
     }

  }

  gotoOpportunity(params){
    this.router.navigate([`${this.currentRole.url}/project`], { queryParams: params });
  }

  gotoIntakes(params){
    this.router.navigate([`${this.currentRole.url}/intake-details`], { queryParams: params });
  }

  getBroadcastNotifications() {
    this.productManagerService.getBroadcastNotifications().subscribe((res) => {
      if (res) {
        this.notifications = res;
      }
    },
      err => {
        throw err;
      });
  }



}
