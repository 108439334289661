// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen {
  app-button-submit, app-reset-button {
    width: 50%;
  }

  div {
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-flow: column wrap;
    margin: 1rem 0;
    width: 19rem;
    padding: 1rem 0;
  }

  em {
    font-weight: bold;
  }

  h1 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0;
    text-decoration: underline;
  }

  li, span {
    font-size: 0.875rem;
  }

  .delete-panel, .error-panel {
    padding: 1rem 3rem 0 0;
  }

  .delete-panel > p, .error-panel {
    padding-right: 6rem;
  }

  .delete-panel-buttons {
    flex-flow: row;
    padding-right: 5rem;
  }

  .edit-delete-buttons {
    display: inline;
    float: right;
    margin: 0 2rem;
    padding: 0;
    width: 3rem;
  }

  .edit-delete-buttons > a:first-child {
    margin-right: 0.5rem;
  }

  .error-message {
    color: rgb(223, 79, 60);
  }

  .label {
    font-weight: bold;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/address-grid-item/address-grid-item.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,oCAAoC;IACpC,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,0BAA0B;EAC5B;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,cAAc;IACd,UAAU;IACV,WAAW;EACb;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["@media screen {\n  app-button-submit, app-reset-button {\n    width: 50%;\n  }\n\n  div {\n    background-color: rgb(255, 255, 255);\n    display: flex;\n    flex-flow: column wrap;\n    margin: 1rem 0;\n    width: 19rem;\n    padding: 1rem 0;\n  }\n\n  em {\n    font-weight: bold;\n  }\n\n  h1 {\n    font-size: 1rem;\n    font-weight: bold;\n    margin-bottom: 0;\n    text-decoration: underline;\n  }\n\n  li, span {\n    font-size: 0.875rem;\n  }\n\n  .delete-panel, .error-panel {\n    padding: 1rem 3rem 0 0;\n  }\n\n  .delete-panel > p, .error-panel {\n    padding-right: 6rem;\n  }\n\n  .delete-panel-buttons {\n    flex-flow: row;\n    padding-right: 5rem;\n  }\n\n  .edit-delete-buttons {\n    display: inline;\n    float: right;\n    margin: 0 2rem;\n    padding: 0;\n    width: 3rem;\n  }\n\n  .edit-delete-buttons > a:first-child {\n    margin-right: 0.5rem;\n  }\n\n  .error-message {\n    color: rgb(223, 79, 60);\n  }\n\n  .label {\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
