import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { countryCode } from '@shared/types/types';

// Main input component to be inherited by other components
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
})
export class InputComponent implements OnInit {
  @Input() controlName: string;
  @Input() disabled = false;
  @Input() error = false;
  @Input() errorMessage: string;
  @Input() formControl: UntypedFormControl;
  @Input() label: string;
  @Input() labelIsBold = true;
  @Input() maxLength = 100;
  @Input() minLength = 1;
  @Input() name: string;
  @Input() placeholderText = '';
  @Input() required = false;
  @Input() showLabel = true;
  @Input() tooltipText: string;

  inputFormGroup: AbstractControl|UntypedFormGroup;

  constructor(
    private controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {
    // Set the name attribute to the same value of the controlName, if one isn't provided
    if (!this.name) {
      this.name = this.controlName;
    }

    this[this.controlName] = new UntypedFormControl();

    // If marked required, then set the required form validation for this component's control
    if (this.required) {
      this.controlContainer.control.get(this.controlName).setValidators(
        [Validators.required]
      );
    }

    // Set's the form control as the parent to this individual control
    this.inputFormGroup = this.controlContainer.control;
  }
}

// Phone number component
// TODO: add regional specifications to adjust based on country.
@Component({
  selector: 'app-input-phone',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
})
export class InputPhoneComponent extends InputComponent {
  @Input() placeholderText = 'Enter Phone Number'

  constructor(
    controlContainer: ControlContainer
  ) {
    super(controlContainer);
  }
}

// ZipCode component with multi-country support
@Component({
  selector: 'app-input-zipcode',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
})
export class InputZipcodeComponent extends InputComponent implements OnChanges {
  @Input() country: countryCode = 'us';

  zipcode = '';

  constructor(
    controlContainer: ControlContainer
  ) {
    super(controlContainer);
  }

  ngOnInit() {
    super.ngOnInit();

    this.placeholderText = 'Enter the Postal Code';
  }

  ngOnChanges(changes): void {
    if (changes.hasOwnProperty('country') && changes['country'].currentValue == 'us') {
      this.label = 'ZIP Code';
      this.placeholderText = 'Enter the ZIP Code';
    } else {
      this.label = 'Post Code';
      this.placeholderText = 'Enter the Postal Code';
    }
  }

  /**
   * Removes hyphens and letters from the postal code string
   */
  numbersOnly(): void {
    this.zipcode = this.zipcode.replace(/[^\d\-]/gi, '');
  }
}
