// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-button-container {
  margin-right: 1rem;
}

.date label {
  color: rgb(38, 153, 251);
  padding: 0 0 0 4px;
  text-decoration: underline;
}

.filter-bar {
  margin-bottom: unset !important;
}

.wizard-content {
  max-width: unset;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/account-manager/intakes/intakeList/intakes.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["app-button-container {\n  margin-right: 1rem;\n}\n\n.date label {\n  color: rgb(38, 153, 251);\n  padding: 0 0 0 4px;\n  text-decoration: underline;\n}\n\n.filter-bar {\n  margin-bottom: unset !important;\n}\n\n.wizard-content {\n  max-width: unset;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
