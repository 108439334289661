import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ForgotPasswordService } from './services/forgot-password.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  currentYear: number;
  forgotPasswordForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    private forgotPasswordService: ForgotPasswordService,
    private toastrService: ToastrService,
    private router: Router
  ) { }

  ngOnInit () {
    this.initializeForm();

    const d = new Date();
    this.currentYear = d.getFullYear();
  }

  initializeForm(){
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]]
  });
  }

  submitEmail(form){
    if(form.valid){
      this.forgotPasswordService.postEmail(form.value).subscribe(res => {
        if(res){
          this.toastrService.success('Password reset link has been sent to your registered email.', 'Success');
          this.router.navigate(['/login']);
        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        });
    }
  }

}
