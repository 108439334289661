// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `i {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 75%;
  display: inline-block;;
  height: 1.25rem;
  vertical-align: middle;
  width: 1.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/glyphs/semrush-icons/semrush-icons/semrush-icons.component.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,4BAA4B;EAC5B,oBAAoB;EACpB,qBAAqB;EACrB,eAAe;EACf,sBAAsB;EACtB,cAAc;AAChB","sourcesContent":["i {\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-size: 75%;\n  display: inline-block;;\n  height: 1.25rem;\n  vertical-align: middle;\n  width: 1.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
