import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { InputGeneric } from '@shared/interfaces/forms';
import { ControlContainer, UntypedFormControl, AbstractControl, UntypedFormGroup } from '@angular/forms';
import {
  countries as CountriesFile,
  islands as CaymanIslands,
  parishes as BermudaParishes,
  provinces as ProvinceFile,
  states as UnitedStates,
} from '@shared/data/locations';
import { countryCode, Day } from '@shared/types/types';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectComponent implements OnInit, OnChanges {
  @Input() controlName: string;
  @Input() defaultOption: number | string;
  @Input() defaultText = '';
  @Input() disabled = false;
  @Input() emptyText = 'There are no available options';
  @Input() formGroupName = '';
  @Input() id = '';
  @Input() label: string;
  @Input() labelIsBold = true
  @Input() name = '';
  @Input() options: Array<InputGeneric> = [];
  @Input() required = false;
  @Input() selectFormGroup: AbstractControl|UntypedFormGroup;

  @Output() onChange = new EventEmitter<any>();

  componentCss = {};

  constructor(private controlContainer: ControlContainer) {}

  ngOnChanges(changes: SimpleChanges) {
    // this.disabled = changes.options.currentValue.length === 0;
  }

  ngOnInit(): void {
    if (!this.disabled) {
      this.disabled = this.options.length === 0;
    }

    if (this.options.length === 0) {
      this.defaultText = this.emptyText;
    }

    if (!this.name) {
      this.name = this.controlName;
    }

    this[this.formGroupName] = new UntypedFormControl();

    this.selectFormGroup = this.controlContainer.control;
  }

  /**
   * Sets the select menu as enabled. Useful for access by parent components
   */
  enableSelectMenu(): void {
    this.disabled = false;
  }

  /**
   * The onChange Output() event for the Select menu in the component
   */
  selectChange() {
    this.onChange.emit();
  }
}

@Component({
  selector: 'app-select-country',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectCountryComponent extends SelectComponent {
  defaultText = 'Select a Country';
  emptyText = 'There are no available countries';
  options: InputGeneric[] = CountriesFile;

  ngOnInit() {
    super.ngOnInit();
  }
}

@Component({
  selector: 'app-select-state',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectStateComponent extends SelectComponent {
  @Input() country: countryCode = 'us'
  @Input() label = 'State';

  defaultText = 'Select a State';
  emptyText = 'There are no available states';
  options: InputGeneric[] = UnitedStates;

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.hasOwnProperty('country') && typeof changes['country'].currentValue == 'string') {
      switch (changes['country'].currentValue.toUpperCase()) {
        case 'BM':
          this.defaultText = 'Select a Parish';
          this.emptyText = 'There are no available parishes';
          this.label = 'Parish';
          this.options = BermudaParishes;
          if(this.defaultOption){
            this.findExistingTerritory(this.options, this.defaultOption);
          }
          break;
        case 'CA':
          this.defaultText = 'Select a Province';
          this.emptyText = 'There are no available provinces';
          this.label = 'Province';
          this.options = ProvinceFile;
          if(this.defaultOption){
            this.findExistingTerritory(this.options, this.defaultOption);
          }
          break;
        case 'KY':
          this.defaultText = 'Select an Island';
          this.emptyText = 'There are no available islands';
          this.label = 'Island';
          this.options = CaymanIslands;
          if(this.defaultOption){
            this.findExistingTerritory(this.options, this.defaultOption);
          }
          break;
        case 'US':
        default:
          this.defaultText = 'Select a State';
          this.emptyText = 'There are no available states';
          this.label = 'State';
          this.options = UnitedStates;
          if(this.defaultOption){
            this.findExistingTerritory(this.options, this.defaultOption);
          }
          break;
      }
    }
  }

  findExistingTerritory(territories: InputGeneric[], existingTerritory: string | number): void {
    const selectedTerritory = territories.find(t => t.value.toLowerCase() == existingTerritory.toString().toLowerCase())
    if(selectedTerritory){
      selectedTerritory.selected = true;
    } else {
      territories[0].selected = true;
    }
  }
}

@Component({
  selector: 'app-select-office-hours',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectOfficeHours extends SelectComponent {
  defaultText = 'Select a Time';
  options: InputGeneric[] = UnitedStates;

  readonly days: Day[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]

  ngOnInit() {
    super.ngOnInit();
  }
}
