import { Component, OnInit } from '@angular/core';
import { CorporatePartnerService } from '../services/corporate-partner.service';
import { HttpService } from '@services/http.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-cp',
  templateUrl: './add-cp.component.html',
  styleUrls: ['./add-cp.component.css']
})
export class AddCpComponent implements OnInit {
  salesForceCp;
  cp;
  constructor(
    private corporatePartnerService: CorporatePartnerService,
    private httpService: HttpService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.getCpData();
  }

  dismissModal() {
    this.activeModal.close();
  }

  getCpData(){
    this.corporatePartnerService.getSalesForceCp().subscribe((res) => {
      if (res) {
        this.salesForceCp = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

}
