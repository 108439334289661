import { Injectable } from '@angular/core';import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';
import { masterEnum } from '@shared/master-enum.enum';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ProductManagerService {

  constructor(
    private httpService: HttpService
  ) { }

  getSites(userId){
    return this.httpService.getApi(`site/getSites/${userId}`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }

  getDasboardData(userInfo){
    return this.httpService.getApi(`Report/Dashboard`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }

  getProjectsInProgress(userInfo){
    return this.httpService.getApi(`Report/Projects`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }

  getAdvanceSearchData(filterBy, userId){
    return this.httpService.getApi(`Report/ProjectBySearch?DomainUrl=${filterBy.domainUrl || ''}&SiteName=${filterBy.businessName || ''}&SiteType=${filterBy.siteType || ''}&SearchByCP=${filterBy.cp || ''}&AccountManager=${filterBy.am || ''}&TaskStatus=${filterBy.status || ''}&StartDate=${filterBy.formattedStartDate || ''}&EndDate=${filterBy.formattedEndDate || ''}&ProductType=${filterBy.project || ''}&IsUpgrade=${filterBy.isUpgrade || ''}&taskSubStatus=${filterBy.taskSubStatus || ''}&SiteInDiscover=${filterBy.siteInDiscover || ''}&CPAccountNo=${filterBy.cpAccountNo || ''}`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }
  getCompletedProjectData(filterBy){
    return this.httpService.getApi(`report/CompletedProjects?DomainUrl=${filterBy.domainUrl || ''}&SiteName=${filterBy.businessName || ''}&CPAccountNo=${filterBy.cpAccountNo || ''}&SiteType=${filterBy.siteType || ''}&SearchByCP=${filterBy.cp || ''}&AccountManager=${filterBy.am || ''}&StartDate=${filterBy.formattedStartDate || ''}&EndDate=${filterBy.formattedEndDate || ''}&ProductType=${filterBy.project || ''}&IsUpgrade=${filterBy.isUpgrade || ''}`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }
  getBroadcastNotifications(): Observable<any[]> {
    return this.httpService.getApi(`site/BroadcastNotification`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

}
