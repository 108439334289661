import { Component, OnInit, ViewChild } from '@angular/core';
import {SiteService} from '../service/site.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {ExcelService} from '../../reporting/services/excel.service';
import { CorporatePartnerService } from '../../../components/admin/manage-corporate-partner/services/corporate-partner.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MasterService } from '../../admin/masters/services/master.service';
import { DatePipe } from '@angular/common';
import { HttpService } from '../../../services/http.service';
import { NgbModal, ModalDismissReasons, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import * as _ from 'lodash';
import { CancelReasonComponent } from '@shared/cancel-reason/cancel-reason.component';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';


@Component({
  selector: 'app-cancel-site',
  templateUrl: './cancel-site.component.html',
  styleUrls: ['./cancel-site.component.css'],
  providers: [DatePipe]
})
export class CancelSiteComponent implements OnInit, PageHasTable {
  @ViewChild('dt') table: TableGenericComponent;

  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  startDate=null;
  endDate=null;
  filterBy = {
    domainUrl: null,
    siteName: null,
    email:null,
    dateRange:'',
    _startDate:'',
    _endDate:''
  };
  cancelSiteLists=[];
  allSites;
  corporatePartner;
  projectType;
  siteLists;
  constructor(
    private siteService:SiteService,
    private router:Router,
    private toastrService: ToastrService,
    private activatedRoute:ActivatedRoute,
    private excelService:ExcelService,
    private httpService: HttpService,
    public modalService: NgbModal,
    private corporatePartnerService: CorporatePartnerService,
    private masterService: MasterService,
    private datePipe: DatePipe,
    ) {
      this.filterBy['startDate'] = this.filterBy['_startDate'] = this.activatedRoute.snapshot.queryParamMap.get('startDate');
      this.filterBy['endDate'] = this.filterBy['_endDate'] = this.activatedRoute.snapshot.queryParamMap.get('endDate');
    }

  ngOnInit() {
    this.getCorporatePartner();
    this.getProductType();
    this.getCancelSites(this.filterBy);
    this.getSites();

    this.getTableData();
  }
  getCorporatePartner() {
    this.corporatePartnerService.getCorporatePartners().subscribe(res => {
      if (res) {
        this.corporatePartner = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getProductType() {
    this.masterService.getGeneralMasterByType(4).subscribe((res) => {
      if (res) {
        this.projectType = res;
      }
    },
      err => {
        throw err;
      })
  }
  getSites(){
    this.siteService.getSites(null,false).subscribe((res) => {
      if (res) {
        this.siteLists = Object.assign([], res);
      }
    },
      err => {
        throw err;
      })
  };

  /**
   * Gets data for the tables and sets the column headers
   *
   * @TODO Move API call to this method
   */
  getTableData(): void {
    this.columnHeaders = [
      {field: 'domainUrl', header: 'Domain URL'},
      {field: 'name', header: 'Business Name'},
      {field: 'corporatePartnerName', header: 'Program', filter: 'select'},
      {field: 'intakeType', header: 'Product Family', filter: 'select'},
      {field: 'productName', header: 'Product', filter: 'none'},
      {field: 'modifiedDate', header: 'Cancelled Date', filter: 'none', width: 10},
      {field: 'email', header: 'Email', width: 20},
    ];

    this.columnDataTypes = {
      modifiedDate: 'date',
    }
  }

  getCancelSites(filterBy){
    filterBy['formattedStartDate'] = this.datePipe.transform(filterBy.startDate, 'yyyy-MM-dd');
    filterBy['formattedEndDate'] = this.datePipe.transform(filterBy.endDate, 'yyyy-MM-dd');
    this.siteService.getCancelSite(filterBy).subscribe((res) => {
      if (res) {
          // Map each item to rename 'name' to 'productName'
          this.cancelSiteLists = res.map(site => ({
              ...site,
              productName: site.name
          }));
          console.log(this.cancelSiteLists);
          if (Object.values(filterBy).every(x => (x === null || x === ''))) {
              this.allSites = Object.assign([], this.cancelSiteLists); // Ensure this also uses the updated property
          }
      }
  },err=>{
      this.httpService.openErrorPopup(err.error.message);
    })
  }
  selectedItem(){
    this.getCancelSites(this.filterBy);
  }
  applyFilter(obj?){
    this.getCancelSites(this.filterBy);
  }

  applySearch(inputKey, filterKey, key) {
    if ((typeof this.filterBy[inputKey]) == 'object') {
      this.filterBy[filterKey] = this.filterBy[inputKey][key];
    } else {
      this.filterBy[filterKey] = this.filterBy[inputKey];
    }
    this.getCancelSites(this.filterBy);
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.siteLists.filter(v => v['businessName'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  formatter = (result: string) => `${result['businessName']}`;
  inputFormatter = (result: string) => `${result['businessName']}`;

  searchDomain = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 3 ? []
      : this.siteLists.filter(v => v['domainUrl'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )
domainFormatter = (result: string) => `${result['domainUrl']}`;
domainInputFormatter = (result: string) => `${result['domainUrl']}`;

searchEmail = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 3 ? []
      : this.allSites.filter(v => v.email.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )
emailFormatter = (result: string) => `${result['email']}`;
emailInputFormatter = (result: string) => `${result['email']}`;

  onValueChange(event) {
    if (event == null || event == '')
      return;
    this.filterBy['startDate'] = moment(event[0]).format('MM/DD/YYYY');
    this.filterBy['endDate'] = moment(event[1]).format('MM/DD/YYYY');
    this.filterBy['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.filterBy['_endDate'] = moment(event[1]).format('MM/DD/YY');
    this.applyFilter();
  }

  /**
   * Resets the table filter
   */
  resetFilter(): void {
   this.filterBy = {
      domainUrl: null,
      siteName: null,
      email: null,
      dateRange: '',
      _startDate: '',
      _endDate: '',
    };

    this.getCancelSites(this.filterBy);

    this.table.clearFilters();
  }

  viewNotes(cancelationNote, productName){
    var model = this.modalService.open(CancelReasonComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.message = cancelationNote;
    model.componentInstance.productName = productName;
    model.componentInstance.isViewMode = true;
    // model.result.then((cancelationNote) => {

    // }, (reason) => {
    //   console.log(`Dismissed: ${reason}`);
    // });
  }

}
