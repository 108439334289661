import { Injectable } from '@angular/core';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntakesService {

  constructor(
    private httpService: HttpService
  ) { }

  getIntakes(userId,startDate,endDate,filterBy): Observable<any[]> {

    let query='UserId='+userId+'&';
    query+=filterBy.dt?'Dt='+filterBy.dt+'&':'';
    query+=startDate?'StartDate='+startDate+'&':'';
    query+= endDate?'EndDate='+endDate+'&':'';
    query+= filterBy && filterBy.domainUrl ?'DomainUrl='+filterBy.domainUrl+'&':'';
    query+= filterBy && filterBy.siteName ?'SiteName='+filterBy.siteName+'&':'';
    query+= filterBy && filterBy.intakeTypeId ?'intakeTypeId='+filterBy.intakeTypeId+'&':'';
    query+= filterBy && filterBy.status ?'Status='+filterBy.status+'&':'';
    query+= filterBy && filterBy.cpId ?'cpId='+filterBy.cpId+'&':'';
    query+= filterBy && filterBy.paymentStatusName ?'PaymentStatus='+filterBy.paymentStatusName+'&':'';
    query+= filterBy && filterBy.paymentModeName ?'PaymentMode='+filterBy.paymentModeName+'&':'';
    query+= filterBy && filterBy.isNew ?'isNew='+filterBy.isNew:'';

    return this.httpService.getApi(`Intake/GetProjects?${query}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getIntakeProductList(IntakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/GetIntake?IntakeId=${IntakeId}`)
    .pipe(
      tap(res => {
        if(res){
          return res;
        }
    })
  );
  }

  getBillingIntakeProduct(obj): Observable<any[]> {
    return this.httpService.getApi(`Intake/GetBillingIntakeProduct?IntakeId=${obj.intakeId}&ReCheckExistingProduct=${obj.reCheckExistingProduct}&IsCouponTypeAmount=${obj.isCouponTypeAmount}`)
    .pipe(
      tap(res => {
        if(res){
          return res;
        }
    })
  );
  }

  reCheckForCoupon(data): Observable<any> {
    return this.httpService.putApi(`intake/ReCheckForCoupon`, data, '')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  getPaymentMode(): Observable<any[]> {
    return this.httpService.getApi(`Master/General/1`)
    .pipe(
      tap(res => {
        if(res){
          return res;
        }
    })
  );
  }
  getPaymentStatus(): Observable<any[]> {
    return this.httpService.getApi(`Master/General/2`)
    .pipe(
      tap(res => {
        if(res){
          return res;
        }
    })
  );
  }

  postIntakeInitiate(paymentInfo): Observable<any[] | any> {
    return this.httpService.postApi(`intake/IntakeInitiate`, paymentInfo)
    .pipe(
      tap(res => {
        if(res){
          return res;
        }
    })
  );
  }
  deleteIntake(intakeId): Observable<any> {
    return this.httpService.deleteApi(`intake/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  getSites(cpId?){
    return this.httpService.getApi(`site/getSites?CPId=${cpId ? cpId : 0}`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }

}
