import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '@shared/data-service.service';
import { getRoleById } from '@shared/master-enum.enum';

@Component({
  selector: 'app-invalid-address-modal',
  templateUrl: './invalid-address-modal.component.html',
  styleUrls: ['./invalid-address-modal.component.css']
})
export class InvalidAddressModalComponent implements OnInit {
  selectedSiteId: string;
  @Input() message = 'There has been an error';
  @Input() title = 'Error';
  public userrolename: string;

  constructor(
    public activeModal: NgbActiveModal,
    private dataservice: DataService
  ) { 
    this.selectedSiteId = this.dataservice.selectedSiteId;
  }

  ngOnInit() {
    const userInfoJson = localStorage.getItem('userInfo');
    // Parse the JSON string into an object
    const userInfo = JSON.parse(userInfoJson);
    // Extract the 'userId' property
    const role = userInfo.roleId;
    
    const userrole = getRoleById(role);
    if (userrole) {
       this.userrolename = userrole.url.slice(1); // Removes the first character
  }    
  
  if(this.selectedSiteId != null && (role >= 1 && role <= 10)){
    localStorage.setItem('selectedSite', JSON.stringify({ siteId: this.selectedSiteId }));
    }
  }


  
  /**
   * Closes the modal
   */
  closeModal() {
    this.activeModal.close();
  }
}