import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import { HttpParams } from '@angular/common/http';
import { CounselEarAppointment, CounselEarSetup } from '@shared/interfaces/counsel-ear';

@Injectable({
  providedIn: 'root',
})
export class CounselEarService {
  constructor(private httpService: HttpService) { }

  deleteSetup(locationData: CounselEarSetup): Observable<any> {
    locationData['is_active'] = false;

    return this.httpService.postApi('OfficeMgmt/SaveSetup', locationData);
  }

  /**
   * Retrieves All CounselEar Appointments
   * @param {number|string} siteId The ID for the client site
   * @param {string} startDate The start date for the appointment search range
   * @param {string} endDate The end date for the appointment search range
   *
   * @return {Observable<CounselEarAppointment[]>} An observable with all of the appointment entries
   */
  getAppointments(
    siteId: string,
    startDate: string,
    endDate: string
  ): Observable<CounselEarAppointment[]> {
    const urlParams = new HttpParams({
      fromObject: {
        siteId: siteId,
        startDate: startDate,
        endDate: endDate,
      },
    });

    return this.httpService.getApi(`OfficeMgmt/GetReport?${urlParams.toString()}`);
  }

  /**
   * Retrievs all of the CounselEar setup entries
   *
   * @param {string} siteId The ID for the client site
   *
   * @return {Observable<CounselEarSetup[]>} Returns an observable of all the counsel ear setups
   */
  getSetups(siteId: string): Observable<CounselEarSetup[]> {
    return this.httpService.getApi(`OfficeMgmt/GetCounselEarSetup/${siteId}`);
  }

  saveAppointment(appointmentData: object) {

  }

  saveSetup(setupData): Observable<any> {
    return this.httpService.postApi('OfficeMgmt/SaveSetup', setupData);
  }
}
