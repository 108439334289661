import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-trial',
  templateUrl: './add-trial.component.html',
  styleUrls: ['./add-trial.component.css'],
})
export class AddTrialComponent implements OnInit {
  differenceInDays;
  minDate = new Date();
  modelData = {
    prorate: false,
    value: null,
  };
  required = true;
  type: string | 'billing';

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.minDate = new Date(this.minDate.setDate(this.minDate.getDate() + 1));

    this.modelData.value = this.type == 'billing' ? this.minDate : '';
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  confirm(){
    if(this.type=='duration'||this.type=='billing'){
      const currentDate=new Date();
      const selectedDate=new Date(this.modelData.value);
      const differenceInTime = selectedDate.getTime() - currentDate.getTime();
      this.differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
      if(this.differenceInDays<0 || this.differenceInDays>730)
          return;
      this.modelData.value=this.differenceInDays;
    }

      this.activeModal.close(this.modelData);
  }
}
