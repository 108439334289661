export const environment = {
  production: false,
  apiUrl: 'https://qa-manage.audiologydesign.com/service/',
  themeApiUrl: 'https://qa-website.audiologydesign.com/',
  wpSSO: 'https://qa-website.audiologydesign.comsite/Pathwp-json/wp/connect',
  imgUrl: 'https://s3.amazonaws.com/manage.audilogydesign.com/QA/wwwroot/',
  jiraImagUrl: 'https://qa-manage.audiologydesign.com/service/',
  jiraURL: 'https://audiologydesign.atlassian.net/browse',
  isMasterMockTrue: false,
  wpRootPath: '/',
};