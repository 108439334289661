import { Injectable } from '@angular/core';
import { CallSources, SummaryData } from '@shared/interfaces/call_tracking';

@Injectable({
  providedIn: 'root'
})
export class CallTrackingService {
  constructor() { }

  /**
   * Calculates appointments set by calculating the sum of all appointments marked yes
   *
   * @param {CallSources} callSources
   * @return number
   */
  calculateAppointmentsSet(callSources: CallSources[]): number {
   return callSources.filter(a => a.appointment_Set === 'Yes').length;
  }

  /**
   * Calculates appointments set by calculating the sum of all missed appointments marked 'Yes'
   *
   * @param callSources
   * @return number
   */
  calculateAppointmentsNotSet(callSources: CallSources[]): number {
    return callSources.filter(a => a.missed_Opp === 'Yes').length;
  }

  calculateBookableHearingAidLead(callSources: CallSources[]): number {
    return 23;
  }

  /**
   * Calculates total phone leads by adding leads + qualified leads + bookable hearing aid leads
   *
   * @return {Number}
   */
  calculatePhoneLeads(callSources: CallSources[]): number {
    return 23;
  }

  calculateQualifiedLeads(callSources: CallSources[]): number {
    return 23;
  }

  getCallTrackingSummaryData(callSources: CallSources[]): SummaryData {
    console.log(callSources);
    console.log(this.calculateAppointmentsSet(callSources));
    return {
      appointmentsSet: this.calculateAppointmentsSet(callSources),
      appointmentsNotSet: this.calculateAppointmentsNotSet(callSources),
      bookableLeads: this.calculateBookableHearingAidLead(callSources),
      leads: this.calculatePhoneLeads(callSources),
      qualifiedLeads: this.calculateQualifiedLeads(callSources),
    };
  }
}
