// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-action-icon{
    top:4px;
}

.wizard-content {
  max-width: unset;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/customer-intakes-list/customer-intakes-list.component.css"],"names":[],"mappings":"AAAA;IACI,OAAO;AACX;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".table-action-icon{\n    top:4px;\n}\n\n.wizard-content {\n  max-width: unset;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
