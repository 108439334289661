// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all {
  input {
    background-color: rgb(52, 151, 236);
    color: white;
  }

  .btn {
    padding: 6px 10px
  }

  .disabled {
    border: 2px solid rgb(52, 151, 236);
    background-color: rgb(255, 255, 255);
    color: rgb(52, 151, 236);
  }

  .disabled:hover {
    cursor: not-allowed;
  }

}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/buttons/button-submit/button-submit.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,mCAAmC;IACnC,YAAY;EACd;;EAEA;IACE;EACF;;EAEA;IACE,mCAAmC;IACnC,oCAAoC;IACpC,wBAAwB;EAC1B;;EAEA;IACE,mBAAmB;EACrB;;AAEF","sourcesContent":["@media all {\n  input {\n    background-color: rgb(52, 151, 236);\n    color: white;\n  }\n\n  .btn {\n    padding: 6px 10px\n  }\n\n  .disabled {\n    border: 2px solid rgb(52, 151, 236);\n    background-color: rgb(255, 255, 255);\n    color: rgb(52, 151, 236);\n  }\n\n  .disabled:hover {\n    cursor: not-allowed;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
