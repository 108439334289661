import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PmDashboardComponent } from './pm-dashboard/pm-dashboard.component';
import { TicketModule } from '../ticket/ticket.module';
import { AudChartsModule } from '@shared/charts/charts.module';
import { ProjectsComponent } from './projects/projects.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { routing } from '../pages.routing';
import { CompletedProjectsComponent } from './completed-projects/completed-projects.component';
import { SharedModule } from '@shared/shared.module';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';


@NgModule({
  imports: [
    routing,
    CommonModule,
    NgbModule,
    TicketModule,
    AudChartsModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    TableModule,
    SharedModule,
    PaginatorModule,

  ],
  declarations: [PmDashboardComponent, ProjectsComponent, CompletedProjectsComponent],
})

export class ProductManagerModule { }
