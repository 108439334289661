import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from '../services/configuration.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../services/http.service';

@Component({
  selector: 'app-add-config',
  templateUrl: './add-config.component.html',
  styleUrls: ['./add-config.component.css']
})
export class AddConfigComponent implements OnInit {
  addConfigForm:UntypedFormGroup
  existingConfig;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private httpService: HttpService,
    public configurationService: ConfigurationService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }
  initializeForm(){
    this.addConfigForm=this.formBuilder.group({
      id: [ this.existingConfig?this.existingConfig.id: null],
      name:[this.existingConfig?this.existingConfig.name :'',[Validators.required]],
      value:[this.existingConfig && this.existingConfig.value != 'XXXXX' ? this.existingConfig.value :'',[Validators.required]],
      description:[ this.existingConfig?this.existingConfig.description:'']
    });
  }

  updateConfig(form) {
    if (form.valid) {
      let configInfo = form.value;
      if (configInfo.id) {
        this.configurationService.updateConfig(configInfo).subscribe(res => {
          this.activeModal.close();
        },
          err => {
            this.httpService.openErrorPopup(err.error.message);
          });
      }
      
    }
    
  }
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }

}
