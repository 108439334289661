import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Postal Code Validator for US ZIP codes
 *
 * @constructor
 *
 * @return {ValidatorFn}
 */
export function usPostalCodeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/g).test(control.value) ? null : {
      postalCode: {
        valid: false,
      },
    };
  };
}

/**
 * Postal Code validator for Bermudan postal codes
 *
 * @return {ValidatorFn} The validation results
 */
export function bermudanPostalCodeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return new RegExp(/^[A-Za-z]{2}[ -]?\d{2}$/g).test(control.value) ? null : {
      postalCode: {
        valid: false,
      },
    };
  };
}

/**
 * Postal Code validator for Canadian postal codes
 *
 * @constructor
 *
 * @param {string} province
 *
 * @return {ValidatorFn}
 */
export function canadianPostalCodeValidator(province = null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let regex = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');

    switch (province) {
      case 'ab':
        regex = new RegExp(/^[Tt]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
      case 'bc':
        regex = new RegExp(/^[Vv]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
      case 'mb':
        regex = new RegExp(/^[Rr]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
      case 'nb':
        regex = new RegExp(/^[Ee]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
      case 'nl':
        regex = new RegExp(/^[Aa]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
      case 'nt':
        regex = new RegExp(/^[Xx]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
      case 'ns':
        regex = new RegExp(/^[Bb]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
      case 'nu':
        regex = new RegExp(/^[Xx]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
      case 'on':
        regex = new RegExp(/^[KkLlMmNnPp]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
      case 'pe':
        regex = new RegExp(/^[Cc]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
      case 'qc':
        regex = new RegExp(/^[GgHhJj]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
      case 'sk':
        regex = new RegExp(/^[Ss]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
      case 'yt':
        regex = new RegExp(/^[Yy]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'i');
        break;
    }

    return regex.test(control.value) ? null : {
      valid: false,
    };
  };
}

/**
 * Validates the Cayman Island postal codes
 *
 * @param {string} island The code for one of the three Cayman islands.
 * @return {ValidatorFn} The validation response
 */
export function caymanianPostalCodeValidator(island: string = null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let regex = new RegExp(/^KY[1-3][ -]?\d{4}$/, 'i');

    switch (island) {
      case 'ky1': regex = new RegExp(/^[Kk][Yy]1[ -]?\d{4}$/, 'i'); break;
      case 'ky2': regex = new RegExp(/^[Kk][Yy]2[ -]?\d{4}$/, 'i'); break;
      case 'ky3': regex = new RegExp(/^[Kk][Yy]3[ -]?\d{4}$/, 'i'); break;
    }

    return regex.test(control.value) ? null : {
      valid: false,
    };
  };
}
