import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  transform(time: string, inFormat = 'HH:mm:ss', outFormat = 'h:mm A'): string {
    return moment(time, inFormat).format(outFormat);
  }

}
