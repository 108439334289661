import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PaymentService } from '../payment/services/payment.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { MasterService } from '../../admin/masters/services/master.service';
import { HttpService } from '@services/http.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-statement-of-work',
  templateUrl: './statement-of-work.component.html',
  styleUrls: ['./statement-of-work.component.css']
})
export class StatementOfWorkComponent implements OnInit {
  @Output() back = new EventEmitter<any>();
  @Output() goToPayment = new EventEmitter<any>();
  @Output() goToIntake = new EventEmitter<any>();
  @Output() open = new EventEmitter<any>();
  @Input() parent;
  @Input() intake;
  intakeId;
  isAgreed;
  sowDataByType;
  currentRole
  activeSection:any="a-section1";
  currentSection: string = 'main';
  constructor(
    private paymentService: PaymentService,
    private masterService: MasterService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
    })
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        // this.getIntakeProductList(params.id);
      }
    });
  }

  ngOnInit() {
    // this.getDataByType(1);
  }
  showSection(event: Event, section: string): void {
    event.preventDefault();
    this.currentSection = section;
  }
  getDataByType(type){
    this.masterService.getSowDataByType(type).subscribe(res => {
      if(res){
        this.sowDataByType = res;
        _.forEach(this.sowDataByType, (type) => {
          //type.description = type.description.replace(/#exhibit/g,`#/${this.currentRole}/exhibit/`);
          type.description = type.description.split('#exhibit').join(`#/${this.currentRole}/exhibit/`);
        })
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }
  scroll(el, event:Event) {
    let element = document.getElementById(el);
    event.preventDefault();
    event.stopPropagation();
    this.activeSection=event.currentTarget["id"]; 
    element.scrollIntoView({behavior: 'smooth'});
}
  openExhibit(exhibitType) {
    this.open.next(exhibitType);
  }
  goBack() {
    this.back.next();
  }
  acceptTermsAndConditions(isAgreed) {
    if (isAgreed) {
      let info = {
        intakeId: this.intakeId || this.intake.intakeId,
        IsAcceptTnC: isAgreed
      }
      this.paymentService.acceptTermCondition(info).subscribe((res) => {
        if (res) {
          this.intake.isAcceptTnC = isAgreed;
          this.parent == 'payment' ? this.goToPayment.next(this.intake) : this.goToIntake.next(this.intake);
        }
      },
        err => {
          throw err;
        })
    }

  }

}
