import { Roles } from '@shared/enums/Roles';

const corporatePartnerUsers = [
  Roles.CORPORATE_PARTNER,
  Roles.CP_USER,
];

const internalUsers = [
  Roles.SUPER_ADMIN,
  Roles.BUSINESS_DEVELOPMENT,
  Roles.PRODUCT_MANAGEMENT,
  Roles.ONBOARDING,
  Roles.ACCOUNT_MANAGEMENT,
  Roles.CLIENT_COMMUNICATION,
  Roles.DESIGNER_DEVELOPER,
  Roles.SEO_EDITOR,
  Roles.SEARCH_MARKETING,
  Roles.SALES_MARKETING,
];

const actions = {
  CAN_ADD_GOOGLE_ADS: 'CAN_ADD_GOOGLE_ADS',
  CAN_SEARCH_SITES: 'CAN_SEARCH_SITES',
  CHANGE_SITE_SETTINGS: 'CHANGE_SITE_SETTINGS',
  EDIT_REVIEW_GEN_PROFILE: 'EDIT_REVIEW_GEN_PROFILE',
  IMPERSONATE_USER: 'IMPERSONATE_USER',
  LOG_INTO_WORDPRESS: 'LOG_INTO_WORDPRESS',
  MANAGE_BLUEPRINT: 'MANAGE_BLUEPRINT',
  MANAGE_CARDS: 'MANAGE_CARDS',
  MANAGE_COUNCIL_EAR: 'MANAGE_COUNCIL_EAR',
  MANAGE_GOOGLE_MY_BUSINESS: 'MANAGE_GOOGLE_MY_BUSINESS',
  MANAGE_INTAKES: 'MANAGE_INTAKES',
  MANAGE_LOCATIONS: 'MANAGE_LOCATIONS',
  MANAGE_REVIEW_GEN_EMAILS: 'MANAGE_REVIEW_GEN_EMAILS',
  MANAGE_SYCLE: 'MANAGE_SYCLE',
  MANAGE_SOCIAL_MEDIA: 'MANAGE_SOCIAL_MEDIA',
  MANAGE_SEO_LOCATIONS: 'MANAGE_SEO_LOCATIONS',
  VIEW_BILLING: 'VIEW_BILLING',
  VIEW_JIRA_PROJECT: 'VIEW_JIRA_PROJECT',
  VIEW_REPORTS: 'VIEW_REPORTS',
} as const;

type ActionType = keyof typeof actions;

const mappings = new Map<ActionType, Array<any>>();

mappings.set(actions.CAN_ADD_GOOGLE_ADS, internalUsers);
mappings.set(actions.CAN_SEARCH_SITES, [...corporatePartnerUsers, ...internalUsers]);
mappings.set(actions.CHANGE_SITE_SETTINGS, internalUsers);
mappings.set(actions.EDIT_REVIEW_GEN_PROFILE, internalUsers);
mappings.set(actions.IMPERSONATE_USER, [...internalUsers, ...corporatePartnerUsers]);
mappings.set(actions.LOG_INTO_WORDPRESS, internalUsers);
mappings.set(actions.MANAGE_BLUEPRINT, internalUsers);
mappings.set(actions.MANAGE_COUNCIL_EAR, internalUsers);
mappings.set(actions.MANAGE_CARDS, [
  Roles.SUPER_ADMIN,
  Roles.BUSINESS_DEVELOPMENT,
  Roles.PRODUCT_MANAGEMENT,
  Roles.ONBOARDING,
  Roles.ACCOUNT_MANAGEMENT,
  Roles.CLIENT_COMMUNICATION,
  Roles.DESIGNER_DEVELOPER,
  Roles.SEO_EDITOR,
  Roles.SEARCH_MARKETING,
  Roles.SALES_MARKETING,
  Roles.CLIENT,
  Roles.SITE_USER,
]);
mappings.set(actions.MANAGE_GOOGLE_MY_BUSINESS, internalUsers);
mappings.set(actions.MANAGE_REVIEW_GEN_EMAILS, internalUsers);
mappings.set(actions.MANAGE_INTAKES, internalUsers);
mappings.set(actions.MANAGE_SYCLE, internalUsers);
mappings.set(actions.MANAGE_SOCIAL_MEDIA, internalUsers);
mappings.set(actions.MANAGE_SEO_LOCATIONS, internalUsers);
mappings.set(actions.VIEW_BILLING, [...internalUsers, ...corporatePartnerUsers]);
mappings.set(actions.VIEW_JIRA_PROJECT, internalUsers);
mappings.set(actions.VIEW_REPORTS, [...internalUsers, ...corporatePartnerUsers]);


export default mappings;
export { actions, ActionType, mappings };
