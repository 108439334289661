import { Component, OnInit } from '@angular/core';
import { PageCollectionService } from '../services/page-collection.service'
import * as _ from 'lodash';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../services/http.service';

@Component({
  selector: 'app-page-collection',
  templateUrl: './page-collection.component.html',
  styleUrls: ['./page-collection.component.css']
})
export class PageCollectionListComponent implements OnInit {
  allPageCollectionData;
  pageCollectionList;
  filterBy;
  constructor(
    private pageCollectionService: PageCollectionService,
    private router: Router,
    private httpService: HttpService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.getPageCollectionData();
  }

  getPageCollectionData(){
    this.pageCollectionService.getAllCollectionData().subscribe(res => {
      if(res){
        _.forEach(res, (data) => {
          let parentPage = _.find(res, (collectionData) => {
            return collectionData.id == data.parentId;
          });
          data['parentName'] = parentPage ? parentPage.name : '';
        });
        this.allPageCollectionData = res;
        this.pageCollectionList = Object.assign([], this.allPageCollectionData);
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  goToEdit(collection){
    // this.userService.existingUser = user;
    this.router.navigate(['/admin/add-page-collection/'+collection.id]);
  }

  deleteCollection(id, username: string) {
    if (confirm("Are you sure to delete " + username)) {
      this.pageCollectionService.delete(id).subscribe(res => {
        // var removeIndex = this.allPageCollectionData.map(function (item) { return item.id; })
        //   .indexOf(id);
        // ~removeIndex && this.allPageCollectionData.splice(removeIndex, 1);
        this.getPageCollectionData();
      }, err => {
        this.httpService.openErrorPopup(err.error.message);
      });

    }
  }

  applyFilter(filterBy) {
    let filteredCollectionData = this.allPageCollectionData;
    if (filterBy) {
      if (!_.isEmpty(filterBy.name)) {
        filteredCollectionData = _.filter(filteredCollectionData, (collection) => {
          return _.includes(collection['name'].toLowerCase(), filterBy.name.toLowerCase());
        });
      }
      if (!_.isEmpty(filterBy.title)) {
        filteredCollectionData = _.filter(filteredCollectionData, (collection) => {
          return _.includes(collection['title'].toLowerCase(), filterBy.title.toLowerCase());
        });
      }
      if (!_.isEmpty(filterBy.siteType)) {
        filteredCollectionData = _.filter(filteredCollectionData, (collection) => {
          return _.includes(collection['siteTypeName'].toLowerCase(), filterBy.siteType.toLowerCase());
        });
      }
      if (!_.isEmpty(filterBy.pageType)) {
        filteredCollectionData = _.filter(filteredCollectionData, (collection) => {
          return _.includes(collection['pageTypeName'].toLowerCase(), filterBy.pageType.toLowerCase());
        });
      }
      
      if (!_.isEmpty(filterBy.parent)) {
        filteredCollectionData = _.filter(filteredCollectionData, (collection) => {
          return _.includes(collection['parentName'].toLowerCase(), filterBy.parent.toLowerCase());
        });
      }
      this.pageCollectionList = filteredCollectionData;
    }
  }

}
