import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phone",
})
export class PhonePipe implements PipeTransform {
  /**
   * Converts a number string into the proper phone format
   *
   * @param {String} phoneNumber The phone number to process
   * @param {String} emptyText The text that appears when the number is empty
   *
   * @return {String} The processed and reformatted phone number or emptyText.
   */
  transform(phoneNumber: string, emptyText = ""): string {
    if (!phoneNumber) {
      return emptyText;
    }

    const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-digit characters

    if (cleaned.length === 10) {
      // Format as (XXX) XXX-XXXX
      return `(${cleaned.substr(0, 3)}) ${cleaned.substr(
        3,
        3
      )}-${cleaned.substr(6)}`;
    }

    return cleaned; // Return unformatted if not 10 digits
  }
}
