// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .ck-editor__editable {
    min-height: 300px; /* Set your desired minimum height here */
}`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/basic/content/content-editor/content-editor.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB,EAAE,yCAAyC;AAChE","sourcesContent":["::ng-deep .ck-editor__editable {\n    min-height: 300px; /* Set your desired minimum height here */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
