// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wizard-content {
  max-width: unset;
}

.wizard-content .content {
  padding: 2rem 1rem 5rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/site/site-config/site-config.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".wizard-content {\n  max-width: unset;\n}\n\n.wizard-content .content {\n  padding: 2rem 1rem 5rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
