import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManageCustomerService {

  constructor(
    private httpService: HttpService
  ) { }

  getCustomerList(userId): Observable<any> {
    return this.httpService.getApi(`user/UserByCP/${userId}`)
      .pipe(
        tap(res => {
          return res;
        })
      );
  }

  getUserProfile(userId) {
    return this.httpService.getApi(`user/UserProfile/${userId}`).pipe(
      tap(res => {
        if (res) {
          return res;
        }
      })
    )
  }

}
