import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(
    private httpService: HttpService
  ) { }

  getConfig(): Observable<any[]> {
    return this.httpService.getApi('ADConfiguration')
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }
  updateConfig(configData): Observable<any[]> {
    return this.httpService.putApi(`ADConfiguration`, configData, '')
    .pipe(
      tap(res => {
        if(res){

          return res;
        }
    })
  );
  }
  getLiveChatLicense(): Observable<any[]> {
    return this.httpService.getApi('ADConfiguration/LiveChatLicense')
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }
}
