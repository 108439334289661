import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
  @Output() back = new EventEmitter<any>();
  @Output() goToPayment = new EventEmitter<any>();
  @Output() goToIntake = new EventEmitter<any>();
  @Output() open = new EventEmitter<any>();
  @Input() parent;
  @Input() intake;
  intakeId;
  isAgreed;
  sowDataByType;
  currentRole
  activeSection:any="a-section1";
  currentSection: string = 'main';
  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
    })
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        // this.getIntakeProductList(params.id);
      }
    });
  }

  ngOnInit() {
  }
  
  showSection(event: Event, section: string): void {
    event.preventDefault();
    this.currentSection = section;
  }
  
  openExhibit(exhibitType) {
    this.open.next(exhibitType);
  }
  
  goBack() {
    this.back.next();
  }
}
