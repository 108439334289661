// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr {
  margin: 0;
}
p {
  cursor: pointer;
  margin-bottom: unset;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
p:hover {
  background-color: rgb(215, 215, 215);
}

.disabled {
  cursor: not-allowed;
  color: grey;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/buttons/button-dropdown-item/button-dropdown-item.component.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;AACA;EACE,eAAe;EACf,oBAAoB;EACpB,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,oCAAoC;AACtC;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb","sourcesContent":["hr {\n  margin: 0;\n}\np {\n  cursor: pointer;\n  margin-bottom: unset;\n  padding-bottom: 0.5rem;\n  padding-top: 0.5rem;\n}\np:hover {\n  background-color: rgb(215, 215, 215);\n}\n\n.disabled {\n  cursor: not-allowed;\n  color: grey;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
