import { Injectable } from '@angular/core';
import { HttpService } from '@services/http.service';
import { Observable } from 'rxjs';
import { Location } from '@shared/interfaces/billing';
import { AddressData, SiteLocation, SiteLocationAddress } from '@shared/interfaces/addresses';
import { HttpParams } from '@angular/common/http';
import { DataResponse, GenericResponse } from '@shared/interfaces/responses';

@Injectable({
  providedIn: 'root',
})
export class ClinicLocationsService {
  constructor(private httpService: HttpService) { }

  /**
   * Creates a new address record and will create the appropriate site link if provided a
   * `siteId` and marked `isPrimary`
   *
   * @param {AddressData} addressData
   *
   * @return {Observable}
   */
  createAddress(addressData: AddressData): Observable<DataResponse<any>> {
    return this.httpService.postApi(`site/address`, addressData);
  }

  /**
   * Creates a SiteLocationAddress record, which links a Site and an Address
   *
   * @param {SiteLocationAddress} siteLocationAddressData
   *
   * @return {Observable}
   */
  createSiteLocationAddress(siteLocationAddressData: SiteLocationAddress): Observable<number> {
    return this.httpService.postApi(`site/siteLocationAddress`, siteLocationAddressData);
  }

  /**
   * @param {String} addressId
   *
   * @return {Observable}
   */
  deleteAddress(addressId: number|string): Observable<any> {
    return this.httpService.putApi(`site/address/delete/${addressId}`, '');
  }

  /**
   * Delete the specified SiteLocationAddress record
   *
   * @param {number} siteLocationAddressId
   *
   * @return {Observable}
   */
  deleteSiteLocationAddress(siteLocationAddressId: number): Observable<GenericResponse> {
    return this.httpService.putApi(`site/siteLocationAddress/delete/${siteLocationAddressId}`, '');
  }

  /**
   * Returns the information on the specified address
   *
   * @param {number} addressId
   *
   * @return {Observable}
   */
  getAddressById(addressId: number): Observable<Location[]> {
    return this.httpService.getApi(`site/address/${addressId}`);
  }

  /**
   * Returns a list of all clinic locations
   *
   * @return {Observable}
   */
  getAllAddresses(): Observable<Location[]> {
    return this.getAddressById(0);
  }

  /**
   * Returns a list of clinic locations tied to a client site
   *
   * @param {string} siteId
   * @param {boolean} includeInactive
   *
   * @return {Observable} An Observable for the clients locations
   */
  getAddressesBySite(siteId: string|number, includeInactive = false): Observable<AddressData[]> {
    const httpParams = new HttpParams({
      fromObject: {
        includeInactive: includeInactive ? 'true' : 'false',
      },
    });

    return this.httpService.getApi(`site/addressBySite/${siteId}?${httpParams.toString()}`);
  }

  /**
   * Returns all of the records for the locations
   *
   * @param {number} siteLocationAddressId The ID of the siteLocationAddress record
   *
   * @return {Observable}
   */
  getSiteLocationAddress(siteLocationAddressId: number = 0): Observable<SiteLocation[]> {
    return this.httpService.getApi(`site/siteLocationAddress/${siteLocationAddressId}`);
  }

  /**
   * Updates an address record if the record has an addressId
   *
   * @param {AddressData} addressData
   *
   * @return {Observable}
   */
  updateAddress(addressData: AddressData): Observable<DataResponse<any>> {
    return this.httpService.putApi(`site/address`, addressData);
  }

  /**
   * Updates the `isPrimary` status of the specified SiteLocationAddress
   *
   * @param {Object} data
   *
   * @return {Observable}
   */
  updateSiteLocationAddress(data: {id: number, isPrimary: boolean}): Observable<GenericResponse> {
    return this.httpService.putApi(`site/siteLocationAddress`, data);
  }
}
