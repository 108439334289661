// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all {
  input[type="checkbox"] {
    margin-right: 1rem;
    display: none;
  }

  input[type="checkbox"] + label span.checkbox {
    background: url('checkbox-unchecked.svg') no-repeat center;
    background-size: 100% 100%;
    display: inline-flex;
    height: 2rem;
    width: 2rem;
  }

  input[type="checkbox"]:checked + label span.checkbox {
    background: url('checkbox-checked.svg') no-repeat center;
    background-size: 100% 100%;
  }

  label {
    align-items: center;
    cursor: pointer;
    line-height: 100%;
    font-size: 1.1rem;
    font-weight: bold;
    width: 100%;
  }

  .disabled {
    filter: grayscale(1);
  }

  .invalid {
    filter: saturate(1352%) hue-rotate(139deg);
  }

  .required {
    color: rgb(223, 79, 60);
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/forms/checkbox-squared/checkbox-squared.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,0DAA8E;IAC9E,0BAA0B;IAC1B,oBAAoB;IACpB,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,wDAA4E;IAC5E,0BAA0B;EAC5B;;EAEA;IACE,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,0CAA0C;EAC5C;;EAEA;IACE,uBAAuB;EACzB;AACF","sourcesContent":["@media all {\n  input[type=\"checkbox\"] {\n    margin-right: 1rem;\n    display: none;\n  }\n\n  input[type=\"checkbox\"] + label span.checkbox {\n    background: url('assets/images/icons/checkbox-unchecked.svg') no-repeat center;\n    background-size: 100% 100%;\n    display: inline-flex;\n    height: 2rem;\n    width: 2rem;\n  }\n\n  input[type=\"checkbox\"]:checked + label span.checkbox {\n    background: url('assets/images/icons/checkbox-checked.svg') no-repeat center;\n    background-size: 100% 100%;\n  }\n\n  label {\n    align-items: center;\n    cursor: pointer;\n    line-height: 100%;\n    font-size: 1.1rem;\n    font-weight: bold;\n    width: 100%;\n  }\n\n  .disabled {\n    filter: grayscale(1);\n  }\n\n  .invalid {\n    filter: saturate(1352%) hue-rotate(139deg);\n  }\n\n  .required {\n    color: rgb(223, 79, 60);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
