// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-accordion .custom-dropdown-icon {
  margin-left: 10px; /* Space between the title and the arrow */
  width: 20px;
  height: 19px;
  background: url('/assets/images/panel-arrow.svg') no-repeat center center;
  transition: transform 0.3s ease;
  display: inline-block;
}

.custom-accordion .custom-dropdown-icon.expanded {
  transform: rotate(90deg); /* Rotate arrow when expanded */
}

.custom-accordion .mat-expansion-panel-header {
  background: #F5F6FA;
  border-bottom: 1px solid #D3D3D3;
  padding: 10px 18px;
  position: relative; /* Ensure the custom arrow can be positioned correctly */
}

.custom-accordion .panel-title {
  font-size: 16px;
  font-weight: bold;
  color: #4D4F5C; /* Dark gray text color */
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
}

.custom-accordion .panel-title .accordion-toggle {
  text-decoration: none;
  color: inherit; /* Inherits the color from .panel-title */
  flex-grow: 1;
}

.custom-accordion .listItem {
  padding: 8px 0 8px 50px;
  border-bottom: 1px solid #D3D3D3;
  margin: 0;
}

.custom-accordion .mat-expansion-panel-content {
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/basic/pages/custom-pages/custom-pages.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAE,0CAA0C;EAC7D,WAAW;EACX,YAAY;EACZ,yEAAyE;EACzE,+BAA+B;EAC/B,qBAAqB;AACvB;;AAEA;EACE,wBAAwB,EAAE,+BAA+B;AAC3D;;AAEA;EACE,mBAAmB;EACnB,gCAAgC;EAChC,kBAAkB;EAClB,kBAAkB,EAAE,wDAAwD;AAC9E;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc,EAAE,yBAAyB;EACzC,qBAAqB;EACrB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,cAAc,EAAE,yCAAyC;EACzD,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,gCAAgC;EAChC,SAAS;AACX;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".custom-accordion .custom-dropdown-icon {\n  margin-left: 10px; /* Space between the title and the arrow */\n  width: 20px;\n  height: 19px;\n  background: url('/assets/images/panel-arrow.svg') no-repeat center center;\n  transition: transform 0.3s ease;\n  display: inline-block;\n}\n\n.custom-accordion .custom-dropdown-icon.expanded {\n  transform: rotate(90deg); /* Rotate arrow when expanded */\n}\n\n.custom-accordion .mat-expansion-panel-header {\n  background: #F5F6FA;\n  border-bottom: 1px solid #D3D3D3;\n  padding: 10px 18px;\n  position: relative; /* Ensure the custom arrow can be positioned correctly */\n}\n\n.custom-accordion .panel-title {\n  font-size: 16px;\n  font-weight: bold;\n  color: #4D4F5C; /* Dark gray text color */\n  text-decoration: none;\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n\n.custom-accordion .panel-title .accordion-toggle {\n  text-decoration: none;\n  color: inherit; /* Inherits the color from .panel-title */\n  flex-grow: 1;\n}\n\n.custom-accordion .listItem {\n  padding: 8px 0 8px 50px;\n  border-bottom: 1px solid #D3D3D3;\n  margin: 0;\n}\n\n.custom-accordion .mat-expansion-panel-content {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
