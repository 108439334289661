import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from '../../admin/masters/services/master.service';
import { HttpService } from '@services/http.service';
import moment from 'moment';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { ReportingService } from '../services/reporting.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add-insight-data',
  templateUrl: './add-insight-data.component.html',
  styleUrls: ['./add-insight-data.component.css']
})
export class AddInsightDataComponent implements OnInit {
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
      'link', 'unlink', '|',
      'bulletedList', 'numberedList', '|',
      'alignment', '|',
      'blockQuote', 'codeBlock', '|',
      'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', '|',
      'imageUpload', 'mediaEmbed', '|',
      'specialCharacters', '|',
      'horizontalLine', 'pageBreak', '|',
      'undo', 'redo', '|',
      'removeFormat', 'findAndReplace', 'sourceEditing'
    ],
    image: {
      toolbar: [
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        '|',
        'toggleImageCaption',
        'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    }
  };
  reportParams = {};
  selectedInsightType;
  masterData;
  addInsightForm: UntypedFormGroup;
  siteId;
  params;
  insightData = {
    masterId: '',
    siteId: '',
    content: '',
    startDate: '',
    endDate: '',
    quarter:''
  }
  existingData;
  selectedMaster;
  quarterData
  insightType = [
    { id: 42, name: 'Website Insights' },
    { id: 43, name: 'PPC Insights' },
    { id: 44, name: 'Facebook Insights' }
  ]
  constructor(
    public activeModal: NgbActiveModal,
    public masterService: MasterService,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    public reportingService: ReportingService,
    public httpService: HttpService,
  ) { }

  ngOnInit() {


    if (moment(this.existingData['quarter'], "MM/DD/YY", true).isValid()) {
      this.existingData['startDate'] = moment(this.existingData['quarter']).startOf('quarter').format('MM/DD/YYYY');
      this.existingData['endDate'] = moment(this.existingData['quarter']).endOf('quarter').format('MM/DD/YYYY');
      this.quarterData = this.reportingService.getQuarters();
      this.reportParams = {
        quarter: this.existingData['quarter']
      };
    }
    else{
      this.insightData['_startDate'] = this.params.formattedStartDate || this.params.startDate;
      this.insightData['_endDate'] = this.params.formattedEndDate || this.params.endDate;
    }
    if(this.existingData && this.existingData.masterTypeId){
      this.getMasterData(this.existingData.masterTypeId);
    }
    this.initializeForm();
    this.selectedInsightType = _.find(this.insightType, (type) => {
      return type.id == this.existingData.masterTypeId;
    });


  }

  initializeForm() {
    this.addInsightForm = this.formBuilder.group({
      masterId: [this.existingData ? this.existingData.masterId : '', [Validators.required]],
      masterTypeId: [this.existingData ? this.existingData.masterTypeId : '', [Validators.required]],
      siteId: [this.existingData ? this.existingData.siteId : ''],
      content: [this.existingData ? this.existingData.content : ''],
      startDate: [this.existingData ? this.existingData.startDate : (this.params.formattedStartDate || ''), [Validators.required]],
      endDate: [this.existingData ? this.existingData.endDate : (this.params.formattedEndDate || ''), [Validators.required]],
      id: [(this.existingData ? this.existingData.id : null)],
      quarter:[this.reportParams['quarter']]
    });
  }

  dismissModal() {
    this.activeModal.close();
  }

  getMasterData(insightType) {
    this.masterService.getMastersListById(insightType).subscribe((res: any) => {
      if (res) {
        this.masterData = res['masterList'];
        this.selectedMaster = _.find(this.masterData, (master) => {
          return master.id == this.existingData.masterId;
        });
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  onValueChange(event) {
    this.addInsightForm.controls.startDate.setValue(moment(event[0]).format('MM/DD/YYYY'));
    this.addInsightForm.controls.endDate.setValue(moment(event[1]).format('MM/DD/YYYY'));
    this.insightData['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.insightData['_endDate'] = moment(event[1]).format('MM/DD/YY');
  }

  saveInsightData(formData) {
    let insightData = formData;
    insightData['siteId'] = this.siteId;
    if (moment(formData['quarter'], "MM/DD/YY", true).isValid()) {
      insightData['startDate'] = moment(formData['quarter']).startOf('quarter').format('MM/DD/YYYY');
      insightData['endDate'] = moment(formData['quarter']).endOf('quarter').format('MM/DD/YYYY');
    }
    if(!insightData.id){ delete insightData['id'] };
    this.reportingService.saveInsightData(insightData).subscribe((res: any) => {
      if (res) {
        this.dismissModal();
        this.toastrService.success('Insight Added', 'Success');
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

}
