import { ElementRef, Renderer2 } from '@angular/core';
import { UserService } from '../../components/admin/users/services/user-service.service';
import { UserProfile } from '@shared/interfaces/user';
import { SiteInfo } from '@shared/interfaces/site';
import { environment } from '../../../environments/environment';

export class WordPressHelper {
  private el: ElementRef;
  private renderer: Renderer2;
  private userService: UserService;

  constructor(
    el: ElementRef,
    renderer: Renderer2,
    userService: UserService,
  ) {
    this.el = el;
    this.renderer = renderer;
    this.userService = userService;
  }

  logInToWordPress(currentUser: UserProfile|any, site: SiteInfo): void {
    if (!this.userService.UserProfile) {
      this.userService.setUserProfile(currentUser);
    }

    const wpSSOUrl = environment.wpSSO.replace('sitePath', site.wpSitePath);
    const wpForm = this.renderer.createElement('form');

    this.renderer.setAttribute(wpForm, 'id', 'wpForm');
    this.renderer.setAttribute(wpForm, 'action', wpSSOUrl);
    this.renderer.setAttribute(wpForm, 'target', '_blank');
    this.renderer.setAttribute(wpForm, 'method', 'post');

    const inputEmail = this.renderer.createElement('input');

    this.renderer.setAttribute(inputEmail, 'type', 'text');
    this.renderer.setAttribute(inputEmail, 'name', 'email');
    this.renderer.setAttribute(inputEmail, 'value', this.userService.UserProfile.email);

    const inputToken = this.renderer.createElement('input');

    this.renderer.setAttribute(inputToken, 'type', 'text');
    this.renderer.setAttribute(inputToken, 'name', 'token');

    this.renderer.setAttribute(inputToken, 'value', currentUser.token);
    this.renderer.appendChild(wpForm, inputEmail);
    this.renderer.appendChild(wpForm, inputToken);
    this.renderer.setStyle(wpForm, 'visibility', 'hidden');
    this.renderer.appendChild(this.el.nativeElement, wpForm);

    wpForm.submit();

    document.getElementById('wpForm').remove();
  }
}
