import { Component, Input, OnInit } from '@angular/core';
import { IntakeStatus } from '@modules/intakes/intake-component.interface';

@Component({
  selector: 'app-intake-sidebar-submenu-item',
  templateUrl: './intake-sidebar-submenu-item.component.html',
  styleUrls: ['./intake-sidebar-submenu-item.component.css'],
})
export class IntakeSidebarSubmenuItemComponent implements OnInit {
  @Input() label: string;
  @Input() id: number;
  @Input() status: IntakeStatus;
  @Input() selected = false;

  constructor() { }

  ngOnInit(): void { }
}
