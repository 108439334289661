import {
  Component,
  EventEmitter,
  HostBinding,
  Input, OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit, OnChanges {
  @Input() menu: Menu;
  @Input() selectedItem;
  @Input() barWidth: number;
  @Input() roundedLeftEdge = false;
  @Input() displayCircle;
  @Input() displayDot;
  @Input() isFirst = false;
  @Input() isLast = false;

  @Output() clickLabel = new EventEmitter<any>();

  @HostBinding('style.width') width = '0%';

  outerClasses = {};

  outerStyle = {};

  progressBarClasses = {};

  progressBarStyles = {
    width: '0',
    marginLeft: '0'
  };

  labelClasses = {};

  constructor() { }

  ngOnInit(): void {
    this.progressBarStyles = {
      ...this.progressBarStyles,
      width: this.menu.percentage + '%',
    };

    this.width = this.barWidth + '%';

    this.progressBarClasses = {
      'status-in-progress': this.menu.id === this.selectedItem.id,
      'status-incomplete': this.menu.id !== this.selectedItem.id,
      'status-complete': this.menu.percentage === 100,
      'rounded-left-edge': this.roundedLeftEdge || this.isFirst,
      'rounded-right-edge': this.isLast,
    };

    if (!this.isFirst) {
      this.progressBarStyles = {
        ...this.progressBarStyles,
        marginLeft: '-5px'
      };
    }
    this.outerClasses = {
      'rounded-left-edge': this.roundedLeftEdge || this.isFirst,
      'rounded-right-edge': this.isLast,
    };

    this.labelClasses = {
      'active-label': this.menu.id === this.selectedItem.id,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.progressBarClasses = {
      'status-in-progress': this.menu.id === this.selectedItem.id,
      'status-incomplete': this.menu.id !== this.selectedItem.id,
      'status-complete': this.menu.percentage === 100,
      'rounded-left-edge': this.roundedLeftEdge || this.isFirst,
      'rounded-right-edge': this.isLast,
    };

    this.labelClasses = {
      'active-label': this.menu.id === this.selectedItem.id,
    };

    this.outerClasses = {
      'rounded-left-edge': this.roundedLeftEdge || this.isFirst,
      'rounded-right-edge': this.isLast,
    };
  }

  onLabelClick() {
    this.clickLabel.emit();
  }
}

export interface Menu {
  familyId: number;
  id: number;
  isOverview: boolean;
  name: string;
  parent: number;
  percentage: number;
  status: any;
  submenu: SubMenu[];
}

export interface SubMenu {
  familyId: number;
  id: number;
  isOverview: boolean;
  name: string;
  parent: number;
  percentage: number;
  status: any;
  requiredFields: MenuRequiredField[];
}

export interface MenuRequiredField {
  key: string;
  errorMsg: string;
  sectionId: number;
  status: string;
  percentage: number;
}

export interface SelectedItem {
  id: number;
  familyId: number;
  isOverview: boolean;
  name: string;
  parent: number;
  percentage: number;
  status: any;
  submenu: SubMenu[];
}
