import { Component, OnInit } from '@angular/core';
import { SiteService } from '../../site/service/site.service';

@Component({
  selector: 'app-sales-performance',
  templateUrl: './sales-performance.component.html',
  styleUrls: ['./sales-performance.component.css']
})
export class SalesPerformanceComponent implements OnInit {

  filterBy={
    month:null
  };
  salesReport=[];
  sales: any[];
  constructor(private siteService: SiteService) { }

  ngOnInit() {
    this.sales = [
      {brand: 'STARTUP FEES',product:'BASIC', buddollar: '6000', budunit: '3',actdollar: '0', actunit: '0',vardollar: '-6000', varunit: '3',cp:'Audiology Design'},
      {brand: 'STARTUP FEES',product:'ENHANCED', buddollar: '0', budunit: '0',actdollar: '0', actunit: '0',vardollar: '0', varunit: '0',cp:'Audiology Design'},
      {brand: 'STARTUP FEES',product:'PREMIUM', buddollar: '3000', budunit: '1',actdollar: '0', actunit: '0',vardollar: '3000', varunit: '-1',cp:'Audiology Design'},
      {brand: 'UPSELL OPPS',product:'Basic > Enhanced', buddollar: '0', budunit: '0',actdollar: '0', actunit: '0',vardollar: '3000', varunit: '0',cp:'Audiology Design'},
      {brand: 'UPSELL OPPS',product:'Monthly Increase', buddollar: '0', budunit: '0',actdollar: '0', actunit: '0',vardollar: '3000', varunit: '0',cp:'Audiology Design'},
      {brand: 'PPC CAMPAIGNS',product:'', buddollar: '0', budunit: '0',actdollar: '0', actunit: '0',vardollar: '3000', varunit: '0',cp:'Audiology Design'},
     
  ];
  }

}
