import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox-chip',
  templateUrl: './checkbox-chip.component.html',
  styleUrls: ['./checkbox-chip.component.css'],
})
export class CheckboxChipComponent implements OnInit, OnChanges {
  @Input() checked = false;
  @Input() controlName = '';
  @Input() control = new UntypedFormControl();
  @Input() disabled = false;
  @Input() formGroup: AbstractControl|UntypedFormGroup;
  @Input() label = 'Text';
  @Input() labelIsBold = true;
  @Input() name = '';
  @Input() required = false;
  @Input() selected = false;
  @Input() value: string;

  public id = '';

  controlValues = [];
  inputFormGroup: AbstractControl|UntypedFormGroup;

  constructor(private controlContainer: ControlContainer) { }

  ngOnInit(): void {
    if (!this.name) {
      this.name = this.controlName;
      this.id = `checkbox_${this.controlName}`;
    }

    this.formGroup = this.controlContainer.control;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Array.isArray(this.control.value)) {
      this.selected = this.control.value.includes(this.value);
    }
  }

  /**
   * Adds or removes the value of the chip to the Form Control
   *
   * @param {PointerEvent|MouseEvent} event The event that triggers the onClick action
   */
  onClick(event: PointerEvent|MouseEvent): void {
    if (event) {
      event.preventDefault();
    }

    // Toggles the button appearance
    this.selected = !this.selected;

    const controlValue = this.control.value ?? [];
    this.formGroup = this.controlContainer.control;

    let value = [];

    if (this.selected) {
      if (!this.controlContainer.control.get(this.controlName).value) {
        this.controlContainer.control.get(this.controlName).setValue('');
      }

      value = this.addToArray(
        this.controlContainer.control.get(this.controlName).value,
        this.value
      );
    } else {
      value = controlValue.filter((item: string) => item != this.value);
    }

    this.controlContainer.control.get(this.controlName).patchValue(value);
  }

  /**
   * Adds value to the Form Control
   * @param {Array<string>} array The data that already exists in the form control
   * @param {string} value The value being added or removed from the form control
   *
   * @return {Array<string>} The new data for the form control
   */
  addToArray(array: Array<string> = [], value: string): Array<string> {
    let newArray = array;

    if (!array.includes(value)) {
      newArray = [...array, value];
    }

    return newArray.filter(Boolean);
  }
}
