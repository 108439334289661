import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-frame',
  templateUrl: './modal-frame.component.html',
  styleUrls: ['./modal-frame.component.css'],
})
export class ModalFrameComponent implements OnInit {
  @Input() title = '';
  @Input() showX = false;
  @Input() bannerMessage = '';
  @Input() cancelButtonLabel = 'Cancel';
  @Input() closeMessage = 'The user has closed the modal';
  @Input() confirmButtonLabel = 'Confirm';
  @Input() dismissMessage = 'The modal has been dismissed';
  @Input() errorMessage = '';
  @Input() enableConfirmButton = true;
  @Input() modalConfirmable: boolean;

  @Output() confirm = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {}

  closeModal(): void {
    this.activeModal.close(this.closeMessage);
  }

  dismissModal(): void {
    this.activeModal.dismiss(this.dismissMessage);
  }

  confirmModal() {
    if (this.confirm) {
      this.confirm.emit();
    }
  }
}
