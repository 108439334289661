import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketlistComponent } from './ticketlist/ticketlist.component';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { RouterModule } from '@angular/router';
import {  BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ListContainerComponent } from './list-container/list-container.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { PaginatorModule } from 'primeng/paginator';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularEditorModule,
        RouterModule,
        SharedModule,
        NgbModule,
        BsDatepickerModule.forRoot(),
        SelectDropDownModule,
        PaginatorModule,
        CKEditorModule,

    ],
    declarations: [
        TicketDetailComponent,
        TicketlistComponent,
        ListContainerComponent,
    ],
    exports: [
        TicketDetailComponent,
        TicketlistComponent,
    ]
})
export class TicketModule { }
