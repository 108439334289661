import { FileInterface } from "@shared/interfaces/file";

export class UploadedFile implements FileInterface {
  constructor(data: FileInterface){
    this.name = data.path.substring(data.path.lastIndexOf('/') + 1);
    this.path = data.path;
    this.dataInBytes = data.dataInBytes;
  }
  name: string;
  path: string;
  dataInBytes: string;
}
