import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import { DataService } from '../../../../../../../shared/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../../../../services/http.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-custom-content',
  templateUrl: './custom-content.component.html',
  styleUrls: ['./custom-content.component.css']
})
export class CustomContentComponent implements OnInit {
  activeIds: string[] = [];
  @Input() selectedItem;
  @Input() siteId;
  @Input() intakeTypeId;
  @Input() contentMenu;
  searchKeyword: string = '';
  selectedContentMenu;
  message: boolean = false;
  @Output() gotoPageSection = new EventEmitter<object>();
  @Output() messageEvent = new EventEmitter<boolean>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() backClicked = new EventEmitter<any>();
  @Output() changeStatus = new EventEmitter<any>();
  intakeId: number;
  savedPages = [];
  allSavedPagesData = [];
  filteredData = [];
  checkSavedPages: boolean = false;
  allChecked: boolean = false; // For the top header checkbox
  panelOpenState: boolean = false;

  constructor(
    private intakesService: IntakesService,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private cdr: ChangeDetectorRef
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
      }
    });
  }

  ngOnInit() {
    this.selectedContentMenu = _.find(this.contentMenu, (menu) => {
      return menu.name === this.selectedItem.name;
    });
    this.getIntakeSavedPages(this.intakeId, this.selectedItem.id);
    this.expandAll();
  }

  gotoPages() {
    this.gotoPageSection.emit({
      "menuId": 2,
      "sectionId": 27,
      "sectionName": this.selectedContentMenu.name
    });
  }

  editPage(pages) {
    this.dataService.setData(pages);
    this.messageEvent.emit(this.message);
  }

  deletePage(pages) {
    let msg = 'Are you sure to delete this page?';
    if (confirm(msg)) {
      this.deleteInkateCustomPages(pages.intakeId, pages.itemId, pages.productSectionId);
    }
  }

  getIntakeSavedPages(intakeId, pageSectionId) {
    this.intakesService.getIntakeSavedPages(intakeId, pageSectionId).subscribe((res: any) => {
      this.savedPages = [];
      if (res) {
        _.forEach(res, (pages) => {
          if (pages.type === 'P') {
            this.savedPages.push(pages);
          }
        });
        this.allSavedPagesData = Object.assign([], this.savedPages);
        this.checkSavedPages = res.length === 0;
        this.updateHeaderCheckbox(); // Update header checkbox based on saved pages
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  deleteInkateCustomPages(intakeId, itemId, productSectionId) {
    this.intakesService.deleteCustomPages(intakeId, itemId, productSectionId).subscribe((res: any) => {
      if (res) {
        this.toastrService.success('Deleted Successfully', 'Success');
        this.getIntakeSavedPages(this.intakeId, this.selectedItem.id);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  expandAll() {
    this.panelOpenState = true;
    this.activeIds = this.savedPages.map(page => `panel-${page.id}`);
    this.cdr.detectChanges();
  }

  collapseAll() {
    this.panelOpenState = false;
    this.activeIds = [];
    this.cdr.detectChanges();
  }

  goBack() {
    this.backClicked.next();
  }

  goToNext() {
    this.updateSectionStatus();
    this.continueClicked.next();
  }

  applyFilter(name: string) {
    this.savedPages = _.cloneDeep(this.allSavedPagesData);
    this.filteredData = this.savedPages;
    if (!_.isEmpty(name)) {
      this.filteredData = _.filter(this.filteredData, (data) => {
        return (_.includes(data['pageName'].toLowerCase(), name.toLowerCase())) && data.type === 'P';
      });
      this.savedPages = this.filteredData;
    }
  }

  // Toggles individual page checkboxes
  togglePageChecked(page) {
    page.isComplete = !page.isComplete;
    this.updateHeaderCheckbox(); // Update header checkbox whenever a page is toggled
    this.updateSectionStatus(); // Recalculate percentage and update status when individual pages are checked
  }

  // Toggles all checkboxes based on the top header checkbox
  toggleAllPages(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.savedPages.forEach(page => page.isComplete = checked);
    this.allChecked = checked;
    this.updateSectionStatus(); // Update status and percentage after toggling all
  }

  // Update header checkbox based on the state of individual checkboxes
  updateHeaderCheckbox() {
    const totalPages = this.savedPages.length;
    const totalChecked = this.savedPages.filter(page => page.isComplete).length;

    this.allChecked = totalChecked === totalPages;
    this.updateSectionStatus(); // Ensure section status is updated when header checkbox changes
  }

  // Hardcoded section status and percentage
  updateSectionStatus() {
    let sectionStatusData = {
      intakeId: this.intakeId,
      lstIntakeSection: [{
        productSectionId: this.selectedContentMenu.id,
        status: 2,  // Hardcoded status as 2 (Completed) (this was done in the old code.)
        percentage: this.selectedContentMenu.percentage
      }],
      IsContentSection: true
    };

    this.postIntakeSection(sectionStatusData);
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({
          basicMenu: this.intakesService.basicMenu,
          overAllPercentage: res.OverAllPercentage,
          isSection: true
        });
      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    });
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
}

