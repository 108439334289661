// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.form .form-field p {
    margin-bottom: 12px;
    font-size: 17px;
    color: #43425D;
}
*/

.aud-tabs .panel .btn{background:#f8f8f8; color:#9f9eac; border-radius:6px 6px 0 0; font-size:14px; margin-right:2px; padding:15px 26px; border:#e9e9f0 solid 1px; border-bottom:none;}
.aud-tabs .panel .btn.active{background:#3b86ff; color:#fff;}
.aud-tabs .panel.border-panel{border-bottom:#e9e9f0 solid 1px;}

.summary-list, .yourwebsite-tab { color:#43425D;}
.summary-list h5, .yourwebsite-tab  h5{ font-size:17px; border-bottom:1px solid #D3D3D3; padding-bottom:10px;} 
.yourwebsite-tab .col-sm-12{line-height:26px;}
.summary-list label{font-weight:700; margin-bottom:0;}
.summary-list label+div{font-style:italic; padding-left:4px;}
.summary-list h4{border-bottom:#4d4f5c solid 4px; font-weight:700; font-size:20px;}
.summary-list ul{margin-bottom:0;}
.summary-list ul li{font-style:italic;}
.btn-secondary:not(:disabled):not(.disabled):active{box-shadow:none; background:#fff; color:#ADADAD; border:1px solid #D3D3D3;}
div.summary-list{
    font-style: italic;
    padding-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/logo/review-logo/review-logo.component.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED,sBAAsB,kBAAkB,EAAE,aAAa,EAAE,yBAAyB,EAAE,cAAc,EAAE,gBAAgB,EAAE,iBAAiB,EAAE,wBAAwB,EAAE,kBAAkB,CAAC;AACtL,6BAA6B,kBAAkB,EAAE,UAAU,CAAC;AAC5D,8BAA8B,+BAA+B,CAAC;;AAE9D,kCAAkC,aAAa,CAAC;AAChD,wCAAwC,cAAc,EAAE,+BAA+B,EAAE,mBAAmB,CAAC;AAC7G,4BAA4B,gBAAgB,CAAC;AAC7C,oBAAoB,eAAe,EAAE,eAAe,CAAC;AACrD,wBAAwB,iBAAiB,EAAE,gBAAgB,CAAC;AAC5D,iBAAiB,+BAA+B,EAAE,eAAe,EAAE,cAAc,CAAC;AAClF,iBAAiB,eAAe,CAAC;AACjC,oBAAoB,iBAAiB,CAAC;AACtC,oDAAoD,eAAe,EAAE,eAAe,EAAE,aAAa,EAAE,wBAAwB,CAAC;AAC9H;IACI,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":["/*.form .form-field p {\n    margin-bottom: 12px;\n    font-size: 17px;\n    color: #43425D;\n}\n*/\n\n.aud-tabs .panel .btn{background:#f8f8f8; color:#9f9eac; border-radius:6px 6px 0 0; font-size:14px; margin-right:2px; padding:15px 26px; border:#e9e9f0 solid 1px; border-bottom:none;}\n.aud-tabs .panel .btn.active{background:#3b86ff; color:#fff;}\n.aud-tabs .panel.border-panel{border-bottom:#e9e9f0 solid 1px;}\n\n.summary-list, .yourwebsite-tab { color:#43425D;}\n.summary-list h5, .yourwebsite-tab  h5{ font-size:17px; border-bottom:1px solid #D3D3D3; padding-bottom:10px;} \n.yourwebsite-tab .col-sm-12{line-height:26px;}\n.summary-list label{font-weight:700; margin-bottom:0;}\n.summary-list label+div{font-style:italic; padding-left:4px;}\n.summary-list h4{border-bottom:#4d4f5c solid 4px; font-weight:700; font-size:20px;}\n.summary-list ul{margin-bottom:0;}\n.summary-list ul li{font-style:italic;}\n.btn-secondary:not(:disabled):not(.disabled):active{box-shadow:none; background:#fff; color:#ADADAD; border:1px solid #D3D3D3;}\ndiv.summary-list{\n    font-style: italic;\n    padding-left: 4px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
