import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpService } from '@services/http.service';
import { catchError, tap } from 'rxjs/operators';
import { User } from '../models/user';
import { ImpersonateUserConfig, UserProfile } from '@shared/interfaces/user';
import {
  ActivatedRoute,
  PRIMARY_OUTLET,
  Router, UrlSegment,
  UrlSegmentGroup,
  UrlTree,
} from '@angular/router';
import { SiteInfo } from '@shared/interfaces/site';

@Injectable({
  providedIn: 'root',
})
/**
 * Manages user related business logic and the current site selection
 * @property {BehaviorSubject<any>} selectedSite - Stores the current site
 * @property {BehaviorSubject<any>} selectedSiteData - Can stores the current site, deprecated
 */
export class UserService {
  currentUserRole: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private router: Router,
  ) { }
  UserProfile;
  selectedProductId;
  public selectedSite: BehaviorSubject<SiteInfo|any> = new BehaviorSubject(null);
  /** @deprecated If possible use `selectedSite` to store the current site selection **/
  public selectedSiteData: BehaviorSubject<any> = new BehaviorSubject(null);
  private userImagePath = new BehaviorSubject<any>(null);
  userImagePathData = this.userImagePath.asObservable();

  /**
   * Calculates the current user role and returns it as a string
   *
   * @return {String} The current user role
   */
  getCurrentUserRole(): string {
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;

    this.currentUserRole = s[0].path;

    return this.currentUserRole;
  }

  setUserProfile(userProfile: UserProfile) {
    this.UserProfile = userProfile;
  }

  getAllUsers(filterBy,siteId): Observable<User[]> {

    return this.httpService.getApi(`user?SiteId=${siteId ? siteId:''}&UserName=${filterBy && filterBy.username ? filterBy.username:''}&Name=${filterBy && filterBy.name ? filterBy.name:''}&Phone=${filterBy && filterBy.mobile ? filterBy.mobile:''}&RoleId=${filterBy && filterBy.role ? filterBy.role:''}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getSites(cpId?){
    return this.httpService.getApi(`site/getSites?CPId=${cpId ? cpId : 0}`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }

  getAllUsersByParent(parentId, siteId?): Observable<User[]> {
    return this.httpService.getApi(`User/GetUsersByParent?UserId=${parentId}&SiteId=${siteId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  checkUsername(userData): Observable<any> {

    return this.httpService.getApi('User/IsUserExists?userName=' + userData)
      .pipe(
        tap(res => {

          return res;

        })
      );
  }

  addNewUser(userData): Observable<any> {
    return this.httpService.postApi('user', userData, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  updateUser(userData): Observable<any> {
    return this.httpService.postApi(`user`, userData, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }
        })
      );
  }

  getUserInfo(id): Observable<User[]> {
    return this.httpService.getApi(`user/GetUser/${id}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        }),
        catchError(this.httpService.handleError<any>())
      );
  }

  uploadProfileImg(userFormData) {

    return this.httpService.postApi('fileUpload', userFormData, {
      reportProgress: true // for progress data
    })
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  delete(id) {
    return this.httpService.deleteApi(`user/${id}`).pipe(
      tap(res => {
        if (res) {

          return res;
        }
      })
    )
  }

  /**
   * The given user's profile information
   *
   * @param {String} userId
   *
   * @return {Observable<UserProfile>}
   */
  getUserProfile(userId): Observable<UserProfile> {
    return this.httpService.getApi(`user/UserProfile/${userId}`).pipe(
      tap(
        (res) => {
          if (res) {
            return res;
          }
        }
      )
    );
  }

  updateUserInfo(userInfo): Observable<any> {
    return this.httpService.putApi(`User/UserProfile`, userInfo, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  updatePassword(info): Observable<any> {
    return this.httpService.putApi(`user/ChangePassword`, info, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  updateSiteUser(userData): Observable<any> {
    return this.httpService.postApi('site/updateSiteUser', userData, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getAmInfo(siteId) {
    return this.httpService.getApi(`site/SiteAccountManager?SiteId=${siteId}`).pipe(
      tap(res => {
        if (res) {
          return res;
        }
      })
    )
  }

  /**
   * Changes a logged in users account to that of another user
   *
   * @param {Object} userData
   *
   * @return {Observable<ImpersonateUserConfig>}
   */
  impersonateUser(userData): Observable<ImpersonateUserConfig> {
    return this.httpService.postApi(
      `Login/Impersonate?ImpersonateUserId=${userData.userId}`,
      '',
      ''
    ).pipe(
      tap(
        (res) => {
          if (res) {
            return res;
          }
        }
      )
    );
  }

  updatedDataSelection(data){
    console.log('updatedDataSelection');
    this.userImagePath.next(data);
  }
}
