// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

label {
  font-weight: bold;
}

.required {
  color: rgb(224, 79, 60);
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/forms/input-office-hours-week/input-office-hours-week.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n}\n\nlabel {\n  font-weight: bold;\n}\n\n.required {\n  color: rgb(224, 79, 60);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
