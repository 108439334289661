import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'formatNumber' })
export class FormatNumberPipe implements PipeTransform {
  transform(value: any): any {
    let si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "K" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (value >= si[i].value) {
        break;
      }
    }
    return ((value / si[i].value).toFixed(2).replace(rx, "$1") + si[i].symbol).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

@Pipe({ name: 'wordCount' })
export class WordCountPipe implements PipeTransform {
  transform(value: any): any {
      return value != '' ? value.match(/\S+/g).length : 0;
  }
}

@Pipe({ name: 'nameAbbr' })
export class NameAbbrPipe implements PipeTransform {
  transform(value: any): any {
    let stringArr = value.split(' ');
    let abbr = '';
    _.forEach(stringArr, (val) => {
      abbr += val.charAt(0)
    })
      return abbr;
  }
}