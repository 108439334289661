import { Component, OnInit,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-content-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class ContentLandingPageComponent implements OnInit {

  constructor() { }

  @Output() continueClicked = new EventEmitter<any>();
  @Output() backClicked = new EventEmitter<any>();
  ngOnInit() {
  }
  goToNext() {
    this.continueClicked.next();
  }
  goBack(menu){
    this.backClicked.next(menu);
  }
}