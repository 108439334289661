import { Component, OnInit, Input } from '@angular/core';
import { ReportingService } from '../services/reporting.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import * as _ from 'lodash';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { HttpService } from '../../../services/http.service';
import { customLayout } from '../../../shared/charts/custom-tooltip';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-reviewgen-report',
  templateUrl: './reviewgen-report.component.html',
  styleUrls: ['./reviewgen-report.component.css']
})
export class ReviewgenReportComponent {
  @Input() siteId: string;
  accountData: any;
  reportData: any;
  today = new Date();
  reportParams = {
    startDate: moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY'),
    endDate: moment(moment()).subtract(1, 'months').endOf('month').format('MM/DD/YYYY'),
    _startDate: moment().subtract(1, 'months').startOf('month').format('MM/DD/YY'),
    _endDate: moment(moment()).subtract(1, 'months').endOf('month').format('MM/DD/YY'),
  };
  yearsData = [];
  overallRating = [];
  colorMapping = {
    'bbb': '#0D46AD',
    'citysearch': '#C3E8FF',
    'diamondcertified': '#011C57',
    'direct': '#82CFFF',
    'facebook': '#2C74D9',
    'google': '#E53D2F',
    'healthgrades': '#44A0FC',
    'healthyhearing': '#003481',
    'hearingtracker': '#FFC4C4',
    'judysbook': '#67000D',
    'yellowpages': '#F86C5E',
    'yelp': '#B21117',
    'yp': '#B21117',
    'zocdoc': '#FD958D',
    'default': '#1BB5A6'
  };

  public lineChartData: ChartData<'line'> = {
    labels: [],
    datasets: [
      {
        data: [],
        fill: true,
        label: 'Reviews',
        borderWidth: 1,
        backgroundColor: ['#46A99C'],
        borderColor: '#35847D',
        pointBorderColor: '#35847D',
        pointBackgroundColor: '#35847D',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#333',
      }
    ]
  };

  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        enabled: false,
        external: function (context) {
          customLayout.getLayout(context.tooltip, context.chart.canvas);
        },
        callbacks: {
          label: function (tooltipItem) {
            return Number(tooltipItem.raw) + " review(s) <div style='font-size:14px; font-weight:400;'>" + moment(tooltipItem.label).format('ddd, MMM DD') + '</div>';
          }
        },
      },
    },
    scales: {
      x: {
        type: 'category',
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        ticks: {},
        title: {
          display: true,
          text: 'Total Reviews',
        },
      }
    }
  };

  public lineChartType: ChartType = 'line';

  constructor(
    private reportingService: ReportingService,
    private httpService: HttpService,
    private toastrService: ToastrService
  ) { }

  ngOnChanges() {
    this.getProfileData(this.siteId);
  }

  getProfileData(siteId: string) {
    this.accountData = {};
    this.reportingService.getProfileData(siteId).subscribe((res: any) => {
      if (res) {
        this.accountData = res;
        this.reportParams['profileId'] = res.length ? res[0].gradusId : '';
        this.getReviewgenReport(this.reportParams);
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  getReviewgenReport(params: any) {
    this.resetJSLegend();
    // set the time of the end date to 11:59:59 PM
    params.endDate = moment(params.endDate).endOf('day').format('MM/DD/YYYY HH:mm:ss');
    this.reportingService.getReviewgenReport(params).subscribe((res) => {
      if (res) {
        this.reportData = res;

        // Normalize and prepare data for each chart/graph
        this.prepareDoughnutChartData('totalReviews');
        this.prepareDoughnutChartData('newReviews');
        this.prepareLineChartData();

        // Prepare data for "Total Reviews by Site" and "New Reviews by Site"
        this.prepareSiteData('totalReviews');
        this.prepareSiteData('newReviews');
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  normalizeCategory(category: string): string {
    return category.toLowerCase().replace(/\s+/g, '');
  }

  getColorForCategory(category: string): string {
    const normalizedCategory = this.normalizeCategory(category);
    return this.colorMapping[normalizedCategory] || this.colorMapping['default'];
  }

  prepareDoughnutChartData(reviewType: string) {
    const reviewData = this.reportData[reviewType];
    if (!reviewData || !reviewData.reviewsBySite) return;

    const colors = [];
    const data = [];
    const labels = [];

    _.forEach(reviewData.reviewsBySite, (site) => {
      const color = this.getColorForCategory(site.category);
      site['color'] = color;
      colors.push(color);
      const percentage = site.reviewsCount && reviewData.totalReviews 
        ? (site.reviewsCount / reviewData.totalReviews) * 100 
        : 0;
      if (site.reviewsCount && percentage > 0) {
        data.push(_.round(percentage, 2));
        labels.push(_.startCase(site.category));
      }
    });

    this.reportData[reviewType].chart = {
      labels: labels,
      datasets: [
        {
          data: data.length > 0 ? data : [0],
          backgroundColor: colors.length > 0 ? colors : [this.colorMapping['default']]
        }
      ]
    };

    setTimeout(() => this.updateLegend(reviewType), 0);
  }

  prepareSiteData(reviewType: string) {
    const reviewData = this.reportData[reviewType];
    if (!reviewData || !reviewData.reviewsBySite) return;

    _.forEach(reviewData.reviewsBySite, (site) => {
      site['color'] = this.getColorForCategory(site.category);
      site['percentage'] = site.reviewsCount && reviewData.totalReviews 
        ? (site.reviewsCount / reviewData.totalReviews) * 100 
        : 0;
    });
  }

  updateLegend(reviewType: string) {
    const legendId = `js-legend-${reviewType}`;
    const element = document.getElementById(legendId);
    if (element && this.reportData[reviewType].chart) {
      let legendHTML = '';

      this.reportData[reviewType].chart.datasets.forEach((dataset, i) => {
        dataset.data.forEach((_, index) => {
          const style = `background-color:${dataset.backgroundColor[index]};`;
          const text = this.reportData[reviewType].chart.labels[index] || '';

          legendHTML += `<span style="${style}">&nbsp;&nbsp;&nbsp;&nbsp;</span> ${text}<br>`;
        });
      });

      element.innerHTML = legendHTML;
    }
  }

  prepareLineChartData() {
    const reviewChart = this.reportData.reviewChart;
    if (!reviewChart) return;

    this.lineChartData.labels = _.keys(reviewChart);
    this.lineChartData.datasets[0].data = _.values(reviewChart);
  }
  downloadReport(report: string) {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = 'none';
    var element = document.getElementById('report');
    var collapsedElements = document.getElementsByClassName('collapse');
    var showLess = document.querySelectorAll('.show-less, .titleWithLink');
    var titleWithoutLink = document.querySelectorAll('.titleWithoutLink');
    _.forEach(collapsedElements, (element) => {
      element.className += ' show';
    })
    _.forEach(showLess, (element) => {
      element.style.display = 'none';
    })
    _.forEach(titleWithoutLink, (element) => {
      element.style.display = 'block';
    })
    var opt = {
      margin: 0,
      filename: 'reviewgen.pdf',
      jsPDF: { unit: 'in', format: 'b3', orientation: 'portrait' },
      // pagebreak: { mode: ['avoid-all', 'css'] }
    };

    //  document.getElementById("barChart").style.width='100%';

    if (report == 'print') {
      html2pdf().from(element).set(opt).output('dataurlnewwindow').then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        _.forEach(showLess, (element) => {
          element.style.display = 'block';
        })
        _.forEach(titleWithoutLink, (element) => {
          element.style.display = 'none';
        })
      });
    } else {
      html2pdf().from(element).set(opt).save().then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        _.forEach(showLess, (element) => {
          element.style.display = 'block';
        })
        _.forEach(titleWithoutLink, (element) => {
          element.style.display = 'none';
        })
      });
    }


  }

  onValueChange(event) {
    this.reportParams['startDate'] = moment(event[0]).format('MM/DD/YYYY');
    this.reportParams['endDate'] = moment(event[1]).format('MM/DD/YYYY');
    this.reportParams['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.reportParams['_endDate'] = moment(event[1]).format('MM/DD/YY');
    this.getReviewgenReport(this.reportParams);
  }
  resetJSLegend() {
    let legends = document.querySelectorAll('[id^="js-legend"]');
    [].forEach.call(legends, function (element) {
      element.id = "js-legend";
    });
  }
}
