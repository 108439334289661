// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location input {
    width: 100%;
}
input.has-danger{
    border: 1px solid red !important;
}
.content-location .upload-img-thumbnail{max-width:80px;}
.content-location h6, .content-location h6 a{font-weight:600; color:#212529; font-size:14px;}
.content-location h6 a:hover{text-decoration:none;}
`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/basic/content/locatoin/locatoin.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,gCAAgC;AACpC;AACA,wCAAwC,cAAc,CAAC;AACvD,6CAA6C,eAAe,EAAE,aAAa,EAAE,cAAc,CAAC;AAC5F,6BAA6B,oBAAoB,CAAC","sourcesContent":[".location input {\n    width: 100%;\n}\ninput.has-danger{\n    border: 1px solid red !important;\n}\n.content-location .upload-img-thumbnail{max-width:80px;}\n.content-location h6, .content-location h6 a{font-weight:600; color:#212529; font-size:14px;}\n.content-location h6 a:hover{text-decoration:none;}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
