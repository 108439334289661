import { Component, OnInit } from '@angular/core';
import { FileUploadService } from '../services/file-upload.service';
import { environment } from 'environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DataService } from '../data-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from '@shared/services/http/http.service';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  siteId: any;
  baseUrl = environment.imgUrl;
  uploadedFilesData = [];
  resMsg: string;
  warningMsg: string;
  field: string;
  isMultipleFiles: boolean;
  attachmentFor: string;
  imageForId: any;
  imageFor: any;
  selectedFile = [];
  isAssets: boolean;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  uploadData: FormData = new FormData();
  checkUploadedImgType: boolean;

  constructor(
    private fileUploadService: FileUploadService,
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private httpService: HttpService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.uploadedFilesData = [];
    if (this.siteId) {
      this.getUploadedfilesData(this.siteId, this.selectedFile ? this.selectedFile : null);
    }else{
      _.forEach(this.selectedFile, (file) => {
        this.uploadedFilesData.push({path:file, name: file.substring(file.lastIndexOf('/')+1)});
      })
    }
  }

  public getSantizeUrl(url: string) {
    let finalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl + '/' + url);
    return finalUrl;
  }

  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }

  getUploadedfilesData(siteId, selectedFile?) {
    if (!this.attachmentFor) {
      this.fileUploadService.getUploadedfilesData(siteId).subscribe(res => {
        if (res) {
          this.uploadedFilesData = [];
          _.forEach(res, (file) => {
            this.uploadedFilesData.push({path:file.path, name: file.path.substring(file.path.lastIndexOf('/')+1)});
          })
          if (selectedFile) {
            this.checkSelectedFile(selectedFile);
          }

        } else {

        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
    } else {
      if (this.fileUploadService.uploadedFiles && this.fileUploadService.uploadedFiles['userId'] == this.userInfo['userId']) {
        this.uploadedFilesData = this.fileUploadService.uploadedFiles['files'];
      }
    }

  }

  checkSelectedFile(selectedFile) {
    _.forEach(this.uploadedFilesData, (file) => {
      _.forEach(selectedFile, (sFile) => {
        file.isFileSelected = file.path == sFile ? true : false;
      })
    })
  }

  onFileChanged(event) {
    this.uploadData = new FormData();
    if (event) {
      _.forEach(event.target.files, (targetFile) => {
        let file = targetFile;
        let name = targetFile.name.split('.');
        name = name[name.length - 1];
        var supportedFormate = this.dataService.supportedFileFormate;
        if (supportedFormate.indexOf(name.toLowerCase()) != -1 && targetFile.size <= this.dataService.maxxFileSize) {
          this.uploadData.append('myFile', file, file.name);
          var reader = new FileReader();
          reader.readAsDataURL(targetFile); // read file as data url
          reader.onload = (event) => { // called once readAsDataURL is completed
            this.warningMsg = '';
            this.uploadFile();
          }
        } else {
          if (targetFile.size > this.dataService.maxxFileSize) {
            this.warningMsg = 'Exceeds the maximum upload size';
          } else {
            this.warningMsg = 'Sorry, this file type is not permitted for security reasons.';
          }

        }
      });
    }

  }

  uploadFile() {
    if (this.warningMsg == '') {
      if (this.uploadData.has("myFile")) {
        this.uploadData.append("siteId", this.siteId);
        this.uploadData.append("imageFor", this.imageFor);
        this.uploadData.append("imageForId", this.imageForId);
        this.uploadData.append("attachmentFor", this.attachmentFor);
        this.fileUploadService.uploadFile(this.uploadData).subscribe(ures => {
          if (ures) {
            this.resMsg = "File Uploaded Successfully";
            let files = ures['path'].split(",");
            this.selectedFile.push(files[files.length - 1]);
            if (this.siteId && !this.attachmentFor) {
              this.getUploadedfilesData(this.siteId, this.selectedFile);
            } else {
              _.forEach(files, (file) => {
                let n;
                if (this.uploadedFilesData.length) {
                  n = _.find(this.uploadedFilesData, (photo) => {
                    return photo.path == file;
                  })
                }
                if (!n) {
                  this.uploadedFilesData.push({ path: file, name: file.substring(file.lastIndexOf('/')+1) });
                }
              })
              this.checkSelectedFile(this.selectedFile);
            }
          }
        },
          err => {
            this.httpService.openErrorPopup(err.error.message);
          })
      }
    }
  }

  /**
   * Sets the selected file to a null array
   */
  resetSelectedFile(): void {
    this.selectedFile = [];
  }

  selectFile(selectedFile) {
    selectedFile['field'] = this.field;
    if (!this.isMultipleFiles) {
      this.selectedFile = [];
      _.forEach(this.uploadedFilesData, (file) => {
        if (file.path != selectedFile.path) {
          file.isFileSelected = false;
        }
      })
    }
    if (selectedFile.isFileSelected) {
      this.selectedFile.push(selectedFile.path);
    } else {
      this.selectedFile = _.filter(this.selectedFile, (file) => {
        return file != selectedFile.path;
      })
    }
  }

  saveSelectedFile() {
    if (this.isAssets) {
      this.setAssets(this.selectedFile);
    }
    this.activeModal.close(this.selectedFile);
  }

  // currently used by content-editor.component.ts to define a method that checks if the file already exists in the asset list for that intake
  // TODO: remove the logic from content-editor and this function and handle on modal close event logic instead
  setAssets(file) {

  }
}
