import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import {customLayout} from '../custom-tooltip'

@Component({
  selector: 'app-bar-charts',
  templateUrl: './bar-charts.component.html',
  styleUrls: ['./bar-charts.component.css']
})
export class BarChartsComponent implements OnInit {
  @Input() aspectRatio;
  public barChartOptions: ChartOptions = {
    responsive: true,
   
    scales: {
      x: {
        grid: {
          display:false,
          drawOnChartArea: false,
        }
      },
      y: {
        grid: {
          display:true,
          drawOnChartArea: true,
        },
        
          min: 0
        
      }
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: function(tooltipModel) {
          customLayout.getLayout(tooltipModel, (this as any).chart.canvas);
        }
      }
      ,
      legend: {display:false},
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  
  @Input() chartColors;
  public barChartColors;
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  @Input() data: ChartDataset[];
  @Input() labels;
  @Input() height;
  // data;
  constructor() { }

  ngOnInit() {
    this.barChartColors = this.chartColors || [{
      backgroundColor: '#83cefc'
    }]
    if(this.aspectRatio){ 
      this.barChartOptions.aspectRatio = this.aspectRatio;
    }
  }

}
