import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { HttpService } from '../../../../../services/http.service';
import { TimeListService } from '@shared/services/time-list/time-list.service';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.css'],
})
export class BasicComponent implements OnInit {
  @Input() intakeDisplayName;
  @Input() intakeTypeId;
  @Input() isUpgrade;
  @Input() status;

  @Output() changeStatus = new EventEmitter<any>();

  basic;
  basicMenu = this.intakesService.basicMenu;
  intakeId: string;
  overAllPercentage = this.intakesService.overAllPercentage;
  selectedItem = { id: 0 };
  siteId;
  style;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private intakesService: IntakesService,
    private router: Router,
    private toastrService: ToastrService,
    private timeListService: TimeListService
  ) {
    this.intakesService.progressBar.subscribe(obj => {
      if (obj != null) {
        this.basicMenu = obj.basicMenu;
        this.overAllPercentage = obj.overAllPercentage;
      }
    });
  }
  disabled;
  ngOnInit() {
    this.loadIntake();
    this.getTimes();
  }

  ngOnChanges() {
    this.loadIntake();
  }

  loadIntake() {
    const sItem = this.selectedItem;
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        if (this.intakeTypeId == 1 || this.intakeTypeId == 2 || this.intakeTypeId == 3 || this.intakeTypeId == 4) {
          this.getIntakeBasic(params.id);
        }

        this.getProductList(this.intakeTypeId, params.id, sItem);

        this.disabled = this.status == 3 || this.status == 5 ||this.status == 6  ? 'disabled' : '';
      }
    });
  }


  /**
   * Retrieves the associated data for the given intake and sets the intakes ID to a member
   * variable in this component
   *
   * @param {String} intakeId The ID of the intake to query
   */
  getIntakeBasic(intakeId: string): void {
    this.intakeId = intakeId;

    this.intakesService.getIntakeBasic(intakeId).subscribe(
      (res) => {
        if (res.intakeId != 0) {
          this.intakesService.commonBasicInfoObj = res;
          this.basic = res;
        } else {
          this.router.navigate([`/client/intakes`]);
          this.toastrService.error(`Intake doesn't exist`, 'Error');
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getProductList(familyId, intakeId, selectedItem) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res)
        this.basicMenu = this.intakesService.basicMenu;
        this.overAllPercentage = this.intakesService.overAllPercentage;
        this.intakesService.updateSectionstatus(this.basicMenu);
        if(!this.selectedItem || (selectedItem && selectedItem['familyId'] != familyId)){
          this.selectedItem = this.basicMenu[0];
        }
      } else {
        this.router.navigate([`/client/intakes`]);
        this.toastrService.error(`Site doesn't exist`, 'Error');
      }
    });
  }

  openPage(item) {
    this.selectedItem['selectedSectionId'] = null;
    this.selectedItem = item;
    console.log("open page => ", this.selectedItem);
  }

  gotoEditPage(event){
    this.selectedItem = _.find(this.basicMenu, (menu) => {return menu.id == event.menuId});
    this.selectedItem['selectedSectionName']=event.sectionName;
    this.selectedItem['selectedSectionId'] = event.sectionId;
    console.log("go to edit page => ", this.selectedItem);
    // console.log(event);
  }

  goToNext(event) {
    let selectedItem = this.selectedItem;
    let index = _.findIndex(this.basicMenu, function (menu) {
      return menu.id == selectedItem['id'];
    });
    this.selectedItem = this.basicMenu[index + 1];
    console.log("go to next => ", this.selectedItem);
    window.scroll(0,0);
  }

  goBack(event) {
    let selectedItem = this.selectedItem;
    let index = _.findIndex(this.basicMenu, function (menu) {
      return menu.id == selectedItem['id'];
    });
    this.selectedItem = this.basicMenu[index - 1];
    let item = this.selectedItem['name'] == 'Pages' ? this.selectedItem['submenu'][this.selectedItem['submenu'].length - 2] : this.selectedItem['submenu'][this.selectedItem['submenu'].length - 1];
    this.selectedItem['selectedSectionId'] = item.id;
    this.selectedItem['selectedSectionName'] = item.name;
    console.log("go back => ", this.selectedItem);
    window.scroll(0,0);
  }

  changeIntakeStatus(event) {
    this.changeStatus.next(event);
  }

  getTimes(): void {
    // sets the timeList in the service to be retreived by any intake later.
    this.timeListService.getTimeList().subscribe(() => { });
  }
}