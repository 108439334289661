import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MasterListComponent, AddComponent, ListComponent, MtListComponent, AdminDashboardComponent } from './index';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AddMasterTypeComponent } from './masters/masterTypes/add-master-type/add-master-type.component';
import { PageCollectionListComponent } from './page-collection/page-collection-list/page-collection.component';
import { ConfigListComponent } from './configuration/config-list/config-list.component';
import { AddConfigComponent } from './configuration/add-config/add-config.component';
import { PageCollectionAddComponent } from './page-collection/page-collection-add/page-collection-add.component';
import { BuildSiteComponent } from './build-site/build-site.component';
import { DetailComponent } from './users/detail/detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NotificationComponent } from './notification/notification.component';
import { AddEditNotificationComponent } from './notification/add-edit-notification/add-edit-notification.component';
import { ManageCorporatePartnerComponent } from './manage-corporate-partner/manage-corporate-partner.component';
import { TicketModule } from '../ticket/ticket.module';
import { SitesPopupComponent } from './users/sites-popup/sites-popup.component';
import { CustomerModule } from '../customer/customer.module';
import { AddCpComponent } from './manage-corporate-partner/add-cp/add-cp.component';
import { BluePrintProcessComponent } from './blue-print-process/blue-print-process.component';
import { SharedModule } from '@shared/shared.module';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';


// import { NotificationlistComponent } from './notification/notificationlist/notificationlist.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CustomerModule,
        ReactiveFormsModule,
        TicketModule,
        FormsModule,
        TableModule,
        NgbModule,
        BsDatepickerModule,
        SharedModule,
        PaginatorModule,
    ],
    declarations: [
        AddCpComponent,
        AddConfigComponent,
        AddComponent,
        AdminDashboardComponent,
        AddEditNotificationComponent,
        AddMasterTypeComponent,
        BluePrintProcessComponent,
        BuildSiteComponent,
        ConfigListComponent,
        DetailComponent,
        ListComponent,
        ManageCorporatePartnerComponent,
        MasterListComponent,
        MtListComponent,
        NotificationComponent,
        PageCollectionListComponent,
        PageCollectionAddComponent,
        SitesPopupComponent,
    ]
})
export class AdminModule { }
