import { InputGeneric } from '@shared/interfaces/forms';
import countriesData from './countries.json';
import statesData from './states.json';
import { StateItem } from '@shared/interfaces/data';
import { CountryData } from '@shared/interfaces/addresses';

/**
 * The list of countries the portal supports
 */
export const countries: InputGeneric[] = Object.values(countriesData).map((countryData) => {
  return { text: countryData.name, value: countryData.code };
});

/**
 * Returns a list of Burmedian islands that is compatible with a select menu
 */
export const parishes: InputGeneric[] = statesData['bm'].map((stateData: StateItem) => {
  return { text: stateData.name, value: stateData.abbreviation.toLowerCase() };
});

/**
 * Returns a list of Caymanian islands that is compatible with a select menu
 */
export const islands: InputGeneric[] = statesData['ky'].map((stateData: StateItem) => {
  return { text: stateData.name, value: stateData.abbreviation.toLowerCase() };
});

/**
 * Returns a list of Canadian provinces compatible with a select menu
 *
 * @return {InputGeneric[]}
 */
export const provinces: InputGeneric[] = statesData['ca'].map((stateData: StateItem) => {
  return { text: stateData.name, value: stateData.abbreviation.toLowerCase() };
});

/**
 * Returns a list of US states and US territories, compatible with a select menu
 *
 * @return {InputGeneric[]}
 */
export const states: InputGeneric[] = statesData['us'].map((stateData: StateItem) => {
  return { text: stateData.name, value: stateData.abbreviation.toLowerCase() };
});

export const countrySubdivisions = (countryCode: string) => {
  return statesData[countryCode].map((stateData: StateItem) => {
    return { text: stateData.name, value: stateData.abbreviation.toLowerCase() };
  });
};

/**
 * Method to retrieve an array of national subdivisions based on the country code
 *
 * @param {String} countryCode The ISO-3166 country code
 * @return {InputGeneric[]} An array of inputs compatible with a select menu.
 */
export function getCountrySubdivision(countryCode: string): InputGeneric[] {
  const countryCodeLowerCase = countryCode.toLowerCase();

  return statesData[countryCodeLowerCase].map((subdivisionData: StateItem) => {
    return { text: subdivisionData.name, value: subdivisionData.abbreviation.toLowerCase() };
  });
}

/**
 * Returns the data associate with a country, such as name and what subdivisions are called
 *
 * @param {string} countryCode The ISO-3166 country code
 *
 * @return {CountryData|null} A JSON object containing the data for a country or a null response
 */
export function getCountryData(countryCode: string): CountryData|null {
  if (countriesData.hasOwnProperty(countryCode)) {
    return countriesData[countryCode];
  }

  return null;
}

/**
 * Searches for the appropriate country code when provide the full country name
 *
 * @param {String} countryName
 * @TODO remove key from the loop and only traverse through the values
 */
export function getCountryCodeFromFullName(countryName: string) {
  for (const [key, {text, value}] of Object.entries(countries)) {
    if (countryName === text) {
      return value;
    }
  }

  return null;
}

export function getCountrySubdivisionCodeFromFullName(stateName: string, countryName: string) {
  if (stateName.length === 2)
      return stateName;
  let country = countryName.toLowerCase();
  if (countryName.length !== 2) {
    country = getCountryCodeFromFullName(country);
  }
  var result = countrySubdivisions(country).filter(x => x.text.toLowerCase() === stateName.toLowerCase());
  return result[0].value;
}
