import { SiteConfigComponent } from '../components/site/site-config/site-config.component';
import { Route } from '@angular/router';

export const SiteConfigRoutes: Route[] = [
  {
    path: 'site-config/:siteId',
    component: SiteConfigComponent,
    data: {
      name: 'Site Config',
    },
  },
  {
    path: 'site-config/:siteId/practice-management',
    component: SiteConfigComponent,
    data: {
      familyId: 'pms',
    },
  },
  {
    path: 'site-config/:siteId/practice-management/counsel-ear',
    component: SiteConfigComponent,
    data: {
      familyId: 'pms',
      type: 4,
    },
  },
  {
    path: 'site-config/:siteId/review-gen',
    component: SiteConfigComponent,
    data: {
      familyId: 3,
    },
  },
  {
    path: 'site-config/:siteId/social-media-marketing',
    component: SiteConfigComponent,
    data: {
      familyId: 6,
    },
  },
];
