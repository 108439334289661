import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../../../services/intakes.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import * as _ from 'lodash';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { environment } from 'environments/environment';
import { SectionStatus } from '../../review/section-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../../../services/http.service';
import { AssetsComponent } from '../../content/assets/assets.component';
@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.css']
})
export class BrandingComponent implements OnInit {
  @Input() existingData;
  brandingForm: UntypedFormGroup;
  intakeId;
  baseUrl = environment.imgUrl;
  selectedSiteId;
  fieldValid = {};
  paletteData;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() changeStatus = new EventEmitter<any>();
  @Input() sectionId;
  @Input() intakeTypeId;
  @Input() data;
  selectedColor = {};
  selectedColorList = [];
  colorPickerToggle;
  // disableColorPicker = false;
  requiredFields = [];
  photographs = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private httpService: HttpService,
    public dataService: DataService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.selectedSiteId = params.siteId;
      }
    });
  }

  ngOnInit() {
    console.log(this.intakeTypeId);
    this.data = this.intakesService.basicInfo['branding'] ? this.intakesService.basicInfo['branding'] : this.existingData;;
    this.getRequiredFields();
    // this.intakesService.updateRequiredFieldsStatus(this.data);
    this.initializeForm();
    if (this.data) {
      this.splitColor(this.data.colorPalette);
    }
    this.getPaletteData();
  }

  getRequiredFields() {
    this.requiredFields = _.filter(this.intakesService.requiredFields, (field) => {
      return field.sectionId == this.sectionId;
    });
  }

  initializeForm() {
    this.brandingForm = this.formBuilder.group({
      isLogo: new UntypedFormControl(this.data ? this.data.isLogo : null),
      isColorPalette: new UntypedFormControl(this.data ? this.data.isColorPalette : null),
      colorPalette: new UntypedFormControl(this.data ? this.data.colorPalette : null),
      acceptLogo: new UntypedFormControl(this.data ? this.data.acceptLogo : false),
      logo: new UntypedFormControl(this.data ? this.data.logo : null),
      colorPicker: new UntypedFormControl(),
      styleGuide: new UntypedFormControl(this.data ? this.data.styleGuide : null),
      isColorPreference: new UntypedFormControl(this.data ? this.data.isColorPreference : null),
      colorPaletteOther: new UntypedFormControl(this.data ? this.data.colorPaletteOther : null),
      isColorPalettePicker: new UntypedFormControl(this.data ? this.data.isColorPalettePicker : null)
    })
    this.photographs['logo']=[];
    this.photographs['styleGuide']=[]
    if(this.data && this.data.logo)
      this.photographs['logo'].push(this.data.logo);
    if(this.data && this.data.styleGuide)
      this.photographs['styleGuide'].push(this.data.styleGuide);
  }



  getPaletteData() {
    this.intakesService.getPaletteData().subscribe((res) => {
      if (res) {
        this.paletteData = res['masterList'];

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  openUploadedFilesPopup(fieldName) {
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = this.selectedSiteId;
    model.componentInstance.field = fieldName;
    model.result.then((result) => {
      if (result) {
        _.forEach(result, (file) => {
          let n;
          if(this.photographs[fieldName] && this.photographs[fieldName].length){
            n = _.find(this.photographs[fieldName], (photo) => {
              return photo == file;
            })
          }else{
            this.photographs[fieldName] = [];
          }
          if(!n){
            this.photographs[fieldName]=[];
            this.photographs[fieldName].push(file);
          }
        })
        let control = _.pick(this.brandingForm.controls, fieldName);
        control[fieldName].patchValue(result[0]);

      }
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }

  openAssetsPopup(fieldName) {
    let model = this.modalService.open(AssetsComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.title = fieldName;
    this.photographs[fieldName].forEach(function (file) {
      let type = file.split('.');
      type = type[type.length - 1];
      model.componentInstance.assets.push({ path: file, fileType: type, name: file.split('/')[file.split('/').length - 1] })
    });
    model.componentInstance.deleteAssets = (type) => {
      model.componentInstance.assets = _.filter(model.componentInstance.assets, (file) => {
        return !file.hasOwnProperty('isFileSelected') || file.isFileSelected == false;
      });

      this.photographs = [];
      this.brandingForm.controls[fieldName].setValue('');
      _.forEach(model.componentInstance.assets, (asset) => {
        this.photographs[fieldName].push(asset.path);
        this.brandingForm.controls[fieldName].setValue(this.photographs[fieldName].join(','));
      })
      if(type != 'delete'){
        model.close();
      }
    }
  }

  isFieldValid(field: string, index: number = 0) {
    //this.fieldValid[field] = !this.brandingForm.get(field).valid && this.brandingForm.get(field).touched;
     return !this.brandingForm.get(field).valid && this.brandingForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray) {
        for (let i = 0; i < control.controls.length; i++) {
          this.validateAllFormFields(control.controls[i]);
        }
      }

    });
  }
  changeEvt(event) {
    this.brandingForm.controls.isColorPreference.setValue(null);
    this.brandingForm.controls.isColorPalettePicker.setValue(null);
  }
  saveFormData(form, mode?) {

    if (this.brandingForm.valid) {
      console.log('valid form submitted');
      if (form.value.isColorPalette && form.value.isLogo) {
        form.value.colorPalette = null;
        form.value.isColorPreference = null;
        form.value.isColorPalettePicker = null;
        form.value.colorPaletteOther = null;
      } else {
        form.value.colorPaletteOther = form.value.isColorPreference ? form.value.colorPaletteOther : null;
        form.value.colorPalette = form.value.isColorPalettePicker ? _.toString(_.map(this.selectedColorList, 'value')) : null;
      }
      let intakeData = this.intakesService.basicInfo['branding'] = form.value;
      if (!intakeData.isLogo) {
        intakeData.logo = null;
      }
      intakeData.intakeId = this.intakeId;
      _.assign(this.intakesService.commonBasicInfoObj, intakeData);
      this.intakesService.saveIntake(this.intakesService.commonBasicInfoObj).subscribe(res => {
        if (res) {
          this.changeStatus.next();
          this.toastrService.success(this.dataService.successMsg, 'Success');
          this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
          let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, intakeData.intakeId);
          this.postIntakeSection(sectionStatus);
          if (mode == 'continue') {
            this.goToNext();
          }
        } else {

        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
    } else {
      console.log('invalid form submitted')
      this.validateAllFormFields(this.brandingForm);
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  goBack() {
    this.backClicked.next();
  }

  goToNext() {
    this.continueClicked.next();
  }

  updateColorPalette() {
    this.brandingForm.controls['isColorPalette'].setValue(null);
  }

  addNewColor(selectedColor) {

    let editColor = _.find(this.selectedColorList, (item) => {
      return item.value == this.selectedColor['value'];
    });
    let existingColor = _.find(this.selectedColorList, (item) => {
      return item.value == selectedColor;
    });
    if (editColor && !existingColor) {
      editColor.value = selectedColor;
    } else {
      if (this.selectedColorList.length < 3 && !existingColor) {
        let colorObj = {
          index: this.selectedColorList.length,
          value: selectedColor
        }
        this.selectedColorList.push(colorObj);
      }else{
        this.toastrService.error('Color has already been selected.', 'Error');
      }
    }
    this.selectedColor = {};
    this.selectedColorList.length >= 3 ? this.brandingForm.controls['colorPicker'].disable() : this.brandingForm.controls['colorPicker'].enable();
    this.selectedColor = this.selectedColorList.length >= 3 ? {} : this.selectedColor;
  }

  splitColor(value) {
    if (value) {
      this.selectedColorList = [];
      let splitString = value.split(',');
      _.forEach(splitString, (element, i) => {
        this.selectedColorList.push({
          index: i,
          value: element
        })
      })
      this.selectedColorList.length >= 3 ? this.brandingForm.controls['colorPicker'].disable() : this.brandingForm.controls['colorPicker'].enable();
      this.selectedColor = this.selectedColorList.length >= 3 ? {} : this.selectedColor;
    }

  }

  editColor(color) {
    this.selectedColor = color;
    this.colorPickerToggle = !this.colorPickerToggle;
    this.brandingForm.controls['colorPicker'].enable();
  }

  deleteColor(color) {
    this.selectedColorList = _.filter(this.selectedColorList, (colorObj) => {
      return colorObj.value != color.value;
    })
    if (color.value == this.selectedColor['value']) {
      this.selectedColor = {};
    }
    this.brandingForm.controls['colorPicker'].enable();
  }

}
