// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep.statement-of-work{font-family: 'Source Sans Pro', 'Segoe UI', 'Droid Sans',
                                     Tahoma, Arial, sans-serif;
                                    font-size: 14px; margin:0 0 10px 0;}
:host::ng-deep .statement-of-work h1, .statement-of-work h2{font-size:38px; font-weight:300;}
:host::ng-deep .statement-of-work h2{font-size:33px;}
:host::ng-deep .statement-of-work p{margin-bottom:15px;}
:host::ng-deep .statement-of-work ul{margin-bottom: 10px!important; padding-left: 40px!important;  list-style-type: disc;}
:host::ng-deep .statement-of-work .kb-logo {  padding: 30px 0;}
:host::ng-deep th {background-color: #F5F6FA  ;font-weight: bold;}
:host::ng-deep ol{ margin: 10px 10px 10px 15px ; padding-left: 40px; }
:host::ng-deep ol li{line-height:21px ; margin-bottom:5px ;}  
:host::ng-deep .ol-upper-roman{list-style-type:upper-roman !important;}
:host::ng-deep .ol-lower-roman{list-style-type:lower-roman !important;}
:host::ng-deep .ol-upper-alpha{list-style-type:upper-alpha !important;}
:host::ng-deep .ol-lower-alpha{list-style-type:lower-alpha !important;}
:host::ng-deep .ol-decimal{list-style-type:decimal !important;}
`, "",{"version":3,"sources":["webpack://./src/app/components/customer/statement-of-work/exhibit-b/exhibit-b.component.css"],"names":[],"mappings":"AAAA,iCAAiC;8DAC6B;oCAC1B,eAAe,EAAE,iBAAiB,CAAC;AACvE,4DAA4D,cAAc,EAAE,eAAe,CAAC;AAC5F,qCAAqC,cAAc,CAAC;AACpD,oCAAoC,kBAAkB,CAAC;AACvD,qCAAqC,6BAA6B,EAAE,4BAA4B,GAAG,qBAAqB,CAAC;AACzH,8CAA8C,eAAe,CAAC;AAC9D,mBAAmB,2BAA2B,CAAC,iBAAiB,CAAC;AACjE,mBAAmB,4BAA4B,EAAE,kBAAkB,EAAE;AACrE,qBAAqB,iBAAiB,EAAE,kBAAkB,CAAC;AAC3D,+BAA+B,sCAAsC,CAAC;AACtE,+BAA+B,sCAAsC,CAAC;AACtE,+BAA+B,sCAAsC,CAAC;AACtE,+BAA+B,sCAAsC,CAAC;AACtE,2BAA2B,kCAAkC,CAAC","sourcesContent":[":host::ng-deep.statement-of-work{font-family: 'Source Sans Pro', 'Segoe UI', 'Droid Sans',\n                                     Tahoma, Arial, sans-serif;\n                                    font-size: 14px; margin:0 0 10px 0;}\n:host::ng-deep .statement-of-work h1, .statement-of-work h2{font-size:38px; font-weight:300;}\n:host::ng-deep .statement-of-work h2{font-size:33px;}\n:host::ng-deep .statement-of-work p{margin-bottom:15px;}\n:host::ng-deep .statement-of-work ul{margin-bottom: 10px!important; padding-left: 40px!important;  list-style-type: disc;}\n:host::ng-deep .statement-of-work .kb-logo {  padding: 30px 0;}\n:host::ng-deep th {background-color: #F5F6FA  ;font-weight: bold;}\n:host::ng-deep ol{ margin: 10px 10px 10px 15px ; padding-left: 40px; }\n:host::ng-deep ol li{line-height:21px ; margin-bottom:5px ;}  \n:host::ng-deep .ol-upper-roman{list-style-type:upper-roman !important;}\n:host::ng-deep .ol-lower-roman{list-style-type:lower-roman !important;}\n:host::ng-deep .ol-upper-alpha{list-style-type:upper-alpha !important;}\n:host::ng-deep .ol-lower-alpha{list-style-type:lower-alpha !important;}\n:host::ng-deep .ol-decimal{list-style-type:decimal !important;}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
