import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageCollectionService {

  constructor(
    private httpService: HttpService
  ) { }

  getAllCollectionData(): Observable<any[]> {
    return this.httpService.getApi('PageCollection')
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }

  addPageCollection(userData): Observable<any> {
    return this.httpService.postApi('PageCollection', userData, '')
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }

  getAllCollectionDataById(id): Observable<any[]> {
    return this.httpService.getApi(`PageCollection/${id}`)
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    }),
    catchError(this.httpService.handleError<any>())
  );
  }

  updatePageCollection(userData): Observable<any> {
    return this.httpService.putApi(`PageCollection`, userData, '')
    .pipe(
      tap(res => {
        if(res){

          return res;
        }
    })
  );
  }

  delete(id) {
    return this.httpService.deleteApi(`PageCollection/${id}`).pipe(
     tap(res => {
       if(res){

         return res;
       }
   })
    )}

}
