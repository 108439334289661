import { Component, OnInit } from '@angular/core';
import { IntakesService } from '../services/intakes.service';
import { IntakesService as customerIntakesService } from '../../../customer/intakes/services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { InitiatePopupComponent } from '../initiate-popup/initiate-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '@services/http.service';
import { StripePaymentComponent } from '../../../customer/payment/stripe-payment/stripe-payment.component';
import { IntakeProduct } from '@shared/interfaces/intakes';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { ClinicLocationsService } from '@shared/services/clinic-locations/clinic-locations.service';
import { AddressData } from '@shared/interfaces/addresses';
import { InvalidAddressModalComponent } from '@shared/invalid-address-modal/invalid-address-modal.component';
import { DataService } from '@shared/data-service.service';
import { BuildSiteService } from 'app/components/admin/build-site/build-site.service';
@Component({
  selector: 'app-intake-product-list',
  templateUrl: './intake-product-list.component.html',
  styleUrls: ['./intake-product-list.component.css'],
})
export class IntakeProductListComponent implements OnInit, PageHasTable {
  hasValidAddress: boolean | null = null; // Initialize as null
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  showMessage = false; //used to only check the validity of an address once in ngdocheck
  hasRunOnce = false; //also used to check it only ran once in ngdocheck.
  hoveredButton = false; // Track if the button is hovered
  showModal = false; // Control modal visibility
  domainUrl:string;
  columnHeadersByKey: object;
  intakeProductList;
  groupByData;
  keys;
  customerCardInfo;
  totalAmt = 0;
  IntakeId;
  EXHIBITC;
  EXHIBITA;
  openTAndC;
  userInfo = localStorage.getItem('userInfo') != null ? JSON.parse(localStorage.getItem('userInfo')) : { 'token': '', 'userId': null, 'roleId': null };
  loading = false;
  isCouponTypeAmount: boolean = true;
  intakeId;
  reCheckExistingProduct: boolean = false;
  currentRole;
  selectedSiteId;
  paymentMode;
  locations: AddressData[] = [];

  constructor(
    private intakesService: IntakesService,
    private customerIntakesService: customerIntakesService,
    private router: Router,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    public clinicLocationsService: ClinicLocationsService,
    private mservice: NgbModal,
    private dataservice: DataService,
    private buildSiteService: BuildSiteService,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.intakeId = params.id;
        this.getBillingIntakeProduct();
      }
    });

    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
    });
  }

  ngOnInit() {
    this.openTAndC = this.activatedRoute.snapshot.queryParamMap.get('tnc') ? true : false;
    this.getTableData();
    const userInfoJson = localStorage.getItem('userInfo');
    // Parse the JSON string into an object
    const userInfo = JSON.parse(userInfoJson);
    // Extract the 'userId' property
    const role = userInfo.roleId;
    if(role >= 1 && role <= 10){
      localStorage.removeItem('selectedSite');
    }

  }
  ngDoCheck(){
    if (!this.showMessage && this.selectedSiteId != null){
      this.dataservice.selectedSiteId = this.selectedSiteId;
      this.hasValidAddressLocation();
      this.showMessage = true;
      this.buildSiteService.getDomain(this.userInfo.userId, this.selectedSiteId).subscribe((res) => {
        if (res) {
          this.domainUrl=res.domainUrl;
          console.log(this.domainUrl);
        }
      },
        err => {
           this.httpService.openErrorPopup(err.error.message);
        });
    }
    if(!this.hasRunOnce &&  this.hasValidAddress === false && this.selectedSiteId != null){
      this.hasValidAddressLocation();
      this.openInvalidAddressModal();
      this.hasRunOnce = true;
    }
    const userInfoJson = localStorage.getItem('userInfo');
    // Parse the JSON string into an object
    const userInfo = JSON.parse(userInfoJson);
    // Extract the 'userId' property
    const role = userInfo.roleId;
    if(this.selectedSiteId != null && !(role >= 1 && role <= 10)){
      const siteinfoJson = localStorage.getItem('selectedSite');
        const siteinfo = JSON.parse(siteinfoJson);
        siteinfo.siteId = this.selectedSiteId;
        siteinfo.domainUrl = this.domainUrl;
        localStorage.setItem('selectedSite', JSON.stringify(siteinfo));
        
    }
  }

  getBillingIntakeProduct() {
    const obj = {
      intakeId: this.intakeId,
      reCheckExistingProduct: this.reCheckExistingProduct,
      isCouponTypeAmount: this.isCouponTypeAmount,
    };
    this.intakesService.getBillingIntakeProduct(obj).subscribe((res) => {
      if (res) {
        this.intakeProductList = res;
        if (res['isCouponTypeAmount'] != null) {
          this.isCouponTypeAmount = res['isCouponTypeAmount'];
        }
        const startupProduct = _.find(this.intakeProductList.data, (data) => {
          return data.paymentType == 'Startup';
        });
        if (startupProduct) {
          this.totalAmt = startupProduct.total;
        }
        this.selectedSiteId=this.intakeProductList.siteId;
        this.paymentMode=this.intakeProductList.paymentMode;
        this.getCard(this.intakeProductList.siteId);
      }
    },
    (err) => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  getCard(siteId) {
    this.customerIntakesService.GetCustomerPayInfo(siteId).subscribe((res) => {
      this.customerCardInfo = res;
      this.loading = false;
    },
    (err) => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  getDiscount() {
    this.reCheckExistingProduct = true;
    this.getBillingIntakeProduct();
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      discount: 'number',
      lineItemTax: 'currency',
      listPrice: 'currency',
      totalPrice: 'currency',
    };

    this.columnHeaders = [
      { field: 'productName', header: 'Product Name', width: 42},
      { field: 'listPrice', header: 'List Price'},
      { field: 'quantity', header: 'Quantity'},
    ];

    const columnHeadersBasic = [
      ...this.columnHeaders,
      { field: 'totalPrice', header: 'Startup Fee'},
      { field: 'lineItemTax', header: 'Sales Tax' },
      { field: 'discount', header: 'Discount', width: 11},
    ];

    const columnHeadersMonthly = [
      ...this.columnHeaders,
      { field: 'listPrice', header: 'Recurring Fee'},
      { field: 'discount', header: 'Discount', width: 11},
    ];

    this.columnHeadersByKey = {
      'Startup': columnHeadersBasic,
      'Monthly': columnHeadersMonthly,
      'Quarterly': columnHeadersMonthly,
      'Yearly': columnHeadersMonthly,
    };
  }

  goToReview() {
    this.router.navigate([`/review/28/30`]);
  }
  goBack() {
    if (this.userInfo.roleId>10) {
      this.router.navigate([`${this.currentRole}/intakes`]);
    } else {
      this.router.navigate([`${this.currentRole}/project`]);
    }
  }

  openInitiatePopup() {
    const model = this.modalService.open(InitiatePopupComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.intake = this.intakeProductList;
    model.result.then((result) => {
      if (result) {
        this.toastrService.success('Intake initiated', 'Success');
      }
      this.router.navigate([`${this.currentRole}/project`]);
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }

  /**
   * Determines if a the table data is for a Startup Fees table
   *
   * @param {Object} tableData The data for the applicable table
   *
   * @return {Boolean} If the table data is meant for the Startup Fees table
   */
  isStartupFeesTable(tableData: IntakeProduct): boolean {
    return tableData.paymentType === 'Startup';
  }

  gotoPaymentPopup(intakeProductList) {
    if (!intakeProductList.isAcceptTnC) {
      this.openTAndC = true;
      this.router.navigate([], {
        queryParams: {
          tnc: true,
          intakeId: intakeProductList.intakeId,
        },
        queryParamsHandling: 'merge',
      });
      window.scrollTo(0, 0);
    } else {
      if (
        (
          !this.customerCardInfo.isCardExists ||
            (this.customerCardInfo.isCardExists && intakeProductList.paymentStatus==0)
        ) &&
          // exclude invoice intakes
          intakeProductList.paymentMode != 1
      ) {
        this.openCardPopup(intakeProductList);
      } else {
        this.goToAddIntake(intakeProductList);
      }
    }
  }

  backToProductList() {
    this.openTAndC = false;
    this.getBillingIntakeProduct();
  }

  openCardPopup(productList) {
    this.openTAndC = false;
    let paymentInfo = {};
    let componentName;
    let totalAmount;
    this.intakeProductList = productList;
    paymentInfo = {
      siteId: productList.siteId,
      cardNumber: this.customerCardInfo.last4,
      expirationYear: this.customerCardInfo.expYear,
      expirationMonth: this.customerCardInfo.expMonth,
      intakeId: this.intakeId,
      userId: this.userInfo.userId,
      amount: productList.paymentStatus == 0 ? this.totalAmt : 0,
    };
    componentName = StripePaymentComponent;
    this.openAddPopup(componentName, paymentInfo);
  }

  openAddPopup(componentName, paymentInfo, tnc?) {
    const model = this.modalService.open(componentName, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.paymentInfo = paymentInfo;
    model.result.then((result) => {
      console.log(`add success: ${result}`);
      this.goToAddIntake(this.intakeProductList);
      this.openTAndC = false;
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }

  goToAddIntake(intake) {
    if (!this.intakeProductList.isAcceptTnC) {
      this.openTAndC = true;
      this.router.navigate([], {
        queryParams: {
          tnc: true,
          intakeId: intake.intakeId,
        },
        queryParamsHandling: 'merge',
      });
      window.scrollTo(0, 0);
    } else {
      this.router.navigate([`/client/intakes/${intake.intakeId}/${intake.siteId}`]);
      this.customerIntakesService.productFamilyList = this.intakeProductList.sfIntakeProductFamily;
    }
  }



  hasValidAddressLocation(): void {
    // Check if the method has already been called
    if (this.hasValidAddress !== null) {
      return; // Method has already been called, so do nothing
    }
    // Make your service call here
    this.clinicLocationsService.getAddressesBySite(this.selectedSiteId).subscribe(
      (res: AddressData[]) => {
        // Replace with your actual logic to determine if any address has a valid address
        const billingAddressExists = res.some((location) => {
          return location.isValid === true;
        });
        this.hasValidAddress = billingAddressExists;
      },
      (error) => {
        this.hasValidAddress = false; // Handle errors by setting hasValidAddress to false
      }
    );
  }
  isButtonHovered = false; // Track button hover state

  openInvalidAddressModal() {
    if (this.isButtonDisabled()) {
      const modalRef = this.mservice.open(InvalidAddressModalComponent, {
        centered: true, // Centers the modal on the page
      });
    }
  }
   // Function to check if the button is disabled
   isButtonDisabled(): boolean {
    return this.hasValidAddress === false || this.hasValidAddress === null;
  }
  


  openExhibit(ExhibitType) {
    if (ExhibitType == 'A') {
      this.EXHIBITA = true;
    } else {
      this.EXHIBITC = true;
    }
    window.scrollTo(0, 0);
  }
}
