import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { IntakesService } from '../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../services/http.service';

@Component({
  selector: 'app-master-review',
  templateUrl: './master-review.component.html',
  styleUrls: ['./master-review.component.css']
})
export class MasterReviewComponent implements OnInit {
  isEdit:boolean=true;
  constructor( 
    private activatedRoute: ActivatedRoute,
    private intakesService: IntakesService,
    private httpService: HttpService,
    private toastrService: ToastrService
  ) { }

  status;
  intakeTypeId;
  intakeId;
  isUpgrade;
  wpSiteId;
  comments;
  ngOnInit() {
    
    this.activatedRoute.params.subscribe(params => {
      if(params.intakeTypeId){
        this.intakeTypeId=Number(params.intakeTypeId)
        
      }
      if (params.id) {
        this.intakeId = Number(params.id);       
      }
    });
    this.getIntakeDetails(this.intakeId, this.intakeTypeId);
    this.getComment();
  }

  getIntakeDetails(intakeId, intakeTypeId){
    this.intakesService.getIntakeDetails(intakeId, intakeTypeId).subscribe(res => {
      if (res) {
        this.isUpgrade = res.isUpgrade;
        this.status = res.status;
        this.wpSiteId=res.wpSiteId;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getComment(){
    this.intakesService.getComment(this.intakeId,this.intakeTypeId).subscribe((res)=>{
      if(res){
          this.comments=res;
      }
    },
    err=>{
      throw err;
    })
  }

}
