import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-dropdown-item',
  templateUrl: './button-dropdown-item.component.html',
  styleUrls: ['./button-dropdown-item.component.css']
})
export class ButtonDropdownItemComponent implements OnInit {
  @Input() disabled = false;
  @Input() hr: false;
  @Input() text: string;

  @Output() click = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  clickFunction(): void {
    this.click.emit(null);
  }
}
