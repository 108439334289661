import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkmarkOrCross',
})
export class CheckmarkOrCrossPipe implements PipeTransform {
  transform(value: boolean): string {
    if (value === true) {
      return '<i class="icon tick-mark iconWidthHeight"></i>';
    }

    return (
      '<span><i class="icon cross iconWidthHeight"></i></span>'
    );
  }
}
