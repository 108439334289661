// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.filter {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(191, 190, 199);
  border-radius: 40px;
  box-shadow: 0 3px 5px rgb(191, 190, 199);
  min-height: 2rem;
  padding: 0.5rem 1.5rem;
  width: 25rem;
}

div.input-button {
  justify-content: flex-end;
}

div.input-button, div.input-buttons {
  align-items: baseline;
  display: inline-flex;
  flex-flow: row nowrap;
  float: right;
  width: 4rem;
}

div.input-buttons {
  justify-content: space-between;
}

img {
  min-height: 1rem;
  min-width: 1rem;
}

input {
  border: 0;
  color: rgb(77, 79, 92);
  font-size: 0.875rem;
  line-height: 1rem;
  width: 17rem;
}

input:focus {
  outline-width: 0;
}

.clear, .filter, .pipe, .search {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/inputs/filter/filter.component.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,oCAAoC;EACpC,mBAAmB;EACnB,wCAAwC;EACxC,gBAAgB;EAChB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,qBAAqB;EACrB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["div.filter {\n  background-color: rgb(255, 255, 255);\n  border: 1px solid rgb(191, 190, 199);\n  border-radius: 40px;\n  box-shadow: 0 3px 5px rgb(191, 190, 199);\n  min-height: 2rem;\n  padding: 0.5rem 1.5rem;\n  width: 25rem;\n}\n\ndiv.input-button {\n  justify-content: flex-end;\n}\n\ndiv.input-button, div.input-buttons {\n  align-items: baseline;\n  display: inline-flex;\n  flex-flow: row nowrap;\n  float: right;\n  width: 4rem;\n}\n\ndiv.input-buttons {\n  justify-content: space-between;\n}\n\nimg {\n  min-height: 1rem;\n  min-width: 1rem;\n}\n\ninput {\n  border: 0;\n  color: rgb(77, 79, 92);\n  font-size: 0.875rem;\n  line-height: 1rem;\n  width: 17rem;\n}\n\ninput:focus {\n  outline-width: 0;\n}\n\n.clear, .filter, .pipe, .search {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
