// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.py-36 {
  padding-top: 36px !important;
  /* padding-bottom: 36px !important; */
}
.font-12 {
  font-size: 12px !important;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0 0 0 0;
}
.font-12 p {
  font-size: 12px !important;
}
.btn.btn-xl {
  line-height: 50px;
  padding: 0 14px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 4px;
  min-width: 100px;
}
.btn.add-btn {
  color: #fff;
}
.blue-bg {
  background: #3497EC !important;
}
.bule-2 {
  color: #4296F7 !important;
}
img {
  max-width: 100%;
}
.icon {
 
  display: inline-block;
  flex-basis: 22px;
  min-width: 22px;
}
.font-semi-bold {
  font-weight: 600 !important;
}
.form-control.aud-input.xl {
  height: 50px;
  padding: 5px 8px;
  font-size: 17px;
  border-radius: 0px;
}
.form-control.aud-input {
  background-color: #fff;
  border-radius: 0px;
  color: #939292;
  border: #BABABA solid 1px;
}
.btn.btn-xl {
    line-height: 50px;
    padding: 0 14px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 4px;
    min-width: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/forgot-password/forgot-password.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,8BAA8B;AAClC;AACA;EACE,4BAA4B;EAC5B,qCAAqC;AACvC;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,eAAe;AACjB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;AACA;EACE,8BAA8B;AAChC;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,eAAe;AACjB;AACA;;EAEE,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,yBAAyB;AAC3B;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".px-40 {\n    padding-left: 40px !important;\n    padding-right: 40px !important;\n}\n.py-36 {\n  padding-top: 36px !important;\n  /* padding-bottom: 36px !important; */\n}\n.font-12 {\n  font-size: 12px !important;\n}\nh1, h2, h3, h4, h5, h6, p {\n  margin: 0 0 0 0;\n}\n.font-12 p {\n  font-size: 12px !important;\n}\n.btn.btn-xl {\n  line-height: 50px;\n  padding: 0 14px;\n  font-size: 15px;\n  font-weight: 700;\n  border-radius: 4px;\n  min-width: 100px;\n}\n.btn.add-btn {\n  color: #fff;\n}\n.blue-bg {\n  background: #3497EC !important;\n}\n.bule-2 {\n  color: #4296F7 !important;\n}\nimg {\n  max-width: 100%;\n}\n.icon {\n \n  display: inline-block;\n  flex-basis: 22px;\n  min-width: 22px;\n}\n.font-semi-bold {\n  font-weight: 600 !important;\n}\n.form-control.aud-input.xl {\n  height: 50px;\n  padding: 5px 8px;\n  font-size: 17px;\n  border-radius: 0px;\n}\n.form-control.aud-input {\n  background-color: #fff;\n  border-radius: 0px;\n  color: #939292;\n  border: #BABABA solid 1px;\n}\n.btn.btn-xl {\n    line-height: 50px;\n    padding: 0 14px;\n    font-size: 15px;\n    font-weight: 700;\n    border-radius: 4px;\n    min-width: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
