// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep p {
  margin-bottom: 0;
}

section {
  align-items: center;
  border: 1px solid rgb(178, 178, 178);
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 100%;
  padding: 2rem 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/intakes/tip-block/tip-block.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":["::ng-deep p {\n  margin-bottom: 0;\n}\n\nsection {\n  align-items: center;\n  border: 1px solid rgb(178, 178, 178);\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n  height: 100%;\n  padding: 2rem 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
