import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JiraService {
  private jiraUrl = '';

  constructor() {
    this.jiraUrl = environment.jiraURL;
  }

  /**
   * Returns the full JIRA Issue URL when provided an ID
   *
   * @param {String} ticketID The JIRA issue ID
   *
   * @return {String} The full JIRA issue URL
   */
  getTicketUrl(ticketID: string): string {
    return `${this.jiraUrl}/${ticketID}/`;
  }
}
