// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all {
  div {
    width: 8rem;
  }

  .btn {
    padding: 6px 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/buttons/export-button/export-button.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;EACb;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["@media all {\n  div {\n    width: 8rem;\n  }\n\n  .btn {\n    padding: 6px 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
