// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blue-text {
    color: #3597EC !important;
}
.button-group {
  margin-right: 6rem;
}
.dropdown-item {
  cursor: pointer;
  margin-bottom: unset;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.dropdown-item:hover {
  background-color: rgb(215, 215, 215);
}
.dropdown-item.disabled {
  cursor: not-allowed;
  color: grey;
}
.dropdown-menu.show {
  left: -105px;
  padding-bottom: 0;
  padding-top: 0;
}
.dropdown-menu > p {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.flex-100 {
  flex-basis: 100px;
  min-width: 100px;
}
.font-semi-bold {
  font-weight: 600 !important;
}
hr {
  margin-bottom: 0;
  margin-top: 0;
}
.sky-blue {
  background-color: #3597EC;
  color: white;
}
.teal {
  background-color: #00A89B;
  color: white;
}

.table td, .table th{
  vertical-align: middle !important;
}
.text-right {
    text-align: right !important;
}
.width-340 {
    width: 340px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/site/site-product/site-product.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,oBAAoB;EACpB,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,oCAAoC;AACtC;AACA;EACE,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,oBAAoB;EACpB,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,iCAAiC;AACnC;AACA;IACI,4BAA4B;AAChC;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".blue-text {\n    color: #3597EC !important;\n}\n.button-group {\n  margin-right: 6rem;\n}\n.dropdown-item {\n  cursor: pointer;\n  margin-bottom: unset;\n  padding-bottom: 0.5rem;\n  padding-top: 0.5rem;\n}\n.dropdown-item:hover {\n  background-color: rgb(215, 215, 215);\n}\n.dropdown-item.disabled {\n  cursor: not-allowed;\n  color: grey;\n}\n.dropdown-menu.show {\n  left: -105px;\n  padding-bottom: 0;\n  padding-top: 0;\n}\n.dropdown-menu > p {\n  padding-bottom: 1rem;\n  padding-top: 1rem;\n}\n.flex-100 {\n  flex-basis: 100px;\n  min-width: 100px;\n}\n.font-semi-bold {\n  font-weight: 600 !important;\n}\nhr {\n  margin-bottom: 0;\n  margin-top: 0;\n}\n.sky-blue {\n  background-color: #3597EC;\n  color: white;\n}\n.teal {\n  background-color: #00A89B;\n  color: white;\n}\n\n.table td, .table th{\n  vertical-align: middle !important;\n}\n.text-right {\n    text-align: right !important;\n}\n.width-340 {\n    width: 340px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
