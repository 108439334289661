import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent implements OnInit {
  @Input() placeholderText ? = 'Filter your results here...';
  @Input() searchButton = false;

  @Output() filterFunction = new EventEmitter<any>();
  @Output() searchFunction = new EventEmitter<any>();

  inputButtonCss = {
    'input-button': null,
    'input-buttons': null,
  };
  filterText = '';
  showFilterIcon = true;

  constructor() { }

  ngOnInit(): void {
    this.inputButtonCss['input-button'] = !this.searchButton;
    this.inputButtonCss['input-buttons'] = !this.inputButtonCss['input-button'];
  }

  /**
   * Clears the Input text field
   */
  clearInput(): void {
    this.filterText = '';

    this.filterFunction.emit('');
  }

  /**
   * Runs the filtering function attached to the input
   */
  filter(): void {
    this.filterFunction.emit(this.filterText);
  }

  /**
   * Runs the search function that was passed to the component
   */
  search(): void {
    if (this.searchFunction !== null) {
      this.searchFunction.emit();
    }
  }
}
