import { Component, OnInit, Input } from '@angular/core';
import { SiteService } from '../service/site.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '@services/http.service';
import { CancelReasonComponent } from '@shared/cancel-reason/cancel-reason.component';
import { AddTrialComponent } from '../add-trial/add-trial.component';
import { AddDiscountComponent } from '../add-discount/add-discount.component';
import { ModifySubscriptionComponent } from '../modify-subscription/modify-subscription.component';
import { AddSubscriptionComponent } from '../add-subscription/add-subscription.component';
import { ChangeProductComponent } from '../../billing/change-product/change-product.component';
import { Product } from '@shared/interfaces/billing';
import { BillingProduct } from '@shared/interfaces/interfaces';

@Component({
  selector: 'app-site-product',
  templateUrl: './site-product.component.html',
  styleUrls: ['./site-product.component.css'],
})
export class SiteProductComponent implements OnInit {
  @Input() selectedSiteId;
  @Input() corporatePartnerList;

  siteIsLock: boolean;
  currentRole;
  siteId;
  productSubsDetails;
  products = [];
  durationType: string = 'forever';
  monthlyDis;
  isCouponTypeAmount: boolean = true;
  isCouponApply: boolean = false;
  siteInfo;
  cancelAllCheck: boolean = true;
  DiscountType = [];

  constructor(
    private siteService: SiteService,
    public modalService: NgbModal,
    private httpService: HttpService,
    private router: Router,
    public toastrService: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
    })
  }

  ngOnInit() {
    this.siteIsLock = this.siteService.siteIsLock;
    this.siteId = this.selectedSiteId;

    this.getSiteInfo(this.siteId);
    this.getProductBySite();
  }

  addTrialIsEnabled(key: BillingProduct) {
    return (key.isSubscribedProduct) || (key.products[0].isDirect && !key.products[0].isSignia);
  }

  getSiteInfo(siteId) {
    this.siteService.getSiteInfo(siteId).subscribe((res) => {
      if (res) {
        this.siteInfo = res;
        this.siteIsLock = this.siteInfo.isLock;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getProductBySite() {
    let obj = {
      siteId: this.selectedSiteId,
      isCouponApply: this.isCouponApply,
      // isCouponTypeAmount: this.isCouponTypeAmount || true
    }
    this.siteService.getProductBySite(obj).subscribe((res) => {
      if (res) {
        this.products = res['result']['products'];
        this.productSubsDetails = res['subscriptionDetails'];
        this.DiscountType= res['DiscountType'];
        // this.isCouponTypeAmount = res['result'].isCouponTypeAmount;
        // this.monthlyDis = res['result'].products[0].discount;
        console.log(res, '==getProductBySite');
        //add a property to each product storing whether to disable checkbox

        if (this.products && this.products.length > 0) {
          this.products = this.products.map(product => {
            product.BillingSubscriptionIsDisabled = this.isBillingSubCheckboxDisabled(product)
            return product;
          });
        }
        _.forEach(this.products, (product) => {
          product.isSubscribedProduct = _.find(product.products, (item) => {
            return item.stripeSubscriptionStatus == 'Subscribed';
          });
        });
      }
    },
      err => {
        throw err;
      })
  };

  cancelSubscription(product?) {

    let body = {
      'SiteId': this.siteId,
      'ProductIdToBeUnSubscribed': product && product.productId ? product.productId : '',
      'IsCancelSubscription': product ? false : true
    };

    var model = this.modalService.open(CancelReasonComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.product = product; // Ensure product is correctly passed here
    // model.componentInstance.message = 'Are you sure you want to cancel subscription of this product?';
    model.result.then((cancelationBody) => {
      if (cancelationBody) {
          body['cancelationNoteId'] = cancelationBody['cancelationNoteId'];
          body['cancelationNote'] = cancelationBody['cancelationNote'];
          this.siteService.cancelSubscription(body).subscribe(res => {
            if (res) {
              this.toastrService.success('Subscription has been cancelled successfully', 'Success');
              this.getProductBySite();
            }
          }, err => {
            throw err;
          })


      }
      else {
        // this.toastrService.warning('Please enter valid reason', 'Alert');
        return false;
      }
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });

  }

  cancelAllSubscription() {
    this.cancelSubscription();
  }

  gotoList() {
    this.router.navigate([`${this.currentRole}/sites`]);
  }

  /**
   * Opens a modal to change a product attached to a subscription, then submits the changes to
   * the API
   *
   * @param {Product} product
   */
  openChangeProductModal(product: Product) {
    const modal: NgbModalRef = this.modalService.open(ChangeProductComponent, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
    });

    modal.componentInstance.currentProduct = product;
    modal.componentInstance.site = this.siteInfo;

    modal.result.then(
      (changeProductBody) => {},
      (dismissalReason: string) => {
        this.getProductBySite();
      }
    );
  }

  disapprove() {
    // this.activeModal.close();
  }
  downgrade(siteId) {
    this.router.navigate([`${this.currentRole}/downgrade-product/${siteId}`]);
  }

  callFromParent(site) {
    this.selectedSiteId = site.id;
    this.siteIsLock = site.isLock;
    this.ngOnInit();
  }

  openAddDiscountPopup(key) {
    var model = this.modalService.open(AddDiscountComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.result.then((selectedCoupon) => {
      if (selectedCoupon) {
        let body = {
          siteId: this.selectedSiteId,
          paymentType: key.paymentType,
          couponId: selectedCoupon.coupon
        };
        this.siteService.UpdateCouponOnSubscription(body).subscribe(res => {
          if (res) {
            this.toastrService.success('Coupon has been added successfully', 'Success');
            this.getProductBySite();
          }
        }, err => {
          this.httpService.openErrorPopup(err.error.message);
        })
      }
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }

  openAddSubscriptionPopup(siteId: number): void {
    const modal = this.modalService.open(
      AddSubscriptionComponent,
      {
        ariaLabelledBy: 'modal-basic-title',
        backdrop: 'static',
      }
    );

    modal.componentInstance.type = 'duration';
    modal.componentInstance.siteId = siteId;
    modal.componentInstance.existingSubscriptions = this.products;

    modal.result.then((modalData) => {
      console.log(modalData);
      if (modalData.value >= 0) {
        const body = {
          billingPeriod: 'monthly',
          startDate: new Date(),
          subscriptionId: 123,
        };

        console.log(body);
      } else {
        console.log('Modal empty');
      }
    }, (reason: string) => {});
  }

  openAddTrialPopup(key) {
    const model = this.modalService.open(
      AddTrialComponent, {
        ariaLabelledBy: 'modal-basic-title',
        backdrop: 'static'
      }
    );

    model.componentInstance.type = 'duration';
    model.result.then((modelData) => {
      if (modelData.value >= 0) {
        const body = {
          siteId: this.selectedSiteId,
          paymentType: key['paymentType'],
          trialDays: modelData.value,
          prorate: modelData.prorate
        };

        this.siteService.pauseSubscription(body).subscribe(res => {
          if (res) {
             this.toastrService.success('Trial has been added successfully ', 'Success');
            this.getProductBySite();
          }
        }, err => {
          throw err;
        })
      }
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }

  getDiscount() {
    this.isCouponApply = true;
    this.getProductBySite();
  }

  /**
   * @deprecated
   * @param {Product} product
   */
  openChangePricePopup(product) {
    const modal = this.modalService.open(
      ChangeProductComponent,
      {
        ariaLabelledBy: 'modal-basic-title',
        backdrop: 'static',
        size: 'lg',
      }
    );

    modal.componentInstance.siteId = this.selectedSiteId;
    modal.componentInstance.product = product;

    modal.result.then((result) => {
        console.log(result);
      },
      (dismissalReason: string) => console.log(dismissalReason)
    );
  }

  openModifySubscriptionPopup(product, paymentType) {
    var model = this.modalService.open(ModifySubscriptionComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.siteId = this.selectedSiteId;
    model.componentInstance.product = product;
    model.result.then((product) => {
      if (product) {
          let selectedPaymentType = _.find(this.products, (product) => {
            return product.paymentType == paymentType;
          })
          let body = {
            siteId: this.selectedSiteId,
            paymentType: paymentType,
            productId: product.productId,
            quantity: product.quantity,
            prorate: product.prorate,
            unitPrice: product.unitPrice,
            IsCouponTypeAmount: selectedPaymentType.isCouponTypeAmount
          }
        this.siteService.modifySubscription(body).subscribe(res => {
          if (res) {
            this.toastrService.success('Invoice has been created successfully', 'Success');
            this.getProductBySite();
          }
        }, err => {
          this.httpService.openErrorPopup(err.error.message);
        })
      }
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }

  /**
   * Triggers a subscription's billing period
   *
   * @param {Product} product The product to add to a subscription
   * @param {String} type The type of subscription/Billing period
   *
   * @return {Boolean}
   */
  startBilling(product, type: string): void|boolean {
    let selectedPaymentType = _.find(this.products, (product) => {
      return product.paymentType == type;
    })
    let subscribedProduct = _.find(selectedPaymentType.products, (item) => {
      return item.stripeSubscriptionStatus == 'Subscribed'
    })

    let body = {
      SiteId: this.selectedSiteId,
      IntakeId: product.productId?product.intakeId:0,
      // IntakeProductIdsToBeSubscribed: product.productId,
      IsCouponTypeAmount: selectedPaymentType.isCouponTypeAmount ,
      CouponDuration: this.durationType
    };
    let productIds = [];

    if (product.length) {
      _.forEach(product, (item) => {
        if (item.isSelected) {
          productIds.push(item.productId);
        }
      })
    }

    body['IntakeProductIdsToBeSubscribed'] = product.productId || productIds.toString();

    if (body['IntakeProductIdsToBeSubscribed'] == '') {
      this.toastrService.warning('Please Select atleast one product', 'Alert');
      return false;
    }

    if (subscribedProduct && !product.isUpgrade) {
      body['prorate'] = false;

      this.postStartBillingData(body);
    } else {
      const model = this.modalService.open(
        AddTrialComponent,
        { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }
      );

      model.componentInstance.type = 'billing';

      model.result.then((modelData) => {
        if (modelData.value >= 0) {
          body['TrialDays'] = modelData.value;
          body['prorate'] = modelData.prorate;

          this.postStartBillingData(body);
        }
      }, (reason) => {
        console.log(`Dismissed: ${reason}`);
      });
    }
  }

  startBillingIsEnabled(key: BillingProduct) {
    return key.products[0].isDirect && key.products[0].isSignia;
  }

  /**
   * Determines if a subscription can be cancelled
   *
   * @param {Product} product
   */
  subscriptionIsCancellable(product: Product) {
    return (product.intakeId !== 0 || product.intakeTypeStatus !== 0) &&
      (product.intakeTypeStatus <= 0 || product.intakeTypeId <= 0) &&
      (product.intakeTypeId !== 0);
  }

  postStartBillingData(body) {
    this.siteService.startBilling(body).subscribe((res) => {
      if (res) {
        this.toastrService.success('Billing Started', 'Success');
        // this.isCouponTypeAmount = true;
        this.isCouponApply = false;
        this.getProductBySite();
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  isBillingSubCheckboxDisabled(product){
    const isDisabled = ((product.intakeTypeStatus <= 0) && (product.intakeTypeId != 0) || (!product.isDirect && product.isSignia) || (product.stripeSubscriptionStatus == 'Subscribed'))
    return isDisabled;
  }
}
