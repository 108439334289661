import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
  Injectable
} from '@angular/core';
import {
  ColumnActions,
  ColumnDataTypes,
  ColumnHeader,
  PrimeNGFilterEvent,
} from '@shared/interfaces/tables';
import { Table } from 'primeng/table';
import { JiraService } from '@shared/services/jira/jira.service';
import { InvoiceService } from '@shared/services/invoice/invoice.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Calendar } from 'primeng/calendar';
import { InputGeneric } from '@shared/interfaces/forms';
import {
  FilterOption,
  TableRow,
} from '@shared/components/tables/table-generic/table-generic.interface';
import { NestedActionIconsDirective } from '@shared/components/tables/table-generic/table-generic.directives';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';
@Component({
  selector: 'app-table-generic',
  templateUrl: './table-generic.component.html',
  styleUrls: ['./table-generic.component.css'],
})
/**
 * @property {string} dataKey The primary key for the table row. If omitted, all rows will expand
 * @property {ColumnDataTypes} columnDataTypes Determines how column data is displayed in the table
 * @property {ColumnHeader[]} columnHeaders Configures the name of each column and other data
 * @property {Object} columnWidths The column widths for the table columns, in `key: percent` format
 * @property {Object[]} data The data to populate the table with
 * @property {Observable<Array>} dataObservable An observable containing the data to populate the
 *            table width. Use as an alternative to the the `data` array.
 * @property {string} fieldToSortBy The column that the table will automatically sort by on load
 * @property {string} nestedTableKey The key for the nested data to be passed into nested table.
 */
export class TableGenericComponent implements OnInit {
  @ContentChild(TemplateRef) actionIcons: TemplateRef<unknown>;
  @ContentChild(NestedActionIconsDirective) actionIconsNested: NestedActionIconsDirective;
  @ContentChild('customFilters', { static: false }) customFilters: TemplateRef<any>;

  @Input() audioSink ?: HTMLAudioElement;
  @Input() checkboxKeys: object;
  @Input() columnActions: ColumnActions|any;
  @Input() columnsAreSortable = true;
  @Input() columnDataTypes: ColumnDataTypes;
  @Input() columnHeaders: ColumnHeader[];
  @Input() columnWidths: { [columnName: string]: number } = {}
  @Input() data: TableRow[];
  @Input() dataObservable: Observable<any>;
  @Input() dataIsExportable = true;
  @Input() dataKey = 'parentIssueKey';
  @Input() extraOptions: {[key: string]: InputGeneric[]};
  @Input() exportFilename = 'table-export'
  @Input() fieldToSortBy: string;
  @Input() fieldToSortByOrder: 'asc' | 'desc' = 'desc';
  @Input() indexLabel = '#';
  @Input() numberOfActionIcons = 2;
  @Input() pagination = true;
  @Input() selectFilterFields = [];
  @Input() showActions = false;
  @Input() showNestedActions = false;
  @Input() showColumnToggle = true;
  @Input() showColumnFilters = true;
  @Input() showCustomFilters = true;
  @Input() showIndex = false;
  @Input() nestedTableColumnHeaders: ColumnHeader[];
  @Input() nestedTableDataTypes: ColumnDataTypes;
  @Input() nestedTableKey: string;
  @Input() nestedTableData: Array<object>;
  @Input() numberOfNestedActionIcons
  @Input() numberOfTableRows = 10;

  @Output() checkboxFunction = new EventEmitter<any>();
  @Output() nestedTableQuery = new EventEmitter<any>();
  @Output() filterChangeEvent = new EventEmitter<any>();

  @ViewChild('dt') table: Table

  @ViewChildren('calendars') calendars: QueryList<Calendar>;

  private activatedUrl: string;
  // In table-generic.component.ts
expandedRows: { [key: string]: boolean } = {};

  private audit = true;
  private selectData: Array<object>;

  audio = new Audio();
  audioLinks = {};
  checkboxItem: any;
  filterOptions: {[key: string]: Array<any>} = {};
  options: Array<{label: string, value: string}>;
  subtableVisible: false;
  tableLength = null;


  constructor(
    private activatedRoute: ActivatedRoute,
    private jiraService: JiraService,
    private invoiceService: InvoiceService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // Checks for audio element, which is required for audio buttons in tables
    if (!this.audioSink) {
      this.audioSink = new Audio();
    }

    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.activatedUrl = urlPath[urlPath.length - 1].path;
      localStorage.setItem('previousUrl', this.activatedUrl);
    });

    if (this.activatedRoute.snapshot.queryParamMap.get('audit')) {
      this.audit = JSON.parse(this.activatedRoute.snapshot.queryParamMap.get('audit'));
    }

    if (this.dataObservable) {
      this.dataObservable.subscribe(
        (data) => {
          this.data = data;
        },
        (error: Error) => {
          console.error(error.message);
        },
        () => {
          this.tableLength = this.data.length;
          this.ref.detectChanges();
        }
      );
    }

    if (this.showColumnFilters) {
      this.showCustomFilters = false;
    }

    if (this.showCustomFilters) {
      this.showColumnFilters = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data?.currentValue?.length) {
      this.tableLength = changes['data'].currentValue.length;

      // Populates filters. Moving this to an onChange keeps the filters from visually re-rendering
      for (let i = 0; i < this.columnHeaders.length; i++) {
        const column = this.columnHeaders[i];

        if (
          column.hasOwnProperty('filter') &&
          ['activeInactive', 'select'].includes(column.filter)
        ) {
          this.filterOptions[column.field as string] = this.getFilterOptionsByKey(column);
        }
      }
    }
  }

  get actionIconsAreCentered(): boolean {
    return this.actionColumnWidth <= 80;
  }

  /**
   * Returns the number of pages in a table
   *
   * @return {Number} The number of pages in a table
   */
  get numberOfPages(): number {
    if (this.tableLength % this.numberOfTableRows === 0) {
      return Math.floor(this.tableLength / this.numberOfTableRows);
    } else if (this.tableLength % this.numberOfTableRows > 0) {
      return Math.floor(this.tableLength / this.numberOfTableRows) + 1;
    }

    return 1;
  }

  /**
   * Returns the string with the number of pages for the paginator
   *
   * @return {String} The paginator's pagination message
   */
  get paginationMessage(): string {
    return `of ${this.numberOfPages.toString()}`;
  }

  get sortOrder(): number {
    if (this.fieldToSortByOrder === 'asc') {
      return 1;
    }

    return -1;
  }
  /**
   * Provides a getter that returns whether or not the table has received data.
   * @type {Boolean}
   */
  get tableHasData(): boolean {
    return this.data && this.data.length > 0;
  }

  /**
   * The pixel width of the action column
   *
   * @return {Number} The width, in pixels, of the action column.
   */
  get actionColumnWidth(): number {
    if (this.numberOfActionIcons > 3) {
      return this.numberOfActionIcons * 40;
    }

    return 80;
  }

  get nestedActionColumnWidth(): number {
    if (this.numberOfNestedActionIcons > 3) {
      return this.numberOfNestedActionIcons * 40;
    }

    return 80;
  }

  /**
   * The number of columns in the nested table
   *
   * @return {Number} The number of columns in the nested table
   */
  get numberOfNestedTableColumns(): number {
    let length = this.columnHeaders.length;

    if (this.showActions) {
      length++;
    }

    return length;
  }

  /**
   * The number of columns in the table
   *
   * @return {Number} The number of columns in the table
   */
  get numberOfTableColumns(): number {
    return this.numberOfNestedTableColumns;
  }

  /**
   * Click handler for checkbox column type
   */
  checkboxClick() {
    return this.checkboxFunction.emit();
  }

  /**
   * Clears all Filters for the table
   */
  clearFilters(): void {
    this.expandedRows = {};

    // This should iterate through all instances of the PrimeNG calendar and clear them
    this.calendars.forEach((calendar, index) => {
      calendar.onClearButtonClick(null);
    });

    if (this.table) {
      this.table.expandedRowKeys = {};

      this.table.clear();

      this.tableLength = this.data.length;
    }

    // if there are any filter change events, trigger them
    this.columnHeaders.forEach((colHeader) => {
      // if there is a filter on the column, check if there is a filter change event boolean and
      // that the event is passed in
      if (colHeader.filter && colHeader.hasFilterChangeEvent && this.filterChangeEvent) {
        this.filterChangeEvent.emit({event: null, field: colHeader.field});
      }
    });
  }

  /**
   * Checks if a column has a specification
   * @param {string} columnKey
   *
   * @return {Boolean}
   */
  columnHasSetType(columnKey: string): boolean {
    if (columnKey && this.columnDataTypes) {
      return columnKey && Object.keys(this.columnDataTypes).includes(columnKey);
    }

    return false;
  }

  executeColumnAction(rowData, key: string) {
    if (key && this.columnActions.hasOwnProperty(key)) {
      this.columnActions[key](rowData);
    } else {
      throw new Error('There is no key configured');
    }
  }

  expandNestedTable(rowData): void {
    const rowKey = rowData[this.dataKey];
    // Toggle the expanded state for the row
    this.expandedRows[rowKey] = !this.expandedRows[rowKey];
  
    if (this.expandedRows[rowKey] && this.nestedTableQuery) {
      this.nestedTableQuery.emit({ rowData });
    }
  }
  
  

  /**
   * Triggers the CSV export functionality
   */
  exportCSV(): void {
    this.table.exportCSV();
  }

  getColumnDatatype(columnKey: string): string {
    if (columnKey && this.columnHasSetType(columnKey)) {
      return this.columnDataTypes[columnKey];
    }

    return 'string';
  }

  /**
   * Returns all of the possible options for a select menu filter based on the column's key
   *
   * @param {String} column The columnHeader object
   *
   * @return {Array} The list filterable options
   */
  getFilterOptionsByKey(column: ColumnHeader): FilterOption[] {
    // ensure column info is passed in
    if (column) {
      let optionsWithDuplicates: InputGeneric[] = [];
      // if columnHeader is passed in with excludeColumnContentInFilter=true then don't add column
      // data to the optionsWithDuplicates list should only be used in conjunction with passing in
      // extraOptions for that column
      if (!column.excludeColumnContentInFilter) {
        optionsWithDuplicates = this.data.map(columnData => {
          return {
            text: columnData[column.field],
            value: columnData[column.field],
            disabled: false,
          } as InputGeneric;
        });
      }

      // add extra options to the options list for that column
      if (this.extraOptions && this.extraOptions.hasOwnProperty(column.field)) {
        for (let i = 0; i < this.extraOptions[column.field as string].length; i++) {
          optionsWithDuplicates.push(this.extraOptions[column.field as string][i]);
        }
      }

      const optionsUnsorted = optionsWithDuplicates.filter((value, index, self) =>
        index === self.findIndex((t) => (t.text === value.text && t.value === value.value))
      );

      const arrayOptions = optionsUnsorted.sort(function(a, b) {
        // Sort options on the text
        if (a.text < b.text) {
          return -1;
        }

        if (a.text < b.text) {
          return 1;
        }

        return 0;
      }).map((option) => ({
        label: option.text,
        value: option.value,
        disabled: option.disabled,
      }));

      arrayOptions.unshift({label: 'All Items', value: null, disabled: false});

      return arrayOptions;
    }

    return [{ label: 'No Items', value: null}];
  }

  getFilterPlaceholder(header: string): string {
    return `Filter by ${header}`;
  }

  getJiraUrl(keyId: string): string {
    return this.jiraService.getTicketUrl(keyId);
  }

  getNestedColumnDatatype(columnKey: string): string {
    if (columnKey && this.nestedColumnHasSetType(columnKey)) {
      return this.nestedTableDataTypes[columnKey];
    }

    return 'string';
  }

  /**
   * Determines if the chevron on the expandable rows are visible
   * @param {TableRow} rowData Row data passed from the table
   * @return {Boolean} Returns true if a chevron should be hidden
   */
  hideChevron(rowData: TableRow): boolean {
    return rowData[this.nestedTableKey]?.length == 0 && this.nestedTableQuery.observers.length == 0;
  }

  lastColumnColSpan(isLastColumn): number {
    if (this.nestedTableQuery && isLastColumn) {
      return (this.columnHeaders.length - this.nestedTableColumnHeaders.length) + 1;
    }

    return 1;
  }

  /**
   * Generates a link to the interal ticket view
   *
   * @param {String} ticketId The ID for the ticket, a string of numbers, as opposed the the code
   *
   * @return {String} The relative link to the Portal Service ticket
   */
  getPortalTicketLink(ticketId: string): string {
    // return `#/${this.activatedUrl}/ticket/${ticketId}?audit=${this.audit}`;
    return `#/${this.activatedUrl}/ticket/${ticketId}`;
  }

/**
   * Generates a link to the interal audit view
   *
   * @param {String} ticketId The ID for the ticket, a string of numbers, as opposed the the code
   *
   * @return {String} The relative link to the Portal Audit ticket
   */
getPortalAuditTicketLink(ticketId: string): string {
  return `#/${this.activatedUrl}/ticket/${ticketId}?audit=${this.audit}`;
}

  /**
   * Checks if a column has a specification
   * @param {string} columnKey
   *
   * @return {Boolean}
   */
  nestedColumnHasSetType(columnKey: string): boolean {
    if (columnKey && this.nestedTableDataTypes) {
      return columnKey && Object.keys(this.nestedTableDataTypes).includes(columnKey);
    }

    return false;
  }

  onDateSelect(value: Date | null, field: string) {
    if (value) {
      const selectedDate = moment(value).format('YYYY-MM-DD');
      this.table.filter(selectedDate, field, 'contains');
    } else {
      // Clear the filter
      this.table.filter(null, field, 'equals');
    }
  }

  /**
   * Event Handle for table filtering events
   *
   * @param {PrimeNGFilterEvent} event The filter event
   * @param {Table} table The table being filtered
   */
  onTableFilter(event: PrimeNGFilterEvent, table: Table) {
    this.tableLength = event.filteredValue.length;
  }

  playAudio(rowData: object, field: string, index: number, action): void {
    this.audio.pause();

    if (action == 'play') {
      this.data[index].audioIsPlaying = true;

      this.audio = new Audio();

      this.audio.src = rowData[field];

      this.audio.load();
      this.audio.play();
    } else {
      this.data[index].audioIsPlaying = false;

      this.audio.pause();
    }

    this.data[index].audioIsPlaying = !this.data[index].audioIsPlaying;
  }

  /**
   * Filters the table columns as needed. Includes the ability to remove filters for columns
   * @param {Event} event The select filter's on change event
   * @param {ColumnHeader} columnHeader The columnHeader object
   */
  selectMenuFilter(event: Event, columnHeader: ColumnHeader): void {
    const eventTarget = event.target as HTMLInputElement;
  
    // Ensure columnHeader.field is a string before proceeding
    if (typeof columnHeader.field === 'string') {
      if (columnHeader.hasFilterChangeEvent && this.filterChangeEvent) {
        this.filterChangeEvent.emit({event: event, field: columnHeader.field});
      }
  
      this.table.filter(
        eventTarget.value === 'null' ? '' : eventTarget.value,
        columnHeader.field,
        'equals'
      );
    } else {
      // Handle the case where columnHeader.field is not a string
      console.error('columnHeader.field is not a string', columnHeader.field);
    }
  }
  

  /**
   * Checks if a column should use a dropdown filter
   * @param {any} columnHeader - The column header object with the three attributes field, header, and filter
   * @return {boolean} - True if the column should use a dropdown filter, false otherwise
   */
  selectMenuDropdown(columnHeader: any): boolean {
    return columnHeader.filter && columnHeader.filter === 'select' && this.filterOptions[columnHeader.field]?.length > 0;
  }

  getInvoiceUrl(invoiceId: string):void{
      this.invoiceService.getStripeInvoiceUrl(invoiceId).subscribe(
        (response) =>{
          if (response.hosted_invoice_url)
              window.open(response.hosted_invoice_url, "_blank");
        }
      );
  }
}
