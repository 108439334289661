import { Component, OnInit, Input } from '@angular/core';
import { PaymentService } from '../payment/services/payment.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StripePaymentComponent } from '../payment/stripe-payment/stripe-payment.component';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '@services/http.service';
import { SiteService } from '../../site/service/site.service';
import {
  ColumnActions,
  ColumnDataTypes,
  ColumnHeader,
  PageHasTable,
} from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';
import { CardData } from '@shared/interfaces/billing';
import { NotificationsService } from '@shared/services/notifications/notifications.service';

@Component({
  selector: 'app-manage-card',
  templateUrl: './manage-card.component.html',
  styleUrls: ['./manage-card.component.css'],
})
export class ManageCardComponent implements OnInit, PageHasTable {
  @Input() selectedSiteId;

  columnActions: ColumnActions;
  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  table: TableGenericComponent
  siteIsLock;
  selectedSite;
  cardData: CardData[] = [];
  userInfo;
  loading = false;
  currentRole;

  constructor(
    private notificationsService: NotificationsService,
    private paymentService: PaymentService,
    private toastrService: ToastrService,
    public modalService: NgbModal,
    private httpService: HttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private SiteService: SiteService
  ) {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
    });
  }

  ngOnInit() {
    this.siteIsLock = this.SiteService.siteIsLock;

    this.userInfo = localStorage.getItem('userInfo') != null ?
      JSON.parse(localStorage.getItem('userInfo')) :
      { 'token': '', 'userId': null, 'roleId': null };

    this.selectedSite = JSON.parse(localStorage.getItem('selectedSite'));

    this.getCardList();
    this.getTableData();
  }

  /**
   * Retrieves the list of credit cards for the client
   *
   * @return {void}
   */
  getCardList(): void {
    this.paymentService
      .getStripeCards(this.selectedSiteId ? this.selectedSiteId: this.selectedSite.siteId)
      .subscribe(
        (res) => {
          if (res) {
            this.cardData = res;

            for (let i = 0; i < this.cardData.length; i++) {
              const {expMonth, expYear} = this.cardData[i];

              this.cardData[i]['expDate'] = `${expMonth} / ${expYear}`;
            }
          }
        },
        (err) => this.httpService.openErrorPopup(err.error.message)
      );
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnActions = {
      isDefaultCard: this.setCardAsDefault.bind(this),
    };

    this.columnDataTypes = {
      isDefaultCard: 'checkmarkClickable',
      last4: 'creditCardMask',
    };

    this.columnHeaders = [
      {field: 'brand', header: 'Card Type'},
      {field: 'last4', header: 'Card No.'},
      {field: 'expDate', header: 'Expires'},
      {field: 'isDefaultCard', header: 'Is Default'},
    ];
  }

  /**
   * Returns the correct message for the update card button
   *
   * @param {CardData} card The information for each credit card
   *
   * @return {String} The title message displayed for the update card icon
   */
  getUpdateCardMessage(card: CardData): string {
    if (this.cardData.length <= 1) {
      return 'Add another card to change the default card';
    } else {
      return 'Make Default Card';
    }
  }

  /**
   * Sets a given card as the default payment method
   *
   * @param {CardData} cardData Payment information, pulled from table, used to submit to the API
   */
  setCardAsDefault(cardData: CardData): void {
    if (confirm('Are you sure to make is this card as Default card')) {
      this.loading = true;

      this.paymentService.UpdateDefaultCard(cardData).subscribe(
        (res) => {
          if (res) {
            this.getCardList();

            this.toastrService.success('Card Updated successfully', 'Success');
          }
        },
      );
    }
  }

  deleteCard(card) {
    let msg=card.isDefaultCard?
            'Are you sure to delete Default card?':
            'Are you sure to delete this card?';
    if (confirm(msg)) {
      this.loading=true
      if (this.cardData.length == 1){
        this.httpService.openErrorPopup("Cannot delete. At least one card is required.");

      }
      else {
        this.paymentService.deleteCard({ siteId: this.selectedSiteId ? this.selectedSiteId: this.selectedSite.siteId, Id: card.cardID }).subscribe(res => {
          this.toastrService.success("Card Deleted successfully", 'Success')
          this.getCardList();
        });

      }

    }
  }
  addCard() {
    var data = {
      type: 'add'
    }
    this.openAddPopup(StripePaymentComponent, data);
  }
  updateCard(card,event){
      if(confirm('Are you sure to make is this card as Default card')){
        this.loading=true
        this.paymentService.UpdateDefaultCard(card).subscribe((res)=>{
          if(res){
            this.getCardList();
            this.toastrService.success('Card Updated successfully','Success');

          }
        },
        err=>{

          this.httpService.openErrorPopup(err.error.message);
        })
    }else{
       card.isDefaultCard = false;
       event.target.checked=false;
    }
  }
  editCardInfo(card) {
    this.openAddPopup(StripePaymentComponent, card);
  }
  openAddPopup(componentName, paymentInfo) {
    var model = this.modalService.open(componentName, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.paymentInfo = paymentInfo;
    model.componentInstance.selectedSiteId=this.selectedSiteId ? this.selectedSiteId: this.selectedSite.siteId;
    model.result.then((result) => {
      this.getCardList();
      console.log(`add success: ${result}`);
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });

  }

  callFromParent(site){
    this.selectedSiteId = site.id;
    this.siteIsLock = site.isLock;
    this.getCardList();
  }
}
