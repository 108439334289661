import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';

@Component({
  selector: 'app-bar-chart-horizontal',
  templateUrl: './bar-chart-horizontal.component.html',
  styleUrls: ['./bar-chart-horizontal.component.css']
})
export class BarChartHorizontalComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',  // 'y' for horizontal bar chart, 'x' for vertical
    scales: {
      x:{},
      y: { 
         min: 0
      }
    },
    plugins: {
      legend: {display:false},
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartColors = [{
    backgroundColor: '#83cefc'
  }]
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  @Input() data: ChartDataset[];
  @Input() labels;
  @Input() height;
  // data;
  constructor() { }

  ngOnInit() {
  }

}
