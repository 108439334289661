import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-service-box',
  templateUrl: './service-box.component.html',
  styleUrls: ['./service-box.component.css'],
})
export class ServiceBoxComponent implements OnInit {
  @Input() cssClass: string;
  @Input() displayName: string;
  @Input() intakeTypeId: number;
  @Input() isLive: string;
  @Input() taskStatus: number;
  @Output() manageFunction: EventEmitter<any> = new EventEmitter();
  @Output() reportsFunction: EventEmitter<any> = new EventEmitter();

  footerCss;

  constructor() {

  }

  ngOnInit(): void {
    this.footerCss = {
      'disabled': !this.isLive,
    };
  }

  intakeIsBasicPremiumOrEnhanced(intakeTypeId: number): boolean {
    return intakeTypeId === 1 || intakeTypeId === 2 || intakeTypeId === 3;
  }

  isReportsLink(): boolean {
    const isNotReportsLink = [
      'Email',
      'Domain',
      'Citation Clean Up',
      'Social Media Setup',
      'Logo',
      'Redesign',
    ].includes(this.displayName);

    return !isNotReportsLink;
  }

  eventManage(manageFunction?: any) {
    this.manageFunction.emit(manageFunction);
  }

  eventReports(reportsFunction?: any): void {
    this.reportsFunction.emit(reportsFunction);
  }
}
