import * as _ from 'lodash';
import { IntakesService } from '../../services/intakes.service';


export class Smm {
    date: string;
    website: string;
    email: string;
    fields: string;
    otherField: string;
    specialtyServices: string;
    otherSpecialtyServices: string;
    hearingAidManufacturers: string;
    otherHearingAidManufacturers: string;
    intakeId: number;


    constructor(info, intakeId) {
        this.intakeId = intakeId;
        if(info.date)
        this.date = info.date.getMonth()+1+"/"+info.date.getDate()+"/"+info.date.getFullYear();
        this.website = info.website;
        this.email = info.email;
        this.fields = info.fields ? this.getStringId(info.fields) : null;        
        this.specialtyServices = info.specialtyServices ? this.getStringId(info.specialtyServices) : null;        
        this.hearingAidManufacturers = info.hearingAidManufacturers ? this.getStringId(info.hearingAidManufacturers) : null;        
        this.otherField = this.getOtherValue(info.fields, 'otherField');
        this.otherSpecialtyServices = this.getOtherValue(info.specialtyServices, 'otherSpecialtyServices');
        this.otherHearingAidManufacturers = this.getOtherValue(info.hearingAidManufacturers, 'otherHearingAidManufacturers');
    }


    getStringId(data) {
        let filteredData = _.filter(data, (item) => {
            return item.checked;
        });
        filteredData = _.toString(_.map(filteredData, 'id'));
        return filteredData;
    }

    getOtherValue(data, key){
        let otherTradeAssoc = _.find(data, (item) => {
            return !_.isEmpty(item[key]) && item.checked && item.name.toLowerCase() == 'other';
        })
        return otherTradeAssoc ? otherTradeAssoc[key] : '';
    }

}

