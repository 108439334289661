import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { RouterModule } from '@angular/router';
import {  BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AudChartsModule } from '@shared/charts/charts.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddInsightDataComponent } from './add-insight-data/add-insight-data.component';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


@NgModule({
    imports: [
        CommonModule,
        CKEditorModule,
        FormsModule,
        ReactiveFormsModule,
        AngularEditorModule,
        AudChartsModule,
        RouterModule,
        BsDatepickerModule.forRoot(),
        TableModule,
        DropdownModule,
        NgbModule,
        PaginatorModule,
    ],
    declarations: [AddInsightDataComponent],
    exports: []
})

export class ReportingModule { }
