// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seperator{    
    border-bottom: #e6e6e6 solid 6px;
    padding-bottom: 15px;
    margin-bottom: 20px;}
    .has-danger{
        border:1px solid red;
    }`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/call-tracking/call-tracking.component.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,oBAAoB;IACpB,mBAAmB,CAAC;IACpB;QACI,oBAAoB;IACxB","sourcesContent":[".seperator{    \n    border-bottom: #e6e6e6 solid 6px;\n    padding-bottom: 15px;\n    margin-bottom: 20px;}\n    .has-danger{\n        border:1px solid red;\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
