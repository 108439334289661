import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import { DataService } from '@shared/data-service.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { Homepage } from '../models/homepage';
import { HttpService } from '../../../../../../../services/http.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-content-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class ContentHomepageComponent implements OnInit {
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
      'link', 'unlink', '|',
      'bulletedList', 'numberedList', '|',
      'alignment', '|',
      'blockQuote', 'codeBlock', '|',
      'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', '|',
      'imageUpload', 'mediaEmbed', '|',
      'specialCharacters', '|',
      'horizontalLine', 'pageBreak', '|',
      'undo', 'redo', '|',
      'removeFormat', 'findAndReplace', 'sourceEditing'
    ],
    image: {
      toolbar: [
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        '|',
        'toggleImageCaption',
        'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    }
  };
  @Input() selectedItem;
  @Input() contentMenu;
  @Input() siteId;
  @Input() intakeTypeId;
  intakeId;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() changeStatus = new EventEmitter<any>();
  savedPages = [];
  editedPageId;
  updatedData;
  contentSectionData;
  elementData;
  specialServiceData;
  elementCount = 0;
  csCount = 0;
  existingData;
  contentHomepageForm: UntypedFormGroup;
  ssOther;
  elOther;
  elementStatus: string;
  contentStatus: string;
  specialServiceStatus: string;

  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private httpService: HttpService,
    public dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef  // Inject ChangeDetectorRef
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
      }
    });
  }

  ngOnInit() {
    this.initializeForm();
    this.getContentSectionData();
    this.getElementData();
    this.getSpecialServiceData();
    this.getHomepageData(this.intakeId);
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();  // Detect changes after view initialization
  }

  getHomepageData(intakeId) {
    this.intakesService.getHomepageData(intakeId).subscribe((res) => {
      if (res) {
        this.existingData = res;
        this.setContentSectionControl();
        this.setElementControl();
        this.setPromotionControl();
        this.setStatus();
        this.cdRef.detectChanges();  // Detect changes after data load
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  setStatus() {
    this.elementStatus = this.existingData.element != '' ? 'completed ' : 'active';
    this.contentStatus = this.existingData.intakeBasicContents.length > 0 ? 'completed ' : 'active';
    this.specialServiceStatus = this.existingData.websitePromotion != '' ? 'completed ' : 'active';
  }

  initializeForm() {
    this.contentHomepageForm = this.formBuilder.group({
      otherWebsitePromotion: new UntypedFormControl(this.existingData ? this.existingData.otherWebsitePromotion : ''),
      lwebsitePromotion: this.formBuilder.array(this.specialServiceData ? this.specialServiceData.map(c => this.formBuilder.group({
        checked: new UntypedFormControl(false)
      })) : []),
      lElement: this.formBuilder.array(this.elementData ? this.elementData.map(c => this.formBuilder.group({
        checked: new UntypedFormControl(false)
      })) : []),
      intakeBasicContents: this.formBuilder.array(this.contentSectionData ? this.contentSectionData.map(c => this.formBuilder.group({
        checked: new UntypedFormControl(false)
      })) : [])
    });
  }

  getSpecialServiceData() {
    this.intakesService.getSmmSpecialServiceData().subscribe((res) => {
      if (res) {
        this.specialServiceData = res['masterList'];
        _.forEach(this.specialServiceData, (service) => {
          if (service.name.includes('{xx}') || service.name.toLowerCase() == 'other') {
            service['textbox'] = '';
            service.tempName = service.name.replace(/{xx}/g, "");
          }
        });
        this.ssOther = _.find(this.specialServiceData, (service) => {
          return service.name.toLowerCase() == 'other';
        });
        this.setPromotionControl();
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  getContentSectionData() {
    this.intakesService.getContentSectionData().subscribe((res) => {
      if (res) {
        this.contentSectionData = res['masterList'];
        this.setContentSectionControl();
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  getElementData() {
    this.intakesService.getElementData().subscribe((res) => {
      if (res) {
        this.elementData = res['masterList'];
        this.elOther = _.find(this.elementData, (service) => {
          return service.name.toLowerCase() == 'video';
        });
        this.setElementControl();
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  onInput(event: any) {
    const inputElement = event.target;
    let inputValue = inputElement.value;
    inputValue = inputValue.replace(/[^0-9,.]|(\.(?=.*\.))/g, '');
    inputElement.value = inputValue;
  }

  setPromotionControl() {
    this.contentHomepageForm.setControl('lwebsitePromotion', this.formBuilder.array(this.specialServiceData.map(c => this.formBuilder.group({
      checked: new UntypedFormControl(this.getExistingData('lwebsitePromotion', 'checked', c)),
      name: new UntypedFormControl(c.name),
      tempName: new UntypedFormControl(c.tempName ? c.tempName : c.name),
      textbox: new UntypedFormControl(this.getExistingData('lwebsitePromotion', 'textbox', c, 'otherWebsitePromotion', 'other') || c.textbox),
      id: new UntypedFormControl(c.id),
      masterTypeId: new UntypedFormControl(c.masterTypeId),
      description: new UntypedFormControl(c.description)
    }))));
    this.cdRef.detectChanges();  // Detect changes after setting control
  }

  setContentSectionControl() {
    this.contentHomepageForm.setControl('intakeBasicContents', this.formBuilder.array(this.contentSectionData.map(c => this.formBuilder.group({
      intakeId: this.intakeId,
      checked: new UntypedFormControl(this.getExistingData('intakeBasicContents', 'checked', c)),
      name: new UntypedFormControl(c.name),
      title: new UntypedFormControl(this.getExistingData('intakeBasicContents', 'title', c) || ''),
      content: new UntypedFormControl(this.getExistingData('intakeBasicContents', 'content', c) || ''),
      id: new UntypedFormControl(c.id)
    }))));
    this.checkCsLimit(this.contentHomepageForm.controls.intakeBasicContents['controls']);
    this.cdRef.detectChanges();  // Detect changes after setting control
  }

  setElementControl() {
    this.contentHomepageForm.setControl('lElement', this.formBuilder.array(this.elementData.map(c => this.formBuilder.group({
      checked: new UntypedFormControl(this.getExistingData('lElement', 'checked', c)),
      textbox: new UntypedFormControl(this.getExistingData('lElement', 'textbox', c, 'hpElementVideo', 'video') || c.textbox),
      name: new UntypedFormControl(c.name),
      id: new UntypedFormControl(c.id)
    }))));
    this.checkLimit(this.contentHomepageForm.controls.lElement['controls']);
    this.cdRef.detectChanges();  // Detect changes after setting control
  }

  getExistingData(control, field, value, otherVal?, element?) {
    if (this.existingData) {
      let data = _.find(this.existingData[control], (item) => {
        return item.id == value.id;
      });
      if (data) {
        if (field == 'checked') {
          let checked = true;
          return checked;
        } else if (field == 'textbox') {
          data.textbox = data.name.toLowerCase() == element && this.existingData[otherVal] ? `{${this.existingData[otherVal]}}` : data.textbox;
          return data.textbox ? data.textbox.substring(data.textbox.indexOf("{") + 1, data.textbox.indexOf("}")) : data.name.substring(data.name.indexOf("{") + 1, data.name.indexOf("}")) || data.textbox;
        } else if (field == 'title') {
          return data.title;
        } else if (field == 'content') {
          return data.content;
        }
      }
    }
  }

  checkLimit(sectionData) {
    this.elementCount = 0;
    _.forEach(sectionData, (data) => {
      if (data.value.checked) {
        if (this.elOther && data.value.id == this.elOther.id) {
          data.get('textbox').setValidators([Validators.required, Validators.pattern(this.dataService.urlRegex)]);
          data.get('textbox').updateValueAndValidity();
        }
        this.elementCount++;
      } else {
        data.get('textbox').clearValidators();
        data.get('textbox').setValue('');
        data.get('textbox').updateValueAndValidity();
      }
    });
    this.cdRef.detectChanges();  // Detect changes after checking limit
  }

  checkCsLimit(sectionData) {
    this.csCount = 0;
    _.forEach(sectionData, (data) => {
      if (data.value.checked) {
        this.csCount++;
      } else {
        data.controls.title.clearValidators();
        data.controls.title.updateValueAndValidity();
        data.controls.content.clearValidators();
        data.controls.content.updateValueAndValidity();
      }
    });
    this.cdRef.detectChanges();  // Detect changes after checking limit
  }

  isFieldValid(field: string, index: number = 0) {
    let control = this.contentHomepageForm.controls['lwebsitePromotion'];
    let controlContent = this.contentHomepageForm.controls['intakeBasicContents'];

    if (field == 'textbox') {
      for (let i = index; i < control['controls'].length; i++) {
        return !control['controls'][i].controls.textbox.valid && control['controls'][i].controls.textbox.touched;
      }
    } else if (field == 'title') {
      for (let i = index; i < controlContent['controls'].length; i++) {
        return !controlContent['controls'][i].controls.title.valid && controlContent['controls'][i].controls.title.touched;
      }
    } else if (field == 'content') {
      for (let i = index; i < controlContent['controls'].length; i++) {
        return !controlContent['controls'][i].controls.content.valid && controlContent['controls'][i].controls.content.touched;
      }
    } else {
      return !this.contentHomepageForm.get(field).valid && this.contentHomepageForm.get(field).touched;
    }
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray) {
        for (let i = 0; i < control.controls.length; i++) {
          this.validateAllFormFields(control.controls[i]);
        }
      }
    });
  }

  saveFormData(form, mode?) {
    if (this.contentHomepageForm.valid) {
      let control = this.contentHomepageForm.get('intakeBasicContents');
      for (let i = 0; i < control['controls'].length; i++) {
        if (!control['controls'][i].controls.checked.value) {
          control['controls'][i].controls.content.setValue('');
          control['controls'][i].controls.title.setValue('');
        }
      }
      let selectedContentMenu = _.find(this.contentMenu, (menu) => { return menu.name === this.selectedItem.name });
      let contentSectionData = new Homepage(form.value, this.intakeId);
      this.intakesService.postContentHomepage(contentSectionData).subscribe((res) => {
        if (res) {
          this.toastrService.success("Content Saved", 'Success');
          let sectionStatus = {
            intakeId: this.intakeId,
            lstIntakeSection: [{
              productSectionId: selectedContentMenu.id,
              status: 2,
              percentage: selectedContentMenu.percentage
            }],
            IsContentSection: true
          };
          this.postIntakeSection(sectionStatus);
          if (mode == 'continue') {
            this.goToNext('parent');
          }
        }
      },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
    } else {
      this.validateAllFormFields(this.contentHomepageForm);
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage, isSection: true });
      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    });
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  goBack() {
    this.backClicked.next();
  }

  goToNext(menu) {
    this.continueClicked.next(menu);
  }

  validateListField(selectedInput, textField, list) {
    if (selectedInput) {
      let checkedField = _.filter(this.contentHomepageForm.controls[list]['controls'], (item) => { return item.value.checked && item.value.textbox != null });
      if (checkedField) {
        _.forEach(checkedField, (field) => {
          field.get(textField).setValidators([Validators.required]);
          field.get(textField).updateValueAndValidity();
        });
      }
    } else {
      let checkedField = _.filter(this.contentHomepageForm.controls[list]['controls'], (item) => { return !item.value.checked && item.value.textbox != null });
      if (checkedField) {
        _.forEach(checkedField, (field) => {
          field.get(textField).clearValidators();
          field.get(textField).setValue('');
          field.get(textField).updateValueAndValidity();
        });
      }
    }
  }

  checkWordLimit(control, limit) {
    if (control.value.match(/\S+/g).length > limit) {
      let string = control.value.match(/\S+/g).splice(0, limit).join(' ');
      control.setValue(string);
    }
  }
}
