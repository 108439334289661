import { Component, OnInit } from "@angular/core";
import { SiteService } from "../../service/site.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HttpService } from "../../../../services/http.service";
import { roles } from "@shared/master-enum.enum";
import { ConfirmModalComponent } from "../../../../shared/confirm-modal/confirm-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddCallTrackingComponent } from "../site-call-tracking/add-call-tracking/add-call-tracking.component";
import {
  ColumnDataTypes,
  ColumnHeader,
  PageHasTable,
} from "@shared/interfaces/tables";
import { CallTrackingInfo } from "@shared/interfaces/call_tracking";
import { PhonePipe } from "@pipes/phone/phone.pipe";

@Component({
  selector: "app-site-call-tracking",
  templateUrl: "./site-call-tracking.component.html",
  styleUrls: ["./site-call-tracking.component.css"],
})
export class SiteCallTrackingComponent implements OnInit, PageHasTable {
  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  roles = roles;
  userInfo =
    localStorage.getItem("userInfo") != null
      ? JSON.parse(localStorage.getItem("userInfo"))
      : { token: "", userId: null, roleId: null };
  callTrackingInfo: CallTrackingInfo[];
  siteId: string;
  familyId;
  setup = true;

  constructor(
    private siteService: SiteService,
    private toastrService: ToastrService,
    private router: Router,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private phonePipe: PhonePipe
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.siteId = params.siteId;
      this.familyId = params.familyId;
      if (params.siteId) {
        this.getCallTracking(params.siteId);
      }
    });

    this.getTableData();
  }

  /**
   * Retrieves the call tracking information from the API
   * @param {Number} siteId The ID of the site with the call tracking records you are querying
   */
  getCallTracking(siteId: string): void {
    this.siteService.getCallTracking(siteId).subscribe(
      (res: CallTrackingInfo[]) => {
        if (res) {
          // Use the PhonePipe to format targetNumber and trackNumber
          this.callTrackingInfo = res.map((item) => ({
            ...item,
            targetNumber: this.phonePipe.transform(item.targetNumber),
            trackNumber: this.phonePipe.transform(item.trackNumber),
          })) as CallTrackingInfo[]; // Cast to the correct type
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      isReplacementNotRequired: "checkmarkOrCross",
      isActive: "checkmarkOrCross",
    };

    this.columnHeaders = [
      { field: "customerCode", header: "Code" },
      { field: "customerName", header: "Location Name" },
      { field: "targetNumber", header: "Target Number" },
      { field: "trackNumber", header: "Tracking Number" },
      { field: "adSource", header: "AdSource" },
      {
        field: "isReplacementNotRequired",
        header: "Requires Manual Replacement",
      },
      { field: "isActive", header: "Is Active", width: 7 },
    ];
  }

  postSetup(siteId) {
    this.siteService.postSetup(siteId).subscribe(
      (res) => {
        if (res) {
          this.getCallTracking(siteId);
          this.toastrService.success(res.status, "Success");
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  saveProvisionInfo(siteId) {
    this.siteService.SaveProvisionInfo(siteId).subscribe(
      (res) => {
        if (res) {
          this.getCallTracking(siteId);
          this.toastrService.success(res.status, "Success");
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }
  UpdateCallsourceNumber() {
    this.siteService.UpdateCallsourceNumber(this.siteId).subscribe(
      (res) => {
        if (res) {
          this.toastrService.success(res["status"], "success");
        }
      },
      (err) => {
        this.toastrService.error(err, "Error");
      }
    );
  }
  addCalltracking() {
    var model = this.modalService.open(AddCallTrackingComponent, {
      ariaLabelledBy: "modal-basic-title",
      backdrop: "static",
    });
    model.componentInstance.siteId = this.siteId;
    model.result.then(
      (result) => {
        setTimeout(() => {
          this.getCallTracking(this.siteId);
        }, 50);
        console.log(`Update success: ${result}`);
      },
      (reason) => {
        console.log(`Dismissed: ${reason}`);
      }
    );
  }

  goToEdit(location) {
    var model = this.modalService.open(AddCallTrackingComponent, {
      ariaLabelledBy: "modal-basic-title",
      backdrop: "static",
    });
    model.componentInstance.siteId = this.siteId;
    model.componentInstance.existingData = location;
    model.result.then(
      (result) => {
        setTimeout(() => {
          this.getCallTracking(this.siteId);
        }, 50);
        console.log(`Update success: ${result}`);
      },
      (reason) => {
        console.log(`Dismissed: ${reason}`);
      }
    );
  }
  deleteCallTracking(location) {
    console.log(location, "==selected location");
    var model = this.modalService.open(ConfirmModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      backdrop: "static",
    });
    model.componentInstance.message = "Are you sure you want to delete?";
    model.result.then(
      (result) => {
        if (result) {
          this.siteService
            .deleteCallTracking(location.id, this.siteId)
            .subscribe(
              (res) => {
                if (res) {
                  this.getCallTracking(this.siteId);
                  this.toastrService.success("Deleted successfully", "success");
                }
              },
              (err) => {
                this.toastrService.error(err, "Error");
              }
            );
        }
      },
      (reason) => {
        console.log(`Dismissed: ${reason}`);
      }
    );
  }
}
