import { Component, OnInit } from '@angular/core';
import { AmServicesService } from '../services/am-services.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { TicketService } from '../../../components/ticket/service/ticket.service';
import moment from 'moment';
import { HttpService } from '../../../services/http.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class AmDashboardComponent implements OnInit {
  userInfo;
  notifications;
  dashboardData;
  jiraUrl;
  pageNo = 1;
  showMore;
  constructor(
    private amServicesService: AmServicesService,
    private toastrService: ToastrService,
    private ticketService: TicketService,
    private httpService: HttpService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getJiraUrl();
    this.getDashboardData();
    this.getNotifications(0, this.pageNo);
  }

  getDashboardData() {
    this.amServicesService.getDasboardData(this.userInfo).subscribe(res => {
      if (res) {
        this.dashboardData = res[0];
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  getNotifications(Siteid, pageNo) {
    this.amServicesService.getNotifications(Siteid, pageNo).subscribe((res) => {
      if (res) {
        this.pageNo = pageNo;
        this.notifications = res;
        this.showMore = this.notifications.length < (pageNo * 10) ? false : true;
      }
    },
      err => {
        throw err;
      });
  }

  gotoNewClients(){
    let params = { isNew: true, isActive: true };
    this.router.navigate(['account-manager/sites'], { queryParams: params });
  }

  gotoCustomer(isLive?, productType?, isNew?) {
    let params = {};
    //params = { dt: 1, startDate: moment().subtract(7, 'days').format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY') };
    params = { isLive: isLive || false, product_type: productType || null, isNew: isNew  };
    this.router.navigate(['account-manager/sites'], { queryParams: params });
  }

  gotopaymentHistory() {
    this.router.navigate(['account-manager/billing'], { queryParams: { status: -1, startDate: moment().startOf('month').format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY'), productId: 1 } });
  }

  gotoprojects() {
    let params = { status: 0 };
    this.router.navigate(['account-manager/project'], { queryParams: params });
  }

  gotoIntakes(params) {
    this.router.navigate(['account-manager/intake-details'], { queryParams: params });
  }
  gotoCompletedProjects() {
    this.router.navigate(['account-manager/completed-projects']);
  }

  getJiraUrl() {
    this.ticketService.getJiraUrl().subscribe(res => {
      if (res) {
        this.jiraUrl = res['url'];
      }
    },
      err => {
        throw err;

      })
  }

}
