import { actions, ActionType, mappings } from '@shared/policies/mappings';

/**
 * Checks permissions map to see if a user role has a provide capability
 * @param {Number} roleId The User Role ID
 * @param {ActionType} action One of the various capabilities in the portal
 *
 * @see https://css-tricks.com/handling-user-permissions-in-javascript/
 *
 * @return {Boolean} True, if a user role can perform an action
 */
const hasPermission = function(roleId: number, action: keyof typeof actions): boolean {
  if (typeof roleId === 'string') {
    roleId = Number(roleId);
  }

  if (mappings.has(action)) {
    return mappings.get(action).includes(roleId);
  }

  return false;
};

export { hasPermission };
