import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap } from 'rxjs/operators';
import {Master} from '../masterTypes/models/master'
@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(
    private httpService: HttpService
  ) { }

  getAllMasters(): Observable<any> {
    return this.httpService.getApi('master')
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }
  getMastersListById(id): Observable<any> {
    return this.httpService.getApi(`master/${id}`)
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }
  delete(id) {
     return this.httpService.deleteApi(`master/${id}`).pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
     )};
     addMaster(MasterData): Observable<Master[]> {
      return this.httpService.postApi('master', MasterData, '')
      .pipe(
        tap(res => {
          if(res){

            return res;
          }

      })
    );
    }
    updateMaster(masterData): Observable<Master[]> {
      return this.httpService.putApi(`master`, masterData, '')
      .pipe(
        tap(res => {
          if(res){

            return res;
          }
      })
    );
    }
    getGeneralMasterByType(type): Observable<any> {
      return this.httpService.getApi(`master/General/${type}`)
      .pipe(
        tap(res => {
          if(res){

            return res;
          }

      })
    );
    }
    getSowDataByType(type, id?): Observable<any> {
      return this.httpService.getApi(`intake/Statement/${type}?id=${id || 0}`)
      .pipe(
        tap(res => {
          if(res){

            return res;
          }

      })
    );
    }

    saveSowTac(data): Observable<Master[]> {
      return this.httpService.postApi('intake/Statement', data, '')
      .pipe(
        tap(res => {
          if(res){

            return res;
          }

      })
    );
    }
  }
