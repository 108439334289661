import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports-heading',
  templateUrl: './reports-heading.component.html',
  styleUrls: ['./reports-heading.component.css']
})
export class ReportsHeadingComponent implements OnInit {
  @Input() isCustomer: boolean;
  @Input() roleId: number;

  constructor() { }

  ngOnInit(): void {
  }

}
