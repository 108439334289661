import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { AddProductModalComponent } from './add-product-modal/add-product-modal.component';
import { ChangeProductComponent } from './change-product/change-product.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TabComponent } from './tab/tab.component';
import { ManageLocationsComponent } from './manage-locations/manage-locations.component';
import { ManageLocationsFormComponent } from './manage-locations-form/manage-locations-form.component';
import { ManageLocationsEditFormComponent } from './manage-locations-edit-form/manage-locations-edit-form.component';

@NgModule({
  declarations: [
    AddProductModalComponent,
    ChangeProductComponent,
    TabComponent,
    ManageLocationsComponent,
    ManageLocationsFormComponent,
    ManageLocationsEditFormComponent,
  ],
  exports: [
    AddProductModalComponent,
    ManageLocationsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
  ],
})
export class BillingModule { }
