import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { socialMedia } from '@shared/master-enum.enum';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { SocialMedia } from './social-media-model';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { SectionStatus } from '../../review/section-model';
import { HttpService } from '../../../../../../../services/http.service';
import { DataService } from '@shared/data-service.service';
@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {
  @Input() data;
  @Input() sectionId;
  @Input() intakeTypeId;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();  
  @Output() changeStatus=new EventEmitter<any>();
  socialMediaData;
  intakeId;
  @Input() existingData;
  socialMediaForm: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private intakesService: IntakesService,
    private activatedRoute: ActivatedRoute,
    private elementRef:ElementRef,
    private httpService: HttpService,
    private toastrService: ToastrService,
    public dataService:DataService
  ) {
    this.socialMediaData = socialMedia;
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
      }
    });
  }

  ngOnInit() {
    this.data = this.intakesService.basicInfo['socialMedia'] ? this.intakesService.basicInfo['socialMedia'] : this.existingData;
    this.initializeForm();
  }
  initializeForm() {
    this.socialMediaForm = this.formBuilder.group({
      // intakeId: this.intakeId ? this.intakeId : null,
      socialMedia: this.formBuilder.array(this.socialMediaData.map(c => this.formBuilder.group({
        checked: new UntypedFormControl(this.getExistingFormData(c.name, 'checked') || this.getSocialMediaUrl(c.name, 'checked')),
        name: new UntypedFormControl(c.name),
        url: new UntypedFormControl(this.getExistingFormData(c.name, 'url') || this.getSocialMediaUrl(c.name, 'url'), Validators.pattern(this.dataService.urlRegex))
      }))),
      noSocialMediaAccount: new UntypedFormControl(this.data ? (this.data.isSocialMediaAccount || this.data.noSocialMediaAccount) : null),
      noThridPartySite: new UntypedFormControl(this.data.isThridPartySite),
      sisterPractice: new UntypedFormGroup({
        isSisterPractice: new UntypedFormControl(this.data.sisterPracticeUrl==null||this.data.sisterPracticeUrl==undefined?false:true),
        sisterPracticeUrl: new UntypedFormControl(this.data.sisterPracticeUrl)
      }),
      patientPortal: new UntypedFormGroup({
        isPatientPortal: new UntypedFormControl(this.data.patientPortalUrl==null||this.data.patientPortalUrl==undefined?false:true),
        patientPortalUrl: new UntypedFormControl(this.data.patientPortalUrl)
      }),
      reviewService: new UntypedFormGroup({
        isReviewService: new UntypedFormControl(this.data.reviewServiceUrl==null||this.data.reviewServiceUrl==undefined?false:true),
        reviewServiceUrl: new UntypedFormControl(this.data.reviewServiceUrl)
      }),

    })
  }

  getExistingFormData(mediaName, control) {
    
    if (this.data) {
      let socialMedia = _.find(this.data.socialMedia, (media) => {
        return media.name == mediaName;
      });
      if (socialMedia) {
        if (control == 'url') {
          return socialMedia.url;
        } else {
          return socialMedia.checked;
        }
      }
    }

  }

  getSocialMediaUrl(mediaName, control) {
    let name = _.camelCase(mediaName);
    if(name=='linkedIn'){
      name='linkedin';
    }else if(name=='youTube'){
      name='youtube';
    }else if(name=='reviewServiceExHealthyHearingZocDoc'){
        name='reviewService'
    }
    for (let property in this.data) {
      if (property.includes(name) && property.includes('Url')) {
        if (!_.isEmpty(this.data[property])) {
          if (control == 'url') {
            return this.data[property];
          } else {
            return true;
          }
        }
      }
    }
  }

  ngOnDestroy() {
  }


  isFieldValid(field: string,index:number =0) {
    
    let control=this.socialMediaForm.get('socialMedia');
     if(field=='url'){
       for (let i=index;i<control['controls'].length;i++){ 
         return !control['controls'][i].controls.url.valid && control['controls'][i].controls.url.touched; 
       }
     }else{
      return !this.socialMediaForm.get(field).valid && this.socialMediaForm.get(field).touched;
    }
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray){
        for (let i=0;i<control.controls.length;i++){
          this.validateAllFormFields(control.controls[i]);
        }
      }
    });
  }



  saveFormData(form, mode?) {

    if(this.socialMediaForm.valid){

      console.log('valid form submitted');
      
      this.intakesService.basicInfo['socialMedia'] = form.value;
      let intakeData = new SocialMedia(this.intakesService.basicInfo, this.intakeId);
      intakeData.isSocialMediaAccount=form.value.noSocialMediaAccount;
     _.assign(this.intakesService.commonBasicInfoObj, intakeData);
   
    this.intakesService.saveIntake(this.intakesService.commonBasicInfoObj).subscribe(res => {
      if (res) {
        this.changeStatus.next();
        if(mode == 'continue' || mode == 'submit'){
          this.toastrService.success(this.dataService.successMsg, 'Success');  
        }
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);        
        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, intakeData.intakeId);
      this.postIntakeSection(sectionStatus);
      if(mode == 'continue'){
        this.goToNext();
      }
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
    }else{
      console.log('invalid form submitted');
      this.validateAllFormFields(this.socialMediaForm);
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
 
  }

  getProductSectionData(familyId, intakeId){
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
       this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({basicMenu:this.intakesService.basicMenu,overAllPercentage:res.OverAllPercentage});
      
      }else{
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus){
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res)=>{
      if(res){
        this.getProductSectionData(this.intakeTypeId, this.intakeId); 
      }
    },
    err=>{
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  goBack() {
    this.backClicked.next();
  }

  goToNext(){
    this.continueClicked.next();
  }
  validateListField(selectedInput, textField, list) {
    if (selectedInput) {
      let checkedField = _.filter(this.socialMediaForm.controls[list]['controls'], (item) => { return item.value.checked});
      if (checkedField) {
        this.socialMediaForm.get('noSocialMediaAccount').setValue(null);
        _.forEach(checkedField, (field) => {
          field.get(textField).setValidators([Validators.required, Validators.pattern(this.dataService.urlRegex)]);
          field.get(textField).updateValueAndValidity();
        })
      }
    } else {
      let checkedField = _.filter(this.socialMediaForm.controls[list]['controls'], (item) => { return !item.value.checked});
      if (checkedField) {
        _.forEach(checkedField, (field) => {
          field.get(textField).clearValidators();
          field.get(textField).setValue('');
          field.get(textField).updateValueAndValidity();
        })
      }
    }
  }

  disableAll(){
    if(this.socialMediaForm.value.noSocialMediaAccount){
      _.forEach(this.socialMediaForm.controls['socialMedia']['controls'], (field) => {
        field.get('url').clearValidators();
        field.get('url').setValue('');
        field.get('checked').setValue('');
        field.get('url').updateValueAndValidity();
      })
    }    
  }

  ngAfterViewInit() { 
    // this.elementRef.nativeElement.querySelectorAll(['input','select','textarea']).forEach(element => {
    //   element.addEventListener('blur', this.onClick.bind(this));
    // });
  }
  
  onClick(event) {
    this.saveFormData(this.socialMediaForm);
  }
}

