// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1, h2, h3, h4, h5, h6, p {
  margin: 0 0 0 0;
}

img {
  max-width: 100%;
}

.blue-bg {
  background: #3497EC !important;
}

.btn.add-btn {
  color: #fff;
}

.btn.btn-xl {
  line-height: 50px;
  padding: 0 14px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 4px;
  min-width: 100px;
}

.bule-2 {
  color: #4296F7 !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-12 p {
  font-size: 12px !important;
}

.form-control.aud-input {
  background-color: #fff;
  border-radius: 0;
  color: #939292;
  border: #BABABA solid 1px;
}

.form-control.aud-input.xl {
  height: 50px;
  padding: 5px 8px;
  font-size: 17px;
  border-radius: 0;
}

.font-semi-bold {
  font-weight: 600 !important;
}

.icon {
  display: inline-block;
  flex-basis: 22px;
  min-width: 22px;
}

.logo {
  margin-bottom: 2px;
  width: 100%;
}

.px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.py-36 {
  padding-top: 36px !important;

}
`, "",{"version":3,"sources":["webpack://./src/app/components/login/login.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;IACI,6BAA6B;IAC7B,8BAA8B;AAClC;;AAEA;EACE,4BAA4B;;AAE9B","sourcesContent":["h1, h2, h3, h4, h5, h6, p {\n  margin: 0 0 0 0;\n}\n\nimg {\n  max-width: 100%;\n}\n\n.blue-bg {\n  background: #3497EC !important;\n}\n\n.btn.add-btn {\n  color: #fff;\n}\n\n.btn.btn-xl {\n  line-height: 50px;\n  padding: 0 14px;\n  font-size: 15px;\n  font-weight: 700;\n  border-radius: 4px;\n  min-width: 100px;\n}\n\n.bule-2 {\n  color: #4296F7 !important;\n}\n\n.font-12 {\n  font-size: 12px !important;\n}\n\n.font-12 p {\n  font-size: 12px !important;\n}\n\n.form-control.aud-input {\n  background-color: #fff;\n  border-radius: 0;\n  color: #939292;\n  border: #BABABA solid 1px;\n}\n\n.form-control.aud-input.xl {\n  height: 50px;\n  padding: 5px 8px;\n  font-size: 17px;\n  border-radius: 0;\n}\n\n.font-semi-bold {\n  font-weight: 600 !important;\n}\n\n.icon {\n  display: inline-block;\n  flex-basis: 22px;\n  min-width: 22px;\n}\n\n.logo {\n  margin-bottom: 2px;\n  width: 100%;\n}\n\n.px-40 {\n    padding-left: 40px !important;\n    padding-right: 40px !important;\n}\n\n.py-36 {\n  padding-top: 36px !important;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
