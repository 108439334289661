import { Component, OnInit, Input } from '@angular/core';
import { SiteService } from '../service/site.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';

@Component({
  selector: 'app-refunds',
  templateUrl: './refunds.component.html',
  styleUrls: ['./refunds.component.css'],
})
export class RefundsComponent implements OnInit, PageHasTable {
  @Input() selectedSiteId;

  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[]
  refunds;
  siteIsLock;

  constructor(
    private siteService: SiteService,
  ) { }

  ngOnInit() {
    this.siteIsLock=this.siteService.siteIsLock;

    this.getRefunds(this.selectedSiteId);

    this.getTableData();
  }

  callFromParent(site) {
    this.selectedSiteId = site.id;
    this.siteIsLock = site.isLock;
    this.getRefunds(this.selectedSiteId);
  };

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      amount: 'currency',
      createdOn: 'date',
    };

    this.columnHeaders = [
      {field: 'amount', header: 'Amount', filter: 'none'},
      {field: 'createdOn', header: 'Created On', filter: 'none'},
      {field: 'reason', header: 'Reason', filter: 'none'},
      {field: 'status', header: 'Status', filter: 'none'},
    ];
  }

  getRefunds(siteId) {
    this.siteService.getRefunds(siteId).subscribe(res => {
      this.refunds = res;
    }, err => {
      throw err;
    });
  }
}
