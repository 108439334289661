import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntakeLocalListing, LocalListingsLocation, IntakeLocationData, LlRequiredData, RequiredLocationData } from '@shared/interfaces/intakes';
import { IntakeTag } from '@modules/intakes/intake-component.interface';
import { SectionStatus } from 'app/components/customer/intakes/add-intakes-data/basic/review/section-model';
import { IntakesService } from 'app/components/customer/intakes/services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { IntakeLocalListingsComponent } from '@modules/intakes/intake-local-listings/intake-local-listings.component';

@Component({
  selector: 'app-local-listings-location-info',
  templateUrl: './local-listings-location-info.component.html',
  styleUrls: ['./local-listings-location-info.component.css'],
})
export class LocalListingsLocationInfoComponent {
  @Input() llIntake: IntakeLocalListing;
  @Input() llRequiredData: LlRequiredData;
  @Input() tags: IntakeTag[];
  @Input() intakeTypeId: number;
  @Input() sectionId: number;

  intakeId: string;
  siteId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private intakeLocalListingsComponent: IntakeLocalListingsComponent
  ) {
    this.activatedRoute.params.subscribe(
      (params: {id: string, siteId: string}) => {
        if (params.id && params.siteId) {
          this.intakeId = params.id;
          this.siteId = params.siteId;
        }
      }
    );
  }

  /**
   * Submits a location to be added to the intake
   */
  addLocation(): void {
    // initialize an empty llLocation object
    const emptyLocation = {} as LocalListingsLocation;
    // using unary operator to convert to number but also checking for empty string to avoid NaN error
    emptyLocation.intake_id = this.intakeId !== '' ? +this.intakeId : 0;
    emptyLocation.appointment_link = '';
    emptyLocation.gbp_location_id = '';
    emptyLocation.has_existing_gbp_listing = false;
    emptyLocation.intake_location = {} as IntakeLocationData;
    emptyLocation.intake_location.locationName = '';
    emptyLocation.intake_location.address1 = '';
    emptyLocation.intake_location.address2 = '';
    emptyLocation.intake_location.altPhone = '';
    emptyLocation.intake_location.city = '';
    emptyLocation.intake_location.country = '';
    emptyLocation.intake_location.email2 = '';
    emptyLocation.intake_location.email = '';
    emptyLocation.intake_location.faxNumber = '';
    emptyLocation.intake_location.googleBusinessName = '';
    emptyLocation.intake_location.hasHours = false;
    emptyLocation.intake_location.intakeType = 13;
    emptyLocation.intake_location.locationName = '';
    emptyLocation.intake_location.lstLocationWorkingHours = [];
    emptyLocation.intake_location.neighborhoodBuilding = '';
    emptyLocation.intake_location.phoneNumber = '';
    emptyLocation.intake_location.picture = '';
    emptyLocation.intake_location.state = '';
    emptyLocation.intake_location.type = 1;
    emptyLocation.intake_location.zipCode = '';
    emptyLocation.languages_supported = '';
    emptyLocation.ll_business_id = this.llIntake.ll_business_id;
    emptyLocation.location_tags = [];
    emptyLocation.office_photos = '';
    emptyLocation.service_area = '';
    emptyLocation.site_id = this.siteId !== '' ? +this.siteId : 0;
    emptyLocation.year_established = '';
    if(!this.llIntake.locations)
      this.llIntake.locations = [];
    this.llIntake.locations.push(emptyLocation);
  }

  changeIntakeStatus(){
    this.llRequiredData.locations = this.llIntake ? this.llIntake.locations.map(location => {
      return new RequiredLocationData(location);
    }) : [];
    this.intakesService.updateRequiredFieldsStatus(this.llRequiredData);
    let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, this.intakeId);
    this.postIntakeSection(sectionStatus);
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.toastrService.error(err.error.message, 'Error');
      });
  }
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (res) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });
      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    });
  }
}
