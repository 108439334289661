export class String {
  /**
   * Converts a string to a CSS friendly string
   *
   * @param {String} string
   * @return {String}
   */
  static toCssClass(string: string): string {
    return string.replace(/\/|\s/g, '-').toLowerCase();
  }

  /**
   * Converts a string to an HTML ID friendly string
   *
   * @param {String} processedString
   * @param {String} addOnText
   *
   * @return {String}
   */
  static toIdString(processedString: string, addOnText ?: string ): string {
    addOnText = `-${addOnText}` ? addOnText : '';

    const newString = `${processedString}${addOnText}`;

    return newString.replace(/\/|\s/g, '-').toLowerCase();
  }
}
