// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: calc(100% - 400px);
}

section {
  background-image: url('background.svg');
  background-position: center;
  background-size: cover;
  display: flex;
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/login/right-section/right-section.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uCAAgE;EAChE,2BAA2B;EAC3B,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,WAAW;AACb","sourcesContent":[":host {\n  width: calc(100% - 400px);\n}\n\nsection {\n  background-image: url('assets/images/promotions/background.svg');\n  background-position: center;\n  background-size: cover;\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
