// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  display: inline-flex;
  gap: 1.5rem;
}

input, select {
  border: 1px solid rgb(194, 194, 194);
  border-radius: .5rem;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  height: 3rem;
  line-height: 1.5rem;
  padding: 10px;
  width: 10rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/forms/input-office-hours/input-office-hours.component.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,oCAAoC;EACpC,oBAAoB;EACpB,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd","sourcesContent":["div {\n  display: inline-flex;\n  gap: 1.5rem;\n}\n\ninput, select {\n  border: 1px solid rgb(194, 194, 194);\n  border-radius: .5rem;\n  display: inline-block;\n  font-size: 1rem;\n  font-weight: 400;\n  height: 3rem;\n  line-height: 1.5rem;\n  padding: 10px;\n  width: 10rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
