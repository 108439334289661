// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  font-weight: bold;
}

.input-container, .input-group {
  display: flex;
  gap: 0.5rem;
}

.input-container {
  flex-direction: row;
  gap: 0.5rem 1.5rem;
}

:host /deep/ .input-container div {
  gap: 0.25rem !important;
}

:host /deep/ .input-container div label {
  margin-bottom: 0 !important;
}

.input-group {
  flex-direction: column;
}

.input-group label {

}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/forms/checkbox-group/checkbox-group.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;AAEA","sourcesContent":["label {\n  font-weight: bold;\n}\n\n.input-container, .input-group {\n  display: flex;\n  gap: 0.5rem;\n}\n\n.input-container {\n  flex-direction: row;\n  gap: 0.5rem 1.5rem;\n}\n\n:host /deep/ .input-container div {\n  gap: 0.25rem !important;\n}\n\n:host /deep/ .input-container div label {\n  margin-bottom: 0 !important;\n}\n\n.input-group {\n  flex-direction: column;\n}\n\n.input-group label {\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
