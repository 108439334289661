import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkmark',
})
export class CheckmarkPipe implements PipeTransform {
  transform(value: boolean): unknown {
    if (value) {
      return '<i class="icon tick-mark iconWidthHeight"></i>';
    }

    return (
      '<span>' +
       '<i class="table-action-icon delete"></i>' +
      '</span>'
    );
  }
}
