import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  IntakeComponent,
  IntakeFormComponent,
  IntakeTag,
} from '@modules/intakes/intake-component.interface';
import { IntakeForm, IntakeLocalListing, IntakeStep, LlRequiredData } from '@shared/interfaces/intakes';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '@shared/services/http/http.service';
import { LocalListingsService } from '@shared/services/local-listings/local-listings.service';
import { IntakesService } from 'app/components/customer/intakes/services/intakes.service';

@Component({
  selector: 'app-intake-local-listings',
  templateUrl: './intake-local-listings.component.html',
  styleUrls: ['./intake-local-listings.component.css'],
})
export class IntakeLocalListingsComponent implements OnInit, IntakeComponent {
  localListingsIntake: IntakeLocalListing;
  intakeId: string;
  intakeSteps: IntakeStep[];
  siteId: string;
  submenu: any;
  businessTags: IntakeTag[];
  locationTags: IntakeTag[];
  selectedItem: any = {};
  requiredData: LlRequiredData;

  @Input() intakeTypeId: number;
  @Input() menuId: number;
  @Input() selectedMenu;

  @Output() continue = new EventEmitter<any>();
  @Output() changeStatus = new EventEmitter<any>();

  @ViewChildren('formComponents') formComponents: QueryList<IntakeForm>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private intakesService: IntakesService,
    private localListingsService: LocalListingsService,
  ) {
    this.activatedRoute.params.subscribe(
      (params: { id: string, siteId: string }) => {
        if (params.id && params.siteId) {
          this.intakeId = params.id;
          this.siteId = params.siteId;
        }
      },
    );
    this.intakesService.progressBar.subscribe(obj => {
      if (obj != null) {
        console.log(obj.basicMenu);
        obj.basicMenu.forEach((menu) => {
          if (this.selectedMenu && menu.id == this.selectedMenu.id) {
            this.selectedMenu.submenu, this.submenu = menu.submenu;
          }
        })
      }
    });
  }

  ngOnInit(): void {
    this.submenu = this.selectedMenu.submenu;
    if (this.selectedMenu.selectedSectionId) {
      this.selectedItem = this.selectedMenu.submenu.find((item) => { return item.id == this.selectedMenu.selectedSectionId });
    }

    this.getExistingIntake();
    this.getBusinessTags();
    this.getLocationTags();
  }

  /**
   * The label for the Local Listings intake form container
   * @return {String} The dynamically set value for the container label
   */
  get continueButtonLabel(): string {
    switch (this.selectedItem['id']) {
      case -1:
        //we want to seperate save from continue however this functionality may be useful later so leaving ing
        return 'Save and Continue';
      default:
        return 'Continue';
    }
  }

  /**
   * Retrieve the tabs for the Local Listings Business Info Form
   */
  getBusinessTags(): void {
    this.localListingsService.getBusinessTags().subscribe(
      (res) => {
        if (res) {
          // Creates a `tags` object with reorganized tag items from the API
          this.businessTags = res.reduce((previous, current) => {
            previous[current.tag_type_name] = previous[current.tag_type_name] || [];
            previous[current.tag_type_name].push({text: current.name, value: current.ll_tag_id});
            return previous;
          }, Object.create(null));
        }
      }
    );
  }

  getLocationTags(): void {
    this.localListingsService.getLocationTags().subscribe(
      (res) => {
        if (res) {
          // Creates a `tags` object with reorganized tag items from the API
          this.locationTags = res.reduce((previous, current) => {
            previous[current.tag_type_name] = previous[current.tag_type_name] || [];
            previous[current.tag_type_name].push({text: current.name, value: current.ll_tag_id});
            return previous;
          }, Object.create(null));
        }
      }
    );
  }

  /*
   * Retrieves the existing Local Listings intake if there is one and sets the component variable
   * this.localListingsIntake with the results. This intake will be passed to the business and
   * locations components so they can set their forms with the existing data.
   */
  getExistingIntake(): void {
    this.localListingsService.getLocalListingsIntake(this.intakeId).subscribe(
      (res) => {
        if (res.intake_id != 0) {
          this.localListingsIntake = res;
        } else {
          this.localListingsIntake = {} as IntakeLocalListing;
          this.localListingsIntake.intake_id = this.intakeId !== '' ? +this.intakeId : 0;
        }
        this.requiredData = new LlRequiredData(this.localListingsIntake);
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  /**
   * Navigates to the next intake step
   */
  goToNextStep(): void {
    const component: IntakeFormComponent = this.formComponents['_results'][0];
    // if going to next step when section id is 99/Location Info, emit continue event to move to Review page
    if (this.selectedItem['id']===99) {
      if (typeof component.changeIntakeStatus === 'function') {
        this.changeStatus.emit();
        component.changeIntakeStatus();
        this.goToNext();
      }
    } else {
      let selectedItem = this.selectedItem;
      let index = this.selectedMenu.submenu.findIndex(menu => menu.id == selectedItem['id']);
      this.selectedItem = this.selectedMenu.submenu[index + 1];
      window.scroll(0,0);
    }
  }

  /**
   * Navigates to the previous intake step
   */
  goToPreviousStep(): void {
    let selectedItem = this.selectedItem;
    if(selectedItem['id']!==78){
      let index = this.selectedMenu.submenu.findIndex(menu => menu.id == selectedItem['id']);
      this.selectedItem = this.selectedMenu.submenu[index - 1];
    }else{
      this.selectedItem = {};
    }
    this.getExistingIntake();
    window.scroll(0,0);
  }

  openPage(item?) {
    this.selectedItem = item ? item : {id: 0};
  }

  goToNext(): void {
    this.continue.next()
  }
}
