import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'binary',
})
export class BinaryPipe implements PipeTransform {
  /**
   * Replaces 0 and 1 binary values with text
   * @param {boolean} value
   * @param {string} negativeText
   * @param {string} positiveText
   *
   * @return {String} The replacing string
   */
  transform(value: 0 | 1 | boolean, negativeText = 'No', positiveText = 'Yes'): string {
    if (value == 1 || value) {
      return positiveText;
    }

    return negativeText;
  }
}
