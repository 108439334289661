import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObDashboardComponent } from './ob-dashboard/ob-dashboard.component';
import{ TicketModule } from '../ticket/ticket.module';

@NgModule({
  imports: [
    CommonModule,
    TicketModule
  ],
  declarations: [ObDashboardComponent]
})
export class OnboardingModule { }
