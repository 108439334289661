import { Component, OnInit, Input } from "@angular/core";
import { SiteService } from "../../site/service/site.service";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { ReportingService } from "../services/reporting.service";
import * as html2pdf from "html2pdf.js";
import moment from "moment";
import { HttpService } from "../../../services/http.service";
import * as _ from "lodash";

@Component({
  selector: "app-roi-performance",
  templateUrl: "./roi-performance.component.html",
  styleUrls: ["./roi-performance.component.css"]
})
export class RoiPerformanceComponent implements OnInit {
  allSites;
  sites;
  corporatePartner;
  reportData;
  yearsData = [];
  reportParams = { month: "", cp: "" };
  constructor(
    private siteService: SiteService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private reportingService: ReportingService
  ) {}

  ngOnInit() {
    this.getSites();
    this.getCorporatePartner();
    this.getYearsData();
  }

  getYearsData() {
    let currentYear = moment().year();
    for (let i = 0; i <= 3; i++) {
      this.yearsData.push(currentYear - i);
    }
  }

  getSites(cpId?) {
    this.siteService.getSites(cpId).subscribe(
      res => {
        if (res) {
          this.allSites = Object.assign([], res);
          this.sites = res;
        }
      },
      err => {
        throw err;
      }
    );
  }

  getCorporatePartner() {
    this.siteService.getCorporatePartner().subscribe(
      res => {
        if (res) {
          this.corporatePartner = res;
        }
      },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term.length < 3
          ? []
          : this.allSites
              .filter(
                v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      )
    );
  formatter = (result: string) => `${result["name"]}`;
  inputFormatter = (result: string) => `${result["name"]}`;

  getReport(params) {
    this.reportData={};
    let requestParams = this.getReportParams(params);
    if (requestParams) {
      this.reportingService.getRoiReport(requestParams).subscribe(
        res => {
          if (res) {
            this.reportData = res;
            console.log(this.reportData);
          }
        },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        }
      );
    }
  }

  getReportParams(params) {
    let currentYear = moment().year();
    let currentMonth = moment().month();
    params["formattedStartDate"] = moment([currentYear, 1 - 1]).format(
      "YYYY-MM-DD"
    );
    params["formattedEndDate"] =
      currentMonth == params.month - 1
        ? moment().format("YYYY-MM-DD")
        : moment(moment([currentYear, params.month - 1]).format("YYYY-MM-DD"))
            .endOf("month")
            .format("YYYY-MM-DD");
    this.reportParams["cpObj"] = _.find(this.corporatePartner, cp => {
      return cp.id == this.reportParams["cp"];
    });
    if (typeof this.reportParams["site"] == "object") {
      this.reportParams["siteId"] = this.reportParams["site"]["id"];
    } else {
      this.reportParams["siteId"] = 0;
    }
    return this.reportParams;
  }

  downloadReport(report: string) {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = "none";
    document.getElementById("selectedParams").style.display = "block";
    var element = document.getElementById("report");
    var opt = {
      margin: 0,
      filename: "roi-performance.pdf",
      jsPDF: { unit: "in", format: "b3", orientation: "portrait" }
    };
    if (report == "print") {
      html2pdf()
        .from(element)
        .set(opt)
        .output("dataurlnewwindow")
        .then(() => {
          document.getElementById("downloadButton").style.display = "block";
          document.getElementById("selectedParams").style.display = "none";
        });
    } else {
      html2pdf()
        .from(element)
        .set(opt)
        .save()
        .then(() => {
          document.getElementById("downloadButton").style.display = "block";
          document.getElementById("selectedParams").style.display = "none";
        });
    }
  }
}
