// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-img-thumbnail{max-width:150px;}
.form label{font-size:15px;}
:host >>> my-date-picker >>> .mydp{height:50px !important;}
`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/ppc-fb-ads/ppc-fb-ads.component.css"],"names":[],"mappings":"AAAA,sBAAsB,eAAe,CAAC;AACtC,YAAY,cAAc,CAAC;AAC3B,mCAAmC,sBAAsB,CAAC","sourcesContent":[".upload-img-thumbnail{max-width:150px;}\n.form label{font-size:15px;}\n:host >>> my-date-picker >>> .mydp{height:50px !important;}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
