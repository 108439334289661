// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  background-color: rgb(048, 062, 078);
  border-radius: 2px;
  color: white;
}

a > span {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1rem;
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/buttons/secondary-button/secondary-button.component.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":["a {\n  background-color: rgb(048, 062, 078);\n  border-radius: 2px;\n  color: white;\n}\n\na > span {\n  font-size: 1.5rem;\n  font-weight: bold;\n  line-height: 1rem;\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
