// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all {
  a {
    color: rgb(255, 255, 255);
    background: transparent;
  }

  a:hover {
    font-weight: bold;
    color: rgb(255, 255, 255);
    text-decoration: none;
  }

  button {
    background-color: rgb(65, 81, 100);
    border: 0;
    border-radius: 0.25rem;
    height: 2.5rem;
    padding: 6px 10px;
    width: 8rem;
  }

  button.disabled {
    background-color: rgb(163, 166, 183);
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/buttons/search-button/search-button.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAyB;IACzB,uBAAuB;EACzB;;EAEA;IACE,iBAAiB;IACjB,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,kCAAkC;IAClC,SAAS;IACT,sBAAsB;IACtB,cAAc;IACd,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,oCAAoC;EACtC;AACF","sourcesContent":["@media all {\n  a {\n    color: rgb(255, 255, 255);\n    background: transparent;\n  }\n\n  a:hover {\n    font-weight: bold;\n    color: rgb(255, 255, 255);\n    text-decoration: none;\n  }\n\n  button {\n    background-color: rgb(65, 81, 100);\n    border: 0;\n    border-radius: 0.25rem;\n    height: 2.5rem;\n    padding: 6px 10px;\n    width: 8rem;\n  }\n\n  button.disabled {\n    background-color: rgb(163, 166, 183);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
