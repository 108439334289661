import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { CorporatePartner} from '@shared/interfaces/client';
import { SiteService } from "../../service/site.service";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { PiwikSetupComponent } from "../basic/piwik-setup/piwik-setup.component";
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CorporatePartnerService } from "../../../admin/manage-corporate-partner/services/corporate-partner.service";
import { masterEnum } from '@shared/master-enum.enum';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '@services/http.service';
import { ClientService } from '@shared/services/client.service';
import { SiteInfo } from '@shared/interfaces/site';
import { TextValue } from '@shared/interfaces/forms';
import { CheckboxGroupControl, CheckboxItemControl } from '@shared/utilities/CheckboxFormGroup';

@Component({
  selector: "app-site-basic",
  templateUrl: "./basic.component.html",
  styleUrls: ["./basic.component.css"],
})
export class SiteBasicComponent implements OnInit {
  @Input() siteInfo: SiteInfo;
  @Output() updateSite = new EventEmitter<any>();

  corporatePartner;
  corporatePartners: TextValue<string, number|string>[] = [];
  accountManager;
  siteType;
  filterBy = {};
  siteId: number|string;
  wpSites;
  wpSitePath;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  divisionCheckboxes;
  siteForm: UntypedFormGroup;

  constructor(
    private clientService: ClientService,
    private formBuilder: UntypedFormBuilder,
    private siteService: SiteService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    public modalService: NgbModal,
    private httpService: HttpService,
    private corporatePartnerService: CorporatePartnerService,
    public dataService: DataService
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.siteId) {
        this.siteId = params.siteId;
      }
    });

    this.getCorporatePartners();
  }

  ngOnInit() {
    if (!this.siteInfo) {
      // If the site information isn't being passed to the component, fetch it from the API
      this.getSiteInfo(this.siteId);
    } else {
      // If the site information is passed in, then continue setting up the component
      this.initializeComponent();
    }
  }

  /**
   * Get the configuration information for the site and adds it to the component
   *
   * @param {String} siteId
   */
  getSiteInfo(siteId: string|number) {
    if(typeof siteId === 'number'){
      siteId = siteId.toString();
    }
    this.siteService.getSiteInfo(siteId).subscribe(
        (res: SiteInfo) => {
          if (res) {
            this.siteInfo = res;

            this.initializeComponent();
          }
        },
        (err) => {
          this.httpService.openErrorPopup(err.error.message);
        },
    );
  }

  /**
   * Runs a series of methods to setup data for the component
   *
   * @return {void}
   */
  initializeComponent(): void {
    this.initializeForm();
    this.getAccountManager();
    this.getCorporatePartner();
    this.getSiteType(masterEnum.site_type);
    this.getWPSite();
  }

  initializeForm() {
    this.siteForm = this.formBuilder.group({
      id: [this.siteInfo.id ? this.siteInfo.id : 0, [Validators.required]],
      siteTypeId: [
        this.siteInfo.siteTypeId ? this.siteInfo.siteTypeId : 0,
        [Validators.required]
      ],
      name: [
        this.siteInfo.name ? this.siteInfo.name : "",
        [Validators.required]
      ],
      domainUrl: [
        this.siteInfo.domainUrl ? this.siteInfo.domainUrl : "",
        [Validators.required]
      ],
      aliasDomain: [this.siteInfo.aliasDomain ? this.siteInfo.aliasDomain : ""],
      accountManager: [
        this.siteInfo.accountManager ? this.siteInfo.accountManager : "0",
        [Validators.required]
      ],
      corporatePartnerId: [
        this.siteInfo.corporatePartnerId ? this.siteInfo.corporatePartnerId : 0,
        [Validators.required]
      ],
      description: [this.siteInfo.description ? this.siteInfo.description : ''],
      isLive: [this.siteInfo.isLive ? this.siteInfo.isLive : false],
      isActive: [this.siteInfo.isActive ? this.siteInfo.isActive : false],
      programId: [this.siteInfo.programId ? this.siteInfo.programId : '17'],
      wpSiteId: [this.siteInfo.wpSiteId ? this.siteInfo.wpSiteId : 0],
      wpSitePath: [this.siteInfo.wpSitePath ? this.siteInfo.wpSitePath : ''],
      stripeId: [
        this.siteInfo.oSiteSetting.stripeCustomerId
          ? this.siteInfo.oSiteSetting.stripeCustomerId
          : ""
      ],
      stripeId2: [
        this.siteInfo.oSiteSetting.stripeCustomerId2
          ? this.siteInfo.oSiteSetting.stripeCustomerId2
          : ""
      ],
      pwikiId: [
        this.siteInfo.oSiteSetting.piwikSiteId
          ? this.siteInfo.oSiteSetting.piwikSiteId
          : null
      ],
      launchDate: [
        this.siteInfo && this.siteInfo.launchDate != null
          ? new Date(this.siteInfo.launchDate)
          : null
      ],
      cpAccountNo: [
        this.siteInfo && this.siteInfo.cpAccountNo != null
          ? this.siteInfo.cpAccountNo
          : null
      ],
      isMigrated: [this.siteInfo.isMigrated ? this.siteInfo.isMigrated : false],
      cpDivisions: new FormArray([])
    });
    this.getCorporatePartnerDivisions();
  }

  piwikIdSetup(siteId) {
    this.corporatePartnerService.piwikIdSetup(siteId).subscribe(
      res => {
        if (res) {
          this.getSiteInfo(this.siteId);
        }
      },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  getCorporatePartner() {
    this.corporatePartnerService.getCorporatePartners().subscribe(
        (res: CorporatePartner[]) => {
          this.corporatePartner = res;

          if (this.siteInfo.corporatePartnerId) {
            this.siteForm.controls.corporatePartnerId.setValue(this.siteInfo.corporatePartnerId);
          }
        },
        (err) => {
          this.httpService.openErrorPopup(err.error.message);
        },
    );
  }

  /**
   * Retrieves a list of corporate partners;
   */
  getCorporatePartners(): void {
    this.clientService.queryCorporatePartners().subscribe((response: CorporatePartner[]) => {
      response.map((corporatePartner: CorporatePartner) => {
        this.corporatePartners.push({
          value: corporatePartner.id,
          text: corporatePartner.name,
        });
      });
    });
  }

  getAccountManager() {
    this.siteService.getAccountManager().subscribe(
      res => {
        if (res) {
          this.accountManager = res;
          if (this.siteInfo.accountManager)
            this.siteForm.controls.accountManager.setValue(
              this.siteInfo.accountManager
            );
        }
      },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }
  getSiteType(id) {
    this.siteService.getMastersListById(id).subscribe(
      res => {
        if (res) {
          this.siteType = res.masterList;
          if (this.siteInfo.siteTypeId)
            this.siteForm.controls.siteTypeId.setValue(
              this.siteInfo.siteTypeId
            );
        }
      },
      err => {
        throw err;
      }
    );
  }

  getCorporatePartnerDivisions() {
    this.siteService.getMastersListById(masterEnum.cp_division).subscribe(
      res => {
        if (res) {
          const divisions = this.siteForm.get('cpDivisions') as FormArray;
          const divList = [];

          res.masterList.forEach((division) => {
            const isChecked = this.siteInfo.cpDivision?.includes(division.id) || false;
            divList.push(new CheckboxItemControl({value: division.id, label: division.name, defaultValue: isChecked}));
          })
          this.divisionCheckboxes = new CheckboxGroupControl('divisions', divList);
          divisions.push(this.divisionCheckboxes.control);
        }
      },
      err => {
        throw err;
      }
    );
  }

  /**
   * Saves the Site Config Form
   *
   * @param {FormGroup} form
   */
  save(form: UntypedFormGroup) {
    if (form.valid) {
      let configData = form.value;
      configData.launchDate = configData.launchDate
        ? new Date(configData.launchDate)
        : null;
      configData.launchDate = configData.launchDate
        ? configData.launchDate.getMonth() +
          1 +
          "/" +
          configData.launchDate.getDate() +
          "/" +
          configData.launchDate.getFullYear()
        : configData.launchDate;
      configData['cpDivision'] = this.divisionCheckboxes.value.toString();
      this.siteService.updateSiteInfo(configData).subscribe(
        res => {
          if (res) {
            this.updateSite.next(this.siteInfo.id);
            this.toastrService.success("success", res["status"]);
          }
        },
        err => {
          this.toastrService.error(err.message);
        }
      );
    }
  }
  getWPSite() {
    this.siteService.getWPSites().subscribe(
      res => {
        if (res) {
          this.wpSites = res;
          this.wpSitePath = _.find(this.wpSites, site => {
            return site.siteId == this.siteInfo.wpSiteId;
          });
          this.selected(this.wpSitePath,true);
        }
      },
      err => {
        this.toastrService.error(err.error.message);
      }
    );
  }
  searchDomain = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term.length < 3
          ? []
          : this.wpSites
              .filter(
                v => v.domainUrl.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      )
    );
  domainFormatter = (result: string) => `${result["domainUrl"]}`;
  inputFormatter = (result: string) =>
    result["domainUrl"] || (this.wpSitePath ? this.wpSitePath.domainUrl : "");
  selected(data,isLoad) {
    if (!this.wpSitePath || this.wpSitePath.path != data) {
      this.wpSitePath = data;
      this.siteForm.controls.wpSitePath.setValue(
        typeof this.wpSitePath == "object" ? this.wpSitePath.path : ""
      );
      this.siteForm.controls.wpSiteId.setValue(
        typeof this.wpSitePath == "object" ? this.wpSitePath.siteId : 0
      );
      if(!isLoad){
        if(this.siteForm.controls.isLive.value && this.wpSitePath )
          this.siteForm.controls.isMigrated.setValue(true);
        else
          this.siteForm.controls.isMigrated.setValue(false);
      }
    }

  }
  isFieldValid(field: string, index: number = 0) {
    return !this.siteForm.get(field).valid && this.siteForm.get(field).touched;
  }
  getPiwikscript(){
    var model = this.modalService.open(PiwikSetupComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.siteId = this.siteId;
  }
  isLiveChange(){
    if(!this.siteForm.controls.isLive.value && this.wpSitePath )
        this.siteForm.controls.isMigrated.setValue(true);
      else
        this.siteForm.controls.isMigrated.setValue(false);
  }
}
