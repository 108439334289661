// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  border: 2px solid rgb(244, 244, 247);
}

header {
  background-color: rgb(48, 63, 78);
  color: white;
  font-size: 24px;
  height: 57px;
  padding-left: 27px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

main {
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/frames/overview-frame/overview-frame.component.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,iCAAiC;EACjC,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":["div {\n  border: 2px solid rgb(244, 244, 247);\n}\n\nheader {\n  background-color: rgb(48, 63, 78);\n  color: white;\n  font-size: 24px;\n  height: 57px;\n  padding-left: 27px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\nmain {\n  background-color: white;\n  display: flex;\n  flex-direction: row;\n  padding: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
