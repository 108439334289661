// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  border: 1px solid rgb(240, 240, 247);
  border-left-width: 0.3rem;
  display: inline-flex;
  padding: 1rem 0 1rem 0.7rem;
  width: 100%;
  cursor: pointer;
}

div:hover {
  cursor: pointer;
}

div.active {
  background-color: rgb(240, 240, 247);
  border-left-color: rgb(86, 89, 102);
}

.submenu-span {
  color: rgb(77, 79, 92);
  font-weight: bold;
}

.untouched {
  font-size: 15px; border: 0px; padding: 14px 15px; min-height: 52px; border-left: 5px solid #ECECEC; font-weight: 600; font-style: italic; color: #C7C7C7; border-radius: 0px;  border-bottom: 1px solid #ECECEC; display:flex;   border-top: 1px solid #ECECEC; word-break: break-word;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/intakes/intake-sidebar-submenu-item/intake-sidebar-submenu-item.component.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,oBAAoB;EACpB,2BAA2B;EAC3B,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,mCAAmC;AACrC;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,eAAe,EAAE,WAAW,EAAE,kBAAkB,EAAE,gBAAgB,EAAE,8BAA8B,EAAE,gBAAgB,EAAE,kBAAkB,EAAE,cAAc,EAAE,kBAAkB,GAAG,gCAAgC,EAAE,YAAY,IAAI,6BAA6B,EAAE,sBAAsB;AACxR","sourcesContent":["div {\n  border: 1px solid rgb(240, 240, 247);\n  border-left-width: 0.3rem;\n  display: inline-flex;\n  padding: 1rem 0 1rem 0.7rem;\n  width: 100%;\n  cursor: pointer;\n}\n\ndiv:hover {\n  cursor: pointer;\n}\n\ndiv.active {\n  background-color: rgb(240, 240, 247);\n  border-left-color: rgb(86, 89, 102);\n}\n\n.submenu-span {\n  color: rgb(77, 79, 92);\n  font-weight: bold;\n}\n\n.untouched {\n  font-size: 15px; border: 0px; padding: 14px 15px; min-height: 52px; border-left: 5px solid #ECECEC; font-weight: 600; font-style: italic; color: #C7C7C7; border-radius: 0px;  border-bottom: 1px solid #ECECEC; display:flex;   border-top: 1px solid #ECECEC; word-break: break-word;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
