import { Component, Input, OnInit } from '@angular/core';
import { SiteService } from '../../service/site.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@shared/confirm-modal/confirm-modal.component';
import { AddSycleSetupComponent } from './add-sycle-setup/add-sycle-setup.component';
import { AddBluePrintSetupComponent } from './add-blue-print-setup/add-blue-print-setup.component';
import { HttpService } from '@services/http.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { hasPermission } from '@shared/policies/permissions';

@Component({
  selector: 'app-sycle-setup',
  templateUrl: './sycle-setup.component.html',
  styleUrls: ['./sycle-setup.component.css'],
})
export class SycleSetupComponent implements OnInit, PageHasTable {
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  columnHeadersBluePrint: ColumnHeader[];
  hasPermissions = hasPermission;
  sycleSetupData;
  bluePrintSetupData;
  siteId;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  @Input() type = 1;
  adSources;
  adSource = '';
  iFrameScript = '';

  constructor(private siteService:SiteService,
              private toastrService:ToastrService,
              private activatedRoute: ActivatedRoute,
              public modalService: NgbModal,
              public httpService:HttpService) {
    this.activatedRoute.params.subscribe(params => {
      if (params.siteId) {
        this.siteId = params.siteId;
        }
      });
    }

  ngOnInit() {
    this.getSycleSetup(this.siteId);

    this.getBluePrintSetup(this.siteId);

    this.getAdSource();

    this.getTableData();
  }

  getSycleSetup(siteId){
    this.siteService.getSycleSetup(siteId).subscribe(res=>{
      if(res){
        this.sycleSetupData=res;
      }
    },err=>{
      throw err;
    })
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnHeaders = [
      { field: 'clinicId', header: 'Clinic ID' },
      { field: 'versionName', header: 'Version' },
      { field: 'parentId', header: 'Parent ID' },
      { field: 'location', header: 'Location' },
      { field: 'callbackEmail', header: 'Callback Email' },
      { field: 'timeZoneName', header: 'TimeZone' },
      { field: 'profileName', header: 'ReviewGen Profile' },
    ];

    this.columnHeadersBluePrint = [
      { field: 'clinicId', header: 'Clinic ID' },
      { field: 'clinicName', header: 'Clinic Name' },
      { field: 'locationID', header: 'Location ID' },
    ];
  }

  deleteSycle(id){
    var model = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.message = 'Are you sure to delete?';
    model.result.then((result) => {
      if(result){
      this.siteService.deleteSycleSetup(id).subscribe(res=>{
        if(res){
          this.toastrService.success('Delete successfully', 'success');
          this.getSycleSetup(this.siteId);
        }
      },err=>{
        this.toastrService.error(err, 'Error');
      })
    }
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }
  openSycleAddPopup(sycle?){
    var model = this.modalService.open(AddSycleSetupComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.siteId = this.siteId;
    model.componentInstance.sycleSetupData = sycle;
    model.componentInstance.msg= sycle ? 'Updatd Successfully' : 'Added Successfully';
    model.result.then((result) => {
      setTimeout(() => {
        this.getSycleSetup(this.siteId);
      }, 50);
      console.log(`Update success: ${result}`);
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }
  getBluePrintSetup(siteId){
    this.siteService.getBluePrintSetup(siteId).subscribe(res=>{
      if(res){
        this.bluePrintSetupData=res;
      }
    },err=>{
      throw err;
    });
  }
  deleteBluePrint(id){
    var modal=this.modalService.open(ConfirmModalComponent,{ariaLabelledBy:'modal-basic-title',backdrop:'static'});
    modal.componentInstance.message="Are you sure to delete?";
    modal.result.then((result)=>{
      if(result){
        this.siteService.deleteBluePrintSetup(id).subscribe(res=>{
          if(res){
            this.toastrService.success('Delete successfully', 'success');
            this.getBluePrintSetup(this.siteId);
          }
        },err=>{
          this.toastrService.error(err, 'Error');
        })
      }
      }, (reason) => {
        console.log(`Dismissed: ${reason}`);
      });
  }
  openBluePrintAddPopup(bluePrint?){
    var model = this.modalService.open(AddBluePrintSetupComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.siteId = this.siteId;
    model.componentInstance.bluePrintSetupData = bluePrint;
    model.componentInstance.msg= bluePrint ? 'Updatd Successfully' : 'Added Successfully';
    model.result.then((result) => {
      setTimeout(() => {
        this.getBluePrintSetup(this.siteId);
      }, 50);
      console.log(`Update success: ${result}`);
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }
  getAdSource(){
    this.siteService.getGeneralMaster(11).subscribe(res=>{
      if(res){
        this.adSources=res;
      }
    },err=>{
      throw err;
    })
  }
  generateIframeScript(){
    if(!this.adSource){
      this.iFrameScript='';
      return;
    }

    let data={siteId:this.siteId,adSource:this.adSource};
    this.siteService.generateIframeScript(data).subscribe(res=>{
      if(res){
        this.iFrameScript=res.IframeScript;
      }
    },err=>{
      this.httpService.openErrorPopup(err.error.message);
    })
  }

  setPMSType(pmsType: number): void {
    this.type = pmsType;
  }
}
