// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all {
  :root {
    display: flex;
  }

  main {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 6px rgb(175, 175, 175);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 75rem;
    padding: 1rem;
    width: 75rem;
  }
}

@media(max-width: 1515px) {
  main {
    width: 100%
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/intakes/intake-content/intake-content.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;;EAEA;IACE,oCAAoC;IACpC,wCAAwC;IACxC,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;IAChB,aAAa;IACb,YAAY;EACd;AACF;;AAEA;EACE;IACE;EACF;AACF","sourcesContent":["@media all {\n  :root {\n    display: flex;\n  }\n\n  main {\n    background-color: rgb(255, 255, 255);\n    box-shadow: 0 2px 6px rgb(175, 175, 175);\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    max-width: 75rem;\n    padding: 1rem;\n    width: 75rem;\n  }\n}\n\n@media(max-width: 1515px) {\n  main {\n    width: 100%\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
