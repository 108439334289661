import { Component, OnInit, ViewChild } from '@angular/core';
import { SiteService } from '../../service/site.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { HttpService } from '@services/http.service';
import { UserService } from '../../../admin/users/services/user-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SitesPopupComponent } from '../../../admin/users/sites-popup/sites-popup.component';
import { ChangePasswordComponent } from '../../../change-password/change-password.component';
import { ConfirmModalComponent } from '@shared/confirm-modal/confirm-modal.component';
import { MapSiteUserComponent } from '../map-site-user/map-site-user.component';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';
import { PortalUser } from '@shared/interfaces/user';

@Component({
  selector: 'app-site-user',
  templateUrl: './site-user.component.html',
  styleUrls: ['./site-user.component.css']
})
export class SiteUserComponent implements OnInit, PageHasTable {
  allUsers;
  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  usersList: PortalUser[];
  currentRole;
  userId;
  siteId;
  filterBy:Object = {};
  currentUser;
  selectedSite;
  userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };

  @ViewChild('dt') table: TableGenericComponent;

  constructor(
    private siteService: SiteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private userService: UserService,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
    localStorage.setItem('previousUrl', this.router.url);

    this.userId = this.userInfo.userId;
    let selectedSite = JSON.parse(localStorage.getItem('selectedSite'));

    localStorage.setItem('selectedSite', JSON.stringify(selectedSite));
    this.selectedSite = selectedSite;
    this.siteId = selectedSite.siteId;

    this.activatedRoute.params.subscribe(
      (params) => {
        if (!_.isEmpty(params)) {
          this.siteId = params.siteId;
        } else {
          this.siteId = this.selectedSite.siteId;
        }

        this.getUsersByParent(
          this.filterBy,
          params && params.siteId ? this.siteId : this.selectedSite.id
        );
      }
    );

    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
    });

    this.getTableData();
  }

  applyFilter(filterBy) {
    this.getUsersByParent(filterBy,this.siteId ? this.siteId : this.selectedSite.siteId);
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData() {
    this.columnHeaders = [
      { field: 'username', header: 'Username'},
      { field: 'fullName', header: 'Full Name'},
      { field: 'phone', header: 'Phone'},
      { field: 'userRoleName', header: 'Role', filter: 'none'},
      { field: 'isActive', header: 'Status', filter: 'none'},
    ];

    this.columnDataTypes = {
      phone: 'phone',
      isActive: 'checkmark',
    };
  }

  goToEdit(user) {
    this.router.navigate([`${'/'+this.currentRole+'/user/' + user.id}`], { queryParams: {siteId:this.siteId}});
  }

  /**
   * Delete a user registered in the portal
   * @param {PortalUser} user The user registered in the portal
   */
  deleteUser(user: PortalUser): void {
    const model = this.modalService.open(
      ConfirmModalComponent, {
        ariaLabelledBy: 'modal-basic-title',
        backdrop: 'static',
      }
    );

    model.componentInstance.message = `Are you sure you want to delete ${user.username}?`;

    model.result.then(
      (result) => {
        if (result) {
          this.siteService.deleteSiteUser(user.id).subscribe(
            (res) => {
              if (res) {
                                // Get the item from localStorage
                var siteString = localStorage.getItem('selectedSite');

                // Parse the JSON string back into an object
                var selectedSite = JSON.parse(siteString);
                const siteId = selectedSite.siteId ?? this.siteId;

                this.getUsersByParent(this.filterBy, siteId);
                this.toastrService.success('User deleted successfully', 'Success');
              }
            },
            (err) => {
              console.error(err);
              this.httpService.openErrorPopup(err.error.message);
            }
          );
        }
      }
    );
  }

  /**
   * Retrieves all users based on the parent site ID
   * @param {Object} filterBy
   * @param {Number} siteId
   */
  getUsersByParent(filterBy, siteId) {
    this.siteService.getAllUsersByParent(filterBy, siteId).subscribe(
      (res) => {
        if (res) {
          this.allUsers = res;
          this.allUsers = _.filter(this.allUsers, (user) => {
            user.fullName = `${user.firstName} ${user.middleName} ${user.lastName}`;
            return user;
          });
          this.usersList = Object.assign([], this.allUsers);
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  openSitesPopup(sites) {
    var model = this.modalService.open(SitesPopupComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.sites = sites;
  }
  openChangePasswordPopup(userId) {
    var model = this.modalService.open(ChangePasswordComponent, {
      ariaLabelledBy: "modal-basic-title",
      backdrop: "static"
    });
    model.componentInstance.userId = userId;
    model.result.then(
      result => {
      },
      reason => {
        console.log(`Dismissed: ${reason}`);
      }
    );
  }
  openMapSiteUser() {
    var model = this.modalService.open(MapSiteUserComponent, {
      ariaLabelledBy: "modal-basic-title",
      backdrop: "static"
    });
    model.componentInstance.siteId = this.siteId;
    model.result.then(
      result => {
        setTimeout(() => {
          this.applyFilter({});
        }, 50);

      },
      reason => {
        console.log(`Dismissed: ${reason}`);
      }
    );
  }

}
