import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from '../../../service/site.service';

@Component({
  selector: 'app-google-setup',
  templateUrl: './google-setup.component.html',
  styleUrls: ['./google-setup.component.css']
})
export class GoogleSetupComponent implements OnInit {
  googleForm:UntypedFormGroup;
  modalService;
  existingData;
  
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private router: Router,
    private toastrService: ToastrService,
    private siteService:SiteService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }
  initializeForm(){
    this.googleForm=this.formBuilder.group({
      siteId:[this.existingData.siteId?this.existingData.siteId:0,[Validators.required]],
      gaViewId:[this.existingData.gaViewId?this.existingData.gaViewId:'',[Validators.required]],
      gaPropertyId:[this.existingData.gaPropertyId?this.existingData.gaPropertyId:'',[Validators.required]],
      GscDomain:[this.existingData.gscDomain?this.existingData.gscDomain:'',[Validators.required]]
    });
  }
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
  setUp(form) {
    if (form.valid) {
      this.siteService.setUpGoogle(form.value).subscribe(res=>{
        if(res){
          this.activeModal.close();
          this.toastrService.success('Google Setup Done','success');
        }
      },
      err=>{
        this.toastrService.error(err.error);
      })
      }
      
    }

}

