// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error{
    color: red !important;
    background: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/corporate-partner/audit-request/audit-request/audit-request.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,2BAA2B;AAC/B","sourcesContent":[".error{\n    color: red !important;\n    background: none !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
