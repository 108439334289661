// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.btn {
  background-color: rgb(53, 151, 236);
  color: rgb(255, 255, 255);
}

a.btn:hover {
  filter: brightness(1.10);
}

a > span {
  border-radius: 2px;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1rem;
  vertical-align: middle;
}

.add-location {
  background-color: rgb(0, 168, 155) !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/buttons/add-button/add-button.component.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,6CAA6C;AAC/C","sourcesContent":["a.btn {\n  background-color: rgb(53, 151, 236);\n  color: rgb(255, 255, 255);\n}\n\na.btn:hover {\n  filter: brightness(1.10);\n}\n\na > span {\n  border-radius: 2px;\n  font-size: 2rem;\n  font-weight: bold;\n  line-height: 1rem;\n  vertical-align: middle;\n}\n\n.add-location {\n  background-color: rgb(0, 168, 155) !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
