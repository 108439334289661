import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SiteService } from '../../../service/site.service';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';

@Component({
  selector: 'app-add-insta-page',
  templateUrl: './add-insta-page.component.html',
  styleUrls: ['./add-insta-page.component.css']
})
export class AddInstaPageComponent implements OnInit {
  siteId;
  instapageForm:UntypedFormGroup;
  modalService;
  existingData;
  msg;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private router: Router,
    private toastrService: ToastrService,
    private siteService:SiteService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm(){
    this.instapageForm=this.formBuilder.group({
      id: [ this.existingData?this.existingData.id: 0],
      siteId:[this.existingData?this.existingData.siteId: this.siteId],
      pageId:[this.existingData?this.existingData.pageId: null,[Validators.required]],
      pageName:[ this.existingData?this.existingData.pageName: null,[Validators.required]]
    });
  }
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
  addAccount(form) {
    if (form.valid) {
      this.siteService.addInstaPage(form.value).subscribe(res=>{
        if(res){
          this.activeModal.close();
          this.toastrService.success(this.msg,'success');
        }
      },
      err=>{
        this.toastrService.error(err.error);
      })
      }
      
    }
}
