import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routing } from './pages.routing';
import { Pages } from './pages.component';
import { CoreModule} from '../core/core.module';
import { AdminModule} from './admin/admin.module';
import { CorporatePartnerModule} from './corporate-partner/corporate-partner.module';
import { AccountManagerModule} from './account-manager/account-manager.module';
import { OnboardingModule} from './onboarding/onboarding.module';
import { ProductManagerModule} from './product-manager/product-manager.module';
import { ClientCommunicationManagerModule} from './client-communication-manager/client-communication-manager.module';
import { DeveloperModule} from './developer/developer.module';
import { DesignerModule} from './designer/designer.module';
import { SeoModule } from './seo/seo.module';
import { BusinessDevelopementModule } from './business-developement/business-developement.module';
import { SalesAndMarketingModule } from './sales-and-marketing/sales-and-marketing.module';
import { CustomerModule} from './customer/customer.module';
import { LoginComponent } from './login/login.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import {SiteModule} from './site/site.module';
import {DownloadsModule} from './downloads/downloads.module';
import {TicketModule} from './ticket/ticket.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import {CallTrackingReportComponent} from './reporting/call-tracking-report/call-tracking-report.component';
import { ReportComponent } from './reporting/report.component';
import { SmmReportComponent } from './reporting/smm-report/smm-report.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {  BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { GaReportComponent } from './reporting/ga-report/ga-report.component';
import { FacebookAdsReportComponent } from './reporting/facebook-ads-report/facebook-ads-report.component';
import { SemRushReportComponent } from './reporting/sem-rush-report/sem-rush-report.component';
import { PpcReportComponent } from './reporting/ppc-report/ppc-report.component';
import { ManufacturerUpdatesComponent } from './reporting/manufacturer-updates/manufacturer-updates.component';
import { SharedModule } from '../shared/shared.module';
import { AudChartsModule } from '../shared/charts/charts.module';
import { DirectorModule } from './director/director.module';
import { GscReportComponent } from './reporting/gsc-report/gsc-report.component';
import { ReportingModule } from './reporting/reporting.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NewsfeedArticlesComponent } from './reporting/newsfeed-articles/newsfeed-articles.component';
import { BlogPostsComponent } from './reporting/blog-posts/blog-posts.component';
import { AmrpcReportComponent } from './reporting/amrpc-report/amrpc-report.component';
import { ReviewgenReportComponent } from './reporting/reviewgen-report/reviewgen-report.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactFormReportComponent } from './reporting/contact-form-report/contact-form-report.component';
import { ProjectComponent } from './reporting/project-report/project.component';
import {PiwikReportComponent} from '../components/reporting/piwik-report/piwik-report.component';
import { RoiPerformanceComponent } from '../components/reporting/roi-performance/roi-performance.component';
import { BusinessPerformanceComponent } from './reporting/business-performance/business-performance.component';
import { SalesPerformanceComponent } from './reporting/sales-performance/sales-performance.component';
import { SearchMarketingModule } from './search-marketing/search-marketing.module';
import { LlReportComponent } from './reporting/ll-report/ll-report.component';
import { ContactsComponent } from './reporting/blog-posts/contacts/contacts.component';
import { FormDetailsComponent } from './reporting/blog-posts/form-details/form-details.component';
import { ContentComponent } from './reporting/blog-posts/content/content.component';
import { KeywordRankingComponent } from './reporting/blog-posts/keyword-ranking/keyword-ranking.component';
import { AlertPopupComponent } from '@shared/alert-popup/alert-popup.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PMSReportComponent } from './reporting/blog-posts/pmsreport/pmsreport.component';
import { RightSectionComponent } from './login/right-section/right-section.component';
import { PromotionsPanelComponent } from './login/promotions-panel/promotions-panel.component';
import { PromotionsItemComponent } from './login/promotions-item/promotions-item.component';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';


@NgModule({
    providers: [provideCharts(withDefaultRegisterables())],

    imports: [
        CommonModule,
        AdminModule,
        SharedModule,
        AudChartsModule,
        ReportingModule,
        TableModule,
        TableModule,
        DropdownModule,
        CorporatePartnerModule,
        AccountManagerModule,
        DirectorModule,
        ProductManagerModule,
        DeveloperModule,
        NgbModule,
        DesignerModule,
        BusinessDevelopementModule,
        SalesAndMarketingModule,
        SeoModule,
        SearchMarketingModule,
        NgCircleProgressModule,
        ClientCommunicationManagerModule,
        OnboardingModule,
        CustomerModule,
        routing,
        CoreModule,
        ReactiveFormsModule,
        FormsModule,
        SiteModule,
        DownloadsModule,
        TicketModule,
        BsDatepickerModule.forRoot(),
        PaginatorModule,
        BaseChartDirective 
    ],
    declarations: [
        Pages,
        LoginComponent,
        SetPasswordComponent,
        ForgotPasswordComponent,
        UserProfileComponent,
        ChangePasswordComponent,
        CallTrackingReportComponent,
        ReportComponent,
        SmmReportComponent,
        GaReportComponent,
        LlReportComponent,
        FacebookAdsReportComponent,
        GscReportComponent,
        SemRushReportComponent,
        PpcReportComponent,
        AlertPopupComponent,
        ManufacturerUpdatesComponent,
        NewsfeedArticlesComponent,
        BlogPostsComponent,
        ProjectComponent,
        AmrpcReportComponent,
        ContactFormReportComponent,
        PiwikReportComponent,
        RoiPerformanceComponent,
        BusinessPerformanceComponent,
        SalesPerformanceComponent,
        ReviewgenReportComponent,
        ContactsComponent,
        ContentComponent,
        KeywordRankingComponent,
        FormDetailsComponent,
        TermsAndConditionsComponent,
        PMSReportComponent,
        RightSectionComponent,
        PromotionsPanelComponent,
        PromotionsItemComponent,
    ]
})
export class PagesModule { }
