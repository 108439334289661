import { Component, Input, OnInit } from '@angular/core';
import { InputGeneric } from '@shared/interfaces/forms';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-chip-group',
  templateUrl: './chip-group.component.html',
  styleUrls: ['./chip-group.component.css'],
})
export class ChipGroupComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() name: string;
  @Input() label: string;
  @Input() options: InputGeneric[];
  @Input() required: boolean;

  constructor() { }

  ngOnInit(): void {}

  /**
   * @deprecated Doesn't seem to be needed anymore. Remove after testing.
   */
  get controlValueIsValid(): boolean {
    return Array.isArray(this.control.value);
  }
}
