import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import {MasterService} from '../../services/master.service';
import { from } from 'rxjs';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../../services/http.service';

@Component({
  selector: 'app-add-master-type',
  templateUrl: './add-master-type.component.html',
  styleUrls: ['./add-master-type.component.css']
})
export class AddMasterTypeComponent implements OnInit {
  addMasterForm:UntypedFormGroup;
  modalService;
  masterTypeId;
  master;
  isEdit;
  masterType;
  action;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    public activeModal: NgbActiveModal,
    public masterService:MasterService,
    private router: Router,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }
  initializeForm(){
    this.action=this.master?'Edit':'Add';
    this.addMasterForm=this.formBuilder.group({
      id: [ this.master?this.master.id: null],
      name:[this.master?this.master.name :'',[Validators.required]],
      description:[ this.master?this.master.description:'']
    });
  }
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
  addMaster(form) {
    if (form.valid) {
      let masterInfo = form.value;
      masterInfo.masterTypeId=this.masterTypeId;
      if (masterInfo.id) {
        this.masterService.updateMaster(masterInfo).subscribe(res => {
          this.activeModal.close();
        },
          err => {
            this.httpService.openErrorPopup(err.error.message);
          });
      } else {
        masterInfo.id=0;
        this.masterService.addMaster(masterInfo).subscribe(res => { 
          this.activeModal.close();
        },
          err => {
            this.httpService.openErrorPopup(err.error.message);
          });
      }
      
    }
    
  }
  
}
