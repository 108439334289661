// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link.completed {
    cursor: pointer;
  }
  .nav-link.incomplete {
    cursor: pointer;
  }
  .nav-link.active {
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/basic/content/content.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB","sourcesContent":[".nav-link.completed {\n    cursor: pointer;\n  }\n  .nav-link.incomplete {\n    cursor: pointer;\n  }\n  .nav-link.active {\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
