import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yn',
})
export class YnPipe implements PipeTransform {
  /**
   * Pipe that coverts truthy/falsy values into yes/no strings
   *
   * @param { Boolean } value         The value being converted to 'Yes' or 'No'
   * @param { String } style          Capitalization style
   * @param { String } alternativeYes Alternative text for 'Yes'
   * @param { String } alternativeNo  Alternative text for 'No'
   *
   * @return {String} The formatted 'Yes' or 'No' string
   */
  transform(
    value: any,
    style: 'lower'|'upper'|'title' = 'title',
    alternativeYes ?: string,
    alternativeNo ?: string): string
  {
    const yes = 'Yes' ?? alternativeYes;
    const no = 'No' ?? alternativeNo;

    const text = value ? yes : no;

    switch (style) {
      case 'lower': return text.toLowerCase();
      case 'title': return text.charAt(0).toUpperCase() + text.slice(1);
      case 'upper': return text.toUpperCase();

      default: return text.charAt(0).toUpperCase() + text.slice(1);
    }
  }
}
