// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  font-weight: bold;
  font-size: 0.85rem;
  margin-bottom: 1.5rem;
}

p {
  font-size: 0.85rem;
}

section {
  padding: 1rem;
}

.circle {
  border-radius: 50%;
  display: inline-block;
  height: 0.5rem;
  margin-right: 0.75rem;
  width: 0.5rem;
}

.blue {
  background-color: blue;
}

.green {
  background-color: green;
}

.orange {
  background-color: orange;
}

.grey
{
  background-color: #ECECEC;
}

.greyText {
  color: #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/intakes/intake-sidebar-status-group/intake-sidebar-status-group.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,cAAc;EACd,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;;EAEE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb","sourcesContent":["h2 {\n  font-weight: bold;\n  font-size: 0.85rem;\n  margin-bottom: 1.5rem;\n}\n\np {\n  font-size: 0.85rem;\n}\n\nsection {\n  padding: 1rem;\n}\n\n.circle {\n  border-radius: 50%;\n  display: inline-block;\n  height: 0.5rem;\n  margin-right: 0.75rem;\n  width: 0.5rem;\n}\n\n.blue {\n  background-color: blue;\n}\n\n.green {\n  background-color: green;\n}\n\n.orange {\n  background-color: orange;\n}\n\n.grey\n{\n  background-color: #ECECEC;\n}\n\n.greyText {\n  color: #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
