import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-nav-tab',
  templateUrl: './nav-tab.component.html',
  styleUrls: ['./nav-tab.component.css'],
})
export class NavTabComponent implements OnInit {
  @Input() active = false;
  @Input() label: string;

  @Output() onClick = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }
}
