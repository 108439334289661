import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { InputGeneric } from '@shared/interfaces/forms';
import {
  states as StatesFile,
  countries as CountriesFile,
  getCountrySubdivision,
  getCountryData
} from '@shared/data/locations';
import { ClinicLocationsService } from '@shared/services/clinic-locations/clinic-locations.service';
import { AddressData, CountryData } from '@shared/interfaces/addresses';
import { SiteInfo } from '@shared/interfaces/interfaces';
import {
  bermudanPostalCodeValidator,
  canadianPostalCodeValidator,
  caymanianPostalCodeValidator,
  usPostalCodeValidator
} from '@shared/validators/postalCodes';
import { countrySubdivisionLabel, postalCodeLabelType } from '@shared/types/types';
import tooltipText from '@shared/text/tooltips.json';
import { DataResponse } from '@shared/interfaces/responses';
import { NotificationsService } from '@shared/services/notifications/notifications.service';

@Component({
  selector: 'app-manage-locations-form',
  templateUrl: './manage-locations-form.component.html',
  styleUrls: ['./manage-locations-form.component.css'],
})
export class ManageLocationsFormComponent implements OnInit {
  @Input() defaultState = undefined;
  @Input() defaultCountry = 'us';
  @Input() hasBillingAddress: boolean;
  @Input() site: SiteInfo;

  @Output() hideForm = new EventEmitter<any>();
  @Output() onUpdate = new EventEmitter<any>();

  billingTooltip: string;
  countryData: CountryData = null;
  countrySubdvisionLabel: countrySubdivisionLabel = 'State';

  constructor(
    private clinicLocationsService: ClinicLocationsService,
    private notificationsService: NotificationsService,
  ) { }

  newLocationForm = new UntypedFormGroup({
    address1: new UntypedFormControl(null, Validators.required),
    address2: new UntypedFormControl(null),
    billingAddress: new UntypedFormControl(null, Validators.required),
    city: new UntypedFormControl(null, Validators.required),
    clinicLocation: new UntypedFormControl(null),
    country: new UntypedFormControl('us', Validators.required),
    locationName: new UntypedFormControl(null, Validators.required),
    mailingAddress: new UntypedFormControl(null),
    postalCode: new UntypedFormControl(null, [
      Validators.required, Validators.maxLength(10),
    ]),
    primaryLocation: new UntypedFormControl(null),
    state: new UntypedFormControl(null, Validators.required),
  });

  countries: InputGeneric[] = CountriesFile;
  states: InputGeneric[] = StatesFile;
  // @TODO: Change 'Post Code' to 'Postal Code' once form is made more responsive and there is space
  postalCodeLabel: postalCodeLabelType;
  postalCodeMaxLength = 10;
  postalCodePlaceholder = 'Please enter your Postal Code';
  provinces: InputGeneric[] = [];

  ngOnInit(): void {
    // Initializing Postal Code input attributes
    this.getStatesProvinces();
    this.updatePostalCodePlaceholder();
    this.updatePostalCodeValidators(this.newLocationForm.get('country').value);
    this.updatePostalCodeMaxLength();

    if (this.hasBillingAddress) {
      this.billingTooltip = tooltipText.billing.addressExistsOverwrite;
      this.newLocationForm.controls['billingAddress'].setValidators([]);
    } else {
      this.newLocationForm.get('billingAddress').enable();
      this.newLocationForm.get('billingAddress').setValidators(Validators.required);
      this.billingTooltip = tooltipText.billing.addressRequired;
    }

    // Updates validation for postalCode control, dynamically based on the selected country
    this.newLocationForm.get('country').valueChanges.subscribe((country: string) => {
      this.newLocationForm.controls['state'].setValue(null);
      this.newLocationForm.get('postalCode').setValue(null);
      this.updatePostalCodePlaceholder();
      this.updatePostalCodeMaxLength();
      this.updatePostalCodeValidators(country);
    });
  }

  /**
   * Returns postal code Validators specific to each country
   *
   * @param {string} countryCode
   *
   * @return {ValidatorFn[]}
   */
  getCountryValidator(countryCode: string): ValidatorFn {
    switch (countryCode) {
      case 'bm': return bermudanPostalCodeValidator();
      case 'ca': return canadianPostalCodeValidator();
      case 'ky': return caymanianPostalCodeValidator();
      case 'us': return usPostalCodeValidator();

      default: return Validators.required;
    }
  }

  /**
   * Parses the response to return the appropriate error message
   *
   * @param {DataResponse} errorResponse The response from the API call
   *
   * @return {String} The extracted errormessage
   */
  getErrorMessageFromResponse(errorResponse: DataResponse<any>): string {
    const errorArray = errorResponse.errorMessage.split('\n ');

    const errorSummary = errorArray.find((messageDetail: string) => {
      return messageDetail.includes('Summary: ');
    });

    return errorSummary.split('Summary: ')[1];
  }

  /**
   * Sets the list of states/provinces and the appropriate menu label
   *
   * @param {String} country The country of the clinic location address. Will load the country's
   *                         subdivision
   */
  getStatesProvinces(country = this.newLocationForm.get('country').value): void {
    this.countryData = getCountryData(country.toLowerCase());

    this.countrySubdvisionLabel = this.countryData.subdivisionName;
    this.postalCodeLabel = this.countryData.postalCodeLabel;
    this.states = getCountrySubdivision(country);

    this.newLocationForm.get('city').setValue('');
    this.newLocationForm.get('city').clearValidators();
    this.newLocationForm.get('city').updateValueAndValidity();

    if (!country) {
      this.states = StatesFile;
      this.countrySubdvisionLabel = 'State';
      this.postalCodeLabel = 'ZIP Code';
    }
  }

  /**
   * Resets the form and all the associated fields in the UI
   */
  resetForm(): void {
    this.newLocationForm.reset({ state: this.defaultState, country: this.defaultCountry });

    this.getStatesProvinces();
    this.hideForm.emit();
  }

  /**
   * Saves the location data to the API
   * @param {FormGroup} newLocation
   */
  saveLocation(newLocation: UntypedFormGroup) {
    const formData = newLocation.getRawValue();

    const addressData: AddressData = {
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      country: formData.country,
      isActive: true,
      isBillingAddress: formData.billingAddress === '1' || formData.billingAddress === true,
      isClinicLocation: formData.clinicLocation === '1' || formData.clinicLocation === true,
      isMailingAddress: formData.mailingAddress === '1' || formData.mailingAddress === true,
      isPrimary: formData.primaryLocation === '1' || formData.primaryLocation === true,
      name: formData.locationName,
      siteId: this.site.id,
      state: formData.state,
      zip: formData.postalCode,
    };

    this.clinicLocationsService.createAddress(addressData)
      .subscribe(
        (response) => {
          if (response.success) {
            this.notificationsService.showToastSuccess(
              `New Location (${ addressData.name }) has been saved.`,
              'Success'
            );
          } else {
            this.notificationsService.clearToastMessage();

            this.notificationsService.showToastError(
              this.getErrorMessageFromResponse(response),
              'Address Error'
            );

            throw new Error(this.getErrorMessageFromResponse(response));
          }
        },
        () => {},
        () => {
          this.resetForm();
          this.onUpdate.emit();
        }
      );
  }

  /**
   * Sets the max length according to the length of postal codes for the selected country
   */
  updatePostalCodeMaxLength() {
    switch (this.newLocationForm.get('country').value) {
      case 'bm': this.postalCodeMaxLength = 5; break;
      case 'ca': this.postalCodeMaxLength = 7; break;
      case 'ky': this.postalCodeMaxLength = 8; break;
      case 'us': this.postalCodeMaxLength = 10; break;
    }
  }

  /**
   * Updates the placeholder text for the Postalcode dropdown
   */
  updatePostalCodePlaceholder() {
    switch (this.newLocationForm.get('country').value) {
      case 'bm': this.postalCodePlaceholder = 'XX 11'; break;
      case 'ca': this.postalCodePlaceholder = 'X1X-1X1'; break;
      case 'ky': this.postalCodePlaceholder = 'KY1-1234'; break;
      case 'us': this.postalCodePlaceholder = '12345 or 12345-1234'; break;
    }
  }

  /**
   * Replaces the Validator from the postalCode control with one based on the selected country
   *
   * @param {String} countryCode The two-letter country code of the chosen country
   * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
   */
  updatePostalCodeValidators(countryCode: string): void {
    this.newLocationForm.controls['postalCode'].setValidators(
      [this.getCountryValidator(countryCode)]
    );

    this.newLocationForm.controls['postalCode'].updateValueAndValidity();
  }
}
