import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CcmService {

  constructor(
    private httpService: HttpService
  ) { }
  getDasboardData(userInfo){
    return this.httpService.getApi(`Report/Dashboard`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }
  getBroadcastNotifications(): Observable<any[]> {
    return this.httpService.getApi(`site/BroadcastNotification`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
}
