import {
  Subscription as SubscriptionInterface,
  SubscriptionProductMetadata
} from '@shared/interfaces/models';


export class Subscription implements SubscriptionInterface {
  billingInterval: string;
  directBilled: boolean;
  listPrice: number;
  productMetadata = new Array<SubscriptionProductMetadata>();
  siteId: number;
  stripePriceId: string;
  quantity = 1;

  constructor(data: SubscriptionInterface) {
      this.billingInterval = data.billingInterval;
      this.directBilled = data.directBilled;
      this.listPrice = data.listPrice;
      this.siteId = data.siteId;
      this.stripePriceId = data.stripePriceId;
  }

  addMetadata(key, value): Subscription {
    this.productMetadata.push({'key': key, 'value': value});

    return this;
  }

  getSubscriptionData(): SubscriptionInterface {
    return {
      billingInterval: this.billingInterval,
      directBilled: this.directBilled,
      listPrice: this.listPrice,
      productMetadata: this.productMetadata,
      quantity: this.quantity,
      siteId: this.siteId,
      stripePriceId: this.stripePriceId
    };
  }
}
