import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';
import { ServiceBuilder } from 'selenium-webdriver/edge';

@Injectable({
  providedIn: 'root'
})
export class BuildSiteService {

  constructor(
    private httpService: HttpService
  ) { }

  headerSection=[
    {
       "id":"basic_header_with_standard_menu",
       "image":"wp-content/themes/options-framework/images/basic.png"
    },
    {
       "id":"basic_header_with_alternative_menu",
       "image":"wp-content/themes/options-framework/images/basic-altMenu.png"
    },
    {
       "id":"basic_inline_header",
       "image":"wp-content/themes/options-framework/images/inline-topbar.png"
    }
  ];
  footerSection=[
    {
       "id":"basic_footer_option1",
       "image":"wp-content/themes/options-framework/images/basic_footer_option1.png"
    },
    {
       "id":"basic_footer_option2",
       "image":"wp-content/themes/options-framework/images/basic_footer_option2.png"
    },
    {
       "id":"3column_footer_option1",
       "image":"wp-content/themes/options-framework/images/3column_footer_option1.png"
    },
    {
       "id":"3column_footer_option2",
       "image":"wp-content/themes/options-framework/images/3column_footer_option2.png"
    }
  ];
  ctaBlockLayout=[
  {
    "id":"cta_with_image1",
    "image":"wp-content/themes/options-framework/images/cta_with_image.png"
 },
 {
    "id":"cta_2column_with_image",
    "image":"wp-content/themes/options-framework/images/cta_2column_with_image.png"
 },
 {
    "id":"cta_with_icon",
    "image":"wp-content/themes/options-framework/images/cta_with_icon.png"
 },
 {
    "id":"cta_2column_with_icon",
    "image":"wp-content/themes/options-framework/images/cta_2column_with_icon.png"
 }
]
typicalBlockLayout=[
   {
  "id":"typical_content_aligned_middle",
  "image":"wp-content/themes/options-framework/images/typical_content_aligned_middle.png"
},
{
  "id":"typical_content_aligned_bottom",
  "image":"wp-content/themes/options-framework/images/typical_content_aligned_bottom.png"
},
{
  "id":"typical_2column_with_background",
  "image":"wp-content/themes/options-framework/images/typical_2column_with_background.png"
},
{
  "id":"typical_2column_with_background_with_offset",
  "image":"wp-content/themes/options-framework/images/typical_2column_with_background_with_offset.png"
},
{
  "id":"typical_multi_column",
  "image":"wp-content/themes/options-framework/images/typical_multi_column.png"
},
{
  "id":"typical_single_column_centered",
  "image":"wp-content/themes/options-framework/images/typical_single_column_centered.png"
}
]
ServiceBlockLayout=[
  {
    "id":"services_style_option1",
    "image":"wp-content/themes/options-framework/images/services_style_option1.png"
 },
 {
    "id":"services_style_option2",
    "image":"wp-content/themes/options-framework/images/services_style_option2.png"
 },
 {
    "id":"services_style_option3",
    "image":"wp-content/themes/options-framework/images/services_style_option3.png"
 }
]
manufacturersBlock=[
  {
    "id":"manufacturers_option1",
    "image":"wp-content/themes/options-framework/images/manufacturers_option1.png"
 }
]

  getWpThemesData(): Observable<any> {
    return this.httpService.getApi(`WordPress/GetWPThemes`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }
  getWpTemplateData(): Observable<any> {
    return this.httpService.getApi(`WordPress/GetWPTemplates`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }
  getIntakeSavedPages(intakeId, ProductSectionId): Observable<any> {
    return this.httpService.getApi(`Intake/IntakeSavedPages?IntakeId=${intakeId}&ProductSectionId=${ProductSectionId}`)
      .pipe(
        tap(res => {
          return res;
        })
      );
  }
  updateIntake(intakeData): Observable<any> {
    return this.httpService.putApi(`WordPress/BuildSite`, intakeData, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  bulidSite(intakeId):Observable<any>{
    return this.httpService.postApi('WordPress/BuildSite/'+intakeId,{})
    .pipe(
      tap(res=>{
        if(res){
          return res;
        }
      })
    );
  }

  getDomain(userId, siteId): Observable<any> {
    return this.httpService.getApi(`site/getSite/${siteId}`)
      .pipe(
        tap(res => {
          return res;
        })
      );
  }
  getIntakeSiteId(IntakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/getIntake?IntakeId=${IntakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  runBluePrintProcess(){
    return this.httpService.postApi(`PMS/ReadFileFromFTP`,null)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      )
  }
  getReviewGenLog(filterBy){
    return this.httpService.getApi(`ReviewGen/Logs?Type=${filterBy.type==null?'':filterBy.type }&StartDate=${filterBy.startDate || ''}&EndDate=${filterBy.endDate || ''}`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }
  downloadFile(type,fileName){
    return this.httpService.getApi(`ReviewGen/Download?type=${type}&filename=${fileName}`,false)
    .pipe(
      tap(res=>{
        if(res){
          return res;
        }
      })
    )
  }
}
