import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-partnership-logos',
  templateUrl: './partnership-logos.component.html',
  styleUrls: ['./partnership-logos.component.css'],
})
export class PartnershipLogosComponent implements OnInit {
  @Input() corporatePartner: string;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Returns the logo image if the corporate partner is from a select list
   *
   * @return {String|boolean}
   */
  getCorporatePartnerLogo(): string[]|void {
    if (this.isSelectCorporatePartner()) {
      switch (this.corporatePartner.toLowerCase()) {
        case 'resound': return [
          'resound-lozenge-logo.svg',
          'resound-focus-logo.svg',
        ];
        case 'signia': return ['signia-logo.svg'];
        case 'cq partners': return ['cq-partners-logo.svg'];
      }
    }
  }

  /**
   * Checks if the Corporate Partner is in a list of preselected options
   *
   * @return {boolean}
   */
  isSelectCorporatePartner(): boolean {
    return ['cq partners', 'resound', 'signia'].includes(this.corporatePartner.toLowerCase());
  }
}
