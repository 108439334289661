// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `legend {
  font-size: 1rem;
}

.error {
  background-color: transparent !important;
  color: red;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/forms/radio-group/radio-group.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,wCAAwC;EACxC,UAAU;EACV,iBAAiB;AACnB","sourcesContent":["legend {\n  font-size: 1rem;\n}\n\n.error {\n  background-color: transparent !important;\n  color: red;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
