import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
// import { SiteService } from '../service/site.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { Observable, from } from 'rxjs';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
// import { PaymentHistoryComponent } from '../../customer/payment-history/payment-history.component';
// import { ManageCardComponent } from '../../customer/manage-card/manage-card.component';
// import { DowngradeProductComponent } from '../site-config/downgrade-product/downgrade-product.component';
// import {SiteProductComponent} from '../site-product/site-product.component';
// import {StartBillingComponent} from '../start-billing/start-billing.component';
import { UserService } from '../admin/users/services/user-service.service';

@Component({
  selector: 'app-site-setting',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})

export class DownloadsComponent implements OnInit {
 
  site;
  siteId;
  userId;
  allSites;
  ProductLists=[];
  productId;
  userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };

//  @ViewChild(PaymentHistoryComponent)
//  private PaymentHistoryComponent: PaymentHistoryComponent;
//  @ViewChild(ManageCardComponent)
//  private ManageCardComponent: ManageCardComponent;
//  @ViewChild(DowngradeProductComponent)
//  private DowngradeProductComponent: DowngradeProductComponent;
//  @ViewChild(SiteProductComponent)
//  private SiteProductComponent: SiteProductComponent;
//  @ViewChild(StartBillingComponent)
//  private StartBillingComponent: StartBillingComponent;

  constructor(
    // private siteService: SiteService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService
    ) { 
      // this.site = JSON.parse(localStorage.getItem("selectedSite"));
      // this.siteId = this.site ? this.site.siteId : null;
      this.productId = this.activatedRoute.snapshot.queryParamMap.get('productId');
      // this.getSites();
      this.userService.selectedSiteData.subscribe(site => {
        this.site = site || JSON.parse(localStorage.getItem("selectedSite"));
        this.siteId = this.site ? (this.site.id || this.site.siteId) : 0;
      })
    }

  ngOnInit() {
    
    this.ProductLists=[ 
      // {id: 1, name: "Payment History"},
      // {id: 2, name: "Manage Cards"}, 
      {id: 3, name: "Start Billing Subscription"},
      {id: 4, name: "Cancel Billing Subscription"},
      {id: 5, name: "Downgrade Package"},
      {id: 6, name: "Switch Package"},
      {id: 7, name: "Subscription Paused"}
    ]
    this.productId=1;
    this.userId = JSON.parse(localStorage.getItem('userInfo')).userId;
  }

  // getSelectedSiteId(siteId){
  //   this.siteId=siteId;
  //   if(this.PaymentHistoryComponent)
  //   this.PaymentHistoryComponent.callFromParent(this.siteId);
  //   if(this.ManageCardComponent)
  //   this.ManageCardComponent.callFromParent(this.siteId);
  //   if(this.DowngradeProductComponent)
  //   this.DowngradeProductComponent.callFromParent(this.siteId);
  //   if(this.SiteProductComponent)
  //   this.SiteProductComponent.callFromParent(this.siteId);
  //   if(this.StartBillingComponent)
  //   this.StartBillingComponent.callFromParent(this.siteId);
  //   console.log(siteId,'==selected site id')
  // }

//   getSites(){
//     this.siteService.getSites().subscribe((res) => {
//       if (res) {
//         this.allSites = Object.assign([], res);
// //        this.siteId=res[0].id;
//         // this.siteId=null;

//       }
//     },
//       err => {
//         throw err;
//       })
//   };

  search = (text$: Observable<string>) =>  
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 3 ? []
      : this.allSites.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )
  formatter = (result: string) => `${result['name']}`;
  inputFormatter = (result: string) => `${result['name']}`;


  ngOnDestroy(){
    this.userService.selectedSiteData.next(null);
  }
}
