// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.play-button {
  background: url(/assets/images/play-icon.svg) no-repeat 0 0;
  width: 27px;
  height: 27px;
  display: inline-block;
}

a.stop-button {
  background: url(/assets/images/stop_icon.svg) no-repeat 0 0;
  width: 27px;
  height: 27px;
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/buttons/play-stop-button/play-stop-button.component.css"],"names":[],"mappings":"AAAA;EACE,2DAA2D;EAC3D,WAAW;EACX,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,2DAA2D;EAC3D,WAAW;EACX,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":["a.play-button {\n  background: url(/assets/images/play-icon.svg) no-repeat 0 0;\n  width: 27px;\n  height: 27px;\n  display: inline-block;\n}\n\na.stop-button {\n  background: url(/assets/images/stop_icon.svg) no-repeat 0 0;\n  width: 27px;\n  height: 27px;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
