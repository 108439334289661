import { Component, OnInit } from '@angular/core';
import { SiteService } from '../../service/site.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {AddSocialMediaSetupComponent} from '../social-media-setup/add-social-media-setup/add-social-media-setup.component';

@Component({
  selector: 'app-social-media-setup',
  templateUrl: './social-media-setup.component.html',
  styleUrls: ['./social-media-setup.component.css']
})
export class SocialMediaSetupComponent implements OnInit {

  socialMedia;
  siteId;
  familyId;
  constructor(
    private siteService: SiteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    public modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.siteId = params.siteId;
      this.familyId = params.familyId;
      if (params.siteId) {
        this.getSocialMediaSetUp(params.siteId);
      }
    });
  }
  getSocialMediaSetUp(siteId){
    this.siteService.getSocialMediaSetup(siteId).subscribe(res=>{
      if(res){
        this.socialMedia=res;
      }
    },
    err=>{
      this.toastrService.error(err.error);
    })
  }
  goToEdit(media) {
    var model = this.modalService.open(AddSocialMediaSetupComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.siteId = this.siteId;
    model.componentInstance.existingData = media;
    model.result.then((result) => {
      setTimeout(() => {
        this.getSocialMediaSetUp(this.siteId);
      }, 50);
      console.log(`Update success: ${result}`);
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });

  }


}
