import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reset-button',
  templateUrl: './reset-button.component.html',
  styleUrls: ['./reset-button.component.css'],
})
export class ResetButtonComponent implements OnInit {
  @Input() disabled = false;
  @Input() label = 'Reset';
  @Input() width = 6;

  @Output() clickFunction = new EventEmitter<object>();

  constructor() { }

  ngOnInit(): void {
    if (this.label == '') {
      this.label = 'Reset';
    } else {
      let padding = 1;

      if (this.label.length % 2 == 0) {
        padding++;
      }

      this.width = ((this.label.length + padding) / 2) + 1;
    }
  }

  /**
   * Method to handle the button's click event
   * @param {Event} event
   */
  clickEvent(event): void {
    event.preventDefault();

    this.clickFunction.emit(event);
  }
}
