import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { HttpService } from '../../../../../../../services/http.service';
import { CustomPageModel } from './custom-models';

@Component({
  selector: 'app-custom-pages',
  templateUrl: './custom-pages.component.html',
  styleUrls: ['./custom-pages.component.css']
})
export class CustomPagesComponent implements OnInit {
  public panelOpenState: boolean = false;

  public loading: boolean = false;
  activeIds: string[] = [];
  searchKeyword;
  @Input() selectedItem;
  @Input() siteId;
  @Input() intakeTypeId;
  intakeId: number = null;
  createdPages: Array<Object> = [];
  selectedPageList: Array<Object> = [];
  @Output() continueClicked = new EventEmitter<any>();
  @Output() backClicked = new EventEmitter<any>();
  @Output() changeStatus = new EventEmitter<any>();
  createdPagesData;
  filteredData = [];
  customPageLists = {
    "ProductSectionId": null,
    "IntakeId": null,
    "lstIntakePages": []
  };
  checkedAll: boolean = false;

  constructor(
    private intakesService: IntakesService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef  

  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
      }
    });
  }

  ngOnInit() {
    this.getCustomSavedPages(this.selectedItem.name, this.intakeId);
    this.expandAll();
  }

  getCustomSavedPages(selectedItemName, intakeId) {
    this.intakesService.getPageList(selectedItemName, intakeId).subscribe((response) => {
      if (response) {
        this.createdPagesData = response;
        this.createdPages = Object.assign([], this.createdPagesData);
        _.forEach(this.createdPages, (item) => {
          if (item && item.selected) {
            this.checkedAll = true;
          } else {
            this.checkedAll = false;
          }
        });
      }
    }, err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  addNewCustomPages() {
    const newPage = new CustomPageModel(true);
    this.createdPages.push(newPage);
    const newIndex = this.createdPages.length - 1; // Get the index of the newly added page
    this.activeIds.push(newIndex.toString()); // Expand the newly added page by adding its index to activeIds
    this.checkIfAllSelected();
  }

  saveCustomPages(mode?) {
    this.selectedPageList = [];
    let i = -1;
    _.forEach(this.createdPages, (item) => {
      if (item && item.selected) {
        item.itemId = i;
        this.selectedPageList.push(item);
        i--;
      };
    });
    var valueArr = this.selectedPageList.map(function (item) { return item['title'] });
    let isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx
    });
    console.log(isDuplicate);
    if (isDuplicate) {
      this.httpService.openErrorPopup("You cannot add more than one page with the same title");
      return;
    }

    this.customPageLists = {
      "ProductSectionId": this.selectedItem.id,
      "IntakeId": this.intakeId,
      "lstIntakePages": this.selectedPageList
    };

    this.selectedItem['status'] = 2;
    let sectionStatusData = {
      intakeId: this.intakeId,
      lstIntakeSection: [{
        productSectionId: this.selectedItem.id,
        status: this.selectedItem.status,
        percentage: this.selectedItem.percentage
      }]
    };

    this.intakesService.saveCustomPages(this.customPageLists).subscribe((response) => {
      if (response) {
        this.changeStatus.next();
        this.toastrService.success('Custom pages saved successfully', 'Success');
        this.postIntakeSection(sectionStatusData);
        this.getCustomSavedPages(this.selectedItem.name, this.intakeId);
        if (mode == 'continue') {
          this.goToNext('parent');
        }
      }
    }, err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  iterate(bool) {
    _.forEach(this.createdPages, (item) => {
      item.selected = bool;
    });
  }

  customSelectAll(event) {
    event.stopPropagation();
    if (event.target.checked) {
      this.iterate(true);
    } else {
      this.iterate(false);
    }
  }

  checkIfAllSelected() {
    _.forEach(this.createdPages, (item) => {
      this.checkedAll = true;
      if (!item.selected) {
        this.checkedAll = false;
        return false;
      }
    });
  }

  stopPreventDefault(event) {
    event.stopPropagation();
  }

  expandAll() {
    this.panelOpenState = true;
    this.cdr.detectChanges();
  }

  collapseAll() {
    this.panelOpenState = false;
    this.cdr.detectChanges();
  }

  goBack() {
    this.backClicked.next();
  }

  goToNext(menu) {
    this.continueClicked.next(menu);
  }

  applyFilter(name) {
    this.createdPages = _.cloneDeep(this.createdPagesData);
    this.filteredData = this.createdPages;
    if (!_.isEmpty(name)) {
      this.filteredData = _.filter(this.filteredData, (data) => {
        return (_.includes(data['name'].toLowerCase(), name)) && data.type == 'P';
      });
      this.createdPages = this.filteredData;
    }
  }

deletePage(event: Event, index: number) {
    event.stopPropagation(); // Prevent panel expansion/collapse

    const pageTitle = this.createdPages[index]['title']; // Get the title of the page being deleted
    const confirmed = window.confirm(`Are you sure you want to delete the page titled "${pageTitle}"?`);

    if (confirmed) {
        this.createdPages.splice(index, 1); // Remove the page at the specified index
        this.saveCustomPages(); // Auto-save after deletion
    }
}

}
