import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { hasPermission } from '@shared/policies/permissions';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { CounselEarService } from '@shared/services/practice-management-software/counsel-ear.service';
import moment from 'moment';
import { UserService } from '../../../../admin/users/services/user-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@shared/confirm-modal/confirm-modal.component';
import { NotificationsService } from '@shared/services/notifications/notifications.service';
import { CounselEarAddComponent } from '../counsel-ear-add/counsel-ear-add.component';
import { CounselEarSetup } from '@shared/interfaces/counsel-ear';
import { CounselEarEditComponent } from '../counsel-ear-edit/counsel-ear-edit.component';

@Component({
  selector: 'app-counsel-ear',
  templateUrl: './counsel-ear.component.html',
  styleUrls: ['./counsel-ear.component.css'],
})
export class CounselEarComponent implements OnInit, PageHasTable {
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader<CounselEarSetup>[];
  councilEarData = [];
  date = [];
  dateEnd: string;
  dateStart: string;
  hasPermission = hasPermission;
  selectedSite: string | any;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));

  constructor(
    private councilEarService: CounselEarService,
    private modalService: NgbModal,
    private notificationsService: NotificationsService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.dateEnd = moment().endOf('month').format('YYYY-MM-DD');
    this.dateStart = moment().startOf('month').format('YYYY-MM-DD');

    this.selectedSite = JSON.parse(localStorage.getItem('selectedSite'));

    this.getTableData();
  }

  /**
   *
   * @param {CounselEarSetup} locationData The CounselEar location Data
   */
  deleteCouncilEarEntry(locationData: CounselEarSetup): void {
    const modal = this.modalService.open(ConfirmModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });

    modal.componentInstance.message = 'Are you sure you would like to delete this location?';

    modal.result.then((confirm) => {
      if (confirm) {
        this.councilEarService.deleteSetup(locationData).subscribe(
          (res) => {
            if (res) {
              this.notificationsService.showToastSuccess(
                'The location has been removed',
                'Location Removed'
              );
            }
          },
          (error) => {},
          () => {
            this.getTableData();
          }
        );
      }
    });
  }

  /**
   * Retrieves all Council Ear data from the API
   */
  getTableData(): void {
    this.councilEarService.getSetups(this.selectedSite.id).subscribe(
      (res: CounselEarSetup[]) => {
        if (res) {
          this.councilEarData = res;
          this.changeDetectorRef.detectChanges();  // Force change detection
        }
      }
    );

    this.columnDataTypes = {
      apptDate: 'date',
      phoneCell: 'phone',
    };

    this.columnHeaders = [
      { field: 'counselear_location_id', header: 'Location ID' },
      { field: 'location_name', header: 'Location' },
      { field: 'profile_name', header: 'ReviewGen Profile' },
    ];
  }

  /**
   * Opens the modal with the add form
   */
  openAddModal(): void {
    const modal = this.modalService.open(
      CounselEarAddComponent,
      {
        ariaLabelledBy: 'modal-basic-title',
        backdrop: 'static',
        centered: true,
      }
    );

    /* Requery the table data */
    modal.result.then(
      () => {
        this.getTableData();
      },
      () => {
        this.getTableData();
      }
    );
  }

  /**
   * Opens a modal with the Edit Setup form
   *
   * @param {CounselEarSetup} data
   */
  openEditPopup(data: CounselEarSetup): void {
    const modal = this.modalService.open(
      CounselEarEditComponent,
      { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }
    );

    modal.componentInstance.setupData = data;
    modal.componentInstance.onLocationEdit.subscribe(() => {
      this.getTableData();
    });

    modal.result.then(
      () => {
        this.getTableData();
      },
      () => {
        this.getTableData();
      }
    );
  }

  dateChange(event: Array<object>): void {
    if (event && event.length > 1) {
      this.dateStart = moment(event[0]).format('YYYY-MM-DD');
      this.dateEnd = moment(event[1]).format('YYYY-MM-DD');

      this.getTableData();
    }
  }
}
