import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SiteService } from '../service/site.service';
import { ToastrService } from 'ngx-toastr';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-subscription-paused',
  templateUrl: './subscription-paused.component.html',
  styleUrls: ['./subscription-paused.component.css'],
})
export class SubscriptionPausedComponent implements OnInit, PageHasTable {
  @Input() selectedSiteId;

  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  products;
  siteIsLock;

  @ViewChild('dt') table: TableGenericComponent;

  constructor(
    private siteService: SiteService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.siteIsLock = this.siteService.siteIsLock;

    this.getProduct(this.selectedSiteId);

    this.getTableData();
  }

  callFromParent(site) {
    this.selectedSiteId = site.id;
    this.siteIsLock = site.isLock;
    this.getProduct(this.selectedSiteId);
  };

  getProduct(siteId) {
    this.siteService.getPausedSubscription(siteId).subscribe(res => {
        this.products = res;
    }, err => {
      throw err;
    })
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      trialEndOn: 'date',
    };

    this.columnHeaders = [
      { field: 'products', header: 'Product Name', filter: 'none', width: 50},
      { field: 'siteName', header: 'Site Name', filter: 'none'},
      { field: 'paymentType', header: 'Payment Type', filter: 'none'},
      { field: 'trialEndOn', header: 'Trial End Date', filter: 'none'},
    ];
  }

  startBilling(product){
    // if (days >= 0) {
      let body = {
        siteId: this.selectedSiteId,
        paymentType: product['paymentType'],
        trialDays: 0
      }
      this.siteService.pauseSubscription(body).subscribe(res => {
        if (res) {
          this.toastrService.success('Subscription has been started successfully', 'Success');
          this.getProduct(this.selectedSiteId);
        }
      }, err => {
        throw err;
      })
    }
}
