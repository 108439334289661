import { Component, OnInit, Input } from '@angular/core';
import { ReportingService } from '../services/reporting.service';
import { ToastrService } from 'ngx-toastr';
// import { Chart } from 'angular-highcharts';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';
import { HttpService } from '../../../services/http.service';


@Component({
  selector: 'app-smm-report',
  templateUrl: './smm-report.component.html',
  styleUrls: ['./smm-report.component.css']
})
export class SmmReportComponent {
  @Input() siteId;
  smmReport;
  yearsData = [];
  today = new Date();
  reportParams = { accountId: '', year: '2019', month: '1',
  startDate: moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY'),
  endDate: moment(moment()).subtract(1, 'months').endOf('month').format('MM/DD/YYYY'),
  _startDate: moment().subtract(1, 'months').startOf('month').format('MM/DD/YY'),
  _endDate: moment(moment()).subtract(1, 'months').endOf('month').format('MM/DD/YY')
};
  accountData;
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {},
      y: {
        min:0,
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartColors = [{
    backgroundColor: '#83cefc'
  }]
  public barChartLabels: String[];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartData: ChartDataset[];
  constructor(
    private reportingService: ReportingService,
    private httpService: HttpService,
    private toastrService: ToastrService
  ) {
  }
  
  // ngOnInit() {
  //   this.getAccountsData(this.siteId);
  // }

  ngOnChanges() {
    this.getAccountsData(this.siteId);
  }

  getReport(params) {
    let that=this;
    params['formattedStartDate'] = params.startDate;
    params['formattedEndDate'] = params.endDate;
      this.reportingService.getSmmReport(params).subscribe((res: any) => {
        if (res) {
         that.setSMMData(res);
        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        });

  }
  setSMMData(res){
    this.smmReport={};
    _.forEach(res.results, (post) => {
      this.getBase64FromImage(post.picture, function (base64Img) {
        post.avatarUrl = base64Img;
      })
    })

    this.smmReport = res;
    this.createChart(this.smmReport.monthly_posts);
  }

  createChart(posts) {
    let categories = [];
    let data = [];
    _.forIn(posts, function (value, key) {
      categories.push(key);
      data.push(value);
    });
    this.barChartData = [
      { data: data, borderWidth:1, label: 'Posts' }
    ];
    this.barChartLabels = categories;

  }

  downloadReport(report: string) {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = 'none';
    var collapsedElements = document.getElementsByClassName('collapse');
    var showLess = document.querySelectorAll('.show-less, .drop-icon');
    _.forEach(collapsedElements, (element) => {
      element.className += ' show';
    })
    _.forEach(showLess, (element) => {
      element.style.display = 'none';
    })
    var element = document.getElementById('report');
    var opt = {
      margin: 0,
      filename: 'social-media-marketing.pdf',
      jsPDF: { unit: 'in', format: 'b3', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };

    // document.getElementById("barChart").style.width = '100%';

    if (report == 'print') {
      html2pdf().from(element).set(opt).output('dataurlnewwindow').then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        _.forEach(showLess, (element) => {
          element.style.display = 'block';
        })
      });
    } else {
      html2pdf().from(element).set(opt).save().then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        _.forEach(showLess, (element) => {
          element.style.display = 'block';
        })
      });
    }


  }


  getAccountsData(siteId) {
    this.reportingService.getAccountsData(siteId).subscribe((res: any) => {
      if (res) {
        this.accountData = res;
        this.reportParams['accountId'] = res.length ? res[0].accountId : null;
        this.getReport(this.reportParams);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getBase64FromImage(url, onSuccess) {
    var xhr = new XMLHttpRequest();

    xhr.responseType = "arraybuffer";
    xhr.open("GET", url);

    xhr.onload = function () {
      var base64, binary, bytes, mediaType;

      bytes = new Uint8Array(xhr.response);
      //NOTE String.fromCharCode.apply(String, ...
      //may cause "Maximum call stack size exceeded"
      binary = [].map.call(bytes, function (byte) {
        return String.fromCharCode(byte);
      }).join('');
      mediaType = xhr.getResponseHeader('content-type');
      base64 = [
        'data:',
        mediaType ? mediaType + ';' : '',
        'base64,',
        btoa(binary)
      ].join('');
      onSuccess(base64);
    };
    xhr.send();
  }

  onValueChange(event) {
    this.reportParams['startDate'] = moment(event[0]).format('MM/DD/YYYY');
    this.reportParams['endDate'] = moment(event[1]).format('MM/DD/YYYY');
    this.reportParams['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.reportParams['_endDate'] = moment(event[1]).format('MM/DD/YY');
    this.getReport(this.reportParams);
  }

}
