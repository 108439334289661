import { Injectable } from '@angular/core';
import { IntakesService } from 'app/components/customer/intakes/services/intakes.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TimeListService {
  private _timeList: string[];

  constructor(private intakesService: IntakesService) { }

  getTimeList(): Observable<string[]> {
    if(this._timeList) {
      return of(this._timeList);
    }
    return this.intakesService.getMaster(49).pipe(
      map((res) => {
        if(res){
          this._timeList = this.timesInBetween(res['masterList'][0]['name'],res['masterList'][1]['name']);
          return this._timeList;
        }
      })
    );
  }

  private timesInBetween(start, end) {
    let getGenTime = (timeString) => {
      let H = +timeString.substr(0, 2);
      let h = (H % 12) || 12;
      let ampm = H < 12 ? " AM" : " PM";
      return timeString = h + timeString.substr(2, 3) + ampm;
    }
    var timesInBetween = [];

    var startH = parseInt(start.split(":")[0]);
    var startM = parseInt(start.split(":")[1]);
    var endH = parseInt(end.split(":")[0]);
    var endM = parseInt(end.split(":")[1]);

    if (startM == 30)
      startH++;

    for (var i = startH; i < endH; i++) {
      timesInBetween.push(i < 10 ? "0" + i + ":00" : i + ":00");
      timesInBetween.push(i < 10 ? "0" + i + ":30" : i + ":30");
    }

    timesInBetween.push(endH + ":00");
    if (endM == 30)
      timesInBetween.push(endH + ":30")

    return timesInBetween.map(getGenTime);
  }
}
