import { Component, OnInit } from '@angular/core';
import { DeveloperService } from '../services/developer.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpService } from '@services/http.service';
import { MenuService } from '@core/page-header/service/menu.service';

@Component({
  selector: 'app-dev-dashboard',
  templateUrl: './dev-dashboard.component.html',
  styleUrls: [
    '../../../core/dashboard/dashboard.component.css',
    './dev-dashboard.component.css',
  ],
})
export class DevDashboardComponent implements OnInit {
  dashboardData;
  notifications;
  pageNo = 1;
  showMore;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  constructor(
    private developerService: DeveloperService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private router: Router,
    private menuService: MenuService
  ) {
  }

  ngOnInit() {
    this.getDashboardData();
    this.getNotifications(0, this.pageNo);
  }

  getDashboardData() {
    this.developerService.getDasboardData(this.userInfo).subscribe(res => {
      if (res) {
        this.dashboardData = res[0];
        console.log(this.dashboardData);
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }


  gotoProjects(type?) {
    let params = {};
    switch (type) {
      case 'discover':
        params = { task_status: 0, product_type: -5, SiteInDiscover: true };
        break;
      case 'development':
        params = { task_status: 6, product_type: -5, task_sub_status: '-Resolved' };
        break;
      case 'design':
        params = { task_status: 5, product_type: -5, task_sub_status: '-Resolved' };
        break;
      case 'review':
        // params = { task_status: 10, task_sub_status: 'Review', product_type: -5 };
        params = { task_status: 10, product_type: -5, task_sub_status:'-Resolved' };
        break;
      case 'ancillary':
        // params = { task_status: 10, task_sub_status: 'Review', product_type: -5 };
        params = { task_status: 0, product_type: "-1,6,14" };
        break;
      default:
        params = {}
    }
    this.router.navigate(['designer-developer/projects'], { queryParams: params });
  }
  getNotifications(Siteid, pageNo) {
    this.menuService.getNotifications(Siteid, pageNo).subscribe((res) => {
      if (res) {
        this.pageNo = pageNo;
        this.notifications = res;
        this.showMore = this.notifications.length < (pageNo * 10) ? false : true;
      }
    },
      err => {
        throw err;
      });
  }

}
