// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  font-family: "Roboto Slab", serif;
}

.promotions-panel {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2.5rem;
  margin: 12rem 4rem;
  width: 44rem;
  padding: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/login/promotions-panel/promotions-panel.component.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,oCAAoC;EACpC,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf","sourcesContent":["h2 {\n  font-family: \"Roboto Slab\", serif;\n}\n\n.promotions-panel {\n  background-color: rgb(255, 255, 255);\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  gap: 2.5rem;\n  margin: 12rem 4rem;\n  width: 44rem;\n  padding: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
