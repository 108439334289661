import { Component, OnInit } from '@angular/core';
import { SiteService } from '../../service/site.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-customer-communication',
  templateUrl: './customer-communication.component.html',
  styleUrls: ['./customer-communication.component.css']
})
export class CustomerCommunicationComponent implements OnInit {
  ccmData;
  ccmForm: UntypedFormGroup;
  siteId;
  disabled;
  userInfo=JSON.parse(localStorage.getItem("userInfo"));
  constructor(
    private formBuilder: UntypedFormBuilder,
    private siteService: SiteService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.siteId) {
        this.siteId=params.siteId;
        this.getCCM(this.siteId);
      }
    });
    //this.disabled = this.userInfo.roleId<11  ? 'disabled' : '';
   }

  ngOnInit() {

  }

  getCCM(siteId){
    this.siteService.getCCM(siteId).subscribe(res=>{
      if(res){
        this.ccmData=res;
        this.initializeForm();
      }
    },
    err=>{
      this.toastrService.error(err);
    })
  }
  initializeForm() {
    this.ccmForm = this.formBuilder.group({
      siteId: new UntypedFormControl(this.siteId),
      newsletterDate: new UntypedFormControl(this.ccmData && this.ccmData.newsletterDate !=null ? new Date(this.ccmData.newsletterDate) :null),
      isSocialMediaFollows: new UntypedFormControl(this.ccmData && this.ccmData.isSocialMediaFollows !=null ? this.ccmData.isSocialMediaFollows :null),
      cmsEmailDate: new UntypedFormControl(this.ccmData && this.ccmData.cmsEmailDate !=null ? new Date(this.ccmData.cmsEmailDate) :null),
      cmsDemoDate: new UntypedFormControl(this.ccmData && this.ccmData.cmsDemoDate !=null ? new Date(this.ccmData.cmsDemoDate) :null),
      cmsThanksDate: new UntypedFormControl(this.ccmData && this.ccmData.cmsThanksDate !=null ? new Date(this.ccmData.cmsThanksDate) :null),
      followupDate: new UntypedFormControl(this.ccmData && this.ccmData.followupDate !=null ? new Date(this.ccmData.followupDate) :null),
      followupResponse: new UntypedFormControl(this.ccmData && this.ccmData.followupResponse !=null ? this.ccmData.followupResponse :null),
      reviewRequestDate: new UntypedFormControl(this.ccmData && this.ccmData.reviewRequestDate !=null ? new Date(this.ccmData.reviewRequestDate) :null),
      requestSeoReportDate: new UntypedFormControl(this.ccmData && this.ccmData.requestSeoReportDate !=null ? new Date(this.ccmData.requestSeoReportDate) :null),
      sentSeoReportDate: new UntypedFormControl(this.ccmData && this.ccmData.sentSeoReportDate !=null ? new Date(this.ccmData.sentSeoReportDate) :null),
      surveyRequestDate: new UntypedFormControl(this.ccmData && this.ccmData.surveyRequestDate !=null ? new Date(this.ccmData.surveyRequestDate) :null),
      surveyResponse: new UntypedFormControl(this.ccmData && this.ccmData.surveyResponse !=null ? this.ccmData.surveyResponse :null),
      handWrittenSentDate: new UntypedFormControl(this.ccmData && this.ccmData.handWrittenSentDate !=null ? new Date(this.ccmData.handWrittenSentDate) :null),
      giftLinkSentDate: new UntypedFormControl(this.ccmData && this.ccmData.giftLinkSentDate !=null ? new Date(this.ccmData.giftLinkSentDate) :null),
      annualFollowupDate: new UntypedFormControl(this.ccmData && this.ccmData.annualFollowupDate !=null ? new Date(this.ccmData.annualFollowupDate) :null),
      annualFollowupResponse: new UntypedFormControl(this.ccmData && this.ccmData.annualFollowupResponse !=null ? this.ccmData.annualFollowupResponse :null),
    });
  }
  saveCCM(form){
    this.siteService.saveCCM(form.value).subscribe(res=>{
      if(res){
        this.toastrService.success('success',"CCM Data Save");
        this.getCCM(this.siteId);
      }
    },
    err=>{
      this.toastrService.error(err);
    })
  }
  
}
