import { Component, OnInit } from '@angular/core';
import {SiteService} from '../../../service/site.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../../../../shared/data-service.service';
@Component({
  selector: 'app-add-blue-print-setup',
  templateUrl: './add-blue-print-setup.component.html',
  styleUrls: ['./add-blue-print-setup.component.css']
})
export class AddBluePrintSetupComponent implements OnInit {

  siteId
  bluePrintSetupData;
  bluePrintForm: UntypedFormGroup;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  msg;
    constructor(private siteService:SiteService,
                private toastrService:ToastrService,
                private formBuilder: UntypedFormBuilder,
                public activeModal: NgbActiveModal,
                private dataService:DataService
                ) { 
      }
  
    ngOnInit() {
      this.initializeForm();
    }
  
    saveBluePrintSetup(form){
      this.siteService.saveBluePrintSetup(form.value).subscribe(res=>{
        if(res){
          this.activeModal.close();
          this.toastrService.success(this.msg,'success');
        }
      },err=>{
        this.toastrService.error(err.error);
      })
    }
    initializeForm(){
      this.bluePrintForm=this.formBuilder.group({
        id:[this.bluePrintSetupData?this.bluePrintSetupData.id:0],
        siteId:[this.bluePrintSetupData?this.bluePrintSetupData.siteId:this.siteId,[Validators.required]],
        clinicId:[this.bluePrintSetupData?this.bluePrintSetupData.clinicId:'',[Validators.required]],
        clinicName:[this.bluePrintSetupData?this.bluePrintSetupData.clinicName:'',[Validators.required]],
        locationId:[this.bluePrintSetupData?this.bluePrintSetupData.locationId:''],
      });
    }
    dismissModal() {
      this.activeModal.dismiss();
      return false;
    }
  }
  