import { Component, OnInit } from '@angular/core';
import { SiteService } from '../../service/site.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@shared/confirm-modal/confirm-modal.component';
import { AddSocialMediaMarketingComponent } from './add-social-media-marketing/add-social-media-marketing.component';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { hasPermission } from '@shared/policies/permissions';

@Component({
  selector: 'app-social-media-marketing',
  templateUrl: './social-media-marketing.component.html',
  styleUrls: ['./social-media-marketing.component.css'],
})
export class SocialMediaMarketingComponent implements OnInit, PageHasTable {
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  hasPermission = hasPermission;
  socialMedia;
  isListing = true;
  siteId;
  familyId;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));

  constructor(
    private siteService: SiteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    public modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.siteId = params.siteId;
      this.familyId = params.familyId;
      if (params.siteId) {
        this.getSocialMediaMarketing(params.siteId);
      }
    });

    this.getTableData();
  }

  getSocialMediaMarketing(siteId){
    this.siteService.getSocialMediaMarketing(siteId).subscribe(res=>{
      if(res){
        this.socialMedia=res;
        this.isListing=this.socialMedia.length>0?false:true;
      }
    },
    err=>{
      this.toastrService.error(err.error);
    })
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnHeaders = [
      { field: 'accountId', header: 'Account ID' },
      { field: 'accountName', header: 'Account Name' },
    ];
  }
  openAddPopup() {
    var model = this.modalService.open(AddSocialMediaMarketingComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.siteId = this.siteId;
    model.result.then((result) => {
        this.getSocialMediaMarketing(this.siteId);
      console.log(`add success: ${result}`);
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });

  }
  goToEdit(media) {
    var model = this.modalService.open(AddSocialMediaMarketingComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.siteId = this.siteId;
    model.componentInstance.existingData = media;
    model.result.then((result) => {
      setTimeout(() => {
        this.getSocialMediaMarketing(this.siteId);
      }, 50);
      console.log(`Update success: ${result}`);
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });

  }

  delete(media){
    var model = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.message = 'Are you sure you want to delete?';
    model.result.then((result) => {
      if(result){
      this.siteService.deleteSmm(media.id).subscribe(res=>{
        if(res){
          this.getSocialMediaMarketing(this.siteId);
          this.toastrService.success('Delete successfully', 'success');
        }
      },err=>{
        this.toastrService.error(err, 'Error');
      })
    }
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }
}
