import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ReportingService } from '../services/reporting.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import * as html2pdf from 'html2pdf.js';
import { ConvertToHoursPipe } from '@shared/pipes/secondsToHours.pipe';
import * as _ from 'lodash';
import { HttpService } from '@services/http.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import {
  GAReport,
  GSCGoogleSearchDetail,
  GSCLocalTraffic,
  GSCReport,
  GSCTopLandingPage,
} from '@shared/interfaces/reports';

@Component({
  selector: 'app-ga-report',
  templateUrl: './ga-report.component.html',
  styleUrls: ['./ga-report.component.css'],
  providers: [DatePipe, ConvertToHoursPipe],
})
export class GaReportComponent implements OnChanges, OnInit, PageHasTable {
  @Input() siteId;

  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader<GSCGoogleSearchDetail>[];
  columnHeadersTopLandingPages: ColumnHeader<GSCTopLandingPage>[];
  columnHeadersLocalTraffic: ColumnHeader<GSCLocalTraffic>[];
  errorcount = 0;
  reportParams = {
    quarter: moment().subtract(1, 'quarter').startOf('quarter').format('MM/DD/YY'),
  };
  quarterData;
  gscReport: Partial<GSCReport>;
  gaReport: Partial<GAReport>;
  submenu = [
    {id: 1, name: 'Leads'},
    {id: 2, name: 'Analytics'},
    {id: 3, name: 'Insights'},
    {id: 4, name: 'Content'},
    {id: 5, name: 'Organic Search Performance'},
    {id: 6, name: 'Keyword Ranking'},
  ];
  selectedMenu;
  currentYear = moment().year();

  constructor(
    private convertToHoursPipe: ConvertToHoursPipe,
    private reportingService: ReportingService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private datePipe: DatePipe

  ) { }

  ngOnChanges(){
    this.getReport();
  }

  ngOnInit() {
    this.getTableData();
  }

  getReport(){
  this.quarterData = this.reportingService.getQuarters();
  if (moment(this.reportParams['quarter'], "MM/DD/YY", true).isValid()) {
    this.reportParams['formattedStartDate'] = moment(this.reportParams['quarter']).startOf('quarter').format('MM/DD/YYYY');
    this.reportParams['formattedEndDate'] = moment(this.reportParams['quarter']).endOf('quarter').format('MM/DD/YYYY');
  }
  this.errorcount=0;
  this.getGaReport(this.siteId, this.reportParams);
  this.getGscReport(this.siteId, this.reportParams);
}

  /**
   * Retrives and set the Google Analytics Report Data
   * @param {String} siteId The site's ID in Google Analytics
   * @param {Object} params The configuration parameters for the request
   */
  getGaReport(siteId, params): void {
    this.reportingService.getGaReport(siteId, params).subscribe(
      (res: any) => {
        if (res) {
          this.gaReport = res;

          for (let i = 0; i < this.gaReport.lstGALocalTraffic.length; i++) {
            const {
              newUsers,
              newUsersPercent,
              engagedSessions,
              engagedSessionsPercent,
              activeUsers,
              usersPercent
            } = this.gaReport.lstGALocalTraffic[i];

            this.gaReport.lstGALocalTraffic[i]['averageEngagementTime'] = this.secondsToHms(this.gaReport.lstGALocalTraffic[i]['averageEngagementTime']);
            this.gaReport.lstGALocalTraffic[i]['engagementRate'] = +(this.gaReport.lstGALocalTraffic[i]['engagementRate'] * 100).toFixed(2);
            this.gaReport.lstGALocalTraffic[i]['usersWithPercent'] = `${activeUsers} (${usersPercent}%)`;
            this.gaReport.lstGALocalTraffic[i]['newUsersWithPercent'] = `${newUsers} ` +
              `(${newUsersPercent}%)`;
            this.gaReport.lstGALocalTraffic[i]['engagedSessionsWithPercent'] = `${engagedSessions} ` +
              `(${engagedSessionsPercent}%)`;
          }
        }
      },
      (err) => {
        this.errorcount++;

        if (this.errorcount>1) {
          this.httpService.openErrorPopup(err.error.warning);

          this.gaReport = {};
          this.gscReport = {};
        }
      }
    );
  }

  getGscReport(siteId, params) {
    this.reportingService.getGscReport(siteId, params).subscribe((res: any) => {
      if (res) {
        this.gscReport = res;
      }
    },
      err => {
        this.errorcount++;
        if(this.errorcount>1) {
          this.httpService.openErrorPopup(err.error.warning);
          this.gaReport={};
          this.gscReport={};
        }
      });
  }

  downloadReport(report?: string) {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = 'none';
    //var element = document.getElementById('org-report');
    var element = document.getElementById('org-report').innerHTML;
    var gsc=this.gscReport? `<div class="listing"><div class="listing-heading dark-shade">
    <h3 style="padding:20px;">GSC: Search Performance (Data by Category)</h3></div></div>

            <div class="text-danger">
              This is a sample of search query data that lead to clicks and impressions for your website in Google
              search. Google does not share all data with us, and it is not possible to request or expand this list.
              Please note that you are ranking for more search terms than are found below.
            </div>
        <div style="padding-bottom: 20px;"></div>
    `:'';
    element='<div style="padding: 20px;">'+ element+gsc +'</div>';
    var collapsedElements = document.getElementsByClassName('collapse');
    var showLess = document.querySelectorAll('.show-less, .titleWithLink');
    var titleWithoutLink = document.querySelectorAll('.titleWithoutLink');
    _.forEach(collapsedElements, (element) => {
      element.className += ' show';
    })
    _.forEach(showLess, (element) => {
      element.style.display = 'none';
    })
    _.forEach(titleWithoutLink, (element) => {
      element.style.display = 'block';
    })
    var pagebreak ={ mode: '', before: '.before', after: '.after', avoid: '.avoid' };
    var opt = {
      margin: 0,
      filename: 'organic-search-performance.pdf',
      jsPDF: { unit: 'in', format: 'b3', orientation: 'portrait' },
      pagebreak: pagebreak,
    };
    let values = [];
    let keys = ['query', 'clicks', 'impressions', 'ctr', 'position'];
    let gscTableData =this.gscReport?this.gscReport.googleSearchDetail:null;
    _.forEach(gscTableData, (data) => {
      let value = _.at(data, ['query', 'clicks', 'impressions', 'ctr', 'position']);
      values.push(value);
    })

    var output = 'save';
    if (report == 'print') {
      output = 'dataurlnewwindow';
    }

    var worker = html2pdf().from(element).set(opt);
    worker.toPdf().get('pdf').then((pdf) => {
      if(this.gscReport){
        pdf.autoTable({
          headStyles: { fillColor: '#E7E9EF', textColor: '#4D4F5C' },
          head: [keys],
          body:
            values,
          columnStyles: { 1: { minCellWidth: .75 }, 2: { minCellWidth: 1 }, 3: { minCellWidth: .75 }, 4: { minCellWidth: .75 } },
          startY: 5.5,
          margin: 0.2,
          startX: 0

        });
      }
      document.getElementById("downloadButton").style.display = 'block';
      _.forEach(showLess, (element) => {
        element.style.display = 'block';
      })
      _.forEach(titleWithoutLink, (element) => {
        element.style.display = 'none';
      })
    }).output(output, 'organic-search-performance.pdf');

  }

  secondsToHms(d) {
    var sec_num = parseInt(d, 10); 
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hDisplay = hours > 0 ? (hours < 10 ? "0" : "") + hours: "00";
    var mDisplay = minutes > 0 ? (minutes < 10 ? "0" : "") + minutes: "00";
    var sDisplay = seconds > 0 ? (seconds < 10 ? "0" : "") + seconds: "00";

    return hDisplay+':'+mDisplay+':'+sDisplay; 
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      avgSessionDuration: 'convertToHours',
      engagementRate: 'percentage',
      city: 'index',
      clicks: 'number',
      ctr: 'percentage',
      impressions: 'number',
      sessions: 'number',
      sessionsPercent: 'percentage',
      position: 'number',
    };

    this.columnHeaders = [
      {
        field: 'query',
        header: 'Queries',
        tooltip: 'Search term or phrase used by a user that resulted in an impression of your' +
          ' site and/or click to your site from search results.',
      },
      {
        field: 'clicks',
        header: 'Clicks',
        tooltip: 'Total number of clicks to your site from search in relation to the search query.',
        width: 12,
      },
      {
        field: 'impressions',
        header: 'Impressions',
        tooltip: 'Total number of times a user saw a link to your site from search results in' +
          ' relation to the search query.',
        width: 18,
      },
      {
        field: 'ctr',
        header: 'CTR',
        tooltip: 'Percentage of impressions in search results that resulted in a click for the ' +
          'relative search query.',
        width: 12,
      },
      {
        field: 'position',
        header: 'Position',
        tooltip: 'Ranking of your site in search results in relation to the search query.',
        width: 15,
      },
    ];

    this.columnHeadersLocalTraffic = [
      { field: 'city', header: 'City' },
      { field: 'usersWithPercent', header: 'Users' },
      { field: 'newUsersWithPercent', header: 'New Users' },
      { field: 'engagedSessionsWithPercent', header: 'Engaged Sessions' },
      { field: 'engagementRate', header: 'Engagement Rate' },
      { field: 'eventsPerSession', header: 'Events/Session' },
      { field: 'averageEngagementTime', header: 'Avg. Engagement Time' },
      { field: 'eventCount', header: 'Event Count' },
    ];

    this.columnHeadersTopLandingPages = [
      { field: 'pageName', header: 'Page' },
      { field: 'sessions', header: 'Sessions', width: 25 },
      { field: 'sessionsPercent', header: '% Sessions', width: 25 },
    ];
  }
}
