import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview-frame',
  templateUrl: './overview-frame.component.html',
  styleUrls: ['./overview-frame.component.css']
})
export class OverviewFrameComponent implements OnInit {
  @Input() title = 'Overview';

  constructor() { }

  ngOnInit(): void {
  }

}
