import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-glyph-generic',
  templateUrl: './glyph-generic.component.html',
  styleUrls: ['./glyph-generic.component.css'],
})
export class GlyphGenericComponent implements OnInit {
  @Input() enabled = true;
  @Input() glyphName: string;
  @Input() routerLink: Array<string>;
  @Input() tooltip: string;

  @Output() clickAction = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {}

  get anchorLink(): string|null {
    if (this.enabled) {
      return '#';
    }

    return null;
  }

  /**
   * Activates a function that is passed to the component
   *
   * @param {Event} event The click event that triggers the function
   */
  clickFunction(event): void {
    if (this.enabled) {
      this.clickAction.emit(event);
    }
  }
}
