import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevDashboardComponent } from './dev-dashboard/dev-dashboard.component';
import { TicketModule } from '../ticket/ticket.module';
import { AudChartsModule } from '@shared/charts/charts.module';
import { CustomerModule } from "../customer/customer.module";
import { routing } from '../pages.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    routing,
    CommonModule,
    TicketModule,
    NgbModule,
    CustomerModule,
    AudChartsModule
  ],
  declarations: [DevDashboardComponent]
})
export class DeveloperModule { }
