import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Pipe({
  name: 'formControl',
})
export class FormControlPipe implements PipeTransform {
  /**
   * Converts AbstractControl items to FormControl items in templates. Used to fixed common typing
   * errors
   * @param {AbstractControl} control The form control to change from AbstractControl to FormControl
   * @return {FormControl} The original control with a new type, FormControl
   * @see https://stackoverflow.com/a/72517767
   * @todo Convert FormControl to be a Generic when available
   */
  transform(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl;
  }
}
