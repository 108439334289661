import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ReportingService } from '../../../../reporting/services/reporting.service';
import { ReviewGenProfile } from '@shared/interfaces/reports';
import { InputGeneric } from '@shared/interfaces/forms';
import { NotificationsService } from '@shared/services/notifications/notifications.service';
import { ModalFrameComponent } from '@shared/components/frames/modal-frame/modal-frame.component';
import { CounselEarService } from '@shared/services/practice-management-software/counsel-ear.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../admin/users/services/user-service.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-counsel-ear-add',
  templateUrl: './counsel-ear-add.component.html',
  styleUrls: ['./counsel-ear-add.component.css'],
})
export class CounselEarAddComponent implements OnInit {
  counselEarForm = new UntypedFormGroup({
    locationName: new UntypedFormControl(null, Validators.required),
    locationId: new UntypedFormControl(null, [
      Validators.min(0),
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    reviewGenProfile: new UntypedFormControl(null, Validators.required),
  });
  reviewGenProfiles: InputGeneric[] = [];
  siteId: number;
  userInfo;

  @Output('onLocationAdd') onLocationAdd = new EventEmitter<any>();

  @ViewChild('modal') modal: ModalFrameComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private counselEarService: CounselEarService,
    private notificationsService: NotificationsService,
    private reportingService: ReportingService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    var siteString = localStorage.getItem('selectedSite');
    // Parse the JSON string back into an object
    var selectedSite = JSON.parse(siteString);
    this.siteId = selectedSite.siteId;

    this.userInfo = localStorage.getItem('userInfo') != null ?
      JSON.parse(localStorage.getItem('userInfo')) :
      { 'token': '', 'userId': null, 'roleId': null };

    this.getReviewGenProfiles();
  }

  /**
   *
   * @param {FormGroup} form
   */
  addLocation(form: UntypedFormGroup): void {
    const counselEarSetupData = {
      counselear_location_id: form.value.locationId,
      location_name: form.value.locationName,
      modified_by: this.userInfo.userId,
      reviewgen_id: form.value.reviewGenProfile,
      site_id: this.siteId,
    };

    this.counselEarService.saveSetup(counselEarSetupData).subscribe(
      (res) => {
        if (res) {
          this.notificationsService.showToastSuccess(`Location ${form.value.locationName} Added`);
        }
      },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.notificationsService.showToastError(error.message);
      },
      () => {
        this.onLocationAdd.emit();
        this.modal.closeModal();
      }
    );
  }

  /**
   * Returns an error message based the control
   *
   * @param {String} control The name of the form control
   *
   * @return {String} The error message
   */
  getErrorMessages(control: string) {
    if (this.counselEarForm?.controls.hasOwnProperty(control)) {
      const formControl = this.counselEarForm.controls[control];

      if (formControl?.errors?.pattern) {
        return 'Numbers only';
      }
    }
  }

  /**
   * @return {Array<any>}
   */
  getReviewGenProfiles(): Array<any> {
    this.reportingService.getProfileData(this.siteId).subscribe(
      (res: ReviewGenProfile[]) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            this.reviewGenProfiles.push({
              text: res[i].profileName,
              value: res[i].profileId,
            });
          }
        }
      },
      (error) => console.error(error),
    );

    return [];
  }
}
