// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.has-danger{
    border: 1px solid red !important;
}
.staff-bios .upload-img-thumbnail{max-width:80px;}
.staff-bios h6, .staff-bios h6 a{font-weight:600; color:#212529; font-size:14px;}
.staff-bios h6 a:hover{text-decoration:none;}
.text-area{resize:vertical !important;}

`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/basic/content/staff-bios/staff-bios.component.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;AACA,kCAAkC,cAAc,CAAC;AACjD,iCAAiC,eAAe,EAAE,aAAa,EAAE,cAAc,CAAC;AAChF,uBAAuB,oBAAoB,CAAC;AAC5C,WAAW,0BAA0B,CAAC","sourcesContent":["input.has-danger{\n    border: 1px solid red !important;\n}\n.staff-bios .upload-img-thumbnail{max-width:80px;}\n.staff-bios h6, .staff-bios h6 a{font-weight:600; color:#212529; font-size:14px;}\n.staff-bios h6 a:hover{text-decoration:none;}\n.text-area{resize:vertical !important;}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
