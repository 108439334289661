import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    SharedModule,
  ],
  exports: [
    PageHeaderComponent,
    PageFooterComponent,
  ],
  declarations: [
    PageHeaderComponent,
    PageFooterComponent,
  ],
})
export class CoreModule { }
