import { Component, OnInit, ViewChild } from '@angular/core';
import { IntakesService } from '../services/intakes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InitiatePopupComponent } from '../initiate-popup/initiate-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from '../../../admin/masters/services/master.service';
import { CorporatePartnerService } from '../../../../components/admin/manage-corporate-partner/services/corporate-partner.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import moment from 'moment';
import { HttpService } from '../../../../services/http.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {ExcelService} from '../../../reporting/services/excel.service';
import { ConfirmModalComponent } from '../../../../shared/confirm-modal/confirm-modal.component';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-intakes',
  templateUrl: './intakes.component.html',
  styleUrls: ['./intakes.component.css']
})
export class IntakesComponent implements OnInit, PageHasTable {
  @ViewChild('dt') dt: TableGenericComponent;

  allIntakes;
  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  intakesList = [];
  filterBy = { domainUrl: null, siteName: null };
  page = 1;
  startDate = '';
  endDate = '';
  userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
  projectType;
  statusData;
  _startDate;
  _endDate;
  paymentMode;
  corporatePartner;
  paymentStatus;
  siteLists;
  currentRole

  constructor(
    private intakesService: IntakesService,
    private router: Router,
    public modalService: NgbModal,
    private httpService: HttpService,
    private excelService:ExcelService,
    private corporatePartnerService: CorporatePartnerService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private masterService: MasterService,
  ) {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
      console.log(this.currentRole,'==role')
    })
  }

  ngOnInit() {
    if(this.activatedRoute.snapshot.queryParamMap.get('status') && this.currentRole=='client-communication-manager'){
      this.filterBy['isNew']  = "false";
    }
    this.filterBy['status'] = this.activatedRoute.snapshot.queryParamMap.get('status');
    const startDate = this.activatedRoute.snapshot.queryParamMap.get('startDate');
    const endDate = this.activatedRoute.snapshot.queryParamMap.get('endDate');
    const dt = this.activatedRoute.snapshot.queryParamMap.get('dt');
    if (startDate)
      this.startDate = startDate;
    if (endDate)
      this.endDate = endDate;
    if (dt) {
      this.filterBy['dt'] = dt;
    }
    this.getProductType(4);
    this.getProductType(3);
    this.getPaymentMode();
    this.getPaymentStatus();
    this.getCorporatePartner();
    this.getSites();
    this.getIntakes(this.userInfo.userId, this.startDate, this.endDate, this.filterBy);

    this.getTableData();
  }

  deleteIntake(intakeId) {

    var model = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.message = 'Are you sure you want to delete this intake?';
    model.result.then((result) => {
      if (result) {
        this.intakesService.deleteIntake(intakeId).subscribe((res) => {
          if (res) {
            this.toastrService.success('Deleted Successfully', 'Success');
            this.getIntakes(this.userInfo.userId, this.startDate, this.endDate, this.filterBy);
          }
        }, err => {
          this.httpService.openErrorPopup(err.error.message);
        })
      }
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });


  }

  getIntakes(userId, startDate, endDate, filterBy) {
    this.intakesService.getIntakes(userId, startDate, endDate, filterBy).subscribe((res) => {
      if (res) {
        if (Object.values(filterBy).every(x => (x === null || x === ''))) {
          this.allIntakes = Object.assign([], res);
        }
        this.intakesList = Object.assign([], res);
        console.log(this.intakesList, '==this.intakesList');
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getProductType(type) {
    this.masterService.getGeneralMasterByType(type).subscribe((res) => {
      if (res) {
        if (type == 3) {
          this.statusData = _.filter(res, (item) => {
            return item.name != null;
          });
        } else if (type == 4) {
          this.projectType = res;
        }
      }
    },
      err => {
        throw err;
      })
  }
  getPaymentMode() {
    this.intakesService.getPaymentMode().subscribe(res => {
      if (res) {
        this.paymentMode = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getPaymentStatus() {
    this.intakesService.getPaymentStatus().subscribe(res => {
      if (res) {
        this.paymentStatus = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  /**
   * Gets data for the tables and sets the column headers
   *
   * @TODO Move API call to this method
   */
getTableData(): void {
    this.columnHeaders = [
      { field: 'domainUrl', header: 'Domain URL', filter: 'text' },
      { field: 'siteName', header: 'Business Name', filter: 'text' },
      { field: 'products', header: 'Products', filter: 'text' },
      { field: 'initiateDate', header: 'Start Date', filter: 'none' },
      { field: 'paymentStatusName', header: 'Payment Status', filter: 'select' },
      { field: 'paymentModeName', header: 'Payment Mode', filter: 'select' },
      { field: 'cpName', header: 'Program', filter: 'select' },
      { field: 'clientType', header: 'Client Type', filter: 'select' },
      { field: 'statusName', header: 'Intake Status', filter: 'select' },
    ];


    this.columnDataTypes = {
      initiateDate: 'date',
    };
  }

  goToIntakeProduct(intakeId) {
    // this.userService.existingUser = user;
    // this.router.navigate(['intake-product-list/'+intakeId]);
  }
  openInitiatePopup(intake) {
    var model = this.modalService.open(InitiatePopupComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.intake = intake;
    model.result.then((result) => {
      this.getIntakes(this.userInfo.userId, this.startDate, this.endDate, this.filterBy);
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });

  }
  review(siteData) {
    this.router.navigate([`/review/${siteData.intakeId}?flag=true`]);
    console.log(siteData, 'sitedata');
  }
  selectedItem(obj?){
    this.filterByColumn();
  }

  filterByColumn(inputKey?, filterKey?, key?) {
    this.filterBy[filterKey] = ((typeof this.filterBy[inputKey]) == 'object') ? this.filterBy[inputKey][key] : this.filterBy[inputKey];
    this.getIntakes(this.userInfo.userId, this.startDate, this.endDate, this.filterBy);
  }
  clearFilter() {
    this.startDate = null;
    this.endDate = null;
    this._startDate = null;
    this._endDate = null;
    this.filterBy = { domainUrl: null, siteName: null };


    this.dt.clearFilters();
    this.getIntakes(this.userInfo.userId, this.startDate, this.endDate, this.filterBy);
  }

  searchDomain = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.siteLists.filter(v => v['domainUrl'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  domainFormatter = (result: string) => `${result['domainUrl']}`;
  domainInputFormatter = (result: string) => `${result['domainUrl']}`;

  siteName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.siteLists.filter(v => v['businessName'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  siteNameFormatter = (result: string) => `${result['businessName']}`;
  siteInputFormatter = (result: string) => `${result['businessName']}`;

  getCorporatePartner() {
    this.corporatePartnerService.getCorporatePartners().subscribe(res => {
      if (res) {
        this.corporatePartner = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getSites(){
    this.intakesService.getSites().subscribe((res) => {
      if (res) {
        this.siteLists = Object.assign([], res);
      }
    },
      err => {
        throw err;
      })
  };

  onValueChange(event) {
    this.startDate = moment(event[0]).format('MM/DD/YYYY');
    this.endDate = moment(event[1]).format('MM/DD/YYYY');
    this._startDate = moment(event[0]).format('MM/DD/YY');
    this._endDate = moment(event[1]).format('MM/DD/YY');
    this.getIntakes(this.userInfo.userId, this.startDate, this.endDate, this.filterBy);
  }
}
