import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Router, ActivatedRoute, RouterModule} from '@angular/router';
import {NotificationService} from './service/notification.service';
import * as _ from 'lodash';
import { roles } from '@shared/master-enum.enum';
import { HttpService } from '../../../services/http.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit, PageHasTable {
  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  currentRole;
  generalNotifications=[];
  loading = false;
  siteId: any = null;
  systemNotifications = [];
  notificationForName = [];
  refName = [];
  typeName = [];
  userInfo;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private notificationService:NotificationService
  ) { }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

    this.getCurrentRole();
    this.getTableData();
  }

  getCurrentRole(){
    if(this.userInfo){
      this.currentRole = _.find(roles, (role) => {
        return role.roleId == this.userInfo.roleId;
      })
    }
  }

  getSavedNotifications() {

    this.notificationService.getNotificationLists().subscribe((res: any) => {
      if (res) {
        this.generalNotifications=res;
        this.notificationForName = Array.from(new Set(res.map(item => item.notificationForName)));
        this.refName = Array.from(new Set(res.map(item => item.refName)));
        this.typeName = Array.from(new Set(res.map(item => item.typeName)));
      }
    },
      err => {

        this.httpService.openErrorPopup(err.error.message);
      });
  }

  /**
   * Sets the configuration and data for the table
   */
  getTableData(): void {
    this.columnHeaders = [
      {header: 'Name', field: 'name'},
      {header: 'Start Date', field: 'startDate', filter:'date'},
      {header: 'End Date', field: 'endDate', filter:'date'},
      {header: 'Notification For', field: 'notificationForName', filter: 'select'},
      {header: 'Program', field: 'refName', filter: 'select'},
      {header: 'Notification Type', field: 'typeName', filter: 'select'},
    ];

    this.columnDataTypes = {
      name: 'html',
      startDate: 'date',
      endDate: 'date',
    };

    this.getSavedNotifications();
  }

  addNewNotification(text){
    this.notificationService.notify.type=1;
    this.notificationService.notify.notificationTypeName='General Notification';
  }

  deleteNotification(notification){
     this.notificationService.deleteNotification(notification.id).subscribe((res)=>{
      if(res){
        this.toastrService.success('Deleted Successfully', 'Success');
        this.getSavedNotifications();
      }
    },err=>{

       this.httpService.openErrorPopup(err.error.message);
    })

  }
}
