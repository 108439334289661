// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  background-color: rgb(53, 151, 236);
  border-radius: 2px;
  color: rgb(255, 255, 255);
}

a:hover {
  color: inherit;
}

a > span {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1rem;
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/buttons/export-to-csv/export-to-csv.component.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":["a {\n  background-color: rgb(53, 151, 236);\n  border-radius: 2px;\n  color: rgb(255, 255, 255);\n}\n\na:hover {\n  color: inherit;\n}\n\na > span {\n  font-size: 1.5rem;\n  font-weight: bold;\n  line-height: 1rem;\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
