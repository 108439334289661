// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  color: rgb(77,79, 92);
  font-weight: bold;
  font-size: 1.3rem;
}

header {
  min-height: 4rem;
  padding: 1.5rem;
}

img {
  width: 57%;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/intakes/intake-sidebar-header/intake-sidebar-header.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ","sourcesContent":["h2 {\n  color: rgb(77,79, 92);\n  font-weight: bold;\n  font-size: 1.3rem;\n}\n\nheader {\n  min-height: 4rem;\n  padding: 1.5rem;\n}\n\nimg {\n  width: 57%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
