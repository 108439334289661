// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
    cursor: pointer;
}

.wizard-content {
  max-width: unset;
}

.wp-info-disabled {
  cursor: not-allowed;
  background: url(/assets/images/icons/wordpress-disabled.svg)
}
`, "",{"version":3,"sources":["webpack://./src/app/components/site/site-list/site-list.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB;AACF","sourcesContent":["a {\n    cursor: pointer;\n}\n\n.wizard-content {\n  max-width: unset;\n}\n\n.wp-info-disabled {\n  cursor: not-allowed;\n  background: url(/assets/images/icons/wordpress-disabled.svg)\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
