import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.css'],
})
export class SecondaryButtonComponent implements OnInit {
  @Input() disabled = false;
  @Input() label: string;

  @Output() clickFunction = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  click(): void {
    this.clickFunction.emit(null);
  }
}
