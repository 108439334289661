// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export-button {
  margin-left: auto;
  margin-right: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/downloads/gmb-downloads/gmb-downloads.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".export-button {\n  margin-left: auto;\n  margin-right: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
