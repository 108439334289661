import * as _ from 'lodash';
import { IntakesService } from '../../../../services/intakes.service';

export class Homepage {
    intakeId: number;
    intakeBasicContents: Object;
    lElement: Object;
    hpElementVideo: String;
    lWebsitePromotion: Object;
    otherWebsitePromotion: String;

    constructor(info, intakeId) {
        this.intakeId = intakeId;
        this.intakeBasicContents = this.getContentData(info.intakeBasicContents);
        this.lElement = this.getElementData(info.lElement);
        this.hpElementVideo = this.getElementData(info.lElement, 'video');
        this.lWebsitePromotion = this.getSpecialServiceData(info.lwebsitePromotion);
        this.otherWebsitePromotion = this.getSpecialServiceData(info.lwebsitePromotion, 'other');
    }

    getSpecialServiceData(specialService, other?) {
        if (other) {
            let otherPromo = _.find(specialService, (service) => {
                return service.name.toLowerCase() == 'other';
            })
            return otherPromo.textbox;
        } else {
            let specialServiceData = _.map(specialService, (service) => {
                service.name = service.textbox && service.name.toLowerCase() != 'other' ? `{${service.textbox}}${service.tempName}` : service.tempName;
                let ssData = service.checked ? _.pick(service, ['id', 'name', 'masterTypeId', 'description']) : null;
                return ssData;
            });
            specialServiceData = _.filter(specialServiceData, (data) => { return data != null });
            return specialServiceData;
        }
    }

    getElementData(element, other?) {
        if (other) {
            let otherPromo = _.find(element, (service) => {
                return service.name.toLowerCase() == 'video';
            })
            return otherPromo.textbox;
        } else {
            let elementData = [];
            _.forEach(element, (data) => {
                if (data.checked) {
                    elementData.push(_.pick(data, ['id', 'name']));
                }
            });
            return elementData;
        }
    }

    getContentData(contentData) {
        let elementData = [];
        _.forEach(contentData, (data) => {
            if (data.checked) {
                elementData.push(_.pick(data, ['intakeId', 'id', 'title', 'content']));
            }
        });
        return elementData;
    }


}