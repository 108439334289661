import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { UserService } from '../services/user-service.service';
import { MasterService } from '../../masters/services/master.service';
import { Router, ActivatedRoute } from '@angular/router';
import { masterEnum } from '@shared/master-enum.enum';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '@services/http.service';
import { SiteService } from '../../../site/service/site.service';
import { Location } from '@angular/common';
import { Roles } from '@shared/enums/Roles';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
})
export class AddComponent implements OnInit {
  addUserForm: UntypedFormGroup;
  public masterTypeId;
  public roles;
  public model: any;
  public existingUser;
  public usernameExist = false;
  public isFileInvalid = false;
  isQueue = false;
  fileName;
  imgUrl;
  siteId;
  allUsers;
  selectedUserRole;
  selectedUser;
  currentRole;
  previousurl;
  cpId;
  addNew;
  baseUrl = environment.imgUrl;
  uploadData: FormData = new FormData();
  selectedSite = JSON.parse(localStorage.getItem('selectedSite'));
  userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };

  constructor(
    private _location: Location,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private masterService: MasterService,
    private router: Router,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private siteService: SiteService,
    public dataService: DataService,
    private cd: ChangeDetectorRef
  ) {
    this.siteId = this.activatedRoute.snapshot.queryParamMap.get('siteId');
    this.cpId = this.activatedRoute.snapshot.queryParamMap.get('cpId');
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.userService.getUserInfo(params.id).subscribe(res => {
          if (res) {
            this.existingUser = res;
            this.initializeForm();
            this.getAssignUser(this.existingUser);
            this.existingUser.userRoleId < 11 ? this.getRoles(true) : this.getRoles();
            this.imgUrl = this.baseUrl + this.existingUser.imageURL;
            this.fileName = this.existingUser.imageURL.substring(this.existingUser.imageURL.lastIndexOf('/') + 1);
            this.cd.detectChanges();
          }
        },
          err => {
            this.httpService.openErrorPopup(err.error.message);
          });
      } else {
        this.initializeForm();
        !this.siteId ? this.getRoles(true) : this.getRoles();
      }
    });
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
    })
  }

  ngOnInit() {
    this.previousurl = localStorage.getItem('previousUrl');
  }

  cancelEdit() {
    if (this.currentRole == 'admin') {
      this.router.navigate(['/' + this.currentRole + '/users/']);
    } else {
      this.router.navigate(['/' + this.currentRole + '/site-config/', this.selectedSite.siteId]);
    }

  }

  /**
   * Checks if the username in the form already exists
   * @param {String} value The supplied username
   * @deprecated The validation has been moved to the save method, so this may no longer be needed
   */
  checkUsername(value: string) {
    if (!_.isEmpty(value)) {
      if (this.existingUser&&value==this.existingUser.username) {
        return;
      }

      this.userService.checkUsername(value).subscribe(res => {
        if (res) {
          this.usernameExist = true;
          this.addUserForm.controls.username.setErrors({ 'invalid': true });
          this.toastrService.warning(this.dataService.userExistMsg, 'Warning');
        } else {
          this.usernameExist = false;
        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
    }
  }

  initializeForm() {
    this.addUserForm = this.formBuilder.group({
      id: [(this.existingUser ? this.existingUser.id : null)],
      username: [this.existingUser ? this.existingUser.username : '', [Validators.required, Validators.email]],
      email: [this.existingUser ? this.existingUser.email : '', [Validators.required, Validators.email]],
      firstName: [this.existingUser ? this.existingUser.firstName : '', [Validators.required]],
      middleName: [this.existingUser ? this.existingUser.middleName : ''],
      lastName: [this.existingUser ? this.existingUser.lastName : '', [Validators.required]],
      phone: [this.existingUser ? this.existingUser.phone : '', [Validators.pattern(this.dataService.phoneRegex)]],
      userRoleId: [this.existingUser ? this.existingUser.userRoleId  : (this.cpId) ? 12 :this.userInfo.roleId==11?13:'',],
      description: [this.existingUser ? this.existingUser.description : ''],
      extension: [this.existingUser ? this.existingUser.extension : '', [Validators.pattern(this.dataService.numberRegex)]],
      imageURL: [this.existingUser ? this.existingUser.imageURL : null],
      cpId: [this.cpId || 0],
      isActive: [this.existingUser ? this.existingUser.isActive : true],
      siteId:[this.siteId||0]
    });
  }

  /**
   * Determines whether or not the user is a client
   *
   * @return {boolean}
   */
  isClient(): boolean {
    if (this.existingUser) {
      return this.existingUser.userRoleName === 'Client' || this.existingUser.userRoleId === 11;
    }

    return false;
  }

  onFileChanged(event) {
    if (event) {
      let file = event.target.files[0];
      this.fileName = file.name;
      
      // Reset previous validation
      this.isFileInvalid = false;
      this.addUserForm.get('imageURL')?.setErrors(null); // Clear any previous errors
  
      var checkimg = file.name.toLowerCase();
      if (!checkimg.match(/(\.jpg|\.png|\.jpeg)$/)) {
        this.isFileInvalid = true;
        this.addUserForm.get('imageURL')?.setErrors({ invalidFileType: true }); // Set error on form control
        return; // Stop further processing
      }
  
      // If file type is valid, append to FormData and load preview
      this.uploadData.append('myFile', file, file.name);
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file); // Read file as data URL
  
        reader.onload = (e: any) => {
          this.imgUrl = e.target.result; // Called once readAsDataURL is completed
          this.isFileInvalid = false; // Clear file error
        };
      }
    }
  }  

  isFieldValid(field: string, index: number = 0) {
    return !this.addUserForm.get(field).valid && this.addUserForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray) {
        for (let i = 0; i < control.controls.length; i++) {
          this.validateAllFormFields(control.controls[i]);
        }
      }
    });
  }


  /**
   * Creates or Updates a user if the supplied information is valid
   * @param {FormGroup} form The data from the form
   */
  addUser(form: UntypedFormGroup): void {
    const username = this.addUserForm.controls.username.value;

    if (form.valid) {
      const userInfo = form.value;

      // If existing user, else add new user
      if (userInfo.id) {
        this.userService.updateUser(userInfo).subscribe(
          (res) => {
            this.uploadFile(res);

            this.toastrService.success(this.dataService.updateUserMsg, 'Success');

            this.goBack();
          },
          (err) => {
            this.httpService.openErrorPopup(err.error.message);
          }
        );
      } else {
        delete userInfo['id'];
        const selectedSite = localStorage.getItem('selectedSite') ?
          JSON.parse(localStorage.getItem('selectedSite')) : null;
        const userInfoSiteId = selectedSite && selectedSite.siteId ?
          selectedSite.siteId : selectedSite && selectedSite.id ?
          selectedSite.id : this.siteId ?? 0;

        userInfo['siteId'] = userInfoSiteId;

        this.userService.checkUsername(username).subscribe(
          (res) => {
            if (res) {
              this.usernameExist = true;

              this.addUserForm.controls.username.setErrors({ 'invalid': true });

              this.toastrService.warning(this.dataService.userExistMsg, 'Warning');
            } else {
              this.usernameExist = false;
            }
          },
          (err) => {
            this.httpService.openErrorPopup(err.error.message);
          },
          () => {
            this.userService.addNewUser(userInfo).subscribe(
              (res) => {
                userInfo.id = res.UserId;

                this.uploadFile(res);

                this.toastrService.success(this.dataService.addUserMsg, 'Success');

                this.goBack();
              },
              (err) => {
                if (err.error.message !== 'Already exists') {
                  this.httpService.openErrorPopup(err.error.message);
                }
              });
          }
        );
      }
    } else {
      this.validateAllFormFields(this.addUserForm);

      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
  }

  uploadFile(data) {
    if (this.uploadData.has("myFile")) {
      this.uploadData.append("userId", data.UserId)
      this.userService.uploadProfileImg(this.uploadData).subscribe(ures => {
        console.log("Profile image successfully added");
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
    }
  }

  /**
   * Depending on the current user's role, this retrieves or generates a list of possible roles
   * @param {Object} filter
   */
  getRoles(filter?): void {
    if (this.userInfo.roleId == Roles.CLIENT) {
      this.roles = [
        { name: 'Client', id: '11' },
        { name: 'Site User', id: '13' },
      ];
    } else {
      this.masterService.getMastersListById(masterEnum['role']).subscribe(
        (res) => {
          if (res) {
            this.roles = res.masterList;

            if (filter && !this.cpId) {
              this.roles = _.filter(this.roles, (role) => {
                return role.id !== 11 && role.id !== 12 && role.id !== 13 &&
                  role.id !== 14 && role.id !== 15;
              });
            }
          }
        },
        (err) => {
          this.httpService.openErrorPopup(err.error.message);
        },
      );
    }
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.allUsers.filter(v => v.fullName.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.email.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  formatter = (result: string) => `${result['fullName']}<${result['email']}>`;

  selectUser(event) {
    if (event.item) {
      this.selectedUser = event.item;
      this.selectedUserRole = event.item.userRoleName;
    }
  }

  updateSiteUser(userId) {
    let params = {
      id: JSON.parse(localStorage.getItem('selectedSite')).siteId,
      userId: userId
    }
    this.userService.updateSiteUser(params).subscribe(res => {
      if (res) {
        this.toastrService.success("User Assigned Successfully", 'Success');
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getAssignUser(user){
    this.siteService.getAssignUser(user.id).subscribe(res => {
      if (res) {
        this.existingUser['sites'] = _.filter(res, (site) => {
          return site.isAssign == 1;
        });
        this.cd.detectChanges();
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  goBack() {
    this._location.back();
  }
}
