import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarChartsComponent } from './bar-charts/bar-charts.component';
import { BarChartHorizontalComponent } from './bar-chart-horizontal/bar-chart-horizontal.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { AreaChartComponent } from './area-chart/area-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { BaseChartDirective } from 'ng2-charts';


@NgModule({
  imports: [
    CommonModule,
    BaseChartDirective
    
  ],
  exports:[
    BarChartsComponent,
    BarChartHorizontalComponent,
    DoughnutChartComponent,
    AreaChartComponent,
    PieChartComponent
  ],
  declarations: [
    BarChartsComponent,
    BarChartHorizontalComponent,
    DoughnutChartComponent,
    AreaChartComponent,
    PieChartComponent
  ]
})
export class AudChartsModule { }
