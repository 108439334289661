// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `circle-progress {
  font-family: Roboto, monospace;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/graphs/progress-circle/progress-circle.component.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC","sourcesContent":["circle-progress {\n  font-family: Roboto, monospace;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
