import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.css'],
  template: `
  
  <router-outlet></router-outlet>
  `
})
export class AppComponent {
  title = 'audiology';
  constructor( ){
    
  }

  }
