import { Component, Input, OnInit } from '@angular/core';
import { RadioInput } from '@shared/interfaces/forms';
import { AbstractControl, ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-radio-squared',
  templateUrl: './radio-squared.component.html',
  styleUrls: ['./radio-squared.component.css'],
})
export class RadioSquaredComponent implements OnInit {
  @Input() controlName: string;
  @Input() defaultValue: string;
  @Input() disabled = false;
  @Input() formGroup: AbstractControl|UntypedFormGroup;
  @Input() label: string;
  @Input() labelIsBold = true;
  @Input() name: string;
  @Input() options: RadioInput[] = [
    { text: 'Yes', value: '1' },
    { text: 'No', value: '0' },
  ];
  @Input() radioGroupName = '';
  @Input() required = false;

  constructor(
    private controlContainer: ControlContainer
  ) { }

  ngOnInit(): void {
    if (this.defaultValue !== undefined) {
      this.setDefaultValue();
    }

    if (!this.name) {
      this.name = this.label.toLowerCase()
        .replace(' ', '-')
        .replace(/[!"#$%&'()*+,./:;<=>?@[\]^_`{|}~]/g, '');
    }

    if (!this.name) {
      this.name = this.controlName;
    }

    this[this.radioGroupName] = new UntypedFormControl();

    this.formGroup = this.controlContainer.control;
  }

  /**
   * Converts a Boolean value to an HTML compatible string
   *
   * @param {Boolean|String} booleanValue
   *
   * @return {String}
   */
  convertBooleanToString(booleanValue: boolean|string): string {
    if (booleanValue) {
      return '1';
    } else {
      return '0';
    }
  }

  /**
   * Sets the default value of checkbox data by using a loose comparison
   */
  setDefaultValue(): void {
    for (const key in this.options) {
      if (this.options[key].value === this.convertBooleanToString(this.defaultValue)) {
        this.options[key].checked = true;
      }
    }
  }
}
