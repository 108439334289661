import { Pipe, PipeTransform } from '@angular/core';
import { String } from '@shared/utilities/StringUtilities';

@Pipe({
  name: 'toCssClass'
})
export class ToCssClassPipe implements PipeTransform {
  transform(value: string): unknown {
    return String.toCssClass(value);
  }
}
