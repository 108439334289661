import { Component, OnInit } from '@angular/core';
import{SiteService} from '../../service/site.service';
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-map-site-user',
  templateUrl: './map-site-user.component.html',
  styleUrls: ['./map-site-user.component.css']
})
export class MapSiteUserComponent implements OnInit {
  users;
  user;
  selectedUser;
  siteId;
  constructor(private siteService:SiteService,
              private activeModal:NgbActiveModal) { }

  ngOnInit(): void {
    this.getSiteUser();
  }
  getSiteUser(){
    this.siteService.getSiteuser().subscribe(res=>{
      if(res){
        this.users=res;
      }
    },err=>{
      throw err;
    })
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term.length < 3
          ? []
          : this.users
              .filter(
                v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      )
    );
  formatter = (result: string) => `${result["name"]}`;
  inputFormatter = (result: string) => `${result["name"]}`;
  getUser(id){
    this.siteService.getUserInfo(id).subscribe(res=>{
      if(res){
        this.selectedUser=res;
        this.selectedUser.fullName = `${this.selectedUser.firstName} ${this.selectedUser.middleName} ${this.selectedUser.lastName}`;
      }
    },err=>{
      throw err;
    })
  }
  addSiteUser(){
    this.siteService.addSiteUser(this.siteId,this.selectedUser.id).subscribe(res=>{
      if(res){
        this.activeModal.close();
      }
    },err=>{
      throw err;
    })
  }
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
}
