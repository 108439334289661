import * as _ from 'lodash';
import { IntakesService } from '../services/intakes.service';

export class Ppc {
    intakeId : number;
    IntakeTypeId:number;
    date : Date;
    email : string;
    callTrackingNumber : number;
    clientLogo:string;
    productType : string;
    campaign1StartDate : string;
    campaign1EndDate : string;
    campaign1Budget : number;
    campaign2StartDate : Date;
    campaign2EndDate : Date;
    campaign2Budget : number;
    targetDemographic :  string;
    targetGeographicZone :  string;
    previousCampaignHistory : string;
    isActiveAccount : boolean;
    facebookBusinessUrl : string;
    additionalNotes : string;
    topSellingServices : string;
    promotion : string;
    focusOnService : string;
    costPerLead : string;
    leadConversionRate : string;
    averageRevenue : number;
    averageProfitMargin : number;
    competitorName1 : string;
    website1 : string;
    competitorName2 : string;
    website2 : string;
    competitorName3 : string;
    website3 : string;
    oIntakeLocation: string;
    lstPPCLocation: Object;

    constructor(info) {
        this.intakeId = info.intakeId;
        this.IntakeTypeId=info.intakeTypeId;
        this.date = info.date!=null?new Date(info.date):null;
        this.campaign1StartDate = info.campaign1StartDate;
        this.campaign1EndDate = info.campaign1EndDate ;
        this.email = info.email;
        this.callTrackingNumber = info.callTrackingNumber;
        this.clientLogo=info.clientLogo;
        this.productType = info.productType;
        this.campaign1Budget = info.campaign1Budget;
        this.campaign2StartDate = info.campaign2StartDate==null||info.campaign2StartDate==""?null:new Date(info.campaign2StartDate);
        this.campaign2EndDate = info.campaign2EndDate==null|| info.campaign2EndDate==""?null:new Date(info.campaign2EndDate);
        this.campaign2Budget = info.campaign2Budget;
        this.targetDemographic = info.targetDemographic;
        this.targetGeographicZone = info.targetGeographicZone;
        this.previousCampaignHistory = info.previousCampaignHistory;
        this.isActiveAccount=info.isActiveAccount;
        this.facebookBusinessUrl = info.facebookBusinessUrl;
        this.additionalNotes = info.additionalNotes;
        this.topSellingServices = info.topSellingServices;
        this.promotion = info.promotion;
        this.focusOnService = info.focusOnService;
        this.costPerLead=info.costPerLead;
        this.leadConversionRate = info.leadConversionRate;
        this.averageRevenue = info.averageRevenue;
        this.averageProfitMargin = info.averageProfitMargin;
        this.competitorName1 = info.competitorName1;
        this.website1 = info.website1;
        this.competitorName2 = info.competitorName2;
        this.website2 = info.website2;
        this.competitorName3 = info.competitorName3;
        this.website3 = info.website3;
        this.oIntakeLocation = info.oIntakeLocation;
        this.lstPPCLocation = info.lstPPCLocation;
    }

}
