import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.css'],
})
export class CheckboxGroupComponent implements OnInit {
  @Input() label: string;
  @Input() name: string;
  @Input() required: boolean;

  constructor() { }

  ngOnInit(): void {
  }
}
