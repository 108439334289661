import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import { TreeNodeComponent } from '@shared/tree-node/tree-node.component';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { HttpService } from '../../../../../../../services/http.service';

@Component({
  selector: 'app-practiceInof',
  templateUrl: './practiceinof.component.html',
  styleUrls: ['./practiceinof.component.css']
})
export class PracticeinofComponent implements OnInit {

  @Input() selectedItem;
  @Input() siteId;
  @Input() intakeTypeId;
  @ViewChild(TreeNodeComponent, { static: false }) TreeNodeComponent;
  @Output() continueClicked = new EventEmitter<any>();
  @Output() backClicked = new EventEmitter<any>();  
  @Output() changeStatus = new EventEmitter<any>(); 
  name: string;
  key: string = 'children';
  data;
  intakeId: number = null;
  homePageMenu;
  title: string = '';
  allPracticeInfoData;
  searchKeyword;
  seoDescription: string = '';
  filteredData = [];
  searchTerm: string = '';

  constructor(
    private intakesService: IntakesService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private toastrService: ToastrService
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
      }
    });
  }

  ngOnInit() {
    this.getPracticeInfo(this.selectedItem.name || 'Practice Information', this.intakeId);
  }

  getPracticeInfo(selectedItemName, intakeId) {
    this.intakesService.getPageList(selectedItemName, intakeId).subscribe((response) => {
        if (response) {
            console.log('API Response:', response);
            this.allPracticeInfoData = response;
            this.data = Object.assign([], this.allPracticeInfoData);
            this.title = response[0].title;
            this.seoDescription = response[0].seoDescription;
            this.initializeData(this.data); // Initialize data after assignment
        }
    }, err => {
        console.log('Error in getPracticeInfo:', err);
        this.httpService.openErrorPopup(err.error.message);
    });
}

initializeData(data) {
    data.forEach(item => {
        if (!item.hasOwnProperty('status')) {
            item.status = null; // or any default value you want to set
        }
        if (item.children && item.children.length) {
            this.initializeData(item.children);
        }
    });
}

  savePracticeInfoPages(mode?) {
    console.log('Saving Practice Info Pages. Mode:', mode);
    console.log('Selected Item:', this.selectedItem);
    console.log('Data being saved:', this.data);

    this.initializeData(this.data); // Ensure data is initialized with the status field
    console.log('Initialized Data:', this.data); // Log initialized data

    console.log(this.data);
    console.log(this.selectedItem);

    this.TreeNodeComponent.saveFormData(this.selectedItem, mode);
    this.changeStatus.next();
}


  expandAll() {
    this.TreeNodeComponent.expandAll();
  }

  collapseAll() {
    this.TreeNodeComponent.collapseAll();
  }

  goBack() {
    this.backClicked.next();
  }

  goToNext() {
    this.continueClicked.next();
  }

  applyFilter(name) {
    this.searchTerm = name;
    // this.data = _.cloneDeep(this.allPracticeInfoData);
    // if (!_.isEmpty(name)) {
    //   this.filteredData = [];
    //   this.data = this.getFilteredData(this.data, name);
    // }
  }

  getFilteredData(pageList, name) {
    _.forEach(pageList, (data) => {
      if (data.type == 'P') {
        if (_.includes(data['name'].toLowerCase(), name)) {
          _.forEach(this.filteredData, (item) => {
            if (item.id == data.parent) {
              item.children.push(data);
            }
          });
        }
      } else {
        let children = data.children;
        data.children = [];
        this.filteredData.push(data);
        this.getFilteredData(children, name);
      }
    });
    return this.filteredData;
  }
}
