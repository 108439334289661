// import {ReviewCTComponent} from  '../components/customer/intakes/add-intakes-data/call-tracking/review-ct/review-ct.component'
// import {ReviewComponent} from  '../components/customer/intakes/add-intakes-data/basic/review/review.component'
export enum masterEnum {
    role = 1,
    page_type = 2,
    site_type = 3,
    audit_type = 4,
    practice_type = 5,
    social_media_type = 10,
    third_party = 11,
    trade_associations = 12,
    pms_provider_type = 13,
    color_pallete = 14,
    banner_photography = 15,
    banner_type = 16,
    subject_matter_age_group = 17,
    subject_matter_ethnicity = 18,
    subject_matter_lifecycle = 19,
    subject_matter_products = 20,
    file_type = 21,
    smm_field = 22,
    smm_speciality_services = 23,
    special_services = 24,
    primary_goals = 25,
    age_group = 26,
    ethinicity = 27,
    lifestyle = 28,
    products = 29,
    palette = 30,
    contentSection = 32,
    element = 33,
    enhancedPages = 34,
    premiumPages = 35,
    referenceContent = 36,
    hearingAid = 40,
    reviewPlateForms=41,
    purpose = 37,
    icon=38,
    font=39,
    system_event=7,
    hearing_aid_manufacturers = 40,
    additional_review_platform = 41,
    website_insights = 42,
    ppc_insights = 43,
    fb_insights = 44,
    cs_adsource = 45,
    email_provider = 46,
    cancellation_reason = 47,
    sycle_version = 48,
    appointment_time = 49,
    ll_form_type = 50,
    ll_tag_type = 51,
    cp_division = 52
}

export function getRoleById(roleId: string) {
    // Convert the roles object into an array and find the role by roleId
    const allRoles = Object.values(roles);
    const role = allRoles.find(r => r.roleId === roleId);
    return role || null; // Return the role or null if not found
}

export const roles = {
    admin : {
        roleId : '1',
        url : '/admin'
    },
    businessDevelopment: {
        roleId : '2',
        url : '/business-development'
    },
    productManager: {
        roleId : '3',
        url : '/product-manager'
    },
    onboarding: {
        roleId : '4',
        url : '/onboarding'
    },
    accountManagement : {
        roleId : '5',
        url : '/account-manager'
    },
    clientCommunicationManager: {
        roleId : '6',
        url : '/client-communication-manager'
    },
    developer: {
        roleId : '7',
        url : '/designer-developer'
    },
    seo: {
        roleId : '8',
        url : '/seo-editor'
    },
    searchMarketing: {
        roleId : '9',
        url : '/search-marketing'
    },
    salesAndMarketing: {
        roleId : '10',
        url : '/salesAndMarketing'
    },
    client : {
        roleId : '11',
        url : '/client'
    },
    corporatePartner: {
        roleId : '12',
        url : '/corporate-partner'
    },
    siteUser: {
        roleId : '13',
        url : '/client'
    },
    corporatePartnerUser: {
        roleId : '14',
        url : '/corporate-partner'
    },
}

export const socialMedia = [
    {
        id : 1,
        name : 'Facebook',
        isSelected: false
    },
    {
        id : 2,
        name : 'Twitter',
        isSelected: false
    },
    {
        id : 9,
        name : 'YouTube',
        isSelected: false
    },
    {
        id : 3,
        name : 'LinkedIn',
        isSelected: false
    },
    {
        id : 4,
        name : 'Yelp',
        isSelected: false
    },
    {
        id : 7,
        name : 'Instagram',
        isSelected: false
    },
    {
        id : 8,
        name : 'Pinterest',
        isSelected: false
    },
    // {
    //     id : 9,
    //     name : 'Tumblr',
    //     isSelected: false
    // },
    {
        id : 10,
        name : 'Other',
        isSelected: false
    },
    {
        id : 11,
        name : 'Sister Practice',
        isSelected: false
    },
    {
        id : 12,
        name : 'Patient Portal',
        isSelected: false
    },
    {
        id : 13,
        name : 'Review Service (Ex. Healthy Hearing , Zoc Doc)',
        isSelected: false
    }
]

export class Enums {

}
