// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-circle {
  --border-width: 2px;
  --circle-diameter: 50px;

  background-color: rgb(48, 63, 78);
  border: var(--border-width) solid #8C96A0;
  border-radius: 50%;
  color: white;
  font-size: 1.5rem;
  height: var(--circle-diameter);
  line-height: calc(var(--circle-diameter) - var(--border-width) * 2);
  margin-right: .5rem;
  overflow: hidden;
  text-align: center;
  width: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.avatar-circle > img {
  width: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/users/avatar-circle/avatar-circle.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;;EAEvB,iCAAiC;EACjC,yCAAyC;EACzC,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,8BAA8B;EAC9B,mEAAmE;EACnE,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,0BAA0B;EAC1B,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".avatar-circle {\n  --border-width: 2px;\n  --circle-diameter: 50px;\n\n  background-color: rgb(48, 63, 78);\n  border: var(--border-width) solid #8C96A0;\n  border-radius: 50%;\n  color: white;\n  font-size: 1.5rem;\n  height: var(--circle-diameter);\n  line-height: calc(var(--circle-diameter) - var(--border-width) * 2);\n  margin-right: .5rem;\n  overflow: hidden;\n  text-align: center;\n  width: 50px;\n  -webkit-border-radius: 50%;\n  -moz-border-radius: 50%;\n}\n\n.avatar-circle > img {\n  width: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
