import { Component, OnInit } from '@angular/core';
import { UserService } from '../admin/users/services/user-service.service';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EqualPasswordsValidator } from '@shared/validators/equalPassword';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {


  changePasswordForm: UntypedFormGroup;
  userId;
  currentUserId;
  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.currentUserId = JSON.parse(localStorage.getItem('userInfo')).userId;
    this.initializeForm();
  }

  initializeForm() {
    this.changePasswordForm = this.formBuilder.group({
      userId: new UntypedFormControl(this.userId),
      oldPassword: new UntypedFormControl('', this.userId==this.currentUserId? [Validators.required]:null),
      newPassword: this.formBuilder.group({
        password: new UntypedFormControl('', [Validators.required]),
        confirmPassword: new UntypedFormControl('', [Validators.required])
      }, {
          validator: EqualPasswordsValidator.validate('password', 'confirmPassword')
        })


    });
  }

  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }

  updateNewPassword(form){
    let data = form.value;
    data.newPassword = data.newPassword.password;
    console.log(data);
    this.userService.updatePassword(data).subscribe((res) => {
      if (res) {
        this.toastrService.success('Password updated successfully', 'Success');
        this.dismissModal();
      }
    },
      err => {
         this.toastrService.error(err.error.status, 'Error');
      });
  }

}
