// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="radio"] {
  margin-right: 1rem;
  display: none;
}

input[type="radio"] + label span.checkbox {
  background: url('checkbox-unchecked.svg') no-repeat center;
  background-size: 100% 100%;
  display: inline-flex;
  height: 2rem;
  width: 2rem;
}

input[type="radio"]:checked + label span.checkbox {
  background: url('checkbox-checked.svg') no-repeat center;
  background-size: 100% 100%;
}

label {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 0;
  line-height: 100%;
}

.form-group-label {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1.3rem;
  width: 100%;
}

.label-text {
  display: inline-flex;
  margin: 0 1.5rem 0 0.7rem;
}

.radio-group {
  display: inline-flex;
  height: 2.5rem;
}

@media all {
  .required {
    color: rgb(223, 79, 60);
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/forms/radio-squared/radio-squared.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,0DAA8E;EAC9E,0BAA0B;EAC1B,oBAAoB;EACpB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,wDAA4E;EAC5E,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,oBAAoB;EACpB,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;AACF","sourcesContent":["input[type=\"radio\"] {\n  margin-right: 1rem;\n  display: none;\n}\n\ninput[type=\"radio\"] + label span.checkbox {\n  background: url('assets/images/icons/checkbox-unchecked.svg') no-repeat center;\n  background-size: 100% 100%;\n  display: inline-flex;\n  height: 2rem;\n  width: 2rem;\n}\n\ninput[type=\"radio\"]:checked + label span.checkbox {\n  background: url('assets/images/icons/checkbox-checked.svg') no-repeat center;\n  background-size: 100% 100%;\n}\n\nlabel {\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  margin-bottom: 0;\n  line-height: 100%;\n}\n\n.form-group-label {\n  font-size: 1.1rem;\n  font-weight: bold;\n  margin-bottom: 1.3rem;\n  width: 100%;\n}\n\n.label-text {\n  display: inline-flex;\n  margin: 0 1.5rem 0 0.7rem;\n}\n\n.radio-group {\n  display: inline-flex;\n  height: 2.5rem;\n}\n\n@media all {\n  .required {\n    color: rgb(223, 79, 60);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
