import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-show-message',
  templateUrl: './show-message.component.html',
  styleUrls: ['./show-message.component.css']
})
export class ShowMessageComponent implements OnInit {

  constructor( public activeModal: NgbActiveModal,) { }
  data;
  ngOnInit() {
  }
  dismissModal() {
    this.activeModal.close();
  }
}
