import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css']
})
export class RadioComponent implements OnInit {
  @Input() name: string;
  @Input() label: string;
  @Input() value: string;
  @Input() id ?: string;

  @Input() control;

  constructor() { }

  ngOnInit(): void {
    if (!this.id) {
      this.id = `${this.name}-${this.label.toLowerCase().replace(' ', '-')}`;
    }
  }

}
