import { Component, OnInit } from '@angular/core';
import {SiteService} from '../../../service/site.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../../../../shared/data-service.service';
@Component({
  selector: 'app-add-sycle-setup',
  templateUrl: './add-sycle-setup.component.html',
  styleUrls: ['./add-sycle-setup.component.css']
})
export class AddSycleSetupComponent implements OnInit {
  siteId
  adSources;
  sycleVersion;
  sycleSetupData;
  sycleForm: UntypedFormGroup;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  msg;
  timeZones;
  reviewGenAcc
    constructor(private siteService:SiteService,
                private toastrService:ToastrService,
                private formBuilder: UntypedFormBuilder,
                public activeModal: NgbActiveModal,
                private dataService :DataService,
                ) { 
      }
  
    ngOnInit() {
      //this.getAdsource();
      this.getSycleVersion();
      this.getTimeZone();
      this. getProfileData(this.siteId)
      this.initializeForm();
      
    }
  
    // getAdsource(){
    //   this.siteService.getMastersListById(45).subscribe(res=>{
    //     if(res){
    //       this.adSources=res.masterList;
    //     }
    //   },err=>{
    //     throw err;
    //   })
    // }
    getSycleVersion(){
      this.siteService.getMastersListById(48).subscribe(res=>{
        if(res){
          this.sycleVersion=res.masterList;
        }
      },err=>{
        throw err;
      })
    }
    saveSycleSetup(form){
      this.siteService.saveSycleSetup(form.value).subscribe(res=>{
        if(res){
          this.activeModal.close();
          this.toastrService.success(this.msg,'success');
        }
      },err=>{
        this.toastrService.error(err.error);
      })
    }
    initializeForm(){
      this.sycleForm=this.formBuilder.group({
        id:[this.sycleSetupData?this.sycleSetupData.id:0],
        siteId:[this.sycleSetupData?this.sycleSetupData.siteId:this.siteId,[Validators.required]],
        clinicId:[this.sycleSetupData?this.sycleSetupData.clinicId:'',[Validators.required]],
        version:[this.sycleSetupData?this.sycleSetupData.version:'',[Validators.required]],
        //adSource:[this.sycleSetupData?this.sycleSetupData.adSource:0],
        parentId:[this.sycleSetupData?this.sycleSetupData.parentId:'',[Validators.required]],
        location:[this.sycleSetupData?this.sycleSetupData.location:'',[Validators.required]],
        // clinicStreet1:[this.sycleSetupData?this.sycleSetupData.clinicStreet1:''],
        // clinicStreet2:[this.sycleSetupData?this.sycleSetupData.clinicStreet2:''],
        // clinicCity:[this.sycleSetupData?this.sycleSetupData.clinicCity:''],
        // clinicState:[this.sycleSetupData?this.sycleSetupData.clinicState:''],
        // clinicCountry:[this.sycleSetupData?this.sycleSetupData.clinicCountry:''],
        // clinicZip:[this.sycleSetupData?this.sycleSetupData.clinicZip:''],
        callbackEmail:[this.sycleSetupData?this.sycleSetupData.callbackEmail:'',[Validators.required]],
        timeZoneId:[this.sycleSetupData?this.sycleSetupData.timeZoneId:'',[Validators.required]],
        profileId:[this.sycleSetupData?this.sycleSetupData.profileId:''],
      });
    }
    dismissModal() {
      this.activeModal.dismiss();
      return false;
    }
    getTimeZone(){
      this.siteService.getTimeZone().subscribe(res=>{
        if(res){
          this.timeZones=res
        }
      },err=>{
        throw err;
      })
    }
    getProfileData(siteId) {
      this.siteService.getProfileData(siteId).subscribe((res: any) => {
        if (res) {
          this.reviewGenAcc = res;          
        }
      },
        err => {
          throw err;
        });
    }
  }
  