export class HoursOperation {

    lstLocationWorkingHours=[
        {
            id:0,
            locationId:0,
            day:'Monday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Tuesday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Wednesday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Thursday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Friday',
            openTime:'Closed',
            closeTime:'Closed'
        },
        {
            id:0,
            locationId:0,
            day:'Saturday',
            openTime:'Closed',
            closeTime:'Closed'
        },{
            id:0,
            locationId:0,
            day:'Sunday',
            openTime:'Closed',
            closeTime:'Closed'
        }
  ]
  constructor() {
  }

}


   
