import { Component, Input, OnInit } from '@angular/core';
import { ModalFrameComponent } from '@shared/components/frames/modal-frame/modal-frame.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from '@shared/models/Subscription';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusResponse } from '@shared/interfaces/responses';

@Component({
  selector: 'app-add-product-modal',
  templateUrl: './../../../shared/components/frames/modal-frame/modal-frame.component.html',
  styleUrls: [
    '../../../shared/components/frames/modal-frame/modal-frame.component.css',
    './add-product-modal.component.css'
  ]
})
export class AddProductModalComponent extends ModalFrameComponent implements OnInit {
  @Input() subscriptionService: SubscriptionService;
  @Input() addSubscriptionForm;
  @Input() siteId: number;

  bannerMessage = '';
  errorMessage = '';
  formSubmitted = false;

  constructor(public activeModal: NgbActiveModal) {
    super(activeModal);
  }

  ngOnInit(): void {
    this.confirmButtonLabel = 'Add Product';
    this.modalConfirmable = false;
    this.showX = false;
    this.title = 'Add a Product';
  }

  /**
   * Submits Product data to the API when the Add Product form submit button is clicked
   */
  confirmModal(): void {
    if (this.confirm) {
      const SubscriptionData = new Subscription(
        this.addSubscriptionForm.value
      );

      try {
        SubscriptionData.siteId = this.siteId;
        SubscriptionData.billingInterval = this.addSubscriptionForm.value.plan.interval;
        SubscriptionData.directBilled = this.addSubscriptionForm.value.directBilled;
        SubscriptionData.listPrice = this.addSubscriptionForm.value.plan.amount;
        SubscriptionData.addMetadata('noIntakeRequired', 'true');
        SubscriptionData.stripePriceId = this.addSubscriptionForm.value.plan.id;
      } catch (error) {
        this.errorMessage = 'There has been an error with your selection. Please try back' +
          ' soon or contact a technical admin for assistance.';
      }

      this.subscriptionService.postSubscriptions(SubscriptionData.getSubscriptionData())
        .subscribe(
          (response: StatusResponse) => {
            if (response) {
              this.dismissModal();
            }
          },
          (error: HttpErrorResponse) => {
            this.errorMessage = error.error.message;
            console.error(error);
          }
        );
    }
  }
}

