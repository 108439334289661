import { Component, OnInit } from '@angular/core';
import { BuildSiteService } from './build-site.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import {ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { HttpService } from '../../../services/http.service';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-build-site',
  templateUrl: './build-site.component.html',
  styleUrls: ['./build-site.component.css']
})
export class BuildSiteComponent implements OnInit {
  loading:boolean=false;
  buildSiteData:Array<Object>=[];
  wpThemesData:Array<Object>=[];
  wpTemplateData:Array<Object>=[];
  WpTemplate:Array<Object>=[];
  savedPages:Array<Object>=[];
  intakeId:number=null;
  userInfo;
  siteId;
  domainUrl;
  selectedTheme:string='';
  selectedTemplateId:string='';
  IntakePages={
              Intakeid:this.intakeId,
              WPThemeId:this.selectedTheme,
              WPTemplateId:this.selectedTemplateId
              };
baseUrl = environment['themeApiUrl'];              
headerSection=[]
selectedHeader:string='';
footerSection=[]
selectedFooter:string='';
ctaBlockLayout=[];
ctaSelection:string='';
typicalBlockLayout=[];
typicalSelection:string=''
ServiceBlockLayout=[];
serviceSelection:string='';
manufacturersBlock=[];
manufacturerSelection:string='';
  constructor(
    private buildSiteService: BuildSiteService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        this.getIntakeSideId(this.intakeId);
      }
    });
   }

  ngOnInit() {  
    this.getWpThemesData();
    this.userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
    this.headerSection=this.buildSiteService.headerSection;
    this.footerSection=this.buildSiteService.footerSection;
    this.ctaBlockLayout=this.buildSiteService.ctaBlockLayout;
    this.typicalBlockLayout=this.buildSiteService.typicalBlockLayout;
    this.ServiceBlockLayout=this.buildSiteService.ServiceBlockLayout;
    this.manufacturersBlock=this.buildSiteService.manufacturersBlock;
  }

  selectTheme(){
      this.WpTemplate=[];
      this.selectedTemplateId='';
      //getting template, based on theme selection
    _.forEach(this.wpTemplateData, (template) => {
      if(template.themeId===this.selectedTheme){
        this.WpTemplate.push({id:template.id,name:template.name})
       }
     })

  }
  getWpThemesData() {
    this.buildSiteService.getWpThemesData().subscribe((res) => {
      if (res) {
        this.wpThemesData = res;
        this.selectedTheme=this.wpThemesData[0]['id'];
        this.getWpTemplateData();
      }
    },
      err => {
         this.httpService.openErrorPopup(err.error.message);
      });
  }

  getWpTemplateData() {
    this.buildSiteService.getWpTemplateData().subscribe((res) => {
      if (res) {
        this.wpTemplateData = res;
        this.selectTheme();
      }
    },
      err => {
         this.httpService.openErrorPopup(err.error.message);
      });
  }

  updateIntake(){
     
    this.buildSiteData=[];
    this.IntakePages={
      Intakeid:this.intakeId,
      WPThemeId:this.selectedTheme,
      WPTemplateId:this.selectedTemplateId
      };
    this.buildSiteService.updateIntake(this.IntakePages).subscribe((res) => {
      if (res) {
        this.toastrService.success('Saved Successfully', 'Success');
         
      }
    },
      err => {
         
         this.httpService.openErrorPopup(err.error.message);
      });
  }

  getDomain(userId, siteId) {
    this.buildSiteService.getDomain(userId, siteId).subscribe((res) => {
      if (res) {
        this.domainUrl=res.domainUrl;
      }
    },
      err => {
         this.httpService.openErrorPopup(err.error.message);
      });
  }
  getIntakeSideId(intakeId) {
    this.buildSiteService.getIntakeSiteId(intakeId).subscribe((res:any) => {
      if (res) {
          this.siteId=res.siteId;
          this.getDomain(this.userInfo.userId, this.siteId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  headerChangeEvt(event,header){
    
  }
  footerChangeEvt(event,header){

  }

}
