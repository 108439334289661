import { Component, OnInit } from '@angular/core';
import { MasterService } from '../services/master.service';
// import { User } from '../models/user';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../services/http.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class MasterListComponent implements OnInit {

  public allMasters;
  constructor(
    private masterService:MasterService,
    private httpService: HttpService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    // this.getMasters();
  }

  getMasters(){
    this.masterService.getAllMasters().subscribe(res => {
      if(res){
        this.allMasters = res;
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

}
