// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statement-of-work{font-family: 'Source Sans Pro', 'Segoe UI', 'Droid Sans', Tahoma, Arial, sans-serif;
    font-size: 14px; margin:0 0 10px 0;}
.statement-of-work h1, .statement-of-work h2{font-size:38px; font-weight:300;}
.statement-of-work h2{font-size:33px;}
.statement-of-work p{margin-bottom:15px;}
.statement-of-work ul{margin-bottom: 10px!important; padding-left: 40px!important;  list-style-type: disc;}
.statement-of-work .kb-logo {  padding: 30px 0;}`, "",{"version":3,"sources":["webpack://./src/app/components/customer/statement-of-work/exhibit-a/exhibit-a.component.css"],"names":[],"mappings":"AAAA,mBAAmB,mFAAmF;IAClG,eAAe,EAAE,iBAAiB,CAAC;AACvC,6CAA6C,cAAc,EAAE,eAAe,CAAC;AAC7E,sBAAsB,cAAc,CAAC;AACrC,qBAAqB,kBAAkB,CAAC;AACxC,sBAAsB,6BAA6B,EAAE,4BAA4B,GAAG,qBAAqB,CAAC;AAC1G,+BAA+B,eAAe,CAAC","sourcesContent":[".statement-of-work{font-family: 'Source Sans Pro', 'Segoe UI', 'Droid Sans', Tahoma, Arial, sans-serif;\n    font-size: 14px; margin:0 0 10px 0;}\n.statement-of-work h1, .statement-of-work h2{font-size:38px; font-weight:300;}\n.statement-of-work h2{font-size:33px;}\n.statement-of-work p{margin-bottom:15px;}\n.statement-of-work ul{margin-bottom: 10px!important; padding-left: 40px!important;  list-style-type: disc;}\n.statement-of-work .kb-logo {  padding: 30px 0;}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
