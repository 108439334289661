import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { PagesModule } from './components/pages.module';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbActiveModal, NgbAccordionItem, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SharedModule } from '@shared/shared.module';
import { SiteProductComponent } from './components/site/site-config/site-product/site-product.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TimeListService } from '@shared/services/time-list/time-list.service';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ContentGenerationComponent } from './components/content-generation/content-generation.component';
import { CustomerModule } from './components/customer/customer.module';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';


@NgModule({
    declarations: [
        AppComponent,
        SiteProductComponent,
        ContentGenerationComponent
    ],
    imports: [
        CKEditorModule,
        CustomerModule,
        BrowserModule,
        SharedModule,
        NgbModule,
        RouterModule,
        PagesModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        routing,
        BrowserAnimationsModule,
        ToastrModule.forRoot({ closeButton: true }),
        HttpClientModule,         
        BsDatepickerModule.forRoot(),
        FontAwesomeModule,
        BaseChartDirective,
        CustomerModule,

        
    ],
    providers: [
        NgbActiveModal,
        TimeListService,
        provideCharts(withDefaultRegisterables()),
        provideAnimationsAsync()
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
