import { NgModule } from '@angular/core';
import { CoreModule} from '../../core/core.module';
import { CommonModule } from '@angular/common';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { CustomerIntakesListComponent } from './intakes/customer-intakes-list/customer-intakes-list.component';
import { AddIntakesDataComponent } from './intakes/add-intakes-data/add-intakes-data.component';
import { CustomerIntakeProductListComponent } from './intakes/intake-product-list/customer-intake-product-list.component';
import { StripePaymentComponent } from './payment/stripe-payment/stripe-payment.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BasicComponent } from './intakes/add-intakes-data/basic/basic.component';
import { BasicInfoComponent } from './intakes/add-intakes-data/basic/basic-info/basic-info.component';
import { StyleAndBrandingComponent } from './intakes/add-intakes-data/basic/style-and-branding/style-and-branding.component';
import { OtherPaymentComponent } from './payment/other-payment/other-payment.component';
import { CustomerDashboardComponent } from '.';
import { PpcFbAdsComponent } from './intakes/add-intakes-data/ppc-fb-ads/ppc-fb-ads.component';
import { SocialMediaMarketingComponent } from './intakes/add-intakes-data/social-media-marketing/social-media-marketing.component';
import { SocialMediaSetupComponent } from './intakes/add-intakes-data/social-media-setup/social-media-setup.component';
import { SharedModule } from '@shared/shared.module';
import { CallTrackingComponent } from './intakes/add-intakes-data/call-tracking/call-tracking.component';
import { SocialMediaComponent } from './intakes/add-intakes-data/basic/basic-info/social-media/social-media.component';
import { BasicPracticeComponent } from './intakes/add-intakes-data/basic/basic-info/basic-practice/basic-practice.component';
import { PagesComponent } from './intakes/add-intakes-data/basic/pages/pages.component';
import { ProductComponent } from './intakes/add-intakes-data/basic/pages/product/product.component';
import { ServicesComponent } from './intakes/add-intakes-data/basic/pages/services/services.component';
import { ResouresComponent } from './intakes/add-intakes-data/basic/pages/resoures/resoures.component';
import { PracticeinofComponent } from './intakes/add-intakes-data/basic/pages/practiceinof/practiceinof.component';
import { HomepageComponent } from './intakes/add-intakes-data/basic/pages/homepage/homepage.component';
import { AssociationsComponent } from './intakes/add-intakes-data/basic/basic-info/associations/associations.component';
import { BrandingComponent } from './intakes/add-intakes-data/basic/style-and-branding/branding/branding.component';
import { StyleComponent } from './intakes/add-intakes-data/basic/style-and-branding/style/style.component';
import { HomepagePhotographyComponent } from './intakes/add-intakes-data/basic/style-and-branding/homepage-photography/homepage-photography.component';

import { ContentComponent } from './intakes/add-intakes-data/basic/content/content.component';
import { PracticeInfoComponent } from './intakes/add-intakes-data/basic/content/practice-info/practice-info.component';
import { ContentHomepageComponent } from './intakes/add-intakes-data/basic/content/homepage/homepage.component';
import { ContentProductComponent } from './intakes/add-intakes-data/basic/content/product/product.component';
import { ContentResouresComponent } from './intakes/add-intakes-data/basic/content/resoures/resoures.component';
import { ContentServicesComponent } from './intakes/add-intakes-data/basic/content/services/services.component';
import { ReviewComponent } from './intakes/add-intakes-data/basic/review/review.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CustomPagesComponent } from './intakes/add-intakes-data/basic/pages/custom-pages/custom-pages.component';
import { CustomContentComponent } from './intakes/add-intakes-data/basic/content/custom-content/custom-content.component';
import { ContentEditorComponent } from './intakes/add-intakes-data/basic/content/content-editor/content-editor.component';
import { BasicLandingPageComponent } from './intakes/add-intakes-data/basic/basic-info/landing-page/landing-page.component';
import { StyleLandingPageComponent } from './intakes/add-intakes-data/basic/style-and-branding/landing-page/landing-page.component';
import { ContentLandingPageComponent } from './intakes/add-intakes-data/basic/content/landing-page/landing-page.component';
import { PagesLandingPageComponent } from './intakes/add-intakes-data/basic/pages/landing-page/landing-page.component';
import { PageSampleComponent } from './intakes/add-intakes-data/basic/pages/page-sample/page-sample.component';
import { LocatoinComponent } from './intakes/add-intakes-data/basic/content/locatoin/locatoin.component';
import { StaffBiosComponent } from './intakes/add-intakes-data/basic/content/staff-bios/staff-bios.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { EnhancedComponent } from './intakes/add-intakes-data/basic/enhanced/enhanced.component';
import { PremiumComponent } from './intakes/add-intakes-data/basic/premium/premium.component';
import { ReDesignComponent } from './intakes/add-intakes-data/re-design/re-design.component';
import { ReviewCTComponent } from './intakes/add-intakes-data/call-tracking/review-ct/review-ct.component';
import { ReviewPPCFBComponent } from './intakes/add-intakes-data/ppc-fb-ads/review-ppcfb/review-ppcfb.component';
import { ReviewGenComponent } from './intakes/add-intakes-data/review-gen/review-gen.component';
import { ReviewSMSComponent } from './intakes/add-intakes-data/social-media-setup/review-sms/review-sms.component';
import { ReviewSMMComponent } from './intakes/add-intakes-data/social-media-marketing/review-smm/review-smm.component';
import { MasterReviewComponent } from './intakes/master-review/master-review.component';
import { EmailComponent } from './intakes/add-intakes-data/email/email.component';
import { DomainComponent } from './intakes/add-intakes-data/domain/domain.component';
import { LogoComponent } from './intakes/add-intakes-data/logo/logo.component';
import { ReviewDomainComponent } from './intakes/add-intakes-data/domain/review-domain/review-domain.component';
import { ReviewEmailComponent } from './intakes/add-intakes-data/email/review-email/review-email.component';
import { CitationCleanupComponent } from './intakes/add-intakes-data/citation-cleanup/citation-cleanup.component';
import { ReviewReviewGenComponent } from './intakes/add-intakes-data/review-gen/review-review-gen/review-review-gen.component';
import { ReviewReDesignComponent } from './intakes/add-intakes-data/re-design/review-re-design/review-re-design.component';
import { ReviewCitationCleanupComponent } from './intakes/add-intakes-data/citation-cleanup/review-citation-cleanup/review-citation-cleanup.component';
import { ReviewLogoComponent } from './intakes/add-intakes-data/logo/review-logo/review-logo.component';
import {  BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RouterModule } from '@angular/router';
import { StatementOfWorkComponent } from './statement-of-work/statement-of-work.component';
import { ApprovalPopupComponent } from './intakes/add-intakes-data/approval-popup/approval-popup.component';
import { TreeNodeComponent } from '@shared/tree-node/tree-node.component';
import { FieldErrorDisplayComponent} from '@shared/field-error-display/field-error-display.component';
import { ContentTreeNodeComponent } from '@shared/content-tree-node/content-tree-node.component';
import { ClientInformationComponent } from './intakes/add-intakes-data/ppc-fb-ads/client-information/client-information.component';
import { CampaignInformationComponent } from './intakes/add-intakes-data/ppc-fb-ads/campaign-information/campaign-information.component';
import { CompetitorInformationComponent } from './intakes/add-intakes-data/ppc-fb-ads/competitor-information/competitor-information.component';
import { LandingPageComponent } from './intakes/add-intakes-data/ppc-fb-ads/landing-page/landing-page.component';
import { SeoLandingPageComponent } from './intakes/add-intakes-data/basic/premium/landing-page/landing-page.component';
import { GoogleMyBusinessComponent } from './intakes/add-intakes-data/basic/premium/google-my-business/google-my-business.component';
import { PremiumContentComponent } from './intakes/add-intakes-data/basic/premium/premium-content/premium-content.component';
import { PremiumPromotionsComponent } from './intakes/add-intakes-data/basic/premium/premium-promotions/premium-promotions.component';
import { FacebookConnectorComponent } from './intakes/add-intakes-data/basic/premium/facebook-connector/facebook-connector.component';
import { EnhancedContentComponent } from './intakes/add-intakes-data/basic/enhanced/enhanced-content/enhanced-content.component';
import { EnhancedFacebookComponent } from './intakes/add-intakes-data/basic/enhanced/enhanced-facebook/enhanced-facebook.component';
import { EnhancedLandingComponent } from './intakes/add-intakes-data/basic/enhanced/enhanced-landing/enhanced-landing.component';
import { TreeNgxModule } from '@modules/tree-ngx';
import { AssetsComponent } from './intakes/add-intakes-data/basic/content/assets/assets.component';
import { IntakeReviewComponent } from './intakes/add-intakes-data/intake-review/intake-review.component';
import { ExhibitAComponent } from './statement-of-work/exhibit-a/exhibit-a.component';
import { ExhibitCComponent } from './statement-of-work/exhibit-c/exhibit-c.component';
import { NotificationComponent } from '../notification/notification.component';
import { ExhibitBComponent } from './statement-of-work/exhibit-b/exhibit-b.component';
import { ServiceBoxComponent } from './service-box/service-box.component';
import { IntakesModule } from '@modules/intakes/intakes.module';
import { ReviewLocalListingsComponent } from './intakes/add-intakes-data/local-listings/review-ll.component';
import { FilterTagTypesByFormType } from '@modules/intakes/pipes/ll-tagtype-filter.pipe';
import { FilterTagsByFormId } from '@modules/intakes/pipes/ll-tags-filter.pipe';
import { TicketModule } from '../ticket/ticket.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from 'app/app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input'; 


@NgModule({
    imports: [
        NgbModule,
        CKEditorModule,
        CommonModule,
        CoreModule,
        HttpClientModule,
        AngularEditorModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        ColorPickerModule,
        TableModule,
        NgCircleProgressModule.forRoot(),
        BsDatepickerModule.forRoot(),
        RouterModule,
        TreeNgxModule,
        IntakesModule,
        TicketModule,
        FontAwesomeModule,
        PaginatorModule,
        NgbAccordionModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatIconModule,
        MatTabsModule, 
        MatInputModule // Include other Material modules as needed
        // Add MatTabsModule here

    ],
    declarations: [
        FieldErrorDisplayComponent,
        ContentTreeNodeComponent,
        TreeNodeComponent,
        CustomerIntakesListComponent,
        PageSampleComponent,
        CustomerDashboardComponent,
        AddIntakesDataComponent,
        BasicComponent,
        BasicInfoComponent,
        StyleAndBrandingComponent,
        CustomerIntakeProductListComponent,
        StripePaymentComponent,
        OtherPaymentComponent,
        PpcFbAdsComponent,
        SocialMediaMarketingComponent,
        SocialMediaSetupComponent,
        NotificationComponent,
        CallTrackingComponent,
        SocialMediaComponent,
        BasicPracticeComponent,
        PagesComponent,
        ProductComponent,
        ServicesComponent,
        ResouresComponent,
        PracticeinofComponent,
        HomepageComponent,
        AssociationsComponent,
        BrandingComponent,
        StyleComponent,
        HomepagePhotographyComponent,
        ContentComponent,
        PracticeInfoComponent,
        ContentHomepageComponent,
        ContentProductComponent,
        ContentResouresComponent,
        ContentServicesComponent,
        ReviewComponent,
        CustomPagesComponent,
        CustomContentComponent,
        ContentEditorComponent,
        BasicLandingPageComponent,
        StyleLandingPageComponent,
        PagesLandingPageComponent,
        ContentLandingPageComponent,
        LocatoinComponent,
        StaffBiosComponent,
        EnhancedComponent,
        PremiumComponent,
        ReDesignComponent,
        ReviewCTComponent,
        ReviewPPCFBComponent,
        ReviewSMSComponent,
        ReviewSMMComponent,
        MasterReviewComponent,
        EmailComponent,
        DomainComponent,
        ReviewGenComponent,
        ReviewDomainComponent,
        ReviewEmailComponent,
        CitationCleanupComponent,
        ReviewReviewGenComponent,
        ReviewReDesignComponent,
        ReviewCitationCleanupComponent,
        LogoComponent,
        ReviewLogoComponent,
        StatementOfWorkComponent,
        ApprovalPopupComponent,
        ClientInformationComponent,
        CampaignInformationComponent,
        CompetitorInformationComponent,
        LandingPageComponent,
        SeoLandingPageComponent,
        GoogleMyBusinessComponent,
        PremiumContentComponent,
        PremiumPromotionsComponent,
        FacebookConnectorComponent,
        EnhancedContentComponent,
        EnhancedFacebookComponent,
        EnhancedLandingComponent,
        AssetsComponent,
        IntakeReviewComponent,
        ExhibitAComponent,
        ExhibitCComponent,
        ExhibitBComponent,
        ServiceBoxComponent,
        ReviewLocalListingsComponent,
        FilterTagTypesByFormType,
        FilterTagsByFormId,

    ],
    exports: [
        StatementOfWorkComponent,
        ExhibitAComponent,
        ExhibitCComponent,
        NotificationComponent,
        CustomContentComponent
    ],
})
export class CustomerModule { }
