import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
})
export class DatepickerComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() label = 'Select Date';
  @Input() startDate = new Date();
  @Input() endDate = new Date().setFullYear(new Date().getFullYear() + 1);
  @Input() type = 'duration';

  name: string;

  bsConfig = {
    dateInputFormat: 'MM/DD/YYYY',
    customTodayClass: 'today',
  };
  modelData = {
    prorate: false,
    value: null,
  };
  value: any;

  constructor() { }

  ngOnInit(): void {
    this.modelData.value = this.value;
    this.name = this.label.toLowerCase().replace(' ', '-');
  }
}
