import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { masterEnum } from '@shared/master-enum.enum';
import { PageCollectionService } from '../services/page-collection.service';
import { MasterService } from '../../masters/services/master.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../services/http.service';

@Component({
  selector: 'app-page-collection-add',
  templateUrl: './page-collection-add.component.html',
  styleUrls: ['./page-collection-add.component.css']
})
export class PageCollectionAddComponent implements OnInit {
  addPageCollectionForm:UntypedFormGroup;
  existingCollection;
  siteTypeData;
  pageTypeData;
  pageCollectionList;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private pageCollectionService: PageCollectionService,
    private masterService: MasterService,
    private router: Router,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute
  ) { 
    this.activatedRoute.params.subscribe(params => {
      if (!params.id) {
        this.initializeForm();
        return;
      }
      this.pageCollectionService.getAllCollectionDataById(params.id).subscribe(res => {
        if (res) {
          this.existingCollection = res;
          this.ngOnInit();
        }
      },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
    });
  }

  ngOnInit() {
    this.getSiteTypeData();
    this.getPageTypeData();
    this.getPageCollectionData();
    this.initializeForm();
  }

  updatePageName(select){
    let selectedSiteType;
    let selectedPageType;
    if(select = 'site'){
      let siteTypeId = this.addPageCollectionForm.controls.siteTypeId.value;
      selectedSiteType = _.find(this.siteTypeData, (siteType) => {
        return siteType.id == siteTypeId;
      })
    }
    if(select = 'page'){
      let pageTypeId = this.addPageCollectionForm.controls.pageTypeId.value;
    selectedPageType = _.find(this.pageTypeData, (pageType) => {
      return pageType.id == pageTypeId;
    })
    }
    this.addPageCollectionForm.patchValue({name: selectedSiteType.name + ' : ' + selectedPageType.name});
  }

  setDefaultPageName(){
    if(this.addPageCollectionForm.controls.parentId.value != 0){
      let parentId = this.addPageCollectionForm.controls.parentId.value;
      let selectedParent = _.find(this.pageCollectionList, (parent) => {
        return parent.id == parentId;
      });
      this.addPageCollectionForm.patchValue({name: `${selectedParent.name}: `});
      let parentSplitVal = selectedParent.name.split(': ');
      let selectedSiteType = _.find(this.siteTypeData, (siteType) => {
        return siteType.name == parentSplitVal[0];
      })
      let selectedPageType = _.find(this.pageTypeData, (pageType) => {
        return pageType.name == parentSplitVal[1];
      })
      this.addPageCollectionForm.patchValue({siteTypeId: selectedSiteType.id});
      this.addPageCollectionForm.patchValue({pageTypeId: selectedPageType.id});
      this.addPageCollectionForm.get('siteTypeId').disable();
      this.addPageCollectionForm.get('pageTypeId').disable();
    }
    else{
      this.addPageCollectionForm.get('siteTypeId').enable();
      this.addPageCollectionForm.get('pageTypeId').enable();
    }
  }

  getPageCollectionData(){
    this.pageCollectionService.getAllCollectionData().subscribe(res => {
      if(res){
        this.pageCollectionList = res;
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  getSiteTypeData(){
    let siteTypeId = masterEnum.site_type;
    this.masterService.getMastersListById(siteTypeId).subscribe(res => {
      if(res){
        this.siteTypeData = res.masterList;
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    })
  }

  getPageTypeData(){
    let siteTypeId = masterEnum.page_type;
    this.masterService.getMastersListById(siteTypeId).subscribe(res => {
      if(res){
        this.pageTypeData = res.masterList;
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    })
  }

  addPageCollection(form){
      if (form.valid) { 
        let userInfo = form.value;
        //userInfo.imageURL = this.uploadData;
        if (userInfo.id) {
          //this.userService.submitUser(userInfo);
          this.pageCollectionService.updatePageCollection(userInfo).subscribe(res => {
            this.router.navigate(['/admin/page-collection']);
          //  (function(router) {
          //   setTimeout(function () { router.navigate(['/admin/users']); }, 50);
          // })( this.router);
          },
            err => {
              this.httpService.openErrorPopup(err.error.message);
            });
        } else {
          delete userInfo['id'];
          this.pageCollectionService.addPageCollection(userInfo).subscribe(res => {
            this.router.navigate(['/admin/page-collection']);
  
          },
            err => {
              this.httpService.openErrorPopup(err.error.message);
            });
        }
  
      }
  }

  initializeForm() {
    this.addPageCollectionForm = this.formBuilder.group({
      id: [(this.existingCollection ? this.existingCollection.id : null)],
      name: [this.existingCollection ? this.existingCollection.name : '', [Validators.required]],
      title: [this.existingCollection ? this.existingCollection.title : '', [Validators.required]],
      pageTypeId: [this.existingCollection ? this.existingCollection.pageTypeId : '', [Validators.required]],
      siteTypeId: [this.existingCollection ? this.existingCollection.siteTypeId : '', [Validators.required]],
      parentId: [this.existingCollection ? this.existingCollection.parentId : 0],
      description: [this.existingCollection ? this.existingCollection.description : '']
    });
  }
  

}
