import { Injectable } from '@angular/core';
import { HttpService } from '@services/http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { IntakeLocalListing, LocalListingsTag } from '@shared/interfaces/intakes';

@Injectable({
  providedIn: 'root',
})
export class LocalListingsService {

  readonly intakeFormTypes = {
    BUSINESS: '1',
    LOCATION: '2',
  };

  constructor(private httpService: HttpService) { }

  /**
   * Retrieves the Location data for a given site
   * @param {number} siteId The site ID for the given site
   * @return {Observable<IntakeLocalListing>} The data location data returned from the API
   */
  getLocalListingsIntake(siteId: string): Observable<IntakeLocalListing> {
    return this.httpService.getApiNoBody(`locallistings/IntakeLocalListings/${siteId}`);
  }

  /**
   * Retrieves the tas for the Local Listings forms
   * @param {String} formType The type of form to retrieve tags for
   * @see {@link LocalListingsService.intakeFormTypes}, the currently supported list of form types
   * @return {Observable<LocalListingsTag[]>} The tab data that can be passed to the UI to generate chips
   */
  getTags(formType: string): Observable<LocalListingsTag[]> {
    const params = new HttpParams({
      fromObject: {
        includeInactive: 'false',
        formType: formType,
      },
    });

    return this.httpService.getApiNoBody(`locallistings/tag?${params.toString()}`);
  }

  /**
   * Retrieves the tags, used for UI chips, for the Local Listings Business Info form
   * @see {@link getTags} for parent method
   * @return {Observable<LocalListingsTag[]>} The tag data that can be passed to the UI to generate chips
   */
  getBusinessTags(): Observable<LocalListingsTag[]> {
    return this.getTags(this.intakeFormTypes.BUSINESS);
  }

  /**
   * Retrieves the tags, used for UI chips, for the Local Listings Location Info form
   * @see {@link getTags} for parent method
   * @return {Observable<LocalListingsTag[]>} The tag data that can be passed to the UI to generate chips
   */
  getLocationTags(): Observable<LocalListingsTag[]> {
    return this.getTags(this.intakeFormTypes.LOCATION);
  }

  /**
   * Saves the Local Listings Business form
   *
   * @param {Observable<IntakeLocalListing>} myBusinessForm used to create the Local Listings Business Model
   * @return {Observable<IntakeLocalListing>} The Local Listings Business form data
   */
  saveBusinessForm(myBusinessForm: IntakeLocalListing): Observable<IntakeLocalListing> {
    return this.httpService.postApi(`locallistings/IntakeLocalListings`, myBusinessForm);
  }

  /**
   * Updates the Local Listings Business Form
   * @param {Observable<IntakeLocalListing>} object used to update the Local Listings Business Model
   * @return {Observable<IntakeLocalListing>} The Local Listings Business form data
   */
  updateBusinessForm(myBusinessForm: IntakeLocalListing): Observable<IntakeLocalListing> {
    return this.httpService.putApi(`locallistings/IntakeLocalListings`, myBusinessForm);
  }

  /**
   * Submits an intake object, derived from the location form, to the API
   * @param {Object} locationData
   * @return {Observable<any>}
   */
  saveLocationData(locationData: object): Observable<any> {
    return this.httpService.postApi('locallistings/location', locationData);
  }

  /**
   * Submits an intake object, derived from the location form, to the API
   * @param {Object} locationData
   * @return {Observable<any>}
   */
  updateLocationData(locationData: object): Observable<any> {
    return this.httpService.putApi('locallistings/location', locationData);
  }

  /**
   * Deletes an a business location data, derived from the location form, to the API
   * @param {Object} locationData
   * @return {Observable<any>}
   */
  deleteLocationData(locationData:any): Observable<any> {
    return this.httpService.deleteLocationApi(`locallistings/location`, null, null, locationData);
  }
}
