export enum Roles {
  SUPER_ADMIN = 1,
  BUSINESS_DEVELOPMENT = 2,
  PRODUCT_MANAGEMENT = 3 ,
  ONBOARDING = 4,
  ACCOUNT_MANAGEMENT = 5,
  CLIENT_COMMUNICATION = 6,
  DESIGNER_DEVELOPER = 7,
  SEO_EDITOR = 8,
  SEARCH_MARKETING = 9,
  SALES_MARKETING = 10,
  CLIENT = 11,
  CORPORATE_PARTNER = 12,
  SITE_USER = 13,
  CP_USER = 14,
}

export enum RolesInternalUsers{
  SUPER_ADMIN = 1,
  BUSINESS_DEVELOPMENT = 2,
  PRODUCT_MANAGEMENT = 3 ,
  ONBOARDING = 4,
  ACCOUNT_MANAGEMENT = 5,
  CLIENT_COMMUNICATION = 6,
  DESIGNER_DEVELOPER = 7,
  SEO_EDITOR = 8,
  SEARCH_MARKETING = 9,
  SALES_MARKETING = 10,
}
