// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep a.btn {
  margin-bottom: 0 !important;
}

.btn, .form-group input {
  height: 2.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/site/site-config/google-ads/google-ads.component.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[":host ::ng-deep a.btn {\n  margin-bottom: 0 !important;\n}\n\n.btn, .form-group input {\n  height: 2.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
