import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductManagerService } from '../services/product-manager.service';
import { MasterService } from '../../admin/masters/services/master.service';
import { DatePipe } from '@angular/common';
import { masterEnum } from '@shared/master-enum.enum';
import { ActivatedRoute } from '@angular/router';
import { SiteService } from '../../site/service/site.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import moment from 'moment';
import { CorporatePartnerService } from '../../admin/manage-corporate-partner/services/corporate-partner.service';
import { TicketService } from '../../ticket/service/ticket.service';
import { roles } from '@shared/master-enum.enum';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-completed-projects',
  templateUrl: './completed-projects.component.html',
  styleUrls: ['./completed-projects.component.css'],
  providers: [DatePipe],
})
export class CompletedProjectsComponent implements OnInit, PageHasTable {
  @ViewChild('dt') table: TableGenericComponent;

  accountManager;
  allProjects;
  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  corporatePartner;
  filterBy = {
    _endDate: null,
    _startDate: null,
    dateRange: null,
    domainUrl: null,
    businessName: null,
    startDate: null,
    endDate: null,
  };
  jiraUrl;
  projects = [];
  projectType;
  roles = roles;
  siteLists;
  siteType;
  userInfo;

  constructor(
    private productManagerService: ProductManagerService,
    private masterService: MasterService,
    private ticketService: TicketService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private siteService: SiteService,
    private corporatePartnerService: CorporatePartnerService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

    this.getCorporatePartner();
    this.getAccountManager();
    this.getProjects(this.filterBy);
    this.getProductType();
    this.getSiteType(masterEnum.site_type);
    this.getSites();

    this.getTableData();
  }

  selectedItem(obj?) {
    this.getProjects(this.filterBy);
  }

  /**
   * TODO Move Data call to `getTableData()`
   * @param {Object} filterBy
   */
  getProjects(filterBy) {
    this.productManagerService.getCompletedProjectData(filterBy).subscribe(res => {
      if (res) {
        if (Object.values(filterBy).every(x => (x === null || x === ''))) {
          this.allProjects = Object.assign([], res);
        }
        this.projects = res;
      }
    },
    err => {
        this.httpService.openErrorPopup(err.error.message);
    });
  }

  getSites(){
    this.siteService.getSites().subscribe((res) => {
      if (res) {
        this.siteLists = Object.assign([], res);
      }
    },
      err => {
        throw err;
      })
  };

  getProductType() {
    this.masterService.getGeneralMasterByType(4).subscribe((res) => {
      if (res) {
        this.projectType = res;
      }
    },
      err => {
        throw err;
      })
  }

  getCorporatePartner() {
    this.corporatePartnerService.getCorporatePartners().subscribe(res => {
      if (res) {
        this.corporatePartner = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getAccountManager() {
    this.siteService.getAccountManager().subscribe(res => {
      if (res) {
        this.accountManager = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getSiteType(id) {
    this.siteService.getMastersListById(id).subscribe((res) => {
      if (res) {
        this.siteType = res.masterList;
      }
    },
      err => {
        throw err;
      })
  }

  /**
   * Sets table configuration informamtion
   */
  getTableData(): void {
    this.columnDataTypes = {
      completedOn: 'date',
    };

    this.columnHeaders = [
      {field: 'domainUrl', header: 'Domain URL'},
      {field: 'siteName', header: 'Business Name'},
      {field: 'siteType', header: 'Practice Type', filter: 'select'},
      {field: 'corporatePartnerName', header: 'Program', filter: 'select'},
      {field: 'cpAccountNo', header: 'Account #'},
      {field: 'accountManagerName', header: 'Account Manager', filter: 'select'},
      {field: 'familyName', header: 'Project', filter: 'select'},
      {field: 'completedOn', header: 'Completed Date', filter: 'date'},
    ];
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.siteLists.filter(v => v['businessName'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  formatter = (result: string) => `${result['businessName']}`;

  searchDomain = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.siteLists.filter(v => v['domainUrl'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  domainFormatter = (result: string) => `${result['domainUrl']}`;

  applySearch(key) {
    if ((typeof this.filterBy[key]) == 'object') {
      this.filterBy[key] = this.filterBy[key][key];
    }
    this.getProjects(this.filterBy);
  }

  onValueChange(event) {
    this.filterBy.startDate = moment(event[0]).format('MM/DD/YYYY');
    this.filterBy.endDate = moment(event[1]).format('MM/DD/YYYY');
    this.filterBy['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.filterBy['_endDate'] = moment(event[1]).format('MM/DD/YY');
    this.getProjects(this.filterBy);
  }
}
