export class staffModel {

    constructor(){}
    
        id:number=0;
        staffName:string= '';
        title:string='';
        locationId:number=0;
        location:string='';
        phoneNumber:number=null;
        email:string='';
        bio:string='';
        picture:string=''
    
}
