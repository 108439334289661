import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-block',
  templateUrl: './header-block.component.html',
  styleUrls: ['./header-block.component.css'],
})
export class HeaderBlockComponent implements OnInit {
  @Input() showDefaultDescription = false;
  @Input() text: string;

  constructor() { }

  ngOnInit(): void {
  }
}
