// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date label {
  color: #2699FB;
  padding: 0 0 0 4px;
  text-decoration: underline;
}
.wizard-content {
  max-width: unset;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/site/intake-list/intake-list.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,0BAA0B;AAC5B;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".date label {\n  color: #2699FB;\n  padding: 0 0 0 4px;\n  text-decoration: underline;\n}\n.wizard-content {\n  max-width: unset;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
