import { Component, OnInit,Input } from '@angular/core';
import {ReportingService} from '../services/reporting.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-piwik-report',
  templateUrl: './piwik-report.component.html',
  styleUrls: ['./piwik-report.component.css']
})
export class PiwikReportComponent implements OnInit {
@Input()siteId;
iFrameUrl:any;
  constructor(private reportingService:ReportingService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getPiwik(this.siteId);
  }
  
  getPiwik(siteId){
    this.reportingService.getPiwik(siteId).subscribe(res=>{
      if(res){
        this.iFrameUrl=this.getSantizeUrl(res['iFrameUrl']);
      }
    },err=>{
      throw err;
    })
  }
  public getSantizeUrl(url : string) {
    let finalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url); 
    return finalUrl;
  }
}
