// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-flex;
}

.circle {
  background-color: white;
  border: solid 2px rgb(77, 79, 92);
  border-radius: 50px/50px;
  height: 16px;
  margin-left: auto;
  z-index: 2;
  width: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/shapes/circle-sm/circle-sm.component.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,uBAAuB;EACvB,iCAAiC;EACjC,wBAAwB;EACxB,YAAY;EACZ,iBAAiB;EACjB,UAAU;EACV,WAAW;AACb","sourcesContent":[":host {\n  display: inline-flex;\n}\n\n.circle {\n  background-color: white;\n  border: solid 2px rgb(77, 79, 92);\n  border-radius: 50px/50px;\n  height: 16px;\n  margin-left: auto;\n  z-index: 2;\n  width: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
