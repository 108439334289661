import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';
import { masterEnum } from '@shared/master-enum.enum';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private httpService: HttpService
      ) { }

      notify={
         type:null,
         notificationTypeName:''
      }

      getNotificationLists(): Observable<any> {
        return this.httpService.getApi(`site/BroadCastMessage`)
         .pipe(
           tap(res => {
             if (res) {
               return res;
             }

           })
         );
     }

     getUserType(type): Observable<any> {
      return this.httpService.getApi(`master/General/${type}`)
      .pipe(
        tap(res => {
          if(res){

            return res;
          }
      })
    );
    }
    getNotificationType(): Observable<any> {
      return this.httpService.getApi(`master/NotificationType/`)
      .pipe(
        tap(res => {
          if(res){

            return res;
          }
      })
    );
    }
     saveNotification(notification): Observable<any> {
       return this.httpService.postApi(`site/BroadCastMessage`, notification, '')
         .pipe(
           tap(res => {
             if (res) {

               return res;
             }

           })
         );
     }

     deleteNotification(id): Observable<any> {
      return this.httpService.deleteApi(`site/BroadCastMessage?Id=${id}`)
        .pipe(
          tap(res => {
            if (res) {

              return res;
            }

          })
        );
    }


    getSystemEventLits(): Observable<any[]> {
      return this.httpService.getApi(`master/general/${masterEnum.system_event}`)
        .pipe(
          tap(res => {
            if (res) {

              return res;
            }

          })
        );
    }
    getNotifications(SiteId,pageNo): Observable<any[]> {
      return this.httpService.getApi(`site/Notification?SiteId=${SiteId}&PageNo=${pageNo}`)
        .pipe(
          tap(res => {
            if (res) {

              return res;
            }

          })
        );
    }

}
