import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-export-to-csv',
  templateUrl: './export-to-csv.component.html',
  styleUrls: ['./export-to-csv.component.css'],
})
export class ExportToCsvComponent implements OnInit {
  @Output() clickFunction = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  generateCSV() {
    this.clickFunction.emit(null);
  }
}
