// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amount-column {
  min-width: 11%;
}
.flex-100 {
    flex-basis: 100px;
    min-width: 100px;
}
.blue-text {
    color: #3597EC !important;
}
.text-right {
    text-align: right !important;
}
.width-340 {
    width: 340px !important;
}
.font-semi-bold {
    font-weight: 600 !important;
}
.gray-bg {
    background: #F5F6FA;
}
.mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
}

table tr th:first-child,
tbody tr td:first-child {
    text-align: left !important;
}
table tr th,
tbody tr td {
text-align: right !important;
}

.intake-product-message {
  font-size: .95rem;
  font-style: italic;
  margin-bottom: 0;
}

.row {
    margin-right: -10px;
    margin-left: -10px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/account-manager/intakes/intake-product-list/intake-product-list.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,4BAA4B;AAChC;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,gCAAgC;AACpC;;AAEA;;IAEI,2BAA2B;AAC/B;AACA;;AAEA,4BAA4B;AAC5B;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".amount-column {\n  min-width: 11%;\n}\n.flex-100 {\n    flex-basis: 100px;\n    min-width: 100px;\n}\n.blue-text {\n    color: #3597EC !important;\n}\n.text-right {\n    text-align: right !important;\n}\n.width-340 {\n    width: 340px !important;\n}\n.font-semi-bold {\n    font-weight: 600 !important;\n}\n.gray-bg {\n    background: #F5F6FA;\n}\n.mb-4, .my-4 {\n    margin-bottom: 1.5rem !important;\n}\n\ntable tr th:first-child,\ntbody tr td:first-child {\n    text-align: left !important;\n}\ntable tr th,\ntbody tr td {\ntext-align: right !important;\n}\n\n.intake-product-message {\n  font-size: .95rem;\n  font-style: italic;\n  margin-bottom: 0;\n}\n\n.row {\n    margin-right: -10px;\n    margin-left: -10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
