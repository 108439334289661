import { Component, OnInit } from '@angular/core';
import { IntakesService } from '../../../customer/intakes/services/intakes.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-audit-detail',
  templateUrl: './audit-detail.component.html',
  styleUrls: ['./audit-detail.component.css']
})
export class AuditDetailComponent implements OnInit {
id;
auditDetils
  constructor(private intakesService:IntakesService,
    public activeModal: NgbActiveModal,) { }

  ngOnInit() {
    this.getAuditDetail(this.id);
  }
  getAuditDetail(id){
    this.intakesService.getAuditDetail(id).subscribe(res=>{
      if(res){
        this.auditDetils=res;
      }
    },err=>{
      throw err;
    })
  }
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
}
