import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByKey'
})
export class SortByKeyPipe implements PipeTransform {
  transform(arrayOfObjects: Array<object>, keyToSortBy?): Array<object> {
    const propComparator = (sortableKey) =>
      (a, b) => a[sortableKey] === b[sortableKey] ? 0 : a[sortableKey] < b[sortableKey] ? -1 : 1;

    return arrayOfObjects.sort(propComparator(keyToSortBy));
  }
}
