import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl,Validators, UntypedFormArray } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { environment } from 'environments/environment';
import { SectionStatus } from '../../review/section-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../../../services/http.service';

@Component({
  selector: 'app-style',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.css']
})
export class StyleComponent implements OnInit {

  styleForm: UntypedFormGroup;
  selectedSiteId = JSON.parse(localStorage.getItem('selectedSite')).siteId;
  data;
  @Input() existingData;
  @Input() sectionId;  
  @Input() intakeTypeId;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();  
  @Output() changeStatus= new EventEmitter<any>(); 
  intakeId;
  bannerTypeData;
  baseUrl = environment.imgUrl;
  checkWebsiteField:boolean=false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private intakesService: IntakesService,
    private activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private toastrService: ToastrService,
    private httpService: HttpService,
    public dataService:DataService
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
      }
    });
   }

  ngOnInit() {
    this.data = this.intakesService.basicInfo['style'] ? this.intakesService.basicInfo['style'] : this.existingData;
    this.getBannerTypeData();
    this.initializeForm();
  }

  getBannerTypeData() {
    this.intakesService.getBannerTypeData().subscribe((res) => {
      if (res) {
        let bannerTypeList = Object.assign([], res['masterList']);
        _.forEach(res['masterList'], (bannerType) => {
          if (bannerType) {
            bannerType.subItems = [];
            _.forEach(bannerTypeList, (type) => {
              if (_.includes(type['name'], bannerType.name) && type['name'] != bannerType.name) {
                type.name = type.name.replace(bannerType.name + ':', '');
                bannerType.subItems.push(type);
                _.remove(res['masterList'], type);
              }
            })
          }
        });
        this.bannerTypeData = res['masterList'];
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  initializeForm() {
  
    this.styleForm = this.formBuilder.group({
      acceptBanner: new UntypedFormControl(this.data ? this.data.acceptBanner : null),
      banner: new UntypedFormControl(this.data ? this.data.banner : null),
      bannerId1: new UntypedFormControl(this.data && this.data.bannerId1 !=null ? this.data.bannerId1 : null),
      bannerFile1: new UntypedFormControl(this.data ? this.data.bannerFile1 : null),
      bannerId2: new UntypedFormControl(this.data && this.data.bannerId2 !=null ? this.data.bannerId2 : null),
      bannerFile2: new UntypedFormControl(this.data ? this.data.bannerFile2 : null),
      bannerId3: new UntypedFormControl(this.data && this.data.bannerId3 !=null ? this.data.bannerId3 : null),
      bannerFile3: new UntypedFormControl(this.data ? this.data.bannerFile3 : null),
      bannerStyle: new UntypedFormControl(this.data ? this.data.bannerStyle : null),
      ctaStyle: new UntypedFormControl(this.data ? this.data.ctaStyle : null),
      photography: new UntypedFormControl(this.data ? this.data.photography : null),
      exampleWebsites: this.formBuilder.array((this.data && this.data.exampleWebsites.length) ? this.data.exampleWebsites.map(c => 
        this.exampleWebsiteInstance(c, this.data.exampleWebsites.indexOf(c))
      ) : [
        this.exampleWebsiteInstance('', 0),
        this.exampleWebsiteInstance('', 1)
      ]),
      description: new UntypedFormControl(this.data ? this.data.description : null)
    })

    this.checkExampleWebsite()
  }

  checkExampleWebsite() {
    _.forEach(this.styleForm.controls.exampleWebsites['controls'], (data) => {
      if(data.controls['url'].value == '' || data.controls['url'].value == null){
        console.log('disable')
        data.controls['comments'].disable();
        data.controls['colorPalette'].disable();
        data.controls['bannerStyle'].disable();
        data.controls['photographyStyle'].disable();
        data.controls['actionStyle'].disable();
        data.controls['navigationStyle'].disable();
        data.controls['headerStyle'].disable();
      }else{
        data.controls['comments'].enable();
        data.controls['colorPalette'].enable();
        data.controls['bannerStyle'].enable();
        data.controls['photographyStyle'].enable();
        data.controls['actionStyle'].enable();
        data.controls['navigationStyle'].enable();
        data.controls['headerStyle'].enable();
        console.log('enable')
      }
    })
  }
  exampleWebsiteInstance(c?, index?) {
    let instance = this.formBuilder.group({
      intakeId: this.intakeId,
      SNo: index+1,
      url: new UntypedFormControl(c ? c.url : null,Validators.pattern(this.dataService.urlRegex)),
      colorPalette: new UntypedFormControl(c ? c.colorPalette : null),
      bannerStyle: new UntypedFormControl(c ? c.bannerStyle : null),
      photographyStyle: new UntypedFormControl(c ? c.photographyStyle : null),
      actionStyle: new UntypedFormControl(c ? c.actionStyle : null),
      navigationStyle: new UntypedFormControl(c ? c.navigationStyle : null),
      headerStyle: new UntypedFormControl(c ? c.headerStyle : null),
      comments: new UntypedFormControl(c ? c.comments : null)
    })
    return instance;
  }

  openUploadedFilesPopup(fieldName){
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = this.selectedSiteId;
    model.componentInstance.field = fieldName;
    model.result.then((result) => {      
      if(result){
        // let field = result.field;
        let control = _.pick(this.styleForm.controls, fieldName);
        control[fieldName].patchValue(result[0]);
      }
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }

  isFieldValid(field: string,index:number =0) {   
    
    let control=this.styleForm.get('exampleWebsites');
     
    //  if(field=='comments'){
    //    for (let i=index;i<control['controls'].length;i++){ 
    //      return !control['controls'][i].controls.comments.valid && control['controls'][i].controls.comments.touched; 
    //    }
    //  }else{
      return !this.styleForm.get(field).valid && this.styleForm.get(field).touched;
    // }
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray){
        for (let i=0;i<control.controls.length;i++){
          this.validateAllFormFields(control.controls[i]);
        }
      }

    });
  }
  saveFormData(form, mode?) {

    if(this.styleForm.valid){
      console.log('valid form submitted');
      let intakeData = this.intakesService.basicInfo['style'] = form.value;
      if(intakeData.banner=='1'){
            intakeData.bannerId2='0';
            intakeData.bannerId3='0'
      }
     intakeData.intakeId = this.intakeId;
    _.assign(this.intakesService.commonBasicInfoObj, intakeData);
    _.forEach(intakeData.exampleWebsites, (data) => {
         this.checkWebsiteField=true;
      if(data.url !='' && data.url !=null){
        if(((data.colorPalette == null || data.colorPalette==false) && 
            (data.bannerStyle == null ||  data.bannerStyle==false) && 
            (data.photographyStyle == null || data.photographyStyle==false) &&
            (data.actionStyle == null || data.actionStyle==false) && 
            (data.navigationStyle == null || data.navigationStyle==false) && 
            (data.headerStyle == null || data.headerStyle==false )) ||
            (data.comments == null || data.comments == '')){
          this.checkWebsiteField=false;
          this.httpService.openErrorPopup(this.dataService.requiredMsg);
          return false;
        }
        //return false;
      }else{}

    })

if(this.checkWebsiteField){
    this.intakesService.saveIntake(this.intakesService.commonBasicInfoObj).subscribe(res => {
      if (res) {
        this.changeStatus.next();
        this.toastrService.success(this.dataService.successMsg, 'Success');  
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);        
        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, intakeData.intakeId);
      this.postIntakeSection(sectionStatus);
      if(mode == 'continue'){
        this.goToNext();
      }
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
    }
    }else{
      console.log('invalid form submitted');
      this.validateAllFormFields(this.styleForm);
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
  }

  getProductSectionData(familyId, intakeId){
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
       this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({basicMenu:this.intakesService.basicMenu,overAllPercentage:res.OverAllPercentage});
      
      }else{
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus){
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res)=>{
      if(res){
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
    err=>{
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  goBack() {
    this.backClicked.next();
  }

  goToNext(){
    this.continueClicked.next();
  }

  resetField(fields, checkVal){    
    _.forEach(fields, (field) => {
      this.styleForm.controls[field].setValue(null);
      // (checkVal == 1) ? this.styleForm.get(field).clearValidators() : this.styleForm.get(field).setValidators([Validators.required]);      
    })
  }

}
