import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { SiteService } from '../../service/site.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-facebook-ads',
  templateUrl: './facebook-ads.component.html',
  styleUrls: ['./facebook-ads.component.css']
})
export class FacebookAdsComponent implements OnInit {
  fbForm: UntypedFormGroup;
  @Input() siteInfo;
  @Output() updateSite = new EventEmitter<any>();
  userInfo = JSON.parse(localStorage.getItem("userInfo"));
  constructor(
    private siteService: SiteService,
    private toastrService: ToastrService,
    private formBuilder: UntypedFormBuilder,) { }

  ngOnInit() {
    this.initializeForm();
  }
  initializeForm() {
    this.fbForm = this.formBuilder.group({
      siteId: [this.siteInfo.id ? this.siteInfo.id : 0, [Validators.required]],
      fbAccountId: [this.siteInfo.oSiteSetting.fbAccountId ? this.siteInfo.oSiteSetting.fbAccountId : '']
    });
  }
  // getSiteInfo(siteId) {
  //   this.siteService.getSiteInfo(siteId).subscribe((res) => {
  //     if (res) {
  //       this.siteInfo = res;
  //       this.initializeForm();
  //     }
  //   },
  //     err => {
  //       throw err;
  //     });
  // }
  setUp(form) {
    if (form.valid) {
      this.siteService.siteSetup(form.value).subscribe(res => {
        if (res) {
          this.toastrService.success('Facebook Ads Setup Done', 'success');
          this.updateSite.next(this.siteInfo.id);
        }
      },
        err => {
          this.toastrService.error(err.error);
        })
    }

  }
}
