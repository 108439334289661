// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li.disabled {
  cursor: not-allowed;
}

li.disabled a {
  background: rgb(163, 166, 183) !important;
  color: rgb(245, 246, 250) !important;
  pointer-events: none;
}

span.error {
  background-color: unset !important;
  color: rgb(223, 79, 60);
}

.form-group > input {
  height: 2.5rem;
}

.form-group.button-container {
}

.panel-design .nav-tabs .nav-link.active {
    background: rgb(66, 150, 247);
    color: rgb(255, 255, 255) !important;
    text-decoration: none;
}

.wizard-content {
  max-width: unset;
}

.sitesearch-container {
  display: flex;
}

.site-clear-search {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.site-clear-search-button {
  opacity: 0.5;
  width: 25px;
  height: 25px;
}

.site-clear-search-button:hover {
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/site/site-setting/site-setting.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,yCAAyC;EACzC,oCAAoC;EACpC,oBAAoB;AACtB;;AAEA;EACE,kCAAkC;EAClC,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;AACA;;AAEA;IACI,6BAA6B;IAC7B,oCAAoC;IACpC,qBAAqB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":["li.disabled {\n  cursor: not-allowed;\n}\n\nli.disabled a {\n  background: rgb(163, 166, 183) !important;\n  color: rgb(245, 246, 250) !important;\n  pointer-events: none;\n}\n\nspan.error {\n  background-color: unset !important;\n  color: rgb(223, 79, 60);\n}\n\n.form-group > input {\n  height: 2.5rem;\n}\n\n.form-group.button-container {\n}\n\n.panel-design .nav-tabs .nav-link.active {\n    background: rgb(66, 150, 247);\n    color: rgb(255, 255, 255) !important;\n    text-decoration: none;\n}\n\n.wizard-content {\n  max-width: unset;\n}\n\n.sitesearch-container {\n  display: flex;\n}\n\n.site-clear-search {\n  position: absolute;\n  right: 0;\n  cursor: pointer;\n}\n\n.site-clear-search-button {\n  opacity: 0.5;\n  width: 25px;\n  height: 25px;\n}\n\n.site-clear-search-button:hover {\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
