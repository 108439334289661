// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-section{border-bottom:1px #D3D3D3 solid; margin-bottom:42px; padding-bottom:15px;}
.multi-checkbox .checkbox-container.md{margin-bottom:9px;}
.style-section.last-section{border-bottom:none; margin-bottom:0; padding-bottom:0}
.style-section h3.font-21{font-size:21px;}
.addition-design-note .form-control.aud-input.form-control-lg{min-height:160px;}
.addition-design-note .heading-content h3{font-size:21px;}
.style-section.last-section .form-control.aud-input.form-control-lg{margin-bottom:0;}
.form .form-field.multi-checkbox{margin-bottom:34px; margin-top:32px;}`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/basic/style-and-branding/style/style.component.css"],"names":[],"mappings":"AAAA,eAAe,+BAA+B,EAAE,kBAAkB,EAAE,mBAAmB,CAAC;AACxF,uCAAuC,iBAAiB,CAAC;AACzD,4BAA4B,kBAAkB,EAAE,eAAe,EAAE,gBAAgB;AACjF,0BAA0B,cAAc,CAAC;AACzC,8DAA8D,gBAAgB,CAAC;AAC/E,0CAA0C,cAAc,CAAC;AACzD,oEAAoE,eAAe,CAAC;AACpF,iCAAiC,kBAAkB,EAAE,eAAe,CAAC","sourcesContent":[".style-section{border-bottom:1px #D3D3D3 solid; margin-bottom:42px; padding-bottom:15px;}\n.multi-checkbox .checkbox-container.md{margin-bottom:9px;}\n.style-section.last-section{border-bottom:none; margin-bottom:0; padding-bottom:0}\n.style-section h3.font-21{font-size:21px;}\n.addition-design-note .form-control.aud-input.form-control-lg{min-height:160px;}\n.addition-design-note .heading-content h3{font-size:21px;}\n.style-section.last-section .form-control.aud-input.form-control-lg{margin-bottom:0;}\n.form .form-field.multi-checkbox{margin-bottom:34px; margin-top:32px;}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
