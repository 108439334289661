import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiteInfo } from '@shared/interfaces/site';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { InputGeneric } from '@shared/interfaces/forms';
import {
  countries as CountriesFile,
  getCountryCodeFromFullName,
  getCountrySubdivisionCodeFromFullName,
  getCountryData,
  getCountrySubdivision,
  states as StatesFile,
} from '@shared/data/locations';
import { AddressData, AddressDataEditForm, CountryData } from '@shared/interfaces/addresses';
import { ClinicLocationsService } from '@shared/services/clinic-locations/clinic-locations.service';
import { Subscription } from 'rxjs';
import {
  bermudanPostalCodeValidator,
  canadianPostalCodeValidator,
  caymanianPostalCodeValidator,
  usPostalCodeValidator,
} from '@shared/validators/postalCodes';
import { countrySubdivisionLabel, postalCodeLabelType } from '@shared/types/types';
import { NotificationsService } from '@shared/services/notifications/notifications.service';
import { DataResponse } from '@shared/interfaces/responses';

@Component({
  selector: 'app-manage-locations-edit-form',
  templateUrl: './manage-locations-edit-form.component.html',
  styleUrls: ['./manage-locations-edit-form.component.css'],
})
export class ManageLocationsEditFormComponent implements OnInit {
  @Input() site: SiteInfo;
  @Input() location: AddressData;

  @Output() onUpdate = new EventEmitter<any>();

  countrySubdvisionLabel: countrySubdivisionLabel = 'State';

  countries: InputGeneric[] = CountriesFile;
  countryData: CountryData;
  defaultFormData: AddressDataEditForm;
  editLocationForm = new UntypedFormGroup({
    addressId: new UntypedFormControl(null, Validators.required),
    address1: new UntypedFormControl(null, Validators.required),
    address2: new UntypedFormControl(null),
    billingAddress: new UntypedFormControl(null, Validators.required),
    city: new UntypedFormControl(null, Validators.required),
    clinicLocation: new UntypedFormControl(null, Validators.required),
    country: new UntypedFormControl('us', Validators.required),
    locationName: new UntypedFormControl(null, Validators.required),
    mailingAddress: new UntypedFormControl(null, Validators.required),
    postalCode: new UntypedFormControl(null, [
      Validators.required, Validators.maxLength(10),
    ]),
    primaryLocation: new UntypedFormControl(null, Validators.required),
    state: new UntypedFormControl(null, Validators.required),
  });
  formUpdatable = false;
  // @TODO: Change 'Post Code' to 'Postal Code' once form is made more responsive and there is space
  postalCodeLabel: postalCodeLabelType = 'ZIP Code';
  states: InputGeneric[] = StatesFile;
  postalCodeMaxLength = 10;
  postalCodePlaceholder = 'Please enter your Postal Code';
  provinces: InputGeneric[] = [];

  constructor(
    private clinicLocationsService: ClinicLocationsService,
    private notificationsService: NotificationsService,
  ) { }

  ngOnInit(): void {
    this.updateFormData();
    this.location.state = getCountrySubdivisionCodeFromFullName(
      this.location.state,
      this.location.country
    );
    this.updatePostalCodePlaceholder();

    this.getStatesProvinces();

    // Initializing Postal Code input attributes
    this.updatePostalCodePlaceholder();
    this.updatePostalCodeValidators(this.editLocationForm.get('country').value);
    this.updatePostalCodeMaxLength();

    // Updates validation for postalCode control, dynamically based on the selected country
    this.editLocationForm.get('country').valueChanges.subscribe((country: string) => {
      this.location.state = null;
      this.editLocationForm.controls['state'].setValue(null);
      this.editLocationForm.get('postalCode').setValue(null);
      this.updatePostalCodePlaceholder();
      this.updatePostalCodeMaxLength();
      this.updatePostalCodeValidators(country);
    });

    // On form change, marks a form as updatable if valid
    this.editLocationForm.valueChanges.subscribe(() => {
      this.formUpdatable = this.editLocationForm.valid;
    });

    // Temporary storing initial form data to pass back to the form on reset
    this.defaultFormData = this.editLocationForm.value;
  }

  /**
   * Converts address data into a format that is usable by the edit form
   *
   * @param {Object} data
   *
   * @return {AddressDataEditForm}
   */
  convertSubmittedFormDataToAddressFormat(data): AddressDataEditForm {
    return {
      addressId: data.addressId,
      address1: data.address1,
      address2: data.address2,
      billingAddress: data.isBillingAddress ? '1' : '0',
      city: data.city,
      country: data.country,
      clinicLocation: data.isClinicLocation ? '1' : '0',
      locationName: data.name,
      mailingAddress: data.isMailingAddress ? '1' : '0',
      postalCode: data.zip,
      primaryLocation: data.isPrimary ? '1' : '0',
      state: data.state,
    };
  }

  /**
   * Returns postal code Validators specific to each country
   *
   * @param {string} countryCode
   *
   * @return {ValidatorFn[]}
   */
  getCountryValidator(countryCode: string): ValidatorFn {
    switch (countryCode) {
      case 'bm': return bermudanPostalCodeValidator();
      case 'ca': return canadianPostalCodeValidator();
      case 'ky': return caymanianPostalCodeValidator();
      case 'us': return usPostalCodeValidator();

      default: return Validators.required;
    }
  }

  /**
   * Parses the response to return the appropriate error message
   *
   * @param {DataResponse} errorResponse The response from the API call
   *
   * @return {String} The extracted errormessage
   */
  getErrorMessageFromResponse(errorResponse: DataResponse<any>): string {
    const errorArray = errorResponse.errorMessage.split('\n ');

    const errorSummary = errorArray.find((messageDetail: string) => {
      return messageDetail.includes('Summary: ');
    });

    return errorSummary.split('Summary: ')[1];
  }

  /**
   * Sets the list of states/provinces and the appropriate menu label
   *
   * @param {String} country The country of the clinic location address. Will load the country's
   *                         subdivision
   */
  getStatesProvinces(country = this.editLocationForm.get('country').value): void {
    this.countryData = getCountryData(country.toLowerCase());

    this.countrySubdvisionLabel = this.countryData.subdivisionName;
    this.postalCodeLabel = this.countryData.postalCodeLabel;
    this.states = getCountrySubdivision(country);

    this.editLocationForm.get('city').clearValidators();
    this.editLocationForm.get('city').updateValueAndValidity();
  }

  /**
   * Resets the form and all the associated fields in the UI
   *
   * @param {AddressDataEditForm} formData
   */
  resetForm(formData: AddressDataEditForm = this.defaultFormData): void {
    this.defaultFormData.state = this.defaultFormData.state.toLowerCase();

    this.editLocationForm.reset(formData);

    this.getStatesProvinces();

    this.formUpdatable = false;
  }

  /**
   * Applies data from the location input to the FormGroup
   */
  updateFormData(): void {
    const state = 'al';

    if (this.location.state) {
      this.location.state = this.location.state.toLowerCase();
    }

    // Attempts to get country code from bad country data
    if (this.location.country.length != 2) {
      this.location.country = getCountryCodeFromFullName(this.location.country);
    }

    this.editLocationForm.patchValue({
      addressId: this.location.addressId,
      address1: this.location.address1,
      address2: this.location.address2,
      billingAddress: this.location.isBillingAddress ? '1' : '0',
      city: this.location.city,
      clinicLocation: this.location.isClinicLocation ? '1' : '0',
      country: this.location.country,
      locationName: this.location.name,
      mailingAddress: this.location.isMailingAddress ? '1' : '0',
      postalCode: this.location.zip,
      primaryLocation: this.location.isPrimary ? '1' : '0',
      state: getCountrySubdivisionCodeFromFullName(this.location.state, this.location.country),
    }, { onlySelf: false, emitEvent: true});

    this.defaultFormData = this.editLocationForm.value;

    this.formUpdatable = false;
  }

  /**
   * Update the `isPrimary` field in a given site location address
   *
   * @param {AddressData} addressData
   *
   * @return {Subscription}
   */
  updateIsPrimary(addressData: AddressData): Subscription {
    return this.clinicLocationsService.getSiteLocationAddress().subscribe(
      (siteLocationAddress) => {
        const sla = siteLocationAddress.find((siteLocationAddress) => {
          return siteLocationAddress.addressId === addressData.addressId &&
            siteLocationAddress.siteId === addressData.siteId;
        });

        this.clinicLocationsService.updateSiteLocationAddress({
          id: sla.id,
          isPrimary: addressData.isPrimary,
        }).subscribe(
          () => {},
          (error) => console.error(error),
          () => {
            this.defaultFormData = this.convertSubmittedFormDataToAddressFormat(addressData);

            this.resetForm();

            this.onUpdate.emit();
          }
        );
      }
    );
  }

  /**
   * Saves the location data to the API
   * @param {FormGroup} newLocation
   */
  updateLocation(newLocation: UntypedFormGroup) {
    const formData = newLocation.getRawValue();

    const addressData: AddressData = {
      addressId: formData.addressId,
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      country: formData.country,
      isActive: true,
      isBillingAddress: formData.billingAddress === '1' || formData.billingAddress === true,
      isClinicLocation: formData.clinicLocation === '1' || formData.clinicLocation === true,
      isMailingAddress: formData.mailingAddress === '1' || formData.mailingAddress === true,
      isPrimary: formData.primaryLocation === '1' || formData.primaryLocation === true,
      name: formData.locationName,
      siteId: this.site.id,
      state: formData.state,
      zip: formData.postalCode,
    };

    this.clinicLocationsService.updateAddress(addressData)
    .subscribe(
      (response) => {
        if (response.success) {
          this.notificationsService.showToastSuccess(
            `Location (${addressData.name}) has been updated.`,
            'Success'
          );
        } else {
          this.notificationsService.showToastError(
            this.getErrorMessageFromResponse(response),
            'Address Error'
          );
        }
      },
      (error) => {
        if (error.status === 422) {
          // Handle 422 Unprocessable Entity error
          const errorMessage = error.error ? error.error.message : 'Unprocessable Entity';
          this.notificationsService.showToastError(
            errorMessage,
            'You cannot change billing address when there is only one address listed.'
          );
        } else {
          // Optionally handle other types of errors
          this.notificationsService.showToastError(
            'An unexpected error occurred.',
            'Error'
          );
        }
      },
      () => this.updateIsPrimary(addressData)
    );
  
  }

  /**
   * Sets the max length according to the length of postal codes for the selected country
   */
  updatePostalCodeMaxLength() {
    switch (this.editLocationForm.get('country').value) {
      case 'bm': this.postalCodeMaxLength = 5; break;
      case 'ca': this.postalCodeMaxLength = 7; break;
      case 'ky': this.postalCodeMaxLength = 8; break;
      case 'us': this.postalCodeMaxLength = 10; break;
    }
  }

  /**
   * Updates the placeholder text for the Postal Code dropdown
   */
  updatePostalCodePlaceholder() {
    switch (this.editLocationForm.get('country').value) {
      case 'bm': this.postalCodePlaceholder = 'XX 11'; break;
      case 'ca': this.postalCodePlaceholder = 'X1X-1X1'; break;
      case 'ky': this.postalCodePlaceholder = 'KY1-1234'; break;
      case 'us': this.postalCodePlaceholder = '12345 or 12345-1234'; break;
    }
  }

  /**
   * Replaces the Validator from the postalCode control with one based on the selected country
   *
   * @param {String} countryCode The two-letter country code of the chosen country
   * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
   */
  updatePostalCodeValidators(countryCode: string): void {
    this.editLocationForm.controls['postalCode'].setValidators(
      [this.getCountryValidator(countryCode)]
    );

    this.editLocationForm.controls['postalCode'].updateValueAndValidity();
  }
}
