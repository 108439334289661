import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar-circle',
  templateUrl: './avatar-circle.component.html',
  styleUrls: ['./avatar-circle.component.css']
})
export class AvatarCircleComponent implements OnInit {
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() imageURL: string;

  userInitials: string;

  constructor() {}

  ngOnInit(): void {
    this.userInitials = this.firstName.charAt(0) + this.lastName.charAt(0);

    this.imageURL = this.getImageUrl();
  }

  getImageUrl (): string|null {
    if (this.imageURL.includes('ProfilePhoto/NoUserImage.png')) {
      return null;
    } else {
      return this.imageURL;
    }
  }
}
