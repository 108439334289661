import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from '../../../site/service/site.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '@services/http.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';

@Component({
  selector: 'app-sites-popup',
  templateUrl: './sites-popup.component.html',
  styleUrls: ['./sites-popup.component.css'],
})
export class SitesPopupComponent implements OnInit, PageHasTable {
  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  existingConfig = null;
  selectedSite = JSON.parse(localStorage.getItem('selectedSite'));
  sites;
  siteLists = [];
  userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };

  constructor(
    private siteService: SiteService,
    private httpService: HttpService,
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.getAssignUser(this.sites);

    this.getTableData();
  }

  getAssignUser(sites) {
    this.siteService.getAssignUser(sites.id).subscribe(res => {
      if (res) {
        this.siteLists = res;
        console.log(this.siteLists,'==siteLists');
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getTableData(): void {
    this.columnHeaders = [
      {field: 'domainUrl', header: 'Domain URL'},
      {field: 'siteName', header: 'Business Name'},
    ];
  }

  assignUser() {
    let productId = [];
    _.forEach(this.siteLists, (product) => {
      if (product.isAssign) {
        productId.push(product.siteId);
      }
    })
    let data ={
      SiteId:productId,
      UserId:this.sites.id
            }
    this.siteService.assignUser(data).subscribe(res => {
      if (res) {
        this.toastrService.success('Assigned successfully', 'Success');
        this.dismissModal();
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
}
