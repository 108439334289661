import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTagTypesByFormType',
})
export class FilterTagTypesByFormType implements PipeTransform {
  transform(tagTypes: any[], formType: number): any {
    if(formType == 1){
      // TODO: remove hardcoding of tagTypes by name
      return tagTypes.filter(tagType => tagType.name == 'From the Business' || tagType.name == 'Crowd' || tagType.name == 'Health & Safety' || tagType.name == 'Payment');
    }
    if(formType == 2){
      return tagTypes.filter(tagType => tagType.name == 'Category' || tagType.name == 'Accessibilty' || tagType.name == 'Amenities' || tagType.name == 'Service Options');
    }
  }
}
