// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.down-arrow {
    background: url(/assets/images/up-arrow-offgray.svg) no-repeat;
    width: 11px;
    height: 7px;
    /* display: inline-block;
    margin-left: 16px; */
    transform: rotate(90deg);
    float: left;
    margin-right: 7px;
    margin-left: 0;
    margin-top: 7px;
}

.down-arrow.show{
    background: url(/assets/images/up-arrow-offgray.svg) no-repeat;
    width: 11px;
    height: 7px;
    /* display: inline-block; */
    /* margin-left: 16px; */
    transform: rotate(180deg);
    float: left;
    margin-right: 7px;
    margin-left: 0;
    margin-top: 7px;
}
.table.table-style tr.active-row td{background:#E7E9F2;}

.sorting-tr th {
    background: #F5F6FA;
    border: none !important;
    color: #4D4F5C;
}
.sorting-tr td {
    /* min-width: 119px; */
    border: none !important;
    background: #F5F6FA;
}
.sorting-tr th:first-child, .sorting-tr td:first-child{
    border-left: #D1D3DB solid 1px !important;
}

.sorting-tr th:last-child, .sorting-tr td:last-child{
    border-right: #D1D3DB solid 1px !important;
}
.date label{
  color:#2699FB; padding:0 0 0 4px; text-decoration:underline;
}

.wizard-content {
  max-width: unset;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/admin/manage-corporate-partner/manage-corporate-partner.component.css"],"names":[],"mappings":"AAAA;IACI,8DAA8D;IAC9D,WAAW;IACX,WAAW;IACX;wBACoB;IAEpB,wBAAwB;IACxB,WAAW;IACX,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,8DAA8D;IAC9D,WAAW;IACX,WAAW;IACX,2BAA2B;IAC3B,uBAAuB;IAEvB,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB;AACA,oCAAoC,kBAAkB,CAAC;;AAEvD;IACI,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;AAClB;AACA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,yCAAyC;AAC7C;;AAEA;IACI,0CAA0C;AAC9C;AACA;EACE,aAAa,EAAE,iBAAiB,EAAE,yBAAyB;AAC7D;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".down-arrow {\n    background: url(/assets/images/up-arrow-offgray.svg) no-repeat;\n    width: 11px;\n    height: 7px;\n    /* display: inline-block;\n    margin-left: 16px; */\n    -webkit-transform: rotate(90deg);\n    transform: rotate(90deg);\n    float: left;\n    margin-right: 7px;\n    margin-left: 0;\n    margin-top: 7px;\n}\n\n.down-arrow.show{\n    background: url(/assets/images/up-arrow-offgray.svg) no-repeat;\n    width: 11px;\n    height: 7px;\n    /* display: inline-block; */\n    /* margin-left: 16px; */\n    -webkit-transform: rotate(180deg);\n    transform: rotate(180deg);\n    float: left;\n    margin-right: 7px;\n    margin-left: 0;\n    margin-top: 7px;\n}\n.table.table-style tr.active-row td{background:#E7E9F2;}\n\n.sorting-tr th {\n    background: #F5F6FA;\n    border: none !important;\n    color: #4D4F5C;\n}\n.sorting-tr td {\n    /* min-width: 119px; */\n    border: none !important;\n    background: #F5F6FA;\n}\n.sorting-tr th:first-child, .sorting-tr td:first-child{\n    border-left: #D1D3DB solid 1px !important;\n}\n\n.sorting-tr th:last-child, .sorting-tr td:last-child{\n    border-right: #D1D3DB solid 1px !important;\n}\n.date label{\n  color:#2699FB; padding:0 0 0 4px; text-decoration:underline;\n}\n\n.wizard-content {\n  max-width: unset;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
