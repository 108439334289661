import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, debounceTime, finalize } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../environments/environment";
import { Router, ActivatedRouteSnapshot, ActivatedRoute, RouterStateSnapshot } from "@angular/router";
declare var show: Function;
declare var hide: Function;
import { AlertPopupComponent } from "@shared/alert-popup/alert-popup.component";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

/** @deprecated Use the HTTP service located is `shared/services/http` */
@Injectable({ providedIn: "root" })
export class HttpService {
  public API_URL = environment.apiUrl;
  public isMasterMockTrue = environment.isMasterMockTrue;

  public httpOptions;
  constructor(
    private http: HttpClient,
    private router: Router,
    private modalService: NgbModal,
    private state: ActivatedRoute,
    private toastrService: ToastrService
  ) {
    this.setHeaders();
  }
  private setHeaders(contentType?) {
    const userInfo =
      localStorage.getItem("userInfo") != null
        ? JSON.parse(localStorage.getItem("userInfo"))
        : { token: "", userId: null, roleId: null };
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": contentType || "application/json",
        Authorization: "bearer " + userInfo.token,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
        "If-Modified-Since": "0"
      })
    };
  }

  public getApiNoBody(
    url: string,
    setheader: boolean = true,
    mockFileName?: string,
    isMockTrue: boolean = false,
    isShowError:boolean =true
  ) {
    show();
    url=url.replace(/\+/gi, '%2B');
    if (this.httpOptions !== null) this.setHeaders();
    if (setheader == false) {
      const userInfo =
      localStorage.getItem("userInfo") != null
        ? JSON.parse(localStorage.getItem("userInfo"))
        : { token: "", userId: null, roleId: null };

      this.httpOptions = {
        reportProgress: true,
        responseType: "blob",
        headers: new HttpHeaders({
          Authorization: "bearer " + userInfo.token
        })
      };
    }
    if (this.isMasterMockTrue || isMockTrue) {
      return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
    } else {
      return this.http.get(this.API_URL + url, this.httpOptions).pipe(
        map(response => response),
        catchError(this.handleError<any>(isShowError)),
        finalize(() => {
          hide();
        })
      );
    }
  }

  public getApi(
    url: string,
    setheader: boolean = true,
    mockFileName?: string,
    isMockTrue: boolean = false,
    isShowError:boolean =true,
    options?: any
  ) {
    show();
    url=url.replace(/\+/gi, '%2B');
    if (this.httpOptions !== null) this.setHeaders();
    if (setheader == false) {
      const userInfo =
      localStorage.getItem("userInfo") != null
        ? JSON.parse(localStorage.getItem("userInfo"))
        : { token: "", userId: null, roleId: null };

      this.httpOptions = {
        reportProgress: true,
        responseType: "blob",
        headers: new HttpHeaders({
          Authorization: "bearer " + userInfo.token
        })
      };
    }
    if (options != null) {
      this.httpOptions.responseType = options.responseType;
      this.httpOptions.observe = options.observe;
      this.httpOptions.header = options.header;
    }
    if (this.isMasterMockTrue || isMockTrue) {
      return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
    } else {
      return this.http.get(this.API_URL + url, this.httpOptions).pipe(
        map(response => response),
        catchError(this.handleError<any>(isShowError)),
        finalize(() => {
          hide();
        })
      );
    }
  }
  public postApi(
    url: string,
    options,
    contentType?,
    mockFileName?: string,
    isMockTrue: boolean = false
  ) {
    show();
    const userInfo =
    localStorage.getItem("userInfo") != null
      ? JSON.parse(localStorage.getItem("userInfo"))
      : { token: "", userId: null, roleId: null };
    if (options instanceof FormData) {
      this.httpOptions = {
        headers: new HttpHeaders({
          Authorization: "bearer " + userInfo.token
        }), // Do not set Content-Type
      };
    }
    if (this.isMasterMockTrue || isMockTrue) {
      return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
    } else {
      let requestParams = {};
      requestParams = options;
      return this.http
        .post(this.API_URL + url, requestParams, this.httpOptions)
        .pipe(
          map(response => response),
          catchError(this.handleError<any>()),
          finalize(() => {
            hide();
          })
        );
    }
  }

  public putApi(
    url: string,
    options,
    mockFileName?: string,
    isMockTrue: boolean = false
  ) {
    show();
    if (this.isMasterMockTrue || isMockTrue) {
      return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
    } else {
      let requestParams = {};
      requestParams = options;
      return this.http
        .put(this.API_URL + url, requestParams, this.httpOptions)
        .pipe(
          // @ts-ignore
          map(response => response),
          catchError(this.handleError<any>()),
          finalize(() => {
            hide();
          })
        );
    }
  }
  public postApiThird(
    url: string,
    options,
    contentType?,
    mockFileName?: string,
    isMockTrue: boolean = false
  ) {
    show();
    if (this.httpOptions !== null) {
      this.setHeaders1();
    }
    if (contentType) {
      this.setHeaders1(contentType);
    }
    if (this.isMasterMockTrue || isMockTrue) {
      return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
    } else {
      let requestParams = {};
      requestParams = options;
      return this.http.post(url, requestParams, this.httpOptions).pipe(
        // @ts-ignore
        map(response => response),
        catchError(this.handleError<any>()),
        finalize(() => {
          hide();
        })
      );
    }
  }
  private setHeaders1(contentType?) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": contentType || "application/json"
      })
    };
  }

  public handleError<T>(isShowError:boolean=true,operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (
        error.status === 401 ||
        error.status === 502 ||
        error.status === 504
      ) {
        if (document.getElementById("chat-widget-container"))
          document.getElementById("chat-widget-container").style.display='none';
        localStorage.removeItem("userInfo");
        if (!this.state.snapshot["_routerState"].url.includes("returnUrl"))
          this.router.navigate(["/login"], {
            queryParams: { returnUrl: this.state.snapshot["_routerState"].url }
          });
      } else if (error.status === 404 && error.error.warning &&isShowError) {
        this.openErrorPopup(error.error.warning);
        throw null;
      }
      throw error;
    };
  }
  public deleteApi(
    url: string,
    mockFileName?: string,
    isMockTrue: boolean = false
  ) {
    show();
    if (this.httpOptions !== null) this.setHeaders();
    if (this.isMasterMockTrue || isMockTrue) {
      return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
    } else {
      return this.http.delete(this.API_URL + url, this.httpOptions).pipe(
        // @ts-ignore
        map(response => response),
        catchError(this.handleError<any>()),
        finalize(() => {
          hide();
        })
      );
    }
  }

    /**
   * Performs a DELETE request to the specified endpoint
   * @param {String} url          The endpoint to use
   * @param {String} mockFileName The filename to use if using mock data
   * @param {Boolean }isMockTrue  Set true if using mock data
   *
   * @return {Observable<ArrayBuffer|Object>} The response from the endpoint
   */
    deleteLocationApi(
      url: string,
      mockFileName?: string,
      isMockTrue: boolean = false,
      body?: any 
    ): Observable<ArrayBuffer| Object> {
      show();
  
      if (this.httpOptions !== null) this.setHeaders();
  
  //    let params = {"llBusinessId":body.ll_business_id,"locationId":body.ll_location_id};
      let params = "?llBusinessId="+body.ll_business_id+"&locationId=" + body.ll_location_id;
  
      if (this.isMasterMockTrue || isMockTrue) {
        return this.http.get(`assets/mockJSON/get/${mockFileName}.json`);
      } else {
        return this.http.delete(this.API_URL + url + params, this.httpOptions).pipe(
          map((response) => response),
          catchError(this.handleError<any>()),
          finalize(() => {
            hide();
          })
        );
      }
    }

  /**
   * Shows a modal with the given message
   *
   * @deprecated
   * @param {String} message
   */
  public openErrorPopup(message) {
    var model = this.modalService.open(AlertPopupComponent, {
      size: "sm",
      ariaLabelledBy: "modal-basic-title",
      backdrop: "static"
    });
    model.componentInstance.message = message;
    model.result.then(
      result => {
        console.log(`add success: ${result}`);
      },
      reason => {
        console.log(`Dismissed: ${reason}`);
      }
    );
  }
}
