import { Component, OnInit } from '@angular/core';
import { UserService } from '../admin/users/services/user-service.service';
import { environment } from 'environments/environment';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import {ToastrService } from 'ngx-toastr';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../services/http.service';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  userProfile;
  baseUrl = environment.imgUrl;
  userProfileForm: FormGroup;
  selectedSiteId;
  isEdit;
  showUpdateBtn;
  imgUrl;
  isFileInvalid;
  fileName;
  uploadData: FormData = new FormData();
  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private toastrService: ToastrService,
    public dataService:DataService
  ) { }

  ngOnInit() {
    let userId = JSON.parse(localStorage.getItem('userInfo')).userId;
    this.selectedSiteId = JSON.parse(localStorage.getItem('userInfo')).siteIds;
    this.getUserProfile(userId);
  }

getUserProfile(id){
  this.userService.getUserInfo(id).subscribe((res)=>{
    if(res){
      this.userProfile = res;
      console.log(this.userProfile);
      this.imgUrl = this.baseUrl + this.userProfile.imageURL;
      this.fileName = this.userProfile.imageURL ? this.userProfile.imageURL.substring(this.userProfile.imageURL.lastIndexOf('/')+1) : this.userProfile.imageURL;
      this.initializeForm();
    }
  },
  err=>{
    throw err;
  })
}

initializeForm(){
  this.userProfileForm = this.formBuilder.group({
    id: new FormControl(this.userProfile.id),
    userRoleId: new FormControl(this.userProfile.userRoleId),
    imageUrl: new FormControl(),    
    email: new FormControl(this.userProfile.email,Validators.pattern(this.dataService.emailRegex)),
    username: new FormControl(this.userProfile.username,Validators.pattern(this.dataService.emailRegex)),
    firstName: new FormControl(this.userProfile.firstName),
    middleName: new FormControl(this.userProfile ? this.userProfile.middleName : null),
    lastName: new FormControl(this.userProfile ? this.userProfile.lastName : null),
    phone: new FormControl(this.userProfile ? this.userProfile.phone : null, Validators.pattern(this.dataService.phoneRegex)),
    extension:new FormControl(this.userProfile ? this.userProfile.extension : '', [Validators.pattern(this.dataService.numberRegex)])
  });
  this.userProfileForm.valueChanges.subscribe(val => {
    this.showUpdateBtn = true;
  });
}

onFileChanged(event) {
  if (event) {
    let file = event.target.files[0];
    this.fileName = file.name;
    this.uploadData.append('myFile', file, file.name);
    var checkimg = event.target.value.toLowerCase();
    if (!checkimg.match(/(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG)$/)){ 
        this.isFileInvalid = true;
        return false;
     }else{
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onload = (event) => { // called once readAsDataURL is completed
          this.imgUrl = event.target['result'];
          this.isFileInvalid = false;
        }
      }
     }
  }

}

uploadFile(data) {
  if (this.uploadData.has("myFile")) {
    this.uploadData.append("userId", data.id)
    this.userService.uploadProfileImg(this.uploadData).subscribe(ures => {
      this.userService.updatedDataSelection(ures.File);      
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    })
  }
}
isFieldValid(field: string,index:number =0) {
  return !this.userProfileForm.get(field).valid && this.userProfileForm.get(field).touched;
}

displayFieldCss(field: string) {
return {
  'has-error': this.isFieldValid(field),
  'has-feedback': this.isFieldValid(field)
};
}
validateAllFormFields(formGroup: any) {

Object.keys(formGroup.controls).forEach(field => {
  const control = formGroup.get(field);
  if (control instanceof FormControl) {
    control.markAsTouched({ onlySelf: true });
  } else if (control instanceof FormGroup) {
    this.validateAllFormFields(control);
  } else if (control instanceof FormArray){
    for (let i=0;i<control.controls.length;i++){
      this.validateAllFormFields(control.controls[i]);
    }
  }

});
}
updateUserInfo(form){

  if(form.valid){
    this.userService.updateUserInfo(form.value).subscribe((res) => {
      if (res) {
        this.uploadFile(form.value);
        this.toastrService.success('Saved Successfully', 'Success');
      }
    },
      err => {
         this.httpService.openErrorPopup(err.error.message);
      });
  }else{
    console.log('invalid form submitted');
    this.validateAllFormFields(this.userProfileForm);
    this.httpService.openErrorPopup(this.dataService.requiredMsg);
  }

}

}