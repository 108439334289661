// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-file{height:auto !important;}
h5{
    color: #43425D;
}`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/basic/style-and-branding/branding/branding.component.css"],"names":[],"mappings":"AAAA,aAAa,sBAAsB,CAAC;AACpC;IACI,cAAc;AAClB","sourcesContent":[".custom-file{height:auto !important;}\nh5{\n    color: #43425D;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
