import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadsComponent } from './downloads.component';
import { GmbDownloadsComponent } from './gmb-downloads/gmb-downloads.component';
import { SendibleDownloadsComponent } from './sendible-downloads/sendible-downloads.component';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ExportDataComponent } from './export-data/export-data.component';
import { SharedModule } from '@shared/shared.module';
import {  BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    ReactiveFormsModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    PaginatorModule,

  ],
  declarations: [
    DownloadsComponent,
    GmbDownloadsComponent,
    SendibleDownloadsComponent,
    ExportDataComponent
  ]
})
export class DownloadsModule { }
