// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  color: rgb(66, 150, 247) !important;
  font-size: 0.85rem;
  font-weight: bold;
  text-decoration: underline !important;
}

h2 {
  color: rgb(138, 149, 157);
  font-family: "Source Sans Pro", sans;
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
}

p {
  color: rgb(48, 62, 78);
  font-family: "Roboto Slab", serif;
  font-size: 1.25rem;
}

section {
  display: flex;
  flex-direction: row;
}

.promotion-image {
  margin-right: 2rem;
  width: 11rem;
}

.promotion-content {
  width: 28rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/login/promotions-item/promotions-item.component.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,iBAAiB;EACjB,qCAAqC;AACvC;;AAEA;EACE,yBAAyB;EACzB,oCAAoC;EACpC,iBAAiB;EACjB,iBAAiB;EACjB,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,iCAAiC;EACjC,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":["a {\n  color: rgb(66, 150, 247) !important;\n  font-size: 0.85rem;\n  font-weight: bold;\n  text-decoration: underline !important;\n}\n\nh2 {\n  color: rgb(138, 149, 157);\n  font-family: \"Source Sans Pro\", sans;\n  font-size: 0.9rem;\n  font-weight: bold;\n  text-decoration: underline;\n  text-transform: uppercase;\n}\n\np {\n  color: rgb(48, 62, 78);\n  font-family: \"Roboto Slab\", serif;\n  font-size: 1.25rem;\n}\n\nsection {\n  display: flex;\n  flex-direction: row;\n}\n\n.promotion-image {\n  margin-right: 2rem;\n  width: 11rem;\n}\n\n.promotion-content {\n  width: 28rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
