
import { MasterService } from 'app/components/admin/masters/services/master.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../services/intakes.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { masterEnum } from '@shared/master-enum.enum';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { SectionStatus } from '../basic/review/section-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../services/http.service';
@Component({
  selector: 'app-re-design',
  templateUrl: './re-design.component.html',
  styleUrls: ['./re-design.component.css']
})
export class ReDesignComponent implements OnInit {
reDesignPurpose:any;
redesignForm: UntypedFormGroup;
@Input() intakeTypeId;
@Input() submenu;
existingData;
@Input() menuId;
@Output() continue = new EventEmitter<any>();
@Output() changeStatus= new EventEmitter<any>();   
data;
intakeId;
  constructor(
    private masterService:MasterService,
    private formBuilder: UntypedFormBuilder,
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    public dataService:DataService,
  ) { 
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
      }
    });
  }

  ngOnInit() {
    this.getRedesignPurpose(masterEnum.purpose);
    this.initializeForm();
    this.getRedesignIntake(this.intakeId);
  }

  getRedesignIntake(intakeId){
    this.intakesService.getRedesignIntake(intakeId).subscribe((res) => {
      if (res) {
        this.existingData = res;
        this.initializeForm(this.existingData);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getRedesignPurpose(id) {
    this.masterService.getMastersListById(id).subscribe(res => {
      if (res) {
        this.reDesignPurpose = res.masterList; 
      }
    },
      err => {
        //this.httpService.openErrorPopup(err.error.message);
      });
  }

  initializeForm(data?) {
    this.redesignForm = this.formBuilder.group({
      intakeId: this.intakeId,
      purpose: new UntypedFormControl(data ? data.purpose : null),
      isUpdateWebsiteContent: new UntypedFormControl(data ? data.isUpdateWebsiteContent : null),
      like: new UntypedFormControl(data ? data.like : null),
      dislike: new UntypedFormControl(data ? data.dislike : null)
    })
  }

  saveFormData(form, mode?) {
    let intakeData = this.intakesService.basicInfo['redesign'] = form.value;
    if(intakeData.purpose=='null'){
      intakeData.purpose=null;
    }
    this.intakesService.saveRedesignIntake(intakeData).subscribe(res => {
      if (res) {
        this.changeStatus.next();
        this.toastrService.success(this.dataService.successMsg, 'Success');
        this.intakesService.updateRequiredFieldsStatus(intakeData);
        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.submenu[0].id, this.intakeId);
        this.postIntakeSection(sectionStatus);
        if(mode == 'continue'){
          this.goToNext('parent');
        }
      }
  
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }
  
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });
  
      } else {
        this.httpService.openErrorPopup("Site doesn't exist");
      }
    })
  }
  
  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  goToNext(menu){
    this.continue.next(menu);
  }

}
