import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { IntakesService } from '../../../services/intakes.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-style-and-branding',
  templateUrl: './style-and-branding.component.html',
  styleUrls: ['./style-and-branding.component.css']
})
export class StyleAndBrandingComponent implements OnInit {
  styleAndBrandingForm: UntypedFormGroup;
  selectedSiteId;
  ageGroupData;
  ethnicityData;
  lifestyleData;
  productsData;
  bannerTypeData;
  paletteData;
  intakeId;
  selectedItem = {};
  @Input() data;
  @Input() selectedMenu;
  @Input() intakeTypeId;
  @Output() continue = new EventEmitter<object>();
  @Output() changeStatus = new EventEmitter<any>();
  @Output() back = new EventEmitter<object>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public modalService: NgbModal
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
      }
    });
    this.intakesService.progressBar.subscribe(obj => {
      if (obj != null) {
        console.log(obj.basicMenu);
        console.log(this.selectedMenu);
        _.forEach(obj.basicMenu, (menu) => {
          if (this.selectedMenu && menu.id == this.selectedMenu.id) {
            this.selectedMenu.submenu = menu.submenu;
          }
        })
      }
    });
  }

  openPage(item?) {
    this.selectedItem = item ? item : {id: 0};
  }

  ngOnInit() {
    if (this.selectedMenu.selectedSectionId) {
      this.selectedItem = _.find(this.selectedMenu.submenu, (item) => { return item.id == this.selectedMenu.selectedSectionId });
    }
  }

  goBack(menu?) {
    if (menu == 'parent') {
      this.back.emit();
    } else {
      let selectedItem = this.selectedItem;

      if (selectedItem['id'] !== 19) {
        let index = _.findIndex(this.selectedMenu.submenu, function (menu) {
          return menu.id == selectedItem['id'];
        });
        this.selectedItem = this.selectedMenu.submenu[index - 1];
      } else {
        this.selectedItem = {};
      }
      window.scroll(0,0);
    }
  }

  goToNext(menu?) {
    if (menu == 'parent') {
      this.continue.emit();
    } else {
      let selectedItem = this.selectedItem;
      let index = _.findIndex(this.selectedMenu.submenu, function (menu) {
        return menu.id == selectedItem['id'];
      });
      this.selectedItem = this.selectedMenu.submenu[index + 1];
      window.scroll(0,0);
    }
  }
  changeIntakeStatus() {
    this.changeStatus.next();
  }
}
