import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ReportingService } from '../services/reporting.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { HttpService } from '@services/http.service';
import { ExcelService } from '../services/excel.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageSampleComponent } from '../../customer/intakes/add-intakes-data/basic/pages/page-sample/page-sample.component';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';

@Component({
  selector: 'app-contact-form-report',
  templateUrl: './contact-form-report.component.html',
  styleUrls: ['./contact-form-report.component.css'],
  providers: [DatePipe],
})
export class ContactFormReportComponent implements OnInit, PageHasTable {
  @Input() reportParams;
  @Input() siteId;

  @Output() goToContacts = new EventEmitter<any>();

  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  columnHeadersArray: Array<ColumnHeader[]>;
  reportData;

  constructor(
    private datePipe: DatePipe,
    private reportingService: ReportingService,
    private httpService: HttpService,
    private excelService: ExcelService,
    private toastrService: ToastrService,
    public modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.getReport(this.siteId, this.reportParams);
  }

  backToContacts() {
    this.goToContacts.emit({data:false,params:this.reportParams});
  }

  getReport(siteId, params) {
    // TODO: This comes in as an array, so change this and the typehinting to match
    this.reportData = {};
    const body = {};

    body['startDate'] = this.datePipe.transform(params.startDate, 'yyyy-MM-dd');
    body['endDate'] = this.datePipe.transform(params.endDate, 'yyyy-MM-dd');
    body['site_id'] = siteId;

    const emptyVal = _.pickBy(body, function (value, key) {
      return _.isEmpty(body[key]) && _.isNull(body[key]);
    });

    if (_.isEmpty(emptyVal)) {
      this.reportingService.getContactFormReport(body).subscribe(
        (res) => {
          if (res) {
            this.reportData = res;

            this.manipulateReportData(this.reportData);
          }
        },
        (err) => {
          this.httpService.openErrorPopup(err.error.message);
        },
        () => {
          this.getTableData();
        });
    } else {
      this.toastrService.error('Please fill all required fields', 'Error');
    }
  }

  /**
   * Formats an array into an object that can be used as table headers for the table component
   * @param {Array} titleArray The column title data that is a part of the `reportData`
   *
   * @return {ColumnHeader[]} An object that can be used for headers in the table component
   */
  formatTitlesAsHeaderArray(titleArray: Array<string>): ColumnHeader[] {
    const columnHeaders = [];

    for (let i = 0; i < titleArray.length; i++) {
      const headerObject: ColumnHeader = {field: titleArray[i], header: titleArray[i]};

      // Width Overrides
      switch (titleArray[i].toLowerCase()) {
        case 'date': headerObject.width = 7; break;
        case 'full-name': headerObject.width = 8; break;
        case 'phone': headerObject.width = 7; break;
      }

      columnHeaders.push(headerObject);
    }

    return columnHeaders;
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {};
  }

  manipulateReportData(res) {
    let tblData = {};

    _.forEach(res, (form) => {
      tblData['title'] = [];
      tblData['values'] = [];
      _.forEach(form.data, (dataItem) => {
        let value = {};
        _.forEach(dataItem, (item) => {
          if(item.key.toLowerCase()=='date')
            tblData['title'].splice(0,0,"Date");
          else
            tblData['title'].push(item.key);
          tblData['title'] = _.uniq(tblData['title']);
          value[item.key] = tblData['title'].indexOf(item.key) >= 0 ? item.value : '';
        })
        tblData['values'].push(value);
      })
      // Gives the form new properties and sets them to the arrays we manipulated.
      // Sets the titles and values of the table for the form.
      form['title'] = _.cloneDeep(tblData['title']);
      form['values'] = _.cloneDeep(tblData['values']);
    })
  }

  downloadReport() {
    let modifiedData = []
    _.forEach(this.reportData, (form) => {
      form['dataToDownload'] = [];
      let abc = {};
      _.forEach(form.values, (value) => {
        _.forEach(form.title, (title) => {
          abc[title] = value[title] || '';
        })
        form['dataToDownload'].push(abc);
      })
    })

    document.getElementById("downloadButton").style.display = 'none';
    var element = '<div style="padding: 55px;"></div>';
    var opt = {
      margin: 0,
      filename: 'contact-form.pdf',
      jsPDF: { unit: 'in', format: 'b3', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };
    var output = 'save';
    var worker = html2pdf().from(element).set(opt);
    worker.toPdf().get('pdf').then((pdf) => {
      _.forEach(this.reportData, (form) => {
        let values = [];
        let gscTableData = form.dataToDownload;
        let titles = form.title;
        _.forEach(gscTableData, (data) => {
          let value = _.values(data);
          values.push(value);
        })
        pdf.autoTable({
          headStyles: { fillColor: null, textColor: [0, 0, 0] },
          head: [titles],
          body:
            values,
          columnStyles: { 1: { minCellWidth: .75 }, 2: { minCellWidth: 1 }, 3: { minCellWidth: .75 }, 4: { minCellWidth: .75 } },
          startY: pdf.autoTableEndPosY() + 0.5

        });
      })
      document.getElementById("downloadButton").style.display = 'block';

    }).output(output, 'contact-form.pdf');
  }

  onValueChange(event) {
    this.reportParams['startDate'] = moment(event[0]).format('MM/DD/YYYY');
    this.reportParams['endDate'] = moment(event[1]).format('MM/DD/YYYY');
    this.reportParams['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.reportParams['_endDate'] = moment(event[1]).format('MM/DD/YY');
    this.getReport(this.siteId, this.reportParams);
  }

  exportToCsv(formId) {
    let dummyreportData=_.cloneDeep(this.reportData);
    let exelData = [];
    _.forEach(dummyreportData, (form) => {
      if(form.formId==formId){
        form.title.splice(0,0,"Form Type");
      let arrObj = form.title.reduce((a,b)=> (a[b]='',a),{});
      for (let key in arrObj){
        arrObj[key] = key;
      }
      exelData.push(form.title.reduce((a,b)=> (a[b]='',a),{}), arrObj);
      _.forEach(form.values, (val) => {
          let data = _.pick(val, form.title);
          data['Form Type']=form.formTitle;
          exelData.push(data);
        })
      }  })
      exelData.splice(0,1);
    this.excelService.exportAsExcelFile(exelData, 'ContactForm');

  }
  openPageContent(title,content) {
    let model = this.modalService.open(PageSampleComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title', windowClass: 'pop-height' });
    let data = {
      pageName: title,
      content: content,
      title: '',
    };
    model.componentInstance.data = data;

  }

}
