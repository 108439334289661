import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})
export class AssetsComponent implements OnInit {
assets = [];
source;
baseUrl = environment.imgUrl;
selectedAssets = [];
title='';
  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    console.log(this.assets,'==assets data')
  }

  public getSantizeUrl(url : string) {
    let finalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl+'/'+url); 
    return finalUrl;
  }

  deleteAssets(type?){
  }

  selectFile(selectedFile, type) {
    if(type == 'delete'){
      selectedFile.isFileSelected = true;
    }
    if (selectedFile.isFileSelected) {
      this.selectedAssets.push(selectedFile.path);
    } else {
      this.selectedAssets = _.filter(this.selectedAssets, (file) => {
        return file != selectedFile.path;
      })
    }
    if(type == 'delete'){
      this.deleteAssets(type);
      this.selectedAssets = [];
    }
  }

  dismissModal(){
    this.activeModal.dismiss();
  }

}
