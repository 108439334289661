import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { DataService } from '@shared/data-service.service';
import { IntakesService } from '../../../app/components/customer/intakes/services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { SectionStatus } from '../../components/customer/intakes/add-intakes-data/basic/review/section-model';
import * as _ from 'lodash';
import { HttpService } from '../../services/http.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PageSampleComponent } from '../../components/customer/intakes/add-intakes-data/basic/pages/page-sample/page-sample.component';
import { NodeItem, TreeCallbacks, TreeMode, TreeNgxComponent } from '../../modules/tree-ngx';


@Component({
  selector: 'app-content-tree-node',
  templateUrl: './content-tree-node.component.html',
  styleUrls: ['./content-tree-node.component.css']
})
export class ContentTreeNodeComponent implements OnInit {

  @ViewChild('contentTreeRef') contentTreeRef: TreeNgxComponent; 
  @Input('data') items: Array<Object>;
  @Input('searchTerm') searchTerm:string;
  @Output() messageEvent = new EventEmitter<boolean>();
  @Output() getSavedPages = new EventEmitter<boolean>();

  message: boolean = false;
  selectedContentMenu;
  intakeId:number=null;
  @Input() selectedItem;
  @Input() contentMenu;
  @Input() intakeTypeId;
  @Input() siteId;
  public checkedPageList= [];
  public treeCallbacks: TreeCallbacks;
  options = {
    checkboxes: false,
    alwaysEmitSelected: false
  }

  constructor(
    private intakesService: IntakesService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService, 
    public modalService: NgbModal,
    private dataService: DataService,
    private httpService: HttpService,
    private renderer2: Renderer2) { 
      this.activatedRoute.params.subscribe(params => {
        if (params.id) {
          this.intakeId = params.id;
        }
      }); 
    }
 ngOnInit() { 
  this.selectedContentMenu = _.find(this.contentMenu, (menu) => { return menu.name === this.selectedItem.name });
    this.treeCallbacks = {
      select: this.onSelect,
      unSelect: this.onUnselect
    };
   
  }
  private onSelect(item: NodeItem<any>) {
    
  }

  private onUnselect(item: NodeItem<any>) {
  
  }
  expandAll() {
    this.contentTreeRef.expandAll();
  };
  collapseAll() {
    this.contentTreeRef.collapseAll()
  };

  editPage(pages){
    this.dataService.setData(pages);
    this.messageEvent.emit(this.message);
  }
  
  deletePage(pages){
    let msg = 'Are you sure to delete this page?';
    if (confirm(msg)) {
      this.deleteInkateSavedPage( this.intakeId,pages.id,pages.parent, this.selectedItem.id);
    } 
  }
  deleteInkateSavedPage(intakeId,pageId,parentId, productSectionId){
    this.intakesService.deleteIntakeSavedPages(intakeId,pageId,parentId,productSectionId).subscribe((res:any) => {
      if (res) {
        this.toastrService.success('Deleted Successfully', 'Success');
        this.getSavedPages.emit(); // calling parnet method
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
    
  }
  getSampleData(page) {
    this.intakesService.getSampleData(page.id).subscribe((res) => {
      this.openSamplePopup(res);
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    })
  }
  openSamplePopup(pageData) {
    let model = this.modalService.open(PageSampleComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size: 'lg'});
    let data = {
      pageName: pageData.name,
      content: pageData.content,
      title: ''
    };
    model.componentInstance.data = data;

  }

}
