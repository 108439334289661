import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.css'],
})
export class SearchButtonComponent implements OnInit {
  @Input() cssClasses = '';
  @Input() disabled = false;
  @Input() label = 'Search';

  @Output() clickFunction = new EventEmitter<object>();

  constructor() { }

  ngOnInit(): void {
  }
}
