import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.css'],
})
export class InputGroupComponent implements OnInit {
  @Input() heading: string;

  constructor() { }

  ngOnInit(): void {
  }
}
