import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import { CorporatePartner } from '@shared/interfaces/client';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private httpService: HttpService) { }

  /**
   * Returns a list of Corporate Partners, and if a client ID is provided, it
   * presents only options applicable to that client.
   *
   * @return {Observable}
   */
  queryCorporatePartners(): Observable<CorporatePartner[]> {
    return this.httpService.getApi(`user/corporatePartner`);
  }
}
