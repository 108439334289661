import * as _ from 'lodash';
import { IntakesService } from '../../../services/intakes.service';


export class SectionStatus {
    intakeId: number;
    lstIntakeSection: object;


    constructor(info, sectionId, intakeId) {
        this.intakeId = intakeId;
        this.lstIntakeSection = this.getIntakeSectionData(info, sectionId);
    }

    getIntakeSectionData(info, sectionId){
        let list = [];
        // for(let key in info){
            _.forEach(info, (menu) => {
                _.forEach(menu.submenu, (data) => {
                    if(data.id == sectionId){
                        list.push({
                            productSectionId: data.id,
                            status: this.getPercentage(data),
                            percentage: data.updatedPercentage
                        })
                    }
                
            })
            })
        // }
        
        return list;

    }

    getPercentage(data){
        let reqItem = _.find(data.requiredFields, (item) => {
            return item.status == false;
        })
        return reqItem ? 1 : 2;
    }



}

