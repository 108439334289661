// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen {
  section {
    display: flex;
  }

  .address-grid-filter {
    height: 4rem;
  }

  .address-grid-items {
    min-height: 21rem;
  }

  .no-results {
    margin-top: 1rem;
    padding-top: 1rem;
  }

  .row {
    margin: unset;
    padding-top: 1rem;
    width: 100%;
  }

  .section-address-form, .section-add-form-button, .section-address-grid {
    align-items: flex-start;
    align-content: flex-start;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 2rem;
    width: 100%;
  }
}

`, "",{"version":3,"sources":["webpack://./src/app/components/billing/manage-locations/manage-locations.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,WAAW;EACb;AACF","sourcesContent":["@media screen {\n  section {\n    display: flex;\n  }\n\n  .address-grid-filter {\n    height: 4rem;\n  }\n\n  .address-grid-items {\n    min-height: 21rem;\n  }\n\n  .no-results {\n    margin-top: 1rem;\n    padding-top: 1rem;\n  }\n\n  .row {\n    margin: unset;\n    padding-top: 1rem;\n    width: 100%;\n  }\n\n  .section-address-form, .section-add-form-button, .section-address-grid {\n    align-items: flex-start;\n    align-content: flex-start;\n    flex-flow: row wrap;\n    justify-content: space-between;\n    padding: 2rem;\n    width: 100%;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
