// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviewing-content img{max-width:100%;}
.reviewing-content .success-cont-box{background:#f8f8f8; color:#4d4e56; max-width:35%; margin-right:20px; padding:25px; min-height:530px;}
.reviewing-content .success-cont-box h4{font-size:18px; font-weight:700; text-transform:uppercase; line-height:18px; margin-bottom:40px;}
.reviewing-content .success-cont-box p{margin-bottom:15px; font-size:15px; line-height:20px;}
.reviewing-content .success-cont-box h3{font-size:22px; font-weight:700; line-height:32px; margin-bottom:40px;}
.reviewing-content .success-images{flex-grow:1; background:#00a99b;}
.reviewing-content .success-images img{max-width:100%;}
.reviewing-content .bol-text{font-weight:600; line-height:20px; color:#43425d;}
.reviewing-content .bol-text a.link{text-decoration:underline; color:#3597ec; text-decoration:underline;}
.reviewing-content .bol-text a.link:hover{text-decoration:none;}`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/intake-review/intake-review.component.css"],"names":[],"mappings":"AAAA,uBAAuB,cAAc,CAAC;AACtC,qCAAqC,kBAAkB,EAAE,aAAa,EAAE,aAAa,EAAE,iBAAiB,EAAE,YAAY,EAAE,gBAAgB,CAAC;AACzI,wCAAwC,cAAc,EAAE,eAAe,EAAE,wBAAwB,EAAE,gBAAgB,EAAE,kBAAkB,CAAC;AACxI,uCAAuC,kBAAkB,EAAE,cAAc,EAAE,gBAAgB,CAAC;AAC5F,wCAAwC,cAAc,EAAE,eAAe,EAAE,gBAAgB,EAAE,kBAAkB,CAAC;AAC9G,mCAAmC,WAAW,EAAE,kBAAkB,CAAC;AACnE,uCAAuC,cAAc,CAAC;AACtD,6BAA6B,eAAe,EAAE,gBAAgB,EAAE,aAAa,CAAC;AAC9E,oCAAoC,yBAAyB,EAAE,aAAa,EAAE,yBAAyB,CAAC;AACxG,0CAA0C,oBAAoB,CAAC","sourcesContent":[".reviewing-content img{max-width:100%;}\n.reviewing-content .success-cont-box{background:#f8f8f8; color:#4d4e56; max-width:35%; margin-right:20px; padding:25px; min-height:530px;}\n.reviewing-content .success-cont-box h4{font-size:18px; font-weight:700; text-transform:uppercase; line-height:18px; margin-bottom:40px;}\n.reviewing-content .success-cont-box p{margin-bottom:15px; font-size:15px; line-height:20px;}\n.reviewing-content .success-cont-box h3{font-size:22px; font-weight:700; line-height:32px; margin-bottom:40px;}\n.reviewing-content .success-images{flex-grow:1; background:#00a99b;}\n.reviewing-content .success-images img{max-width:100%;}\n.reviewing-content .bol-text{font-weight:600; line-height:20px; color:#43425d;}\n.reviewing-content .bol-text a.link{text-decoration:underline; color:#3597ec; text-decoration:underline;}\n.reviewing-content .bol-text a.link:hover{text-decoration:none;}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
