import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { HttpService } from '@services/http.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DownloadsService {

  constructor(
    private httpService: HttpService
  ) { }

  getGmbAccountsData(): Observable<any> {
    return this.httpService.getApi(`GoogleMyBusiness/Accounts`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getGmbLocationsData(accountId): Observable<any> {
    return this.httpService.getApi(`GoogleMyBusiness/Locations/${accountId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getSendibleAccountsData(): Observable<any> {
    return this.httpService.getApi(`SocialMediaMarketing/Accounts`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getExportData(type): Observable<any> {
    return this.httpService.getApi(`site/Export/${type}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getReportConfig(type): Observable<any> {
    return this.httpService.getApi(`site/ReportConfig/${type}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getCSVReport(startDate: string, endDate: string, familyType:string): Observable<any> {
    return this.httpService.getApi(`report/getCSVReport?startDate=${startDate}&endDate=${endDate}&familyType=${familyType}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getGeneralMasterByType(type): Observable<any> {
    return this.httpService.getApi(`master/General/${type}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
}
