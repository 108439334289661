import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intake-sidebar-status-group',
  templateUrl: './intake-sidebar-status-group.component.html',
  styleUrls: ['./intake-sidebar-status-group.component.css'],
})
export class IntakeSidebarStatusGroupComponent implements OnInit {
  statuses: Array<{ label: string, color: string}> = [
    { label: 'Completed', color: 'green' },
    { label: 'Not Completed', color: 'orange' },
    { label: 'Active', color: 'blue' },
    { label: 'Not Started', color: 'grey' }
  ]

  constructor() { }

  ngOnInit(): void {
  }
}
