// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host /deep/ .ss_val{
    width: 50px;
    height: 24px;
    border: 1px solid #bbb;
    position: relative;
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/basic/premium/premium.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[":host /deep/ .ss_val{\n    width: 50px;\n    height: 24px;\n    border: 1px solid #bbb;\n    position: relative;\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
