import { Component, Input, OnInit } from '@angular/core';
import { Promotion } from '@shared/interfaces/login';

@Component({
  selector: 'app-promotions-item',
  templateUrl: './promotions-item.component.html',
  styleUrls: ['./promotions-item.component.css'],
})
export class PromotionsItemComponent implements OnInit {
  @Input() promotion: Promotion;

  constructor() { }

  ngOnInit(): void {
  }

  get imageURL(): string {
    return `assets/images/promotions/${this.promotion.icon}.svg`;
  }
}
