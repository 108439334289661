// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[role="radio"] {
  display: none;
}

div.selected {
  color: rgb(255, 255, 255);
  background-color: rgb(53, 151, 236);
  border-color: rgb(53, 151, 236);
}

div.selected > label {
  color: rgb(255, 255, 255);
}

div.selected img {
  filter: brightness(0) invert(1);
}

img {
  width: 0.75rem
}

.chip-container {
  align-items: center;
  border: 2px solid rgb(186, 186, 186);
  border-radius: 27px;
  display: inline-flex;
  height: 2rem;
  padding: 0 1.5rem 0 1rem;
}

.chip-container, .chip-container * {
  cursor: pointer;
}

.chip-container:hover {
  box-shadow: 0 0 0.25rem rgb(59, 134, 255);
}

.chip-container > label {
  margin: 0;
  display: flex;
  gap: 0.45rem;
  flex-direction: row;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/forms/checkbox-chip/checkbox-chip.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,mCAAmC;EACnC,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE;AACF;;AAEA;EACE,mBAAmB;EACnB,oCAAoC;EACpC,mBAAmB;EACnB,oBAAoB;EACpB,YAAY;EACZ,wBAAwB;AAC1B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,SAAS;EACT,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":["div[role=\"radio\"] {\n  display: none;\n}\n\ndiv.selected {\n  color: rgb(255, 255, 255);\n  background-color: rgb(53, 151, 236);\n  border-color: rgb(53, 151, 236);\n}\n\ndiv.selected > label {\n  color: rgb(255, 255, 255);\n}\n\ndiv.selected img {\n  filter: brightness(0) invert(1);\n}\n\nimg {\n  width: 0.75rem\n}\n\n.chip-container {\n  align-items: center;\n  border: 2px solid rgb(186, 186, 186);\n  border-radius: 27px;\n  display: inline-flex;\n  height: 2rem;\n  padding: 0 1.5rem 0 1rem;\n}\n\n.chip-container, .chip-container * {\n  cursor: pointer;\n}\n\n.chip-container:hover {\n  box-shadow: 0 0 0.25rem rgb(59, 134, 255);\n}\n\n.chip-container > label {\n  margin: 0;\n  display: flex;\n  gap: 0.45rem;\n  flex-direction: row;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
