// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `canvas.chartjs-render-monitor {
  max-height: 400px;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/charts/area-chart/area-chart.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":["canvas.chartjs-render-monitor {\n  max-height: 400px;\n  max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
