import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { AccordionComponent } from './accordion/accordion.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ConvertToHoursPipe } from '@pipes/secondsToHours.pipe';
import { FormatNumberPipe, NameAbbrPipe, WordCountPipe } from '@pipes/formatNumber.pipe';
import { AudChartsModule } from './charts/charts.module';
import { CancelReasonComponent } from './cancel-reason/cancel-reason.component';
import { ShowMessageComponent } from './show-message/show-message.component';
import { SortByKeyPipe } from '@shared/pipes/sort-by-key.pipe';
import { ConsolePipe } from '@shared/pipes/console.pipe';
import { PanelFrameComponent } from './components/panel-frame/panel-frame.component';
import { ContactPanelComponent } from './components/contact-panel/contact-panel.component';
import { LinkComponent } from './components/buttons/link/link.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AvatarCircleComponent } from './components/users/avatar-circle/avatar-circle.component';
import { ModalFrameComponent } from './components/frames/modal-frame/modal-frame.component';
import { ModalFrameCloseButtonComponent } from './components/frames/modal-frame-close-button/modal-frame-close-button.component';
import {
  SelectComponent,
  SelectCountryComponent,
  SelectOfficeHours,
  SelectStateComponent,
} from './components/forms/select/select.component';
import { RadioComponent } from './components/forms/radio/radio.component';
import { RadioGroupComponent } from './components/forms/radio-group/radio-group.component';
import { DatepickerComponent } from './components/forms/datepicker/datepicker.component';
import { ResetButtonComponent } from './components/buttons/reset-button/reset-button.component';
import { ExportButtonComponent } from './components/buttons/export-button/export-button.component';
import { ReportsHeadingComponent } from './components/headings/reports-heading/reports-heading.component';
import { NaPipe } from '@pipes/na.pipe';
import { EqualsValidDirective } from '@shared/directives/structural/equals-valid.directive';
import { ButtonDropdownComponent } from './components/buttons/button-dropdown/button-dropdown.component';
import { ButtonDropdownItemComponent } from './components/buttons/button-dropdown-item/button-dropdown-item.component';
import {
  AddButtonComponent,
  AddLocationButtonComponent,
} from './components/buttons/add-button/add-button.component';
import { RouterModule } from '@angular/router';
import { ExportToCsvComponent } from './components/buttons/export-to-csv/export-to-csv.component';
import { SecondaryButtonComponent } from './components/buttons/secondary-button/secondary-button.component';
import { LogoFullComponent } from './components/logos/logo-full/logo-full.component';
import { ToCssClassPipe } from '@pipes/to-css-class/to-css-class.pipe';
import { ProgressCircleComponent } from './components/graphs/progress-circle/progress-circle.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ProgressBarComponent } from './components/graphs/progress-bar/progress-bar.component';
import { CircleSmComponent } from './components/shapes/circle-sm/circle-sm.component';
import { DotBlueComponent } from './components/shapes/dot-blue/dot-blue.component';
import { PartnershipLogosComponent } from './components/logos/partnership-logos/partnership-logos.component';
import { ToIdStringPipe } from '@pipes/to-id-string/to-id-string.pipe';
import { OverviewFrameComponent } from './components/frames/overview-frame/overview-frame.component';
import { StatisticBlockComponent } from './components/information-panels/statistic-block/statistic-block.component';
import { AddressGridItemComponent } from '@shared/components/address-grid-item/address-grid-item.component';
import { YnPipe } from '@pipes/yn/yn.pipe';
import { FilterComponent } from './components/inputs/filter/filter.component';
import {
  InputComponent,
  InputPhoneComponent,
  InputZipcodeComponent,
} from './components/forms/input/input.component';
import { InputNumberComponent } from './components/forms/input-number/input-number.component';
import { RadioSquaredComponent } from './components/forms/radio-squared/radio-squared.component';
import {
  ButtonBasicComponent,
  ButtonIntakeBackComponent,
  ButtonIntakeContinueComponent,
} from '@shared/components/buttons/button-basic/button-basic.component';
import { ButtonSubmitComponent } from '@shared/components/buttons/button-submit/button-submit.component';
import {
  TableGenericComponent,
} from '@shared/components/tables/table-generic/table-generic.component';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxSquaredComponent } from './components/forms/checkbox-squared/checkbox-squared.component';
import { PhonePipe } from '@pipes/phone/phone.pipe';
import { CheckmarkPipe } from '@shared/pipes/checkmark/checkmark.pipe';
import { IconErrorNoticeComponent } from './alerts/icon-error-notice/icon-error-notice.component';
import { CheckmarkClickablePipe } from '@pipes/checkmark-clickable/checkmark-clickable.pipe';
import { BinaryPipe } from '@pipes/binary/binary.pipe';
import { CalendarModule } from 'primeng/calendar';
import { TimePipe } from '@pipes/time/time.pipe';
import { PlayStopButtonComponent } from './components/buttons/play-stop-button/play-stop-button.component';
import { TabComponent } from './components/tab/tab.component';
import { SearchButtonComponent } from './components/buttons/search-button/search-button.component';
import { GlyphGenericComponent } from './components/glyphs/glyph-generic/glyph-generic.component';
import { GlyphsClientActionsComponent } from './components/glyphs/glyphs-client-actions/glyphs-client-actions.component';
import { PaginatorComponent } from './components/tables/paginator/paginator.component';
import { PaginatorModule } from 'primeng/paginator';
import { CheckmarkOrCrossPipe } from '@pipes/checkmark-or-cross/checkmark-or-cross.pipe';
import { HeaderBlockComponent } from './components/headings/header-block/header-block.component';
import { ButtonContainerComponent } from './components/buttons/button-container/button-container.component';
import { SemrushIconsComponent } from './components/glyphs/semrush-icons/semrush-icons/semrush-icons.component';
import { NavTabComponent } from './components/navigation/nav-tab/nav-tab/nav-tab.component';
import { NavTabListComponent } from './components/navigation/nav-tab/nav-tab-list/nav-tab-list.component';
import { NestedActionIconsDirective } from '@shared/components/tables/table-generic/table-generic.directives';
import { ButtonSpacerComponent } from './components/buttons/button-spacer/button-spacer.component';
import { CheckboxChipComponent } from './components/forms/checkbox-chip/checkbox-chip.component';
import { ChipGroupComponent } from './components/forms/chip-group/chip-group.component';
import { InputGroupComponent } from './components/forms/input-group/input-group.component';
import { InputOfficeHoursComponent } from './components/forms/input-office-hours/input-office-hours.component';
import { InputOfficeHoursWeekComponent } from './components/forms/input-office-hours-week/input-office-hours-week.component';
import { CheckboxGroupComponent } from './components/forms/checkbox-group/checkbox-group.component';
import { InputDateComponent } from './components/forms/input-date/input-date.component';
import { FormControlPipe } from '@pipes/form-control/form-control.pipe';
import { SplitPipe } from '@pipes/split/split.pipe';
import { InvalidAddressModalComponent } from './invalid-address-modal/invalid-address-modal.component';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
    imports: [
        AudChartsModule,
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        NgCircleProgressModule,
        NgbModule,
        ReactiveFormsModule,
        RouterModule,
        TableModule,
        MultiSelectModule,
        InputTextModule,
        DropdownModule,
        CalendarModule,
        PaginatorModule,
    ],
    declarations: [
        AccordionComponent,
        AlertModalComponent,
        CancelReasonComponent,
        ConfirmModalComponent,
        ConsolePipe,
        ConvertToHoursPipe,
        EqualsValidDirective,
        FileUploadComponent,
        FormatNumberPipe,
        NameAbbrPipe,
        ShowMessageComponent,
        SortByKeyPipe,
        WordCountPipe,
        PanelFrameComponent,
        ContactPanelComponent,
        LinkComponent,
        AvatarCircleComponent,
        ModalFrameComponent,
        ModalFrameCloseButtonComponent,
        SelectComponent,
        RadioComponent,
        RadioGroupComponent,
        DatepickerComponent,
        ResetButtonComponent,
        ExportButtonComponent,
        ReportsHeadingComponent,
        NaPipe,
        ButtonDropdownComponent,
        ButtonDropdownItemComponent,
        AddButtonComponent,
        ExportToCsvComponent,
        SecondaryButtonComponent,
        ToCssClassPipe,
        ProgressCircleComponent,
        ProgressBarComponent,
        CircleSmComponent,
        DotBlueComponent,
        LogoFullComponent,
        OverviewFrameComponent,
        PartnershipLogosComponent,
        StatisticBlockComponent,
        ToIdStringPipe,
        AddressGridItemComponent,
        YnPipe,
        FilterComponent,
        InputComponent,
        InputPhoneComponent,
        InputNumberComponent,
        InputZipcodeComponent,
        RadioSquaredComponent,
        ButtonBasicComponent,
        ButtonIntakeBackComponent,
        ButtonIntakeContinueComponent,
        ButtonSubmitComponent,
        TableGenericComponent,
        CheckboxSquaredComponent,
        PhonePipe,
        CheckmarkPipe,
        SecondaryButtonComponent,
        IconErrorNoticeComponent,
        CheckmarkClickablePipe,
        BinaryPipe,
        TimePipe,
        PlayStopButtonComponent,
        TabComponent,
        SearchButtonComponent,
        GlyphGenericComponent,
        GlyphsClientActionsComponent,
        PaginatorComponent,
        CheckmarkOrCrossPipe,
        HeaderBlockComponent,
        ButtonContainerComponent,
        SemrushIconsComponent,
        NavTabComponent,
        NavTabListComponent,
        NestedActionIconsDirective,
        ButtonSpacerComponent,
        CheckboxChipComponent,
        ChipGroupComponent,
        InputGroupComponent,
        SelectCountryComponent,
        SelectStateComponent,
        SelectOfficeHours,
        AddButtonComponent,
        AddLocationButtonComponent,
        InputOfficeHoursComponent,
        InputOfficeHoursWeekComponent,
        CheckboxGroupComponent,
        InputDateComponent,
        FormControlPipe,
        SplitPipe,
        InvalidAddressModalComponent,
    ],
    exports: [
        PhonePipe,
        AccordionComponent,
        ConsolePipe,
        ConvertToHoursPipe,
        FormatNumberPipe,
        NameAbbrPipe,
        SortByKeyPipe,
        WordCountPipe,
        PanelFrameComponent,
        ContactPanelComponent,
        AvatarCircleComponent,
        ModalFrameComponent,
        SelectComponent,
        RadioGroupComponent,
        RadioComponent,
        DatepickerComponent,
        ResetButtonComponent,
        ExportButtonComponent,
        ReportsHeadingComponent,
        NaPipe,
        EqualsValidDirective,
        ButtonDropdownComponent,
        ButtonDropdownItemComponent,
        AddButtonComponent,
        ExportToCsvComponent,
        ToCssClassPipe,
        ProgressCircleComponent,
        ProgressBarComponent,
        LogoFullComponent,
        CircleSmComponent,
        DotBlueComponent,
        OverviewFrameComponent,
        PartnershipLogosComponent,
        StatisticBlockComponent,
        ToIdStringPipe,
        AddressGridItemComponent,
        FilterComponent,
        InputNumberComponent,
        InputComponent,
        InputPhoneComponent,
        RadioSquaredComponent,
        ButtonBasicComponent,
        ButtonIntakeBackComponent,
        ButtonIntakeContinueComponent,
        ButtonSubmitComponent,
        TableGenericComponent,
        CheckboxSquaredComponent,
        SecondaryButtonComponent,
        TabComponent,
        SearchButtonComponent,
        GlyphGenericComponent,
        GlyphsClientActionsComponent,
        PaginatorComponent,
        HeaderBlockComponent,
        ButtonContainerComponent,
        SemrushIconsComponent,
        NavTabComponent,
        NavTabListComponent,
        NestedActionIconsDirective,
        ButtonSpacerComponent,
        CheckboxChipComponent,
        ChipGroupComponent,
        InputGroupComponent,
        SelectCountryComponent,
        SelectStateComponent,
        SelectOfficeHours,
        InputZipcodeComponent,
        AddButtonComponent,
        AddLocationButtonComponent,
        InputOfficeHoursComponent,
        InputOfficeHoursWeekComponent,
        CheckboxGroupComponent,
        InputDateComponent,
        FormControlPipe,
        SplitPipe,
        InvalidAddressModalComponent,
    ]
})
export class SharedModule {
}

