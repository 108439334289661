// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  margin-top: .35rem;
}

label + p {
  font-weight: bold;
  padding-left: 0.8rem;
}

.error {
  background: unset !important;
  color: rgb(220, 53, 69);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/billing/change-product/change-product.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,4BAA4B;EAC5B,uBAAuB;AACzB","sourcesContent":["label {\n  margin-top: .35rem;\n}\n\nlabel + p {\n  font-weight: bold;\n  padding-left: 0.8rem;\n}\n\n.error {\n  background: unset !important;\n  color: rgb(220, 53, 69);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
