import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService{
  
  private messageSource = new BehaviorSubject(true);
  currentMessage = this.messageSource.asObservable();

  private dataSource = new BehaviorSubject('');
  currentData = this.dataSource.asObservable();
  emailRegex = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // emailRegex = /^([\w-]+(?:.[\w-]+))@((?:[\w-]+.)\w[\w-]{0,66}).([a-z]{2,6}(?:.[a-z]{2,3})(\".+\")?)$/i;
  urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(HTTP:\/\/WWW\.|HTTPS:\/\/WWW\.|HTTP:\/\/|HTTPS:\/\/)?[A-Za-z0-9]+([\-\.]{1}[A-Za-z0-9]+)*\.[A-Za-z]{2,5}(:[0-9]{1,5})?(\/.*)?[ \s.]{0,500}$/;
  // urlRegex: string='(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  phoneRegex: string = '^[+]?[(]?[0-9]{3}[)]?[-\\s.]?[0-9]{3}[-\\s.]?[0-9]{4,6}$';
  numberRegex:string='^[0-9]*$';
  checkWhiteSpace: string = '^(?=.*[0-9A-Za-z_@./#&+\\-?*%]).+$';
  emailPatternMsg:string='Please enter a valid email';
  urlPatternMsg:string='Please enter a valid url';
  datePatternMsg:string='Please enter a valid date'
  phonePatternMsg:string='Please enter a valid phone number';
  successMsg:string='Data Saved Successfully';
  requiredMsg:string='Please fill the required field';
  numberMsg:string='Please enter numbers only';
  passwordCheckMsg:string='Password and confirm password should be same';
  userExistMsg:string='User name already exist';
  addUserMsg:string='User added successfully';
  updateUserMsg:string='User updated successfully';
  supportedFileFormate = ["jpg","jpeg","png","gif","svg","pdf", "zip", "rar","txt","docx"];
  maxxFileSize:number = 209715200; //10mb
  selectedSiteId: string;
  constructor() { }

  setData(data: any) {
    this.dataSource.next(data)
  }
  changeMessage(message: boolean) {
    this.messageSource.next(message)
  }
}
