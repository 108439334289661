// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intake-container {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2rem;
  justify-content: flex-start;
  padding: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/customer/intakes/add-intakes-data/basic/basic.component.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,SAAS;EACT,2BAA2B;EAC3B,eAAe;AACjB","sourcesContent":[".intake-container {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  gap: 2rem;\n  justify-content: flex-start;\n  padding: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
