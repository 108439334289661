import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy
} from '@angular/core';
import { MasterService } from "../../components/admin/masters/services/master.service";
import { UserService } from "../../components/admin/users/services/user-service.service";
import { SalesforceService } from "./service/salesfoceService";
import { MenuService } from "./service/menu.service";
import { from } from "rxjs";
import * as _ from "lodash";
import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { routes } from "../../components/pages.routing";
import { roles } from "@shared/master-enum.enum";
import { environment } from "environments/environment";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { ChangePasswordComponent } from "../../components/change-password/change-password.component";
import { IntakesService } from "../../components/customer/intakes/services/intakes.service";
import { ToastrService } from "ngx-toastr";
import { LoginService } from "../../components/login/services/login.service";
import { TicketService } from "../../components/ticket/service/ticket.service";
import { FileUploadService } from "@shared/services/file-upload.service";
import { HttpService } from '../../services/http.service';
import { ConfigurationService } from '../../components/admin/configuration/services/configuration.service';
import { MenuListItem } from '@shared/interfaces/interfaces';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit {
  allMasters;
  currentUser;
  currentUserFullName;
  roles = roles;
  // pageMenu = [];
  selectedProduct;
  parentMenu;
  menuList: MenuListItem[];
  intakeProduct;
  selectedSite = JSON.parse(localStorage.getItem("selectedSite"));
  ungroupedMenu;
  impersonateByUserId =
    localStorage.getItem("userInfo") != null
      ? JSON.parse(localStorage.getItem("userInfo")).impersonateByUserId
      : null;
  impersonateByUserName =
    localStorage.getItem("userInfo") != null
      ? JSON.parse(localStorage.getItem("userInfo")).userName
      : null;
  userInfo =
    localStorage.getItem("userInfo") != null
      ? JSON.parse(localStorage.getItem("userInfo"))
      : { token: "", userId: null, roleId: null };
  wpSSOUrl = environment.wpSSO;
  baseImgUrl = environment.imgUrl;
  wpSitePath;
  parentRole;
  currentPath;
  sites = [];
  private toggledByUser = false;

  constructor(
    private masterService: MasterService,
    private userService: UserService,
    private loginService: LoginService,
    private intakesService: IntakesService,
    private menuService: MenuService,
    public modalService: NgbModal,
    private salesforceService: SalesforceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private toastrService: ToastrService,
    private el: ElementRef,
    private httpService: HttpService,
    private ticketService: TicketService,
    private fileUploadService: FileUploadService,
    private configurationService: ConfigurationService
  ) {
    this.menuService.intakeProductFamily.subscribe(obj => {
      this.intakeProduct = obj ? obj["family"] : null;
      this.selectedProduct = obj ? obj["selectedProduct"] : null;
    });

    // this.activatedRoute.url.subscribe((urlPath) => {
    //   this.currentPath = urlPath[urlPath.length - 1].path;
    // })

    this.currentPath = _.find(roles, role => {
      return role.roleId == this.userInfo.roleId;
    });

    document.body.addEventListener('click', (event) => this.collapse(event));

    this.userService.userImagePathData.subscribe(data => {
      if (data) {
        this.getUserInfo(this.userInfo.userId);
      }
    });
  }

  navigateToBilling(): void {
    let currentRole = _.find(roles, role => {
      return role.roleId == this.userInfo.roleId;
    });
    if (currentRole) {
      this.router.navigate([`/${currentRole.url}/billing`], { queryParams: { fromSidebar: true } });
    } else {
      console.error('Current role not found!');
    }
  }

  collapse(event) {
    this.updateNavigation();
  }

  private updateNavigation(clickedElementId = '') {
    const element = document.getElementsByClassName("leftNav");
    const wrapper = document.getElementsByClassName("wrapper");
    const topBar = document.getElementsByClassName("top-bar");
    const expandedDdMenu = document.getElementsByClassName("collapseMenu");
  
    if (element.length > 0) {
      const cldList = element[0].classList;
  
      // Check if the classes are already present
      const wrapperMenuExpandedClassExists = wrapper[0].classList.contains("menuExpanded");
      const slideNavToggleClassExists = !cldList.contains("slide-nav-toggle");
  
      // Introduce a flag to determine if classes should be persisted
      const bigSideBar = wrapperMenuExpandedClassExists && slideNavToggleClassExists;
  
      // Check if the clicked element has one of the desired IDs
      const isDesiredMenu = clickedElementId === 'leftMenu10' ||
                            clickedElementId === 'leftMenu8' ||
                            clickedElementId === 'leftMenu6';
  
      if (isDesiredMenu) {
        cldList.remove("slide-nav-toggle");
        wrapper[0].classList.add("menuExpanded");
        topBar[0].classList.add("menuExpanded");
      } else if (this.toggledByUser) {
        if (bigSideBar) {
          cldList.add("slide-nav-toggle");
          wrapper[0].classList.remove("menuExpanded");
          topBar[0].classList.remove("menuExpanded");
        } else {
          cldList.remove("slide-nav-toggle");
          wrapper[0].classList.add("menuExpanded");
          topBar[0].classList.add("menuExpanded");
        }
        // Reset the flag
        this.toggledByUser = !this.toggledByUser;
  
        // Hide expanded dropdown menus
        _.forEach(expandedDdMenu, menu => {
          menu.classList.remove("show");
        });
      }
    }
  }  

  ngOnInit() {
    if (this.userInfo.siteIds) this.sites = this.userInfo.siteIds.split(",");
    this.getMasters();
    this.getUserInfo(this.userInfo.userId);
    // this.getParentRole();
    this.getMenuList();
    const storedSite = localStorage.getItem('selectedSite');
    if (storedSite) {
      this.selectedSite = JSON.parse(storedSite);
    } else {
      this.selectedSite = null; 
    }
  }
  goToSetup(data) {
    if (data == "Site Config") {
      this.intakesService.goToSetup = "";
    } else {
      this.intakesService.goToSetup = "users";
    }
    this.router.navigate([
      `${this.currentPath.url}/site-config/`,
      this.selectedSite.siteId
    ]);
  }
  clearData() {
    this.intakesService.goToSetup = "";
  }

  /**
   * Assigns all of the appropriate menu items to the a variable accessibe meant to be rendered
   * in the sidebar
   *
   * @return void
   */
  getMenuList(): void {
    this.menuService.getMenu(this.userInfo.roleId).subscribe(
      res => {
        if (res) {
          _.forEach(res, menuItem => {
            if (menuItem.parentId == '0') {
              const groupedMenu = _.groupBy(res, menu => {
                return menu.parentId === menuItem.id;
              });

              menuItem['submenu'] = groupedMenu.true;
            }
          });

          this.ungroupedMenu = res;

          this.updatePageMenu();

          this.menuList = _.filter(this.ungroupedMenu, response => {
            return !response.parentId;
          });

          console.log('Menu list:', this.menuList);

        }
      },
      err => {
        console.log(err.error.message);
      }
    );
  }
 
  
  updatePageMenu() {
    let currentRole = _.find(roles, role => {
      return role.roleId == this.userInfo.roleId;
    });
    let pageMenu = [];
    _.forEach(routes, route => {
      if (route.data && route.data.name == "page") {
        pageMenu = _.find(route.children, childRoute => {
          if (
            childRoute.data.expectedRole &&
            typeof childRoute.data.expectedRole == "object" &&
            childRoute.data.expectedRole.length
          ) {
            return _.find(childRoute.data.expectedRole, role => {
              return role == currentRole.roleId;
            });
          } else {
            return childRoute.data.expectedRole == currentRole.roleId;
          }
        });
      }
    });
    _.forEach(this.ungroupedMenu, menuItem => {
      let menu = _.find(pageMenu["children"], menu => {
        return menu.data.name == menuItem.name;
      });
      if (menuItem.url == "#") {
        menuItem.url = menu ? currentRole.url + "/" + menu.path : "dashboard";
      }
    });
  }

  connectFunc(menuName) {
    if (menuName == "WordPress") {
      this.logIntoWP();
    } else if (menuName == "JIRA") {
      this.loginJira();
    } else {
      this.logIntoSaleForce();
    }
    this.collapseMenu();
  }
  logIntoWP(): any {
    //this.renderer.appendChild(this.el.nativeElement, div);
    this.wpSSOUrl = this.wpSSOUrl.replace("sitePath", environment.wpRootPath);
    const wpForm = this.renderer.createElement("form");
    this.renderer.setAttribute(wpForm, "id", "wpForm");
    this.renderer.setAttribute(wpForm, "action", this.wpSSOUrl);
    this.renderer.setAttribute(wpForm, "target", "_blank");
    this.renderer.setAttribute(wpForm, "method", "post");

    const inputEmail = this.renderer.createElement("input");
    this.renderer.setAttribute(inputEmail, "type", "text");
    this.renderer.setAttribute(inputEmail, "name", "email");
    this.renderer.setAttribute(inputEmail, "value", this.currentUser.email);
    const inputToken = this.renderer.createElement("input");
    this.renderer.setAttribute(inputToken, "type", "text");
    this.renderer.setAttribute(inputToken, "name", "token");
    this.renderer.setAttribute(inputToken, "value", this.userInfo.token);
    this.renderer.appendChild(wpForm, inputEmail);
    this.renderer.appendChild(wpForm, inputToken);
    this.renderer.setStyle(wpForm, "visibility", "hidden");
    document.body.appendChild(wpForm);
    //this.renderer.appendChild(this.el.nativeElement, wpForm);
    wpForm.submit();
    document.getElementById("wpForm").remove();
  }
  logIntoSaleForce() {
    var body = this.el.nativeElement;
    this.salesforceService.getAssertion(this.userInfo.userId).subscribe(
      res => {
        if (res) {
          let data = {
            SAMLResponse: res.samlAssertion,
            RelayState: res.startUrl
          };
          const sfForm = this.renderer.createElement("form");
          this.renderer.setAttribute(sfForm, "id", "sfForm");
          this.renderer.setAttribute(sfForm, "action", res.loginUrl);
          this.renderer.setAttribute(sfForm, "target", "_blank");
          this.renderer.setAttribute(sfForm, "method", "post");

          const inputSamlAssertion = this.renderer.createElement("input");
          this.renderer.setAttribute(inputSamlAssertion, "type", "text");
          this.renderer.setAttribute(
            inputSamlAssertion,
            "name",
            "SAMLResponse"
          );
          this.renderer.setAttribute(
            inputSamlAssertion,
            "value",
            res.samlAssertion
          );
          const inputRelayState = this.renderer.createElement("input");
          this.renderer.setAttribute(inputRelayState, "type", "text");
          this.renderer.setAttribute(inputRelayState, "name", "token");
          this.renderer.setAttribute(inputRelayState, "value", res.StartUrl);
          this.renderer.appendChild(sfForm, inputSamlAssertion);
          this.renderer.appendChild(sfForm, inputRelayState);
          this.renderer.setStyle(sfForm, "visibility", "hidden");
          document.body.appendChild(sfForm);
          // this.renderer.appendChild(body, sfForm);
          sfForm.submit();
          document.getElementById("sfForm").remove();
        }
      },
      err => {
        throw err;
      }
    );
  }
  loginJira() {
    this.ticketService.getJiraUrl().subscribe(
      res => {
        if (res) {
          window.open(res["url"], "_blank");
        }
      },
      err => {
        throw err;
      }
    );
  }

  getMasters() {
    this.masterService.getAllMasters().subscribe(
      res => {
        if (res) {
          this.allMasters = res;
        }
      },
      err => {
        throw err;
      }
    );
  }

  getUserInfo(id) {
    this.userService.getUserInfo(id).subscribe(
      res => {
        if (res) {
          this.currentUser = res;
          this.currentUserFullName = `${this.currentUser.firstName} ${this.currentUser.middleName} ${this.currentUser.lastName} `;
        }
      },
      err => {
        throw err;
      }
    );
  }
  toggleLeft() {
    if(!this.toggledByUser)
    {
      this.toggledByUser = true;
    }
    else
    {
      this.toggledByUser = false;
    }

    this.updateNavigation();
  }

  collapseMenu(menuItem?) {
    this.updateNavigation(menuItem);
  }

  /**
   * Clears the record for the currently selected site
   *
   * @deprecate Doesn't appear to be used anyway. It isn't needed anyway.
   */
  deselectSite() {
    localStorage.removeItem('selectedSite');
  }

  openChangePasswordPopup(userId) {
    var model = this.modalService.open(ChangePasswordComponent, {
      ariaLabelledBy: "modal-basic-title",
      backdrop: "static"
    });
    // model.componentInstance.masterTypeId = this.masterTypeId
    model.componentInstance.userId = userId;
    // model.componentInstance.masterType = this.masterType.name
    model.result.then(
      result => {
        // this.getIntakes();
      },
      reason => {
        console.log(`Dismissed: ${reason}`);
      }
    );
  }

  logout(mode?) {
    let data = JSON.parse(localStorage.getItem("userInfo"));
    if (data) {
      this.loginService.logout(data).subscribe(
        res => {
          if (res) {
            if (mode == "back") {
              let parentInfo = JSON.parse(localStorage.getItem("parentInfo"));
              localStorage.setItem(
                "userInfo",
                localStorage.getItem("parentInfo")
              );
              this.getParentRole(parentInfo);
              localStorage.setItem('selectedSite', '{}');
              localStorage.removeItem("impersonateSite");
              localStorage.removeItem("parentInfo");
              this.router.navigate([`${this.parentRole.url}/dashboard`]);
            } else {
              localStorage.setItem('selectedSite', '{}');
              localStorage.clear();
              this.userService.UserProfile = {};
              this.intakesService.basicInfo = {};
              this.intakesService.websitePromotion = {};
              this.intakesService.commonBasicInfoObj = {};
              this.intakesService.basicInfoRequiredData = {};
              this.intakesService.sectionData = {};
              this.fileUploadService.uploadedFiles = {};
              this.router.navigate([`/login`]);
            }
            if (this.userInfo.roleId>0 && document.getElementById("chat-widget-container"))
                document.getElementById("chat-widget-container").style.display='none';
          }
        },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        }
      );
    } else {
      this.router.navigate([`/login`]);
    }
  }
  openProfile() {
    let data = JSON.parse(localStorage.getItem("userInfo"));
    if (data) {
      this.router.navigate([`${this.currentPath.url}/user-profile`]);
    } else {
      this.router.navigate([`/login`]);
    }
  }
  selectIntakeProduct(product) {
    this.menuService.intakeProductFamily.next({
      family: this.intakeProduct,
      selectedProduct: product
    });
  }

  getParentRole(userInfo) {
    this.parentRole = _.find(roles, role => {
      return role.roleId == userInfo.roleId;
    });
  }
}
