import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap } from 'rxjs/operators';
import { Program } from '@shared/interfaces/programs';
import { CorporatePartner } from '@shared/interfaces/client';

@Injectable({
  providedIn: 'root'
})
export class CorporatePartnerService {

  constructor(
    private httpService: HttpService
  ) { }

  /**
   * Returns a list of corporate partners
   *
   * @return {Observable<[]>} The list of corporate partners
   */
  getCorporatePartners(): Observable<CorporatePartner[]> {
    return this.httpService.getApi('user/corporatePartner')
    .pipe(
      tap(res => {
        if(res){
          return res;
        }
    })
  );
  }

  /**
   * Returns data on all Programs from the API
   * @return {Observable<Program[]>} An observable containing a list of all the registered programs
   */
  getCpData(): Observable<Program[]> {
    return this.httpService.getApi('user/cp')
      .pipe(
        tap(
          (res) => {
            if (res) {
              return res;
            }
          }
        )
      );
  }

  getSalesForceCp(): Observable<any> {
    return this.httpService.getApi('Salesforce/CP')
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }

  piwikIdSetup(siteId): Observable<any> {
    return this.httpService.postApi(`Piwik/SetUp?SiteId=${siteId}`,'','')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
}
