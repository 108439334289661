import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IntakesService } from '../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../services/http.service';
import { NotificationsService } from '@shared/services/notifications/notifications.service';

@Component({
  selector: 'app-initiate-popup',
  templateUrl: './initiate-popup.component.html',
  styleUrls: ['./initiate-popup.component.css']
})
export class InitiatePopupComponent implements OnInit {
  initiateForm:UntypedFormGroup;
  intake;
  paymentMode;
  checkPayType: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.getPaymentMode();
    if(this.intake.paymentMode > 0 && this.intake.isResound_Signia==true){
      this.checkPayType=true;
      this.initiateForm.controls.paymentMode.setValue(this.intake.paymentMode);
    }else{
      this.checkPayType=false;
    }
  }
  selectPaymentModeName(paymentModeId){

    if(paymentModeId){
      for(let i=0;i<this.paymentMode.length;i++){
        if(this.paymentMode[i].id == paymentModeId){
          this.initiateForm.controls.paymentModeName.setValue(this.paymentMode[i].name);
        }
      }
    }else{
      this.initiateForm.controls.paymentModeName.setValue('');
    }
    

  }

  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }

  initializeForm(){
    // this.action=this.master?'Edit':'Add';
    this.initiateForm=this.formBuilder.group({
      siteId: [this.intake ? this.intake.siteId : null],
      intakeId: [ this.intake?this.intake.intakeId: null],
      paymentMode:['',[Validators.required]],
      paymentModeName:[this.intake ? this.intake.paymentModeName : null],
      comment:['']
    });
  }

  getPaymentMode(){
    this.intakesService.getPaymentMode().subscribe(res => { 
      if(res){
        this.paymentMode = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  submitForm(form) {
    if (form.valid) {
      let paymentInfo = form.value;
      this.intakesService.postIntakeInitiate(paymentInfo).subscribe(res => {
        if (res.status !== "Success") {
          this.notificationsService.showToastError(res.errorMessage, "Failed to initiate Intake");
          this.activeModal.close(false);
        }
        this.activeModal.close(true);
        },
          err => {
            this.notificationsService.showToastError(err.error.message, "");
          });
      
    }
    
  }



}
