import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuardsService as AuthGuard } from '@shared/security/auth-guards.service';
import { AppModule } from './app.module';
// import { PagesModule } from '../app/components/pages.module';

export let routes: Routes = [

  // { path: '', loadChildren: './components/pages.module#PagesModule' },
  // { path: '**', loadChildren: './components/pages.module#PagesModule' }
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '' }

];

export let routing: ModuleWithProviders<AppModule> = RouterModule.forRoot(routes, { useHash: true });
