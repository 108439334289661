import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-error-notice',
  templateUrl: './icon-error-notice.component.html',
  styleUrls: ['./icon-error-notice.component.css'],
})
export class IconErrorNoticeComponent implements OnInit {
  @Input() message: string;

  constructor() { }

  ngOnInit(): void {
  }
}
