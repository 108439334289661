import { FormArray, FormControl } from "@angular/forms";

// # This represents a single checkbox item
export class CheckboxItemControl {
  label: string; // value to be shown in the UI
  value: string; // value to be saved in backend

  control: FormControl;

  constructor({ label, value, defaultValue = false }: { label: string; value: string; defaultValue?: boolean }) {
    this.label = label;
    this.value = value;

    this.control = new FormControl(defaultValue || false);
  }

  get selected(): boolean {
    return Boolean(this.control.value);
  }
}

// # This represents a checkbox group, with several items
export class CheckboxGroupControl {
  name?: string; // name of the checkbox group

  items: CheckboxItemControl[];
  control: FormArray;

  constructor(name: string, items: CheckboxItemControl[], isRequired: boolean = false) {
    this.name = name;
    this.items = items;

    this.control = new FormArray(this.getAllItemsControls(), isRequired ? CheckboxGroupControl.emptyArrayFormValidator : null);
  }

  get value(): string[] {
    return this.selectedItems.map(item => item.value);
  }

  private get selectedItems(): CheckboxItemControl[] {
    return this.items.filter(item => item.selected);
  }

  private getAllItemsControls(): FormControl[] {
    return this.items.map(item => item.control);
  }

  private static emptyArrayFormValidator(control: FormControl) {
    const valid = (control.value as boolean[]).some(Boolean);

    // @todo improve error message
    return valid ? null : {
      error: 'empty'
    };
  }
}
