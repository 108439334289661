import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistic-block',
  templateUrl: './statistic-block.component.html',
  styleUrls: ['./statistic-block.component.css']
})
export class StatisticBlockComponent implements OnInit {
  @Input() label: string;
  @Input() number: number;
  @Input() tooltip: string;
  @Input() highlighted = false;
  @Input() showArrow = true;

  constructor() { }

  ngOnInit(): void {
  }

}
