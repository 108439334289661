import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, RouterModule, NavigationStart } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { TicketService } from '../service/ticket.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UserService } from '../../admin/users/services/user-service.service';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { AssetsComponent } from '../../customer/intakes/add-intakes-data/basic/content/assets/assets.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import * as _ from 'lodash';
import { environment } from 'environments/environment';
import { HttpService } from '../../../services/http.service';
import moment from 'moment';
import { roles } from '@shared/master-enum.enum';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.css']
})
export class TicketDetailComponent implements OnInit {
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
      'link', 'unlink', '|',
      'bulletedList', 'numberedList', '|',
      'alignment', '|',
      'blockQuote', 'codeBlock', '|',
      'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', '|',
      'imageUpload', 'mediaEmbed', '|',
      'specialCharacters', '|',
      'horizontalLine', 'pageBreak', '|',
      'undo', 'redo', '|',
      'removeFormat', 'findAndReplace', 'sourceEditing'
    ],
    image: {
      toolbar: [
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        '|',
        'toggleImageCaption',
        'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    }
  };
previousurl;
currentRole;

  commentForm: UntypedFormGroup;
  ticket;
  @ViewChild('fileUpload') fileUpload: ElementRef;
  uploadData: FormData = new FormData();
  imgUrl;
  isFileInvalid = false;
  fileName;
  photographs: any = [];
  loading = false;
  ticketDescription;
  userInfo;
  baseUrl = environment.apiUrl;
  selectedSite;
  ticketId;
  constructor(private ticketService: TicketService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    public modalService: NgbModal,
    private httpService: HttpService,
    public router: Router,
    private toastrService: ToastrService) {
    // this.router.events
    //   .subscribe((event) => {
    //     if (event instanceof NavigationStart) {
    //       localStorage.setItem('previousUrl', this.router.url);
    //     }
    //   });
  }
  audit;
  ngOnInit() {
    this.audit= JSON.parse(this.activatedRoute.snapshot.queryParamMap.get('audit'));
    console.log(this.audit);
     this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.selectedSite = JSON.parse(localStorage.getItem('selectedSite'));
    this.previousurl = localStorage.getItem('previousUrl');
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.ticketId=params.id;
        this.initializeForm(params.id);

      }
    });
    this.currentRole = _.find(roles, (role) => {
      return role.roleId == this.userInfo.roleId;
    })
  }
  strip(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    setTimeout(() => {
      tmp.remove();
    }, 2000);
    return tmp.textContent || tmp.innerText || "";
  }
  getTicketDetail(ticketId) {
    this.ticketService.getTicketDetail(ticketId).subscribe((res) => {
      if (res) {
        this.ticket = res;
      }
    },
      err => {

      })
  }
  initializeForm(ticketId?) {
    if (this.commentForm) {
      this.commentForm.setValue({
        TicketId: ticketId,
        Comment: null
      })
    } else {
      let formTemp: any = {
        TicketId: ticketId,
        Comment: [null, Validators.required]
      }
      this.commentForm = this.formBuilder.group(formTemp);
    }
    // this.commentForm = this.formBuilder.group({
    //   TicketId:[ticketId],
    //   Comment: [null,Validators.required]
    // });
    this.fileName='';
    this.photographs = [];
    this.getTicketDetail(ticketId);
  }
  addComment(form) {
    let data = form.value;
    data.Comment = this.strip(data.Comment);
    data.FileName = this.fileName;
    if(data.Comment.trim()==""&&data.FileName ==""){
      this.httpService.openErrorPopup('Please enter comment or upload Attachment');
      return;
    }
    this.ticketService.addComent(data).subscribe((res) => {
      if (res) {
        this.initializeForm(data.TicketId);
        // this.fileUpload.nativeElement.value = "";
        this.toastrService.success('Comment Added', 'Success');
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);

      })
  }
  onFileChanged(event) {
    if (event) {
      let file = event.target.files[0];
      this.uploadData.append('myFile', file, file.name);
      var checkimg = event.target.value.toLowerCase();
      if (!checkimg.match(/(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.pdf|\.PDF|\.docx|\.DOCX|\.csv|\.CSV|\.xlsx|\.XLSX)$/)) {
        this.isFileInvalid = true;
        return false;
      } else {
        if (event.target.files && event.target.files[0]) {
          var reader = new FileReader();

          reader.readAsDataURL(event.target.files[0]); // read file as data url

          reader.onload = (event) => { // called once readAsDataURL is completed
            this.imgUrl = event.target['result'];
            this.isFileInvalid = false;
          }
        }
      }
      this.uploadFile();
    }

  }

  uploadFile() {
    if (this.uploadData.has("myFile")) {
      this.uploadData.append("AttachmentFor", 'JIRA')
      this.userService.uploadProfileImg(this.uploadData).subscribe(res => {
        this.fileName = res.File;
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
    }
  }
  openUploadedFilesPopup(fieldName) {
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = this.selectedSite?this.selectedSite.siteId:0;
    model.componentInstance.field = fieldName;
    model.componentInstance.isAssets = true;
    model.componentInstance.attachmentFor = 'JIRA';
    model.componentInstance.baseUrl = environment.apiUrl;
    model.componentInstance.isMultipleFiles = true;
    model.result.then((files) => {
      if (files) {
        _.forEach(files, (file) => {
          let n;
          if (this.photographs.length) {
            n = _.find(this.photographs, (photo) => {
              return photo == file;
            })
          }
          if (!n) {
            this.photographs.push(file);
          }
        })
        this.fileName = this.photographs.join(',');
      }
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });

  }

  deleteAssets(file){
    this.photographs = _.filter(this.photographs, (photo) => {
      return photo != file;
    })
    this.fileName = this.photographs.join(',');
  }

  openAssetsPopup() {
    let model = this.modalService.open(AssetsComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.title = 'Attachments';
    model.componentInstance.baseUrl = environment.apiUrl;
    this.photographs.forEach(function (file) {
      let type = file.split('.');
      type = type[type.length - 1];
      model.componentInstance.assets.push({ path: file, fileType: type, name: file.split('\\')[1] })
    });
    model.componentInstance.deleteAssets = (type?) => {
      model.componentInstance.assets = _.filter(model.componentInstance.assets, (file) => {
        return !file.hasOwnProperty('isFileSelected') || file.isFileSelected == false;
      });

      this.photographs = [];
      // this.homepagePhotographyForm.controls.photography.setValue('');
      this.fileName = '';
      _.forEach(model.componentInstance.assets, (asset) => {
        this.photographs.push(asset.path);
        this.fileName = this.photographs.join(',');
      })
      if (type != 'delete') {
        model.close();
      }
    }
  }

  downloadAttachment(attachment){
    this.ticketService.downloadAttachment(attachment).subscribe(res => {
      let Res=res;
      const downloadedFile = new Blob([Res], { type: Res.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = attachment.filename;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
    },
    err=>{
      throw err;
    })
  }
  deleteAttachment(attachmentId){
    this.ticketService.deleteAttachment(attachmentId).subscribe(res=>{
      this.toastrService.success('Attachment Deleted','Success')
      this.getTicketDetail(this.ticketId);
    },
    err=>{
      this.httpService.openErrorPopup(err.error.message);
    })
  }
}
