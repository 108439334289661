// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  display: flex;
}

aside {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 6px rgb(175, 175, 175);
  display: flex;
  flex-basis: 12.5rem;
  flex-direction: column;
  min-height: 23rem;
  width: 210px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/intakes/intake-sidebar/intake-sidebar.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,oCAAoC;EACpC,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[":root {\n  display: flex;\n}\n\naside {\n  background-color: rgb(255, 255, 255);\n  box-shadow: 0 2px 6px rgb(175, 175, 175);\n  display: flex;\n  flex-basis: 12.5rem;\n  flex-direction: column;\n  min-height: 23rem;\n  width: 210px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
