import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '@core/page-header/service/menu.service';
import * as _ from 'lodash';
import { roles } from '@shared/master-enum.enum';

@Component({
  selector: 'app-notifications',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
@Input() pageNo;
@Input() siteId;
notifications = [];
showMore;
userInfo = JSON.parse(localStorage.getItem('userInfo'));
currentRole;
  constructor(
    private menuService: MenuService
  ) { }

  ngOnInit() {
    this.getNotifications(this.siteId, this.pageNo);
    this.currentRole = _.find(roles, (role) => {
      return role.roleId == this.userInfo.roleId;
    })
  }

  getNotifications(Siteid, pageNo) {
    this.menuService.getNotifications(Siteid, pageNo).subscribe((res) => {
      if (res) {
        this.pageNo = pageNo;
        // a.slice(0, a.indexOf('href')+6) + 'abcc/' + a.slice(a.indexOf('href') + Math.abs(6));
        _.forEach(res['table'], (notification) => {
          if(notification.name.indexOf('href') >= 0 && notification.name.indexOf('https') == -1 && notification.name.indexOf('http')== -1  ){
            notification.name = notification.name.slice(0, notification.name.indexOf('href')+6) + '/#' + this.currentRole.url + '/' + notification.name.slice(notification.name.indexOf('href') + Math.abs(6));
          }
        })
        this.notifications.length ? this.notifications.push.apply(this.notifications,res['table']) : this.notifications = res['table'];
        this.showMore = this.notifications.length < res['table1'][0].totalNotifications ? true : false;
      }
    },
      err => {
        throw err;
      });
  }

}
