// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  background: rgb(48, 62, 78);
  border-radius: 5px 5px 0 0;
  border: none;
  color: rgb(255, 255, 255);
  display: block;
  font-size: 15px;
  font-weight: 600;
  height: 42px;
  line-height: 42px;
  margin: 0 4px 0 0;
  padding: 0 16px;
  text-decoration: underline;
}

a:hover {
  border-color: rgb(233, 236, 239) rgb(233, 236, 239) rgb(222, 226, 230);
  color: rgb(255, 255, 255);
}

a.active {
  background: rgb(66, 150, 247);
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
  border-color: rgb(222, 226, 230) rgb(222, 226, 230) rgb(255, 255, 255);
}

li {
  margin-bottom: -1px;
  margin-top: 5px;
  vertical-align: baseline;
}

li:focus, li:hover {
  text-decoration:none
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/tab/tab.component.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,0BAA0B;EAC1B,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,sEAAsE;EACtE,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,oCAAoC;EACpC,qBAAqB;EACrB,sEAAsE;AACxE;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE;AACF","sourcesContent":["a {\n  background: rgb(48, 62, 78);\n  border-radius: 5px 5px 0 0;\n  border: none;\n  color: rgb(255, 255, 255);\n  display: block;\n  font-size: 15px;\n  font-weight: 600;\n  height: 42px;\n  line-height: 42px;\n  margin: 0 4px 0 0;\n  padding: 0 16px;\n  text-decoration: underline;\n}\n\na:hover {\n  border-color: rgb(233, 236, 239) rgb(233, 236, 239) rgb(222, 226, 230);\n  color: rgb(255, 255, 255);\n}\n\na.active {\n  background: rgb(66, 150, 247);\n  color: rgb(255, 255, 255) !important;\n  text-decoration: none;\n  border-color: rgb(222, 226, 230) rgb(222, 226, 230) rgb(255, 255, 255);\n}\n\nli {\n  margin-bottom: -1px;\n  margin-top: 5px;\n  vertical-align: baseline;\n}\n\nli:focus, li:hover {\n  text-decoration:none\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
