import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SiteService } from 'app/components/site/service/site.service';

@Component({
  selector: 'app-add-social-media-setup',
  templateUrl: './add-social-media-setup.component.html',
  styleUrls: ['./add-social-media-setup.component.css']
})
export class AddSocialMediaSetupComponent implements OnInit {

  siteId;
  smsForm:UntypedFormGroup;
  modalService;
  existingData;
  
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private router: Router,
    private toastrService: ToastrService,
    private siteService:SiteService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }
  initializeForm(){
    this.smsForm=this.formBuilder.group({
      socialMediaTypeId: [ this.existingData?this.existingData.socialMediaTypeId: 0],
      socialMediaType:[ this.existingData?this.existingData.socialMediaType: 0],
      url:[ this.existingData?this.existingData.url: 0],
      siteId:[this.existingData?this.existingData.siteId: 0],
      userName:[this.existingData?this.existingData.userName: null],
      password:[ this.existingData?this.existingData.password: null]
    });
  }
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }
  updateAccount(form) {
    if (form.valid) {
      this.siteService.setUpSocialMediaSetup(form.value).subscribe(res=>{
        if(res){
          this.activeModal.close();
          this.toastrService.success('Account Saved','success');
        }
      },
      err=>{
        this.toastrService.error(err.error);
      })
      }
      
    }

}
