// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all {
  select {
    background-image: url('select-drop-arrow.svg');
    background-repeat: no-repeat;
    background-position: 100% center;
    background-size: auto;
    border: 1px solid rgb(186, 186, 186);
    border-radius: 0;
    color: rgb(74, 76, 87);
    font-size: 15px;
    height: 46px;
    line-height: 1.5;
    margin-bottom: 12px;
    padding: 5px 8px;
    resize: none;
  }

  .bold {
    font-weight: bold;
  }

  .required {
    color: rgb(223, 79, 60);
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/forms/select/select.component.css"],"names":[],"mappings":"AAAA;EACE;IACE,8CAA8C;IAC9C,4BAA4B;IAC5B,gCAAgC;IAChC,qBAAqB;IACrB,oCAAoC;IACpC,gBAAgB;IAChB,sBAAsB;IACtB,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,uBAAuB;EACzB;AACF","sourcesContent":["@media all {\n  select {\n    background-image: url('select-drop-arrow.svg');\n    background-repeat: no-repeat;\n    background-position: 100% center;\n    background-size: auto;\n    border: 1px solid rgb(186, 186, 186);\n    border-radius: 0;\n    color: rgb(74, 76, 87);\n    font-size: 15px;\n    height: 46px;\n    line-height: 1.5;\n    margin-bottom: 12px;\n    padding: 5px 8px;\n    resize: none;\n  }\n\n  .bold {\n    font-weight: bold;\n  }\n\n  .required {\n    color: rgb(223, 79, 60);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
