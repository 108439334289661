// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-name{
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 2;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cw{
    width: 38px;
    height: 38px;
    background: #3497EC;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 38px;
    text-transform: uppercase;
    font-weight: bold;
}

.theme-bg {
    background: #303E4E !important;
}

::ng-deep .ck-editor__editable {
    min-height: 300px; /* Set your desired minimum height here */
}`, "",{"version":3,"sources":["webpack://./src/app/components/ticket/ticket-detail/ticket-detail.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,UAAU;IACV,UAAU;IACV,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,iBAAiB,EAAE,yCAAyC;AAChE","sourcesContent":[".img-name{\n    position: absolute;\n    left: 10px;\n    top: 10px;\n    z-index: 2;\n    width: 80%;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.cw{\n    width: 38px;\n    height: 38px;\n    background: #3497EC;\n    border-radius: 50%;\n    color: #fff;\n    text-align: center;\n    line-height: 38px;\n    text-transform: uppercase;\n    font-weight: bold;\n}\n\n.theme-bg {\n    background: #303E4E !important;\n}\n\n::ng-deep .ck-editor__editable {\n    min-height: 300px; /* Set your desired minimum height here */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
