export class auditRequest {
      
        id:number=0;
        requestedBy:any='';
        auditTypeId:any='';
        lstAuditTypeId=[];
        practiceName:string='';
        websiteUrl:string='';
        customerName:string='';
        resoundAccount:any='';
        customerEmail:string='';
        customerPhone:number=null;
        noOfLocation:any='';
        practiceTypeId:any='';
        lstPracticeTypeId=[];
        submittedBy:any='';
        additionalNotes:string='';
   
    constructor() { }

}
export class AddressInfo {
    
    id:number=0; 
    address1:string='';
    address2:string='';
    city:string='';
    state:string='';
    zipCode:string='';
    competitorName1:string='';
    competitorWebsite1:string='';
    competitorName2:string='';
    competitorWebsite2:string='';
    competitorName3:string='';
    competitorWebsite3:string='';
    proposalId:number=0;
    constructor() {
       
       }
  
  }
  
