
export class ClientInfoModel {
    
    id:number=0; 
    address1:string='';
    address2:string='';
    city:string='';
    state:string='';
    zipCode:string='';

    constructor() {
       
       }

}

